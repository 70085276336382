import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../Styles/themed-styled-components';

import { LeadPlatformTheme } from '../../Styles/Types';
import { StyledBadge } from './Badge';

enum AgentLicenseStatus {
  active = 'active',
  expired = 'expired',
}

export type Props = {
  status: AgentLicenseStatus;
};

export function LicenseStatusBadge(props: Props): ReactElement | null {
  const theme = useTheme() as LeadPlatformTheme;
  const { t } = useTranslation();
  const { status } = props;

  const LicenseStatusTextColors: {
    [status in AgentLicenseStatus]: { textColor: string; backgroundColor: string };
  } = {
    active: { textColor: theme.colors.green[50], backgroundColor: theme.colors.green[20] },
    expired: { textColor: theme.colors.red[60], backgroundColor: theme.colors.red[20] },
  };

  const statusLabel = t(`modals.agentLicenses.${status}`);
  const { backgroundColor, textColor } = LicenseStatusTextColors[status];

  return <StyledBadge backgroundColor={backgroundColor} textColor={textColor} label={statusLabel} />;
}
