import styled from '../../Styles/themed-styled-components';

import { ActionButton } from '../../Components/Button/ActionButton';
import { grey } from '../../Styles/Theme';

export const ApplicationMarketingDataExtractButton = styled(ActionButton)`
  &&&&& {
    flex: none;
    svg {
      background-color: ${grey[10]};
    }
  }
`;
