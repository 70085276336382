import { ReactElement, Fragment, useMemo, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Loader } from '@breathelife/ui-components';
import { Box } from '@breathelife/mui';

import Typography from '../../../../../Components/Typography';
import { Select } from '../../../../../Components/Select/Select';
import { QuestionnaireVersionsContext } from '../../../Questionnaire/ContextProvider/QuestionnaireVersionsContextProvider';
import { useLocale } from '../../../../../Hooks';
import { DocumentRulesContainer } from './DocumentRulesContainer';

type DocumentRulesViewProps = {
  fileTemplateId: string;
};

export function DocumentRulesView(props: DocumentRulesViewProps): ReactElement | null {
  const { fileTemplateId } = props;
  const { t } = useTranslation();
  const language = useLocale();

  const {
    questionnaires,
    questionnairesIsFetching,
    setSelectedQuestionnaireId,
    selectedQuestionnaireId,
    questionnaireVersions,
  } = useContext(QuestionnaireVersionsContext);

  useEffect(() => {
    setSelectedQuestionnaireId('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const questionnairesOptions = useMemo(() => {
    return (
      questionnaires?.map((questionnaire) => {
        return {
          value: questionnaire.id,
          label: questionnaire.name[language] as string,
        };
      }) || []
    );
  }, [questionnaires, language]);

  const questionnaireVersion = useMemo(() => {
    return questionnaireVersions[0];
  }, [questionnaireVersions]);
  if (questionnairesIsFetching || !questionnaireVersion) return <Loader />;

  return (
    <Fragment>
      <Fragment>
        <Box display='flex' flexDirection='row' m={2}>
          <Select
            isSearchFilter
            id='questionnaire-select'
            label={t('admin.questionnaireManagement.title')}
            value={selectedQuestionnaireId}
            options={questionnairesOptions}
            onChange={setSelectedQuestionnaireId}
          />
        </Box>
        <Box mx={2} mt={1}>
          <Typography
            variant='body2'
            style={{
              textOverflow: 'ellipsis',
            }}
          >
            {t('admin.documentDetailManagement.documentRule.currentlyEditingVersion', {
              version: `v${questionnaireVersion?.majorVersion}.${questionnaireVersion?.minorVersion} - ${questionnaireVersion?.description}`,
            })}
          </Typography>
        </Box>
        <DocumentRulesContainer questionnaireVersionId={questionnaireVersion.id} fileTemplateId={fileTemplateId} />
      </Fragment>
    </Fragment>
  );
}
