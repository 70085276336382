import { Fragment, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Divider, Grid } from '@breathelife/mui';
import { FileTemplateRecipient, ParticipantRole } from '@breathelife/types';

import { Button } from '../../../../../Components/Button/Button';
import { Checkbox } from '../../../../../Components/Checkbox/Checkbox';
import LoadingView from '../../../../../Components/LoadingView/LoadingView';
import Typography from '../../../../../Components/Typography';
import {
  useDeleteFileTemplateRecipientMutation,
  useUpdateFileTemplateRecipientMutation,
} from '../../../../../ReactQuery/FileTemplateRecipients/fileTemplateRecipients.mutations';
import { useFindFileTemplateRecipientsQuery } from '../../../../../ReactQuery/FileTemplateRecipients/fileTemplateRecipients.queries';
import { StyledSelect } from '../../Style';
import { ESignFieldSettingContainer } from './ESignFieldSetting/ESignFieldSettingContainer';

function FileTemplateRecipientItem(props: {
  fileTemplateRecipient: FileTemplateRecipient;
  participantRoles: ParticipantRole[];
}): ReactElement {
  const { participantRoles } = props;
  const deleteFileTemplateRecipientMutation = useDeleteFileTemplateRecipientMutation();
  const { t } = useTranslation();

  const deleteFileTemplateRecipient = async (): Promise<void> => {
    void (await deleteFileTemplateRecipientMutation.mutateAsync(props.fileTemplateRecipient.id));
  };

  const updateFileTemplateRecipientMutation = useUpdateFileTemplateRecipientMutation();

  const updateParticipantRoleIdHandler = async (participantRoleId: string): Promise<void> => {
    await updateFileTemplateRecipientMutation.mutateAsync({
      id: props.fileTemplateRecipient.id,
      data: {
        participantRoleId,
      },
    });
  };

  const toggleSignatureRequiredHandler = async (isSignatureRequired: boolean): Promise<void> => {
    await updateFileTemplateRecipientMutation.mutateAsync({
      id: props.fileTemplateRecipient.id,
      data: {
        isSignatureRequired,
      },
    });
  };

  const participantRolesView = participantRoles.map((role) => ({
    value: role.id,
    label: `${role.position} - ${role.type}`,
  }));

  return (
    <Fragment>
      <Grid item xs={2}>
        <Box display='flex' alignItems='center' height='100%'>
          <Typography variant='body1'>{t('admin.documentDetailManagement.recipient')}</Typography>
        </Box>
      </Grid>
      <Grid item xs={8}>
        <StyledSelect
          options={participantRolesView}
          onChange={(value) => updateParticipantRoleIdHandler(value as string)}
          value={props.fileTemplateRecipient?.participantRole?.id || ''}
          id='update-esign-signer-setting-role'
        />
      </Grid>
      <Grid item xs={2}>
        <Button color='primary' variant='outlined' onClick={deleteFileTemplateRecipient}>
          {t('cta.delete')}
        </Button>
      </Grid>

      <Grid item xs={12}>
        <Checkbox
          checked={props.fileTemplateRecipient?.isSignatureRequired}
          onChange={() => {
            void toggleSignatureRequiredHandler(!props.fileTemplateRecipient?.isSignatureRequired);
          }}
          label={t('assistedApplication.signatureDrawer.signatureRequired')}
        />
      </Grid>

      {props.fileTemplateRecipient?.isSignatureRequired && (
        <Grid item xs={12}>
          <ESignFieldSettingContainer fileTemplateRecipient={props.fileTemplateRecipient} />
        </Grid>
      )}
    </Fragment>
  );
}

export function FileTemplateRecipientsList(props: {
  fileTemplateId: string;
  participantRoles: ParticipantRole[];
}): ReactElement | null {
  const fileTemplateRecipients = useFindFileTemplateRecipientsQuery({ fileTemplateId: props.fileTemplateId });

  if (fileTemplateRecipients?.isLoading) {
    return <LoadingView />;
  }

  if (!fileTemplateRecipients?.data?.length) {
    return null;
  }

  return (
    <Grid container spacing={2}>
      {fileTemplateRecipients?.data.map((fileTemplateRecipient) => {
        return (
          <FileTemplateRecipientItem
            key={fileTemplateRecipient.id}
            fileTemplateRecipient={fileTemplateRecipient}
            participantRoles={props.participantRoles}
          />
        );
      })}
      <Divider />
    </Grid>
  );
}
