import i18next from 'i18next';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { InsuranceFirmWithProductCount, InsuranceFirm } from '@breathelife/types';

import { useDispatch } from '../../../Hooks';
import { QueryId } from '../../../ReactQuery/common/common.types';
import { notificationSlice } from '../../../ReduxStore/Notification/NotificationSlice';
import { findInsuranceFirms, getInsuranceFirm } from '../../../Services/InsuranceFirmService';

export function useFindFirmsQuery(
  options?: UseQueryOptions<InsuranceFirmWithProductCount[]>,
): UseQueryResult<InsuranceFirmWithProductCount[]> {
  const dispatch = useDispatch();
  const entity = i18next.t('admin.firmManagement.insuranceFirm.plural');

  return useQuery<InsuranceFirmWithProductCount[]>(QueryId.firms, findInsuranceFirms, {
    ...options,
    onError: (error) => {
      options?.onError?.(error);
      dispatch(
        notificationSlice.actions.setError({
          message: i18next.t('notifications.failedToFetchEntity', { entity }),
        }),
      );
    },
  });
}

export function useGetFirmQuery(
  firmId: string,
  options?: UseQueryOptions<InsuranceFirm>,
): UseQueryResult<InsuranceFirm> {
  const dispatch = useDispatch();
  const entity = i18next.t('admin.firmManagement.insuranceFirm.singular');

  return useQuery<InsuranceFirm>([QueryId.firm, firmId], async () => getInsuranceFirm(firmId), {
    ...options,
    onError: (error) => {
      options?.onError?.(error);
      dispatch(
        notificationSlice.actions.setError({
          message: i18next.t('notifications.failedToFetchEntity', { entity }),
        }),
      );
    },
  });
}
