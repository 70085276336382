import { Box } from '@breathelife/mui';
import ReactHtmlParser from 'html-react-parser';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '../../../../Components/Typography';

export function SubmissionErrorView(): ReactElement {
  const { t } = useTranslation();
  return (
    <Box>
      <Box my={3}>
        <Typography variant='body1' grey={70}>
          {ReactHtmlParser(t('modals.assistedApplication.submitApplicationView.errorView.somethingWentWrong'))}
        </Typography>
      </Box>
    </Box>
  );
}
