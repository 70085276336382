import { Box } from '@breathelife/mui';
import dayjs from 'dayjs';
import { ReactElement, Fragment, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';

import {
  ApplicationSupportApplication,
  LeadStatus,
  ApplicationFilters,
  ApplicationSearchMode,
  ApplicationMode,
} from '@breathelife/types';

import { StatusBadge } from '../../../../Components/Badge/StatusBadge';
import { ListFilterContainer } from '../../../../Components/ListFilterContainer/ListFilterContainer';
import LoadingView from '../../../../Components/LoadingView/LoadingView';
import { Pagination } from '../../../../Components/Pagination/Pagination';
import { Table } from '../../../../Components/Table/Table';
import { ApplicationsContext, PER_PAGE_OPTIONS } from './ApplicationsContextProvider';
import { ApplicationsTableHeader } from './ApplicationsTableHeader';
import { EmptySearchResultView } from './EmptySearchResultView';
import { ApplicationModeBadge } from '../../../../Components/Badge/ApplicationModeBadge';

export type ApplicationsTableViewProps = {
  onOpenApplicationSupportView: (applicationId: string) => void;
};

export function ApplicationsTable(props: ApplicationsTableViewProps): ReactElement {
  const { onOpenApplicationSupportView } = props;
  const { t } = useTranslation();

  const [hasSearchQuery, setHasSearchQuery] = useState(false);

  const {
    onPageChange,
    onLimitPerPageChange,
    onSearchChange: onSearchChangedProp,
    onSearchModeChange: onSearchModeChangedProp,
    onFilterChange: onFilterChangedProp,
    onSortingChange: onSortingChangedProp,
    applications,
    total,
    currentPage,
    perPage,
    isLoading,
  } = useContext(ApplicationsContext);

  const onTableRowClick = useCallback(
    (applicationId: string) => {
      onOpenApplicationSupportView(applicationId);
    },
    [onOpenApplicationSupportView],
  );

  const onSortingChanged = useCallback(
    (columnKey: keyof ApplicationSupportApplication, direction: 'asc' | 'desc') => {
      if (!columnKey || !direction) return;
      onSortingChangedProp({ field: columnKey as keyof ApplicationSupportApplication, direction });
    },
    [onSortingChangedProp],
  );

  const onSearchChange = useCallback(
    (search: string) => {
      onSearchChangedProp(search);
      setHasSearchQuery(!!search);
    },
    [onSearchChangedProp],
  );

  const onSearchModeChange = useCallback(
    (searchMode: ApplicationSearchMode) => {
      onSearchModeChangedProp(searchMode);
    },
    [onSearchModeChangedProp],
  );

  const onFilterChange = useCallback(
    (filter: ApplicationFilters | undefined) => {
      onFilterChangedProp(filter);
    },
    [onFilterChangedProp],
  );

  const shouldShowEmptySearchResultView = useMemo(() => {
    return hasSearchQuery && !isLoading && applications.length < 1;
  }, [hasSearchQuery, isLoading, applications]);

  const columns = useMemo<
    {
      id?: string;
      Header: string;
      accessor: keyof ApplicationSupportApplication;
      Cell?: (props: CellProps<ApplicationSupportApplication>) => ReactElement;
      disableSortBy?: boolean;
    }[]
  >(
    () => [
      {
        Header: t('applicationTable.id'),
        accessor: 'id',
      },
      {
        Header: t('applicationTable.refNo'),
        accessor: 'refNo',
      },
      {
        Header: t('applicationTable.createdAt'),
        accessor: 'createdAt',
        Cell: function ({ cell: { value } }: { cell: { value: Date } }): ReactElement {
          return <Fragment>{value ? dayjs(value).format('D MMMM YYYY HH:mm A') : ''}</Fragment>;
        },
      },
      {
        Header: t('applicationTable.updatedAt'),
        accessor: 'updatedAt',
        Cell: function ({ cell: { value } }: { cell: { value: Date } }): ReactElement {
          return <Fragment>{value ? dayjs(value).format('D MMMM YYYY HH:mm A') : ''}</Fragment>;
        },
      },
      {
        Header: t('applicationTable.insuredCount'),
        accessor: 'insuredCount',
        disableSortBy: true,
      },
      {
        Header: t('applicationTable.leadStatus'),
        accessor: 'leadStatus',
        Cell: function ({ cell: { value } }: { cell: { value: string } }): ReactElement {
          return (
            <Fragment>
              <StatusBadge status={value as LeadStatus} />
            </Fragment>
          );
        },
      },
      {
        Header: t('applicationTable.lineOfBusiness'),
        accessor: 'lineOfBusiness',
        Cell: function ({ cell: { value } }: { cell: { value: string } }): ReactElement {
          return <Fragment>{value}</Fragment>;
        },
      },
      {
        Header: t('applicationTable.mode'),
        accessor: 'mode',
        Cell: function ({ cell: { value } }: { cell: { value: string } }): ReactElement {
          return (
            <Fragment>
              <ApplicationModeBadge mode={value as ApplicationMode} icon={true}></ApplicationModeBadge>
            </Fragment>
          );
        },
      },
    ],

    [t],
  );

  return (
    <Fragment>
      <ListFilterContainer>
        <ApplicationsTableHeader
          onSearchChange={onSearchChange}
          onSearchModeChange={onSearchModeChange}
          onFilterChange={onFilterChange}
        />
      </ListFilterContainer>

      {isLoading && <LoadingView />}
      {shouldShowEmptySearchResultView && <EmptySearchResultView />}

      <Table<ApplicationSupportApplication>
        columns={columns}
        data={applications}
        onRowClick={onTableRowClick}
        onOrderChange={onSortingChanged}
      />
      <Box px={2.5}>
        <Pagination
          total={total}
          page={currentPage}
          perPage={perPage}
          perPageOptions={PER_PAGE_OPTIONS}
          onPageChange={onPageChange}
          onPerPageChange={onLimitPerPageChange}
        />
      </Box>
    </Fragment>
  );
}
