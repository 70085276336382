import { ReactElement, useMemo } from 'react';

import { Grid } from '@breathelife/mui';
import { IconName } from '@breathelife/types';
import { Icon } from '@breathelife/ui-components';

import Typography from '../../../../../Components/Typography';
import { getOptionsFromEnum } from '../../../../../Helpers/options';
import { EditorDropdown } from '../Components/EditorDropdown';

type Props = {
  label: string;
  value?: IconName;
  onChange: (value: IconName | undefined) => void;
  disabled: boolean;
};

export function IconNameSelector({ label, value, disabled, onChange }: Props): ReactElement | null {
  const options = useMemo(
    () => getOptionsFromEnum<IconName>(IconName, 'admin.questionnaireManagement.questionnaireIconLabels'),
    [],
  );
  return (
    <EditorDropdown
      disabled={disabled}
      label={label}
      selectedOptionValue={value}
      options={options}
      disableClearable={false}
      onChange={(value) => {
        const newIconName = (value || undefined) as IconName | undefined;
        onChange(newIconName);
      }}
      startIconName={value}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <Grid container spacing={2}>
              <Grid item>
                <Icon name={option.value as IconName} size='20px' />
              </Grid>
              <Grid item>
                <Typography variant='body1'>{option.label}</Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
