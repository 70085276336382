import { ReactElement } from 'react';

import { SvgIconProps } from '../../Components/Icons';

export function EmailSubscriptionStatusIcon(props: SvgIconProps): ReactElement {
  return (
    <svg stroke='#757575' fill='#f6f6f6' height='16' width='16' xmlns='http://www.w3.org/2000/svg' {...props.svgProps}>
      <g strokeLinecap='round' strokeLinejoin='round'>
        <path d='M11.5 7.5a4 4 0 110 8 4 4 0 010-8z' />
        <path d='M13.283 10.337l-1.937 2.582a.502.502 0 01-.754.054l-1-1M5.5 10.5h-4a1 1 0 01-1-1v-8a1 1 0 011-1h12a1 1 0 011 1V6' />
        <path d='M14.274.867l-5.43 4.176c-.792.61-1.896.61-2.689 0L.726.867' />
      </g>
    </svg>
  );
}
