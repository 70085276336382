import { PropsWithChildren, ReactElement, createContext, useCallback, useContext, useMemo, useState } from 'react';

import { User } from '@breathelife/types';

import { useDispatch, useSelector } from '../../../Hooks';
import { DetailViewType, ModalType } from '../../../Models/Layout';
import { defaultState as defaultLayoutState, layoutSlice } from '../../../ReduxStore/Layout/LayoutSlice';

import { GetUsersOptions, SortParams } from '../../../Services/Users/UsersService';
import { Filters } from './UsersTable/UsersTableHeader';
import { extensiveUserFields, useUsers } from '../../../ReactQuery/Admin/Users/users.queries';

const PER_PAGE_OPTIONS = [
  { label: 10, value: 10 },
  { label: 15, value: 15 },
  { label: 25, value: 25 },
  { label: 50, value: 50 },
  { label: 100, value: 100 },
];

type UsersContextValue = {
  users: User[];
  total?: number;
  isLoading: boolean;
  selectedUser?: User;
  setSelectedUser: (auth0Id: string) => void;
  onSearchChange: (search: string) => void;
  onFiltersChanged: (filters?: Filters) => void;
  onOpenCreateUser: () => void;
  onPageChange: (page: number) => void;
  onPerPageChange: (perPage: number) => void;
  onSortingChanged: (sort: SortParams) => void;
  onTableRowClick: (data?: User) => void;
  onOpenResendInvitationModal: (data?: User) => void;
  onOpenDeleteUserConfirmationModal: (data?: User) => void;
  onOpenImportUsers: () => void;
  onCloseUserDetails: () => void;

  resetModalState: () => void;
  currentPage: number;
  perPage: number;
  perPageOptions: { label: number; value: number }[];
  modalState: any;
  shouldShowEmptySearchResultView: boolean;
};

export const UsersContext = createContext<UsersContextValue>({
  isLoading: false,
  users: [],
  currentPage: 1,
  perPage: PER_PAGE_OPTIONS[1].value,
  perPageOptions: PER_PAGE_OPTIONS,
  modalState: defaultLayoutState,
  shouldShowEmptySearchResultView: false,
  setSelectedUser: () => {},
  onSearchChange: () => {},
  onFiltersChanged: () => {},
  onOpenCreateUser: () => {},
  onPageChange: () => {},
  onPerPageChange: () => {},
  onSortingChanged: () => {},
  onTableRowClick: () => {},
  onOpenResendInvitationModal: () => {},
  onOpenDeleteUserConfirmationModal: () => {},
  onOpenImportUsers: () => {},
  onCloseUserDetails: () => {},
  resetModalState: () => {},
});

export function UsersContextProvider(props: PropsWithChildren<Record<string, any>>): ReactElement {
  const defaultLimitValue = PER_PAGE_OPTIONS[1].value;
  const [fetchUsersOptions, setFetchUsersOptions] = useState<GetUsersOptions>({
    page: 1,
    limit: defaultLimitValue,
    fields: extensiveUserFields,
  });
  const [selectedAuthId, setSelectedAuthId] = useState<string | undefined>();

  const modalState = useSelector((store) => store.leadPlatform.layout.modalState);
  const rightPanelState = useSelector((store) => store.leadPlatform.layout.rightPanelState);

  const { data: usersData, isLoading } = useUsers(fetchUsersOptions);
  const users = useMemo(() => {
    return usersData?.data || [];
  }, [usersData]);
  const total = usersData?.total;

  const selectedUser = useMemo(() => {
    if (!selectedAuthId) {
      return;
    }
    return users?.find((user) => user.auth0Id === selectedAuthId);
  }, [users, selectedAuthId]);

  const dispatch = useDispatch();

  const [hasSearchQuery, setHasSearchQuery] = useState(false);

  const onTableRowClick = useCallback(
    (data?: User) => {
      if (!data || !data.auth0Id) return;
      setSelectedAuthId(data?.auth0Id);

      dispatch(
        layoutSlice.actions.setRightPanelState({
          rightPanelState: {
            isOpen: true,
            type: DetailViewType.edit,
          },
        }),
      );
    },
    [dispatch],
  );

  const onOpenCreateUser = useCallback(() => {
    dispatch(
      layoutSlice.actions.setRightPanelState({
        rightPanelState: { isOpen: true, type: DetailViewType.create },
      }),
    );
  }, [dispatch]);

  const onFiltersChanged = useCallback((filters?: Filters) => {
    setFetchUsersOptions((prevValue: GetUsersOptions) => ({ ...prevValue, filters, page: 1 }));
  }, []);

  const onSearchChange = useCallback((search: string) => {
    setHasSearchQuery(!!search);
    setFetchUsersOptions((prevValue: GetUsersOptions) => ({ ...prevValue, search, page: 1 }));
  }, []);

  const onPageChange = useCallback((page: number): void => {
    setFetchUsersOptions((prevValue: GetUsersOptions) => ({ ...prevValue, page }));
  }, []);

  const onPerPageChange = useCallback((perPage: number): void => {
    setFetchUsersOptions((prevValue: GetUsersOptions) => ({ ...prevValue, limit: perPage, page: 1 }));
  }, []);

  const onSortingChanged = useCallback((sort: SortParams) => {
    setFetchUsersOptions((prevValue: GetUsersOptions) => ({ ...prevValue, sort, page: 1 }));
  }, []);

  const onOpenResendInvitationModal = useCallback(
    (data?: User) => {
      dispatch(
        layoutSlice.actions.setModalState({
          modalState: { user: data, isOpen: true, type: ModalType.userEmail },
        }),
      );
    },
    [dispatch],
  );

  const onOpenDeleteUserConfirmationModal = useCallback(
    (data?: User) => {
      dispatch(
        layoutSlice.actions.setModalState({
          modalState: { user: data, isOpen: true, type: ModalType.deleteUser },
        }),
      );
    },
    [dispatch],
  );

  const onOpenImportUsers = useCallback(() => {
    dispatch(
      layoutSlice.actions.setModalState({
        modalState: { isOpen: true, type: ModalType.importUsers },
      }),
    );
  }, [dispatch]);

  const resetModalState = useCallback(
    () => dispatch(layoutSlice.actions.setModalState(defaultLayoutState)),
    [dispatch],
  );

  const onCloseUserDetails = useCallback(() => {
    if (!rightPanelState.isOpen) return;
    setSelectedAuthId(undefined);
    dispatch(layoutSlice.actions.setRightPanelState({ rightPanelState: defaultLayoutState.rightPanelState }));
  }, [dispatch, rightPanelState.isOpen]);

  const shouldShowEmptySearchResultView = useMemo(() => {
    return hasSearchQuery && !isLoading && users && users.length < 1;
  }, [hasSearchQuery, isLoading, users]);

  return (
    <UsersContext.Provider
      value={{
        users,
        total,
        isLoading,
        setSelectedUser: (auth0Id: string) => {
          setSelectedAuthId(auth0Id);
        },
        selectedUser,
        currentPage: fetchUsersOptions.page ?? 1,
        perPage: fetchUsersOptions.limit ?? defaultLimitValue,
        perPageOptions: PER_PAGE_OPTIONS,
        onSearchChange,
        onFiltersChanged,
        onOpenCreateUser,
        onPageChange,
        onPerPageChange,
        onSortingChanged,
        onTableRowClick,
        onOpenResendInvitationModal,
        onOpenDeleteUserConfirmationModal,
        onOpenImportUsers,
        onCloseUserDetails,
        resetModalState,
        modalState,
        shouldShowEmptySearchResultView: !!shouldShowEmptySearchResultView,
      }}
    >
      {props.children}
    </UsersContext.Provider>
  );
}

export const useUsersContext = (): UsersContextValue => useContext(UsersContext);
