import { useCallback, useState, ReactElement, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid } from '@breathelife/mui';
import { MatchesRegexConditionValue } from '@breathelife/types';
import { Input } from '@breathelife/ui-components';

import {
  getConditionValidationError,
  ValidationValues,
} from '../../../Helpers/inputValidation/form/salesDecisionRules';
import { CollectionOperators } from '../Helpers/CollectionOperators';
import { ConditionTargetNodeAutocomplete } from '../Helpers/ConditionTargetNodeAutocomplete';
import { ConditionCriteriaProps } from '../types';

export function MatchesRegexConditionCriteria({
  condition,
  onConditionPropertiesChange,
  language,
  nodeIdList,
  nodeIdInCollectionMap,
  collectionContext,
  conditionIndex,
}: ConditionCriteriaProps<MatchesRegexConditionValue>): ReactElement {
  const collectionNodeIds = nodeIdInCollectionMap[condition.targetNodeId] ?? [];
  const { t } = useTranslation();
  const validationErrors = getConditionValidationError(condition, nodeIdList, nodeIdInCollectionMap);
  const [regex, setRegex] = useState(condition.regex);

  const handleRegexBlur = useCallback(() => {
    onConditionPropertiesChange({ regex });
  }, [onConditionPropertiesChange, regex]);

  return (
    <Fragment>
      <Grid item xs={12}>
        <ConditionTargetNodeAutocomplete
          value={condition.targetNodeId}
          nodeIdList={nodeIdList}
          onSelect={(targetNodeId) => {
            if (targetNodeId !== null) {
              onConditionPropertiesChange({ targetNodeId });
            }
          }}
          validationError={validationErrors?.[ValidationValues.targetNodeId]}
          selectedLanguage={language}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          inputVariant='outlined'
          label={t('admin.conditions.labels.controlValue')}
          value={regex}
          onBlur={() => {
            handleRegexBlur();
          }}
          onChange={(event) => {
            setRegex(event.target.value);
          }}
          error={Boolean(validationErrors?.[ValidationValues.regex])}
          helperText={validationErrors?.[ValidationValues.regex]?.message}
        />
      </Grid>
      <CollectionOperators
        collectionContext={collectionContext}
        collectionNodeIds={collectionNodeIds}
        collectionOperators={condition.collectionOperators}
        onConditionPropertiesChange={onConditionPropertiesChange}
        conditionIndex={conditionIndex}
      />
    </Fragment>
  );
}
