import styled from '../../../../Styles/themed-styled-components';

export const CardBody = styled.div`
  padding: 24px;
  max-height: 400px;
  overflow-y: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;
