import { NumberFieldBlueprint } from '@breathelife/types';

import { FieldDataSetterProps } from '../../../Helpers/helperTypes';
import { NumberFieldSetters, useNumberFieldData } from '../useNumberFieldData';
import { useCommonFieldDataSetters } from './useCommonFieldDataSetters';

export function NumberFieldDataSetter({
  initialData,
  dataLabelOptions,
  ...props
}: FieldDataSetterProps<NumberFieldBlueprint, NumberFieldSetters>): null {
  const { data, setters, validationOptions, defaultValueValidationError } = useNumberFieldData({
    initialData,
    dataLabelOptions,
  });

  useCommonFieldDataSetters<NumberFieldBlueprint, NumberFieldSetters>({
    ...props,
    data,
    setters,
    validationOptions,
    defaultValueValidationError,
    getIsValidBlueprint: (blueprint) => !!blueprint.validateAs,
  });
  return null;
}
