import styled from '../../../../../Styles/themed-styled-components';

import { NaEmpty } from '../../../../../Assets/NaEmpty';

export const ApplicationsContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const ExistingApplicationsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-left: 32px;
  padding-right: 32px;
`;

export const NaEmptyImage = styled(NaEmpty)`
  height: 170px;
  width: 170px;
  margin: 30px;
`;

export const LaunchApplicationContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
