import { Validations } from '@breathelife/questionnaire-engine';
import { FieldBlueprint, MoneyFieldBlueprint, FieldTypes, MoneyFieldValidation } from '@breathelife/types';
import { AutocompleteOption } from '@breathelife/ui-components';

import {
  FieldDataHookReturnValueWithValidationOptions,
  FieldSettersValidateAs,
  FieldSettersDefaultValue,
} from '../../Helpers/helperTypes';
import { BaseFieldSetters, useBaseFieldData } from './useBaseFieldData';
import { useDefaultValue } from './useDefaultValue';
import { useValidateAsData } from './useValidateAsData';

export type MoneyFieldSetters = FieldSettersValidateAs<MoneyFieldBlueprint> &
  BaseFieldSetters &
  FieldSettersDefaultValue<MoneyFieldBlueprint>;
export const useMoneyFieldData = ({
  initialData,
  dataLabelOptions,
}: {
  initialData?: Partial<FieldBlueprint>;
  dataLabelOptions: AutocompleteOption[];
}): FieldDataHookReturnValueWithValidationOptions<MoneyFieldBlueprint, MoneyFieldSetters> => {
  const fieldType = FieldTypes.money;
  const { data, setters } = useBaseFieldData({ initialData, dataLabelOptions });

  const {
    data: validateAsData,
    setters: validateAsSetters,
    validationOptions,
  } = useValidateAsData<MoneyFieldBlueprint>({
    fieldType,
    initialValidateAs: MoneyFieldValidation.integer,
  });
  const {
    data: defaultValueData,
    setters: defaultValueSetters,
    validationError,
  } = useDefaultValue<MoneyFieldBlueprint>({
    validateAs: validateAsData.validateAs as unknown as Validations,
  });

  return {
    data: { fieldType, ...data, ...validateAsData, ...defaultValueData },
    setters: { ...setters, ...validateAsSetters, ...defaultValueSetters },
    validationOptions,
    defaultValueValidationError: validationError,
  };
};
