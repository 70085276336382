import { SVGProps, ReactElement } from 'react';

export function CustomersIcon(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg
      fill='none'
      height='16'
      stroke='#fff'
      viewBox='0 0 21 16'
      width='21'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g strokeLinecap='round' strokeLinejoin='round'>
        <path d='m3.1875 4.44218c0 .91292.36216 1.78845 1.00682 2.43398.64466.64554 1.519 1.00819 2.43068 1.00819s1.78602-.36265 2.43068-1.00819c.64466-.64553 1.00682-1.52106 1.00682-2.43398 0-.91293-.36216-1.78846-1.00682-2.43399s-1.519-1.00819-2.43068-1.00819-1.78602.36266-2.43068 1.00819-1.00682 1.52106-1.00682 2.43399z' />
        <path d='m1 14.7694c0-1.4939.59263-2.9266 1.64752-3.9829 1.0549-1.05634 2.48564-1.64978 3.97748-1.64978s2.92258.59344 3.9775 1.64978c1.0549 1.0563 1.6475 2.489 1.6475 3.9829' />
        <path d='m12.3359 6.32023c0 .74694.2964 1.46328.8238 1.99145.5274.52816 1.2428.82488 1.9887.82488.746 0 1.4613-.29672 1.9888-.82488.5274-.52817.8237-1.24451.8237-1.99145 0-.74693-.2963-1.46328-.8237-1.99144-.5275-.52816-1.2428-.82488-1.9888-.82488-.7459 0-1.4613.29672-1.9887.82488s-.8238 1.24451-.8238 1.99144z' />
        <path d='m13.5508 10.4447c.6959-.2569 1.4434-.3416 2.1791-.247.7356.0946 1.4375.3657 2.046.7903s1.1056.9901 1.449 1.6483c.3434.6583.523 1.3899.5234 2.1326' />
      </g>
    </svg>
  );
}
