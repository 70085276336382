import { ReactElement, useContext, useEffect, useState } from 'react';

import { Box } from '@breathelife/mui';

import LoadingView from '../../../Components/LoadingView/LoadingView';
import { QuestionnaireVersionDataContextProvider } from '../Questionnaire/ContextProvider/QuestionnaireVersionDataContextProvider';
import { QuestionnaireVersionsContext } from '../Questionnaire/ContextProvider/QuestionnaireVersionsContextProvider';
import { SalesDecisionRulesManagementView } from './SalesDecisionRulesManagementView';

export function SalesDecisionRulesManagementViewContainer(): ReactElement | null {
  const { questionnaireVersions, questionnaireVersionsIsFetching } = useContext(QuestionnaireVersionsContext);
  const [questionnaireVersionId, setQuestionnaireVersionId] = useState('');

  useEffect(() => {
    if (questionnaireVersions.length) {
      const latestQuestionnaireVersion = questionnaireVersions[0];
      setQuestionnaireVersionId(latestQuestionnaireVersion.id);
    }
  }, [questionnaireVersions]);

  if (questionnaireVersionsIsFetching && !questionnaireVersionId) {
    return (
      <Box m={2} width='100%'>
        <LoadingView />
      </Box>
    );
  }

  return (
    <QuestionnaireVersionDataContextProvider questionnaireVersionId={questionnaireVersionId}>
      <SalesDecisionRulesManagementView onQuestionnaireVersionFilterChanged={setQuestionnaireVersionId} />
    </QuestionnaireVersionDataContextProvider>
  );
}
