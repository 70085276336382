import { ReactElement, useEffect, useState } from 'react';
import styled from '../../Styles/themed-styled-components';

import { Paper, Button, Checkbox, ListItemText, ListItemIcon, ListItemButton, List, Grid } from '@breathelife/mui';

type TransferListProps = {
  selected: string[];
  notSelected: string[];
  onChange(values: string[]): void;
};

const StyledPaper = styled(Paper)`
  width: 400px;
  height: 750px;
  overflow: auto;
`;

const StyledListItemButton = styled(ListItemButton)`
  && {
    cursor: pointer;
  }
`;

function not(a: readonly string[], b: readonly string[]): string[] {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: readonly string[], b: readonly string[]): string[] {
  return a.filter((value) => b.indexOf(value) !== -1);
}

export function TransferList(props: TransferListProps): ReactElement {
  const { selected, notSelected, onChange } = props;

  const [checked, setChecked] = useState<readonly string[]>([]);
  const [left, setLeft] = useState<readonly string[]>(notSelected);
  const [right, setRight] = useState<readonly string[]>(selected);

  useEffect(() => {
    setRight(selected);
  }, [selected]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = (): void => {
    const allRight = [...right, ...left.filter((c) => !right.includes(c))];
    setRight(allRight);
    setLeft([]);
    onChange(allRight);
  };

  const handleCheckedRight = (): void => {
    const newRight = right.concat(leftChecked);
    setRight(newRight);
    setLeft(not(left, leftChecked));

    setChecked(not(checked, leftChecked));
    onChange(newRight);
  };

  const handleCheckedLeft = (): void => {
    setLeft(left.concat(rightChecked));

    const newRight = not(right, rightChecked);
    setRight(newRight);

    setChecked(not(checked, rightChecked));
    onChange(newRight);
  };

  const handleAllLeft = (): void => {
    setLeft([...left, ...right.filter((c) => !left.includes(c))]);
    setRight([]);
    onChange([]);
  };

  const customList = (items: readonly string[]): ReactElement => (
    <StyledPaper>
      <List dense component='div' role='list'>
        {items.map((value: string) => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <StyledListItemButton key={value} role='listitem' onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox checked={checked.indexOf(value) !== -1} tabIndex={-1} disableRipple />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value} />
            </StyledListItemButton>
          );
        })}
      </List>
    </StyledPaper>
  );

  return (
    <Grid container spacing={2} justifyContent='center' alignItems='center'>
      <Grid item>{customList(left)}</Grid>
      <Grid item>
        <Grid container direction='column' alignItems='center'>
          <Button
            sx={{ my: 0.5 }}
            color='primary'
            variant='contained'
            size='small'
            onClick={handleAllRight}
            disabled={left.length === 0}
          >
            ≫
          </Button>
          <Button
            sx={{ my: 0.5 }}
            color='primary'
            variant='contained'
            size='small'
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            color='primary'
            variant='contained'
            size='small'
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
          >
            &lt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            color='primary'
            variant='contained'
            size='small'
            onClick={handleAllLeft}
            disabled={right.length === 0}
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList(right)}</Grid>
    </Grid>
  );
}
