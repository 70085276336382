import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, ExpandMoreIcon } from '@breathelife/mui';
import { ApplicationSupportESignatureParticipant, ESignSignerStatus } from '@breathelife/types';

import { ESignSignerStatusBadge } from '../../../../../Components/Badge/ESignSignerStatusBadge';
import { ContainerWithCopyButton } from '../../../../../Components/ContainerWithCopyButton/ContainerWithCopyButton';
import Typography from '../../../../../Components/Typography';
import { useDispatch } from '../../../../../Hooks';
import { notificationSlice } from '../../../../../ReduxStore/Notification/NotificationSlice';

export function ActiveParticipants(props: { participants: ApplicationSupportESignatureParticipant[] }): ReactElement {
  const { t } = useTranslation();
  const { participants } = props;
  return (
    <Box mb={3}>
      <Box mb='1rem'>
        <Typography variant='h2'>{t('applicationSupport.eSignature.activeParticipants')}</Typography>
      </Box>
      <Divider />
      {!participants.length && (
        <Box mt='1rem'>
          <Typography variant='body5'>{t('applicationSupport.eSignature.noParticipantsFound')}</Typography>
        </Box>
      )}
      {!!participants.length &&
        participants.map((participant, index) => (
          <ActiveParticipant key={`participant${participant.id}`} index={index} participant={participant} />
        ))}
    </Box>
  );
}

function ActiveParticipant(props: {
  participant: ApplicationSupportESignatureParticipant;
  index: number;
}): ReactElement {
  const { t } = useTranslation();
  const { participant, index } = props;
  const { email, roles, status } = participant;

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box display='flex' flexDirection='row' mr={2}>
          <Typography variant='h3'>
            {t('applicationSupport.eSignature.participant', { participantNumber: (index + 1).toString() })}
          </Typography>
        </Box>
      </AccordionSummary>
      <Divider />
      <AccordionDetails>
        <Box display='flex' width='100%' alignItems='center' justifyContent='left'>
          <ParticipantEmail email={email} />
          <ParticipantRoles roles={roles} />
          <SignatureStatus status={status} />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

function ParticipantEmail(props: { email: string }): ReactElement {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { email } = props;

  const notifyEmailCopied = (data: string): void => {
    dispatch(
      notificationSlice.actions.setSuccess({
        message: t('notifications.dataCopied', { data }),
        autoHideDuration: 3000,
      }),
    );
  };

  return (
    <ContainerWithCopyButton
      inputLabel={t('applicationSupport.eSignature.emailAddress')}
      inputValue={email}
      onClickCallback={notifyEmailCopied}
      mr={3}
      p={1}
      width='18rem'
      options={{
        buttonPosition: 'inside',
      }}
    />
  );
}

function ParticipantRoles(props: { roles: any[] }): ReactElement {
  const { t } = useTranslation();
  const { roles } = props;
  const displayRolesString = (): string => {
    return roles.map((role: any) => t(`participantRole.${role.type}`)).join(', ');
  };

  return (
    <Box display='flex' alignItems='center' p={1} mr={3}>
      <Typography variant='body5'>
        {t('applicationSupport.eSignature.assignedRoles')}: {displayRolesString()}
      </Typography>
    </Box>
  );
}

function SignatureStatus(props: { status: ESignSignerStatus | undefined }): ReactElement {
  const { t } = useTranslation();
  const { status } = props;
  return (
    <Box display='flex' alignItems='center' p={1}>
      <Typography variant='body5'>{t('applicationSupport.eSignature.signatureStatus')}:</Typography>

      <Box display={'inline-block'} ml={1}>
        <ESignSignerStatusBadge status={status} />
      </Box>
    </Box>
  );
}
