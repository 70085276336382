import { ReactElement } from 'react';

import { Box, ListItemText } from '@breathelife/mui';
import Typography from '../../../../Typography';

import { useTheme } from '../../../../../Styles/themed-styled-components';

import { DisplayNameTypography, SubtitleContainer } from './Styles';

type BasicStoredFileProps = {
  icon?: ReactElement;
  rightCtas?: ReactElement;
  warning?: string;
  title: string;
  subtitle?: string;
  subtitle2?: string;
  bottomCtas?: ReactElement;
};

export function BasicStoredFile(props: BasicStoredFileProps): ReactElement {
  const { icon, subtitle, subtitle2, rightCtas, warning, title, bottomCtas } = props;

  const theme = useTheme();

  return (
    <ListItemText>
      <Box display='flex' flexDirection='row' width='100%' justifyContent='space-between'>
        <Box display='flex' flexDirection='row'>
          {icon && (
            <Box mr={2} minWidth='40px' maxWidth='40px' display='flex' justifyContent='center'>
              {icon}
            </Box>
          )}
          <div>
            <div>
              <DisplayNameTypography variant='h4'>{title}</DisplayNameTypography>
            </div>
            {subtitle && (
              <div>
                <DisplayNameTypography variant='body5'>{subtitle}</DisplayNameTypography>
              </div>
            )}
            {subtitle2 && (
              <div>
                <SubtitleContainer>
                  <Typography variant='body5'>{subtitle2}</Typography>
                </SubtitleContainer>
              </div>
            )}
            {warning && (
              <Typography variant='body5' color={theme.colors.red[60]}>
                {warning}
              </Typography>
            )}
            {bottomCtas && <Box mt={1}>{bottomCtas}</Box>}
          </div>
        </Box>

        {rightCtas && <Box>{rightCtas}</Box>}
      </Box>
    </ListItemText>
  );
}
