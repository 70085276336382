import { Box } from '@breathelife/mui';
import { ReactElement, Fragment, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { generateApplicationSupportOverviewUrl } from '../../../Navigation/Urls';

import { ApplicationsContextProvider } from './Applications/ApplicationsContextProvider';
import { ApplicationsTable } from './Applications/ApplicationsTable';

export function ApplicationsTablePage(): ReactElement {
  return (
    <ApplicationsContextProvider>
      <ApplicationsViewContainer />
    </ApplicationsContextProvider>
  );
}

export function ApplicationsViewContainer(): ReactElement {
  const navigate = useNavigate();
  const onOpenApplicationSupportView = useCallback(
    (applicationId: string) => {
      if (!applicationId) return;
      navigate(generateApplicationSupportOverviewUrl(applicationId));
    },
    [navigate],
  );

  return (
    <Fragment>
      <Box mx={2}>
        <ApplicationsTable onOpenApplicationSupportView={onOpenApplicationSupportView} />
      </Box>
    </Fragment>
  );
}
