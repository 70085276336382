import { Box } from '@breathelife/mui';
import ReactHtmlParser from 'html-react-parser';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { SignatureType, OutcomeCode } from '@breathelife/types';

import { SubmissionSuccess as SubmissionSuccessImage } from '../../../../Assets/SubmissionSuccess';
import { DownloadPdfButton } from '../../../../Components/AssistedApplication/AssistedApplicationView/DownloadPdfButton/DownloadPdfButton';
import { GreyContainer } from '../../../../Components/AssistedApplication/Styles';
import Typography from '../../../../Components/Typography';
import { SubmissionDetails } from '../SubmissionDetail/SubmissionDetails';

type SubmissionViewProps = {
  signatureType: SignatureType;
  confirmationNumber?: string;
  cryptoSignature?: string;
  outcome?: OutcomeCode;
};

export function SubmissionView(props: SubmissionViewProps): ReactElement {
  const { signatureType, cryptoSignature, confirmationNumber, outcome } = props;
  const { t } = useTranslation();

  if (outcome && [OutcomeCode.denied, OutcomeCode.blocked, OutcomeCode.blockedAtQuoter].includes(outcome)) {
    return (
      <Box>
        <Box mt={2} mb={4}>
          <Typography variant='body3'>
            {ReactHtmlParser(
              t(`modals.assistedApplication.submitApplicationView.submissionView.responses.denied.body`),
            )}
          </Typography>
        </Box>

        <GreyContainer my={3} p={2}>
          <Typography variant='body3'>
            <strong>
              {t(`modals.assistedApplication.submitApplicationView.submissionView.responses.denied.status`)}
            </strong>
            {t(`modals.assistedApplication.submitApplicationView.submissionView.responses.denied.outcomes.${outcome}`)}
          </Typography>
        </GreyContainer>
      </Box>
    );
  }

  return (
    <Box textAlign='center' mt={1}>
      <Box lineHeight={0} my={3}>
        <SubmissionSuccessImage />
      </Box>
      <Box mb={4}>
        <Typography variant='h3'>
          {outcome
            ? ReactHtmlParser(
                t(`modals.assistedApplication.submitApplicationView.submissionView.responses.${outcome}.title`),
              )
            : ReactHtmlParser(
                t(`modals.assistedApplication.submitApplicationView.submissionView.responses.accepted.title`),
              )}
        </Typography>
      </Box>
      <Box mb={4}>
        <Typography variant='body3'>
          {outcome
            ? ReactHtmlParser(
                t(`modals.assistedApplication.submitApplicationView.submissionView.responses.${outcome}.body`),
              )
            : ReactHtmlParser(
                t(`modals.assistedApplication.submitApplicationView.submissionView.responses.accepted.body`),
              )}
        </Typography>
      </Box>

      <SubmissionDetails
        signatureType={signatureType}
        confirmationNumber={confirmationNumber}
        cryptoSignature={cryptoSignature}
      />
      <DownloadPdfButton iconName='link' mb={4}>
        {t('modals.assistedApplication.submitApplicationView.submissionView.downloadPdf')}
      </DownloadPdfButton>
    </Box>
  );
}
