import {
  Language,
  LeadMarketingMetadata,
  User,
  Answers,
  LeadStatusEnum,
  LeadStatus,
  LeadStatusesKeys,
  ESignCeremonyStatus,
} from '@breathelife/types';

import { Application } from '../Models/Application';
import { LeadNote } from '../Models/LeadsNote';

export enum TimeWindowToCall {
  TenTwelve = '10-12',
  TwelveTwo = '12-2',
  TwoFour = '2-4',
  FourSix = '4-6',
  SixEight = '6-8',
}

export type LeadComputedProps = {
  // FIXME: When lead will have multiple appliations, progress should not be associated
  // to a lead since its an application that has a progress.
  progress: number;
  ['leads-communication']: BaseLeadCommunication;
  applications: Application[];
  ['leads-notes']: LeadNote[];
  ['leads-histories']: LeadsHistory[];
  user?: Partial<User>;
};

// TODO Find a way for this BaseLead type to come from the carrier
export type BaseLead = LeadComputedProps & {
  id: number;
  firstName: string;
  lastName: string;
  phoneNumber?: string;
  bestMomentToCall?: TimeWindowToCall;
  language?: Language; // Copy of the application's language (only used in BNC)
  product?: string;
  assignedToId?: string;
  status: LeadStatus;
  city?: string;
  email?: string;
  archived: boolean;
  createdAt: string;
  updatedAt: string;
  contacted: boolean;
  orderId?: string;
  submittedAt?: string;
  policyNumber?: string;
  transactionDate?: string;
  steps?: any[];
  postalCode?: string;
  region?: string;
  communicationLanguage: Language;
  referralCode?: string;
  // TODO: remove when lead can have multiple applications
  application?: Application;
  applicationId: string;
  applicationRefNo?: string;
  marketingMetadata?: LeadMarketingMetadata;
  ownerDetails?: string | null;
};

export type Lead = BaseLead & {
  email: string;
};

export type ImportableLead = Partial<BaseLead> & {
  answers?: Answers;
};

export type LeadCommunicationMethod = 'email' | 'phone';

export type BaseLeadCommunication = {
  preferredMethods: LeadCommunicationMethod[];
  ['leads-communication-schedules']?: BaseLeadCommunicationSchedule[];
};

export type BaseLeadCommunicationSchedule = {
  day: number;
  time: [number, number][];
};

type StatusData = {
  label: LeadStatusesKeys;
};
export type LeadStatusesColumnData = {
  [status in LeadStatusEnum]?: StatusData;
};

export type LeadSignatureStatusesColumnData = Partial<Record<ESignCeremonyStatus, { label: string }>>;

export type LeadsHistory = {
  id: string;
  leadId: number;
  userId: string | null;
  eventName: string; // TODO: use event name list from backend-types
  updatedAt: string;
  createdAt: string;
  user?: Pick<User, 'auth0Id' | 'firstName' | 'lastName' | 'emailLogin'>;
};
