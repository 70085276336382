import { ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { LeadDetailTab, Permission } from '@breathelife/types';
import { AddIcon } from '@breathelife/mui';

import { useDispatch } from '../../Hooks';
import { openRightPanel } from '../../ReduxStore/Layout/LayoutOperations';
import { DetailViewType } from '../../Models/Layout';
import { RestrictedToUserPermissions } from '../Restricted/RestrictedToUserPermissions';
import { ActionButton } from '../Button/ActionButton';

export function CreateLeadButton(props: { idSuffix?: string }): ReactElement {
  const { idSuffix } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const openLeadDrawer = useCallback(() => {
    dispatch(openRightPanel(DetailViewType.create, LeadDetailTab.personalInfo));
  }, [dispatch]);

  const dataTestId = idSuffix ? `addNewLeadBtn-${idSuffix}` : `addNewLeadBtn`;

  return (
    <RestrictedToUserPermissions requiredPermissions={[Permission.LeadCreate]}>
      <ActionButton
        data-testid={dataTestId}
        color='primary'
        variant='contained'
        onClick={() => openLeadDrawer()}
        startIcon={<AddIcon htmlColor='white' />}
      >
        {t('leadsListTable.addNew')}
      </ActionButton>
    </RestrictedToUserPermissions>
  );
}
