import { Box } from '@breathelife/mui';
import { useTranslation } from 'react-i18next';

import { User } from '@breathelife/types';
import { Input as TextInput } from '@breathelife/ui-components';

import { SubmitButton } from '../../../../Components/Button/SubmitButton';
import Typography from '../../../../Components/Typography';
import { ModalLayout } from '../../../../Layouts/Modal/ModalLayout';
import { useSendInvitationEmailMutation } from '../../../../ReactQuery/Admin/Users/users.mutations';

type Props = {
  user: User;
  isOpen: boolean;
  closeModal: () => void;
};

export function SendInvitationEmailModal(props: Props) {
  const { t } = useTranslation();
  const sendInvitationEmailMutation = useSendInvitationEmailMutation(props.user);
  return (
    <ModalLayout
      maxWidth='xs'
      title={t('modals.sendUserInvitationModal.title')}
      isOpen={props.isOpen}
      closeModal={() => props.closeModal()}
      submitButton={
        <SubmitButton
          isLoading={sendInvitationEmailMutation.isLoading}
          disabled={sendInvitationEmailMutation.isLoading}
          onClick={() => sendInvitationEmailMutation.mutate()}
        >
          {t('cta.sendTheReminder')}
        </SubmitButton>
      }
    >
      <Typography variant='body1' grey={70}>
        {t('modals.sendUserInvitationModal.willBeSentTo')}
      </Typography>
      <Box mt={2.5} mb={3.5}>
        <Typography variant='h3' component='span' grey={90}>
          {props.user.firstName} {props.user.lastName}
        </Typography>
      </Box>
      <Box mb={6.5}>
        <TextInput label={t('inputs.email')} value={props.user.emailLogin} inputVariant='outlined' disabled required />
      </Box>
    </ModalLayout>
  );
}
