import { useTranslation } from 'react-i18next';
import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { ImportCopyDeckResponse, SectionPartIdentifier } from '@breathelife/types';

import { QueryId } from '../../../ReactQuery/common/common.types';
import { notificationSlice } from '../../../ReduxStore/Notification/NotificationSlice';
import ApiService from '../../../Services/ApiService';
import { QuestionnaireVersionDetailWithNodeIdInfo } from '../../../Services/QuestionnaireVersionService';

async function importCopyDeck({
  parentPartIdentifier,
  questionnaireVersionId,
  copyDeckCSV,
}: {
  parentPartIdentifier: SectionPartIdentifier;
  questionnaireVersionId: string;
  copyDeckCSV: string;
}): Promise<ImportCopyDeckResponse | void> {
  try {
    const { data } = await ApiService.admin.uploadCopyDeck(
      {
        questionnaireVersionId,
      },
      { copyDeckCSV, parentPartIdentifier },
    );
    if (data) {
      return data;
    }
  } catch (error: any) {
    throw new Error('There was a problem with the pdf upload');
  }
}

export function useImportCopyDeckMutation(
  options?: UseMutationOptions<
    ImportCopyDeckResponse | void,
    unknown,
    { parentPartIdentifier: SectionPartIdentifier; questionnaireVersionId: string; copyDeckCSV: string }
  >,
): UseMutationResult<
  ImportCopyDeckResponse | void,
  unknown,
  { parentPartIdentifier: SectionPartIdentifier; questionnaireVersionId: string; copyDeckCSV: string }
> {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation<
    ImportCopyDeckResponse | void,
    unknown,
    { parentPartIdentifier: SectionPartIdentifier; questionnaireVersionId: string; copyDeckCSV: string },
    unknown
  >(
    ({ parentPartIdentifier, questionnaireVersionId, copyDeckCSV }) =>
      importCopyDeck({ parentPartIdentifier, questionnaireVersionId, copyDeckCSV }),
    {
      onError: (): void => {
        dispatch(
          notificationSlice.actions.setSuccess({
            message: t('notifications.failedToImportData'),
            autoHideDuration: 3000,
            dataTestId: 'notification-failed-to-import-copy-deck',
          }),
        );
      },
      onSuccess: async (data, variables, context) => {
        if (data?.blueprint) {
          queryClient.setQueryData<QuestionnaireVersionDetailWithNodeIdInfo | undefined>(
            [QueryId.questionnaireVersion, variables.questionnaireVersionId],
            (queryData) => {
              if (queryData && data.blueprint) {
                return { ...queryData, blueprint: data.blueprint };
              }
              return queryData;
            },
          );
        }

        dispatch(
          notificationSlice.actions.setSuccess({
            message: t('notifications.importedDataSuccess'),
            autoHideDuration: 3000,
            dataTestId: 'notification-import-copy-deck-success',
          }),
        );

        if (options?.onSuccess) {
          await options.onSuccess(data, variables, context);
        }
      },
    },
  );
}
