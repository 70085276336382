import { ApiESignAdHocBaseRequest, ApiESignAdHocFullRequest } from '@breathelife/types';
import ApiService from './ApiService';

export async function findAllAddHocSignatureRequests(): Promise<ApiESignAdHocBaseRequest[]> {
  const response = await ApiService.admin.findAllAdHocSignatureRequests();

  return response.data;
}

export async function getAdHocSignatureRequest(
  adHocSignatureRequestId: string,
): Promise<ApiESignAdHocFullRequest | undefined> {
  const response = await ApiService.admin.getAdHocSignatureRequest(adHocSignatureRequestId);
  return response.data;
}

export async function startAdHocSignatureRequest(adHocSignatureRequestId: string): Promise<void> {
  await ApiService.admin.startAdHocSignatureRequest(adHocSignatureRequestId);
}

export async function restartAdHocSignatureRequest(adHocSignatureRequestId: string): Promise<void> {
  await ApiService.admin.restartAdHocSignatureRequest(adHocSignatureRequestId);
}

export async function cancelAdHocSignatureRequest(adHocSignatureRequestId: string): Promise<void> {
  await ApiService.admin.cancelAdHocSignatureRequest(adHocSignatureRequestId);
}
