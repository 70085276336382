import { createGlobalStyle, css } from './themed-styled-components';

import { SharedTheme } from '@breathelife/ui-components';

import { normalizer } from './Normalizer';

export const fontsFamilies = [
  '"-apple-system"', // "San Francisco", macOS / iOS default
  '"BlinkMacSystemFont"', // macOS / iOS default (Chrome only)
  '"Helvetica Neue"', // macOS / iOS default (older OSes)
  '"Segoe UI"', // Windows default
  '"Roboto"', // Android default
  '"sans-serif"',
].join(',');

// This is needed to override SemanticUI font family which is used in direct
const overrideSemanticUiFont = css`
  & {
    body {
      :not(#monaco-wrapper) {
        font-family: ${fontsFamilies} !important;
      }
    }
  }
`;

export const GlobalStyle = createGlobalStyle<SharedTheme>`
  ${normalizer},
  ${overrideSemanticUiFont},
`;
