import { ReactElement, Fragment, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { Grid } from '@breathelife/mui';
import {
  IconName,
  Localizable,
  PlatformType,
  QuestionnaireBlueprintRenderOn,
  SubsectionBlueprint,
  SubsectionVariant,
  QuestionnaireBlueprintCopyableOption,
} from '@breathelife/types';
import { AutocompleteOption, Input as TextInput, SimpleCheckbox } from '@breathelife/ui-components';

import { SubmitButton } from '../../../../../Components/Button/SubmitButton';
import Typography from '../../../../../Components/Typography';
import {
  dataLabelSelectionToDataLabel,
  getSubsectionVariantOptions,
} from '../../../../../Helpers/questionnaireEditor/selectOptions';
import { buildSubsectionVariantBlueprint } from '../../../../../Helpers/questionnaireEditor/subsectionVariant';
import { useCarrierContext } from '../../../../../Hooks';
import { ModalLayout } from '../../../../../Layouts/Modal/ModalLayout';
import { AdvancedBlueprintOptions } from '../Components/AdvancedBlueprintOptions';
import { EditorDropdown } from '../Components/EditorDropdown';
import { IconNameSelector } from '../Components/IconNameSelector';

const VARIANTS_REQUIRING_WARNING = [SubsectionVariant.addon, SubsectionVariant.submission];

export function CreateSubsectionModal(props: {
  onCloseModal: () => void;
  onCreate: (blueprint: SubsectionBlueprint) => void;
  dataLabelOptions: AutocompleteOption[];
}): ReactElement {
  const { onCloseModal, onCreate, dataLabelOptions } = props;
  const { t } = useTranslation();
  const { languageSettings, features } = useCarrierContext();
  const enabledLanguages = languageSettings.enabledLanguages;
  const [title, setTitle] = useState<Partial<Localizable>>({});
  const [text, setText] = useState<Partial<Localizable>>({});
  const [iconName, setIconName] = useState<IconName>();
  const [showInNavigation, setShowInNavigation] = useState(false);
  const [dataLabel, setDataLabel] = useState<string>(dataLabelOptions?.length ? dataLabelOptions[0].value : '');
  const [renderOn, setRenderOn] = useState<QuestionnaireBlueprintRenderOn[]>([]);
  const [platforms, setPlatforms] = useState<PlatformType[]>([]);
  const [copyable, setCopyable] = useState<QuestionnaireBlueprintCopyableOption>(
    QuestionnaireBlueprintCopyableOption.none,
  );
  const [subsectionVariantType, setSubsectionVariantType] = useState<string>('');
  const [isSimpleQuoter, setIsSimpleQuoter] = useState<boolean>(false);
  const [submissionVariantDisplayInline, setSubmissionVariantDisplayInline] = useState<boolean>(false);
  const [submissionVariantHideHeader, setSubmissionVariantHideHeader] = useState<boolean>(false);

  const subsectionVariantOptions = getSubsectionVariantOptions(t, features);

  const onSubmit = useCallback(() => {
    const blueprintDataLabel = dataLabelSelectionToDataLabel(dataLabel);

    const subsectionVariant = buildSubsectionVariantBlueprint(subsectionVariantType, {
      isSimpleQuoter,
      submissionVariantDisplayInline,
      submissionVariantHideHeader,
    });

    const subsectionBlueprint: SubsectionBlueprint = {
      id: uuid(),
      partName: `custom-${uuid()}`,
      text,
      title,
      iconName,
      variant: subsectionVariant,
      dataLabel: blueprintDataLabel,
      isCustom: true,
      questions: [],
      showInNavigation,
      copyable,
    };

    if (renderOn.length) subsectionBlueprint.renderOn = renderOn;
    if (platforms.length) subsectionBlueprint.platforms = platforms;

    onCreate(subsectionBlueprint);
    onCloseModal();
  }, [
    text,
    title,
    iconName,
    showInNavigation,
    dataLabel,
    subsectionVariantType,
    onCloseModal,
    onCreate,
    renderOn,
    platforms,
    isSimpleQuoter,
    submissionVariantDisplayInline,
    submissionVariantHideHeader,
  ]);

  return (
    <ModalLayout
      maxWidth='md'
      isOpen
      closeModal={onCloseModal}
      title={t('admin.questionnaireManagement.input.createSubsection.title')}
      submitButton={
        <SubmitButton onClick={onSubmit} data-testid='questionnaire-editor-subsection-create'>
          {t('cta.save')}
        </SubmitButton>
      }
    >
      <Grid container spacing={2} alignItems='center'>
        {enabledLanguages.map((language) => (
          <Grid item xs={6} key={`createSubsection-title-${language}`}>
            <TextInput
              inputVariant='outlined'
              label={t('admin.questionnaireManagement.input.titleAndLanguage', { language: t(`language.${language}`) })}
              value={title[language] ?? ''}
              onChange={(event) => {
                const value = event.target.value;
                setTitle((previousValue) => ({ ...previousValue, [language]: value }));
              }}
            />
          </Grid>
        ))}
        {enabledLanguages.map((language) => (
          <Grid item xs={12} key={`createSubsection-text-${language}`}>
            <TextInput
              inputVariant='outlined'
              label={t('admin.questionnaireManagement.input.textAndLanguage', { language: t(`language.${language}`) })}
              multiline={true}
              value={text[language] ?? ''}
              onChange={(event) => {
                const value = event.target.value;
                setText((previousValue) => ({ ...previousValue, [language]: value }));
              }}
            />
          </Grid>
        ))}

        <Grid item xs={12}>
          <IconNameSelector
            label={t('admin.questionnaireManagement.input.subsectionIcon')}
            value={iconName}
            onChange={setIconName}
            disabled={false}
          />
        </Grid>
        <Grid item xs={12} key='createSubsection-showInNavigation'>
          <SimpleCheckbox
            id='createSubsection-showInNavigation'
            label={t('admin.questionnaireManagement.input.showInNavigation')}
            checked={showInNavigation}
            onChange={(event) => {
              setShowInNavigation(event.target.checked);
            }}
          />
        </Grid>
        <Grid container item spacing={2} alignItems='flex-start'>
          <Grid item xs={6} key={`createSubsection-subsectionVariant`}>
            <EditorDropdown
              id='addSubsectionDataLabelDropdown'
              label={t('admin.questionnaireManagement.input.subsectionVariant')}
              selectedOptionValue={subsectionVariantType}
              onChange={(value) => {
                if (value !== null) {
                  setSubsectionVariantType(value);
                }
              }}
              options={subsectionVariantOptions}
            />
          </Grid>
          {subsectionVariantType === SubsectionVariant.quoter && (
            <Grid item xs={6} key='createSubsection-subsectionVariant-quoter-simple'>
              <SimpleCheckbox
                id='addQuoterSubsectionVariantSimple'
                label={t('admin.questionnaireManagement.subsectionVariants.simpleQuoter')}
                checked={isSimpleQuoter}
                onChange={(event) => setIsSimpleQuoter(event.target.checked)}
              />
            </Grid>
          )}
          {VARIANTS_REQUIRING_WARNING.includes(subsectionVariantType as SubsectionVariant) && (
            <Grid
              container
              item
              xs={6}
              spacing={1}
              key='createSubsection-subsectionVariant-submission-hideHeader'
              alignItems='flex-start'
            >
              {subsectionVariantType === SubsectionVariant.submission && (
                <Fragment>
                  <Grid item xs={12}>
                    <SimpleCheckbox
                      id='subsectionVariantHideHeader'
                      label={t('admin.questionnaireManagement.subsectionVariants.hideHeader')}
                      checked={submissionVariantHideHeader}
                      onChange={(event) => setSubmissionVariantHideHeader(event.target.checked)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SimpleCheckbox
                      id='subsectionVariantDisplayInline'
                      label={t('admin.questionnaireManagement.subsectionVariants.displayInline')}
                      checked={submissionVariantDisplayInline}
                      onChange={(event) => setSubmissionVariantDisplayInline(event.target.checked)}
                    />
                  </Grid>
                </Fragment>
              )}
              <Grid item xs={12}>
                <Typography variant='small1'>
                  {t('admin.questionnaireManagement.subsectionVariants.variantWarning')}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <AdvancedBlueprintOptions
            tag='subsection'
            platform={{ selected: platforms, onChange: setPlatforms }}
            renderOn={{ selected: renderOn, onChange: setRenderOn }}
            dataLabel={{
              selected: dataLabel,
              options: dataLabelOptions,
              onChange: (value) => {
                if (value !== null) {
                  setDataLabel(value);
                }
              },
            }}
            copyable={{
              selected: copyable,
              onChange: (value) => {
                if (value !== null) {
                  setCopyable(value as QuestionnaireBlueprintCopyableOption);
                }
              },
            }}
            disabled={false}
          />
        </Grid>
      </Grid>
    </ModalLayout>
  );
}
