import { AxiosError } from 'axios';
import { ReactElement, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { hash } from '@breathelife/hash';
import { getDocumentFormat, TypewriterTracking } from '@breathelife/frontend-tracking';
import { FileTemplate, StoredFileDocType } from '@breathelife/types';

import { Drawer } from '../../../../Components/Drawer/Drawer';
import { isFileNameValid, SPECIAL_CHARS } from '../../../../Helpers/files/validateFileName';
import { convertFileSize, FileSizeUnits, printFileSize } from '../../../../Helpers/fileSize';
import { useDispatch, useModalState } from '../../../../Hooks';
import { Application } from '../../../../Models/Application';
import {
  useCreateApplicationFileMutation,
  useDeleteApplicationFileMutation,
} from '../../../../ReactQuery/Application/application.mutations';
import { notificationSlice } from '../../../../ReduxStore/Notification/NotificationSlice';
import { CreateApplicationFileData } from '../../../../Services/ApplicationFileService';
import { DocumentDrawerBody } from './DocumentsDrawerBody';
import { DocumentsDrawerHeader } from './DocumentsDrawerHeader';
import { ConfirmDeleteModal } from './Files/ConfirmDeleteModal';
import { UploadError } from './Files/UploadError';

import Typography from '../../../Typography';
import { AlertTitle, Box } from '@breathelife/mui';
import { Alert } from '../../../Alert/Alert';
import { useApplicationStoredFilesContext, MAX_TOTAL_FILE_SIZE_MB } from './ApplicationStoredFilesContext';

type DocumentsDrawerProps = {
  application: Application;
  isOpen: boolean;
  onClose: () => void;
};

// Maximum upload size per file is limited to 5MB
const MAX_FILE_SIZE_MB = 5;
const MAX_UPLOAD_SIZE_B = convertFileSize(MAX_FILE_SIZE_MB, FileSizeUnits.MB, FileSizeUnits.B);
const MIN_FILE_SIZE_MB = 0;
const MIN_UPLOAD_SIZE_B = convertFileSize(MIN_FILE_SIZE_MB, FileSizeUnits.MB, FileSizeUnits.B);

export function DocumentsDrawer(props: DocumentsDrawerProps): ReactElement {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { totalFileSize, isMaxTotalFileSizeReached } = useApplicationStoredFilesContext();
  const { isOpen, onClose, application } = props;
  const [isValidatingUpload, setIsValidatingUpload] = useState<boolean>(false);

  const totalFileSizeMessage = printFileSize(totalFileSize, 1);

  const createApplicationFileMutation = useCreateApplicationFileMutation({
    onMutate: () => setIsValidatingUpload(false),
    onSuccess: () => {
      dispatch(
        notificationSlice.actions.setSuccess({
          message: t('notifications.fileUploadSuccess'),
          autoHideDuration: 5000,
        }),
      );
    },
    onError: (error: AxiosError<any>) => {
      if (error.response?.status === 422) {
        dispatch(
          notificationSlice.actions.setError({
            message: t('notifications.unprocessablePDFHasFormFieldsThatRequireAppearances'),
          }),
        );
      } else if (error.response?.status === 409) {
        dispatch(
          notificationSlice.actions.setError({
            message: t('notifications.conflictFileAlreadyExists'),
          }),
        );
      } else {
        dispatch(
          notificationSlice.actions.setError({
            message: t([
              `notifications.fileUploadError.${error.response?.data.data?.code}`,
              'notifications.fileUploadError.general',
            ]),
            autoHideDuration: 5000,
          }),
        );
      }
    },
  });

  const deleteApplicationFileMutation = useDeleteApplicationFileMutation({
    onSuccess: useCallback(() => {
      dispatch(
        notificationSlice.actions.setSuccess({
          message: t('notifications.fileDeleteSuccess'),
          autoHideDuration: 5000,
        }),
      );
    }, [dispatch, t]),
    onError: useCallback(() => {
      dispatch(
        notificationSlice.actions.setError({
          message: t('notifications.fileDeleteError'),
          autoHideDuration: 5000,
        }),
      );
    }, [dispatch, t]),
  });

  const [isDeleteModalOpen, onOpenDeleteModalHook, onCloseDeleteModalHook] = useModalState();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [filePendingDelete, setFilePendingDelete] = useState<{ id: string; isRequired: boolean } | undefined>(
    undefined,
  );

  const onFileUploadError = useCallback(
    (file: File, errorMessage: string) => {
      setIsValidatingUpload(false);
      // TODO: do we need a new event?
      TypewriterTracking.attachmentErrorOccurred({
        hashedId: hash(application.id),
        documentFormat: getDocumentFormat(file),
        error: errorMessage,
      });
    },
    [application.id],
  );

  const onFileUpload = useCallback(
    (file: File, template?: FileTemplate) => {
      setIsValidatingUpload(true);

      if (file.size > MAX_UPLOAD_SIZE_B || file.size <= MIN_UPLOAD_SIZE_B) {
        const isMinimumSizeValidationFailure = file.size <= MIN_UPLOAD_SIZE_B;
        const errorMessageKey = isMinimumSizeValidationFailure
          ? 'errorUploadInvalidMinimumSize'
          : 'errorUploadLimitExceeded';
        const errorMessageVariables = {
          filename: file.name,
          size: printFileSize(isMinimumSizeValidationFailure ? MIN_UPLOAD_SIZE_B : MAX_UPLOAD_SIZE_B),
        };

        setErrorMessage(t(`modals.assistedApplication.fileAttachment.${errorMessageKey}`, errorMessageVariables));
        onFileUploadError(
          file,
          isMinimumSizeValidationFailure
            ? 'File failed to meet minimum size requirements.'
            : 'File exceeded upload size limit.',
        );
        return;
      }

      // File templates will have the template's localized name set as the file name in the signature package
      // As opposed to the uploaded's file name
      if (!template && !isFileNameValid(file.name)) {
        setErrorMessage(
          t('modals.assistedApplication.fileAttachment.errorUploadInvalidName', {
            specialChars: SPECIAL_CHARS,
          }),
        );
        onFileUploadError(file, 'Invalid file name.');
        return;
      }

      const newFileInput: CreateApplicationFileData = {
        file,
        applicationId: application.id,
        templateId: template?.id,
        docType: template?.docType ?? StoredFileDocType.Attachment,
      };
      createApplicationFileMutation.mutate(newFileInput);
    },
    [application.id, createApplicationFileMutation, onFileUploadError, t],
  );

  const onOpenDeleteModal = useCallback(
    (fileId: string, isRequired: boolean) => {
      setFilePendingDelete({ id: fileId, isRequired });
      onOpenDeleteModalHook();
    },
    [onOpenDeleteModalHook],
  );

  const onCloseDeleteModal = useCallback(() => {
    setFilePendingDelete(undefined);
    onCloseDeleteModalHook();
  }, [onCloseDeleteModalHook]);

  const onConfirmFileDeletion = useCallback(
    async (fileId: string) => {
      await deleteApplicationFileMutation.mutateAsync({ fileId, applicationId: application.id });
    },
    [application.id, deleteApplicationFileMutation],
  );

  const onCloseErrorAlert = useCallback(() => {
    setErrorMessage(null);
  }, []);

  const onDrawerBodyTabChange = useCallback(() => {
    setErrorMessage(null);
  }, []);

  const errorAlert = <UploadError message={errorMessage} handleClose={onCloseErrorAlert} />;

  return (
    <Drawer open={isOpen} onClose={onClose}>
      <DocumentsDrawerHeader onClose={onClose} />
      <Box mr={5} ml={5} mb={1}>
        {isMaxTotalFileSizeReached && (
          <Alert severity={'error'}>
            <AlertTitle>{t('modals.assistedApplication.fileAttachment.errorMaxFileSize')}</AlertTitle>
          </Alert>
        )}
      </Box>
      <Box mr={5} mb={1} display='flex' justifyContent='flex-end'>
        <Typography variant='body4' color={isMaxTotalFileSizeReached ? 'red' : 'black'}>
          {totalFileSizeMessage}/{MAX_TOTAL_FILE_SIZE_MB}
          MB uploaded
        </Typography>
      </Box>
      <DocumentDrawerBody
        application={application}
        ErrorAlert={errorAlert}
        isUploadDisabled={application.submitted}
        isUploading={createApplicationFileMutation.isLoading || isValidatingUpload}
        onFileDelete={onOpenDeleteModal}
        onFileUpload={onFileUpload}
        onTabChange={onDrawerBodyTabChange}
      />
      <ConfirmDeleteModal
        filePendingDelete={filePendingDelete}
        isOpen={isDeleteModalOpen}
        onClose={onCloseDeleteModal}
        onConfirm={onConfirmFileDeletion}
      />
    </Drawer>
  );
}
