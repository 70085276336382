import i18next from 'i18next';

export type SelectOptions<T> = { value: T; label: string }[];

export function getOptionsFromEnum<T>(enumObject: Record<string, T>, translationBasePath?: string): SelectOptions<T> {
  return Object.values(enumObject).map((value) => {
    return {
      value: value,
      label: i18next.t(translationBasePath ? `${translationBasePath}.${value}` : `${value}`),
    };
  });
}

/**
 * Takes an enum, returns and array of <Select> options
 * Can return options mapped to another set of values if given an object structured
 * like { key: { label: 'some custom label' } }
 * @param enumObject
 * @param options
 * @returns SelectOptions
 */
export function getCustomizedOptionsFromEnum<T extends string | number | symbol>(
  enumObject: Record<string, T>,
  options: {
    translationBasePath?: string;
    customizationMap?: Partial<{ [key in T]: { label: unknown } }>;
    withDefaultOption?: boolean;
    defaultOptionLabel?: string;
    whitelist?: string[];
  },
): SelectOptions<T> {
  const { translationBasePath, customizationMap, withDefaultOption, defaultOptionLabel, whitelist } = options;
  const defaultOption = {
    value: '' as T,
    label: defaultOptionLabel ?? '',
  };

  const customizedOptions = Object.keys(customizationMap || {})
    .filter((key) => enumObject[key] && (!whitelist || whitelist.includes(key)))
    .map((key) => {
      const value = enumObject[key];
      const status = customizationMap?.[value]?.label;

      return {
        value: value,
        label: i18next.t(translationBasePath ? `${translationBasePath}.${status}` : `${status}`),
      };
    });

  return [...(withDefaultOption ? [defaultOption] : []), ...customizedOptions];
}
