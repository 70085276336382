import { ReactElement } from 'react';

import { ApplicationSupportApplication, LeadDetailTab, User } from '@breathelife/types';

import { Lead } from '../Models/Lead';
import { Application } from './Application';

export enum DetailViewType {
  create = 'create',
  read = 'read',
  edit = 'edit',
}

export enum LeadTab {
  active,
  archived,
}

export enum ModalType {
  email = 'email',
  assign = 'assign',
  copyApplication = 'copyApplication',
  deleteLead = 'deleteLead',
  userEmail = 'userEmail',
  downloadFiles = 'downloadFiles',
  userCreate = 'userCreate',
  leadsListFilter = 'leadsListFilter',
  archiveConfirmation = 'archiveConfirmation',
  userEdit = 'userEdit',
  deleteUser = 'deleteUser',
  importUsers = 'importUsers',
  createFirm = 'createFirm',
  productPricing = 'productPricing',
  agentLicenses = 'agentLicenses',
  resetApplicationSubmissionStatus = 'resetApplicationSubmissionStatus',
}

export type TabProps = {
  title?: string;
  index: number;
  component?: (props: any) => ReactElement | null;
};

export type EnabledTabs = {
  [tab in LeadDetailTab]?: TabProps;
};

export type RightPanelState = {
  isOpen: boolean;
  type: DetailViewType;
  tab?: LeadDetailTab;
  lead?: Lead;
  user?: User;
};

export type ModalState = {
  isOpen: boolean;
  type: ModalType;
  lead?: Lead;
  user?: User;
  application?: Application;
  applicationSupportApplication?: ApplicationSupportApplication;
};

const pageLayoutPaddingY = 32;

const headerHeight = 48;
export const HEADER_HEIGHT = `${headerHeight}px`;

const listFilterHeight = 64;
export const LIST_FILTER_HEIGHT = `${listFilterHeight}px`;

const paginationHeight = 48;
export const PAGINATION_HEIGHT = `${paginationHeight}px`;

export const tableHeight = pageLayoutPaddingY + headerHeight + listFilterHeight + paginationHeight;

export const emptyTableHeight = tableHeight - paginationHeight;
