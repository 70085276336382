import { useState } from 'react';

import { FieldBlueprint, FieldTypes, ButtonFieldBlueprint, Language, BooleanFieldValidation } from '@breathelife/types';
import { AutocompleteOption } from '@breathelife/ui-components';

import {
  FieldDataFromBlueprintWithValidateAs,
  FieldDataHookReturnValueWithValidationOptions,
  FieldSettersValidateAs,
  LanguageDependentSetter,
  useCastBlueprintToFieldType,
} from '../../Helpers/helperTypes';
import { BaseFieldData, BaseFieldSetters, useBaseFieldData } from './useBaseFieldData';
import { useValidateAsData } from './useValidateAsData';

export type ButtonFieldData = FieldDataFromBlueprintWithValidateAs<ButtonFieldBlueprint, 'buttonText'> & BaseFieldData;

export type ButtonFieldSetters = FieldSettersValidateAs<ButtonFieldData> & {
  setButtonText: LanguageDependentSetter;
} & BaseFieldSetters;

const defaultProperties = {
  validateAs: BooleanFieldValidation.boolean,
} as const;

export function useButtonFieldData({
  initialData,
  dataLabelOptions,
}: {
  initialData?: Partial<FieldBlueprint>;
  dataLabelOptions: AutocompleteOption[];
}): FieldDataHookReturnValueWithValidationOptions<ButtonFieldBlueprint, ButtonFieldSetters> {
  const initialDataAsButtonField = useCastBlueprintToFieldType<ButtonFieldBlueprint>(FieldTypes.button, initialData);

  const { data, setters } = useBaseFieldData({ initialData, dataLabelOptions });

  const [buttonTextEn, setButtonTextEn] = useState<Required<ButtonFieldData['buttonText']>['en']>(
    initialDataAsButtonField?.buttonText?.en || '',
  );

  const [buttonTextFr, setButtonTextFr] = useState<Required<ButtonFieldData['buttonText']>['fr']>(
    initialDataAsButtonField?.buttonText?.fr || '',
  );

  const {
    data: validateAsData,
    setters: validateAsSetters,
    validationOptions,
  } = useValidateAsData<ButtonFieldBlueprint>({
    fieldType: FieldTypes.button,
    initialValidateAs: defaultProperties.validateAs,
  });

  const setButtonText: LanguageDependentSetter = (language, value) => {
    switch (language) {
      case Language.en:
        setButtonTextEn(value);
        break;
      case Language.fr:
        setButtonTextFr(value);
        break;
    }
  };

  return {
    data: {
      fieldType: FieldTypes.button,
      ...data,
      buttonText: {
        en: buttonTextEn,
        fr: buttonTextFr,
      },
      ...validateAsData,
    },
    setters: {
      ...setters,
      setButtonText,
      ...validateAsSetters,
    },
    validationOptions,
  };
}
