export const MAX_POSTAL_CODE_LENGTH = 6;

export function formatPostalCode(value: string): string {
  if (!value) return '';

  const sanitizedValue = value.replace(/[^a-z0-9]/gi, '');

  return sanitizedValue.length < 4
    ? sanitizedValue
    : `${sanitizedValue.slice(0, 3)} ${sanitizedValue.slice(3, MAX_POSTAL_CODE_LENGTH)}`;
}
