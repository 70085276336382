import { Box, Tooltip } from '@breathelife/mui';
import { Fragment, ReactElement, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ApplicationMode, Permission, SignatureType } from '@breathelife/types';
import { ImageTypes } from '@breathelife/ui-components';

import { ApplicationModeBadge } from '../../../Components/Badge/ApplicationModeBadge';
import { Button } from '../../../Components/Button/Button';
import { CloseButton } from '../../../Components/Button/CloseButton';
import { SubmitButton } from '../../../Components/Button/SubmitButton';
import { Icon } from '../../../Components/Icons';
import { RestrictedToUserPermissions } from '../../Restricted/RestrictedToUserPermissions';
import {
  useAAQuestionnaireVersionContext,
  useAssistedApplicationContext,
  useCarrierContext,
  useSelector,
} from '../../../Hooks';
import { IdentityVerificationPopper } from '../Popper/IdentityVerification/IdentityVerificationPopper';
import { LicenseVerificationPopper } from '../Popper/LicenseVerificationPopper/LicenseVerificationPopper';
import { DocumentsDrawerButton } from './DocumentsDrawerButton';
import { HeaderTabBar } from './HeaderTabBar';
import {
  HeaderButtonContainer,
  HeaderTitle,
  HeaderTitleContainer,
  HeaderWrapper,
  HeaderWrapperContainer,
  CloseButtonContainer,
} from './Styles';
import { useApplicationStoredFilesContext } from '../Drawers/Documents/ApplicationStoredFilesContext';

type AssistedApplicationHeaderProps = {
  applicantName: string;
  isApplicantMinor?: boolean;
  applicationSignatureType?: SignatureType;
  close: () => void;
  isPreview: boolean;
  isSubmitButtonDisabled: boolean;
  isSubmitButtonVisible: boolean;
  applicationMode: ApplicationMode;
  onSubmitApplication: () => void;
  onOpenESignatureDetails: () => void;
  onOpenFileAttachmentModal: () => void;
  onOpenDocumentsDrawer: () => void;
  triggerIdentityVerification: () => void;
  onOpenInstantIdReportDrawer: () => void;
  onOpenSubmitPaperAppModal: () => void;
};

enum SubmissionButtonFlow {
  default = 'default',
  esignature = 'esignature',
  paperApp = 'paperApp',
  esignatureSubmitted = 'esignatureSubmitted',
}

function AssistedApplicationHeader(props: AssistedApplicationHeaderProps): ReactElement {
  const { applicationLineOfBusiness } = useAssistedApplicationContext();
  const { isSectionGroupsTabsEnabled } = useAAQuestionnaireVersionContext();
  const { features } = useCarrierContext();
  const { t } = useTranslation();

  const {
    applicantName,
    isApplicantMinor = false,
    applicationSignatureType,
    close,
    isPreview,
    isSubmitButtonDisabled: isSubmitButtonDisabledProps,
    isSubmitButtonVisible,
    applicationMode,
    onSubmitApplication,
    onOpenESignatureDetails,
    onOpenFileAttachmentModal,
    onOpenDocumentsDrawer,
    triggerIdentityVerification,
    onOpenInstantIdReportDrawer,
    onOpenSubmitPaperAppModal,
  } = props;

  const { hasIssueWithDocuments } = useApplicationStoredFilesContext();

  const isSubmitButtonDisabled = isSubmitButtonDisabledProps || hasIssueWithDocuments;

  const submissionButtonStatus = useMemo<SubmissionButtonFlow | undefined>(() => {
    if (!isSubmitButtonVisible) {
      return applicationMode !== ApplicationMode.paper && applicationSignatureType === SignatureType.eSignature
        ? SubmissionButtonFlow.esignatureSubmitted
        : undefined;
    }
    if (applicationMode === ApplicationMode.paper) {
      return !isPreview ? SubmissionButtonFlow.paperApp : undefined;
    }
    if (
      applicationSignatureType === SignatureType.cryptoSignature ||
      applicationSignatureType === SignatureType.externalSignature ||
      isPreview
    ) {
      return SubmissionButtonFlow.default;
    }
    if (applicationSignatureType === SignatureType.eSignature) {
      return SubmissionButtonFlow.esignature;
    }
    return undefined;
  }, [applicationMode, applicationSignatureType, isPreview, isSubmitButtonVisible]);

  const isApplicationSubmitting = useSelector((state) => state.leadPlatform.submission.isSubmittingApplication);

  const DOCUMENTS_DRAWER_ENABLED = features.assistedApplication?.documentsDrawer?.enabled === true;
  const SECTION_GROUP_TABS_ENABLED = isSectionGroupsTabsEnabled;
  const AGENT_LICENSE_ENABLED = features.agentLicenseValidation?.enabled ?? false;
  const FILES_DRAWER_ENABLED = features.assistedApplication.fileUpload?.allowedLinesOfBusiness
    ? features.assistedApplication.fileUpload.allowedLinesOfBusiness.includes(applicationLineOfBusiness)
    : true;

  const title = `${t('assistedApplication.title')}${applicantName ? ` - ${applicantName}` : ''}`;
  const tooltipTitles = {
    attachingDocumentsNotRequired: t('assistedApplication.attachingDocumentsNotRequired', {
      lineOfBusiness: t('admin.productManagement.options.lineOfBusiness.' + applicationLineOfBusiness),
    }),
  };

  const submitButton = submissionButtonStatus ? (
    <Fragment>
      {submissionButtonStatus === SubmissionButtonFlow.default && (
        <SubmitButton disabled={isSubmitButtonDisabled} onClick={onSubmitApplication}>
          {t('assistedApplication.submitApplication')}
        </SubmitButton>
      )}
      {submissionButtonStatus === SubmissionButtonFlow.esignature && (
        <RestrictedToUserPermissions requiredPermissions={[Permission.ApplicationFileRead]}>
          <SubmitButton
            data-testid='send-to-signature-button'
            disabled={isSubmitButtonDisabled}
            onClick={onOpenESignatureDetails}
          >
            {t('assistedApplication.sendToSignature')}
          </SubmitButton>
        </RestrictedToUserPermissions>
      )}
      {submissionButtonStatus === SubmissionButtonFlow.paperApp && (
        <SubmitButton
          isLoading={isApplicationSubmitting}
          disabled={isSubmitButtonDisabled}
          onClick={onOpenSubmitPaperAppModal}
        >
          {t('assistedApplication.submit')}
        </SubmitButton>
      )}
    </Fragment>
  ) : undefined;

  return (
    <HeaderWrapperContainer>
      <HeaderWrapper>
        <HeaderTitleContainer>
          <HeaderTitle variant='h2'>{title}</HeaderTitle>
          {applicationMode === ApplicationMode.paper && (
            <Box display='inline-block' ml='10px'>
              <ApplicationModeBadge mode={applicationMode} />
            </Box>
          )}
        </HeaderTitleContainer>
        {SECTION_GROUP_TABS_ENABLED && <HeaderTabBar />}
        <HeaderButtonContainer>
          <IdentityVerificationPopper
            triggerIdentityVerification={triggerIdentityVerification}
            onOpenInstantIdReportDrawer={onOpenInstantIdReportDrawer}
            isApplicantMinor={isApplicantMinor}
          />

          {AGENT_LICENSE_ENABLED && <LicenseVerificationPopper />}
          {applicationMode === ApplicationMode.digital &&
            (DOCUMENTS_DRAWER_ENABLED ? (
              <Tooltip title={!FILES_DRAWER_ENABLED ? tooltipTitles.attachingDocumentsNotRequired : ''}>
                <span>
                  <DocumentsDrawerButton
                    isDocumentsDrawerAlert={hasIssueWithDocuments}
                    isDisabled={!FILES_DRAWER_ENABLED}
                    onOpenDocumentsDrawer={onOpenDocumentsDrawer}
                  />
                </span>
              </Tooltip>
            ) : (
              <RestrictedToUserPermissions
                requiredPermissions={[
                  Permission.ApplicationFileCreate,
                  Permission.ApplicationFileRead,
                  Permission.ApplicationFileRemove,
                ]}
              >
                <Box>
                  <Tooltip title={!FILES_DRAWER_ENABLED ? tooltipTitles.attachingDocumentsNotRequired : ''}>
                    <span>
                      <Button
                        color='secondary'
                        variant='outlined'
                        onClick={onOpenFileAttachmentModal}
                        startIcon={<Icon name='files' imageType={ImageTypes.decorative} />}
                        disabled={!FILES_DRAWER_ENABLED}
                      >
                        {t('assistedApplication.files')}
                      </Button>
                    </span>
                  </Tooltip>
                </Box>
              </RestrictedToUserPermissions>
            ))}
          {submissionButtonStatus === SubmissionButtonFlow.esignatureSubmitted && (
            <Box ml={2}>
              <Button
                color='secondary'
                variant='outlined'
                disabled={isSubmitButtonDisabled}
                onClick={onOpenESignatureDetails}
              >
                {t('assistedApplication.signaturesDetails')}
              </Button>
            </Box>
          )}
          {submitButton && (
            <Box ml={2}>
              {hasIssueWithDocuments ? (
                <Tooltip title={t('assistedApplication.cannotSubmitIssueWithFiles')} placement='bottom-end'>
                  <div>{submitButton}</div>
                </Tooltip>
              ) : (
                submitButton
              )}
            </Box>
          )}
        </HeaderButtonContainer>
      </HeaderWrapper>
      <CloseButtonContainer ml={5}>
        <CloseButton onClick={close} />
      </CloseButtonContainer>
    </HeaderWrapperContainer>
  );
}

export const MemoizedAssistedApplicationHeader = memo(AssistedApplicationHeader);
