import { useTranslation } from 'react-i18next';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { useDispatch } from 'react-redux';

import { logger } from '@breathelife/monitoring-frontend';

import { QueryId } from '../../../ReactQuery/common/common.types';
import { notificationSlice } from '../../../ReduxStore/Notification/NotificationSlice';

import { fetchAllQuestionnaireNodeIds } from '../../../Services/QuestionnaireService';

export function useFetchAllQuestionnaireNodeIdsQuery(options?: UseQueryOptions<string[]>): UseQueryResult<string[]> {
  const queryId = [QueryId.questionnaireNodeIds];
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return useQuery<string[]>(queryId, fetchAllQuestionnaireNodeIds, {
    ...options,
    onError: (err) => {
      dispatch(
        notificationSlice.actions.setError({
          message: t('notifications.failedToFetchNodeIds'),
        }),
      );
      logger.error(err);
      options?.onError?.(err);
    },
  });
}
