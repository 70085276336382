import { Fragment, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { logger } from '@breathelife/monitoring-frontend';
import { Skeleton, Box } from '@breathelife/mui';
import { LeadStatus } from '@breathelife/types';

import { Dot } from '../../Components/Icons/Dot';
import Typography from '../../Components/Typography';
import { getPaymentProviderOperations } from '../../Helpers/payments';
import { useCarrierContext } from '../../Hooks';
import { useFetchPaymentTransaction } from '../../ReactQuery/Payment/paymentTransaction.queries';
import { PlatformColorList } from '../../Styles/Types';

type ProgressProps = {
  applicationId?: string;
  checkPaymentInfo?: boolean;
  className?: string;
  progress: number;
  status: LeadStatus;
};

export function Progress(props: ProgressProps): ReactElement {
  const { features } = useCarrierContext();
  const { t } = useTranslation();
  const { applicationId, checkPaymentInfo = false, className, progress, status } = props;
  const [hasPaymentInfo, setHasPaymentInfo] = useState<boolean>(false);

  const {
    isLoading: isLoadingFetchPaymentTransaction = false,
    isRefetching: isRefetchingFetchPaymentTransaction = false,
  } = useFetchPaymentTransaction(
    applicationId ?? '',
    { withCardDetails: true, withToken: true },
    {
      enabled: checkPaymentInfo && features?.payments?.enabled,
      refetchOnMount: 'always',
      onError: (err) => {
        logger.error(err);
      },
      onSuccess: (paymentTransaction) => {
        if (checkPaymentInfo && features?.payments?.enabled && paymentTransaction) {
          const providerOperations = getPaymentProviderOperations(features?.payments?.serviceProvider);

          if (providerOperations) {
            const { validatePaymentTransactionCompletion } = providerOperations;

            setHasPaymentInfo(validatePaymentTransactionCompletion(paymentTransaction));
          }
        }
      },
    },
  );

  // TODO: use application status instead of lead status
  const isStarted = status !== 'new' && status !== 'invited' && progress > 0;
  const isCompleted = progress === 100;
  const isLoadingData = isLoadingFetchPaymentTransaction || isRefetchingFetchPaymentTransaction;
  const paymentInfoNotComplete = checkPaymentInfo && isCompleted && !hasPaymentInfo;

  let color: PlatformColorList = 'purple';
  let text = t('pending');

  if (paymentInfoNotComplete) {
    color = 'yellow';
    text = t('missingPaymentInformation');
  } else if (isCompleted) {
    color = 'green';
    text = t('completed');
  } else if (isStarted) {
    color = 'yellow';
    text = t('percentCompleted', { percent: progress });
  }

  return (
    <Fragment>
      {checkPaymentInfo && isLoadingData ? (
        <Skeleton width={120} height='2em' />
      ) : (
        <div className={className}>
          <Box mr={1} component='span'>
            <Dot color={color} />
          </Box>
          <Typography variant='body3' grey={90}>
            {text}
          </Typography>
        </div>
      )}
    </Fragment>
  );
}
