import { SVGProps, ReactElement } from 'react';

export function AdminIcon(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg
      fill='none'
      height='21'
      stroke='#dae2f4'
      viewBox='0 0 21 21'
      width='21'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g strokeLinecap='round' strokeLinejoin='round'>
        <path d='m2.875 3.5034c0 .66394.26339 1.30069.73223 1.77017s1.10473.73323 1.76777.73323 1.29893-.26375 1.76777-.73323.73223-1.10623.73223-1.77017-.26339-1.30069-.73223-1.77017-1.10473-.73323-1.76777-.73323-1.29893.26375-1.76777.73323-.73223 1.10623-.73223 1.77017z' />
        <path d='m8.43667 8.50932c-.61536-.60367-1.395-1.01199-2.24117-1.17377s-1.7212-.06981-2.51538.26437-1.47217.8957-1.94895 1.61416-.73113 1.56192-.73117 2.42452v1.8775h1.875l.625 6.2585h3.75l.43417-4.3526' />
        <path d='m15.8131 9.30432.3684 1.21248c.0606.2012.1952.3718.3767.4773s.3962.138.6008.091l1.2275-.2854c.2325-.0525.4758-.029.6941.0671.2183.096.4001.2595.5188.4666.1187.207.1681.4467.1408.6839-.0272.2372-.1296.4594-.2921.6341l-.8583.9271c-.1434.1544-.2231.3574-.2231.5682 0 .2109.0797.4139.2231.5683l.8583.9271c.1628.1745.2654.3968.2927.634.0274.2373-.022.477-.1408.6841s-.3007.3706-.5192.4665c-.2184.0959-.4618.1191-.6943.0662l-1.2275-.2845c-.2046-.0473-.4194-.0148-.6009.0907-.1816.1055-.3162.2763-.3766.4776l-.3684 1.2074c-.0687.2286-.2091.4289-.4004.5713s-.4233.2192-.6617.2192c-.2383 0-.4703-.0768-.6616-.2192s-.3317-.3427-.4004-.5713l-.3684-1.2116c-.0604-.2013-.1951-.372-.3766-.4776-.1815-.1055-.3963-.1379-.6009-.0907l-1.2275.2846c-.2326.0528-.4759.0296-.6944-.0663-.2184-.0959-.4004-.2594-.51915-.4665-.11878-.207-.16813-.4468-.14079-.6841.02734-.2372.12993-.4594.29274-.634l.8583-.9271c.1434-.1544.2231-.3574.2231-.5682 0-.2109-.0797-.4139-.2231-.5683l-.8583-.9271c-.16257-.1747-.26494-.3969-.29216-.6341s.02214-.4768.14085-.6839c.11871-.207.30051-.3706.51881-.4666.2183-.0961.4615-.1196.6941-.0671l1.2275.2854c.2045.047.4193.0145.6008-.091.1814-.1055.3161-.2761.3767-.4773l.3684-1.21245c.0691-.22842.2099-.42845.4015-.57046.1916-.142.4237-.21844.6621-.21797.2383.00047.4702.07781.6612.22057s.3311.34334.3993.57203z' />
        <path d='m13.5 14.1423c0 .332.1317.6504.3661.8851.2344.2348.5524.3666.8839.3666s.6495-.1318.8839-.3666c.2344-.2347.3661-.5531.3661-.8851 0-.3319-.1317-.6503-.3661-.8851-.2344-.2347-.5524-.3666-.8839-.3666s-.6495.1319-.8839.3666c-.2344.2348-.3661.5532-.3661.8851z' />
      </g>
    </svg>
  );
}
