import { LeadDetailTab } from '@breathelife/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

import { DetailViewType, ModalState, ModalType, RightPanelState } from '../../Models/Layout';

export type RightPanelPayload = { rightPanelState: RightPanelState };
export type ModalPayload = { modalState: ModalState };

export type LayoutState = RightPanelPayload & ModalPayload;

type ActionsType = {
  setRightPanelState: (state: LayoutState, { payload }: PayloadAction<RightPanelPayload>) => void;
  updateRightPanelState: (state: LayoutState, { payload }: PayloadAction<Partial<RightPanelState>>) => void;
  setModalState: (state: LayoutState, { payload }: PayloadAction<ModalPayload>) => void;
  updateModalState: (state: LayoutState, { payload }: PayloadAction<Partial<ModalState>>) => void;
  reset: (state: LayoutState) => void;
};

export const defaultState: LayoutState = {
  rightPanelState: {
    isOpen: false,
    type: DetailViewType.read,
    tab: LeadDetailTab.personalInfo,
  },
  modalState: {
    isOpen: false,
    type: ModalType.email,
  },
};

export const layoutSlice = createSlice<LayoutState, ActionsType>({
  name: 'leadPlatform/layout',
  initialState: defaultState,
  reducers: {
    setRightPanelState: (state: LayoutState, { payload }: PayloadAction<RightPanelPayload>) => {
      state.rightPanelState = payload.rightPanelState;
    },
    updateRightPanelState: (state: LayoutState, { payload }: PayloadAction<Partial<RightPanelState>>) => {
      state.rightPanelState = _.merge(state.rightPanelState, payload);
    },
    setModalState: (state: LayoutState, { payload }: PayloadAction<ModalPayload>) => {
      state.modalState = payload.modalState;
    },
    updateModalState: (state: LayoutState, { payload }: PayloadAction<Partial<ModalState>>) => {
      state.modalState = _.merge(state.modalState, payload);
    },
    reset: (state: LayoutState) => {
      state.modalState = JSON.parse(JSON.stringify(defaultState.modalState));
      state.rightPanelState = JSON.parse(JSON.stringify(defaultState.rightPanelState));
    },
  },
});
