/* eslint-disable react/jsx-key */
/* the keys are already returned by the `react-table` prop getters */

import _ from 'lodash';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { IdType, TableOptions, useRowSelect, useSortBy, useTable } from 'react-table';

import { Table as MUITable, TableBody, TableCell, TableRow, TableSortLabel } from '@breathelife/mui';
import { SortDirection } from '@breathelife/types';

import { Icon } from '../Icons';
import { StyledTableContainer, StyledTableHead, StyledTableRow } from './Styles';

type TableProps<T extends Record<string, unknown>> = TableOptions<T> & {
  onRowClick?: (rowId: IdType<T>) => void;
  onOrderChange?: (columnId: keyof T, sortDirection: SortDirection) => void;
  hiddenColumns?: IdType<T>[];
  autoHeight?: boolean; // true for height according to table content
};

export function Table<T extends Record<string, unknown>>(props: TableProps<T>): ReactElement {
  const { columns, data, onRowClick, onOrderChange, hiddenColumns, initialState } = props;
  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    getTableBodyProps,
    toggleAllRowsSelected,
    setHiddenColumns,
    state: { sortBy, selectedRowIds },
  } = useTable<T>(
    {
      columns,
      data,
      getRowId: useCallback((originalRow: T) => originalRow.id as string, []),
      manualSortBy: !!onOrderChange,
      initialState,
      disableSortRemove: true,
    },
    useSortBy,
    useRowSelect,
  );

  const [isOnRowActions, setIsOnRowActions] = useState<boolean>(false);

  useEffect(() => {
    if (hiddenColumns?.length) {
      setHiddenColumns(hiddenColumns);
    }
  }, [hiddenColumns, setHiddenColumns]);

  useEffect(() => {
    if (!onRowClick || _.isEmpty(selectedRowIds)) return;
    const selectedId = Object.keys(selectedRowIds).find((row) => selectedRowIds[row]);
    toggleAllRowsSelected(false);
    if (selectedId) onRowClick(selectedId);
  }, [onRowClick, selectedRowIds, toggleAllRowsSelected]);

  useEffect(() => {
    if (!onOrderChange || !sortBy || !Array.isArray(sortBy) || !sortBy.length) return;
    const { id, desc } = sortBy[0];
    onOrderChange(id, desc ? 'desc' : 'asc');
  }, [sortBy, onOrderChange]);

  return (
    <StyledTableContainer autoHeight={!!props.autoHeight}>
      <MUITable {...getTableProps()}>
        <StyledTableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  sortDirection={column.isSortedDesc ? 'desc' : 'asc'}
                >
                  {column.render('Header')}
                  {column.canSort && (
                    <TableSortLabel
                      active={column.isSorted}
                      direction={column.isSortedDesc ? 'desc' : 'asc'}
                      IconComponent={(props) => <Icon name='chevronDown' className={props.className} />}
                    />
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </StyledTableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <StyledTableRow
                id={row.id}
                tabIndex={0}
                {...row.getRowProps()}
                onClick={() => {
                  toggleAllRowsSelected(false);
                  row.toggleRowSelected();
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter' && !isOnRowActions) {
                    toggleAllRowsSelected(false);
                    row.toggleRowSelected();
                  }
                }}
              >
                {row.cells.map((cell) => {
                  return (
                    <TableCell
                      onFocus={() => {
                        setIsOnRowActions(true);
                      }}
                      onBlur={() => {
                        setIsOnRowActions(false);
                      }}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </TableCell>
                  );
                })}
              </StyledTableRow>
            );
          })}
        </TableBody>
      </MUITable>
    </StyledTableContainer>
  );
}
