import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ValidationError } from 'yup';

import { Validations, fieldValidation } from '@breathelife/questionnaire-engine';
import { AcceptingDefaultValueFieldBlueprint } from '@breathelife/types';

import { getValidationError } from '../../../../../../Helpers/inputValidation/getValidationError';

type Props<TBlueprint extends AcceptingDefaultValueFieldBlueprint> = {
  validateAs?: Validations;
  initialValue?: TBlueprint['defaultValue'];
};

export function useDefaultValue<TBlueprint extends AcceptingDefaultValueFieldBlueprint>({
  validateAs,
}: Props<TBlueprint>): {
  data: {
    defaultValue: TBlueprint['defaultValue'];
  };
  setters: {
    setDefaultValue: React.Dispatch<React.SetStateAction<TBlueprint['defaultValue']>>;
  };
  validationError?: ValidationError;
} {
  const [defaultValue, setDefaultValue] = useState<TBlueprint['defaultValue']>();

  const { t } = useTranslation();

  const fieldValueValidation = useMemo(() => {
    if (!validateAs) {
      return;
    }
    return fieldValidation(validateAs, false, t);
  }, [validateAs, t]);

  const validationError = useMemo(() => {
    if (!defaultValue || !fieldValueValidation) {
      return;
    }
    return getValidationError({ defaultValue: fieldValueValidation }, 'defaultValue', defaultValue);
  }, [fieldValueValidation, defaultValue]);

  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
  }, []);

  return {
    data: { defaultValue },
    setters: {
      setDefaultValue,
    },
    validationError: firstUpdate.current === false ? validationError : undefined,
  };
}
