import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { logger } from '@breathelife/monitoring-frontend';
import { Box, Divider } from '@breathelife/mui';
import {
  ESignFieldSettingAnchorPointType,
  ESignFieldSettingPlacementType,
  ESignFieldSettingType,
} from '@breathelife/types';

import { Button } from '../../../../../../Components/Button/Button';
import { SubmitButton } from '../../../../../../Components/Button/SubmitButton';
import { eSignFieldSettingValidation } from '../../../../../../Helpers/inputValidation/form/eSignFieldSetting';
import { useDispatch } from '../../../../../../Hooks';
import { useCreateESignFieldSettingMutation } from '../../../../../../ReactQuery/ESignFieldSettings/eSignFieldSettings.mutations';
import { notificationSlice } from '../../../../../../ReduxStore/Notification/NotificationSlice';
import { ESignFieldSettingForm, ESignFieldSettingFormData } from './ESignFieldSettingForm';
import { ESignFieldSettingUtils } from './utils';

const defaultESignFieldSetting: ESignFieldSettingFormData = {
  type: ESignFieldSettingType.SIGNATURE,
  placementType: ESignFieldSettingPlacementType.RELATIVE,
  top: '0',
  left: '0',
  width: '0',
  height: '0',
  page: '1',
  // relative
  anchorText: '',
  anchorPoint: ESignFieldSettingAnchorPointType.TOPRIGHT,
  anchorIndex: '1',

  // unused
  fieldBinding: '',
  anchorCharacterIndex: '',
  defaultValue: '',
};

export function NewESignFieldSetting(props: { signerSettingId: string; onClose: () => void }): ReactElement | null {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [eSignFieldSetting, setESignFieldSetting] = useState(defaultESignFieldSetting);

  const isDocumentFormValid = eSignFieldSettingValidation.getFormSchema().isValidSync(eSignFieldSetting);

  const createESignFieldSettingMutation = useCreateESignFieldSettingMutation({
    onSuccess: props.onClose,
  });

  const createNewESignFieldSetting = async (): Promise<void> => {
    const isDocumentFormValid = eSignFieldSettingValidation.getFormSchema().isValidSync(eSignFieldSetting);
    if (!isDocumentFormValid) {
      return;
    }

    const submissionData = ESignFieldSettingUtils.transformFormData(eSignFieldSetting);
    if (submissionData === undefined) {
      logger.error({
        message: 'Unable to construct a request payload to the eSignFieldSetting endpoint.',
        details: eSignFieldSetting,
        BLCode: 'CreateESignFieldSettingError',
      });
      dispatch(
        notificationSlice.actions.setError({
          message: t('notifications.failedToUpdateSettings'),
        }),
      );
      return;
    }

    await createESignFieldSettingMutation.mutateAsync({
      ...submissionData,
      signerSettingId: props.signerSettingId,
    });
  };

  return (
    <Box>
      <ESignFieldSettingForm
        data={eSignFieldSetting}
        onChange={setESignFieldSetting}
        disabled={createESignFieldSettingMutation.isLoading}
      />

      <Box display='flex' justifyContent='flex-end' my={2}>
        <Box mr={2}>
          <Button onClick={props.onClose}>{t('cta.cancel')}</Button>
        </Box>
        <Box>
          <SubmitButton
            onClick={createNewESignFieldSetting}
            isLoading={createESignFieldSettingMutation.isLoading}
            disabled={!isDocumentFormValid}
          >
            {t('cta.save')}
          </SubmitButton>
        </Box>
      </Box>
      <Divider />
    </Box>
  );
}
