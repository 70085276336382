import { useState } from 'react';

import { AlwaysValid, FieldBlueprint, FieldTypes, ParticipantRoles, SignatureFieldBlueprint } from '@breathelife/types';
import { AutocompleteOption } from '@breathelife/ui-components';

import {
  FieldDataHookReturnValueWithValidationOptions,
  FieldDataValidateAs,
  FieldSettersValidateAs,
  useCastBlueprintToFieldType,
} from '../../Helpers/helperTypes';
import { BaseFieldData, BaseFieldSetters, useBaseFieldData } from './useBaseFieldData';
import { useValidateAsData } from './useValidateAsData';

export type SignatureFieldData = FieldDataValidateAs<SignatureFieldBlueprint> &
  Pick<SignatureFieldBlueprint, 'participantRole'> &
  BaseFieldData;

export type SignatureFieldSetters = FieldSettersValidateAs<SignatureFieldData> & {
  fieldType: FieldTypes.signature;
  setParticipantRole: React.Dispatch<React.SetStateAction<SignatureFieldData['participantRole']>>;
} & BaseFieldSetters;

const defaultProperties = {
  participantRole: ParticipantRoles.INSURED,
  validateAs: AlwaysValid.signature,
} as const;

export function useSignatureFieldData({
  initialData,
  dataLabelOptions,
}: {
  initialData?: Partial<FieldBlueprint>;
  dataLabelOptions: AutocompleteOption[];
}): FieldDataHookReturnValueWithValidationOptions<SignatureFieldBlueprint, SignatureFieldSetters> {
  const initialDataAsSignatureField = useCastBlueprintToFieldType<SignatureFieldBlueprint>(
    FieldTypes.signature,
    initialData,
  );

  const [participantRole, setParticipantRole] = useState<SignatureFieldData['participantRole']>(
    initialDataAsSignatureField?.participantRole || defaultProperties.participantRole,
  );

  const { data, setters } = useBaseFieldData({ initialData, dataLabelOptions });

  const {
    data: validateAsData,
    setters: validateAsSetters,
    validationOptions,
  } = useValidateAsData<SignatureFieldBlueprint>({
    fieldType: FieldTypes.signature,
    initialValidateAs: defaultProperties.validateAs,
  });

  return {
    data: {
      fieldType: FieldTypes.signature,
      ...data,
      participantRole,
      ...validateAsData,
    },
    setters: {
      fieldType: FieldTypes.signature,
      setParticipantRole,
      ...setters,
      ...validateAsSetters,
    },
    validationOptions,
  };
}
