import { KeyboardEvent, ChangeEvent, ReactElement } from 'react';

import { TextFieldProps } from '@breathelife/mui';
import { Input, ValidationProps } from '@breathelife/ui-components';

type Props = Omit<TextFieldProps, 'onChange'> & ValidationProps & { onChange: (value: string) => void };

function preventNonZipCodeInput(event: KeyboardEvent<HTMLInputElement>): void {
  const keyCode = event.keyCode || event.which;
  const keyValue = String.fromCharCode(keyCode);
  const inputValue = (event.target as HTMLInputElement).value;
  const zipCodeInputRegex = new RegExp(`${inputValue.length === 5 ? '-' : '[0-9]'}`);
  if (!zipCodeInputRegex.test(keyValue)) {
    event.preventDefault();
  }
}

export function ZipCodeInputMui(props: Props): ReactElement {
  function onChange(event: ChangeEvent<HTMLInputElement>): void {
    if (!props.onChange) return;

    props.onChange(event.target.value);
  }

  return (
    <Input
      {...props}
      onChange={onChange}
      onKeyPress={preventNonZipCodeInput}
      inputProps={{ maxLength: 10 }}
      inputVariant='outlined'
      required={props.required}
      id={props.id}
      label={props.label}
      error={!!props.validationError}
      validationError={props.validationError}
    />
  );
}
