import styled from '../../../Styles/themed-styled-components';

import Typography from '../../../Components/Typography';

export const LeadName = styled(Typography)`
  letter-spacing: -0.63px;
`;

export const LeadEmail = styled(Typography)`
  margin-left: 4px;
`;

export const TextButton = styled.button`
  color: ${(props) => props.theme.colors.blue[60]};
  background-color: transparent;
  border: none;
  padding: unset;
  cursor: pointer;
`;

export const Link = styled.a`
  color: ${(props) => props.theme.colors.blue[60]};
`;
