import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Settings } from '@breathelife/types';

export type SettingsState = {
  settings?: Settings;
  isFetching: boolean;
  isUpdatingLanguages: boolean;
  isUpdatingPublicLink: boolean;
  isUpdatingCarrierName: boolean;
  isUpdatingConsumerFooter: boolean;
};

type SettingsPayload = { settings: Settings };
type SetLoadingPayload = boolean;

type ActionsType = {
  setSettings: (state: SettingsState, { payload }: PayloadAction<SettingsPayload>) => void;
  setIsFetching: (state: SettingsState, { payload }: PayloadAction<SetLoadingPayload>) => void;
  setIsUpdatingLanguages: (state: SettingsState, { payload }: PayloadAction<SetLoadingPayload>) => void;
  setIsUpdatingPublicLink: (state: SettingsState, { payload }: PayloadAction<SetLoadingPayload>) => void;
  setIsUpdatingCarrierName: (state: SettingsState, { payload }: PayloadAction<SetLoadingPayload>) => void;
  setIsUpdatingConsumerFooter: (state: SettingsState, { payload }: PayloadAction<SetLoadingPayload>) => void;
};

const defaultState = {
  isFetching: false,
  isUpdatingLanguages: false,
  isUpdatingPublicLink: false,
  isUpdatingCarrierName: false,
  isUpdatingConsumerFooter: false,
};

export const settingsSlice = createSlice<SettingsState, ActionsType>({
  name: 'leadPlatform/settings',
  initialState: defaultState,
  reducers: {
    setSettings: (state: SettingsState, { payload }: PayloadAction<SettingsPayload>) => {
      state.settings = payload.settings;
    },
    setIsFetching: (state: SettingsState, { payload }: PayloadAction<SetLoadingPayload>) => {
      state.isFetching = payload;
    },
    setIsUpdatingLanguages: (state: SettingsState, { payload }: PayloadAction<SetLoadingPayload>) => {
      state.isUpdatingLanguages = payload;
    },
    setIsUpdatingPublicLink: (state: SettingsState, { payload }: PayloadAction<SetLoadingPayload>) => {
      state.isUpdatingPublicLink = payload;
    },
    setIsUpdatingCarrierName: (state: SettingsState, { payload }: PayloadAction<SetLoadingPayload>) => {
      state.isUpdatingCarrierName = payload;
    },
    setIsUpdatingConsumerFooter: (state: SettingsState, { payload }: PayloadAction<SetLoadingPayload>) => {
      state.isUpdatingConsumerFooter = payload;
    },
  },
});
