import { InputFieldBlueprint } from '@breathelife/types';

import { FieldDataSetterProps } from '../../../Helpers/helperTypes';
import { InputFieldSetters, useInputFieldData } from '../useInputFieldData';
import { useCommonFieldDataSetters } from './useCommonFieldDataSetters';

export function InputFieldDataSetter({
  initialData,
  dataLabelOptions,
  ...props
}: FieldDataSetterProps<InputFieldBlueprint, InputFieldSetters>): null {
  const { data, setters, validationOptions, defaultValueValidationError } = useInputFieldData({
    initialData,
    dataLabelOptions,
  });

  useCommonFieldDataSetters<InputFieldBlueprint, InputFieldSetters>({
    ...props,
    data,
    setters,
    validationOptions,
    defaultValueValidationError,
    getIsValidBlueprint: (blueprint) => !!blueprint.validateAs,
  });
  return null;
}
