import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Container, TopText, BottomText, NaPendingImage } from './Styles';

export function PendingInformation(): ReactElement {
  const { t } = useTranslation();
  return (
    <Container>
      <NaPendingImage role='img' />
      <TopText>{t('tabs.needsAnalysis.pending.pendingInfo')}</TopText>
      <BottomText>{t('tabs.needsAnalysis.pending.hasBeenSent')}</BottomText>
    </Container>
  );
}
