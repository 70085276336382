import { ReactElement } from 'react';

import { Drawer } from '../../../Components/Drawer/Drawer';
import { useNavigation } from '../../../Hooks';
import { Application } from '../../../Models/Application';
import { DetailViewType } from '../../../Models/Layout';
import { Lead } from '../../../Models/Lead';
import { useGetLeadQuery } from '../../../ReactQuery/Lead/lead.queries';
import { LeadDetailBody } from './Body/LeadDetailBody';
import leadDetailHeaderLookup from './Header';

export type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSendEmailClick: (lead: Lead) => void;
  onToggleArchiveClick: (lead: Lead) => void;
  onCopyClick: (application: Application) => void;
  onDeleteClick: (lead: Lead) => void;
  onDownloadFilesClick: (lead: Lead) => void;
  viewType: DetailViewType;
};

export function LeadDetailView(props: Props): ReactElement {
  const { leadId } = useNavigation();
  const { data: lead } = useGetLeadQuery(leadId, {
    onError: () => props.onClose(),
  });

  const LeadDetailHeader = leadDetailHeaderLookup(props.viewType);

  return (
    <Drawer open={props.isOpen} onClose={props.onClose}>
      <section>
        <LeadDetailHeader
          lead={lead}
          closeRightPanel={props.onClose}
          onSendEmailClick={props.onSendEmailClick}
          onToggleArchiveClick={props.onToggleArchiveClick}
          onCopyClick={props.onCopyClick}
          onDeleteClick={props.onDeleteClick}
          onDownloadFilesClick={props.onDownloadFilesClick}
        />
      </section>
      <LeadDetailBody
        type={props.viewType}
        lead={lead}
        onSendEmailClick={props.onSendEmailClick}
        onClose={props.onClose}
      />
    </Drawer>
  );
}
