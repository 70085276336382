import _ from 'lodash';
import { ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalButton } from '@breathelife/ui-components';

import Typography from '../../../Components/Typography';
import { useDispatch } from '../../../Hooks';
import { ModalLayout } from '../../../Layouts/Modal/ModalLayout';
import { usePatchFileTemplateMutation } from '../../../ReactQuery/Document/document.mutations';
import { notificationSlice } from '../../../ReduxStore/Notification/NotificationSlice';

type Props = {
  fileTemplateIdToArchive: string;
  onClose: () => void;
  isOpen: boolean;
};

export function ArchiveDocumentDialog(props: Props): ReactElement {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { onClose, fileTemplateIdToArchive, isOpen } = props;

  const patchFileTemplateMutation = usePatchFileTemplateMutation({
    onSuccess: useCallback(() => {
      onClose();

      dispatch(
        notificationSlice.actions.setSuccess({
          message: t('notifications.documentArchived'),
          autoHideDuration: 5000,
        }),
      );
    }, [onClose, dispatch, t]),
  });

  const archiveDocumentTemplate = async (fileTemplateId: string) => {
    await patchFileTemplateMutation.mutateAsync({
      fileTemplateId,
      data: {
        archived: true,
      },
    });
  };

  return (
    <ModalLayout
      isOpen={isOpen}
      closeModal={onClose}
      title={t('admin.documentManagement.archiveDocumentDialog.title')}
      submitButton={
        <ModalButton
          variant='contained'
          color='primary'
          onClick={() => archiveDocumentTemplate(fileTemplateIdToArchive)}
        >
          {t('cta.archive')}
        </ModalButton>
      }
    >
      <Typography variant='body1'>{t('admin.documentManagement.archiveDocumentDialog.message')}</Typography>
    </ModalLayout>
  );
}
