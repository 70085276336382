import { Box } from '@breathelife/mui';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from '../../../../Styles/themed-styled-components';

import { UsersImportResponse } from '@breathelife/types';
import { Loader } from '@breathelife/ui-components';

import { UploadButton } from '../../../../Components/Button/UploadButton';
import Typography from '../../../../Components/Typography';
import { WHITELISTED_FILE_EXTENSIONS } from '../../../../Helpers/importUsers';

type ImportUsersViewProps = {
  handleFileUpload: (file: File) => void;
  isImporting: boolean;
  error: string;
  importResult: UsersImportResponse | undefined;
};

const JsonResultContainer = styled(Box)`
  background-color: ${(props) => props.theme.colors.grey[20]};
`;

export function ImportUsersView(props: ImportUsersViewProps): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const { handleFileUpload, isImporting, importResult, error } = props;
  return (
    <Box>
      <UploadButton
        extensions={WHITELISTED_FILE_EXTENSIONS}
        onChange={handleFileUpload}
        size='small'
        variant='contained'
        color='primary'
        component='label'
      >
        {t('modals.importUsers.import')}
      </UploadButton>
      {isImporting && (
        <Box mt={2}>
          <Loader size='50px' />
        </Box>
      )}
      {/* TODO: improve the error/result display once we start working on allowing the admins to import users.
          Currently, only superadmin have access to the users import feature.
      */}
      {!isImporting && !!error && (
        <Box mt={2}>
          <Typography variant='body1' color={theme.colors.red[50]}>
            {error}
          </Typography>
        </Box>
      )}
      {!isImporting && !!importResult && (
        <Box mt={2}>
          <Box>
            <Typography variant='body1' color={theme.colors.green[50]}>
              {t('modals.importUsers.success')}
            </Typography>
          </Box>
          <JsonResultContainer mt={2} p={1}>
            <pre>{JSON.stringify(importResult, null, 2)}</pre>
          </JsonResultContainer>
        </Box>
      )}
    </Box>
  );
}
