import { Box, DescriptionIcon } from '@breathelife/mui';

import Typography from '../../../../../Components/Typography';
import styled from '../../../../../Styles/themed-styled-components';

export const NoFileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10rem 0;
`;

export const NoFileIcon = styled(DescriptionIcon)`
  &&& {
    font-size: 140px;
    background: #efefef;
    color: grey;
    padding: 20px;
    border-radius: 50%;
    margin-bottom: 20px;
  }
`;

export const DisplayNameTypography = styled(Typography)`
  text-overflow: ellipsis;
`;

export const SubtitleContainer = styled(Box)`
  color: ${(props) => props.theme.colors.grey[70]};
`;
