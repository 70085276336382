import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalLayout } from '../../../../Layouts/Modal/ModalLayout';
import { ImportUsersContainer } from '../../../../Pages/Admin/UserManagement/ImportUsersModal/ImportUsersContainer';

type ImportUsersModalProps = {
  isOpen: boolean;
  closeModal: () => void;
};

export function ImportUsersModal(props: ImportUsersModalProps): ReactElement {
  const { t } = useTranslation();
  return (
    <ModalLayout
      maxWidth='sm'
      title={t('modals.importUsers.importUsers')}
      isOpen={props.isOpen}
      closeModal={() => props.closeModal()}
    >
      <ImportUsersContainer />
    </ModalLayout>
  );
}
