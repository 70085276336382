import { ReactElement } from 'react';
import styled from '../../Styles/themed-styled-components';

type Props = {
  href: string;
  title: string;
};

const StyledLink = styled.a`
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  border: solid 3px #0e85fb;
  padding: 5px;
  display: flex;
  max-width: fit-content;

  &:focus {
    position: static;
    width: auto;
    height: auto;
  }
`;

export function SkipLink(props: Props): ReactElement {
  const { href, title } = props;
  return <StyledLink href={href}>{title}</StyledLink>;
}
