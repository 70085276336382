import { ReactElement } from 'react';

import { DetailViewType } from '../../../../Models/Layout';
import { CreateLeadHeader, Props as CreateLeadHeaderProps } from './CreateLeadHeader';
import { LeadDetailHeader, Props as LeadDetailHeaderProps } from './LeadDetailHeader';

type Props = CreateLeadHeaderProps & LeadDetailHeaderProps;

export const componentMap: { [key in DetailViewType]: (props: Props) => ReactElement | null } = {
  [DetailViewType.create]: CreateLeadHeader,
  [DetailViewType.edit]: LeadDetailHeader,
  [DetailViewType.read]: LeadDetailHeader,
};

export default (type: DetailViewType): ((props: Props) => ReactElement | null) => componentMap[type];
