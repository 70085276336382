import { useState } from 'react';

import { FieldBlueprint, FieldTypes } from '@breathelife/types';

import {
  FieldDataHookReturnValueWithValidationOptions,
  FieldDataValidateAs,
  FieldSettersValidateAs,
} from '../../Helpers/helperTypes';
import { useGetValidationOptions } from './useGetValidationOptions';

export function useValidateAsData<T extends FieldBlueprint>({
  initialValidateAs,
  fieldType,
}: {
  initialValidateAs: FieldDataValidateAs<T>['validateAs'];
  fieldType: FieldTypes;
}): FieldDataHookReturnValueWithValidationOptions<FieldDataValidateAs<T>, FieldSettersValidateAs<T>> {
  const validationOptions = useGetValidationOptions(fieldType);
  const [validateAs, setValidateAs] = useState<FieldDataValidateAs<T>['validateAs']>(initialValidateAs);

  return {
    data: {
      validateAs,
    },
    setters: {
      setValidateAs,
    },
    validationOptions,
  };
}
