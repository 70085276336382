import { Accordion, AccordionDetails, AccordionSummary, Box } from '@breathelife/mui';

import styled from '../../../../Styles/themed-styled-components';

export const WidgetBody = styled(Box)`
  width: 100%;
  min-height: 100px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 15px;
`;

export const WidgetHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-right: 15px;
`;

export const AccordionWrapper = styled(Box)`
  width: 100%;
  min-height: 100px;
`;

export const StyledAccordion = styled(Accordion)`
  &.MuiAccordion-root,
  &.MuiAccordion-root.Mui-expanded,
  &.MuiAccordion-root.Mui-expanded:first-child {
    box-shadow: none;
    width: 100%;
    margin-top: 8px;
    position: initial;
  }

  &::before {
    display: none;
  }
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  &.MuiAccordionSummary-root {
    background-color: ${({ theme }) => theme.colors.grey[20]};
    border-radius: 5px;
  }

  &.MuiAccordionSummary-root,
  &.MuiAccordionSummary-root.Mui-expanded {
    min-height: 32px;
    height: 32px;
  }

  & .MuiIconButton-root.MuiAccordionSummary-expandIcon {
    padding: 6px 12px;
  }
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  min-height: 25px;
  flex-direction: column;

  &.MuiAccordionDetails-root {
    padding-left: 8px;
  }

  & [data-test-id='virtuoso-scroller'] {
    min-height: inherit;
    position: initial !important; /* '!important' used to override inline styles */

    [data-viewport-type='element'] {
      min-height: inherit;
      position: initial !important; /* '!important' used to override inline styles */
    }
  }
`;

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.blue[40]};
  box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 9px 0 15px 15px;
  width: 25%;
  min-width: 269px;
  min-height: 159px;
  max-height: 90%;
  align-items: center;
  position: absolute;
  bottom: 26px;
  right: 26px;
  background-color: ${({ theme }) => theme.colors.grey[0]};
`;

export const ButtonWrapper = styled(Box)`
  position: fixed;
  bottom: 10px;
  right: 10px;

  & .MuiIconButton-root {
    padding: 0;
  }
`;
