import i18next from 'i18next';
import * as yup from 'yup';

import { Language } from '@breathelife/types';

import { CountryCode } from '../../../types';
import {
  getEmailSchema,
  getFirstNameSchema,
  getLastNameSchema,
  getPhoneNumberSchema,
  getPostalCodeSchema,
  getZipCodeSchema,
} from '../field';
import { getValidationError } from '../getValidationError';

type FormFields =
  | 'firstName'
  | 'lastName'
  | 'email'
  | 'phoneNumber'
  | 'postalCode'
  | 'region'
  | 'communicationLanguage';

const getSchemaMap = (options: { countryCode: CountryCode }): { [field in FormFields]: yup.StringSchema } => ({
  firstName: getFirstNameSchema().required(i18next.t('validation.error.required')),
  lastName: getLastNameSchema().required(i18next.t('validation.error.required')),
  email: getEmailSchema().required(i18next.t('validation.error.required')),
  phoneNumber: getPhoneNumberSchema(),
  postalCode: options.countryCode === 'US' ? getZipCodeSchema() : getPostalCodeSchema(),
  region: yup.string(),
  communicationLanguage: yup.string().required(i18next.t('validation.error.required')).oneOf(Object.values(Language)),
});

export const getFormSchema = (options: { countryCode: CountryCode }): yup.ObjectSchema =>
  yup.object().shape(getSchemaMap(options));

export function getFieldValidationError(
  fieldName: FormFields,
  value: string | undefined,
  options: { countryCode: CountryCode },
): yup.ValidationError | undefined {
  return getValidationError(getSchemaMap(options), fieldName, value);
}
