import { Tooltip } from '@breathelife/mui';

import styled from '../../Styles/themed-styled-components';

export const CommunicationToolTip = styled(Tooltip)`
  padding: '15px';
`;

export const DayOfTheWeekName = styled.span`
  font-weight: 500;

  &:not(:first-child)::before {
    content: ' - ';
    font-weight: 300;
  }
`;

export const Preview = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 300px;
`;
