import { Box } from '@breathelife/mui';
import { ReactElement, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Loader } from '@breathelife/ui-components';

import { Button } from '../../../../Components/Button/Button';
import { GhostButton } from '../../../../Components/Button/GhostButton';
import { Icon } from '../../../../Components/Icons';
import Typography from '../../../../Components/Typography';
import { useSelector } from '../../../../Hooks';

type CopyLinkSectionProps = {
  isCompletingProfile: boolean;
  publicLink?: string;
  onCopyAgentLink: () => void;
};

export function CopyLinkSection(props: CopyLinkSectionProps): ReactElement | null {
  const { t } = useTranslation();
  const user = useSelector((store) => store.leadPlatform.authentication.user);
  const { isCompletingProfile, publicLink, onCopyAgentLink } = props;

  if (isCompletingProfile) return null;
  if (!user) return null;
  const displayCopyLinkSection = publicLink;

  return (
    <Fragment>
      {displayCopyLinkSection && (
        <Fragment>
          <Box mb={1}>
            <Typography variant='body1' grey={70}>
              {t('modals.editUser.clickToCopy')}
            </Typography>
          </Box>
          <Box mb={1}>
            <Button startIcon={<Icon name='copy' />} variant='outlined' onClick={onCopyAgentLink}>
              {t('modals.editUser.copyLinkToClipboard')}
            </Button>
          </Box>
          <GhostButton color='primary' onClick={() => window.open(`${publicLink}?productLine=life`)}>
            {t('modals.editUser.openInNewTab')}
          </GhostButton>
        </Fragment>
      )}

      {!displayCopyLinkSection && (
        <Box textAlign='center' display='flex' flex='1'>
          <Box margin='auto'>
            <Loader size='100px' />
          </Box>
        </Box>
      )}
    </Fragment>
  );
}
