import {
  Answers,
  Application,
  Language,
  LocalizedInsuranceProduct,
  ProductsEntity,
  QuoteInfo,
} from '@breathelife/types';

import ApiService from './ApiService';

export async function getProducts(applicationId: string, lang?: Language): Promise<LocalizedInsuranceProduct[]> {
  const response = await ApiService.fetchProducts(applicationId, lang);
  return response.data;
}

export async function getProductsEntity(
  answers: Answers,
  products: LocalizedInsuranceProduct[],
  quotes: QuoteInfo,
  application: Application,
): Promise<ProductsEntity<string>> {
  const response = await ApiService.getProductsEntity({ answers, products, quotes, application });
  return response.data;
}
