export default {
  birthDate: {
    max: "L'âge inscrit est supérieur au maximum permis.",
    min: "L'âge inscrit est inférieur au maximum permis.",
  },
  numberMultiple: {
    '10': 'Le montant doit être un multiple de 10.',
    '1000': 'Le montant doit être un multiple de 1000.',
  },
  policyholder: {
    max: 'Un autre assuré est déjà le détenteur de la police. Veuillez en sélectionner uniquement un.',
    min: 'Au moins un assuré doit être sélectionné en tant que détenteur de la police.',
  },
  required: 'Champs requis',
  sin: "Veuillez inscrire un numéro d'assurance social valide.",
  sinLength: "Veuillez entrer un numéro d'assurance sociale à 9 chiffres",
  ssnLength: 'Veuillez entrer un numéro de sécurité sociale valide à 9 chiffres (par exemple : 123-45-6789)',
  canadianPostalCode: 'Le code postal doit avoir un des formats suivants: A1A 1A1 ou A1A1A1',
  zipCode: 'Le code zip doit avoir un des formats suivants: 12345 ou 12345-1234',
  phoneNumber: 'Veuillez entrer un numéro de téléphone valide à 10 chiffres',
  phoneNumberAreaCode: 'Veuillez entrer un code régional valide',
  dateFormat: 'Veuillez entrer une date valide',
  pastDate: "Veuillez entrer une date avant aujourd'hui.",
  pastYear: 'Veuillez entrer une date dans la dernière année.',
  futureDate: "Veuillez entrer une date ultérieure à aujourd'hui",
  futureOrCurrentDate: 'Veuillez entrer une date ultérieure',
  pastOrCurrentDate: "Merci d'entrer une date courante ou dans le passé",
  currentDate: 'Vous devez entrer la date du jour',
  yearMonth: 'Veuillez entrer une date selon le format AAAA-MM',
  decimal: "Veuillez entrer un nombre (jusqu'à 2 décimales)",
  quoteShare: 'La somme des proportions partagées doit égaler 100%',
  optional: 'optionel',
  radioCheckbox: 'Veuillez répondre à la question.',
  isTrue: 'Veuillez accepter les termes.',
  agreeField: 'Veuillez lire et accepter la note.',
  matchEmail: 'Les adresses courriels ne concordent pas.',
  invalidFirstName: 'Prénom invalide',
  invalidLastName: 'Nom de famille invalide',
  invalidMiddleName: 'Second prénom invalide',
  invalidFullName: 'Nom complet invalide',
  invalidEmail: 'Courriel non valide',
  number: {
    min: 'Le minimum est %{min}',
    max: 'Le maximum est %{max}',
  },
  accountNumber: 'Veuillez entrer un numéro de compte valide comportant au moins 7 chiffres.',
  institutionNumber: "Veuillez entrer un numéro d'institution valide comportant au moins 3 chiffres.",
  branchNumber: 'Veuillez entrer un numéro de transit valide comportant au moins 5 chiffres.',
};
