import { Fragment, memo, ReactElement, useMemo } from 'react';

import { FieldGenerator, RenderContext, StyleVariant } from '@breathelife/field-generator';
import { NodeIds } from '@breathelife/insurance-form-builder';
import { RenderingQuestion, RepeatedIndices } from '@breathelife/questionnaire-engine';

import { useCarrierContext, useLocale } from '../../../../Hooks';
import { useAssistedApplicationContext } from '../../../../Hooks/useAssistedApplicationContext';
import { FieldGroupCardContainer } from './FieldGroupCard/FieldGroupCardContainer';

type FieldGroupContainerProps = {
  question: RenderingQuestion;
};

export const FieldGroupContainer = memo(InnerFieldGroupContainer);

export function InnerFieldGroupContainer(props: FieldGroupContainerProps): ReactElement {
  const { question } = props;
  const { iconMap } = useCarrierContext();
  const locale = useLocale();

  const { currentProposedInsuredIndex, onAnswerChange, onAnswerComplete } = useAssistedApplicationContext();

  const repeatedIndices: RepeatedIndices = useMemo(() => {
    return {
      [NodeIds.insuredPeople]: currentProposedInsuredIndex,
    };
  }, [currentProposedInsuredIndex]);

  if (!question.visible) return <Fragment />;

  if (question.displayAsCard) {
    return <FieldGroupCardContainer question={question} />;
  }

  return (
    <FieldGenerator
      context={RenderContext.web}
      question={question}
      onAnswerChange={onAnswerChange}
      onAnswerComplete={onAnswerComplete}
      styleVariant={StyleVariant.pro}
      iconMap={iconMap}
      locale={locale}
      repeatedIndices={repeatedIndices}
    />
  );
}
