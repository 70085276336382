import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type SubmissionState = {
  isSubmittingApplication: boolean;
  isSubmissionSuccess: boolean;
  isSubmissionFailure: boolean;
};

const defaultState = {
  isSubmittingApplication: false,
  isSubmissionSuccess: false,
  isSubmissionFailure: false,
};

type IsSubmittingPayload = boolean;

type IsSuccessPayload = boolean;

type IsFailedPayload = boolean;

type ActionsType = {
  setIsSubmitting: (state: SubmissionState, isSubmissionStarted: PayloadAction<IsSubmittingPayload>) => void;
  setIsSuccess: (state: SubmissionState, isSubmissionSuccess: PayloadAction<IsSuccessPayload>) => void;
  setIsFailure: (state: SubmissionState, isSubmissionFailure: PayloadAction<IsFailedPayload>) => void;
  reset: (state: SubmissionState) => void;
};

export const submissionSlice = createSlice<SubmissionState, ActionsType>({
  name: 'leadPlatform/submission',
  initialState: defaultState,
  reducers: {
    setIsSubmitting(state: SubmissionState, { payload }: PayloadAction<IsSubmittingPayload>) {
      state.isSubmittingApplication = payload;
    },
    setIsSuccess(state: SubmissionState, { payload }: PayloadAction<IsSuccessPayload>) {
      state.isSubmissionSuccess = payload;
    },
    setIsFailure(state: SubmissionState, { payload }: PayloadAction<IsFailedPayload>) {
      state.isSubmissionFailure = payload;
    },
    reset(state: SubmissionState) {
      state.isSubmittingApplication = false;
      state.isSubmissionSuccess = false;
      state.isSubmissionFailure = false;
    },
  },
});
