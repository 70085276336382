import { Notification, NotificationEventName } from '@breathelife/types';

import { generateApplicationDrawerUrl, generateApplicationUrl } from '../Navigation/Urls';
import { DrawerType } from '../Components/AssistedApplication/AssistedApplication';

export function getNotificationData(notification: Notification): {
  icon: string;
  callToActionUrl: string | undefined;
  metadata: Record<string, any> | undefined;
} {
  let icon = '';
  let callToActionUrl;
  let metadata;

  switch (notification.event) {
    case NotificationEventName.ApplicationSubmitted:
      icon = 'notificationInfoIcon';
      metadata = {
        applicationRefNumber: notification.metadata.applicationRefNumber,
        url: generateApplicationUrl(notification.metadata.applicationId, notification.metadata.leadId),
      };
      break;
    case NotificationEventName.NewFeatureLaunch:
      icon = 'notificationInfoIcon';
      break;
    case NotificationEventName.OneSpanCreatePackageError:
      icon = 'notificationActionIcon';
      callToActionUrl = generateApplicationDrawerUrl(
        notification.metadata.applicationId,
        notification.metadata.leadId,
        undefined,
        DrawerType.signature,
      );
      metadata = {
        applicationId: notification.metadata.applicationId,
        applicationRefNumber: notification.metadata.applicationRefNumber,
      };
      break;
    case NotificationEventName.OneSpanEmailBounce:
      icon = 'notificationActionIcon';
      callToActionUrl = generateApplicationDrawerUrl(
        notification.metadata.applicationId,
        notification.metadata.leadId,
        undefined,
        DrawerType.signature,
      );
      metadata = {
        applicationId: notification.metadata.applicationId,
        applicationRefNumber: notification.metadata.applicationRefNumber,
        signerName: notification.metadata.signerName,
        email: notification.metadata.email,
        roles: notification.metadata.roles,
      };
      break;
  }

  return { icon, callToActionUrl, metadata };
}
