import { ReactElement, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  BlueprintCollectionOperator,
  BlueprintSingleConditionValue,
  ConditionBlueprintType,
  Language,
} from '@breathelife/types';
import { AccountTreeIcon, DeleteIcon, Box, Grid } from '@breathelife/mui';

import { IconButton } from '../../Components/Controls';
import { DEFAULT_COLLECTION_OPERATOR, getTargetNodeIdsFromCondition } from '../../Helpers/conditions/blueprintHelpers';
import { getOptionsFromEnum } from '../../Helpers/options';
import { NodeIdInCollections, QuestionnaireNodeIds } from '../../Helpers/questionnaireEditor/questionnaireNodeIds';
import { Select } from '../Select/Select';
import { getEmptyStateForConditionType } from './Helpers/stateHelper';
import { SingleConditionCriteria } from './SingleConditionCriteria';
import { ConditionPropertiesUpdateCallback } from './types';

type Props = {
  questionnaireNodeIds: QuestionnaireNodeIds;
  condition: BlueprintSingleConditionValue;
  onConditionChange: (updatedCondition: BlueprintSingleConditionValue) => void;
  onRemoveCondition: () => void;
  isConditionRemovable: boolean;
  onAddNestedCondition: () => void;
  language: Language;
  collectionContext: string[];
  nodeIdInCollectionMap: NodeIdInCollections;
  conditionIndex: string;
};

export function SingleConditionValueEditor(props: Props): ReactElement | null {
  const {
    condition,
    questionnaireNodeIds,
    collectionContext,
    onConditionChange,
    onRemoveCondition,
    isConditionRemovable,
    onAddNestedCondition,
    language,
    nodeIdInCollectionMap,
    conditionIndex,
  } = props;

  const { t } = useTranslation();

  const conditionPropertyOptions = useMemo(
    () =>
      getOptionsFromEnum<ConditionBlueprintType>(ConditionBlueprintType, 'admin.conditions.options.conditionProperty'),
    [],
  );

  const setPropertyTypeEmptyCondition = useCallback(
    (type: ConditionBlueprintType) => {
      const emptyCondition = getEmptyStateForConditionType(type);
      onConditionChange(emptyCondition);
    },
    [onConditionChange],
  );

  const onConditionPropertiesChange: ConditionPropertiesUpdateCallback = useCallback(
    (conditionProperties) => {
      const updatedCondition = {
        ...condition,
        ...conditionProperties,
      };

      const targetNodeIds = getTargetNodeIdsFromCondition(updatedCondition);

      // collection conditions with multiple target node ids should belong to the same collection
      const nodeIdInCollection = nodeIdInCollectionMap[targetNodeIds[0]];
      const isTargetNodeIdInCollection = !!nodeIdInCollection?.length;

      if (!isTargetNodeIdInCollection) {
        onConditionChange({ ...updatedCondition, collectionOperators: undefined });
      } else {
        // Set default collection operators if not set.
        const existingCollectionOperators = updatedCondition.collectionOperators ?? {};
        const updatedCollectionOperators: Record<string, BlueprintCollectionOperator> = {};

        for (const collectionNodeId of nodeIdInCollection) {
          if (existingCollectionOperators[collectionNodeId]) {
            // Don't set default if there is already a value.
            updatedCollectionOperators[collectionNodeId] = existingCollectionOperators[collectionNodeId];
            continue;
          }

          if (collectionContext.includes(collectionNodeId)) {
            updatedCollectionOperators[collectionNodeId] = BlueprintCollectionOperator.thisItem;
          } else {
            updatedCollectionOperators[collectionNodeId] = DEFAULT_COLLECTION_OPERATOR;
          }
        }

        onConditionChange({ ...updatedCondition, collectionOperators: updatedCollectionOperators });
      }
    },
    [condition, onConditionChange, nodeIdInCollectionMap, collectionContext],
  );

  if (!condition) {
    return null;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box display='flex' justifyContent='flex-end'>
          <Box px={1}>
            <IconButton
              onClick={onAddNestedCondition}
              aria-label='add a nested condition'
              icon={<AccountTreeIcon />}
              title={t('admin.conditions.labels.addNestedCondition')}
            />
          </Box>
          <Box px={1}>
            <IconButton
              onClick={onRemoveCondition}
              disabled={!isConditionRemovable}
              aria-label='remove a single condition'
              icon={<DeleteIcon />}
              title={t('admin.conditions.labels.removeCondition')}
            />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Select
          id='single-condition-condition-property-type-select'
          value={condition.type}
          onChange={setPropertyTypeEmptyCondition}
          options={conditionPropertyOptions}
          label={t('admin.conditions.labels.conditionBlueprintType')}
        />
      </Grid>
      <SingleConditionCriteria
        condition={condition}
        questionnaireNodeIds={questionnaireNodeIds}
        onConditionPropertiesChange={onConditionPropertiesChange}
        language={language}
        collectionContext={collectionContext}
        nodeIdInCollectionMap={nodeIdInCollectionMap}
        conditionIndex={conditionIndex}
      />
    </Grid>
  );
}
