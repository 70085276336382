import { UseMutationOptions, UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { QueryId } from '../../common/common.types';
import { useDispatch } from 'react-redux';
import i18next from 'i18next';
import { notificationSlice } from '../../../ReduxStore/Notification/NotificationSlice';
import {
  cancelAdHocSignatureRequest,
  restartAdHocSignatureRequest,
  startAdHocSignatureRequest,
} from '../../../Services/AdHocSignatureRequestsService';

export function useRestartAdHocSignatureRequestMutation(
  options?: UseMutationOptions<void, unknown, { adHocSignatureRequestId: string }>,
): UseMutationResult<void, unknown, { adHocSignatureRequestId: string }> {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const entity = i18next.t('admin.firmManagement.insuranceFirm.singular');
  return useMutation<void, unknown, { adHocSignatureRequestId: string }, unknown>(
    async (data: { adHocSignatureRequestId: string }) => {
      await restartAdHocSignatureRequest(data.adHocSignatureRequestId);
    },
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        dispatch(
          notificationSlice.actions.setSuccess({
            message: i18next.t('notifications.successfullyClosedAdHocSignatureRequest', { entity }),
            autoHideDuration: 3000,
          }),
        );

        await queryClient.invalidateQueries(QueryId.allAdHocSignatureRequests);
        await queryClient.invalidateQueries([QueryId.adHocSignatureRequest, variables.adHocSignatureRequestId]);

        if (options?.onSuccess) {
          await options.onSuccess(data, variables, context);
        }
      },

      onError: async (error, variables, context) => {
        dispatch(
          notificationSlice.actions.setError({
            message: i18next.t('notifications.failedToCloseAdHocSignatureRequest', { entity }),
          }),
        );

        if (options?.onError) {
          await options.onError(error, variables, context);
        }
      },
    },
  );
}

export function useStartAdHocSignatureRequestMutation(
  options?: UseMutationOptions<void, unknown, { adHocSignatureRequestId: string }>,
): UseMutationResult<void, unknown, { adHocSignatureRequestId: string }> {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const entity = i18next.t('admin.firmManagement.insuranceFirm.singular');
  return useMutation<void, unknown, { adHocSignatureRequestId: string }, unknown>(
    async (data: { adHocSignatureRequestId: string }) => {
      await startAdHocSignatureRequest(data.adHocSignatureRequestId);
    },
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        dispatch(
          notificationSlice.actions.setSuccess({
            message: i18next.t('notifications.successfullyClosedAdHocSignatureRequest', { entity }),
            autoHideDuration: 3000,
          }),
        );

        await queryClient.invalidateQueries(QueryId.allAdHocSignatureRequests);
        await queryClient.invalidateQueries([QueryId.adHocSignatureRequest, variables.adHocSignatureRequestId]);

        if (options?.onSuccess) {
          await options.onSuccess(data, variables, context);
        }
      },

      onError: async (error, variables, context) => {
        dispatch(
          notificationSlice.actions.setError({
            message: i18next.t('notifications.failedToCloseAdHocSignatureRequest', { entity }),
          }),
        );

        if (options?.onError) {
          await options.onError(error, variables, context);
        }
      },
    },
  );
}

export function useCancelAdHocSignatureRequestMutation(
  options?: UseMutationOptions<void, unknown, { adHocSignatureRequestId: string }>,
): UseMutationResult<void, unknown, { adHocSignatureRequestId: string }> {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const entity = i18next.t('admin.firmManagement.insuranceFirm.singular');
  return useMutation<void, unknown, { adHocSignatureRequestId: string }, unknown>(
    async (data: { adHocSignatureRequestId: string }) => {
      await cancelAdHocSignatureRequest(data.adHocSignatureRequestId);
    },
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        dispatch(
          notificationSlice.actions.setSuccess({
            message: i18next.t('notifications.successfullyCancelledAdHocSignatureRequest', { entity }),
            autoHideDuration: 3000,
          }),
        );

        await queryClient.invalidateQueries(QueryId.allAdHocSignatureRequests);
        await queryClient.invalidateQueries([QueryId.adHocSignatureRequest, variables.adHocSignatureRequestId]);

        if (options?.onSuccess) {
          await options.onSuccess(data, variables, context);
        }
      },

      onError: async (error, variables, context) => {
        dispatch(
          notificationSlice.actions.setError({
            message: i18next.t('notifications.failedToCancelAdHocSignatureRequest', { entity }),
          }),
        );

        if (options?.onError) {
          await options.onError(error, variables, context);
        }
      },
    },
  );
}
