import { ReactElement } from 'react';

import { SvgIconProps } from '../../Components/Icons';

export function LinkIcon(props: SvgIconProps): ReactElement {
  return (
    <svg stroke='#757575' fill='#f6f6f6' height='16' width='16' xmlns='http://www.w3.org/2000/svg' {...props.svgProps}>
      <path d='M6.243 12.334l-.622.62A3 3 0 011.38 8.713L4.56 5.53a3 3 0 014.55 3.883' />
      <g strokeLinecap='round' strokeLinejoin='round'>
        <path d='M6.243 12.334l-.622.62A3 3 0 011.38 8.713L4.56 5.53a3 3 0 014.55 3.883' />
        <path fill='none' d='M9.879 3.712l.5-.5a3 3 0 014.242 4.242l-3.182 3.182A3 3 0 016.89 6.752' />
      </g>
    </svg>
  );
}
