import { useState } from 'react';

import { Validations } from '@breathelife/questionnaire-engine';
import { FieldBlueprint, RadioFieldBlueprint, FieldTypes, StringFieldValidation, OptionSize } from '@breathelife/types';
import { AutocompleteOption } from '@breathelife/ui-components';

import {
  FieldSettersValidateAs,
  FieldSettersDefaultValue,
  FieldDataHookReturnValueWithValidationOptions,
  useCastBlueprintToFieldType,
} from '../../Helpers/helperTypes';
import { BaseFieldSetters, useBaseFieldData } from './useBaseFieldData';
import { useDefaultValue } from './useDefaultValue';
import { useValidateAsData } from './useValidateAsData';

export type RadioFieldSetters = FieldSettersValidateAs<RadioFieldBlueprint> &
  FieldSettersDefaultValue<RadioFieldBlueprint> &
  BaseFieldSetters & { setOptionSize: React.Dispatch<React.SetStateAction<RadioFieldBlueprint['optionSize']>> };

export const useRadioFieldData = ({
  initialData,
  dataLabelOptions,
}: {
  initialData?: Partial<FieldBlueprint>;
  dataLabelOptions: AutocompleteOption[];
}): FieldDataHookReturnValueWithValidationOptions<RadioFieldBlueprint, RadioFieldSetters> => {
  const fieldType = FieldTypes.radio;
  const { data, setters } = useBaseFieldData({ initialData, dataLabelOptions });

  const initialDataAsTypedField = useCastBlueprintToFieldType<RadioFieldBlueprint>(fieldType, initialData);
  const [optionSize, setOptionSize] = useState<RadioFieldBlueprint['optionSize']>(
    initialDataAsTypedField?.optionSize || OptionSize.half,
  );
  const {
    data: validateAsData,
    setters: validateAsSetters,
    validationOptions,
  } = useValidateAsData<RadioFieldBlueprint>({
    fieldType,
    initialValidateAs: StringFieldValidation.string,
  });
  const {
    data: defaultValueData,
    setters: defaultValueSetters,
    validationError,
  } = useDefaultValue<RadioFieldBlueprint>({
    validateAs: validateAsData.validateAs as unknown as Validations,
  });

  return {
    data: {
      fieldType,
      optionSize,
      ...data,
      ...validateAsData,
      ...defaultValueData,
      selectOptions: initialDataAsTypedField?.selectOptions || [],
    },
    setters: { ...setters, setOptionSize, ...validateAsSetters, ...defaultValueSetters },
    validationOptions,
    defaultValueValidationError: validationError,
  };
};
