import { Box } from '@breathelife/mui';
import { ReactElement, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { SubmitButton } from '../../../../Components/Button/SubmitButton';
import Typography from '../../../../Components/Typography';
import { ESignatureContext } from '../../../../Context/ESignatureContext';
import { ModalLayout } from '../../../../Layouts/Modal/ModalLayout';
import { useCancelESignCeremonyMutation } from '../../../../ReactQuery/ESignCeremony/eSignCeremony.mutations';

type CancelESignatureModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export function CancelESignatureModal(props: CancelESignatureModalProps): ReactElement {
  const { t } = useTranslation();
  const { isOpen, onClose } = props;

  const { application } = useContext(ESignatureContext);

  const cancelESignCeremonyMutation = useCancelESignCeremonyMutation({
    onSuccess: onClose,
  });

  const cancelESignCeremony = useCallback(() => {
    if (application?.id) {
      cancelESignCeremonyMutation.mutate(application?.id);
    }
  }, [application, cancelESignCeremonyMutation]);

  return (
    <ModalLayout
      maxWidth='sm'
      isOpen={isOpen}
      closeModal={onClose}
      title={t('cta.cancelESignatureProcess')}
      submitButton={
        <SubmitButton isLoading={cancelESignCeremonyMutation.isLoading} onClick={cancelESignCeremony}>
          {t('assistedApplication.cancelESignature')}
        </SubmitButton>
      }
    >
      <Box mb={3}>
        <Typography variant='body1'>{t('modals.assistedApplication.cancelESignature.body1')}</Typography>
      </Box>
      <Box>
        <Typography variant='body1'>{t('modals.assistedApplication.cancelESignature.body2')}</Typography>
      </Box>
    </ModalLayout>
  );
}
