import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, List } from '@breathelife/mui';
import { FileTemplate, Permission } from '@breathelife/types';

import { RequiredFile } from '../../../../../../Hooks/Application';
import { Application } from '../../../../../../Models/Application';
import { RestrictedToUserPermissions } from '../../../../../Restricted/RestrictedToUserPermissions';
import { NoFiles } from '../NoFiles';
import { CompletedRequiredFile } from './CompletedRequiredFile';
import { MissingRequiredFile } from './MissingRequiredFile';

type RequiredFilesProps = {
  application: Application;
  files: RequiredFile[];
  isUploading: boolean;
  onFileUpload: (file: File, template: FileTemplate) => void;
  onFileDelete: (fileId: string, isRequired: boolean) => void;
};

export function RequiredFiles(props: RequiredFilesProps): ReactElement {
  const { t } = useTranslation();
  const { application, files, isUploading, onFileUpload, onFileDelete } = props;

  if (!files.length) {
    return <NoFiles message={t('assistedApplication.documentsDrawer.noRequiredFiles')} />;
  }

  return (
    <List disablePadding>
      {files.map((file) => (
        <Box key={file.template.id} my={2}>
          {file.storedFile ? (
            <CompletedRequiredFile
              application={application}
              template={file.template}
              storedFile={file.storedFile}
              onFileDelete={onFileDelete}
            />
          ) : (
            <RestrictedToUserPermissions requiredPermissions={[Permission.ApplicationFileCreate]}>
              <MissingRequiredFile isUploading={isUploading} template={file.template} onFileUpload={onFileUpload} />
            </RestrictedToUserPermissions>
          )}
        </Box>
      ))}
    </List>
  );
}
