import _ from 'lodash';
import { Fragment, ReactElement } from 'react';

import Typography from '../../Components/Typography';
import { convertToReadableTime, getDayInText, getMethodDisplayText, TimeFormat } from '../../Helpers/communication';
import { BaseLeadCommunication, BaseLeadCommunicationSchedule } from '../../Models/Lead';
import { CommunicationToolTip, DayOfTheWeekName, Preview } from './Styles';

export type Props = {
  communication?: BaseLeadCommunication;
  format?: TimeFormat;
};

type DisplayableScheduleInfo = {
  dayOfTheWeekName: string;
  time: string;
};

const getDisplayableScheduleInfo = (
  schedule: BaseLeadCommunicationSchedule,
  format: TimeFormat,
): DisplayableScheduleInfo => {
  const dayOfTheWeekName = getDayInText(schedule.day, 'short');
  const time = schedule.time.map((time) => convertToReadableTime(time, format)).join(' - ');
  return {
    dayOfTheWeekName,
    time,
  };
};

const renderSchedules = (days: DisplayableScheduleInfo[]) => {
  return days.map((day) => (
    <div key={`${day.dayOfTheWeekName} - ${day.time}`}>
      <DayOfTheWeekName>{day.dayOfTheWeekName}</DayOfTheWeekName>
      <span>: {day.time}</span>
    </div>
  ));
};

const renderPreview = (days: DisplayableScheduleInfo[]) => {
  return days.map((day) => {
    return (
      <Fragment key={`${day.dayOfTheWeekName} - ${day.time}`}>
        <DayOfTheWeekName>{day.dayOfTheWeekName}</DayOfTheWeekName>
        <span>: {day.time}</span>
      </Fragment>
    );
  });
};

export function CommunicationView({ communication, format = '24' }: Props): ReactElement {
  if (!communication) return <span />;

  const preferredMethodText = getMethodDisplayText(communication?.preferredMethods);
  const schedules = communication?.['leads-communication-schedules'] || [];
  const days = schedules.map((schedule) => getDisplayableScheduleInfo(schedule, format));

  return _.includes(communication.preferredMethods, 'phone') ? (
    <CommunicationToolTip
      title={
        <Fragment>
          <Typography variant='small1'>
            {preferredMethodText && <p>{preferredMethodText}</p>}
            {renderSchedules(days)}
          </Typography>
        </Fragment>
      }
    >
      <Preview>{renderPreview(days)}</Preview>
    </CommunicationToolTip>
  ) : (
    <span>{preferredMethodText}</span>
  );
}
