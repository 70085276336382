import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Badge, Box } from '@breathelife/mui';
import { Permission } from '@breathelife/types';
import { ImageTypes } from '@breathelife/ui-components';

import { Button } from '../../../Components/Button/Button';
import { Icon } from '../../../Components/Icons';
import { RestrictedToUserPermissions } from '../../Restricted/RestrictedToUserPermissions';

type AssistedApplicationHeaderProps = {
  isDocumentsDrawerAlert: boolean;
  isDisabled: boolean;
  onOpenDocumentsDrawer: () => void;
};

export function DocumentsDrawerButton(props: AssistedApplicationHeaderProps): ReactElement | null {
  const { isDocumentsDrawerAlert, isDisabled, onOpenDocumentsDrawer } = props;
  const { t } = useTranslation();

  return (
    <RestrictedToUserPermissions requiredPermissions={[Permission.ApplicationFileRead]}>
      <Box>
        <Badge badgeContent='!' color='error' invisible={!isDocumentsDrawerAlert}>
          <Button
            color='secondary'
            variant='outlined'
            onClick={onOpenDocumentsDrawer}
            startIcon={<Icon name='files' imageType={ImageTypes.decorative} />}
            disabled={isDisabled}
          >
            {t('assistedApplication.files')}
          </Button>
        </Badge>
      </Box>
    </RestrictedToUserPermissions>
  );
}
