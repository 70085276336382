import { ReactElement, useContext, useState, ChangeEvent, Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Draggable,
  DraggableProvided,
  DragDropContext,
  DragStart,
  DropResult,
  Droppable,
  DroppableProvided,
} from '@hello-pangea/dnd';
import { Box, Button, FormControlLabel, Radio, RadioGroup, Typography } from '@breathelife/mui';
import { sortOptionsAlphabetically } from '@breathelife/questionnaire-engine';
import {
  SelectOptionFieldBlueprint,
  BlueprintUpdate,
  FieldPartIdentifier,
  Language,
  isDropdownFieldBlueprint,
  isRadioFieldBlueprint,
  isCheckBoxGroupFieldBlueprint,
  RadioFieldBlueprint,
  CheckBoxGroupFieldBlueprint,
  SelectOptionPartIdentifier,
  PartIdentifierTag,
  FieldTypes,
  SelectOptionBlueprint,
} from '@breathelife/types';
import styled from '../../../../../Styles/themed-styled-components';
import { TextInput } from '../../../../../Pages/Admin/Questionnaire/QuestionnaireEditor/Components/TextInput';
import { QuestionnaireNodeIds } from '../../../../../Helpers/questionnaireEditor/questionnaireNodeIds';
import {
  useAddQuestionnaireElementBlueprint,
  useUpdateQuestionnaireElementBlueprintOrder,
  useUpdateQuestionnaireElementBlueprint,
} from '../../../../../ReactQuery/Admin/Questionnaire/questionnaireVersion.mutations';
import { QuestionnaireVersionDataContext } from '../../ContextProvider/QuestionnaireVersionDataContextProvider';
import { CreateSelectOption } from '../Creators/CreateSelectOption';
import { DynamicOptionsEditor } from '../Editors/DynamicOptionsEditor';
import { SelectOptionBlueprintEditor } from '../Editors/SelectOptionBlueprintEditor';
import { ModalLayout } from '../../../../../Layouts/Modal/ModalLayout';
import { ItalicTypography } from '../../../../../Components/Typography';

const SelectOptionsContainer = styled(Box)`
  margin-bottom: 20px;
`;

const DroppableContainer = styled.div`
  margin: 10px 0;
  padding: 20px;
  background-color: ${(props) => props.theme.colors.grey[0]};
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.grey[30]};
`;

type OptionSource = 'applicationContext' | 'blueprint';

type SelectOptionsProps = {
  blueprint: SelectOptionFieldBlueprint;
  blueprintUpdate: (modification: BlueprintUpdate) => Promise<void>;
  collectionContext: string[];
  disabled: boolean;
  onUpdateDefaultValue: (value: any) => void;
  partIdentifier: FieldPartIdentifier;
  questionnaireNodeIds: QuestionnaireNodeIds;
  selectedLanguage: Language;
};

export function SelectOptions(props: SelectOptionsProps): ReactElement {
  const { partIdentifier, blueprintUpdate, blueprint, selectedLanguage, disabled } = props;

  const { t } = useTranslation();

  const [optionSource, setOptionSource] = useState<OptionSource>('blueprint');
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setOptionSource(blueprint.selectOptionsApplicationContext ? 'applicationContext' : 'blueprint');
  }, [blueprint.selectOptionsApplicationContext]);

  const toggleModal = (): void => setIsModalOpen((prevState) => !prevState);

  const resetExistingOptions = (): void => {
    if (optionSource === 'blueprint') {
      void blueprintUpdate({
        partIdentifier,
        update: { property: 'selectOptions', value: [] },
      });
    }

    if (optionSource === 'applicationContext') {
      void blueprintUpdate({
        partIdentifier,
        update: { property: 'selectOptionsApplicationContext', value: undefined },
      });
    }
  };

  const updateOptionSource = (): void => {
    setOptionSource((prevState) => (prevState === 'blueprint' ? 'applicationContext' : 'blueprint'));
    resetExistingOptions();
  };

  const onSubmitOptionSourceChange = (): void => {
    updateOptionSource();
    toggleModal();
  };

  const title = t('admin.questionnaireManagement.input.options.title');
  const sourceTitle = t('admin.questionnaireManagement.input.options.source.title');
  const radioLabelBlueprint = t('admin.questionnaireManagement.input.options.source.label.blueprint');
  const radioLabelApplicationContext = t('admin.questionnaireManagement.input.options.source.label.applicationContext');

  return (
    <Fragment>
      <ToggleOptionSourceModal isOpen={isModalOpen} onClose={toggleModal} onSubmit={onSubmitOptionSourceChange} />

      <Box pt={1}>
        <Typography variant='body1'>{title}</Typography>

        {isDropdownFieldBlueprint(blueprint) && (
          <Fragment>
            <Box pt={2}>
              <span>{sourceTitle}</span>
              <RadioGroup row defaultValue='blueprint' value={optionSource} onChange={toggleModal}>
                <FormControlLabel
                  control={<Radio />}
                  disabled={disabled}
                  label={radioLabelBlueprint}
                  value='blueprint'
                />
                <FormControlLabel
                  control={<Radio />}
                  disabled={disabled}
                  label={radioLabelApplicationContext}
                  value='applicationContext'
                />
              </RadioGroup>
            </Box>

            {optionSource === 'applicationContext' && (
              <ApplicationContextOptions
                blueprint={blueprint}
                blueprintUpdate={blueprintUpdate}
                disabled={disabled}
                partIdentifier={partIdentifier}
                selectedLanguage={selectedLanguage}
              />
            )}
          </Fragment>
        )}

        {optionSource === 'blueprint' && <BlueprintOptions {...props} />}
      </Box>
    </Fragment>
  );
}

type BlueprintOptionsProps = {
  blueprint: SelectOptionFieldBlueprint;
  blueprintUpdate: (modification: BlueprintUpdate) => Promise<void>;
  collectionContext: string[];
  disabled: boolean;
  onUpdateDefaultValue: (value: any) => void;
  partIdentifier: FieldPartIdentifier;
  questionnaireNodeIds: QuestionnaireNodeIds;
  selectedLanguage: Language;
};

function BlueprintOptions(props: BlueprintOptionsProps): ReactElement {
  const {
    blueprint,
    blueprintUpdate,
    collectionContext,
    disabled,
    onUpdateDefaultValue,
    partIdentifier,
    questionnaireNodeIds,
    selectedLanguage,
  } = props;

  return (
    <Box pr={2} pt={1}>
      {isDropdownFieldBlueprint(blueprint) && (
        <SelectOrderableOptionEditors
          blueprint={blueprint}
          collectionContext={collectionContext}
          disabled={disabled}
          partIdentifier={partIdentifier}
          questionnaireNodeIds={questionnaireNodeIds}
          selectedLanguage={selectedLanguage}
        />
      )}

      {(isRadioFieldBlueprint(blueprint) || isCheckBoxGroupFieldBlueprint(blueprint)) && (
        <SelectOptionEditors
          blueprint={blueprint}
          collectionContext={collectionContext}
          disabled={disabled}
          onUpdateDefaultValue={onUpdateDefaultValue}
          partIdentifier={partIdentifier}
          questionnaireNodeIds={questionnaireNodeIds}
          selectedLanguage={selectedLanguage}
        />
      )}

      <DynamicOptionsEditor
        blueprint={blueprint}
        blueprintUpdate={blueprintUpdate}
        collectionContext={collectionContext}
        disabled={disabled}
        partIdentifier={partIdentifier}
        questionnaireNodeIds={questionnaireNodeIds}
        selectedLanguage={selectedLanguage}
      />
    </Box>
  );
}

type ApplicationContextOptionsProps = {
  blueprint: SelectOptionFieldBlueprint;
  blueprintUpdate: (modification: BlueprintUpdate) => Promise<void>;
  disabled: boolean;
  partIdentifier: FieldPartIdentifier;
  selectedLanguage: Language;
};

function ApplicationContextOptions(props: ApplicationContextOptionsProps): ReactElement {
  const { blueprint, blueprintUpdate, partIdentifier, selectedLanguage, disabled } = props;

  const { t } = useTranslation();

  const [tag, setTag] = useState('');
  const [labelKey, setLabelKey] = useState({});
  const [valuePath, setValuePath] = useState('');

  useEffect(() => {
    setTag(blueprint.selectOptionsApplicationContext?.tag || '');
    setLabelKey(blueprint.selectOptionsApplicationContext?.labelKey || {});
    setValuePath(blueprint.selectOptionsApplicationContext?.valuePath || '');
  }, []);

  const onTagChange = (event: ChangeEvent<HTMLInputElement>): void => setTag(event.target.value);

  const onValuePathChange = (event: ChangeEvent<HTMLInputElement>): void => setValuePath(event.target.value);

  const onLabelKeyChange = (event: ChangeEvent<HTMLInputElement>): void => {
    event.persist();
    setLabelKey((prevState) => ({ ...prevState, [selectedLanguage]: event.target.value }));
  };

  const saveChanges = (): void => {
    const selectOptionsApplicationContextBlueprint = {
      tag,
      labelKey,
      valuePath,
    };

    void blueprintUpdate({
      partIdentifier,
      update: {
        property: 'selectOptionsApplicationContext',
        value: selectOptionsApplicationContextBlueprint,
      },
    });
  };

  const dataTypeAsterisk = t('admin.questionnaireManagement.input.options.source.dataTypeAsterisk');

  const tagLabel = t('admin.questionnaireManagement.input.options.source.label.tag');
  const valuePathLabel = t('admin.questionnaireManagement.input.options.source.label.valuePath');
  const labelKeyLabel = t('admin.questionnaireManagement.input.options.source.label.labelKey');

  const tagPlaceholder = t('admin.questionnaireManagement.input.options.source.tagPlaceholder');
  const valuePathPlaceholder = t('admin.questionnaireManagement.input.options.source.valuePathPlaceholder');
  const labelKeyPlaceholder = t('admin.questionnaireManagement.input.options.source.labelKeyPlaceholder');

  return (
    <Box>
      <ItalicTypography variant='small1'>{dataTypeAsterisk}</ItalicTypography>

      <TextInput
        disabled={disabled}
        label={tagLabel}
        onBlur={saveChanges}
        onChange={onTagChange}
        placeholder={tagPlaceholder}
        value={tag}
      />

      <TextInput
        disabled={disabled}
        label={labelKeyLabel}
        onBlur={saveChanges}
        onChange={onLabelKeyChange}
        placeholder={labelKeyPlaceholder}
        value={labelKey[selectedLanguage] ?? ''}
      />

      <TextInput
        disabled={disabled}
        label={valuePathLabel}
        onBlur={saveChanges}
        onChange={onValuePathChange}
        placeholder={valuePathPlaceholder}
        value={valuePath}
      />
    </Box>
  );
}

type SelectOptionEditorsProps = {
  blueprint: RadioFieldBlueprint | CheckBoxGroupFieldBlueprint;
  collectionContext: string[];
  disabled: boolean;
  onUpdateDefaultValue: (newDefaultValue: any) => void;
  partIdentifier: FieldPartIdentifier;
  questionnaireNodeIds: QuestionnaireNodeIds;
  selectedLanguage: Language;
};

function SelectOptionEditors(props: SelectOptionEditorsProps): ReactElement {
  const {
    blueprint,
    partIdentifier,
    selectedLanguage,
    disabled,
    onUpdateDefaultValue,
    questionnaireNodeIds,
    collectionContext,
  } = props;
  const { questionnaireVersionId } = useContext(QuestionnaireVersionDataContext);

  const addBlueprint = useAddQuestionnaireElementBlueprint(questionnaireVersionId);
  const reorderBlueprint = useUpdateQuestionnaireElementBlueprintOrder(questionnaireVersionId);

  const optionIds = blueprint.selectOptions.map((optionBlueprint) => optionBlueprint.partName);

  function toggleOptionDefaultValueState(partName: string): void {
    let newDefaultValue: string[] = [];
    if (Array.isArray(blueprint.defaultValue)) {
      newDefaultValue = [...blueprint.defaultValue];
    }
    if (newDefaultValue.includes(partName)) {
      newDefaultValue = newDefaultValue.filter((item) => item !== partName);
    } else {
      newDefaultValue.push(partName);
    }

    onUpdateDefaultValue(newDefaultValue);
  }

  const selectOptionEditors = blueprint.selectOptions.map((optionBlueprint, idx) => {
    const selectOptionPartIdentifier: SelectOptionPartIdentifier = {
      ...partIdentifier,
      selectOptionPartName: optionBlueprint.partName,
      tag: PartIdentifierTag.selectOption,
    };

    const isDefaultValue =
      blueprint.defaultValue === optionBlueprint.partName ||
      (Array.isArray(blueprint.defaultValue) && blueprint.defaultValue.includes(optionBlueprint.partName));

    return (
      <Draggable
        draggableId={`${optionBlueprint.partName}`}
        index={idx}
        key={optionBlueprint.partName}
        isDragDisabled={disabled || blueprint.selectOptions.length <= 1}
      >
        {(provided: DraggableProvided) => (
          <SelectOptionBlueprintEditor
            ref={provided.innerRef}
            key={optionBlueprint.partName}
            blueprint={optionBlueprint}
            partIdentifier={selectOptionPartIdentifier}
            selectedLanguage={selectedLanguage}
            parentHidden={disabled}
            draggableProps={provided.draggableProps}
            dragHandleProps={provided.dragHandleProps || undefined}
            isDraggable={blueprint.selectOptions.length > 1}
            isDefaultValue={isDefaultValue}
            defaultValueButtonProps={
              blueprint.fieldType === FieldTypes.checkboxGroup
                ? { onClick: () => toggleOptionDefaultValueState(optionBlueprint.partName) }
                : undefined
            }
            questionnaireNodeIds={questionnaireNodeIds}
            collectionContext={collectionContext}
            condition={optionBlueprint.visible}
            existingOptionIds={optionIds}
          />
        )}
      </Draggable>
    );
  });

  const [draggedField, setDraggedField] = useState<string | undefined>(undefined);

  return (
    <SelectOptionsContainer>
      <DragDropContext
        onDragStart={(dragStart: DragStart) => setDraggedField(dragStart.draggableId)}
        onDragEnd={(dropResult: DropResult) => {
          if (
            !draggedField ||
            typeof dropResult?.destination?.index === 'undefined' ||
            blueprint.selectOptions[dropResult.destination.index].partName === draggedField
          ) {
            return;
          }

          const destinationIndex = dropResult.destination.index;
          const previousSiblingIndex = destinationIndex - 1;
          const previousSibling = previousSiblingIndex >= 0 ? blueprint.selectOptions[previousSiblingIndex] : undefined;

          void reorderBlueprint({
            sourcePartIdentifier: {
              ...partIdentifier,
              selectOptionPartName: draggedField,
              tag: PartIdentifierTag.selectOption,
            },
            targetParentPartIdentifier: partIdentifier,
            targetSiblingPartIdentifier: previousSibling
              ? {
                  ...partIdentifier,
                  selectOptionPartName: previousSibling.partName,
                  tag: PartIdentifierTag.selectOption,
                }
              : undefined,
          });
        }}
      >
        <Droppable droppableId={`${blueprint.partName}-droppable`}>
          {(provided: DroppableProvided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {selectOptionEditors}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Box pt={1}>
        <CreateSelectOption
          onCreate={(selectOptionBlueprint) => addBlueprint(selectOptionBlueprint, partIdentifier)}
          existingOptionIds={optionIds}
          disabled={disabled}
        />
      </Box>
    </SelectOptionsContainer>
  );
}

type SelectOrderableOptionEditorsProps = {
  blueprint: SelectOptionFieldBlueprint | CheckBoxGroupFieldBlueprint;
  collectionContext: string[];
  disabled: boolean;
  partIdentifier: FieldPartIdentifier;
  questionnaireNodeIds: QuestionnaireNodeIds;
  selectedLanguage: Language;
};

function SelectOrderableOptionEditors(props: SelectOrderableOptionEditorsProps): ReactElement {
  const { blueprint, partIdentifier, selectedLanguage, disabled, questionnaireNodeIds, collectionContext } = props;
  const { t } = useTranslation();
  const { questionnaireVersionId } = useContext(QuestionnaireVersionDataContext);

  const addBlueprint = useAddQuestionnaireElementBlueprint(questionnaireVersionId);
  const updateBlueprint = useUpdateQuestionnaireElementBlueprint(questionnaireVersionId);

  const selectOptions = blueprint.selectOptions;
  const optionIds = selectOptions.map((optionBlueprint) => optionBlueprint.partName);

  const [draggedField, setDraggedField] = useState<string | undefined>(undefined);

  function getSelectOptionEditor(optionBlueprint: SelectOptionBlueprint, index: number): React.ReactElement {
    const selectOptionPartIdentifier: SelectOptionPartIdentifier = {
      ...partIdentifier,
      selectOptionPartName: optionBlueprint.partName,
      tag: PartIdentifierTag.selectOption,
    };

    const isDefaultValue =
      blueprint.defaultValue === optionBlueprint.partName ||
      (Array.isArray(blueprint.defaultValue) && blueprint.defaultValue.includes(optionBlueprint.partName));

    return (
      <Draggable
        draggableId={`${optionBlueprint.partName}`}
        index={index}
        key={optionBlueprint.partName}
        isDragDisabled={disabled || selectOptions.length <= 1}
      >
        {(provided: DraggableProvided) => (
          <SelectOptionBlueprintEditor
            ref={provided.innerRef}
            key={optionBlueprint.partName}
            blueprint={optionBlueprint}
            partIdentifier={selectOptionPartIdentifier}
            selectedLanguage={selectedLanguage}
            parentHidden={disabled}
            draggableProps={provided.draggableProps}
            dragHandleProps={provided.dragHandleProps || undefined}
            isDraggable={selectOptions.length > 1}
            isDefaultValue={isDefaultValue}
            questionnaireNodeIds={questionnaireNodeIds}
            collectionContext={collectionContext}
            condition={optionBlueprint.visible}
            existingOptionIds={optionIds}
          />
        )}
      </Draggable>
    );
  }

  const groupedSelectOptions: SelectOptionBlueprint[][] = Array.from(Array(3), () => []);
  selectOptions.map((optionBlueprint: SelectOptionBlueprint) => {
    const orderingIndex = optionBlueprint?.orderingIndex === undefined ? 1 : optionBlueprint.orderingIndex; // TODO: FIXME: Remove the default to 1 after we run the ordering index default migration in all environements
    groupedSelectOptions[orderingIndex].push(optionBlueprint);
  });

  return (
    <SelectOptionsContainer>
      <DragDropContext
        onDragStart={(dragStart: DragStart) => setDraggedField(dragStart.draggableId)}
        onDragEnd={(dropResult: DropResult) => {
          if (!draggedField || typeof dropResult?.destination?.index === 'undefined') {
            return;
          }

          const newOrderingIndex = parseInt(dropResult?.destination?.droppableId.slice(-1));

          // Return early if we move the option to the same previous droppable box
          const selectOption = selectOptions.find(({ partName }) => partName === draggedField);
          if (!selectOption || selectOption.orderingIndex === newOrderingIndex) {
            return;
          }

          const selectOptionPartIdentifier: SelectOptionPartIdentifier = {
            ...partIdentifier,
            selectOptionPartName: draggedField,
            tag: PartIdentifierTag.selectOption,
          };

          void updateBlueprint({
            partIdentifier: selectOptionPartIdentifier,
            update: { property: 'orderingIndex', value: newOrderingIndex },
          });
        }}
      >
        {groupedSelectOptions.map((selectOptionsGroup, index) => (
          <Droppable droppableId={`${blueprint.partName}-droppable-${index}`} key={index}>
            {(provided: DroppableProvided) => (
              <DroppableContainer ref={provided.innerRef} {...provided.droppableProps}>
                {t(`admin.questionnaireManagement.input.options.${index}`)}
                <Box>
                  {(sortOptionsAlphabetically(selectOptionsGroup, selectedLanguage) as SelectOptionBlueprint[]).map(
                    (selectOption, index) => {
                      return getSelectOptionEditor(selectOption, index);
                    },
                  )}
                  <Box>{provided.placeholder}</Box>
                </Box>
              </DroppableContainer>
            )}
          </Droppable>
        ))}
      </DragDropContext>
      <Box pt={1}>
        <CreateSelectOption
          onCreate={(selectOptionBlueprint) => addBlueprint(selectOptionBlueprint, partIdentifier)}
          existingOptionIds={optionIds}
          disabled={disabled}
          isOrderable={true}
        />
      </Box>
    </SelectOptionsContainer>
  );
}

type ToggleOptionSourceModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

function ToggleOptionSourceModal(props: ToggleOptionSourceModalProps): ReactElement {
  const { t } = useTranslation();

  const title = t('admin.questionnaireManagement.input.options.source.modal.title');
  const confirm = t('admin.questionnaireManagement.input.options.source.modal.buttonConfirm');
  const content = t('admin.questionnaireManagement.input.options.source.modal.content');

  return (
    <ModalLayout
      maxWidth='sm'
      title={title}
      isOpen={props.isOpen}
      closeModal={props.onClose}
      submitButton={
        <Button variant='contained' onClick={props.onSubmit}>
          {confirm}
        </Button>
      }
    >
      <Typography variant='body1'>{content}</Typography>
    </ModalLayout>
  );
}
