import { SVGProps, ReactElement } from 'react';

export function NaPending(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg fill='none' height='182' viewBox='0 0 170 182' width='170' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='m85 176.5c46.944 0 85-38.056 85-85 0-46.9442-38.056-85-85-85-46.9442 0-85 38.0558-85 85 0 46.944 38.0558 85 85 85z'
        fill='#fcfcfc'
      />
      <path
        d='m116 132.5c0-1.316-14.048-2.5-31.4936-2.5-17.4454 0-31.5064 1.053-31.5064 2.5s14.0482 2.5 31.5064 2.5c17.4586 0 31.4936-1.118 31.4936-2.5z'
        fill='#eee'
      />
      <g stroke='#757575' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1'>
        <path
          d='m55.6871 55.8932c-2.0815 1.1615-2.8232 3.7893-1.6617 5.8679l14.5417 23.7188h46.9739c-.001-.7849-.431-1.5401-1.178-1.9205l-54.6652-27.7697c-1.2737-.6267-2.7715-.5893-4.0107.1035z'
          fill='#f6f6f6'
        />
        <path
          d='m114.362 87.4034c.406-.2042.736-.5348.943-.943.16-.315.236-.6503.235-.9804h-46.9737l-14.5417 23.759c-.6929 1.239-.7303 2.74-.1035 4.011 1.0551 2.136 3.6397 3.015 5.7759 1.96z'
          fill='#fff'
        />
      </g>
    </svg>
  );
}
