import { ReactElement } from 'react';

import { Select, SelectProps } from '../Select/Select';

import { US_STATES } from '../../Helpers/locations';

type UsStateSelectProps = Omit<SelectProps, 'options'>;

export function UsStateSelect(props: UsStateSelectProps): ReactElement {
  const options = Object.entries(US_STATES).map(([value, label]) => ({
    value,
    label,
  }));
  return <Select {...props} options={options} />;
}
