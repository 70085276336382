import { Box } from '@breathelife/mui';
import styled from '../../../../Styles/themed-styled-components';

export const UserEditContainer = styled.div`
  display: flex;
  align-items: stretch;
  padding: 10px 0;
`;

export const ProfilePreferencesContainer = styled(Box)`
  border-left: 1px solid ${(props) => props.theme.colors.grey[30]};
`;
