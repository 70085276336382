import dayjs from 'dayjs';
import { ReactElement, Fragment } from 'react';

import Typography from '../../../../../../Components/Typography';
import { Application } from '../../../../../../Models/Application';

type ApplicationHeaderInfoProps = {
  application: Application | null;
};

export function ApplicationHeaderInfo({ application }: ApplicationHeaderInfoProps): ReactElement | null {
  if (!application) return null;

  const date = dayjs(application.createdAt || new Date()).format('MMM. D, YYYY');

  return (
    <Fragment>
      <Typography variant='h3'>{date}</Typography>
    </Fragment>
  );
}
