import i18next from 'i18next';
import * as yup from 'yup';

import { FileTemplate, FileTemplateOrigin, StoredFileDocType, DocumentVariant } from '@breathelife/types';

import { findFileTemplates } from '../../../Services/FileTemplateService';
import { getDefaultNameSchema } from '../field';

export type DocumentFormFields = keyof Omit<
  FileTemplate & {
    engName: string;
    frName: string;
  },
  'id' | 'createdAt' | 'updatedAt' | 'lastUsedAt' | 'archived' | 'localizedName' | 'name'
>;

const getSchemaMap = (): {
  [field in DocumentFormFields]:
    | yup.StringSchema<string | null | undefined>
    | yup.BooleanSchema<boolean | undefined | null>;
} => ({
  engName: getDefaultNameSchema().required(i18next.t('validation.error.required')),
  frName: getDefaultNameSchema().required(i18next.t('validation.error.required')),
  origin: yup.string().oneOf(Object.values(FileTemplateOrigin)).required(i18next.t('validation.error.required')),
  docType: yup.string().oneOf(Object.values(StoredFileDocType)).required(i18next.t('validation.error.required')),
  identifier: yup.string().nullable(),
  isRequired: yup.boolean().nullable(),
  variant: yup.string().oneOf([...Object.values(DocumentVariant), 'none']),
  externalUrl: yup
    .string()
    .nullable()
    .url()
    .when('origin', {
      is: FileTemplateOrigin.ExternalStatic,
      then: (schema: yup.StringSchema) => schema.required(i18next.t('validation.error.required')),
    }),
  storageBucket: yup
    .string()
    .nullable()
    .when('origin', {
      is: FileTemplateOrigin.GCPBucket,
      then: (schema: yup.StringSchema) => schema.required(i18next.t('validation.error.required')),
    }),
  storageKey: yup
    .string()
    .nullable()
    .when('origin', {
      is: FileTemplateOrigin.GCPBucket,
      then: (schema: yup.StringSchema) => schema.required(i18next.t('validation.error.required')),
    }),
});

const getAsyncSchemaMap = (): {
  [field in DocumentFormFields]:
    | yup.StringSchema<string | null | undefined>
    | yup.BooleanSchema<boolean | undefined | null>;
} => ({
  ...getSchemaMap(),
  identifier: yup
    .string()
    .nullable()
    .test('is-unique', i18next.t('validation.error.unique'), async (value) => {
      if (value === null || value === undefined) return true;

      const queryResult = await findFileTemplates({ identifier: value });

      return queryResult.length === 0;
    }),
});

const getAsyncFormSchema = (): yup.ObjectSchema => yup.object().shape(getAsyncSchemaMap());

const getFormSchema = (): yup.ObjectSchema => yup.object().shape(getSchemaMap());

export const DocumentTemplateValidation = {
  getSchemaMap,
  getAsyncSchemaMap,
  getAsyncFormSchema,
  getFormSchema,
};
