import { ReactElement } from 'react';

import { SvgIconProps } from '../../Components/Icons';

export function CircledCheckmarkIcon(props: SvgIconProps): ReactElement {
  return (
    <svg
      fill='##757575'
      stroke='none'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      xmlns='http://www.w3.org/2000/svg'
      {...props.svgProps}
    >
      <path
        opacity='0.5'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10ZM13.5719 6.00976C13.7831 5.97543 13.9984 6.03246 14.1704 6.16828C14.3429 6.30369 14.4581 6.50702 14.4906 6.73348C14.5231 6.95995 14.4703 7.19098 14.3438 7.3757L10.0247 13.6524C9.89319 13.8395 9.69709 13.962 9.48044 13.9925C9.26379 14.0231 9.04478 13.959 8.87266 13.8148L5.78931 11.172C5.45268 10.8705 5.40271 10.336 5.67684 9.96903C5.95096 9.60201 6.44862 9.53712 6.79689 9.82298L9.22529 11.9049L13.0442 6.35133C13.1708 6.16697 13.3607 6.04409 13.5719 6.00976Z'
      />
    </svg>
  );
}
