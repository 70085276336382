import { ReactElement, Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonName, TypewriterTracking } from '@breathelife/frontend-tracking';
import { Skeleton, Box } from '@breathelife/mui';
import { Permission } from '@breathelife/types';

import { StatusBadge } from '../../../../Components/Badge/StatusBadge';
import { CloseButton } from '../../../../Components/Button/CloseButton';
import { Checkbox } from '../../../../Components/Checkbox/Checkbox';
import { AssignToSelection } from '../../../../Components/Columns/AssignToSelection';
import { RestrictedToUserPermissions } from '../../../../Components/Restricted/RestrictedToUserPermissions';
import { useCarrierContext } from '../../../../Hooks';
import { Application } from '../../../../Models/Application';
import { Lead } from '../../../../Models/Lead';
import { useUpdateLeadMutation } from '../../../../ReactQuery/Lead/lead.mutations';
import { ActionButtons } from './ActionButtons';
import { Container, FullName, InlineContent } from './Styles';

export type Props = {
  lead?: Lead;
  onSendEmailClick: (lead: Lead) => void;
  closeRightPanel: () => void;
  onToggleArchiveClick: (lead: Lead) => void;
  onCopyClick: (application: Application) => void;
  onDeleteClick: (lead: Lead) => void;
  onDownloadFilesClick: (lead: Lead) => void;
};

export function LeadDetailHeader(props: Props): ReactElement {
  const { t } = useTranslation();
  const { isEmailActionEnabled } = useCarrierContext();
  const { lead } = props;

  const isSendNeedsAnalysisButtonEnabled = !!lead?.assignedToId && isEmailActionEnabled(lead.status);

  const updateLeadMutation = useUpdateLeadMutation({
    onMutate: () => {
      TypewriterTracking.clickedButton({ buttonName: ButtonName.leadContactedToggle, hashedId: null });
    },
  });

  const onToggleContactedClick = useCallback(() => {
    if (!lead) return;
    updateLeadMutation.mutate({
      contacted: !lead.contacted,
      id: lead.id,
    });
  }, [lead, updateLeadMutation]);

  const fullName = useMemo(() => {
    if (!lead) return <Skeleton width={120} height='2em' />;
    return [lead.firstName, lead.lastName].filter(Boolean).join(' ');
  }, [lead]);

  return (
    <Container>
      <InlineContent>
        <div>
          <FullName variant='h1'>{fullName}</FullName>
          {lead ? <StatusBadge status={lead.status ?? 'new'} /> : <Skeleton width={80} height='2em' />}
        </div>
        <CloseButton data-testid='leadDetailHeader-close' onClick={props.closeRightPanel} />
      </InlineContent>
      <Box display='flex' justifyContent='space-between' alignItems='center' mt={2}>
        <Box display='flex' alignItems='center' flexWrap='wrap' flex={1}>
          {lead && (
            <Fragment>
              <RestrictedToUserPermissions requiredPermissions={[Permission.LeadAssignRead]}>
                <Box mr={2} flex={1} maxWidth='150px'>
                  <AssignToSelection lead={lead} />
                </Box>
              </RestrictedToUserPermissions>
              <RestrictedToUserPermissions requiredPermissions={[Permission.LeadEditContacted]}>
                <Box whiteSpace='nowrap'>
                  <Checkbox
                    checked={!!lead.contacted}
                    onChange={onToggleContactedClick}
                    label={t('leadDetailDrawer.connectedWithClient')}
                  />
                </Box>
              </RestrictedToUserPermissions>
            </Fragment>
          )}
          {!lead && <Skeleton width={240} height='2em' />}
        </Box>
        <Box display='flex' justifyContent='flex-end'>
          <ActionButtons
            lead={lead}
            isLoading={!lead}
            onSendEmailClick={props.onSendEmailClick}
            onToggleArchiveClick={props.onToggleArchiveClick}
            onCopyClick={props.onCopyClick}
            onDeleteClick={props.onDeleteClick}
            onDownloadFilesClick={props.onDownloadFilesClick}
            disableEmail={!isSendNeedsAnalysisButtonEnabled}
          />
        </Box>
      </Box>
    </Container>
  );
}
