import { ReactElement } from 'react';

import { PaymentSubsectionActions, PaymentSubsectionActionsProps } from '../../PaymentSubsectionActions';
import { useAssistedApplicationContext } from '../../../../../../../../Hooks/useAssistedApplicationContext';

export type StripeSubsectionViewProps = Pick<
  PaymentSubsectionActionsProps,
  'lastFourCardDigits' | 'onAddClick' | 'onEditClick'
>;

export function StripeSubsectionView(props: StripeSubsectionViewProps): ReactElement {
  const { lastFourCardDigits, onAddClick, onEditClick } = props;
  const { areAllFieldsDisabled } = useAssistedApplicationContext();

  return (
    <PaymentSubsectionActions
      lastFourCardDigits={lastFourCardDigits}
      isEditable={!areAllFieldsDisabled}
      onAddClick={onAddClick}
      onEditClick={onEditClick}
    />
  );
}
