import { Mga, Theme, UserGroup } from '@breathelife/types';

export function getThemeFromUserGroups(userGroups: UserGroup[]): Theme | undefined {
  const userGroupWithTheme = userGroups.find((userGroup) => userGroup.theme && userGroup.theme?.length > 0);
  if (userGroupWithTheme && userGroupWithTheme.theme && userGroupWithTheme.theme.length > 0) {
    return userGroupWithTheme.theme[0];
  }
  return;
}

export function getMgaFromUserGroups(userGroups: UserGroup[]): Mga | undefined {
  const userGroupWithMga = userGroups.find((userGroup) => userGroup.mga);
  if (userGroupWithMga && userGroupWithMga.mga) {
    return userGroupWithMga.mga;
  }
  return;
}
