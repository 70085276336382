import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { PageLayoutTab } from '../Layouts/PageLayout/PageLayout';
import {
  generateApplicationUnderwritingReportsUrl,
  generateApplicationSupportOverviewUrl,
  generateAssociatedFilesUrl,
  generateApplicationSupportESignatureUrl,
} from '../Navigation/Urls';
import { useCarrierContext } from './useCarrierContext';

export function useApplicationSupportTabs(): PageLayoutTab[] {
  const { t } = useTranslation();
  const { features } = useCarrierContext();
  const params = useParams<{ applicationId: string }>();
  const { applicationId = '' } = params;

  const applicationSupportTabs: PageLayoutTab[] = [
    {
      label: t('applicationSupport.tabs.overview'),
      ['data-testid']: 'application-support-tab-overview',
      to: generateApplicationSupportOverviewUrl(applicationId),
    },
    {
      label: t('applicationSupport.tabs.eSignature'),
      ['data-testid']: 'application-support-tab-esignature',
      to: generateApplicationSupportESignatureUrl(applicationId),
    },
    {
      label: t('applicationSupport.tabs.underwriting'),
      ['data-testid']: 'application-support-tab-underwriting',
      to: generateApplicationUnderwritingReportsUrl(applicationId),
    },
  ];
  if (features.applicationSupportAssociatedFiles?.enabled) {
    const associatedFilesTab: PageLayoutTab = {
      label: t('applicationSupport.tabs.associatedFiles'),
      ['data-testid']: 'application-support-tab-associated-files',
      to: generateAssociatedFilesUrl(applicationId),
    };
    applicationSupportTabs.push(associatedFilesTab);
  }

  return applicationSupportTabs;
}
