import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { ApplicationPointOfSaleDecisions, OutcomeCode } from '@breathelife/types';

import { QueryId } from '../../ReactQuery/common/common.types';
import { getPointOfSaleDecisions } from '../../Services/PointOfSaleDecisionsService';

/**
 * @description Hook to retrieve point of sale decision specifically for single insured.
 * @returns The latest point of sale decision outcome code for a single insured.
 */
export function useGetPointOfSaleDecisionsOutcomeForSingleInsured(
  applicationId?: string | undefined,
  options?: UseQueryOptions<ApplicationPointOfSaleDecisions, unknown, OutcomeCode>,
): UseQueryResult<OutcomeCode> {
  return useQuery<ApplicationPointOfSaleDecisions, unknown, OutcomeCode>(
    [QueryId.pointOfSaleDecision, applicationId],
    async () => {
      if (!applicationId) {
        return Promise.reject(new Error('application id is required.'));
      }
      return await getPointOfSaleDecisions(applicationId);
    },
    {
      enabled: !!applicationId,
      select: (data: ApplicationPointOfSaleDecisions) => Object.values(data.decisions)[0]?.outcome,
      ...options,
    },
  );
}

export function useGetPointOfSaleDecisions(
  applicationId?: string | undefined,
  options?: UseQueryOptions<ApplicationPointOfSaleDecisions>,
): UseQueryResult<ApplicationPointOfSaleDecisions> {
  return useQuery<ApplicationPointOfSaleDecisions>(
    [QueryId.pointOfSaleDecision, applicationId],
    async () => {
      if (!applicationId) {
        return Promise.reject(new Error('application id is required.'));
      }
      return await getPointOfSaleDecisions(applicationId);
    },
    {
      enabled: !!applicationId,
      ...options,
    },
  );
}
