import { Box } from '@breathelife/mui';
import { ReactNode, ReactElement } from 'react';

import { LIST_FILTER_HEIGHT } from '../../Models/Layout';

type ListFilterContainerProps = {
  children: ReactNode;
};

export function ListFilterContainer(props: ListFilterContainerProps): ReactElement {
  return (
    <Box display='flex' alignItems='center' justifyContent='space-between' width='100%' minHeight={LIST_FILTER_HEIGHT}>
      {props.children}
    </Box>
  );
}
