import { ReactElement } from 'react';

import { SvgIconProps } from '../../Components/Icons';

export function NotificationBellIcon(props: SvgIconProps): ReactElement {
  return (
    <svg width='24=' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props.svgProps}>
      <path
        d='M12.0003 0C11.0521 0 10.286 0.670312 10.286 1.5V2.4C6.37527 3.09375 3.42884 6.12187 3.42884 9.75V10.9406C3.42884 13.0687 2.59848 15.1359 1.08241 16.7953L0.284196 17.6719C-0.0265188 18.0094 -0.0854474 18.4734 0.128838 18.8625C0.343124 19.2516 0.793124 19.5 1.28598 19.5H22.7146C23.2074 19.5 23.6574 19.2516 23.8717 18.8625C24.086 18.4734 24.0271 18.0094 23.7163 17.6719L22.9181 16.8C21.4021 15.1359 20.5717 13.0687 20.5717 10.9406V9.75C20.5717 6.12187 17.6253 3.09375 13.7146 2.4V1.5C13.7146 0.670312 12.9485 0 12.0003 0ZM12.0003 4.5C15.3163 4.5 18.0003 6.84844 18.0003 9.75V10.9406C18.0003 13.1859 18.7449 15.375 20.1271 17.25H3.87348C5.25562 15.375 6.00027 13.1859 6.00027 10.9406V9.75C6.00027 6.84844 8.6842 4.5 12.0003 4.5ZM15.4288 21H12.0003H8.5717C8.5717 21.7969 8.93062 22.5609 9.57348 23.1234C10.2163 23.6859 11.0896 24 12.0003 24C12.911 24 13.7842 23.6859 14.4271 23.1234C15.0699 22.5609 15.4288 21.7969 15.4288 21Z'
        fill='#757575'
      />

      <defs>
        <clipPath id='clip0_18_1662'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
