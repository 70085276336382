import { Notification } from '@breathelife/types';
import ApiService from './ApiService';

export async function updateNotification(id: string, data: Partial<Notification>): Promise<Notification> {
  const response = await ApiService.updateNotification(id, data);
  return response.data;
}

export async function updateAllNotifications(data: Partial<Notification>): Promise<Notification[]> {
  const response = await ApiService.updateAllNotifications(data);
  return response.data;
}

export async function fetchAllNotifications(cursorDate: Date | undefined): Promise<Notification[]> {
  const response = await ApiService.fetchAllNotifications(cursorDate);
  return response.data;
}
