import { Validations } from '@breathelife/questionnaire-engine';
import { FieldBlueprint, SelectOptionFieldBlueprint, FieldTypes, StringFieldValidation } from '@breathelife/types';
import { AutocompleteOption } from '@breathelife/ui-components';
import { useState } from 'react';

import {
  FieldSettersValidateAs,
  FieldSettersDefaultValue,
  FieldDataHookReturnValueWithValidationOptions,
  useCastBlueprintToFieldType,
} from '../../Helpers/helperTypes';
import { BaseFieldSetters, useBaseFieldData } from './useBaseFieldData';
import { useDefaultValue } from './useDefaultValue';
import { useValidateAsData } from './useValidateAsData';

export type DropdownFieldSetters = FieldSettersValidateAs<SelectOptionFieldBlueprint> &
  FieldSettersDefaultValue<SelectOptionFieldBlueprint> & {
    setSearchable: (searchable: SelectOptionFieldBlueprint['searchable']) => void;
  } & BaseFieldSetters;

export const useDropdownFieldData = ({
  initialData,
  dataLabelOptions,
}: {
  initialData?: Partial<FieldBlueprint>;
  dataLabelOptions: AutocompleteOption[];
}): FieldDataHookReturnValueWithValidationOptions<SelectOptionFieldBlueprint, DropdownFieldSetters> => {
  const fieldType = FieldTypes.dropdown;
  const { data, setters } = useBaseFieldData({ initialData, dataLabelOptions });
  const initialDataAsTypedField = useCastBlueprintToFieldType<SelectOptionFieldBlueprint>(fieldType, initialData);

  const [searchable, setSearchable] = useState<SelectOptionFieldBlueprint['searchable']>(
    initialDataAsTypedField?.searchable,
  );

  const {
    data: validateAsData,
    setters: validateAsSetters,
    validationOptions,
  } = useValidateAsData<SelectOptionFieldBlueprint>({
    fieldType,
    initialValidateAs: StringFieldValidation.string,
  });
  const {
    data: defaultValueData,
    setters: defaultValueSetters,
    validationError,
  } = useDefaultValue<SelectOptionFieldBlueprint>({
    validateAs: validateAsData.validateAs as unknown as Validations,
  });

  return {
    data: {
      fieldType,
      ...data,
      searchable,
      ...validateAsData,
      ...defaultValueData,
      selectOptions: initialDataAsTypedField?.selectOptions || [],
    },
    setters: { ...setters, ...validateAsSetters, ...defaultValueSetters, setSearchable },
    validationOptions,
    defaultValueValidationError: validationError,
  };
};
