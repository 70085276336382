import _ from 'lodash';
import { ReactNode, ReactElement, createContext, useCallback, useContext } from 'react';

import { ExportedData, ExportQueryParams } from '@breathelife/types';

import { QuestionnaireVersionDataContext } from '../../Pages/Admin/Questionnaire/ContextProvider/QuestionnaireVersionDataContextProvider';
import { useFetchDataForExportMutation } from '../../ReactQuery/Admin/ExportData/exportData.mutations';
import { useCarrierContext } from '../../Hooks';
import { getLanguage } from '../../Localization/utils';

type Props = ExportQueryParams & { children: ReactNode };

interface ExportContext {
  isLoading: boolean;
  onExportButtonClick: () => void;
}

export const ExportCtx = createContext({} as ExportContext);

export function ExportDataProvider(props: Props): ReactElement {
  const { questionnaireVersionData, questionnaireVersionId } = useContext(QuestionnaireVersionDataContext);
  const majorVersion = questionnaireVersionData?.majorVersion;
  const minorVersion = questionnaireVersionData?.minorVersion;
  const questionnaireName = questionnaireVersionData?.questionnaireName;

  const { carrierInfo, monitoringEnvironment } = useCarrierContext();
  const language = getLanguage();

  // Eventually remove reliance on Redux for questionnaire version Id.

  const getFilenameContext = useCallback((): string => {
    let context = 'UntitledExport';

    if (props.blueprint || props.salesDecisionRules) {
      context = 'QuestionnaireExport';
    }

    if (props.theme) {
      context = 'ThemeExport';
    }

    if (props.insuranceProductsAndFirms) {
      context = 'insuranceProductsAndFirms';
    }

    if (props.settings) {
      context = 'SettingsExport';
    }

    return context;
  }, [props.blueprint, props.insuranceProductsAndFirms, props.salesDecisionRules, props.theme, props.settings]);

  const createFilename = useCallback((): string => {
    const env = monitoringEnvironment || 'unknownEnv';
    const carrierId = carrierInfo.id || 'unknownCarrier';
    const questionnaireVersion = `v${majorVersion}.${minorVersion}`;
    const localizedName = questionnaireName ? questionnaireName[language] : 'unknownName';

    let filename = `BL_${getFilenameContext()}_${env}_${carrierId}_${localizedName}_${questionnaireVersion}.json`;

    if (props.theme || props.insuranceProductsAndFirms || props.settings) {
      // Not including the questionnaireVersion since themes, products and firms, and settings are not associated to a questionnaire version
      filename = `BL_${getFilenameContext()}_${env}_${carrierId}.json`;
    }

    return filename;
  }, [
    monitoringEnvironment,
    carrierInfo.id,
    majorVersion,
    minorVersion,
    questionnaireName,
    language,
    getFilenameContext,
    props.theme,
    props.insuranceProductsAndFirms,
    props.settings,
  ]);

  const createLinkAndDownloadFile = useCallback((filename: string, exportedData: ExportedData): void => {
    // Create download link
    const stringContent = JSON.stringify(exportedData, null, 2);
    const blob = new Blob([stringContent], { type: 'application/json' });
    const downloadUrl =
      window.URL && window.URL.createObjectURL
        ? window.URL.createObjectURL(blob)
        : window.webkitURL.createObjectURL(blob);

    // Create hidden temporary anchor tag with download link
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = downloadUrl;
    tempLink.setAttribute('download', filename);
    document.body.appendChild(tempLink);

    // Download file and remove temporary anchor tag
    tempLink.click();
    tempLink.parentNode && tempLink.parentNode.removeChild(tempLink);
  }, []);

  const handleExportedData = (data: ExportedData): void => {
    const exportedData = {
      ...(props.blueprint && { blueprint: data.blueprint }),
      ...(props.salesDecisionRules && { salesDecisionRules: data.salesDecisionRules }),
      ...(props.theme && { theme: data.theme }),
      ...(props.insuranceProductsAndFirms && { firms: data.firms, products: data.products }),
      ...(props.settings && { settings: data.settings }),
    };

    createLinkAndDownloadFile(createFilename(), exportedData);
  };

  const fetchDataForExport = useFetchDataForExportMutation({
    onSuccess: handleExportedData,
  });

  const onExportButtonClick = useCallback(() => {
    fetchDataForExport.mutate(_.omit({ ...props, questionnaireVersionId }, 'children'));
  }, [fetchDataForExport, props, questionnaireVersionId]);

  return (
    <ExportCtx.Provider
      value={{
        isLoading: fetchDataForExport.isLoading,
        onExportButtonClick,
      }}
    >
      {props.children}
    </ExportCtx.Provider>
  );
}
