import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';

import { CreateUserData, UserCategory, UserType } from '@breathelife/types';

import { FormFields, getFormSchema } from '../../../../Helpers/inputValidation/form/user';
import { useSelector } from '../../../../Hooks';
import { DetailViewType } from '../../../../Models/Layout';

// Not using UserRole from @breathelife/types because it is also including service role..
import { isSsoUser, UserRole } from '../../../../Services/Auth0';
import { UserDetailView } from './UserDetailView';
import { InputState, UserFormState } from './UserForm';
import { useAllUserGroupsForUser } from '../../../../ReactQuery/Admin/UserGroups/userGroups.queries';
import { useUpdateUserGroupsForUserMutation } from '../../../../ReactQuery/Admin/UserGroups/userGroups.mutations';
import { useCreateUserMutation, useUpdateUserMutation } from '../../../../ReactQuery/Admin/Users/users.mutations';
import { useUsersContext } from '../UsersContextProvider';

const defaultFormState: InputState = {
  value: '',
};

function buildUser(userFormState: UserFormState): Partial<CreateUserData> {
  const user = {
    ...(userFormState.firstName.value && { firstName: userFormState.firstName.value }),
    ...(userFormState.lastName.value && { lastName: userFormState.lastName.value }),
    ...(userFormState.phoneNumber.value && { phoneNumber: userFormState.phoneNumber.value }),
    ...(userFormState.userId.value && { userId: userFormState.userId.value }),
    emailLogin: userFormState.email.value,
    role: userFormState.role,
    category: userFormState.category,
    type: userFormState.type,
    picture: userFormState.picture,
  };
  const { role, userId, ...rest } = user;
  const roles = role ? [role] : undefined;
  return { ...rest, roles, customId: userId };
}

function getDefaultUserFormState(): UserFormState {
  return {
    firstName: { ...defaultFormState },
    lastName: { ...defaultFormState },
    email: { ...defaultFormState },
    phoneNumber: { ...defaultFormState },
    userId: { ...defaultFormState },
    groupNames: [],
    role: UserRole.user,
    category: UserCategory.nonRegistered,
    type: UserType.captive,
  };
}

function UserDetailContainer(): ReactElement {
  const { onOpenResendInvitationModal, onCloseUserDetails, selectedUser } = useUsersContext();
  const { isOpen: isModalOpen, type: detailViewType } = useSelector(
    (store) => store.leadPlatform.layout.rightPanelState,
  );

  const [userFormState, setUserFormState] = useState<UserFormState>(getDefaultUserFormState());

  const createUserMutation = useCreateUserMutation();
  const updateUserMutation = useUpdateUserMutation(selectedUser?.auth0Id, selectedUser && isSsoUser(selectedUser));

  const onClose = useCallback(() => {
    setUserFormState(getDefaultUserFormState());

    onCloseUserDetails();
  }, [onCloseUserDetails]);

  const updateGroupsForUserMutation = useUpdateUserGroupsForUserMutation(selectedUser?.id);

  const onUpdateUser = useCallback(() => {
    if (!selectedUser) return;
    updateGroupsForUserMutation.mutate(userFormState.groupNames);
    const data = buildUser(userFormState);
    updateUserMutation.mutate(data);
  }, [selectedUser, userFormState, updateGroupsForUserMutation, updateUserMutation]);

  const onCreateUser = useCallback(() => {
    const data = buildUser(userFormState) as CreateUserData;
    createUserMutation.mutate(data);
  }, [userFormState, createUserMutation]);

  const { data: userGroups } = useAllUserGroupsForUser(selectedUser?.id);

  const userGroupNames = useMemo(() => {
    return userGroups?.map((group) => group.name) || [];
  }, [userGroups]);

  useEffect(() => {
    const defaultState = getDefaultUserFormState();
    setUserFormState({
      firstName: {
        value: selectedUser?.firstName ?? defaultState.firstName.value,
      },
      lastName: {
        value: selectedUser?.lastName ?? defaultState.lastName.value,
      },
      email: {
        value: selectedUser?.emailLogin ?? defaultState.email.value,
      },
      phoneNumber: {
        value: selectedUser?.phoneNumber ?? defaultState.phoneNumber.value,
      },
      userId: {
        value: selectedUser?.customId ?? defaultState.userId.value,
      },
      role: (selectedUser?.roles?.[0] as UserRole) ?? defaultState.role,
      category: selectedUser?.category ?? defaultState.category,
      type: selectedUser?.type ?? defaultState.type,
      picture: selectedUser?.picture,
      isSso: selectedUser?.isSso,
      groupNames: userGroupNames,
    });
  }, [selectedUser, userGroupNames]);

  useEffect(() => {
    setUserFormState((prev) => ({ ...prev, groupNames: userGroupNames }));
  }, [userGroupNames]);

  const userFormValues: Partial<FormFields> = {
    ...(userFormState.firstName.value && { firstName: userFormState.firstName.value }),
    ...(userFormState.lastName.value && { lastName: userFormState.lastName.value }),
    ...(userFormState.phoneNumber.value && { phoneNumber: userFormState.phoneNumber.value }),
    ...(userFormState.userId.value && { userId: userFormState.userId.value }),
    emailLogin: userFormState.email.value,
    role: userFormState.role,
    category: userFormState.category,
    type: userFormState.type,
    picture: userFormState.picture,
  };

  const isFormValid = getFormSchema().isValidSync(userFormValues);

  const onProfilePictureChange = useCallback((picture: string) => {
    setUserFormState((prevUserFormState) => ({
      ...prevUserFormState,
      picture,
    }));
  }, []);

  const onFirstNameChange = useCallback((firstName: InputState) => {
    setUserFormState((prevUserFormState) => ({
      ...prevUserFormState,
      firstName,
    }));
  }, []);

  const onLastNameChange = useCallback((lastName: InputState) => {
    setUserFormState((prevUserFormState) => ({
      ...prevUserFormState,
      lastName,
    }));
  }, []);

  const onEmailChange = useCallback((email: InputState) => {
    setUserFormState((prevUserFormState) => ({
      ...prevUserFormState,
      email,
    }));
  }, []);

  const onPhoneNumberChange = useCallback((phoneNumber: InputState) => {
    setUserFormState((prevUserFormState) => ({
      ...prevUserFormState,
      phoneNumber,
    }));
  }, []);

  const onGroupsChange = useCallback((groupNames: string[]) => {
    setUserFormState((prevUserFormState) => ({
      ...prevUserFormState,
      groupNames,
    }));
  }, []);

  const onUserIdChange = useCallback((userId: InputState) => {
    setUserFormState((prevUserFormState) => ({
      ...prevUserFormState,
      userId,
    }));
  }, []);

  const onRoleChange = useCallback((role: UserRole) => {
    setUserFormState((prevUserFormState) => ({
      ...prevUserFormState,
      role,
    }));
  }, []);

  const onCategoryChange = useCallback((category: UserCategory) => {
    setUserFormState((prevUserFormState) => ({
      ...prevUserFormState,
      category,
    }));
  }, []);

  const onTypeChange = useCallback((type: UserType) => {
    setUserFormState((prevUserFormState) => ({
      ...prevUserFormState,
      type,
    }));
  }, []);

  const allProps = {
    isOpen: isModalOpen,
    onUpdateUser,
    onCreateUser,
    selectedUser: detailViewType === DetailViewType.edit ? selectedUser : undefined,
    userFormState,
    viewType: detailViewType ?? DetailViewType.create,
    onClose,
    onOpenResendInvitationModal,
    isFormValid: !!isFormValid,
    onProfilePictureChange,
    onFirstNameChange,
    onLastNameChange,
    onEmailChange,
    onPhoneNumberChange,
    onGroupsChange,
    onUserIdChange,
    onRoleChange,
    onCategoryChange,
    onTypeChange,
  };
  return <UserDetailView {...allProps} />;
}

export default UserDetailContainer;
