import { Box } from '@breathelife/mui';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { SubmitButton } from '../../../Components/Button/SubmitButton';
import Typography from '../../../Components/Typography';
import { ModalLayout } from '../../../Layouts/Modal/ModalLayout';

type DataExtractConsentModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export function DataExtractConsentModal(props: DataExtractConsentModalProps): ReactElement {
  const { t } = useTranslation();
  const { isOpen, onClose, onConfirm } = props;

  return (
    <ModalLayout
      maxWidth='sm'
      isOpen={isOpen}
      closeModal={onClose}
      title={t('modals.extractDataConsent.title')}
      submitButton={<SubmitButton onClick={onConfirm}>{t('modals.extractDataConsent.submitButton')}</SubmitButton>}
      hideCancel
    >
      <Box>
        <Typography variant='body1'>{t('modals.extractDataConsent.body')}</Typography>
      </Box>
    </ModalLayout>
  );
}
