import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '../../../../Styles/themed-styled-components';

import { Grid } from '@breathelife/mui';
import {
  CreateRequest,
  DocumentVariant,
  FileTemplate,
  FileTemplateOrigin,
  StoredFileDocType,
} from '@breathelife/types';
import { Input as TextInput } from '@breathelife/ui-components';

import { Checkbox } from '../../../../Components/Checkbox/Checkbox';
import { Select } from '../../../../Components/Select/Select';
import { DocumentTemplateValidation } from '../../../../Helpers/inputValidation/form/documentTemplate';
import { getValidationError } from '../../../../Helpers/inputValidation/getValidationError';

const StyledSelect = styled(Select)`
  &&&& {
    width: 100%;
  }
`;

export type DocumentEditorFormFields = Omit<
  CreateRequest<
    FileTemplate & {
      engName: string;
      frName: string;
    }
  >,
  'storageBucket' | 'storageKey' | 'lastUsedAt' | 'archived' | 'localizedName'
>;

type Props = {
  fileTemplate: DocumentEditorFormFields;
  onChange: (data: DocumentEditorFormFields) => void;
  disabled?: boolean;
};

export function DocumentEditorForm(props: Props): ReactElement {
  const { fileTemplate, onChange: onChangeProp, disabled } = props;
  const { t } = useTranslation();

  const [name, setName] = useState<string>(fileTemplate.name);
  const [engName, setEngName] = useState<string>(fileTemplate.engName);
  const [frName, setFrName] = useState<string>(fileTemplate.frName);
  const [origin, setOrigin] = useState<string>(fileTemplate.origin);
  const [externalUrl, setExternalUrl] = useState<string>(fileTemplate.externalUrl || '');
  const [docType, setDocType] = useState<string>(fileTemplate.docType);
  const [identifier, setIdentifier] = useState<string>(fileTemplate.identifier || '');
  const [isRequired, setIsRequired] = useState<boolean>(!!fileTemplate.isRequired);
  const [variant, setVariant] = useState<string>(fileTemplate.variant || 'none');

  const updateForm = (data: Partial<DocumentEditorFormFields>): void => {
    const { name, origin, externalUrl, identifier, docType, variant, isRequired, engName, frName } = data;
    name !== undefined && setName(name);
    engName !== undefined && setEngName(engName);
    frName !== undefined && setFrName(frName);
    origin !== undefined && setOrigin(origin);
    externalUrl && setExternalUrl(externalUrl);
    identifier && setIdentifier(identifier);
    docType !== undefined && setDocType(docType);
    isRequired !== undefined && setIsRequired(isRequired);
    variant !== undefined && setVariant(variant || 'none');
  };

  useEffect(() => {
    updateForm(fileTemplate);
  }, [fileTemplate]);

  const propagateChange = (): void => {
    const fileTemplate: DocumentEditorFormFields = {
      name,
      origin: origin as FileTemplateOrigin,
      docType: docType as StoredFileDocType,
      externalUrl,
      identifier,
      isRequired,
      variant: variant in DocumentVariant ? (variant as DocumentVariant) : 'none',
      engName,
      frName,
    };

    onChangeProp?.(fileTemplate);
  };

  const documentOrigins = [
    FileTemplateOrigin.ExternalStatic,
    FileTemplateOrigin.CarrierGenerated,
    FileTemplateOrigin.UserProvided,
  ].map((origin) => ({
    value: origin,
    label: t(`documentOrigin.${origin}`),
  }));

  const documentTypes = [StoredFileDocType.Attachment, StoredFileDocType.Illustration].map((docType) => ({
    value: docType,
    label: t(`documentType.${docType}`),
  }));

  const documentVariants = [
    { value: 'none', label: t('documentVariant.none') },
    ...Object.keys(DocumentVariant).map((variant) => ({
      value: variant,
      label: t(`documentVariant.${variant}`),
    })),
  ];

  const schemaMap = DocumentTemplateValidation.getSchemaMap();
  const engNameValidationError = getValidationError(schemaMap, 'engName', engName);
  const frNameValidationError = getValidationError(schemaMap, 'frName', frName);

  const externalUrlValidationError = getValidationError(schemaMap, 'externalUrl', externalUrl);
  const identifierValidationError = getValidationError(schemaMap, 'identifier', identifier);

  return (
    <Grid container justifyContent='flex-end' spacing={2} onBlur={propagateChange}>
      <Grid item xs={6}>
        <TextInput
          required
          fullWidth
          inputVariant='outlined'
          id='new-document-english-name'
          label={t('inputs.enDocumentName')}
          disabled={disabled}
          error={!!engNameValidationError}
          validationError={engNameValidationError}
          value={engName}
          onChange={(event) =>
            updateForm({
              engName: event.target.value,
            })
          }
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          required
          fullWidth
          inputVariant='outlined'
          id='new-document-french-name'
          label={t('inputs.frDocumentName')}
          disabled={disabled}
          error={!!frNameValidationError}
          validationError={frNameValidationError}
          value={frName}
          onChange={(event) =>
            updateForm({
              frName: event.target.value,
            })
          }
        />
      </Grid>

      <Grid item xs={6}>
        <StyledSelect
          options={documentTypes}
          onChange={(value) =>
            updateForm({
              docType: value as StoredFileDocType,
            })
          }
          value={docType}
          disabled={disabled}
          id='edit-document-doc-type'
          label={t('inputs.documentType')}
        />
      </Grid>

      <Grid item xs={9}>
        <TextInput
          fullWidth
          inputVariant='outlined'
          id='edit-document-identifier'
          label={t('inputs.documentIdentifier')}
          disabled={disabled}
          error={!!identifierValidationError}
          validationError={identifierValidationError}
          value={identifier}
          onChange={(event) =>
            updateForm({
              identifier: event.target.value,
            })
          }
        />
      </Grid>

      <Grid item xs={3}>
        <StyledSelect
          options={documentVariants}
          onChange={(value) =>
            updateForm({
              variant: value as DocumentVariant,
            })
          }
          value={variant}
          disabled={disabled}
          id='edit-document-variant'
          label={t('inputs.documentVariant')}
        />
      </Grid>

      {origin === FileTemplateOrigin.ExternalStatic && (
        <Grid item xs={9}>
          <TextInput
            required
            fullWidth
            inputVariant='outlined'
            id='edit-document-external-url'
            label={t('inputs.externalDocumentUrl')}
            disabled={disabled}
            error={!!externalUrlValidationError}
            validationError={externalUrlValidationError}
            value={externalUrl}
            onChange={(event) =>
              updateForm({
                externalUrl: event.target.value,
              })
            }
          />
        </Grid>
      )}

      <Grid item xs={6}>
        <StyledSelect
          options={documentOrigins}
          onChange={(value) =>
            updateForm({
              origin: value as FileTemplateOrigin,
            })
          }
          value={origin}
          disabled={disabled}
          id='edit-document-origin'
          label={t('inputs.documentOrigin')}
        />
      </Grid>
      <Grid item xs={12}>
        <Checkbox
          checked={isRequired}
          onChange={() => {
            updateForm({ isRequired: !isRequired });
          }}
          label={t('assistedApplication.documentsDrawer.documentRequired')}
        />
      </Grid>
    </Grid>
  );
}
