import { Box } from '@breathelife/mui';
import { ReactElement, useEffect, useCallback } from 'react';
import { Outlet } from 'react-router-dom';

import { Permission } from '@breathelife/types';
import { Loader } from '@breathelife/ui-components';

import { Sidebar } from '../Components/Sidebar/Sidebar';
import { useCarrierContext, useDispatch, useSelector } from '../Hooks';
import { useCreateAgentLicenseMutation } from '../ReactQuery/AgentLicense/agentLicense.mutations';
import { fetchPublicSettings } from '../ReduxStore/Admin/SettingsManagement/SettingsOperations';

type Props = {
  displayContentFullScreen?: boolean;
};

export function PrivateRootApplication(props: Props): ReactElement {
  const dispatch = useDispatch();
  const { features } = useCarrierContext();
  const theme = useSelector((state) => state.leadPlatform.theme);
  const authentication = useSelector((store) => store.leadPlatform.authentication);
  const { mutate } = useCreateAgentLicenseMutation();

  const { displayContentFullScreen } = props;

  const fetchAgentLicenses = useCallback(async (): Promise<void> => {
    if (authentication.user && authentication.user.permissions?.includes(Permission.AgentLicenseCreate)) {
      mutate(authentication.user);
    }
  }, [authentication, mutate]);

  useEffect(() => {
    void dispatch(fetchPublicSettings());
    if (features.agentLicenseValidation?.enabled) {
      void fetchAgentLicenses();
    }
  }, [dispatch, fetchAgentLicenses, features.agentLicenseValidation]);

  if (!theme) return <Loader color='#000000' />;

  return (
    <Box width='100%'>
      {!displayContentFullScreen && (
        /* Using a position fixed for the sidebar with a margin left on the page layout because having
        a table inside a `display: flex;` is not working well. The table is rendered wider than the parent even if
        the parent of the table is a div with a `flex: 1;` */
        <Box height='100vh' position='fixed' top='0' left='0' p={2}>
          <Sidebar />
        </Box>
      )}

      <Box ml={displayContentFullScreen ? 0 : 13} mr={displayContentFullScreen ? 0 : 2}>
        <Outlet />
      </Box>
    </Box>
  );
}
