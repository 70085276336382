import _ from 'lodash';
import { ReactElement, useCallback, useContext, useState, useEffect } from 'react';

import { InsuranceFirm } from '@breathelife/types';
import { Box } from '@breathelife/ui-components';

import { Drawer } from '../../../../Components/Drawer/Drawer';
import { CarrierContext } from '../../../../Context/CarrierContext';
import { firmFormSchema } from '../../../../Helpers/inputValidation/form/firm';
import { getCurrentLocale } from '../../../../Localization/utils';
import { FirmDetailHeader } from './Components/FirmDetailHeader';
import { FirmForm } from './Components/FirmForm';
import { getDefaultInsuranceFirmFormState } from './helpers';
import { FirmFormFields, ValueChangeFunctionPayload } from './types';

type Props = {
  isOpen: boolean;
  onSave: (formFields: FirmFormFields) => void;
  onClose: () => void;
  insuranceFirm?: InsuranceFirm;
};

export function FirmEditorDrawer(props: Props): ReactElement | null {
  const { isOpen: isDrawerOpen, onClose, onSave, insuranceFirm } = props;

  const { languageSettings } = useContext(CarrierContext);

  const [formState, setFormState] = useState<FirmFormFields>(
    getDefaultInsuranceFirmFormState(languageSettings.enabledLanguages, insuranceFirm),
  );

  const handleFirmInputValueChange = useCallback((payload: ValueChangeFunctionPayload) => {
    setFormState((prevFormState) => _.setWith(_.cloneDeep(prevFormState), payload.path, payload.value, _.cloneDeep));
  }, []);

  const resetForm = useCallback(() => {
    setFormState(getDefaultInsuranceFirmFormState(languageSettings.enabledLanguages));
  }, [languageSettings.enabledLanguages]);

  const onCloseDrawer = useCallback(() => {
    onClose();
    resetForm();
  }, [onClose, resetForm]);

  const onCreate = useCallback(() => {
    void onSave(formState);
    onCloseDrawer();
  }, [formState, onCloseDrawer, onSave]);

  const isFormValid = firmFormSchema(languageSettings.enabledLanguages).isValidSync(formState);

  useEffect(() => {
    setFormState(getDefaultInsuranceFirmFormState(languageSettings.enabledLanguages, insuranceFirm));
  }, [languageSettings, insuranceFirm]);

  return (
    <Drawer open={isDrawerOpen} onClose={onCloseDrawer}>
      <FirmDetailHeader
        onClose={onCloseDrawer}
        onSave={onCreate}
        isFormValid={isFormValid}
        firmName={insuranceFirm?.name?.[getCurrentLocale()] || insuranceFirm?.name?.en || ''}
      />
      <Box p={3}>
        <FirmForm
          onFirmFieldValueChange={handleFirmInputValueChange}
          enabledLanguages={languageSettings.enabledLanguages}
          insuranceFirmFormState={formState}
        />
      </Box>
    </Drawer>
  );
}
