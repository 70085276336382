import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type PaymentState = {
  isPaymentReadyToSubmit: boolean;
};

type ActionType = {
  reset: () => void;
  setIsPaymentReadyToSubmit: (state: PaymentState, { payload }: PayloadAction<boolean>) => void;
};

const defaultState: PaymentState = {
  isPaymentReadyToSubmit: false,
};

export const paymentSlice = createSlice<PaymentState, ActionType>({
  name: 'leadPlatform/payment',
  initialState: defaultState,
  reducers: {
    reset: () => defaultState,
    setIsPaymentReadyToSubmit(state: PaymentState, { payload }: PayloadAction<boolean>) {
      state.isPaymentReadyToSubmit = payload ?? false;
    },
  },
});
