import { QuestionnaireEngineConfig } from '@breathelife/questionnaire-engine';
import { ApplicationMode } from '@breathelife/types';

import { PlatformFeatures } from '../Models/PlatformFeatures';

// This helper can be used for extending questionnaire engine config
export function getQuestionnaireEngineConfig(
  mode: ApplicationMode,
  features: PlatformFeatures,
  outputConfig?: QuestionnaireEngineConfig,
) {
  const config: QuestionnaireEngineConfig = {
    logUnexpectedAnswers: false,
    logValidationErrors: false,
    ...(outputConfig ?? {}),
  };

  if (features.questionnaireScreen?.enabled && mode === ApplicationMode.paper) {
    config.screenConfig = features.questionnaireScreen?.config?.PaperApp;

    if (!config.screenConfig) {
      throw new Error('The config for the questionnaireScreen feature wasn`t provided.');
    }
  }

  return config;
}
