import { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton, InputAdornment, TextField } from '@breathelife/mui';
import { IconName } from '@breathelife/types';
import { Icon } from '@breathelife/ui-components';

import Typography from '../../../../../Components/Typography';
import styled, { useTheme } from '../../../../../Styles/themed-styled-components';
import { QuestionnaireTreeViewSearchContext } from './QuestionnaireTreeViewSearchContextProvider';

const ResultsTag = styled.div`
  padding: 2px 8px;
  border-radius: 26px;
  background-color: ${({ theme }) => theme.colors.yellow[30]};
  display: flex;
  & span {
    white-space: nowrap;
  }
`;

const EndAdornmentsContainer = styled.div`
  display: flex;
  align-items: center;
  && button {
    margin-left: 4px;
  }
`;

export function QuestionnaireTreeViewSearchField(): ReactElement {
  const { searchTerm, handleSearchInputChange, numberOfSearchResults } = useContext(QuestionnaireTreeViewSearchContext);
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <TextField
      fullWidth
      onChange={(e) => handleSearchInputChange(e.target.value || '')}
      value={searchTerm}
      variant='outlined'
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <Icon name={IconName.search} color={{ primary: theme.colors.grey[70] }} size='16px' />
          </InputAdornment>
        ),
        endAdornment: searchTerm ? (
          <InputAdornment position='end'>
            <EndAdornmentsContainer>
              <ResultsTag>
                <Typography variant='small1'>{`${numberOfSearchResults} ${t(
                  `admin.questionnaireManagement.search.results.${numberOfSearchResults === 1 ? 'singular' : 'plural'}`,
                )}`}</Typography>
              </ResultsTag>
              <IconButton onClick={() => handleSearchInputChange('')} size='small'>
                <Icon name={IconName.close} size='10px' color={{ primary: theme.colors.grey[60] }} />
              </IconButton>
            </EndAdornmentsContainer>
          </InputAdornment>
        ) : (
          <div />
        ),
      }}
    />
  );
}
