import _ from 'lodash';
import { ReactElement, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseQueryResult } from 'react-query';
import { Outlet } from 'react-router-dom';

import { useCarrierContext, useTableOptions, useSelector, TableOptionsOutput } from '../../Hooks';
import { Lead, LeadSignatureStatusesColumnData, LeadStatusesColumnData } from '../../Models/Lead';
import { DefaultLeadsListFilterIds } from '../../Pages/Home/Modals/UserListModal/UserListModal';
import { useFindLeadsQuery, useFindUsedStatusesQuery } from '../../ReactQuery/Lead/lead.queries';
import { FetchLeadsOptions, FetchLeadsQueryParams, LeadsMappedGetResponse } from '../../Services/LeadsService';
import {
  buildLeadFieldsToFetch,
  columnToSortingFieldMapping,
  getRenderedLeadTableColumnsInfo,
  RenderedLeadTableColumnInfo,
} from './LeadColumnsBuilders';
import { CarrierContext } from '../../Context/CarrierContext';
import { ESignCeremonyStatus, LeadStatus } from '@breathelife/types';

type LeadsPageDataContextValue = TableOptionsOutput<FetchLeadsQueryParams, keyof Lead> & {
  leads: Lead[];
  leadsQueryIsLoading?: boolean;
  leadsQueryIsSuccess?: boolean;
  renderedTableColumnsInfo?: RenderedLeadTableColumnInfo[];
  totalNumberOfLeads: number;
  isLoadingApplication: boolean;
  setIsLoadingApplication: (isLoading: boolean) => void;
  usedStatuses: LeadStatusesColumnData;
  usedSignatureStatuses: LeadSignatureStatusesColumnData;
};

export const PER_PAGE_OPTIONS = [
  { label: 25, value: 25 },
  { label: 50, value: 50 },
  { label: 100, value: 100 },
];

const defaultTableOptions = {
  filters: {
    selectedUserId: DefaultLeadsListFilterIds.All,
  },
  page: 1,
  perPage: PER_PAGE_OPTIONS[0].value,
};

export const LeadsPageDataContext = createContext<LeadsPageDataContextValue>({
  tableOptions: defaultTableOptions,
  setFilters: () => {},
  setPage: () => {},
  setSorting: () => {},
  setPerPage: () => {},
  leads: [],
  totalNumberOfLeads: 0,
  isLoadingApplication: false,
  setIsLoadingApplication: () => {},
  usedStatuses: {},
  usedSignatureStatuses: {},
});

function getAssignedToIdParam(userId: string): string | undefined {
  if (userId === DefaultLeadsListFilterIds.All) return undefined;
  if (userId === DefaultLeadsListFilterIds.Unassigned) return 'null';
  return userId;
}

function useLeadsColumnsData(): UseQueryResult<LeadsMappedGetResponse> & {
  tableOptions: LeadsPageDataContextValue['tableOptions'];
  setPage: LeadsPageDataContextValue['setPage'];
  setFilters: LeadsPageDataContextValue['setFilters'];
  setPerPage: LeadsPageDataContextValue['setPerPage'];
  setSorting: LeadsPageDataContextValue['setSorting'];
  usedStatuses: LeadStatusesColumnData;
  usedSignatureStatuses: LeadSignatureStatusesColumnData;
} {
  const { leadTableColumns } = useCarrierContext();

  const leadFieldsToFetch = useMemo(() => buildLeadFieldsToFetch(leadTableColumns), [leadTableColumns]);

  const initialSortByColumn = _.find(leadTableColumns, (column) => !!column.defaultSort);

  const { tableOptions, setPage, setPerPage, setSorting, setFilters } = useTableOptions<
    FetchLeadsQueryParams,
    keyof Lead
  >({
    filters: {
      selectedUserId: DefaultLeadsListFilterIds.All,
    },
    page: 1,
    perPage: PER_PAGE_OPTIONS[0].value,
    ...(initialSortByColumn
      ? {
          sort: {
            columnName: (initialSortByColumn.name as keyof Lead) || 'createdAt',
            direction: initialSortByColumn.defaultSort || 'asc',
          },
        }
      : {}),
  });

  const { filters, perPage, sort, page } = tableOptions;

  const fetchOptions = useMemo((): FetchLeadsOptions | undefined => {
    if (!leadFieldsToFetch || !leadTableColumns || _.isUndefined(filters.archived)) return;

    const skip = (page - 1) * perPage;
    const { selectedUserId, ...otherFilterParams } = filters;
    const assignedToId = getAssignedToIdParam(selectedUserId);

    const field = sort && columnToSortingFieldMapping[sort.columnName as keyof typeof columnToSortingFieldMapping];

    const sortBy =
      sort && field
        ? {
            field: field,
            direction: sort.direction,
          }
        : undefined;

    return {
      skip, // TODO: move this option to the backend
      queryParams: { ...otherFilterParams, assignedToId },
      limit: perPage,
      fields: leadFieldsToFetch,
      sort: sortBy,
    };
  }, [perPage, page, filters, leadFieldsToFetch, leadTableColumns, sort]);

  const findLeadsQueryResult = useFindLeadsQuery(fetchOptions);

  const leadStatuses = useContext(CarrierContext).leadStatuses;

  const { data: uniqueApplicationStatuses } = useFindUsedStatusesQuery(Boolean(filters.archived), 'status');
  const { data: uniqueApplicationSignatureStatuses } = useFindUsedStatusesQuery(
    Boolean(filters.archived),
    'signatureStatus',
  );

  const usedStatuses = useMemo(() => {
    if (!uniqueApplicationStatuses) {
      return {} as LeadStatusesColumnData;
    }
    return Object.fromEntries(
      Object.entries(leadStatuses).filter(([key]) => {
        return uniqueApplicationStatuses.indexOf(key as LeadStatus) > -1;
      }),
    ) as LeadStatusesColumnData;
  }, [uniqueApplicationStatuses, leadStatuses]);

  const usedSignatureStatuses = useMemo(() => {
    if (!uniqueApplicationSignatureStatuses) {
      return {} as LeadSignatureStatusesColumnData;
    }
    return Object.fromEntries(
      Object.entries(ESignCeremonyStatus).filter(([key]) => {
        return uniqueApplicationSignatureStatuses.indexOf(key as ESignCeremonyStatus) > -1;
      }),
    ) as LeadSignatureStatusesColumnData;
  }, [uniqueApplicationSignatureStatuses]);

  useEffect(() => {
    setFilters({ ...filters, status: undefined, signatureStatus: undefined });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.archived]);

  return {
    ...findLeadsQueryResult,
    tableOptions,
    setPage,
    setFilters,
    setPerPage,
    setSorting,
    usedStatuses,
    usedSignatureStatuses,
  };
}

export function LeadsPageDataContextProvider(): ReactElement {
  const { t } = useTranslation();
  const { leadTableColumns } = useCarrierContext();
  const userPermissions = useSelector((store) => store.leadPlatform.authentication.user?.permissions);

  const [isLoadingApplication, setIsLoadingApplication] = useState(false);

  const {
    data,
    isLoading: leadsQueryIsLoading,
    isSuccess: leadsQueryIsSuccess,
    tableOptions,
    setSorting,
    setPage,
    setFilters,
    setPerPage,
    usedStatuses,
    usedSignatureStatuses,
  } = useLeadsColumnsData();

  const renderedTableColumnsInfo = useMemo(
    () => getRenderedLeadTableColumnsInfo(userPermissions ?? [], leadTableColumns, t),
    [userPermissions, leadTableColumns, t],
  );

  return (
    <LeadsPageDataContext.Provider
      value={{
        leads: data?.data || [],
        totalNumberOfLeads: data?.total || 0,
        renderedTableColumnsInfo,
        tableOptions,
        setSorting,
        setPage,
        setFilters,
        setPerPage,
        leadsQueryIsLoading,
        leadsQueryIsSuccess,
        isLoadingApplication,
        setIsLoadingApplication,
        usedStatuses,
        usedSignatureStatuses,
      }}
    >
      <Outlet />
    </LeadsPageDataContext.Provider>
  );
}
