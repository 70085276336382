import { ReactElement } from 'react';

import { QuestionnaireBlueprintCopyableOption, ApplicationMode, FieldBlueprint } from '@breathelife/types';
import { AutocompleteOption } from '@breathelife/ui-components';

import { dataLabelSelectionToDataLabel } from '../../../../../../../Helpers/questionnaireEditor/selectOptions';
import { AdvancedBlueprintOptions } from '../../../Components/AdvancedBlueprintOptions';
import { FieldSetters } from '../../../Helpers/helperTypes';

type AdvancedBlueprintOptionsForFieldProps = {
  data: FieldBlueprint;
  setters: FieldSetters;
  dataLabelOptions: AutocompleteOption[];
};

export function AdvancedBlueprintOptionsForField({
  data,
  setters,
  dataLabelOptions,
}: AdvancedBlueprintOptionsForFieldProps): ReactElement {
  return (
    <AdvancedBlueprintOptions
      tag='field'
      platform={{
        selected: data?.platforms || [],
        onChange: (platforms) => {
          setters.setPlatforms(platforms);
        },
      }}
      renderOn={{
        selected: data?.renderOn || [],
        onChange: (renderOn) => {
          setters.setRenderOn(renderOn);
        },
      }}
      dataLabel={{
        selected: data?.dataLabel,
        options: dataLabelOptions,
        onChange: (value) => {
          if (value !== null) {
            setters.setDataLabel(dataLabelSelectionToDataLabel(value));
          }
        },
      }}
      copyable={{
        selected: data?.copyable || QuestionnaireBlueprintCopyableOption.none,
        onChange: (value) => {
          setters.setCopyable(value as QuestionnaireBlueprintCopyableOption);
        },
      }}
      disabled={false}
      applicationModes={{
        selected: data?.applicationModes || [],
        onChange: (updatedMode: ApplicationMode[]) => {
          setters.setApplicationModes(updatedMode);
        },
      }}
    />
  );
}
