import { ESignCeremony, ESignCeremonyPatchRequestData, ESignSigner2FAInfo } from '@breathelife/types';

import ApiService from './ApiService';

export async function getESignCeremony(applicationId: string): Promise<ESignCeremony | null> {
  const response = await ApiService.getESignCeremony(applicationId);
  return response.data;
}

export async function cancelESignCeremony(applicationId: string): Promise<ESignCeremony | null> {
  const response = await ApiService.cancelESignCeremony(applicationId);
  return response.data;
}

export async function sendESignCeremony(
  applicationId: string,
  signers: ESignSigner2FAInfo[],
): Promise<ESignCeremony | null> {
  const response = await ApiService.sendESignatureRequest(applicationId, signers);
  return response.data;
}

export async function updateESignCeremony(
  applicationId: string,
  data: ESignCeremonyPatchRequestData,
): Promise<ESignCeremony> {
  const response = await ApiService.updateESignCeremony(applicationId, data);
  return response.data;
}
