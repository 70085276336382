import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { IconName } from '@breathelife/types';
import { Icon, JsonViewer } from '@breathelife/ui-components';

import {
  JsonAreaHeaderContainer,
  JsonAreaHeaderElements,
  JsonAreaContainer,
  JsonViewerContainer,
  ApplicationInfoContainer,
  ApplicationTitle,
} from './Styles';
import { OutcomeBadge } from '../../../../Components/Badge/OutcomeBadge';
import { SelectedReportData } from '../../../../Helpers/applicationSupport/underwritingTreeviewDataMapping';

import { Button } from '../../../../Components/Button/Button';

type UnderWritingReportsJsonAreaProps = {
  report: SelectedReportData | undefined;
};

export function UnderWritingReportsJsonArea(props: UnderWritingReportsJsonAreaProps): ReactElement {
  const { t } = useTranslation();
  const { report } = props;

  // TODO DEV-12481: Implement an onCopyClick functionality to copy json when clicking the copy button
  // TODO: Implement breadcrumbs (to replace mock below) https://breathelife.atlassian.net/browse/HOT-2675
  return (
    <JsonAreaContainer>
      <JsonAreaHeaderContainer m={2}>
        <JsonAreaHeaderElements>
          <ApplicationInfoContainer>
            <ApplicationTitle variant='h3'>
              {/*TODO HOT-2675: Replace this mock data by implementing breadcrumbs */}
              {/* Insured 1 / Medical Information / Provider 1 / Request # [yyyy-mm-dd hh:mm:ss] */}
            </ApplicationTitle>
            {report && <OutcomeBadge outcome={report.outcome} />}
          </ApplicationInfoContainer>
          <Button color='primary' variant='outlined' onClick={() => {}} data-testid={'copy-to-clipboard-button'}>
            <Icon name={IconName.copy} size='16px' />
            {t('applicationSupport.underwritingReports.copy')}
          </Button>
        </JsonAreaHeaderElements>
      </JsonAreaHeaderContainer>
      <JsonViewerContainer>
        <JsonViewer jsonObject={report} />
      </JsonViewerContainer>
    </JsonAreaContainer>
  );
}
