import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Alert, AlertTitle } from '@breathelife/mui';

type Props = {
  message: string | null;
  handleClose?: () => void;
};

export function UploadError(props: Props): ReactElement | null {
  const { t } = useTranslation();

  if (!props.message) {
    return null;
  }

  return (
    <Alert severity={'error'} onClose={props.handleClose}>
      <AlertTitle>{t('alerts.errorDetails')}</AlertTitle>
      {props.message}
    </Alert>
  );
}
