import { ReactNode, ReactElement } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import axios, { AxiosError } from 'axios';
import { logger } from '@breathelife/monitoring-frontend';

// Create a client
type Props = {
  children: ReactNode;
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      staleTime: 1000 * 60 * 10, // 10 minutes,
      onError: (err) => {
        if (axios.isAxiosError(err)) {
          const status = (err as AxiosError).response?.status;
          if (status && status >= 400 && status < 500) {
            logger.warn(err);
          } else {
            logger.error(err);
          }
        } else {
          logger.error(err);
        }
      },
    },
  },
});

export function ReactQueryClientProvider(props: Props): ReactElement {
  return (
    <QueryClientProvider client={queryClient}>
      {props.children}
      {/* By default, React Query Devtools are only included in bundles when process.env.NODE_ENV === 'development',
         so you don't need to worry about excluding them during a production build. */}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
