import copy from 'copy-to-clipboard';

/**
 * Copies the passed value to the user's clipboard
 *
 * @param value The value that will be copied to the clipboard
 * @param callback A callback to execute after the value has been successfully copied
 */
export function copyValueToClipboard(value: string, callback?: () => unknown): void {
  if (copy(value)) {
    callback?.();
  }
}
