import { ReactElement, useMemo } from 'react';

import { makeTextHighlightFragmentsFromIndices } from '../../../../../Helpers/questionnaireEditor/questionnaireSearch';
import { HighlightedContent } from './HighlightedContent';

type SearchExportTypographyProps = {
  text: string;
  searchTermCharacterLength: number;
  startIndices: number[];
};

export function SearchInstanceTypographyContent({
  text,
  searchTermCharacterLength,
  startIndices,
}: SearchExportTypographyProps): ReactElement {
  const textFragments = useMemo(() => {
    return makeTextHighlightFragmentsFromIndices(text, searchTermCharacterLength, startIndices);
  }, [text, startIndices, searchTermCharacterLength]);

  return <HighlightedContent textFragments={textFragments} />;
}
