import { ReactElement } from 'react';

import { SvgIconProps } from '../../Components/Icons';

export function SuccessIcon(props: SvgIconProps): ReactElement {
  return (
    <svg stroke='#757575' fill='#f6f6f6' height='24' width='24' xmlns='http://www.w3.org/2000/svg' {...props.svgProps}>
      <g strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.5'>
        <path d='M.75 11.999a11.25 11.25 0 1022.5 0 11.25 11.25 0 00-22.5 0z' />
        <path d='M7 11.5l2.95 3.7a1.05 1.05 0 001.707.051L17 8' />
      </g>
    </svg>
  );
}
