import { useTranslation } from 'react-i18next';
import styled from '../../../../../Styles/themed-styled-components';

import { GhostButton } from '../../../../../Components/Button/GhostButton';
import { Icon } from '../../../../../Components/Icons';

type Props = {
  url: string;
};

const Container = styled.div`
  display: flex;
  height: 100%;
`;

export function SubscriptionToolLink(props: Props): React.ReactElement {
  const { t } = useTranslation();
  return (
    <Container>
      <GhostButton
        color='primary'
        startIcon={<Icon name='emailSubscriptionStatus' variant='primary' />}
        onClick={() => window.open(props.url)}
      >
        {t('leadDetailDrawer.checkSubscriptionStatus')}
      </GhostButton>
    </Container>
  );
}
