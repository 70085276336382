import { ReactElement, createElement, useMemo } from 'react';

import { deserializeNodeIdToAnswerPathMap } from '@breathelife/questionnaire-engine';
import { LeadStatus } from '@breathelife/types';

import { useApplicationProgress, useNeedsAnalysisQuestionnaire } from '../../../../../../../Hooks';
import { Application } from '../../../../../../../Models/Application';
import { useFetchCarrierQuestionnaireQuery } from '../../../../../../../ReactQuery/CarrierQuestionnaires/carrierQuestionnaire.queries';
import { PendingInformation } from '../PendingInformation';
import { SectionGroups } from './SectionGroups';

type ApplicationProps = {
  customView?: (props: any) => ReactElement | null;
  application: Application;
  leadStatus: LeadStatus;
};

export function ApplicationView(props: ApplicationProps): ReactElement | null {
  const { customView, application, leadStatus } = props;
  const { data: questionnaireData } = useFetchCarrierQuestionnaireQuery(application?.id, {});
  const serializedNodeIdToAnswerPathMap = questionnaireData?.nodeIdToAnswerPath;

  const nodeIdToAnswerPathMap = useMemo(
    () => serializedNodeIdToAnswerPathMap && deserializeNodeIdToAnswerPathMap(serializedNodeIdToAnswerPathMap),
    [serializedNodeIdToAnswerPathMap],
  );

  const needsAnalysisProgress = useApplicationProgress({
    application,
    needsAnalysisOnly: true,
    questionnaireResponse: questionnaireData,
  });

  const renderingQuestionnaire = useNeedsAnalysisQuestionnaire({
    application,
    questionnaire: questionnaireData?.questionnaire,
    nodeIdToAnswerPathMap,
    blueprint: questionnaireData?.blueprint,
  });

  const isLeadStatusInvited = leadStatus === 'invited';

  if (isLeadStatusInvited) return <PendingInformation />;

  if (customView) {
    return createElement(customView || 'div', {
      steps: application.steps ?? [],
      leadStatus,
      progress: needsAnalysisProgress ?? 0,
      recommendedCoverageAmount: application.recommendedCoverageAmount ?? 0,
      externalData: application.externalData,
    });
  }

  if (!renderingQuestionnaire) return null;

  // TODO: render a loader when the questionnaire is being fetched?
  return <SectionGroups sectionGroups={renderingQuestionnaire} />;
}
