import { ReactElement } from 'react';

import { SvgIconProps } from '../../Components/Icons';

export function ArrowDownIcon(props: SvgIconProps): ReactElement {
  return (
    <svg stroke='none' fill='#000000' height='5' width='9' xmlns='http://www.w3.org/2000/svg' {...props.svgProps}>
      <path clipRule='evenodd' d='M4.5 5L9 0H0z' fillRule='evenodd' />
    </svg>
  );
}
