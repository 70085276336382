import {
  NodeIdToAnswerPathMap,
  QuestionnaireEngine,
  RepeatableOptionsWithLimits,
  deserializeNodeIdToAnswerPathMap,
} from '@breathelife/questionnaire-engine';
import {
  ApplicationContext,
  ApplicationMode,
  DEFAULT_TIMEZONE_NAME,
  InsuranceModule,
  Language,
  PlatformType,
  QuestionnaireVersionPricingNodeIds,
  Timezone,
} from '@breathelife/types';
import { PropsWithChildren, ReactElement, createContext, useMemo } from 'react';
import { getQuestionnaireEngineConfig } from '../Helpers/questionnaireEngineConfigs';
import { useCarrierContext } from '../Hooks';
import { useFetchCarrierQuestionnaireQuery } from '../ReactQuery/CarrierQuestionnaires/carrierQuestionnaire.queries';
import { useFetchPricingFieldIdentifiersQuery } from '../ReactQuery/Admin/PricingFieldIdentifiers/pricingFieldIdentifiers.queries';
import { useTranslation } from 'react-i18next';
import { SectionGroupId } from '../Helpers/questionnaireAnswers';

export type AssistedApplicationQuestionnaireVersionValue = {
  questionnaireEngine: QuestionnaireEngine;
  nodeIdToAnswerPathMap: NodeIdToAnswerPathMap;
  maxNumberOfInsured: number;
  isSectionGroupsTabsEnabled: boolean;
  pricingFieldIdentifiers?: QuestionnaireVersionPricingNodeIds;
};

export const AssistedApplicationQuestionnaireVersionContext = createContext<
  AssistedApplicationQuestionnaireVersionValue | undefined
>(undefined);

type AssistedApplicationQuestionnaireVersionContextProviderProps = {
  applicationId: string;
  applicationTimezone: string | null;
  applicationMode: ApplicationMode;
  applicationContext?: ApplicationContext;
  applicationLanguage: Language;
};

export function AssistedApplicationQuestionnaireVersionContextProvider(
  props: PropsWithChildren<AssistedApplicationQuestionnaireVersionContextProviderProps>,
): ReactElement | null {
  const { applicationId, applicationTimezone, applicationMode, applicationContext } = props;
  const { t } = useTranslation();

  const { features } = useCarrierContext();

  const { data: questionnaireData } = useFetchCarrierQuestionnaireQuery(applicationId, {});

  const { data: questionnaireVersionFieldIdentifiersData } = useFetchPricingFieldIdentifiersQuery(
    questionnaireData?.versionId,
    { enabled: features.pricingCalculation.enabled && questionnaireData?.versionId != undefined },
  );

  const nodeIdToAnswerPathMap = useMemo(() => {
    if (!questionnaireData?.nodeIdToAnswerPath) return;

    return deserializeNodeIdToAnswerPathMap(questionnaireData?.nodeIdToAnswerPath);
  }, [questionnaireData?.nodeIdToAnswerPath]);

  const questionnaireEngine = useMemo(() => {
    if (!questionnaireData?.questionnaire || !nodeIdToAnswerPathMap || !questionnaireData?.blueprint) return;

    const timezoneResult = Timezone.from(applicationTimezone || DEFAULT_TIMEZONE_NAME);
    if (timezoneResult.success === false) {
      throw new Error('Could not create the timezone for the application or using the default one.');
    }

    // There are performance implications to this object being remade (some internal data structures get initialized when updating answers that are expensive to rebuild)
    // Once the questionnaire and nodeIdToAnswerPathMap are loaded remaking this object should be avoided.
    return new QuestionnaireEngine({
      questionnaire: questionnaireData?.questionnaire,
      nodeIdToAnswerPathMap,
      context: {
        platformTypes: [PlatformType.producer],
        insuranceModules: [InsuranceModule.insuranceApplication],
        applicationModes: [applicationMode],
      },
      config: getQuestionnaireEngineConfig(applicationMode, features),
      blueprint: questionnaireData.blueprint,
      timezone: timezoneResult.value,
      additionalTextProcessing: t,
      applicationContext,
    });
  }, [
    questionnaireData?.questionnaire,
    questionnaireData?.blueprint,
    nodeIdToAnswerPathMap,
    applicationTimezone,
    applicationMode,
    features,
    t,
    applicationContext,
  ]);

  if (
    !questionnaireData ||
    !questionnaireEngine ||
    !nodeIdToAnswerPathMap ||
    (features.pricingCalculation.enabled && !questionnaireVersionFieldIdentifiersData)
  ) {
    return null;
  }

  const insuredPeopleSectionGroupOptions = questionnaireData.questionnaire?.find(
    (sectionGroup) => sectionGroup.id === SectionGroupId.insuredPeople,
  )?.options as RepeatableOptionsWithLimits;

  return (
    <AssistedApplicationQuestionnaireVersionContext.Provider
      value={{
        questionnaireEngine,
        // TODO: FIXME: See HOT-3512. We want to make component underneath the provider to use function exposed from the
        // questionnaire engine directly instead of using the subtilities of the questionnaire engine like the Answer Resolver.
        nodeIdToAnswerPathMap,
        maxNumberOfInsured: insuredPeopleSectionGroupOptions?.maxRepetitions,
        isSectionGroupsTabsEnabled: questionnaireData.questionnaire.length > 1,
        pricingFieldIdentifiers: questionnaireVersionFieldIdentifiersData?.[0],
      }}
    >
      {props.children}
    </AssistedApplicationQuestionnaireVersionContext.Provider>
  );
}
