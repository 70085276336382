import { ReactElement } from 'react';

import { SvgIconProps } from '../../Components/Icons';

export function PushRightIcon(props: SvgIconProps): ReactElement {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props.svgProps}>
      <path d='M11.25 8.00781H1' stroke='#476CC6' strokeWidth='1.25' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M8.125 11.1374L11.25 8.00816L8.125 4.87891'
        stroke='#476CC6'
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M15 1V15' stroke='#476CC6' strokeWidth='1.25' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
}
