import { MouseEvent, ReactElement, Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '../../Styles/themed-styled-components';

import { Permission } from '@breathelife/types';

import { DropdownButton } from '../../Components/Button/DropdownButton';
import { userHasPermission } from '../../Helpers/user';
import { useDispatch, useSelector } from '../../Hooks';
import { ModalType } from '../../Models/Layout';
import { Lead } from '../../Models/Lead';
import { layoutSlice } from '../../ReduxStore/Layout/LayoutSlice';

type AssignToSelectionProps = {
  lead: Lead;
};

const StyledDropdownButton = styled(DropdownButton)`
  &&& {
    padding: 0px 12px;
  }
`;

export function AssignToSelection(props: AssignToSelectionProps): ReactElement | null {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector((store) => store.leadPlatform.authentication.user);
  const { lead } = props;

  const onDropdownClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      dispatch(layoutSlice.actions.setModalState({ modalState: { lead, isOpen: true, type: ModalType.assign } }));
    },
    [dispatch, lead],
  );

  if (!user) return null;

  const isLeadAssigned = !!lead.assignedToId;

  // FIXME: assignedToName should not be an email, but the fullName of the advisor if set
  // with a fallback on email when full name not available.
  const advisorText =
    lead.user?.firstName && lead.user?.lastName
      ? `${lead.user?.firstName} ${lead.user?.lastName}`
      : lead.user?.emailLogin;
  const assignedToText = user.auth0Id === lead.assignedToId ? t('me') : advisorText ?? '';
  const assignedTo = isLeadAssigned ? assignedToText : t('unassigned');

  const canAssign = userHasPermission(user?.permissions, [
    Permission.LeadAssignAll,
    Permission.LeadAssignGroup,
    Permission.LeadAssignMe,
    Permission.LeadUnassignAll,
    Permission.LeadUnassignMe,
  ]);

  if (!canAssign) return <Fragment>{assignedTo}</Fragment>;

  return (
    <StyledDropdownButton
      size='small'
      grey={isLeadAssigned ? undefined : 60}
      maxWidth={150}
      fullWidth
      title={assignedTo}
      onClick={onDropdownClick}
    >
      {assignedTo}
    </StyledDropdownButton>
  );
}
