import i18next from 'i18next';
import _ from 'lodash';

import { Settings } from '@breathelife/types';

import { settingsSlice } from '../../../ReduxStore/Admin/SettingsManagement/SettingsSlice';
import { notificationSlice } from '../../../ReduxStore/Notification/NotificationSlice';
import { Dispatch } from '../../../ReduxStore/types';
import ApiService from '../../../Services/ApiService';

export const fetchAdminSettings =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(settingsSlice.actions.setIsFetching(true));

    try {
      const response = await ApiService.admin.fetchSettings();
      dispatch(settingsSlice.actions.setSettings({ settings: response.data }));
    } catch (error: any) {
      dispatch(
        notificationSlice.actions.setError({
          message: i18next.t('notifications.failedToLoadSettings'),
        }),
      );
    } finally {
      dispatch(settingsSlice.actions.setIsFetching(false));
    }
  };

export const fetchPublicSettings =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(settingsSlice.actions.setIsFetching(true));

    try {
      const response = await ApiService.fetchPublicSettings();
      dispatch(settingsSlice.actions.setSettings({ settings: response.data }));
    } catch (error: any) {
      dispatch(
        notificationSlice.actions.setError({
          message: i18next.t('notifications.failedToLoadSettings'),
        }),
      );
    } finally {
      dispatch(settingsSlice.actions.setIsFetching(false));
    }
  };

export const updateSettings =
  (currentSettings: Settings, newSettings: Settings) =>
  async (dispatch: Dispatch): Promise<void> => {
    const haveLanguageSettingsChanged = !_.isEqual(
      {
        defaultLanguage: currentSettings.data.defaultLanguage,
        enabledLanguages: currentSettings.data.enabledLanguages,
      },
      { defaultLanguage: newSettings.data.defaultLanguage, enabledLanguages: newSettings.data.enabledLanguages },
    );

    const havePublicLinkSettingsChanged = !_.isEqual(currentSettings.data.publicLink, newSettings.data.publicLink);

    const haveCarrierNameSettingsChanged = !_.isEqual(currentSettings.data.carrierNames, newSettings.data.carrierNames);

    const haveConsumerFooterSettingsChanged = !_.isEqual(
      currentSettings.data.carrierNames,
      newSettings.data.consumerFooter,
    );

    if (
      !haveLanguageSettingsChanged &&
      !havePublicLinkSettingsChanged &&
      !haveCarrierNameSettingsChanged &&
      !haveConsumerFooterSettingsChanged
    ) {
      return;
    }

    if (haveLanguageSettingsChanged) {
      dispatch(settingsSlice.actions.setIsUpdatingLanguages(true));
    }

    if (havePublicLinkSettingsChanged) {
      dispatch(settingsSlice.actions.setIsUpdatingPublicLink(true));
    }

    if (haveCarrierNameSettingsChanged) {
      dispatch(settingsSlice.actions.setIsUpdatingCarrierName(true));
    }

    if (haveConsumerFooterSettingsChanged) {
      dispatch(settingsSlice.actions.setIsUpdatingConsumerFooter(true));
    }

    try {
      const updatedSettings = _.pick(newSettings, 'data');
      const response = await ApiService.admin.updateSettings(newSettings.id, updatedSettings);

      dispatch(settingsSlice.actions.setSettings({ settings: response.data }));
      dispatch(
        notificationSlice.actions.setSuccess({
          message: i18next.t('notifications.settingsUpdateSuccess'),
          autoHideDuration: 3000,
        }),
      );
    } catch (error: any) {
      dispatch(
        notificationSlice.actions.setError({
          message: i18next.t('notifications.failedToUpdateSettings'),
        }),
      );
    } finally {
      dispatch(settingsSlice.actions.setIsUpdatingLanguages(false));
      dispatch(settingsSlice.actions.setIsUpdatingPublicLink(false));
      dispatch(settingsSlice.actions.setIsUpdatingCarrierName(false));
      dispatch(settingsSlice.actions.setIsUpdatingConsumerFooter(false));
    }
  };
