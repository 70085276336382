import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { DisplayQuestionnaireBuilder } from '@breathelife/types';

import { QueryId } from '../../../ReactQuery/common/common.types';
import ApiService from '../../../Services/ApiService';
import {
  PIXEL_GAP_BETWEEN_PAGES,
  transformPagesPosition,
  transformPdfEntitiesPosition,
} from './questionnaireBuilderHelpers';

async function fetchQuestionnaireBuilder(): Promise<DisplayQuestionnaireBuilder | undefined> {
  const { data } = await ApiService.admin.getLatestQuestionnaireBuilder();
  if (data) {
    const displayedPages = transformPagesPosition(data.pdfPages, PIXEL_GAP_BETWEEN_PAGES);
    return {
      ...data,
      pdfPages: displayedPages,
      pdfEntities: transformPdfEntitiesPosition(data.pdfEntities, displayedPages),
    };
  } else {
    throw new Error('getLatestQuestionnaireBuilder returned no data');
  }
}

export function useFetchQuestionnaireBuilderQuery(
  options?: UseQueryOptions<DisplayQuestionnaireBuilder | undefined, unknown>,
): UseQueryResult<DisplayQuestionnaireBuilder | undefined, unknown> {
  return useQuery<DisplayQuestionnaireBuilder | undefined, unknown>(
    QueryId.questionnaireBuilder,
    fetchQuestionnaireBuilder,
    {
      ...options,
      onError: (e) => {
        if ((e as AxiosError).response?.status === 404) {
          return;
        }
        options?.onError?.(e);
      },
    },
  );
}
