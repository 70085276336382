import { useCallback, useState } from 'react';

// [isOpen, onOpenModal, onCloseModal]
type HookAPI = [boolean, () => void, () => void];

export function useModalState(): HookAPI {
  const [isOpen, setIsOpen] = useState(false);

  const onOpenModal = useCallback(() => {
    setIsOpen(true);
  }, []);

  const onCloseModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  return [isOpen, onOpenModal, onCloseModal];
}
