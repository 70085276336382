import { GenericError } from '../../Components/Error/GenericError';
import { ErrorPageContainer } from './Styles';

export function ErrorPage(): React.ReactElement {
  return (
    <ErrorPageContainer>
      <GenericError />
    </ErrorPageContainer>
  );
}
