import _ from 'lodash';
import { ReactElement, Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '../../../Styles/themed-styled-components';

import { AddIcon, Box } from '@breathelife/mui';

import { HelmetContainer } from '../../../Components/HelmetContainer';
import {
  QuestionnaireVersionDataContext,
  useQuestionnaireVersionDetailWithNodeIdInfo,
} from '../Questionnaire/ContextProvider/QuestionnaireVersionDataContextProvider';
import { QuestionnaireVersionsContext } from '../Questionnaire/ContextProvider/QuestionnaireVersionsContextProvider';
import { Select, SelectProps } from '../../../Components/Select/Select';
import { useLocale } from '../../../Hooks/useLocale';
import { PricingFieldIdentifiersManagementSelector } from './PricingFieldIdentifiersManagementSelector';
import { ActionButton } from '../../../Components/Button/ActionButton';
import { ConfirmPricingFieldIdentifiersModal } from '../../../Components/AssistedApplication/Modals/PricingFieldIdentifiers/ConfirmPricingFieldIdentifiersModal';
import { Loader } from '@breathelife/ui-components';

type SelectFunctionType = <T extends string | number>(props: SelectProps<T>) => React.ReactElement<SelectProps<T>>;

const createStyledSelect = <T extends string | number>(
  component: React.ComponentType<SelectProps<T>>,
): SelectFunctionType =>
  styled(component)`
    &&& {
      width: 300px;
    }
  ` as SelectFunctionType;

const QuestionnaireVersionSelect = createStyledSelect<string>(Select);
const QuestionnaireSelect = createStyledSelect<string>(Select);

export function PricingFieldIdentifiersManagementView({
  onQuestionnaireVersionFilterChanged,
  onSavingPricingFieldIdentifiers,
}: {
  onQuestionnaireVersionFilterChanged: (version: string) => void;
  onSavingPricingFieldIdentifiers: (pricingFieldIdentifiersId: string, fieldIdentifiers: string[]) => void;
}): ReactElement | null {
  const { t } = useTranslation();
  const language = useLocale();

  const {
    questionnaireVersionId,
    pricingFieldIdentifiers,
    isLoading: isQuestionnaireVersionDataLoading,
  } = useContext(QuestionnaireVersionDataContext);

  const questionnaireVersionData = useQuestionnaireVersionDetailWithNodeIdInfo();

  const [selectedFieldIdentifiers, setSelectedFieldIdentifiers] = useState<string[]>(
    pricingFieldIdentifiers?.nodeIds ?? [],
  );

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);

  const { questionnaires, questionnaireVersions, setSelectedQuestionnaireId, selectedQuestionnaireId } =
    useContext(QuestionnaireVersionsContext);

  useEffect(() => {
    setSelectedQuestionnaireId('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelectedFieldIdentifiers(pricingFieldIdentifiers?.nodeIds ?? []);
  }, [pricingFieldIdentifiers, questionnaireVersionId]);

  const questionnaireVersionsSelectOptions = useMemo(() => {
    return questionnaireVersions.map((questionnaireVersion) => {
      const versionDescription = questionnaireVersion.isDraft
        ? t('admin.questionnaireManagement.draftQuestionnaireVersion')
        : questionnaireVersion.description;

      return {
        value: questionnaireVersion.id,
        label: `${questionnaireVersion.majorVersion}.${questionnaireVersion.minorVersion}: ${versionDescription}`,
      };
    });
  }, [questionnaireVersions, t]);

  const questionnaireSelectOptions = useMemo(() => {
    return questionnaires.map((questionnaire) => {
      return {
        value: questionnaire.id,
        label: questionnaire.name[language] as string,
      };
    });
  }, [language, questionnaires]);

  const onQuestionnaireFilterChanged = useCallback(
    (questionnaireId: string) => {
      setSelectedQuestionnaireId(questionnaireId);
      const questionnaire = questionnaires.find((questionnaire) => questionnaire.id === questionnaireId);
      if (!questionnaire) return;
      const questionnaireVersion = questionnaireVersions.filter((q) => q.questionnaireId === questionnaireId)[0];
      if (!questionnaireVersion) return;
      onQuestionnaireVersionFilterChanged(questionnaireVersion.id);
    },
    [questionnaires, onQuestionnaireVersionFilterChanged, questionnaireVersions, setSelectedQuestionnaireId],
  );

  const onConfirmChangesClick = useCallback(() => {
    if (pricingFieldIdentifiers) {
      onSavingPricingFieldIdentifiers(pricingFieldIdentifiers.id, selectedFieldIdentifiers);
      setIsConfirmModalOpen(false);
    }
  }, [onSavingPricingFieldIdentifiers, selectedFieldIdentifiers, pricingFieldIdentifiers]);

  const allQuestionnaireFieldIdentifiers: string[] = useMemo(() => {
    if (!questionnaireVersionData || !questionnaireVersionData.questionnaireNodeIds) {
      return [];
    }

    const nodeIds = questionnaireVersionData.questionnaireNodeIds.inQuestionnaire.allLeafs.map(
      (leaf) => leaf.answerNodeId,
    );

    const nodeIdsWithoutSelection = nodeIds.filter((field) => !selectedFieldIdentifiers.includes(field));

    return [...new Set(nodeIdsWithoutSelection)];
  }, [questionnaireVersionData, selectedFieldIdentifiers]);

  if (!questionnaireVersionId || !selectedQuestionnaireId) {
    return null;
  }

  return (
    <Box m={2} width='100%'>
      <HelmetContainer text={t('pageTitles.pricingFieldIdentifiersManagement')} />
      <Fragment>
        <Box display='flex' justifyContent='space-between' mb={3}>
          <QuestionnaireSelect
            isSearchFilter
            id='questionnaire-select'
            label={t('admin.questionnaireManagement.title')}
            value={selectedQuestionnaireId}
            options={questionnaireSelectOptions}
            onChange={onQuestionnaireFilterChanged}
          />
          <QuestionnaireVersionSelect
            isSearchFilter
            id='questionnaire-version-select'
            label={t('admin.pricingFieldIdentifiersManagement.questionnaireVersion')}
            value={questionnaireVersionId}
            options={questionnaireVersionsSelectOptions}
            onChange={onQuestionnaireVersionFilterChanged}
          />
          <ActionButton
            data-testid='save-pricing-field-identifiers-button'
            color='primary'
            variant='contained'
            onClick={() => setIsConfirmModalOpen(true)}
            startIcon={<AddIcon htmlColor='white' />}
            disabled={isQuestionnaireVersionDataLoading}
          >
            {t('cta.save')}
          </ActionButton>
        </Box>
        {allQuestionnaireFieldIdentifiers.length === 0 ? (
          <Loader size='88px' />
        ) : (
          <Fragment>
            <PricingFieldIdentifiersManagementSelector
              onFieldIdentifiersSelectionChange={(selectedFieldIdentifiers: string[]) => {
                setSelectedFieldIdentifiers(selectedFieldIdentifiers);
              }}
              availableFieldIdentifiers={allQuestionnaireFieldIdentifiers}
              pricingFieldIdentifiers={selectedFieldIdentifiers}
            />
            <ConfirmPricingFieldIdentifiersModal
              isOpen={isConfirmModalOpen}
              onConfirmChangesClick={onConfirmChangesClick}
              onClose={() => setIsConfirmModalOpen(false)}
            />
          </Fragment>
        )}
      </Fragment>
    </Box>
  );
}
