import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ESignSignerStatus, IconName } from '@breathelife/types';
import { Box, Icon } from '@breathelife/ui-components';

import { useTheme } from '../../Styles/themed-styled-components';
import { LeadPlatformTheme } from '../../Styles/Types';
import { BadgeConfig, BadgeUI, StyledBadge, StyledBadgeIconContainer } from './Badge';

export type ESignSignerStatusBadgeProps = {
  containerTestId?: string;
  iconContainerTestId?: string;
  status: ESignSignerStatus | undefined;
  withIcon?: boolean;
};

export function ESignSignerStatusBadge({
  containerTestId = 'esign-signer-status-badge-container',
  iconContainerTestId = 'esign-signer-status-icon-container',
  status,
  withIcon = false,
}: ESignSignerStatusBadgeProps): ReactElement {
  const theme = useTheme() as LeadPlatformTheme;
  const { t } = useTranslation();

  const { icon, label, styles } = useMemo<BadgeConfig>(() => {
    if (!status) {
      return {
        icon: <Icon name={IconName.circledSignature} size='20' />,
        label: t(`eSignature.signerStatus.notSigned`),
        styles: { backgroundColor: theme.colors.grey[20], color: theme.colors.grey[60] },
      };
    }

    if (!Object.values(ESignSignerStatus).includes(status)) {
      return {
        icon: <Icon name={IconName.circledSignature} size='20' />,
        label: t(`eSignature.signerStatus.unknown`),
        styles: { backgroundColor: theme.colors.warning[20], color: theme.colors.warning[60] },
      };
    }

    const signerStatusUIMap: Record<ESignSignerStatus, BadgeUI> = {
      [ESignSignerStatus.EMAIL_BOUNCED]: {
        icon: <Icon name={IconName.circledSignature} size='20' />,
        styles: { backgroundColor: theme.colors.red[20], color: theme.colors.red[60] },
      },
      [ESignSignerStatus.EXPIRED]: {
        icon: <Icon name={IconName.circledSignature} size='20' />,
        styles: { backgroundColor: theme.colors.red[20], color: theme.colors.red[60] },
      },
      [ESignSignerStatus.INTERNAL_SIGNING_EMAIL_SENT]: {
        icon: <Icon name={IconName.circledSignature} size='20' />,
        styles: { backgroundColor: theme.colors.orange[20], color: theme.colors.orange[60] },
      },
      [ESignSignerStatus.SIGNED]: {
        icon: <Icon name={IconName.circledSignature} size='20' />,
        styles: { backgroundColor: theme.colors.green[20], color: theme.colors.green[60] },
      },
      [ESignSignerStatus.SIGNER_LOCKED_OUT]: {
        icon: <Icon name={IconName.circledSignature} size='20' />,
        styles: { backgroundColor: theme.colors.red[20], color: theme.colors.red[60] },
      },
    };

    return { label: t(`eSignature.signerStatus.${status}`), ...signerStatusUIMap[status] };
  }, [status, t, theme]);

  return (
    <Box alignItems='center' display='flex' data-testid={containerTestId}>
      {withIcon && icon ? (
        <StyledBadgeIconContainer data-testid={iconContainerTestId}>{icon}</StyledBadgeIconContainer>
      ) : null}
      <StyledBadge backgroundColor={styles.backgroundColor} label={label} textColor={styles.color} />
    </Box>
  );
}
