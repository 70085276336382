import { ReactElement, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCarrierContext, useNavigation } from '../../../../../Hooks';
import { Lead } from '../../../../../Models/Lead';
import { useLaunchAssistedApplicationMutation } from '../../../../../ReactQuery/AssistedApplication/assistedApplication.mutations';
import {
  ApplicationsContainer,
  ExistingApplicationsContainer,
  LaunchApplicationContainer,
  NaEmptyImage,
} from './Styles';
import { LeadItem } from '../../../../../Components/LeadItem/LeadItem';
import { CreateButton, CreateButtonActionType } from '../../../../../Components/CreateButton/CreateButton';
import { generateApplicationUrl } from '../../../../../Navigation/Urls';
import { Application } from '@breathelife/types';

type ApplicationsViewContainerProps = {
  lead?: Lead;
};

export function ApplicationsViewContainer(props: ApplicationsViewContainerProps): ReactElement | null {
  const { lead } = props;
  const { leadTab } = useNavigation();
  const { features } = useCarrierContext();
  const navigate = useNavigate();

  const hasExistingApplication = !!lead?.applications?.length;

  const launchApplicationSuccess = useCallback(
    (application: Application) => {
      if (!application.leadId) return;
      navigate(generateApplicationUrl(application.id, application.leadId, leadTab));
    },
    [leadTab, navigate],
  );

  const launchExistingAssistedApplicationMutation = useLaunchAssistedApplicationMutation({
    onSuccess: (data: Application) => {
      launchApplicationSuccess(data);
    },
  });

  const launchExistingApplication = useCallback(
    (application: Application | undefined, canOnlyReadApplication: boolean) => {
      if (lead?.id && application) {
        if (canOnlyReadApplication) {
          navigate(generateApplicationUrl(application.id, lead.id, leadTab));
        } else {
          launchExistingAssistedApplicationMutation.mutate({
            applicationId: application.id,
          });
        }
      }
    },
    [navigate, launchExistingAssistedApplicationMutation, lead, leadTab],
  );

  if (!lead) return null;

  const leadApplication =
    lead?.applications.find((application) => {
      return application.id === lead.applicationId;
    }) || lead?.applications[0];

  const title =
    features.hiddenLeads?.enabled && leadApplication?.refNoIncrement && !leadApplication?.submitted
      ? leadApplication.refNoIncrement.toString()
      : leadApplication?.refNo;

  return (
    <ApplicationsContainer>
      {!hasExistingApplication && (
        <LaunchApplicationContainer>
          <NaEmptyImage role='img' />
          <CreateButton action={CreateButtonActionType.createApplicationAndLaunch} />
        </LaunchApplicationContainer>
      )}

      {hasExistingApplication && (
        <ExistingApplicationsContainer>
          <LeadItem
            key={leadApplication.id}
            application={leadApplication}
            isItemSelected={
              lead?.applicationId === leadApplication.id || lead?.applications?.[0].id === leadApplication.id
            }
            lead={lead}
            onLaunchApplication={(application: Application | undefined, canOnlyReadApplication: boolean) =>
              launchExistingApplication(application, canOnlyReadApplication)
            }
            title={'#' + title}
            titleVariant='h2'
          />
        </ExistingApplicationsContainer>
      )}
    </ApplicationsContainer>
  );
}
