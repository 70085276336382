import { TFunction } from 'i18next';

import { fieldTypeToValidation } from '@breathelife/insurance-form-builder';
import { DataLabel } from '@breathelife/meta-cruncher';
import { InfoVariants } from '@breathelife/questionnaire-engine';
import { IsoCountryCode, NumericalDataType, FieldTypes, SubsectionVariant } from '@breathelife/types';
import { AutocompleteOption } from '@breathelife/ui-components';

import { PlatformFeatures } from '../../Models/PlatformFeatures';

export function getFieldTypeOptions(t: TFunction): AutocompleteOption[] {
  return Object.values(FieldTypes)
    .map((fieldType) => ({
      value: fieldType,
      label: t(`admin.questionnaireManagement.fieldTypes.${fieldType}`),
    }))
    .filter(({ value }) => value !== FieldTypes.yearMonth)
    .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
}

export const DATA_LABEL_INHERIT_KEY = 'inherit';

export function getDataLabelOptions(t: TFunction): AutocompleteOption[] {
  return [
    DATA_LABEL_INHERIT_KEY,
    DataLabel.Unknown,
    DataLabel.KnownSafe,
    DataLabel.PII,
    DataLabel.PHI,
    DataLabel.IdentifiablePHI,
    DataLabel.ReferenceNumber,
    DataLabel.PostalCode,
    DataLabel.DateOfBirth,
    DataLabel.IdentifiableDate,
    DataLabel.NonIdentifiableDate,
    DataLabel.FreeFormText,
    DataLabel.AgeAtServiceTime,
    DataLabel.Language,
    DataLabel.AdvisorEmail,
    DataLabel.LoginEmail,
    DataLabel.InsuredPersonEmail,
    DataLabel.LeadEmail,
    DataLabel.Email,
  ].map((dataLabel) => ({
    value: dataLabel,
    label: t(`admin.questionnaireManagement.dataLabels.${dataLabel}`),
  }));
}

export function dataLabelSelectionToDataLabel(selection: string): DataLabel | undefined {
  if (selection === DATA_LABEL_INHERIT_KEY) {
    // Don't set a label, empty labels will inherit from their parents.
    return undefined;
  }

  return selection as DataLabel;
}

export type ValidationOptionsByFieldType = { [key in FieldTypes]: AutocompleteOption[] };

export function getValidationOptionsByFieldType(t: TFunction): ValidationOptionsByFieldType {
  return {
    [FieldTypes.input]: Object.values(fieldTypeToValidation[FieldTypes.input]).map((validationKey) => ({
      value: validationKey,
      label: t(`admin.questionnaireManagement.validateAs.${validationKey}`),
    })),
    [FieldTypes.textarea]: Object.values(fieldTypeToValidation[FieldTypes.textarea]).map((validationKey) => ({
      value: validationKey,
      label: t(`admin.questionnaireManagement.validateAs.${validationKey}`),
    })),
    [FieldTypes.radio]: Object.values(fieldTypeToValidation[FieldTypes.radio]).map((validationKey) => ({
      value: validationKey,
      label: t(`admin.questionnaireManagement.validateAs.${validationKey}`),
    })),
    [FieldTypes.dropdown]: Object.values(fieldTypeToValidation[FieldTypes.dropdown]).map((validationKey) => ({
      value: validationKey,
      label: t(`admin.questionnaireManagement.validateAs.${validationKey}`),
    })),
    [FieldTypes.checkbox]: Object.values(fieldTypeToValidation[FieldTypes.checkbox]).map((validationKey) => ({
      value: validationKey,
      label: t(`admin.questionnaireManagement.validateAs.${validationKey}`),
    })),
    [FieldTypes.checkboxGroup]: Object.values(fieldTypeToValidation[FieldTypes.checkboxGroup]).map((validationKey) => ({
      value: validationKey,
      label: t(`admin.questionnaireManagement.validateAs.${validationKey}`),
    })),
    [FieldTypes.date]: Object.values(fieldTypeToValidation[FieldTypes.date]).map((validationKey) => ({
      value: validationKey,
      label: t(`admin.questionnaireManagement.validateAs.${validationKey}`),
    })),
    [FieldTypes.number]: Object.values(fieldTypeToValidation[FieldTypes.number]).map((validationKey) => ({
      value: validationKey,
      label: t(`admin.questionnaireManagement.validateAs.${validationKey}`),
    })),
    [FieldTypes.money]: Object.values(fieldTypeToValidation[FieldTypes.money]).map((validationKey) => ({
      value: validationKey,
      label: t(`admin.questionnaireManagement.validateAs.${validationKey}`),
    })),
    [FieldTypes.phone]: Object.values(fieldTypeToValidation[FieldTypes.phone]).map((validationKey) => ({
      value: validationKey,
      label: t(`admin.questionnaireManagement.validateAs.${validationKey}`),
    })),
    [FieldTypes.information]: Object.values(fieldTypeToValidation[FieldTypes.information]).map((validationKey) => ({
      value: validationKey,
      label: t(`admin.questionnaireManagement.validateAs.${validationKey}`),
    })),
    [FieldTypes.button]: Object.values(fieldTypeToValidation[FieldTypes.button]).map((validationKey) => ({
      value: validationKey,
      label: t(`admin.questionnaireManagement.validateAs.${validationKey}`),
    })),
    [FieldTypes.currencyCard]: Object.values(fieldTypeToValidation[FieldTypes.currencyCard]).map((validationKey) => ({
      value: validationKey,
      label: t(`admin.questionnaireManagement.validateAs.${validationKey}`),
    })),
    [FieldTypes.autocomplete]: Object.values(fieldTypeToValidation[FieldTypes.autocomplete]).map((validationKey) => ({
      value: validationKey,
      label: t(`admin.questionnaireManagement.validateAs.${validationKey}`),
    })),
    [FieldTypes.agree]: Object.values(fieldTypeToValidation[FieldTypes.agree]).map((validationKey) => ({
      value: validationKey,
      label: t(`admin.questionnaireManagement.validateAs.${validationKey}`),
    })),
    [FieldTypes.signature]: Object.values(fieldTypeToValidation[FieldTypes.signature]).map((validationKey) => ({
      value: validationKey,
      label: t(`admin.questionnaireManagement.validateAs.${validationKey}`),
    })),
    [FieldTypes.yearMonth]: Object.values(fieldTypeToValidation[FieldTypes.yearMonth]).map((validationKey) => ({
      value: validationKey,
      label: t(`admin.questionnaireManagement.validateAs.${validationKey}`),
    })),
  };
}

export function getSubsectionVariantOptions(t: TFunction, features: PlatformFeatures): AutocompleteOption[] {
  const subsectionVariantOptions = [
    { value: '', label: t('admin.questionnaireManagement.input.noneSet') },
    {
      value: SubsectionVariant.summary,
      label: t(`admin.questionnaireManagement.subsectionVariants.${SubsectionVariant.summary}`),
    },
    {
      value: SubsectionVariant.quoter,
      label: t(`admin.questionnaireManagement.subsectionVariants.${SubsectionVariant.quoter}`),
    },
    {
      value: SubsectionVariant.payment,
      label: t(`admin.questionnaireManagement.subsectionVariants.${SubsectionVariant.payment}`),
    },
    {
      value: SubsectionVariant.submission,
      label: t(`admin.questionnaireManagement.subsectionVariants.${SubsectionVariant.submission}`),
    },
    {
      value: SubsectionVariant.addon,
      label: t(`admin.questionnaireManagement.subsectionVariants.${SubsectionVariant.addon}`),
    },
    {
      value: SubsectionVariant.productSummary,
      label: t(`admin.questionnaireManagement.subsectionVariants.${SubsectionVariant.productSummary}`),
    },
  ];

  if (features.productSelection?.enabled) {
    subsectionVariantOptions.push({
      value: SubsectionVariant.productSelection,
      label: t(`admin.questionnaireManagement.subsectionVariants.${SubsectionVariant.productSelection}`),
    });
  }

  return subsectionVariantOptions;
}

export function getInfoFieldStylingOptions(t: TFunction): AutocompleteOption[] {
  return Object.keys(InfoVariants)
    .map((styleKey) => ({
      label: t(`admin.questionnaireManagement.infoFieldStyles.${styleKey}`),
      value: styleKey,
    }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));
}

export function getNumericalDataTypeOptions(t: TFunction): AutocompleteOption[] {
  return Object.keys(NumericalDataType)
    .map((numericDataTypeKey) => ({
      label: t(`admin.questionnaireManagement.numericalDataTypeOptions.${numericDataTypeKey}`),
      value: numericDataTypeKey,
    }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));
}

export function getCountryCodeOptions(t: TFunction): AutocompleteOption[] {
  const countryCodes: IsoCountryCode[] = ['CA', 'US'];
  return countryCodes.map((countryCode) => ({
    value: countryCode,
    label: t(`admin.questionnaireManagement.input.autocompleteCountryCodes.${countryCode}`),
  }));
}
