import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MessageType } from '@breathelife/ui-components';

type CommonState = {
  title?: string;
  message?: string;
  autoHideDuration?: number;
  dataTestId?: string;
};

export type SetNotificationState = CommonState & { type?: MessageType };
export type SetErrorState =
  | (CommonState & { title: string; message: string })
  | (CommonState & { message: string; title?: never });
export type SetSuccessState = SetErrorState;

export type NotificationState = SetNotificationState;

type ActionsType = {
  setNotification: (state: NotificationState, { payload }: PayloadAction<SetNotificationState>) => void;
  setError: (state: NotificationState, { payload }: PayloadAction<SetErrorState>) => void;
  setSuccess: (state: NotificationState, { payload }: PayloadAction<SetSuccessState>) => void;
  setWarning: (state: NotificationState, { payload }: PayloadAction<SetNotificationState>) => void;
  setInfo: (state: NotificationState, { payload }: PayloadAction<SetNotificationState>) => void;
};

const defaultState = {};

export const notificationSlice = createSlice<NotificationState, ActionsType>({
  name: 'leadPlatform/notification',
  initialState: defaultState,
  reducers: {
    setNotification: (state: NotificationState, { payload }: PayloadAction<SetNotificationState>) => {
      state.type = payload.type;
      state.title = payload.title;
      state.message = payload.message;
      state.autoHideDuration = payload.autoHideDuration;
      state.dataTestId = payload.dataTestId;
    },
    setError: (state: NotificationState, { payload }: PayloadAction<SetErrorState>) => {
      state.type = MessageType.error;
      state.title = payload.title;
      state.message = payload.message;
      state.autoHideDuration = payload.autoHideDuration;
      state.dataTestId = payload.dataTestId;
    },
    setSuccess: (state: NotificationState, { payload }: PayloadAction<SetSuccessState>) => {
      state.type = MessageType.success;
      state.title = payload.title;
      state.message = payload.message;
      state.autoHideDuration = payload.autoHideDuration;
      state.dataTestId = payload.dataTestId;
    },
    setWarning: (state: NotificationState, { payload }: PayloadAction<SetNotificationState>) => {
      state.type = MessageType.warning;
      state.title = payload.title;
      state.message = payload.message;
      state.autoHideDuration = payload.autoHideDuration;
      state.dataTestId = payload.dataTestId;
    },
    setInfo: (state: NotificationState, { payload }: PayloadAction<SetNotificationState>) => {
      state.type = MessageType.info;
      state.title = payload.title;
      state.message = payload.message;
      state.autoHideDuration = payload.autoHideDuration;
      state.dataTestId = payload.dataTestId;
    },
  },
});
