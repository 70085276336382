import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { LoaderOverlay } from '@breathelife/ui-components';

import Typography from '../../../../Components/Typography';
import getCurrency from '../../../../Helpers/currency';
import { useCarrierContext, useNavigation } from '../../../../Hooks';
import { toCurrency } from '../../../../Localization/utils';
import { useGetProductsWidgetTotalPremiumsQuery } from '../../../../ReactQuery/AssistedApplication/assistedApplication.queries';
import { CardContainer, CardHeader } from '../ProductWidget/Styles';
import { CardBody } from './Styles';

export function TotalPremiumsWidget(): ReactElement {
  const { countryCode } = useCarrierContext();
  const { t } = useTranslation();
  const { applicationId } = useNavigation();
  const { data: totalPremiums, isFetching } = useGetProductsWidgetTotalPremiumsQuery(applicationId);

  return (
    <CardContainer>
      <CardHeader>
        <Typography variant='h3' component='h2'>
          {t('assistedApplication.totalPremiumsWidget.title')}
        </Typography>
      </CardHeader>
      <LoaderOverlay isLoading={isFetching}></LoaderOverlay>
      <CardBody>
        <Typography variant='body4' component='p'>
          {t('assistedApplication.totalPremiumsWidget.totalPremium')}
        </Typography>
        {totalPremiums ? (
          <div>
            <p>
              <Typography variant='h2' component='span'>
                {toCurrency(totalPremiums.annual, getCurrency(countryCode), { minimumFractionDigits: 2 })}
              </Typography>
              <Typography variant='small1' component='span'>
                {t(`assistedApplication.totalPremiumsWidget.paymentFrequency.annually`)}
              </Typography>
            </p>
            <p>
              <Typography variant='h2' component='span'>
                {toCurrency(totalPremiums.monthly, getCurrency(countryCode), { minimumFractionDigits: 2 })}
              </Typography>
              <Typography variant='small1' component='span'>
                {t(`assistedApplication.totalPremiumsWidget.paymentFrequency.monthly`)}
              </Typography>
            </p>
          </div>
        ) : (
          <p>
            <Typography variant='h2' component='p'>
              --
            </Typography>
          </p>
        )}
      </CardBody>
    </CardContainer>
  );
}
