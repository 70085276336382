import { EmailNotificationTypes } from '@breathelife/types';

import { Translation } from '../../../Localization';

const translation: Translation = {
  needsAnalysis: 'Invites',
  questionnaire: 'Questionnaire',
  cta: {
    sendNeedsAnalysis: 'Send Invite',
    resendNeedsAnalysis: 'Resend Invite',
    needAnalysisDetails: 'Questionnaire Details',
  },
  modals: {
    sendEmail: {
      selectNeedsAnalysis: 'Select the invite you want to share with your lead:',
      willBeSentTo: 'An invite will be sent to:',
    },
  },
  notificationPreferences: {
    emails: {
      [EmailNotificationTypes.leadCompletedNeedsAnalysisEmail]: 'Email me when a Lead completes the Questionnaire',
    },
  },
  tabs: {
    needsAnalysis: {
      sent: 'Invite Sent',
      notSent: {
        notSentYet: 'No Invite has been sent to this client yet',
      },
    },
  },
  product: {
    missingNeedsAnalysisInformation:
      "We're missing information to determine eligible products and calculate prices. Please send an Invite to your Customer.",
  },
};
export default translation;
