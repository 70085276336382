import { ReactElement } from 'react';
import styled from '../Styles/themed-styled-components';

type ProfilePictureUploadAvatarProps = {
  width: number;
  height: number;
};

const StyledCircle = styled.circle`
  stroke: ${(props) => props.theme.colors.primary[40]};
`;

const StyledPath = styled.path`
  fill: ${(props) => props.theme.colors.primary[40]};
`;

const StyledSvg = styled.svg`
  & {
    :hover {
      ${StyledCircle} {
        stroke: ${(props) => props.theme.colors.primary[70]};
      }
      ${StyledPath} {
        fill: ${(props) => props.theme.colors.primary[70]};
      }
    }
  }
`;

export function ProfilePictureUploadAvatar(props: ProfilePictureUploadAvatarProps): ReactElement {
  const { width, height } = props;
  return (
    <StyledSvg width={width} height={height} viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g filter='url(#filter0_d)'>
        <circle cx='32' cy='31' r='28' fill='white' />
        <StyledCircle cx='32' cy='31' r='28' strokeDasharray='4 4' />
      </g>
      <g clipPath='url(#clip0)'>
        <StyledPath d='M32.9893 24.3708C32.7432 24.3708 32.5027 24.4438 32.2981 24.5805C32.0935 24.7172 31.934 24.9115 31.8398 25.1389C31.7457 25.3662 31.721 25.6164 31.769 25.8577C31.817 26.0991 31.9355 26.3207 32.1095 26.4947C32.2835 26.6687 32.5052 26.7872 32.7466 26.8352C32.9879 26.8833 33.2381 26.8586 33.4654 26.7644C33.6927 26.6703 33.8871 26.5108 34.0238 26.3062C34.1605 26.1016 34.2335 25.8611 34.2335 25.615C34.2336 25.4516 34.2015 25.2897 34.139 25.1387C34.0765 24.9877 33.9848 24.8505 33.8693 24.735C33.7537 24.6194 33.6165 24.5278 33.4655 24.4653C33.3145 24.4028 33.1527 24.3707 32.9893 24.3708Z' />
        <StyledPath d='M41.5125 23.5L39.5 21.4875C39.1874 21.1752 38.7635 20.9999 38.3217 21H28.6667C28.2246 21 27.8007 21.1756 27.4882 21.4882C27.1756 21.8007 27 22.2246 27 22.6667V28.7292C26.9996 28.7834 27.0204 28.8357 27.0582 28.8746C27.0959 28.9136 27.1474 28.9362 27.2017 28.9375C27.5067 28.9425 28.0667 28.9658 28.4392 29.0067C28.4681 29.0099 28.4973 29.0069 28.525 28.9979C28.5526 28.9889 28.578 28.9742 28.5995 28.9546C28.6211 28.9351 28.6382 28.9112 28.6497 28.8845C28.6613 28.8579 28.6671 28.8291 28.6667 28.8V23.0833C28.6667 22.9728 28.7106 22.8668 28.7887 22.7887C28.8669 22.7106 28.9728 22.6667 29.0833 22.6667H38.1492C38.2595 22.6667 38.3653 22.7104 38.4433 22.7883L40.2117 24.5567C40.2896 24.6348 40.3333 24.7405 40.3333 24.8508V36C40.3333 36.1105 40.2894 36.2165 40.2113 36.2946C40.1332 36.3728 40.0272 36.4167 39.9167 36.4167H34.2017C34.1527 36.4168 34.1055 36.4346 34.0686 36.4667C34.0317 36.4988 34.0077 36.5432 34.0008 36.5917C33.9383 37.0053 33.8367 37.4121 33.6975 37.8067C33.6858 37.8375 33.6817 37.8706 33.6856 37.9034C33.6895 37.9361 33.7013 37.9674 33.72 37.9945C33.7387 38.0217 33.7637 38.0438 33.7929 38.0592C33.8221 38.0745 33.8545 38.0825 33.8875 38.0825H40.3333C40.7754 38.0825 41.1993 37.9069 41.5119 37.5943C41.8244 37.2818 42 36.8579 42 36.4158V24.6783C42.0001 24.2365 41.8248 23.8126 41.5125 23.5Z' />
        <StyledPath d='M33.5258 28.8333C33.4976 28.7889 33.4586 28.7524 33.4125 28.727C33.3664 28.7017 33.3147 28.6884 33.2621 28.6884C33.2094 28.6884 33.1577 28.7017 33.1116 28.727C33.0655 28.7524 33.0265 28.7889 32.9983 28.8333L31.96 30.4942C31.9351 30.5343 31.9249 30.5819 31.9313 30.6288C31.9377 30.6756 31.9602 30.7188 31.995 30.7508C32.3172 31.0547 32.6086 31.3897 32.865 31.7508C32.8839 31.7772 32.909 31.7986 32.938 31.8131C32.967 31.8276 32.9992 31.8348 33.0316 31.8342H38.9308C38.9587 31.8343 38.9861 31.827 39.0101 31.8129C39.0342 31.7988 39.054 31.7785 39.0675 31.7541C39.0809 31.7297 39.0876 31.7021 39.0867 31.6743C39.0858 31.6464 39.0775 31.6193 39.0625 31.5958L36.3625 27.28C36.3345 27.2352 36.2957 27.1983 36.2495 27.1727C36.2034 27.1471 36.1515 27.1336 36.0987 27.1336C36.0459 27.1336 35.994 27.1471 35.9479 27.1727C35.9018 27.1983 35.8629 27.2352 35.835 27.28L34.3266 29.6967C34.3126 29.719 34.2931 29.7375 34.27 29.7503C34.2469 29.7631 34.221 29.7698 34.1946 29.7698C34.1681 29.7698 34.1422 29.7631 34.1191 29.7503C34.096 29.7375 34.0765 29.719 34.0625 29.6967L33.5258 28.8333Z' />
        <StyledPath d='M27.4167 30.1667C26.3454 30.1667 25.2981 30.4843 24.4073 31.0795C23.5166 31.6747 22.8223 32.5207 22.4123 33.5105C22.0023 34.5002 21.8951 35.5893 22.1041 36.6401C22.3131 37.6908 22.829 38.656 23.5865 39.4135C24.344 40.171 25.3092 40.6869 26.3599 40.8959C27.4107 41.1049 28.4998 40.9976 29.4895 40.5877C30.4793 40.1777 31.3253 39.4834 31.9205 38.5927C32.5157 37.7019 32.8333 36.6546 32.8333 35.5833C32.8316 34.1473 32.2603 32.7706 31.2449 31.7551C30.2294 30.7397 28.8527 30.1684 27.4167 30.1667ZM29.5 36.2083H28.25C28.1947 36.2083 28.1418 36.2303 28.1027 36.2693C28.0636 36.3084 28.0417 36.3614 28.0417 36.4167V37.6667C28.0417 37.8324 27.9758 37.9914 27.8586 38.1086C27.7414 38.2258 27.5824 38.2917 27.4167 38.2917C27.2509 38.2917 27.0919 38.2258 26.9747 38.1086C26.8575 37.9914 26.7917 37.8324 26.7917 37.6667V36.4167C26.7917 36.3614 26.7697 36.3084 26.7306 36.2693C26.6916 36.2303 26.6386 36.2083 26.5833 36.2083H25.3333C25.1676 36.2083 25.0086 36.1425 24.8914 36.0253C24.7742 35.9081 24.7083 35.7491 24.7083 35.5833C24.7083 35.4176 24.7742 35.2586 24.8914 35.1414C25.0086 35.0242 25.1676 34.9583 25.3333 34.9583H26.5833C26.6386 34.9583 26.6916 34.9364 26.7306 34.8973C26.7697 34.8582 26.7917 34.8052 26.7917 34.75V33.5C26.7917 33.3342 26.8575 33.1753 26.9747 33.058C27.0919 32.9408 27.2509 32.875 27.4167 32.875C27.5824 32.875 27.7414 32.9408 27.8586 33.058C27.9758 33.1753 28.0417 33.3342 28.0417 33.5V34.75C28.0417 34.8052 28.0636 34.8582 28.1027 34.8973C28.1418 34.9364 28.1947 34.9583 28.25 34.9583H29.5C29.6658 34.9583 29.8247 35.0242 29.9419 35.1414C30.0592 35.2586 30.125 35.4176 30.125 35.5833C30.125 35.7491 30.0592 35.9081 29.9419 36.0253C29.8247 36.1425 29.6658 36.2083 29.5 36.2083Z' />
      </g>
      <defs>
        <filter
          id='filter0_d'
          x='0.5'
          y='0.5'
          width='63'
          height='63'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' />
          <feOffset dy='1' />
          <feGaussianBlur stdDeviation='1.5' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
        </filter>
        <clipPath id='clip0'>
          <rect width='20' height='20' fill='white' transform='translate(22 21)' />
        </clipPath>
      </defs>
    </StyledSvg>
  );
}
