import { useTranslation } from 'react-i18next';
import { Application } from '../../Models/Application';
import { useGetQuestionnaireForQuestionnaireVersionId } from '../../ReactQuery/Questionnaire/questionnaire.queries';
import { useLocale } from '../useLocale';

export function useApplicationQuestionnaireAndLineOfBusiness(application?: Application): {
  questionnaireName?: string;
  lineOfBusinessName?: string;
} {
  const { t } = useTranslation();
  const locale = useLocale();

  const { data: questionnaire } = useGetQuestionnaireForQuestionnaireVersionId(
    application?.currentQuestionnaireVersionId,
  );

  if (!questionnaire || !application) {
    return {};
  }

  let lineOfBusinessName = questionnaire?.linesOfBusiness?.find((lob) => lob.tag === application.lineOfBusiness)?.name[
    locale
  ];

  if (!lineOfBusinessName && application.lineOfBusiness) {
    lineOfBusinessName = t(`modals.createButton.lineOfBusinessChoices.${application.lineOfBusiness}`);
  }

  return {
    questionnaireName: questionnaire?.name[locale],
    lineOfBusinessName,
  };
}
