import _ from 'lodash';
import { generatePath } from 'react-router-dom';

import { LeadTab } from '../Models/Layout';
import { DrawerType } from '../Components/AssistedApplication/AssistedApplication';

export enum UrlParamKeys {
  leadTab = 'leadTab',
  leadId = 'leadId',
  applicationId = 'applicationId',
  questionnaireId = 'questionnaireId',
  questionnaireVersionId = 'questionnaireVersionId',
  drawerType = 'drawerType',
  adHocSignatureRequestId = 'adHocSignatureRequestId',
}

// React-Router (v6+) now uses two types of paths. An absolute path to redirect, and a relative path to defined nested routes.
type BaseUrls = {
  fullPath: string; // Full paths (absolute) are used to redirect using <Navigate /> component
  path: string; // Paths (relative) are used to defined the nested routes for the lead-platform with <Routes /> component
};

const urls: Record<string, BaseUrls> = {
  pro: {
    fullPath: '/pro',
    path: '/pro',
  },

  // Lead management
  leads: {
    fullPath: '/pro/leads',
    path: '/leads',
  },
  leadsList: {
    fullPath: `/pro/leads/:${UrlParamKeys.leadTab}`,
    path: `/leads/:${UrlParamKeys.leadTab}`,
  },
  leadDetail: {
    fullPath: `/pro/leads/:${UrlParamKeys.leadTab}/:${UrlParamKeys.leadId}`,
    path: `/leads/:${UrlParamKeys.leadTab}/:${UrlParamKeys.leadId}`,
  },
  application: {
    fullPath: `/pro/leads/:${UrlParamKeys.leadTab}/:${UrlParamKeys.leadId}/applications/:${UrlParamKeys.applicationId}`,
    path: `/leads/:${UrlParamKeys.leadTab}/:${UrlParamKeys.leadId}/applications/:${UrlParamKeys.applicationId}`,
  },
  applicationDrawer: {
    fullPath: `/pro/leads/:${UrlParamKeys.leadTab}/:${UrlParamKeys.leadId}/applications/:${UrlParamKeys.applicationId}/:${UrlParamKeys.drawerType}`,
    path: `/leads/:${UrlParamKeys.leadTab}/:${UrlParamKeys.leadId}/applications/:${UrlParamKeys.applicationId}/:${UrlParamKeys.drawerType}`,
  },

  // Admin tools
  admin: {
    fullPath: '/pro/admin',
    path: '/admin',
  },
  userManagement: {
    fullPath: '/pro/admin/users',
    path: '/admin/users',
  },
  questionnaireManagement: {
    fullPath: '/pro/admin/questionnaire',
    path: '/admin/questionnaire',
  },
  questionnaire: {
    fullPath: `/pro/admin/questionnaire/:${UrlParamKeys.questionnaireId}`,
    path: `/admin/questionnaire/:${UrlParamKeys.questionnaireId}`,
  },
  questionnaireVersion: {
    fullPath: `/pro/admin/questionnaire-version/:${UrlParamKeys.questionnaireVersionId}`,
    path: `/admin/questionnaire-version/:${UrlParamKeys.questionnaireVersionId}`,
  },
  salesDecisionRulesManagement: {
    fullPath: '/pro/admin/sales-decision-rules',
    path: '/admin/sales-decision-rules',
  },
  pricingFieldIdentifiersManagement: {
    fullPath: '/pro/admin/pricing-field-identifiers',
    path: '/admin/pricing-field-identifiers',
  },
  documentsManagement: {
    fullPath: '/pro/admin/documents',
    path: '/admin/documents',
  },
  firmManagement: {
    fullPath: '/pro/admin/firm',
    path: '/admin/firm',
  },
  productManagement: {
    fullPath: '/pro/admin/product',
    path: '/admin/product',
  },
  themeManagement: {
    fullPath: '/pro/admin/theme',
    path: '/admin/theme',
  },
  settingsManagement: {
    fullPath: '/pro/admin/settings',
    path: '/admin/settings',
  },
  auditLogs: {
    fullPath: '/pro/admin/logs',
    path: '/admin/logs',
  },
  questionnaireBuilder: {
    fullPath: '/pro/admin/q-builder',
    path: '/admin/q-builder',
  },
  thirdPartyIntegrations: {
    fullPath: '/pro/admin/third-party-integrations',
    path: '/admin/third-party-integrations',
  },
  entityManagement: {
    fullPath: '/pro/admin/entity',
    path: '/admin/entity',
  },
  entityManagementMapping: {
    fullPath: '/pro/admin/entity/mapping-editor',
    path: '/admin/entity/mapping-editor',
  },
  entityManagementEntity: {
    fullPath: '/pro/admin/entity/entity-editor',
    path: '/admin/entity/entity-editor',
  },
  applicationSupportApplications: {
    fullPath: '/pro/admin/application-support/applications',
    path: '/admin/application-support/applications',
  },
  applicationSupportApplication: {
    fullPath: `/pro/admin/application-support/applications/:${UrlParamKeys.applicationId}`,
    path: `/admin/application-support/applications/:${UrlParamKeys.applicationId}`,
  },
  applicationSupportUnderwritingReports: {
    fullPath: `/pro/admin/application-support/applications/:${UrlParamKeys.applicationId}/underwriting-reports`,
    path: `/admin/application-support/applications/:${UrlParamKeys.applicationId}/underwriting-reports`,
  },
  applicationSupportOverview: {
    fullPath: `/pro/admin/application-support/applications/:${UrlParamKeys.applicationId}/overview`,
    path: `/admin/application-support/applications/:${UrlParamKeys.applicationId}/overview`,
  },
  applicationSupportAssociatedFiles: {
    fullPath: `/pro/admin/application-support/applications/:${UrlParamKeys.applicationId}/associated-files`,
    path: `/admin/application-support/applications/:${UrlParamKeys.applicationId}/associated-files`,
  },
  applicationSupportESignature: {
    fullPath: `/pro/admin/application-support/applications/:${UrlParamKeys.applicationId}/esignature`,
    path: `/admin/application-support/applications/:${UrlParamKeys.applicationId}/esignature`,
  },
  adHocSignatureRequests: {
    fullPath: '/pro/admin/ad-hoc-signature-requests',
    path: '/admin/ad-hoc-signature-requests',
  },
  adHocSignatureRequest: {
    fullPath: `/pro/admin/ad-hoc-signature-requests/:${UrlParamKeys.adHocSignatureRequestId}`,
    path: `/admin/ad-hoc-signature-requests/:${UrlParamKeys.adHocSignatureRequestId}`,
  },

  // Login
  login: {
    fullPath: '/pro/login',
    path: '/login',
  },
  logout: {
    fullPath: '/pro/logout',
    path: '/logout',
  },
  authCallback: {
    fullPath: '/pro/login-callback',
    path: '/login-callback',
  },
};

const DEFAULT_LEAD_TAB = _.findKey(LeadTab, (value) => value === 0); // === 'active'

function getLeadTabKey(leadTab: LeadTab): string | undefined {
  return _.findKey(LeadTab, (value) => value === leadTab) || DEFAULT_LEAD_TAB;
}

export function generateLeadsListUrl(leadTab: LeadTab = LeadTab.active): string {
  return generatePath(urls.leadsList.fullPath, {
    [UrlParamKeys.leadTab]: getLeadTabKey(leadTab),
  });
}

export function generateLeadDetailUrl(leadId: number, leadTab: LeadTab = LeadTab.active): string {
  return generatePath(urls.leadDetail.fullPath, {
    [UrlParamKeys.leadId]: leadId.toString(),
    [UrlParamKeys.leadTab]: getLeadTabKey(leadTab),
  });
}

export function generateApplicationUrl(
  applicationId: string,
  leadId: number,
  leadTab: LeadTab = LeadTab.active,
): string {
  return generatePath(urls.application.fullPath, {
    [UrlParamKeys.applicationId]: applicationId,
    [UrlParamKeys.leadId]: leadId.toString(),
    [UrlParamKeys.leadTab]: getLeadTabKey(leadTab),
  });
}

export function generateApplicationDrawerUrl(
  applicationId: string,
  leadId: number,
  leadTab: LeadTab = LeadTab.active,
  drawerType: DrawerType,
): string {
  return generatePath(urls.applicationDrawer.fullPath, {
    [UrlParamKeys.applicationId]: applicationId,
    [UrlParamKeys.leadId]: leadId.toString(),
    [UrlParamKeys.leadTab]: getLeadTabKey(leadTab),
    [UrlParamKeys.drawerType]: drawerType,
  });
}

export function generateApplicationUnderwritingReportsUrl(applicationId: string): string {
  return generatePath(urls.applicationSupportUnderwritingReports.fullPath, {
    [UrlParamKeys.applicationId]: applicationId,
  });
}

export function generateApplicationSupportUrl(applicationId: string): string {
  return generatePath(urls.applicationSupportApplication.fullPath, {
    [UrlParamKeys.applicationId]: applicationId,
  });
}

export function generateApplicationSupportOverviewUrl(applicationId: string): string {
  return generatePath(urls.applicationSupportOverview.fullPath, {
    [UrlParamKeys.applicationId]: applicationId,
  });
}

export function generateAssociatedFilesUrl(applicationId: string): string {
  return generatePath(urls.applicationSupportAssociatedFiles.fullPath, {
    [UrlParamKeys.applicationId]: applicationId,
  });
}

export function generateApplicationSupportESignatureUrl(applicationId: string): string {
  return generatePath(urls.applicationSupportESignature.fullPath, {
    [UrlParamKeys.applicationId]: applicationId,
  });
}

export function generateQuestionnaireUrl(questionnaireId: string): string {
  return generatePath(urls.questionnaire.fullPath, {
    [UrlParamKeys.questionnaireId]: questionnaireId,
  });
}

export function generateQuestionnaireVersionUrl(questionnaireVersionId: string): string {
  return generatePath(urls.questionnaireVersion.fullPath, {
    [UrlParamKeys.questionnaireVersionId]: questionnaireVersionId,
  });
}

export function generateAdHocSignatureRequestUrl(adHocSignatureRequestId: string): string {
  return generatePath(urls.adHocSignatureRequest.fullPath, {
    [UrlParamKeys.adHocSignatureRequestId]: adHocSignatureRequestId,
  });
}

export default urls;
