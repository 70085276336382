import {
  ESignFieldSetting,
  ESignFieldSettingAnchorPointType,
  ESignFieldSettingPlacementType,
  ESignFieldSettingType,
} from '@breathelife/types';

import { ESignFieldSettingFormData } from './ESignFieldSettingForm';

// https://stackoverflow.com/questions/57103834/typescript-omit-a-property-from-all-interfaces-in-a-union-but-keep-the-union-s
type DistributiveOmit<T, K extends keyof any> = T extends any ? Omit<T, K> : never;

export const ESignFieldSettingUtils = {
  transformFormData(
    data: ESignFieldSettingFormData,
  ): DistributiveOmit<ESignFieldSetting, 'id' | 'signerSettingId'> | undefined {
    const baseProperties = {
      type: ESignFieldSettingType.SIGNATURE,
      top: Number(data.top),
      left: Number(data.left),
      width: Number(data.width),
      height: Number(data.height),
    };

    switch (data.placementType) {
      case ESignFieldSettingPlacementType.RELATIVE:
        return {
          ...baseProperties,
          placementType: ESignFieldSettingPlacementType.RELATIVE,
          anchorText: data.anchorText as string,
          anchorPoint: data.anchorPoint as ESignFieldSettingAnchorPointType,
          anchorIndex: Number(data.anchorIndex),
          anchorCharacterIndex: data.anchorCharacterIndex ? Number(data.anchorCharacterIndex) : 0,
        };

      case ESignFieldSettingPlacementType.ABSOLUTE:
        return {
          ...baseProperties,
          placementType: ESignFieldSettingPlacementType.ABSOLUTE,
          page: Number(data.page),
        };

      default:
        return undefined;
    }
  },
};
