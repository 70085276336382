import {
  isFieldPartIdentifier,
  isQuestionPartIdentifier,
  isSectionGroupPartIdentifier,
  isSectionPartIdentifier,
  isSubsectionPartIdentifier,
  PartIdentifier,
  QuestionnaireBlueprint,
  QuestionnaireElementBlueprint,
} from '@breathelife/types';

export function findElementBlueprintFromPartIdentifier(
  questionnaireBlueprint: QuestionnaireBlueprint,
  partIdentifier: PartIdentifier,
): { blueprint: QuestionnaireElementBlueprint | undefined; areParentsHidden: boolean } | undefined {
  if (isSectionGroupPartIdentifier(partIdentifier)) {
    return;
  }

  let areParentsHidden = false;
  const sectionBlueprint = questionnaireBlueprint.sectionBlueprints.find(
    (b) => b.partName === partIdentifier.sectionPartName,
  );

  if (isSectionPartIdentifier(partIdentifier)) {
    return { blueprint: sectionBlueprint, areParentsHidden };
  }

  if (sectionBlueprint?.hidden) {
    areParentsHidden = true;
  }

  const subsectionBlueprint = sectionBlueprint?.subsections.find(
    (b) => b.partName === partIdentifier.subsectionPartName,
  );

  if (isSubsectionPartIdentifier(partIdentifier)) {
    return { blueprint: subsectionBlueprint, areParentsHidden };
  }

  if (subsectionBlueprint?.hidden) {
    areParentsHidden = true;
  }
  const questionBlueprint = subsectionBlueprint?.questions.find((b) => b.partName === partIdentifier.questionPartName);

  if (isQuestionPartIdentifier(partIdentifier)) {
    return { blueprint: questionBlueprint, areParentsHidden };
  }

  if (questionBlueprint?.hidden) {
    areParentsHidden = true;
  }

  const fieldBlueprint = questionBlueprint?.fields.find((b) => b.partName === partIdentifier.fieldPartName);

  if (isFieldPartIdentifier(partIdentifier)) {
    return { blueprint: fieldBlueprint, areParentsHidden };
  }

  return;
}

export function findElementBlueprintInSiblingsFromPartName(
  blueprints: QuestionnaireElementBlueprint[],
  partName: string,
): QuestionnaireElementBlueprint | undefined {
  return blueprints.find((b) => b.partName === partName);
}
