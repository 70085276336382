import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, IconButton, Typography } from '@breathelife/mui';
import { IconName, LocalizedThirdPartyIntegration, ThirdPartyIntegrationStatus } from '@breathelife/types';
import { Icon } from '@breathelife/ui-components';

import { Button } from '../../../Components/Button/Button';
import styled, { useTheme } from '../../../Styles/themed-styled-components';

const CardContainer = styled(Box)`
  border: 1px solid ${({ theme }) => theme.colors.grey[40]};
  box-shadow: 0px 1px 3px rgba(9, 30, 66, 0.1);
`;

const CardDescription = styled(Typography)`
  color: ${({ theme }) => theme.colors.grey[80]};
`;

const ActionButton = styled(IconButton)`
  transform: rotate(90deg);
  padding: 0;
  position: relative;
`;

const StatusCircle = styled.div<{ status: ThirdPartyIntegrationStatus }>`
  display: block;
  border-radius: 100%;
  background-color: ${({ theme, status }) =>
    status === ThirdPartyIntegrationStatus.active ? theme.colors.green[50] : theme.colors.grey[50]};
  border: 2px solid
    ${({ theme, status }) =>
      status === ThirdPartyIntegrationStatus.active ? theme.colors.green[30] : theme.colors.grey[40]};
  width: 12px;
  height: 12px;
  margin-right: 12px;
`;

type ThirdPartyIntegrationCardProps = {
  data: LocalizedThirdPartyIntegration;
};

function IntegrationStatus({ status }: { status: ThirdPartyIntegrationStatus }): ReactElement {
  const { t } = useTranslation();
  return (
    <Box display='flex' alignItems={'center'}>
      <StatusCircle status={status} />
      <Typography variant='body2'>{t(`admin.thirdPartyIntegrations.status.${status}`)}</Typography>
    </Box>
  );
}

export function ThirdPartyIntegrationCard({ data }: ThirdPartyIntegrationCardProps): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <CardContainer p={2} borderRadius={8}>
      <Box display='flex' justifyContent='space-between' alignItems='start'>
        <Box>
          <Typography variant='h3'>{data.title}</Typography>
          <Box mt={1}>
            <CardDescription variant='body2'>{data.description}</CardDescription>
          </Box>
        </Box>
        <Box position='relative' right='-10px'>
          <ActionButton size='small'>
            <Icon name={IconName.threeDots} color={{ primary: theme.colors.grey[50] }} size='16px' />
          </ActionButton>
        </Box>
      </Box>
      <Box mt={3} display='flex' alignItems='center' justifyContent='space-between'>
        <IntegrationStatus status={data.status} />
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            // eslint-disable-next-line no-console
            console.log('Manage THAT!');
          }}
          size='small'
        >
          {t('admin.thirdPartyIntegrations.buttonLabels.manage')}
        </Button>
      </Box>
    </CardContainer>
  );
}
