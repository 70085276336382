import { ReactElement } from 'react';

import { SvgIconProps } from '../../Components/Icons';

export function DeleteIcon(props: SvgIconProps): ReactElement {
  return (
    <svg stroke='#757575' fill='#f6f6f6' width='16' height='16' xmlns='http://www.w3.org/2000/svg' {...props.svgProps}>
      <path d='M14 3l-1.208 11.473a1.334 1.334 0 01-1.325 1.194H4.533a1.333 1.333 0 01-1.326-1.194L2 3' />
      <path
        d='M14 3l-1.208 11.473a1.334 1.334 0 01-1.325 1.194H4.533a1.333 1.333 0 01-1.326-1.194L2 3M5 3V1c0-.368.299-.667.667-.667h4.666c.368 0 .667.299.667.667v2M.333 3h15.334M8 6v7M11 6l-.333 7M5 6l.333 7'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
