import {
  FieldPartIdentifier,
  PartIdentifier,
  PartIdentifierTag,
  QuestionPartIdentifier,
  SectionGroupKey,
  SectionPartIdentifier,
  SubsectionPartIdentifier,
} from '@breathelife/types';
import { FormattedTreeViewItemData } from '@breathelife/ui-components';

import { SelectedPartIdentifier } from '../types';

const SECTION_GROUP_PATH_LENGTH = 1;
const SECTION_PATH_LENGTH = 2;
const SUBSECTION_PATH_LENGTH = 3;
const QUESTION_PATH_LENGTH = 4;
const FIELD_PATH_LENGTH = 5;

const PATH_LENGTH_TO_TAG_NAME_MAP: Record<number, PartIdentifierTag> = {
  [SECTION_GROUP_PATH_LENGTH]: PartIdentifierTag.sectionGroup,
  [SECTION_PATH_LENGTH]: PartIdentifierTag.section,
  [SUBSECTION_PATH_LENGTH]: PartIdentifierTag.subsection,
  [QUESTION_PATH_LENGTH]: PartIdentifierTag.question,
  [FIELD_PATH_LENGTH]: PartIdentifierTag.field,
};

export function makePartIdentifierFromTreePath(path: FormattedTreeViewItemData['path']): SelectedPartIdentifier {
  const tagName = PATH_LENGTH_TO_TAG_NAME_MAP[path.length];
  const partIdentifier: Partial<PartIdentifier> = {
    sectionGroupPartName: path[0] as SectionGroupKey,
  };
  if (tagName == PartIdentifierTag.sectionGroup) {
    partIdentifier.tag = tagName;
  }
  (partIdentifier as SectionPartIdentifier).sectionPartName = path[1];
  if (tagName === PartIdentifierTag.section) {
    partIdentifier.tag = tagName;
    return partIdentifier as SectionPartIdentifier;
  }
  (partIdentifier as SubsectionPartIdentifier).subsectionPartName = path[2];
  if (tagName === PartIdentifierTag.subsection) {
    partIdentifier.tag = tagName;
    return partIdentifier as SubsectionPartIdentifier;
  }
  (partIdentifier as QuestionPartIdentifier).questionPartName = path[3];
  if (tagName === PartIdentifierTag.question) {
    partIdentifier.tag = tagName;
    return partIdentifier as QuestionPartIdentifier;
  }
  (partIdentifier as FieldPartIdentifier).fieldPartName = path[4];
  if (tagName === PartIdentifierTag.field) {
    partIdentifier.tag = tagName;
    return partIdentifier as FieldPartIdentifier;
  }
  return null;
}

export function getElementTypeFromTreePathLength(length: number): PartIdentifierTag {
  return PATH_LENGTH_TO_TAG_NAME_MAP[length];
}
