import { ReactElement } from 'react';

import { SvgIconProps } from '../../Components/Icons';

export function LogoutIcon(props: SvgIconProps): ReactElement {
  return (
    <svg stroke='#757575' fill='#f6f6f6' height='16' width='16' xmlns='http://www.w3.org/2000/svg' {...props.svgProps}>
      <path d='M10.5 11v3a.958.958 0 01-.91 1H1.41A.958.958 0 01.5 14V2a.957.957 0 01.909-1H9.59a.958.958 0 01.909 1v3' />
      <path
        d='M10.5 11v3a.958.958 0 01-.91 1H1.41A.958.958 0 01.5 14V2a.957.957 0 01.909-1H9.59a.958.958 0 01.909 1v3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M5 8.002h10.5z' />
      <g strokeLinecap='round' strokeLinejoin='round'>
        <path d='M5 8.002h10.5M13 10.502l2.5-2.5-2.5-2.5' />
      </g>
    </svg>
  );
}
