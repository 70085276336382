import styled from '../../../../Styles/themed-styled-components';

export const ComprehensivePricingCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: ${(props) => `1px solid ${props.theme.colors.grey[40]}`};
  border-radius: 8px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.07);
  overflow: hidden;
`;

export const ComprehensivePricingCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 24px;
  background-color: ${(props) => props.theme.colors.grey[20]};
  border-bottom: 1px solid ${(props) => props.theme.colors.grey[40]};
  min-height: 56px;
`;

export const ComprehensivePricingCardBody = styled.div`
  padding: 24px;
  max-height: 500px;
  overflow-y: auto;
`;

export const ComprehensivePricingContractProductsContainer = styled.div`
  margin-top: 8px;
  padding: 16px;
  background-color: ${(props) => props.theme.colors.grey[20]};
`;

export const ComprehensivePricingProductContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: start;
`;

export const ComprehensivePricingCardFooterContainer = styled.div`
  padding: 0 16px 0 24px;
`;

export const ComprehensivePricingCardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  border-top: 1px solid ${(props) => props.theme.colors.grey[40]};
`;

export const ComprehensivePricingSubtotalContainer = styled.div<{ isLast: boolean }>`
  border-bottom: ${(props) => (props.isLast ? 'none' : `1px solid ${props.theme.colors.grey[40]}`)};
  padding-bottom: ${(props) => (props.isLast ? 0 : `24px`)};
`;
