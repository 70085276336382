import { logger } from '@breathelife/monitoring-frontend';
import { useEffect, useState, Dispatch, SetStateAction } from 'react';

function getSessionStorageValue<T>(key: string, initialValue: T): T {
  const value = sessionStorage.getItem(key);

  if (value === null) {
    return initialValue;
  }

  try {
    return JSON.parse(value);
  } catch (err: any) {
    logger.warn({
      message: `Unable to read from session storage for key ${key}.`,
      error: err instanceof Error ? err : undefined,
    });
    return initialValue;
  }
}

/**
 * @param key Name of the key to reference the stored session value.
 * @param initialValue Default default for the stored session value.
 * @returns The state and state setter from the stored session value.
 * @IMPORANT NOTE: Please try to keep the different use cases small as the maximum storage value 5MB.
 */

export function useSessionStorage<T>(key: string, initialValue: T): [T, Dispatch<SetStateAction<T>>] {
  const [sessionStorageState, setSessionStorageState] = useState<T>(() => {
    return getSessionStorageValue<T>(key, initialValue);
  });

  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(sessionStorageState));
  }, [sessionStorageState, key]);

  return [sessionStorageState, setSessionStorageState];
}
