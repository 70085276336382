import { ReactElement } from 'react';

import { SvgIconProps } from '../../Components/Icons';

export function CopyIcon(props: SvgIconProps): ReactElement {
  return (
    <svg fill='#F6F6F6' stroke='#757575' height='16' width='16' xmlns='http://www.w3.org/2000/svg' {...props.svgProps}>
      <g strokeLinecap='round' strokeLinejoin='round'>
        <path d='M2.045 3.5h7.91c.3 0 .545.253.545.564v9.872a.555.555 0 01-.545.564h-7.91a.555.555 0 01-.545-.564V4.064c0-.311.244-.564.545-.564z' />
        <path d='M5.045.5h7.91c.3 0 .545.253.545.564v9.872a.555.555 0 01-.545.564h-7.91a.555.555 0 01-.545-.564V1.064C4.5.753 4.744.5 5.045.5z' />
      </g>
    </svg>
  );
}
