import { Box } from '@breathelife/mui';
import ReactHtmlParser from 'html-react-parser';
import { ReactElement, Fragment } from 'react';

import Typography from '../../../../Components/Typography';

type SubsectionViewProps = {
  title?: string;
  text?: string;
};

export function SubsectionHeader(props: SubsectionViewProps): ReactElement | null {
  const { title, text } = props;

  return (
    <Fragment>
      {title && (
        <Box mb={3}>
          {title ? (
            <Typography variant='h3' component='h2'>
              {ReactHtmlParser(title.toUpperCase())}
            </Typography>
          ) : null}
        </Box>
      )}
      {text && (
        <Box mb={3}>
          <Typography variant='body1'>{ReactHtmlParser(text)}</Typography>
        </Box>
      )}
    </Fragment>
  );
}
