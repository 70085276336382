import { UserGroup } from '@breathelife/types';
import { useTranslation } from 'react-i18next';
import { UseMutationOptions, UseMutationResult, useQueryClient, useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { notificationSlice } from '../../../ReduxStore/Notification/NotificationSlice';
import { QueryId } from '../../common/common.types';
import ApiService from '../../../Services/ApiService';

export function useUpdateUserGroupsForUserMutation(
  userId?: string,
  options?: UseMutationOptions<UserGroup[], unknown, string[]>,
): UseMutationResult<UserGroup[], unknown, string[]> {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation<UserGroup[], unknown, string[]>(
    async (data: string[]) => {
      if (!userId) {
        throw new Error();
      }
      const result = await ApiService.admin.updateUserGroupsForUser(userId, data);
      return result.data;
    },
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries([QueryId.userGroups, { userId }]);

        if (options?.onSuccess) {
          await options.onSuccess(data, variables, context);
        }
      },
      onError: async (error, variables, context) => {
        dispatch(
          notificationSlice.actions.setError({
            message: t('notifications.failedToUpdateUserGroupsForUser'),
          }),
        );

        if (options?.onError) {
          await options.onError(error, variables, context);
        }
      },
    },
  );
}
