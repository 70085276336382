import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'html-react-parser';

import { useCarrierContext, useLocale } from '../../../../Hooks';
import styled from '../../../../Styles/themed-styled-components';

const StyledImg = styled.img`
  margin-top: 10px;
`;

const StyledDiv = styled.div`
  margin-top: 10px;
`;

export function NotificationCentreHelp(): ReactElement {
  const { t } = useTranslation();
  const locale = useLocale();
  const {
    carrierInfo: { companyName },
    features,
  } = useCarrierContext();

  const cloudinaryImg = useMemo(() => {
    if (features.notificationCentre.enabled) {
      return features.notificationCentre.cloudinaryImages;
    }
    return;
  }, [features.notificationCentre]);

  return (
    <StyledDiv>
      {ReactHtmlParser(
        t(`notificationCentre.helpPopover.main`, {
          companyName,
        }),
      )}
      {ReactHtmlParser(t(`notificationCentre.helpPopover.informational.content`))}
      <p>
        {cloudinaryImg && (
          <StyledImg
            alt={t(`notificationCentre.helpPopover.informational.alt`)}
            src={cloudinaryImg['informational'][locale]}
            width='100%'
          />
        )}
      </p>
      {ReactHtmlParser(t(`notificationCentre.helpPopover.actionable.content`))}
      <p>
        {cloudinaryImg && (
          <StyledImg
            alt={t(`notificationCentre.helpPopover.actionable.alt`)}
            src={cloudinaryImg['actionable'][locale]}
            width='100%'
          />
        )}
      </p>
    </StyledDiv>
  );
}
