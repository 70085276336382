import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { NodeIdSelector } from '../../../../../../../Components/NodeIds/NodeIdSelector';
import { NodeDetail } from '../../../../../../../Helpers/questionnaireEditor/questionnaireNodeIds';
import { QuestionnaireEditorContext } from '../../../../ContextProvider/QuestionnaireEditorContextProvider';

type AnswerNodeIdInputProps = {
  nodeIds: NodeDetail[];
  selectedNodeId: string;
  handleChange: (newNodeId: string) => void;
};

export function AnswerNodeIdInput({
  nodeIds,
  selectedNodeId,
  handleChange,
}: AnswerNodeIdInputProps): React.ReactElement {
  const { t } = useTranslation();
  const { selectedLanguage } = useContext(QuestionnaireEditorContext);
  return (
    <NodeIdSelector
      label={t('admin.questionnaireManagement.input.answerStorageNodeId')}
      nodeIds={nodeIds}
      readOnly={false}
      selectedNodeId={selectedNodeId}
      onChange={(nodeIdUpdate) => {
        if (nodeIdUpdate !== null) {
          handleChange(nodeIdUpdate.value);
        }
      }}
      selectedLanguage={selectedLanguage}
      required
    />
  );
}
