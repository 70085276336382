import { Fragment, ReactElement, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, PublishIcon } from '@breathelife/mui';

import { useModalState } from '../../../Hooks';
import { Application } from '../../../Models/Application';
import { Lead } from '../../../Models/Lead';
import { DragAndDropUpload } from '../../../Pages/Admin/Questionnaire/QuestionnaireEditor/Components/DragAndDropUpload';
import { useCreateOrUpdateApplicationMutation } from '../../../ReactQuery/DebugToolbar/debugToolbar.mutations';
import { generateLeadDetailUrl } from '../../../Navigation/Urls';

type Props = {
  application?: Application;
  lead?: Lead;
};

export function ImportApplicationAnswersButton({ application, lead }: Props): ReactElement {
  const [isImportModalOpen, onOpenImportModal, onCloseImportModal] = useModalState();
  const navigate = useNavigate();

  const createOrUpdateApplicationMutation = useCreateOrUpdateApplicationMutation({
    onMutate: () => {
      onCloseImportModal();
    },
    onSuccess: (_data, { leadId }) => {
      navigate(generateLeadDetailUrl(leadId));
    },
  });

  const handleFileUpload = useCallback(
    (files: File[]) => {
      if (!files) return;
      const file = files[0];
      const reader = new FileReader();

      reader.onload = async () => {
        const importedFile = reader.result as string;

        if (!lead?.id || !importedFile) return;

        const answers = JSON.parse(importedFile);

        createOrUpdateApplicationMutation.mutate({
          leadId: lead.id,
          applicationId: application?.id,
          answers,
        });
      };

      reader.readAsText(file);
    },
    [application?.id, lead?.id, createOrUpdateApplicationMutation],
  );

  return (
    <Fragment>
      <Button
        disabled={!lead}
        variant='outlined'
        color='primary'
        onClick={onOpenImportModal}
        startIcon={<PublishIcon />}
      >
        Import answers
      </Button>
      <DragAndDropUpload
        title={'Import answers into the current lead or application (which will be automatically created if necessary)'}
        isOpen={isImportModalOpen}
        onClose={onCloseImportModal}
        handleFileUpload={handleFileUpload}
        accept={'application/json'}
      />
    </Fragment>
  );
}
