import { ChangeEvent, ReactElement, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  PdfDocumentType,
  IconName,
  InsuranceModule,
  Language,
  Localizable,
  SectionBlueprint,
  SectionPartIdentifier,
  QuestionnaireBlueprintCopyableOption,
} from '@breathelife/types';
import { AutocompleteOption, Box } from '@breathelife/ui-components';

import { QuestionnaireNodeIds } from '../../../../../Helpers/questionnaireEditor/questionnaireNodeIds';
import { DATA_LABEL_INHERIT_KEY } from '../../../../../Helpers/questionnaireEditor/selectOptions';
import { useCarrierContext } from '../../../../../Hooks';
import { useUpdateQuestionnaireElementBlueprint } from '../../../../../ReactQuery/Admin/Questionnaire/questionnaireVersion.mutations';
import { QuestionnaireVersionDataContext } from '../../ContextProvider/QuestionnaireVersionDataContextProvider';
import { AdvancedBlueprintOptions } from '../Components/AdvancedBlueprintOptions';
import { EditorDropdown } from '../Components/EditorDropdown';
import { TextInput } from '../Components/TextInput';
import { useAdvancedBlueprintOptions } from '../Hooks/useAdvancedBlueprintOptions';
import { useHighlightedTextInBlueprintElement } from '../Hooks/useHighlightedTextInBlueprintElement';
import { ConditionsEditor } from './ConditionsEditor';

type Props = {
  dataLabelOptions: AutocompleteOption[];
  isEditingEnabled: boolean;
  partIdentifier: SectionPartIdentifier;
  sectionBlueprint: SectionBlueprint;
  sectionGroupCollectionNodeId: string | undefined;
  selectedLanguage: Language;
  questionnaireNodeIds: QuestionnaireNodeIds;
};

function SectionBlueprintEditorComponent(props: Props): ReactElement {
  const {
    dataLabelOptions: dataLabelOptionsProps,
    isEditingEnabled,
    partIdentifier,
    sectionBlueprint,
    selectedLanguage,
    questionnaireNodeIds,
    sectionGroupCollectionNodeId,
  } = props;

  const { t } = useTranslation();

  const { questionnaireVersionId } = useContext(QuestionnaireVersionDataContext);
  const { showBlueprintIdInEditor } = useCarrierContext();

  const [isInitialized, setIsInitialized] = useState(false);
  const [referenceLabel, setReferenceLabel] = useState<string>('');
  const [sectionIconName, setSectionIconName] = useState<IconName | undefined>(sectionBlueprint?.iconName);
  const [sectionTitle, setSectionTitle] = useState<Partial<Localizable>>(sectionBlueprint?.title || {});
  const [pdfDocuments, setPdfDocuments] = useState<PdfDocumentType[]>(sectionBlueprint.pdfDocuments ?? []);
  const [insuranceModule, setInsuranceModule] = useState<InsuranceModule>(
    sectionBlueprint.modules?.includes(InsuranceModule.needsAnalysis)
      ? InsuranceModule.needsAnalysis
      : InsuranceModule.insuranceApplication,
  );

  const updateQuestionnaireElement = useUpdateQuestionnaireElementBlueprint(questionnaireVersionId);

  const {
    actions: { setPlatforms, setRenderOn, setDataLabel, setCopyable },
    selectors: { platforms, renderOn, dataLabel, copyable },
  } = useAdvancedBlueprintOptions<SectionPartIdentifier>({
    partIdentifier,
    blueprintUpdate: updateQuestionnaireElement,
  });

  const highlightedTexts = useHighlightedTextInBlueprintElement({ partIdentifier, blueprint: sectionBlueprint });

  const dataLabelOptions = useMemo(
    () =>
      dataLabelOptionsProps.reduce((accumulator, option) => {
        if (option.value === DATA_LABEL_INHERIT_KEY) {
          return [...accumulator, { ...option, label: t('admin.questionnaireManagement.input.noneSet') }];
        }
        return [...accumulator, option];
      }, [] as AutocompleteOption[]),
    [dataLabelOptionsProps, t],
  );

  useEffect(() => {
    if (isInitialized) return;

    const referenceLabel = sectionBlueprint.referenceLabel;
    if (referenceLabel) {
      setReferenceLabel(referenceLabel);
    }

    const initialTitle = sectionBlueprint.title;
    if (initialTitle) {
      setSectionTitle(initialTitle);
    }

    const initialDataLabel = sectionBlueprint.dataLabel;
    if (initialDataLabel) {
      setDataLabel(initialDataLabel, false);
    } else if (dataLabelOptions?.length) {
      setDataLabel(dataLabelOptions[0].value, false);
    }

    setPlatforms(sectionBlueprint.platforms ?? [], false);
    setRenderOn(sectionBlueprint.renderOn ?? [], false);
    setCopyable(sectionBlueprint.copyable ?? QuestionnaireBlueprintCopyableOption.none, false);

    setIsInitialized(true);
  }, [
    sectionBlueprint.title,
    sectionBlueprint.text,
    sectionBlueprint.dataLabel,
    sectionBlueprint.platforms,
    sectionBlueprint.renderOn,
    sectionBlueprint.copyable,
    sectionBlueprint.referenceLabel,
    dataLabelOptions,
    setPlatforms,
    setRenderOn,
    isInitialized,
    setDataLabel,
    setCopyable,
  ]);

  const onReferenceLabelBlur = useCallback(() => {
    void updateQuestionnaireElement({
      partIdentifier,
      update: {
        property: 'referenceLabel',
        value: referenceLabel.trim(),
        blueprintId: sectionBlueprint.id,
      },
    });
  }, [updateQuestionnaireElement, partIdentifier, referenceLabel, sectionBlueprint.id]);

  const onTitleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const title = event.target.value;
      setSectionTitle((previousValue) => ({ ...previousValue, [selectedLanguage]: title }));
    },
    [selectedLanguage],
  );

  const onTitleBlur = useCallback(() => {
    void updateQuestionnaireElement({
      partIdentifier,
      update: { property: 'title', value: sectionTitle },
    });
  }, [updateQuestionnaireElement, partIdentifier, sectionTitle]);

  const onPdfDocumentsChange = useCallback(
    (updatedPdfDocuments: PdfDocumentType[]): void => {
      setPdfDocuments(updatedPdfDocuments);
      void updateQuestionnaireElement({
        partIdentifier,
        update: { property: 'pdfDocuments', value: updatedPdfDocuments },
      });
    },
    [setPdfDocuments, partIdentifier, updateQuestionnaireElement],
  );

  const onInsuranceModuleChange = useCallback(
    (newActiveModule: InsuranceModule): void => {
      setInsuranceModule(newActiveModule);

      // The UI is a single selection, but, in the blueprint we still support the possibility that there could be multiple selections.
      const newActiveModules = [newActiveModule];
      void updateQuestionnaireElement({
        partIdentifier,
        update: { property: 'modules', value: newActiveModules },
      });
    },
    [setInsuranceModule, partIdentifier, updateQuestionnaireElement],
  );

  const disabled = sectionBlueprint.hidden || !isEditingEnabled;

  return (
    <Box>
      {showBlueprintIdInEditor && (
        <TextInput label={'Id'} value={sectionBlueprint.id} disabled highlighted={!!highlightedTexts?.blueprintId} />
      )}

      <TextInput
        label={t('admin.questionnaireManagement.input.referenceLabel')}
        value={referenceLabel}
        disabled={disabled}
        onChange={(event) => {
          setReferenceLabel(event.target.value);
        }}
        onBlur={onReferenceLabelBlur}
        highlighted={!!highlightedTexts?.referenceLabel}
      />

      <TextInput
        label={t('admin.questionnaireManagement.input.sectionTitle')}
        value={sectionTitle[selectedLanguage] ?? ''}
        disabled={disabled}
        onChange={onTitleChange}
        onBlur={onTitleBlur}
        highlighted={!!highlightedTexts.title}
      />
      <Box pt={1}>
        <EditorDropdown
          label={t('admin.questionnaireManagement.input.sectionIcon')}
          selectedOptionValue={sectionIconName}
          disabled={disabled}
          options={Object.keys(IconName).map((iconName) => {
            return { label: t(`admin.questionnaireManagement.questionnaireIconLabels.${iconName}`), value: iconName };
          })}
          disableClearable={false}
          onChange={(value) => {
            const newIconName = (value || undefined) as IconName | undefined;
            setSectionIconName(newIconName);
            void updateQuestionnaireElement({
              partIdentifier,
              update: { property: 'iconName', value: newIconName },
            });
          }}
        />
      </Box>
      <Box pt={1} pb={2} mr={-1}>
        <ConditionsEditor
          questionnaireNodeIds={questionnaireNodeIds}
          condition={sectionBlueprint?.visible}
          collectionContext={sectionGroupCollectionNodeId ? [sectionGroupCollectionNodeId] : []}
          isReadOnly={!isEditingEnabled || !!sectionBlueprint.hidden || disabled}
          label={t('admin.questionnaireManagement.rules.visibility.conditions')}
          editHeadingText={t('admin.questionnaireManagement.rules.visibility.edit')}
          selectedLanguage={selectedLanguage}
          saveCondition={(condition) => {
            void updateQuestionnaireElement({
              partIdentifier,
              update: { property: 'visible', value: condition },
            });
          }}
        />
      </Box>

      <AdvancedBlueprintOptions
        tag='section'
        insuranceModule={{ selected: insuranceModule, onChange: onInsuranceModuleChange }}
        pdfDocument={{ selected: pdfDocuments, onChange: onPdfDocumentsChange }}
        platform={{ selected: platforms, onChange: setPlatforms }}
        renderOn={{ selected: renderOn, onChange: setRenderOn }}
        dataLabel={{
          selected: dataLabel,
          options: dataLabelOptions,
          onChange: (value) => {
            if (value !== null) {
              setDataLabel(value);
            }
          },
        }}
        copyable={{
          selected: copyable,
          onChange: (value) => {
            if (value !== null) {
              setCopyable(value as QuestionnaireBlueprintCopyableOption);
            }
          },
        }}
        disabled={disabled}
      />
    </Box>
  );
}

export const SectionBlueprintEditor = memo(SectionBlueprintEditorComponent);
