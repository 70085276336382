import { ReactElement } from 'react';

import { Error as ErrorSvg } from '../../Assets/Error';
import { ErrorContainer } from './Styles';

export type Props = {
  message?: string;
  help?: string;
};

export function GenericError(props: Props): ReactElement {
  return (
    <ErrorContainer>
      <ErrorSvg role='img' />
      <p>{props.message ?? 'Something unexpected has happened.'}</p>
      <p>{props.help ?? 'Try reloading the page!'}</p>
    </ErrorContainer>
  );
}
