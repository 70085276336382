import { Box } from '@breathelife/mui';
import { ReactElement, Fragment, useCallback } from 'react';
import styled, { css } from '../../../Styles/themed-styled-components';

import { User } from '@breathelife/types';
import { Loader } from '@breathelife/ui-components';

import { Image } from '../../../Components/Images/Image';
import RadioGroup, { Radio } from '../../../Components/RadioGroup/RadioGroup';
import Typography from '../../../Components/Typography';
import { UserListModalItem } from '../../../Pages/Home/Modals/UserListModal/UserListModal';

type UserListViewProps = {
  onUserSelection: (selectedUserId: string) => void;
  selectedUserId: string;
  users: User[];
  isLoading: boolean;
  staticItems: UserListModalItem[];
};

type StyledRadioProps = {
  selected: boolean;
};

const StyledRadio = styled(Radio)<StyledRadioProps>`
  padding: 12px 24px;
  background-color: ${(props) => (props.selected ? props.theme.colors.primary[20] : props.theme.colors.grey[0])};

  &:hover {
    background-color: ${(props) => props.theme.colors.grey[20]};
  }
`;

type ListContainerProps = {
  borderBottom: boolean;
};

const ListContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => !['borderBottom'].includes(prop),
})<ListContainerProps>`
  overflow-y: auto;
  max-height: 500px;
  border-bottom: 0;

  ${(props) =>
    props.borderBottom &&
    css`
      border-bottom: 1px solid ${(props) => props.theme.colors.grey[40]};
    `}
`;

const RadioContainer = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.grey[40]};
`;

export function UserListView(props: UserListViewProps): ReactElement | null {
  const { staticItems, isLoading, onUserSelection, selectedUserId, users } = props;
  const hasUsers = users.length > 0;
  const onChangeHandler = useCallback(
    (id: string): void => {
      onUserSelection(id);
    },
    [onUserSelection],
  );

  return (
    <RadioGroup<string> name='leads-assign' onChange={onChangeHandler} value={selectedUserId}>
      <ListContainer borderBottom={hasUsers || isLoading}>
        {staticItems.map((staticItem) => (
          <RadioContainer key={staticItem.id}>
            <StyledRadio
              value={staticItem.id}
              label={<UserCard title={staticItem.title} subtitle={staticItem.subtitle} icon={staticItem.icon} />}
              selected={selectedUserId === staticItem.id}
            />
          </RadioContainer>
        ))}
        {isLoading && (
          <Box py={2}>
            <Loader size='50px' />
          </Box>
        )}
        <UsersList users={users} isLoading={isLoading} selectedUserId={selectedUserId} />
      </ListContainer>
    </RadioGroup>
  );
}

type UsersListProps = {
  isLoading: boolean;
  users: User[];
  selectedUserId: string;
};

function UsersList(props: UsersListProps): ReactElement | null {
  const { isLoading, users, selectedUserId } = props;
  const hasUsers = users.length > 0;

  if (!hasUsers || isLoading) return null;

  return (
    <Fragment>
      {users.map((user: User) => {
        const userPicture = user.picture;
        const hasFirstNameAndLastName = !!user.firstName && !!user.lastName;
        return (
          <StyledRadio
            key={user.auth0Id}
            value={user.auth0Id}
            label={
              <UserCard
                title={hasFirstNameAndLastName ? `${user.firstName} ${user.lastName}` : user.emailLogin}
                subtitle={hasFirstNameAndLastName ? user.emailLogin : ''}
                icon={userPicture ? <Image src={userPicture} width={30} height={30} /> : null}
              />
            }
            selected={selectedUserId === user.auth0Id}
          />
        );
      })}
    </Fragment>
  );
}

type UserCardProps = {
  title: string;
  subtitle?: string;
  icon?: ReactElement | null;
};

function UserCard(props: UserCardProps): ReactElement | null {
  const { icon, subtitle, title } = props;

  return (
    <Box display='flex' alignItems='center'>
      {icon && (
        <Box ml={2} lineHeight='0'>
          {icon}
        </Box>
      )}
      <Box ml={2}>
        <Box>
          <Typography variant='body3' grey={90}>
            {title}
          </Typography>
        </Box>
        {!!subtitle && (
          <Box>
            <Typography variant='body5' grey={70}>
              {subtitle}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}
