import { useMutation, useQueryClient, UseMutationResult, UseMutationOptions } from 'react-query';

import { FileTemplate } from '@breathelife/types';

import { QueryId } from '../../ReactQuery/common/common.types';
import { createFileTemplate, CreateFileTemplateData, patchFileTemplate } from '../../Services/FileTemplateService';

export function useCreateFileTemplateMutation(
  options?: UseMutationOptions<FileTemplate, unknown, CreateFileTemplateData>,
): UseMutationResult<FileTemplate, unknown, CreateFileTemplateData> {
  const queryClient = useQueryClient();
  return useMutation<FileTemplate, unknown, CreateFileTemplateData, unknown>(createFileTemplate, {
    ...options,
    // Update cache
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries(QueryId.fileTemplates);
      if (options?.onSuccess) {
        await options.onSuccess(data, variables, context);
      }
    },
  });
}

export type PatchFileTemplateParameters = {
  fileTemplateId: string;
  data: Partial<FileTemplate>;
};

export function usePatchFileTemplateMutation(
  options?: UseMutationOptions<FileTemplate, unknown, PatchFileTemplateParameters>,
): UseMutationResult<FileTemplate, unknown, PatchFileTemplateParameters> {
  const queryClient = useQueryClient();
  return useMutation<FileTemplate, unknown, PatchFileTemplateParameters, unknown>(
    ({ fileTemplateId, data }) => patchFileTemplate(fileTemplateId, data),
    {
      ...options,
      // Update cache
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries([QueryId.fileTemplate, data.id]);
        await queryClient.invalidateQueries(QueryId.fileTemplates);

        if (options?.onSuccess) {
          await options.onSuccess(data, variables, context);
        }
      },
    },
  );
}
