/* eslint-disable @typescript-eslint/no-unused-vars */
import { Fragment, ReactElement, useCallback, useMemo, useState } from 'react';

import { HelmetContainer } from '../../../Components/HelmetContainer';

import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { useGetAdHocSignatureRequestQuery } from '../../../ReactQuery/Admin/AdHocSignatureRequests/adHocSignatureRequests.queries';
import LoadingView from '../../../Components/LoadingView/LoadingView';

// @ts-ignore
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import urls, { UrlParamKeys } from '../../../Navigation/Urls';

import { Icon } from '../../../Components/Icons';
import { IconButton } from '../../../Components/Controls';
import { Button } from '../../../Components/Button/Button';
import { Grid } from '@breathelife/mui';
import Typography from '../../../Components/Typography';
import dayjs from 'dayjs';
import { Table } from '../../../Components/Table/Table';
import { CellProps } from 'react-table';
import {
  ApiESignAdhocRequestEvent,
  ApiESignAdhocSigner,
  ApiESignAdhocTransaction,
  ESignAdhocSignerStatus,
  ESignAdhocStatus,
} from '@breathelife/types';
import {
  useRestartAdHocSignatureRequestMutation,
  useStartAdHocSignatureRequestMutation,
} from '../../../ReactQuery/Admin/AdHocSignatureRequests/adHocSignatureRequests.mutations';
import { ModalLayout } from '../../../Layouts/Modal/ModalLayout';
import { Box, Input } from '@breathelife/ui-components';

type AdHocSignatureRequestDocumentTableColumn = {
  id?: string;
  Header: string;
  accessor: keyof ApiESignAdhocSigner;
  disableSortBy?: boolean;
  hidden?: boolean;
  Cell?: (props: CellProps<ApiESignAdhocSigner>) => ReactElement;
};

type Event = ApiESignAdhocRequestEvent & {
  id: string;
};

type AdHocSignatureRequestEventsTableColumn = {
  id?: string;
  Header: string;
  accessor: keyof Event;
  disableSortBy?: boolean;
  hidden?: boolean;
  Cell?: (props: CellProps<Event>) => ReactElement;
};

type TransactionWithId = Omit<ApiESignAdhocTransaction, 'createdAt'> & { id: string; createdAt: string };
type AdHocSignatureRequestTransactionsTableColumn = {
  id?: string;
  Header: string;
  accessor: keyof TransactionWithId;
  disableSortBy?: boolean;
  hidden?: boolean;
  Cell?: (props: CellProps<TransactionWithId>) => ReactElement;
};

export function AdHocSignatureRequestView(): ReactElement {
  const { adHocSignatureRequestId = '' } = useParams<{ [UrlParamKeys.adHocSignatureRequestId]: string }>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    data: adHocSignatureRequest,
    isLoading,
    isSuccess,
  } = useGetAdHocSignatureRequestQuery(adHocSignatureRequestId);

  const onBack = useCallback(() => {
    navigate(urls.adHocSignatureRequests.fullPath);
  }, [navigate]);

  const [restartModalState, setRestartModalState] = useState<{ opened: boolean; text: string }>({
    opened: false,
    text: '',
  });

  const [startModalState, setStartModalState] = useState<{ opened: boolean; text: string }>({
    opened: false,
    text: '',
  });

  const restartMutation = useRestartAdHocSignatureRequestMutation();
  const startMutation = useStartAdHocSignatureRequestMutation();

  const documentsColumns = useMemo<AdHocSignatureRequestDocumentTableColumn[]>(() => {
    return [
      {
        Header: t('admin.adHocSignatureRequestsManagement.documentsTableColumns.signerName'),
        accessor: 'name',
      },
      {
        Header: t('admin.adHocSignatureRequestsManagement.documentsTableColumns.signerRole'),
        accessor: 'roles',
      },
      {
        Header: t('admin.adHocSignatureRequestsManagement.documentsTableColumns.signerEmail'),
        accessor: 'email',
      },
      {
        Header: t('admin.adHocSignatureRequestsManagement.documentsTableColumns.status'),
        accessor: 'status',
        Cell: function ({ cell: { value } }: { cell: { value: ESignAdhocSignerStatus } }): ReactElement {
          return <Fragment>{t(`admin.adHocSignatureRequestsManagement.signatureStatuses.${value}`)}</Fragment>;
        },
      },
    ];
  }, [t]);

  const eventsColumns = useMemo<AdHocSignatureRequestEventsTableColumn[]>(() => {
    return [
      {
        Header: t('admin.adHocSignatureRequestsManagement.eventsTableColumns.data'),
        accessor: 'raw',
      },
      {
        Header: t('admin.adHocSignatureRequestsManagement.eventsTableColumns.createdBy'),
        accessor: 'createdBy',
      },
    ];
  }, [t]);

  const transactionsColumns = useMemo<AdHocSignatureRequestTransactionsTableColumn[]>(() => {
    return [
      {
        Header: t('admin.adHocSignatureRequestsManagement.transactionsTableColumns.providerName'),
        accessor: 'providerName',
      },
      {
        Header: t('admin.adHocSignatureRequestsManagement.transactionsTableColumns.transactionId'),
        accessor: 'transactionId',
      },
      {
        Header: t('admin.adHocSignatureRequestsManagement.transactionsTableColumns.createdAt'),
        accessor: 'createdAt',
        Cell: function ({ cell: { value } }: { cell: { value: Date } }): ReactElement {
          return <Fragment>{dayjs(value).format('D MMMM YYYY HH:mm A')}</Fragment>;
        },
      },
    ];
  }, [t]);

  if (isLoading) {
    return <LoadingView />;
  }

  return (
    <Fragment>
      <HelmetContainer text={t('pageTitles.adHocSignatureRequests')} />
      <ModalLayout
        maxWidth='sm'
        isOpen={restartModalState.opened}
        closeModal={() => setRestartModalState({ opened: false, text: '' })}
        title={'Restart the adhoc request'}
        submitButton={
          <Button
            disabled={restartModalState.text !== 'restart'}
            onClick={() => {
              if (restartModalState.text !== 'restart') {
                return;
              }
              setRestartModalState({ opened: false, text: '' });
              restartMutation.mutate({ adHocSignatureRequestId });
            }}
          >
            Confirm
          </Button>
        }
      >
        <Box mt={2} mb={2.5}>
          <Typography display='block' variant='body1' grey={90}>
            <Input
              title={'Type "restart" in this field, then click on "Confirm"'}
              value={restartModalState.text}
              onChange={(e) => setRestartModalState({ ...restartModalState, text: e.target.value })}
            />
          </Typography>
        </Box>
      </ModalLayout>

      <ModalLayout
        maxWidth='sm'
        isOpen={startModalState.opened}
        closeModal={() => setStartModalState({ opened: false, text: '' })}
        title={'Start the adhoc request'}
        submitButton={
          <Button
            disabled={startModalState.text !== 'start'}
            onClick={() => {
              if (startModalState.text !== 'start') {
                return;
              }
              setStartModalState({ opened: false, text: '' });
              startMutation.mutate({ adHocSignatureRequestId });
            }}
          >
            Confirm
          </Button>
        }
      >
        <Box mt={2} mb={2.5}>
          <Typography display='block' variant='body1' grey={90}>
            <Input
              title={'Type "start" in this field, then click on "Confirm"'}
              value={startModalState.text}
              onChange={(e) => setStartModalState({ ...startModalState, text: e.target.value })}
            />
          </Typography>
        </Box>
      </ModalLayout>

      {isSuccess && !adHocSignatureRequest && (
        <Navigate
          to={{
            pathname: urls.adHocSignatureRequests.fullPath,
          }}
          replace
        />
      )}
      {adHocSignatureRequest && (
        <Grid container spacing={6} padding={2}>
          {/** Header */}
          <Grid item container justifyContent='space-between'>
            <Grid item>
              <IconButton
                onClick={onBack}
                icon={<Icon name='leftArrow' />}
                title={t('admin.adHocSignatureRequestsManagement.return')}
              />
            </Grid>

            {adHocSignatureRequest.status !== ESignAdhocStatus.Completed &&
              adHocSignatureRequest.status !== ESignAdhocStatus.Cancelled &&
              adHocSignatureRequest.status !== ESignAdhocStatus.NotStarted && (
                <Grid item container xs='auto' spacing={1}>
                  <Grid item zeroMinWidth>
                    <Button
                      variant='contained'
                      onClick={() => setRestartModalState({ opened: true, text: '' })}
                      disabled={restartMutation.isLoading}
                    >
                      {t('admin.adHocSignatureRequestsManagement.buttons.restartSignatureRequest')}
                    </Button>
                  </Grid>
                </Grid>
              )}

            {adHocSignatureRequest.status === ESignAdhocStatus.NotStarted && (
              <Grid item container xs='auto' spacing={1}>
                <Grid item zeroMinWidth>
                  <Button
                    variant='contained'
                    onClick={() => setStartModalState({ opened: true, text: '' })}
                    disabled={startMutation.isLoading}
                  >
                    {t('admin.adHocSignatureRequestsManagement.buttons.startSignatureRequest')}
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>

          <Grid item container spacing={6} direction='column' mx={6} paddingBottom={6}>
            {/** Summary */}
            <Grid item container spacing={1} direction='column'>
              <Grid item>
                <Typography variant='h1' grey={100}>
                  {t('admin.adHocSignatureRequestsManagement.sectionTitles.requestDetails')}
                </Typography>
              </Grid>
              <Grid item container alignItems='center' spacing={1}>
                <Grid item>
                  <Typography variant='h3' grey={100}>
                    {t('admin.adHocSignatureRequestsManagement.sectionTitles.context')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant='body1' grey={100}>
                    {adHocSignatureRequest.context}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item container alignItems='center' spacing={1}>
                <Grid item>
                  <Typography variant='h3' grey={100}>
                    {t('admin.adHocSignatureRequestsManagement.sectionTitles.applicationId')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant='body1' grey={100}>
                    {adHocSignatureRequest.applicationId}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item container alignItems='center' spacing={1}>
                <Grid item>
                  <Typography variant='h3' grey={100}>
                    {t('admin.adHocSignatureRequestsManagement.sectionTitles.refNo')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant='body1' grey={100}>
                    {adHocSignatureRequest.refNo}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item container alignItems='center' spacing={1}>
                <Grid item>
                  <Typography variant='h3' grey={100}>
                    {t('admin.adHocSignatureRequestsManagement.sectionTitles.createdAt')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant='body1' grey={100}>
                    {dayjs(adHocSignatureRequest.createdAt).format('D MMMM YYYY HH:MM A')}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item container alignItems='center' spacing={1}>
                <Grid item>
                  <Typography variant='h3' grey={100}>
                    {t('admin.adHocSignatureRequestsManagement.sectionTitles.status')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant='body1' grey={100}>
                    {t(`admin.adHocSignatureRequestsManagement.requestStatuses.${adHocSignatureRequest.status}`)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            {/** Documents */}
            {!!adHocSignatureRequest.documentsToSign?.length && (
              <Grid item container spacing={3} direction='column'>
                <Grid item>
                  <Typography variant='h2' grey={100}>
                    {t('admin.adHocSignatureRequestsManagement.sectionTitles.documents')}
                  </Typography>
                </Grid>
                {adHocSignatureRequest.documentsToSign.map((document) => (
                  <Grid item container key={document.templateId} direction='column' spacing={2}>
                    <Grid item>
                      <Typography variant='h4'>{document.templateName}</Typography>
                    </Grid>
                    <Grid item>
                      <Table data={document.signers} columns={documentsColumns}></Table>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            )}

            {/** Transactions */}

            {!!adHocSignatureRequest.providerTransactions.length && (
              <Grid item container spacing={3} direction='column'>
                <Grid item>
                  <Typography variant='h2' grey={100}>
                    {t('admin.adHocSignatureRequestsManagement.sectionTitles.transactions')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Table
                    data={adHocSignatureRequest.providerTransactions
                      .sort((a, b) => (new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime() ? -1 : 1))
                      .map((t) => ({
                        ...t,
                        id: t.transactionId,
                        createdAt: t.createdAt,
                      }))}
                    columns={transactionsColumns}
                  ></Table>
                </Grid>
              </Grid>
            )}

            {/** Events */}
            {!!adHocSignatureRequest.events.length && (
              <Grid item container spacing={3} direction='column'>
                <Grid item>
                  <Typography variant='h2' grey={100}>
                    {t('admin.adHocSignatureRequestsManagement.sectionTitles.events')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Table
                    data={
                      adHocSignatureRequest.events
                        .sort(() => -1)
                        .map((e) => ({
                          id: uuid(),
                          raw: JSON.stringify(e.raw),
                          createdBy: e.createdBy,
                        })) as Event[]
                    }
                    columns={eventsColumns}
                  ></Table>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
}
