import { Fragment, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, PublishIcon } from '@breathelife/mui';

import { useModalState } from '../../../Hooks';
import { DragAndDropUpload } from '../../../Pages/Admin/Questionnaire/QuestionnaireEditor/Components/DragAndDropUpload';
import { useCreateLeadMutation } from '../../../ReactQuery/DebugToolbar/debugToolbar.mutations';
import { generateLeadsListUrl } from '../../../Navigation/Urls';

export function ImportLeadButton(): ReactElement {
  const [isImportModalOpen, onOpenImportModal, onCloseImportModal] = useModalState();
  const navigate = useNavigate();
  const createLeadMutation = useCreateLeadMutation({
    onMutate: () => {
      onCloseImportModal();
    },
    onSuccess: () => {
      navigate(generateLeadsListUrl());
    },
  });

  function handleFileUpload(files: File[]): void {
    if (!files) return;
    const file = files[0];
    const reader = new FileReader();

    reader.onload = async () => {
      const importedFile = reader.result as string;

      if (!importedFile) return;

      const lead = JSON.parse(importedFile);

      createLeadMutation.mutate(lead);
    };

    reader.readAsText(file);
  }

  return (
    <Fragment>
      <Button variant='outlined' color='primary' onClick={onOpenImportModal} startIcon={<PublishIcon />}>
        Import lead
      </Button>
      <DragAndDropUpload
        title={'Import a lead to be created'}
        isOpen={isImportModalOpen}
        onClose={onCloseImportModal}
        handleFileUpload={handleFileUpload}
        accept={'application/json'}
      />
    </Fragment>
  );
}
