import { ValidationError } from 'yup';

import { AcceptingDefaultValueFieldBlueprint, FieldBlueprint, FieldTypes, Language } from '@breathelife/types';
import { AutocompleteOption } from '@breathelife/ui-components';

import { AddressAutocompleteFieldSetters } from '../Creators/useDataByField/useAddressAutocompleteFieldData';
import { AgreeFieldSetters } from '../Creators/useDataByField/useAgreeFieldData';
import { ButtonFieldSetters } from '../Creators/useDataByField/useButtonFieldData';
import { CheckboxFieldSetters } from '../Creators/useDataByField/useCheckboxFieldData';
import { CurrencyCardFieldSetters } from '../Creators/useDataByField/useCurrencyCardFieldData';
import { DateFieldSetters } from '../Creators/useDataByField/useDateFieldData';
import { DropdownFieldSetters } from '../Creators/useDataByField/useDropdownFieldData';
import { InformationFieldSetters } from '../Creators/useDataByField/useInformationFieldData';
import { InputFieldSetters } from '../Creators/useDataByField/useInputFieldData';
import { MoneyFieldSetters } from '../Creators/useDataByField/useMoneyFieldData';
import { NumberFieldSetters } from '../Creators/useDataByField/useNumberFieldData';
import { PhoneFieldSetters } from '../Creators/useDataByField/usePhoneFieldData';
import { RadioFieldSetters } from '../Creators/useDataByField/useRadioFieldData';
import { SignatureFieldSetters } from '../Creators/useDataByField/useSignatureFieldData';
import { TextareaFieldSetters } from '../Creators/useDataByField/useTextareaFieldData';
import { CheckboxGroupFieldSetters } from '../Creators/useDataByField/useCheckboxGroupFieldData';

export interface LanguageDependentSetter {
  (language: Language, value: string): void;
}

export type FieldDataHookReturnValue<TData, TSetters> = {
  data: TData;
  setters: TSetters;
  defaultValueValidationError?: ValidationError;
};

export type FieldDataHookReturnValueWithValidationOptions<TData, TSetters> = FieldDataHookReturnValue<
  TData,
  TSetters
> & {
  validationOptions: AutocompleteOption[];
};

export type FieldDataValidateAs<TFieldType extends FieldBlueprint> = Pick<TFieldType, 'validateAs'>;
export type FieldSettersValidateAs<TData extends FieldDataValidateAs<FieldBlueprint>> = {
  setValidateAs: React.Dispatch<React.SetStateAction<TData['validateAs']>>;
};
export type FieldDataDefaultValue<TFieldType extends AcceptingDefaultValueFieldBlueprint> = Pick<
  TFieldType,
  'defaultValue'
>;
export type FieldSettersDefaultValue<TData extends FieldDataDefaultValue<AcceptingDefaultValueFieldBlueprint>> = {
  setDefaultValue: React.Dispatch<React.SetStateAction<TData['defaultValue']>>;
};

export type FieldDataFromBlueprint<TBlueprint extends FieldBlueprint, TUnion extends keyof TBlueprint> = Pick<
  TBlueprint,
  TUnion
>;

export type FieldDataFromBlueprintWithValidateAs<
  TBlueprint extends FieldBlueprint,
  TUnion extends keyof TBlueprint,
> = FieldDataValidateAs<TBlueprint> & FieldDataFromBlueprint<TBlueprint, TUnion>;

export function useCastBlueprintToFieldType<TBlueprintCast>(
  type: FieldTypes,
  initialData?: Partial<FieldBlueprint>,
): TBlueprintCast | undefined {
  return initialData?.fieldType === type ? (initialData as TBlueprintCast) : undefined;
}

export type FieldSetters =
  | InputFieldSetters
  | TextareaFieldSetters
  | PhoneFieldSetters
  | InformationFieldSetters
  | NumberFieldSetters
  | MoneyFieldSetters
  | DateFieldSetters
  | CheckboxFieldSetters
  | ButtonFieldSetters
  | CurrencyCardFieldSetters
  | DateFieldSetters
  | AgreeFieldSetters
  | DropdownFieldSetters
  | AddressAutocompleteFieldSetters
  | SignatureFieldSetters
  | RadioFieldSetters
  | CheckboxGroupFieldSetters;

export type FieldDataSetterProps<TBlueprint extends FieldBlueprint, TSetters extends FieldSetters> = {
  initialData?: Partial<FieldBlueprint>;
  dataLabelOptions: AutocompleteOption[];
  setData: (data: TBlueprint) => void;
  setSetters: (setters: TSetters) => void;
  setValidationOptions: (options: AutocompleteOption[]) => void;
  setIsValidBlueprint: (isValidBlueprint: boolean) => void;
  setDefaultValueValidationError: (error: ValidationError | undefined) => void;
};
