import {
  QuestionnaireElementBlueprint,
  isSectionBlueprint,
  isSubsectionBlueprint,
  isQuestionBlueprint,
} from '@breathelife/types';

export function removeReferenceLabelsFromElementBlueprint<T extends QuestionnaireElementBlueprint>(
  questionnaireElement: T,
): T {
  const newQuestionnaireElement = { ...questionnaireElement };
  delete newQuestionnaireElement.referenceLabel;
  if (isSectionBlueprint(newQuestionnaireElement)) {
    newQuestionnaireElement.subsections = newQuestionnaireElement.subsections.map((subsection) => {
      return removeReferenceLabelsFromElementBlueprint(subsection);
    });
  }
  if (isSubsectionBlueprint(newQuestionnaireElement)) {
    newQuestionnaireElement.questions = newQuestionnaireElement.questions.map((question) => {
      return removeReferenceLabelsFromElementBlueprint(question);
    });
  }
  if (isQuestionBlueprint(newQuestionnaireElement)) {
    newQuestionnaireElement.fields = newQuestionnaireElement.fields.map((field) => {
      return removeReferenceLabelsFromElementBlueprint(field);
    });
  }
  return newQuestionnaireElement;
}
