import styled from '../../../../../Styles/themed-styled-components';

import { Icon } from '../../../../../Components/Icons';

export const StyledList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const StyledListItem = styled.li`
  margin: 10px 0;
`;

export const StyledListItemButton = styled.button`
  padding: 0 0 0 20px;
  position: relative;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  text-align: left;

  :disabled {
    cursor: not-allowed;
  }
`;

export const StyledListItemButtonIcon = styled(Icon)`
  position: absolute;
  left: 0;
`;
