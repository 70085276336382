import { Fragment, ReactElement, useCallback } from 'react';

import { Box, IconButton } from '@breathelife/mui';

import { copyValueToClipboard } from '../../Helpers/clipboard';
import { TypographyVariant } from '../../Styles/Types';
import { Icon } from '../Icons';
import Typography from '../Typography';

type CommonProps = {
  label: string;
  value: string | ReactElement;
  variant?: TypographyVariant;
};

type ClipboardCopyProps =
  | {
      copyButtonAriaLabel?: never;
      copyEnabled?: false;
      onCopy?: never;
    }
  | { copyButtonAriaLabel: string; copyEnabled: true; onCopy?: (label: string) => void };

type DescriptionListItemProps = CommonProps & ClipboardCopyProps;

export function DescriptionListItem({
  copyButtonAriaLabel = '',
  copyEnabled = false,
  label,
  onCopy,
  variant = 'body5',
  value,
}: DescriptionListItemProps): ReactElement {
  const handleCopyValueToClipboard = useCallback(() => {
    if (!copyEnabled || typeof value !== 'string') return;

    copyValueToClipboard(value, () => onCopy?.(label));
  }, [copyEnabled, label, onCopy, value]);

  return (
    <Box display='flex' alignItems='center'>
      <Typography variant={variant}>{label}:</Typography>
      <Box display='flex' alignItems='center' ml={1}>
        {typeof value === 'string' ? (
          <Fragment>
            <Typography variant={variant}>{value}</Typography>
            {copyEnabled ? (
              <IconButton aria-label={copyButtonAriaLabel} onClick={handleCopyValueToClipboard} size='small'>
                <Icon ariaLabel={copyButtonAriaLabel} name='copy' />
              </IconButton>
            ) : null}
          </Fragment>
        ) : (
          value
        )}
      </Box>
    </Box>
  );
}
