import { ReactElement, SVGProps } from 'react';

export function EmptyApplicationList(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg fill='none' height='182' width='170' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M85 176.5c46.944 0 85-38.056 85-85s-38.056-85-85-85-85 38.056-85 85 38.056 85 85 85z' fill='#fcfcfc' />
      <path
        d='M88.37 141.993c18.368 0 33.258-1.317 33.258-2.942 0-1.624-14.89-2.941-33.258-2.941-18.369 0-33.259 1.317-33.259 2.941 0 1.625 14.89 2.942 33.259 2.942z'
        fill='#eee'
      />
      <path
        d='M68.817 51h36.57L120 66.163V121.1c0 .503-.193.987-.537 1.343a1.798 1.798 0 0 1-1.295.556h-49.35a1.801 1.801 0 0 1-1.281-.556 1.936 1.936 0 0 1-.537-1.328V52.884c.004-.499.197-.975.537-1.328a1.8 1.8 0 0 1 1.28-.556z'
        fill='#f6f6f6'
      />
      <g stroke='#757575' strokeLinecap='round' strokeLinejoin='round'>
        <path d='M65.884 48.311h39.123L120.64 64.01v56.875c0 .521-.207 1.021-.574 1.39a1.956 1.956 0 0 1-1.386.576H65.884a1.955 1.955 0 0 1-1.37-.576 1.969 1.969 0 0 1-.574-1.374v-70.64c.004-.516.21-1.01.574-1.374a1.957 1.957 0 0 1 1.37-.576z' />
        <path d='M120.64 64.009L104.991 48.31v13.731a1.958 1.958 0 0 0 1.944 1.966h13.705z' fill='#fff' />
        <path
          d='M69.788 129.74c12.428 0 22.502-10.105 22.502-22.571S82.216 84.598 69.788 84.598c-12.427 0-22.502 10.105-22.502 22.571s10.075 22.571 22.502 22.571z'
          fill='#b1d7ae'
        />
      </g>
      <path
        d='M69.788 124.833c9.726 0 17.61-7.909 17.61-17.664s-7.884-17.664-17.61-17.664c-9.725 0-17.61 7.909-17.61 17.664s7.885 17.664 17.61 17.664z'
        fill='#fff'
      />
      <path
        d='M69.788 94.412a17.562 17.562 0 0 1 11.544 4.338 17.665 17.665 0 0 1 5.871 10.872c.12-.785.184-1.578.195-2.372 0-4.685-1.856-9.178-5.158-12.49a17.582 17.582 0 0 0-12.452-5.174c-4.67 0-9.15 1.861-12.451 5.174a17.691 17.691 0 0 0-5.158 12.49c.006.821.071 1.641.194 2.454a17.68 17.68 0 0 1 5.852-10.919 17.578 17.578 0 0 1 11.563-4.373z'
        fill='#f0f0f0'
      />
      <path
        d='M69.788 124.833c9.726 0 17.61-7.909 17.61-17.664s-7.884-17.664-17.61-17.664c-9.725 0-17.61 7.909-17.61 17.664s7.885 17.664 17.61 17.664zm4.633-66.723h21.514m-21.514 6.874h21.514m-21.514 6.873h36.191m-36.19 6.858h36.19M90.071 85.59h20.541m-14.677 6.873h14.677M98.867 99.32h11.745m-10.757 6.874h10.757m-11.745 6.857h11.745'
        stroke='#757575'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M66.58 116.009a2.587 2.587 0 0 1-1.83-.764l-5.216-5.232a2.63 2.63 0 0 1-.911-1.887 2.626 2.626 0 0 1 .762-1.952 2.615 2.615 0 0 1 1.945-.764 2.606 2.606 0 0 1 1.881.914l3.37 3.38 8.586-8.613a2.607 2.607 0 0 1 4.301 1.887 2.62 2.62 0 0 1-.624 1.802l-10.417 10.465a2.622 2.622 0 0 1-1.846.764z'
        fill='#b1d7ae'
        stroke='#757575'
        strokeMiterlimit='10'
      />
    </svg>
  );
}
