import { ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, IconButton } from '@breathelife/mui';
import { ImageTypes } from '@breathelife/ui-components';

import { Icon } from '../../../Components/Icons';
import Typography from '../../../Components/Typography';
import styled from '../../../Styles/themed-styled-components';

type Direction = 'previous' | 'next';

type Props = {
  onClick: () => void;
  children?: ReactNode;
  className?: string;
  direction: Direction;
};

const StyledIconButton = styled(IconButton)<{ direction: Direction }>`
  &&& {
    background-color: ${(props) => props.theme.colors.primary.default};
    margin-right: ${(props) => props.direction === 'previous' && '12px'};
    margin-left: ${(props) => props.direction === 'next' && '12px'};
    padding: 22px;

    svg {
      stroke: ${(props) => props.theme.colors.grey[0]};
      fill: ${(props) => props.theme.colors.primary.default};
      transition: fill 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }

    &:hover,
    &:focus {
      background-color: ${(props) => props.theme.colors.primary[70]};
      outline: ${(props) => `1px solid ${props.theme.colors.selection.border.focus}}`};

      svg {
        fill: ${(props) => props.theme.colors.primary[70]};
      }
    }

    &:active {
      background-color: ${(props) => props.theme.colors.primary[80]};

      svg {
        fill: ${(props) => props.theme.colors.primary[80]};
      }
    }
  }
`;

export function NavigationButton(props: Props): ReactElement {
  const { direction, onClick, children, className } = props;
  const { t } = useTranslation();

  const previousSectionTranslation = t('assistedApplication.previousSection');
  const nextSectionTranslation = t('assistedApplication.nextSection');

  return (
    <Box display='flex' alignItems='center' className={className}>
      {direction === 'previous' && (
        <StyledIconButton onClick={onClick} direction={direction} aria-label={previousSectionTranslation}>
          <Icon name={'leftArrow'} ariaLabel={previousSectionTranslation} imageType={ImageTypes.meaningful} />
        </StyledIconButton>
      )}

      <Typography variant='body2'>{children}</Typography>

      {direction === 'next' && (
        <StyledIconButton onClick={onClick} direction={direction} aria-label={nextSectionTranslation}>
          <Icon name={'rightArrow'} ariaLabel={nextSectionTranslation} imageType={ImageTypes.meaningful} />
        </StyledIconButton>
      )}
    </Box>
  );
}
