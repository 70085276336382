import styled, { css } from '../../../Styles/themed-styled-components';

import { GhostButton } from '../../../Components/Button/GhostButton';

export const UploadButton = styled(GhostButton).withConfig({
  shouldForwardProp: (prop) => !['hideUploadButton'].includes(prop),
})<{ hideUploadButton: boolean }>`
  && {
    margin-top: 15px;
    white-space: nowrap;
    ${(props) =>
      props.hideUploadButton &&
      css`
        display: none;
      `}
  }
`;
