import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { EditorDropdown } from '../../Components/EditorDropdown';

type Props = {
  value?: boolean;
  onChange: (val?: boolean) => void;
  disabled: boolean;
};

enum DropdownOptions {
  checked = 'checked',
  unchecked = 'unchecked',
}

const DROPDOWN_VALUE_TO_FIELD_VALUE_MAP = {
  [DropdownOptions.checked]: true,
  [DropdownOptions.unchecked]: false,
};

export function BooleanFieldDefaultValueEditor(props: Props): ReactElement | null {
  const { value, onChange, disabled } = props;

  const { t } = useTranslation();

  return (
    <EditorDropdown
      label={t('admin.questionnaireManagement.input.defaultValue')}
      selectedOptionValue={value === true ? DropdownOptions.checked : DropdownOptions.unchecked}
      onChange={(value?: string | null) => {
        onChange(value ? DROPDOWN_VALUE_TO_FIELD_VALUE_MAP[value as DropdownOptions] : undefined);
      }}
      disabled={disabled}
      options={[
        { label: t('admin.questionnaireManagement.input.checked'), value: DropdownOptions.checked },
        {
          label: t('admin.questionnaireManagement.input.unchecked'),
          value: DropdownOptions.unchecked,
        },
      ]}
    />
  );
}
