import dayjs from 'dayjs';
import { ReactElement, Fragment, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Box } from '@breathelife/mui';
import { OutcomeCode, Permission } from '@breathelife/types';
import { downloadZipFile } from '@breathelife/file-helpers';

import { GenericSuccessStatusBadge } from '../../../../Components/Badge/GenericSuccessStatusBadge';
import { OutcomeBadge } from '../../../../Components/Badge/OutcomeBadge';
import { StatusBadge } from '../../../../Components/Badge/StatusBadge';
import { useGetSupportPageApplicationQuery } from '../../../../ReactQuery/ApplicationSupport/Application/application.queries';
import { ActionsWidget } from './ActionsWidget/ActionsWidget';
import { ContainerWithCopyButton } from '../../../../Components/ContainerWithCopyButton/ContainerWithCopyButton';
import { OverviewGridContainer, ActionsWidgetContainer, EmptyGridElement } from './Styles';
import { useDispatch, useModalState } from '../../../../Hooks';
import { useResetApplicationSubmissionStatusMutation } from '../../../../ReactQuery/ApplicationSupport/Application/application.mutations';
import { queryClient } from '../../../../ReactQuery';
import { QueryId } from '../../../../ReactQuery/common/common.types';
import { ConfirmResetApplicationStatusModal } from './ConfirmResetApplicationStatusModal/ConfirmResetApplicationStatusModal';
import { RestrictedToUserPermissions } from '../../../../Components/Restricted/RestrictedToUserPermissions';
import { notificationSlice } from '../../../../ReduxStore/Notification/NotificationSlice';
import ApiService from '../../../../Services/ApiService';
import { ApplicationModeBadge } from '../../../../Components/Badge/ApplicationModeBadge';

// TODO HOT-1902: Centralize date formats in lead platform
const DATE_FORMAT = 'MMMM Do, YYYY';

export function ApplicationOverviewContainer(): ReactElement | null {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { applicationId = '' } = useParams<{ applicationId: string }>();
  const { data: application } = useGetSupportPageApplicationQuery(applicationId);
  const [
    isResetSubmissonStatusConfirmationModalOpen,
    onOpenResetSubmissonStatusConfirmationModal,
    onCloseResetSubmissonStatusConfirmationModal,
  ] = useModalState();
  const [isDownloadingFiles, setIsDownloadingFiles] = useState(false);

  const resetApplicationSubmissionStatusMutation = useResetApplicationSubmissionStatusMutation({
    onSuccess: useCallback(() => {
      void queryClient.invalidateQueries(QueryId.applicationSupportApplications);
    }, []),
  });

  const onDownloadSubmissionFiles = useCallback(async () => {
    if (!applicationId) return;

    setIsDownloadingFiles(true);

    let buffer: Buffer | undefined = undefined;

    try {
      buffer = await ApiService.getApplicationSupportApplicationSubmittedFiles(applicationId);
    } catch (error: any) {
      dispatch(
        notificationSlice.actions.setError({
          message: t('notifications.getSupportApplicationSubmittedFilesFailure', { applicationId }),
        }),
      );
    }

    if (buffer) {
      downloadZipFile(buffer, `${applicationId}_submission_files.zip`);
    }

    setIsDownloadingFiles(false);
  }, [applicationId]);

  const onResetApplicationStatus = useCallback(
    (applicationId?: string) => {
      if (!applicationId) return;

      resetApplicationSubmissionStatusMutation.mutate(applicationId, {
        onSuccess: () => {
          onCloseResetSubmissonStatusConfirmationModal();
        },
      });
    },
    [resetApplicationSubmissionStatusMutation, onCloseResetSubmissonStatusConfirmationModal],
  );

  const notifyDataCopied = (data: string): void => {
    dispatch(
      notificationSlice.actions.setSuccess({
        message: t('notifications.dataCopied', { data }),
        autoHideDuration: 3000,
      }),
    );
  };

  if (!application) {
    return null;
  }

  const {
    refNo: policyNumber,
    createdAt: createdDate,
    updatedAt: lastModifiedDate,
    insuredCount,
    leadStatus: applicationStatus,
    carrierSubmissionStatus,
    outcomes,
    assignedToEmail,
    lineOfBusiness,
    mode,
  } = application;

  return (
    <Box display='flex'>
      <Box padding='2rem'>
        <ContainerWithCopyButton
          inputLabel={t('applicationSupport.applicationOverview.applicationUUID')}
          inputValue={applicationId}
          onClickCallback={notifyDataCopied}
          options={{
            buttonLabel: t('cta.copy'),
          }}
          marginBottom='2rem'
          width='28rem'
        />
        <ContainerWithCopyButton
          inputLabel={t('applicationSupport.applicationOverview.policyNumber')}
          inputValue={policyNumber}
          onClickCallback={notifyDataCopied}
          options={{
            buttonLabel: t('cta.copy'),
          }}
          marginBottom='3rem'
          width='28rem'
        />
        <OverviewGridContainer>
          <div>{t('applicationSupport.applicationOverview.currentAssignment')}</div>
          <div>{assignedToEmail}</div>

          <div>{t('applicationSupport.applicationOverview.createdDate')}</div>
          <div>{dayjs(createdDate).format(DATE_FORMAT)}</div>

          <div>{t('applicationSupport.applicationOverview.lastModifiedDate')}</div>
          <div>{dayjs(lastModifiedDate).format(DATE_FORMAT)}</div>

          {
            <Fragment>
              <div>{t('applicationSupport.applicationOverview.mode')}</div>
              <ApplicationModeBadge mode={mode} icon={true}></ApplicationModeBadge>
            </Fragment>
          }

          {
            <Fragment>
              <div>{t('applicationSupport.applicationOverview.lineOfBusiness')}</div>
              <div>{lineOfBusiness}</div>
            </Fragment>
          }

          {applicationStatus && (
            <Fragment>
              <div>{t('applicationSupport.applicationOverview.applicationStatus')}</div>
              <StatusBadge status={applicationStatus} />
            </Fragment>
          )}

          <div>{t('applicationSupport.applicationOverview.submissionStatus')}</div>
          {carrierSubmissionStatus ? (
            <GenericSuccessStatusBadge status={carrierSubmissionStatus} />
          ) : (
            <EmptyGridElement />
          )}

          <div>{t('applicationSupport.applicationOverview.totalNumberOfInsured')}</div>
          <div>{insuredCount}</div>

          {!!Object.keys(outcomes).length &&
            Object.entries(outcomes).map(([outcomeId, value], index) => (
              <Fragment key={index}>
                <div>{t('applicationSupport.applicationOverview.outcomeForInsured', { index: outcomeId })}</div>
                <OutcomeBadge outcome={value.outcome as OutcomeCode} />
              </Fragment>
            ))}
        </OverviewGridContainer>
      </Box>

      {/* Hiding the whole actions widget to avoid displaying an empty widget when there is no reset permission   */}
      {/* This approach will be revisited once we add more actions */}

      <RestrictedToUserPermissions requiredPermissions={[Permission.ApplicationSupportApplicationResetSubmittedStatus]}>
        <ActionsWidgetContainer>
          <ActionsWidget
            applicationId={application.id}
            refNo={application.refNo}
            applicationStatus={applicationStatus}
            onOpenResetSubmissonStatusConfirmationModal={onOpenResetSubmissonStatusConfirmationModal}
            onDownloadSubmissionFiles={onDownloadSubmissionFiles}
            isDownloadingFiles={isDownloadingFiles}
          />
        </ActionsWidgetContainer>
      </RestrictedToUserPermissions>

      <ConfirmResetApplicationStatusModal
        application={application}
        isResettingApplicationStatus={resetApplicationSubmissionStatusMutation.isLoading}
        isOpen={isResetSubmissonStatusConfirmationModalOpen}
        closeModal={onCloseResetSubmissonStatusConfirmationModal}
        onResetApplicationStatus={onResetApplicationStatus}
      />
    </Box>
  );
}
