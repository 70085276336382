import { ReactElement } from 'react';

import { SvgIconProps } from '../../Components/Icons/Icon';

export function ArchiveIcon(props: SvgIconProps): ReactElement {
  return (
    <svg stroke='#757575' fill='#f6f6f6' height='14' width='16' xmlns='http://www.w3.org/2000/svg' {...props.svgProps}>
      <path d='M14.5 4v7.5a2 2 0 01-2 2h-9a2 2 0 01-2-2V4' />
      <g strokeLinecap='round' strokeLinejoin='round'>
        <path d='M14.5 4v7.5a2 2 0 01-2 2h-9a2 2 0 01-2-2V4M14.5.5h-13a1 1 0 00-1 1v2h15v-2a1 1 0 00-1-1z' />
        <path d='M10 6.5a1 1 0 01-1 1H7a1 1 0 010-2h2a1 1 0 011 1z' fill='#fff' />
      </g>
    </svg>
  );
}
