import { useEffect } from 'react';

type useIdleTimeProps = {
  timeout: number;
  onIdle: () => void;
  onActive: () => void;
};

export function useIdleTimer(props: useIdleTimeProps): void {
  const { timeout, onIdle, onActive } = props;

  let idleTimer: NodeJS.Timeout;

  const resetTimeout = (): void => {
    clearTimeout(idleTimer);
    onActive();
  };

  useEffect(() => {
    idleTimer = setTimeout(onIdle, timeout);

    window.addEventListener('click', resetTimeout);
    window.addEventListener('mousemove', resetTimeout);
    window.addEventListener('keydown', resetTimeout);
    window.addEventListener('scroll', resetTimeout);
  }, [timeout, onIdle]);
}
