import { ReactNode } from 'react';

import { ButtonProps } from '@breathelife/mui';

import { StyledButton } from './Styles';

type Props = ButtonProps & { color: 'primary' | 'secondary'; startIcon?: ReactNode };

export function ActionButton(props: Props): JSX.Element {
  return (
    <StyledButton $color={props.color} {...props}>
      {props.children}
    </StyledButton>
  );
}
