import i18next from 'i18next';
import * as yup from 'yup';

import { isValidPhoneNumberAreaCode, phoneAreaCodeList, phoneNumberRegex } from '@breathelife/questionnaire-engine';
import { PhoneType } from '@breathelife/types';

const PHONE_TYPE_KEY_MAP: { [field in PhoneType]: string } = {
  [PhoneType.home]: 'phoneNumber',
  [PhoneType.mobile]: 'cellNumber',
};

export const getPhoneNumberSchema = (type: PhoneType = PhoneType.home): yup.StringSchema =>
  yup
    .string()
    .matches(phoneNumberRegex, {
      message: i18next.t(`validation.error.${PHONE_TYPE_KEY_MAP[type]}`),
      excludeEmptyString: true,
    })
    .test({
      test: (value) => isValidPhoneNumberAreaCode(value, phoneAreaCodeList),
      message: i18next.t('validation.error.phoneNumberAreaCode'),
    });
