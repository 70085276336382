import { ReactElement } from 'react';

import { SvgIconProps } from '../../Components/Icons';

export function WarningIcon(props: SvgIconProps): ReactElement {
  return (
    <svg stroke='#757575' fill='#f6f6f6' height='24' width='24' xmlns='http://www.w3.org/2000/svg' {...props.svgProps}>
      <g strokeWidth='1.5'>
        <path d='M.75 12a11.25 11.25 0 1022.5 0 11.25 11.25 0 00-22.5 0z' strokeMiterlimit='10' />
        <g strokeLinecap='round'>
          <path d='M12 16.5a.375.375 0 100 .75.375.375 0 000-.75z' strokeLinejoin='round' />
          <path d='M12 13.5V5.25' strokeMiterlimit='10' />
        </g>
      </g>
    </svg>
  );
}
