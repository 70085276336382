import { Fragment, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Grid } from '@breathelife/mui';
import { Language } from '@breathelife/types';

import RadioGroup, { Radio } from '../../../../Components/RadioGroup/RadioGroup';
import Typography from '../../../../Components/Typography';

type Props = {
  enabledLanguages: Language[];
  selectedLanguage: Language;
  onLanguageChange: (selectedLanguage: Language) => void;
};

export function PlatformLanguagePreference(props: Props): ReactElement | null {
  const { t } = useTranslation();
  const { enabledLanguages, selectedLanguage, onLanguageChange } = props;

  const languageRadioChoices = enabledLanguages.map((language) => ({
    value: language,
    label: t(`language.${language}`),
  }));

  return (
    <Fragment>
      <Box mb={1}>
        <Typography variant='h3' component='h3' grey={90}>
          {t('modals.editUser.platformLanguage')}
        </Typography>
      </Box>
      <RadioGroup<Language>
        label={t('modals.editUser.selectAPlatformLanguage')}
        name='userEdit-sharing-preferences'
        onChange={onLanguageChange}
        value={selectedLanguage}
      >
        <Grid container spacing={1}>
          {languageRadioChoices.map((languageOption) => {
            return (
              <Grid item xs={12} sm={6} key={`${languageOption.label}_key`}>
                <Radio<Language> value={languageOption.value} label={languageOption.label} />
              </Grid>
            );
          })}
        </Grid>
      </RadioGroup>
    </Fragment>
  );
}
