import { Box } from '@breathelife/mui';
import styled from '../../../../Styles/themed-styled-components';

import { ColorRanges, ESignCeremonyStatus, FullColorGradient, PartialColorGradient } from '@breathelife/types';

import { DesignSystemTheme, LeadPlatformCarrierTheme } from '../../../../Styles/Types';

function getESignatureStatusColor(
  theme: LeadPlatformCarrierTheme & {
    colors: ColorRanges;
  } & DesignSystemTheme,
  status: ESignCeremonyStatus,
): PartialColorGradient | FullColorGradient {
  switch (status) {
    case ESignCeremonyStatus.COMPLETED:
      return theme.colors.green;
    case ESignCeremonyStatus.IN_PROGRESS:
    case ESignCeremonyStatus.SENT:
      return theme.colors.orange;
    case ESignCeremonyStatus.EMAIL_BOUNCED:
    case ESignCeremonyStatus.OPT_OUT:
    case ESignCeremonyStatus.DECLINED:
    case ESignCeremonyStatus.SIGNER_LOCKED_OUT:
    case ESignCeremonyStatus.CANCELLED:
    case ESignCeremonyStatus.SEND_PACKAGE_ERROR:
      return theme.colors.red;
    case ESignCeremonyStatus.EXPIRED:
      return theme.colors.purple;
    default:
      return theme.colors.grey;
  }
}

export const StyledESignatureStatus = styled(Box)<{ $status: ESignCeremonyStatus }>`
  display: flex;
  align-items: center;
  padding: 1.5px 4px 1.5px 4px;
  height: 16px;
  background: ${({ theme, $status }) => getESignatureStatusColor(theme, $status)[20]};
  color: ${({ theme, $status }) => getESignatureStatusColor(theme, $status)[60]};
  border: ${({ theme }) => `1px solid ${theme.colors.grey[0]}`};
  border-radius: 3px;
  font-size: ${({ theme }) => theme.text.small2.size};
  line-height: 0px;
  white-space: nowrap;
`;
