import { createContext } from 'react';

import { QuestionnaireTreeViewDragContextValue } from './QuestionnaireTreeViewDragContextProvider';

export const QuestionnaireTreeViewDragContext = createContext<QuestionnaireTreeViewDragContextValue>({
  isUserDragging: false,
  handleDragStart: () => {},
  handleDragEnd: () => {},
  handleDragUpdate: () => {},
});
