import { Box } from '@breathelife/mui';
import styled from '../../../../Styles/themed-styled-components';

import { Button } from '../../../../Components/Button/Button';

export const DateTextContainer = styled.p`
  line-height: 1.2em;
  letter-spacing: -0.42px;
  margin-bottom: 0;
`;

export const ContentHeading = styled(Box)`
  width: 100%;
  background-color: ${(props) => props.theme.colors.grey[10]};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: fit-content;
`;

export const CtaButton = styled(Button)`
  &&&& {
    padding: 6px 12px;
  }
`;
