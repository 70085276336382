import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../Styles/themed-styled-components';

import { JetDecisionOutcomes, RuleReasonValue } from '@breathelife/types';
import { TreeView, TreeViewItemRenderChildProps } from '@breathelife/ui-components';
import { ExpandMoreIcon, Box } from '@breathelife/mui';

import Typography from '../../../../Components/Typography';
import { StyledAccordion, StyledAccordionSummary, StyledAccordionDetails } from './Styles';

type OutcomeData = {
  key: string;
  value?: RuleReasonValue;
};

type OutcomeItem = {
  key: string;
  outcomes?: OutcomeItem[];
};

type BulletColors = {
  [key in RuleReasonValue | 'default']: string;
};

const getOutcomesData = (item: OutcomeItem) => item.outcomes || [];

const getFormatedTreeData = (outcome: { [key: string]: string }): OutcomeItem[] => {
  return [{ outcomes: Object.keys(outcome).map((key) => ({ key })) }] as OutcomeItem[];
};

const renderOutcomeViewItem = (item: TreeViewItemRenderChildProps) => {
  const metadata = item.data.metadata as OutcomeData;

  const { t } = useTranslation();
  const ruleReasonValueText = useMemo(
    () => t(`assistedApplication.jetDecisionWidget.ruleReason.${metadata.value}`).toUpperCase(),
    [t, metadata],
  );

  return (
    <Box pl={1}>
      {metadata.value && (
        <Typography variant='body3' noWrap display='block'>
          {metadata.key} : {ruleReasonValueText}
        </Typography>
      )}
    </Box>
  );
};

export function JetDecisionOutcomeAccordion(props: JetDecisionOutcomes): React.ReactElement {
  const { participantId, rulesOutcomeList: outcomeRules, participant } = props;

  const theme = useTheme();
  const bulletColors = useMemo<BulletColors>(
    () => ({
      [RuleReasonValue.fail]: theme.colors.red[60],
      [RuleReasonValue.pass]: theme.colors.green[50],
      default: theme.colors.grey[50],
    }),
    [theme],
  );

  const getOutcomeDataItem = (item: OutcomeItem) => {
    const { key, outcomes } = item;
    const value = outcomeRules[key];

    return {
      identifier: key as string,
      label: key as string,
      iconWidth: 20 as number,
      isLast: true,
      bulletIconColor: bulletColors[value] || bulletColors.default,
      forceHide: outcomes && outcomes.length > 0,
      forceExpand: true,
      metadata: {
        key,
        value,
      } as OutcomeData,
    };
  };

  return (
    <StyledAccordion>
      <StyledAccordionSummary expandIcon={<ExpandMoreIcon htmlColor={theme.colors.grey[90]} />}>
        <Typography color={theme.colors.grey[100]} variant='body4'>
          {participant.firstName} {participant.lastName}
        </Typography>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <TreeView
          data={getFormatedTreeData(outcomeRules)}
          treeName={`tree-${participantId}`}
          canDropInsideOtherElement
          itemStyle={{ height: 30 }}
          getDataItemFromUnknownItem={getOutcomeDataItem}
          getUnknownItemChildren={getOutcomesData}
          getNewParentPathFromPreviousTreeItemPath={() => []}
          renderChild={renderOutcomeViewItem}
        />
      </StyledAccordionDetails>
    </StyledAccordion>
  );
}
