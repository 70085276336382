import { ReactElement } from 'react';

import { DrawerProps } from '@breathelife/mui';
import { ErrorBoundary } from '@breathelife/ui-components';

import { GenericError } from '../../Components/Error/GenericError';
import { Content, Drawer as StyledDrawer } from './Styles';

type Props = DrawerProps & { width?: string };

export function Drawer(props: Props): ReactElement {
  return (
    <StyledDrawer
      open={props.open}
      onClose={props.onClose}
      anchor='right'
      classes={{ paperAnchorRight: 'paper' }}
      {...props}
    >
      <ErrorBoundary
        renderErrorComponent={() => (
          <div>
            <GenericError />
          </div>
        )}
      >
        <Content role='dialog'>{props.children}</Content>
      </ErrorBoundary>
    </StyledDrawer>
  );
}
