import { SVGProps, ReactElement } from 'react';

export function LogoBl(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg fill='#476cc6' viewBox='0 0 270 50' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='m13.0948 17.0604h14.5702c6.3643 0 11.5242 5.0785 11.5242 11.3424v10.2555c0 6.2639-5.1599 11.3417-11.5242 11.3417h-16.1415c-6.3643 0-11.52348313-5.0778-11.52348313-11.3417v-9.6805c0-3.6042 1.64117313-6.8334 4.21864313-9.0202-4.21940704-4.1528-4.21907343-7.0647-4.21865159-10.74669.00000423-.03689.00000846-.07386.00000846-.11091v-8.08611c0-.559723.46074213-1.01389 1.03014313-1.01389h12.15284c5.3194 0 9.6312 4.24375 9.6312 9.47847v2.15073c0 .5597-.4615 1.0132-1.0302 1.0132h-4.3788c-.5687 0-1.0294-.4535-1.0294-1.0132v-2.15073c0-1.73472-1.4295-3.14166-3.1928-3.14166h-6.74459v2.76319c0 3.6125.54541 7.9604 6.65639 7.9604zm13.6261 26.6028c3.3297 0 6.0299-2.6569 6.0299-5.9347v-8.3959c0-3.2777-2.7002-5.9354-6.0299-5.9354h-3.9067-9.7194-.6265c-3.33034 0-6.02988 2.6577-6.02988 5.9354v8.3959c0 3.2778 2.69954 5.9347 6.02988 5.9347z' />
      <path d='m60.7168 24.7503v-11.3078c0-.604-.5028-1.094-1.1226-1.094h-4.0691c-.6206 0-1.1226.49-1.1226 1.094v35.1438c0 .604.502 1.094 1.1226 1.094h3.779c.5702 0 1.0489-.4153 1.1155-.9661v-.6911c.1071-.8914 1.1865-1.257 1.88-.6662 1.8786 1.5992 4.2784 2.1866 6.6476 2.1866 7.505 0 13.1471-4.8416 13.1471-13.0127 0-8.5747-5.5385-13.014-12.9918-13.014-1.9338 0-4.6421.729-6.571 2.0932-.7581.5362-1.8147.0532-1.8147-.8597zm7.8163 19.094c-3.9855 0-7.4022-2.8251-7.4022-7.3135 0-4.3378 3.4167-7.2637 7.4022-7.2637 4.0372 0 7.2468 2.7739 7.2468 7.2637 0 4.3378-3.2096 7.3135-7.2468 7.3135z' />
      <path d='m90.9643 24.0117c.7148 0 1.324.5072 1.4353 1.1955l.2723 1.68c1.9672-3.0766 4.6067-3.5306 7.1951-3.5306 2.105 0 4.144.6406 5.592 1.5915.599.3932.768 1.1803.437 1.8044l-1.539 2.8886c-.38.7145-1.29.9668-2.009.5708-.902-.4962-1.903-.7526-3.3093-.7526-3.3125 0-6.3668 1.7152-6.3668 6.306v12.3893c0 .7816-.651 1.416-1.4537 1.416h-3.4075c-.8028 0-1.4531-.6344-1.4531-1.416v-22.7262c0-.7823.6503-1.4167 1.4531-1.4167z' />
      <path d='m114.773 40.5864c-.455-.8569.25-1.8769 1.24-1.8769h16.826c.712 0 1.321-.5369 1.354-1.2301.453-9.4226-4.786-14.1563-12.978-14.1563-8.282 0-13.561 5.4483-13.561 13.0147 0 7.97 5.228 13.2656 14.028 13.2656 3.375 0 7.143-.991 9.838-3.042.642-.4886.685-1.4264.105-1.9841l-2.148-2.0656c-.422-.407-1.049-.4809-1.568-.2259-.157.0774-.314.1559-.471.2343-1.173.5854-2.348 1.1718-3.651 1.3461-4.551.6095-7.72-.8424-9.014-3.2798zm12.693-8.8773c.455.8686-.229 1.9052-1.23 1.9052h-9.84c-1.068 0-1.759-1.1755-1.151-2.0303 1.331-1.8748 3.574-2.8126 6.177-2.8126 2.822 0 5.016.9792 6.044 2.9377z' />
      <path d='m165.192 23.9399h-3.99c-.579 0-1.057.4395-1.091 1.0027l-.147 2.4284c-1.449-2.4719-4.71-4.0358-8.126-4.0358-7.454-.0504-13.304 4.4394-13.304 13.0141 0 8.7253 5.591 13.2663 13.148 13.2158 2.847-.0511 6.833-1.4637 8.282-4.3889l.232 3.3972c.044.5232.493.9254 1.031.9254h3.965c.571 0 1.036-.4513 1.036-1.009v-23.5409c0-.557-.465-1.009-1.036-1.009zm-12.229 19.9039c-4.718.5722-8.726-2.3192-8.726-7.4945 0-5.1664 3.994-7.9963 8.701-7.4483 8.721 1.0152 8.741 13.8869.025 14.9428z' />
      <path d='m181.687 16.9034v6.0468c0 .4464.371.8071.829.8071h5.382c.458 0 .829.3614.829.8079v3.6812c0 .4458-.371.8072-.829.8072h-5.434c-.457 0-.828.3614-.828.8072v10.6278c0 2.3717 1.345 3.532 3.313 3.532.721 0 1.528-.1638 2.274-.4299.436-.1561.914.0754 1.058.5059l1.234 3.6751c.137.4125-.082.8686-.502 1.0124-1.493.5113-2.834.7408-4.479.7843-5.538.2025-9.161-2.8755-9.161-9.0798v-10.6278c0-.4458-.372-.8072-.829-.8072h-3.105c-.457 0-.828-.3614-.828-.8072v-3.6812c0-.4465.371-.8079.828-.8079h3.105c.457 0 .829-.3607.829-.8071v-5.5631c0-.4132.319-.7594.741-.803l4.657-.4837c.489-.0505.916.3234.916.803z' />
      <path d='m199.045 13.0263v13.1647c0 .6579.804 1.0179 1.304.5749 2.003-1.7746 4.301-2.3468 6.667-2.3468 7.246 0 10.457 4.7925 10.457 12.1067v12.3458c0 .4243-.353.7684-.789.7684h-4.738c-.435 0-.788-.3441-.788-.7684v-12.2961c0-4.5396-2.433-6.4566-5.798-6.4566-3.727 0-6.315 3.0773-6.315 6.8091v11.9436c0 .4243-.353.7684-.789.7684h-4.736c-.436 0-.789-.3441-.789-.7684v-35.8453c0-.4243.353-.7685.789-.7685h4.736c.436 0 .789.3442.789.7685z' />
      <path d='m228.416 39.7427c-.16-.4921.232-.9972.762-.9972h18.206c.414 0 .767-.3179.795-.7208.668-9.7606-4.612-14.665-12.949-14.665-8.282 0-13.563 5.4483-13.563 13.014 0 7.9707 5.228 13.2663 14.028 13.2663 3.612 0 7.671-1.134 10.387-3.4857.333-.2882.324-.803.007-1.1084l-2.933-2.821c-.271-.2605-.705-.3123-1.005-.0836-1.572 1.2052-4.255 1.9004-6.352 1.9004-3.954 0-6.56-1.7518-7.383-4.299zm13.431-7.2808c.153.4997-.225 1.0124-.76 1.0124h-11.653c-.578 0-.966-.5957-.72-1.1064 1.2-2.4912 3.722-3.7365 6.722-3.7365 3.223 0 5.627 1.2771 6.411 3.8305z' />
      <path d='m250.151 11.0666h-.746c-.325 0-.589.2559-.589.5704v10.3578c0 .3145.264.5704.589.5704h.746c.325 0 .589-.2559.589-.5704v-10.3578c0-.3145-.264-.5704-.589-.5704z' />
      <path d='m252.142 12.3032c0 .7457.614 1.1475 1.191 1.1475s1.191-.4018 1.191-1.1475c0-1.4921-2.382-1.4907-2.382 0z' />
      <path d='m253.691 14.0851h-.747c-.325 0-.589.2559-.589.5704v7.3396c0 .3145.264.5703.589.5703h.747c.325 0 .59-.2558.59-.5703v-7.3396c0-.3145-.265-.5704-.59-.5704z' />
      <path d='m259.53 12.8288c.298 0 .484.0758.856.2907.27.1549.617.0778.791-.1754l.367-.5321c.173-.2511.113-.5908-.137-.7723-.564-.41-1.185-.6174-1.848-.6174-1.834 0-2.843 1.0909-2.843 3.0714v.0805h-.948c-.325 0-.59.2559-.59.5704v.6358c0 .3146.265.5704.59.5704h.948v6.044c0 .3145.264.5704.59.5704h.731c.325 0 .59-.2559.59-.5704v-6.044h1.892c.324 0 .589-.2558.589-.5704v-.6358c0-.3145-.265-.5704-.589-.5704h-1.892v-.0805c0-1.2649.564-1.2649.903-1.2649z' />
      <path d='m265.666 13.9835c1.361 0 2.538.4591 3.316 1.2935.788.8433 1.13 2.0345.987 3.4454-.029.2933-.281.515-.587.515h-6.065c.38 1.1476 1.466 1.5665 2.349 1.5665.9 0 1.842-.3712 2.239-.8822.093-.1221.239-.204.398-.2237.151-.0191.316.0232.442.1214l.534.4162c.25.1937.295.541.098.7886-.787 1.0057-2.209 1.6306-3.711 1.6306-2.598 0-4.413-1.78-4.413-4.3288 0-2.4759 1.897-4.3425 4.413-4.3425zm0 1.8127c-1.135 0-2.02.6434-2.349 1.6701h4.746c-.224-1.067-1.079-1.6701-2.397-1.6701z' />
    </svg>
  );
}
