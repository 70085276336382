import { SVGProps, ReactElement } from 'react';

export function EmptyProductListIcon(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg width='168' height='168' viewBox='0 0 168 168' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M84 168C130.392 168 168 130.392 168 84C168 37.6081 130.392 0 84 0C37.6081 0 0 37.6081 0 84C0 130.392 37.6081 168 84 168Z'
        fill='#FBFBFB'
      />
      <path
        d='M110.682 129.459C110.682 128.159 98.5623 126.988 83.5114 126.988C68.4605 126.988 56.3294 128.028 56.3294 129.459C56.3294 130.889 68.4495 131.929 83.5114 131.929C98.5733 131.929 110.682 130.824 110.682 129.459Z'
        fill='#EEEEEE'
      />
      <path
        d='M110.682 113.24C110.684 113.489 110.637 113.735 110.544 113.966C110.451 114.196 110.314 114.405 110.14 114.581C109.966 114.756 109.759 114.895 109.532 114.99C109.305 115.084 109.061 115.131 108.815 115.129H61.176C60.9296 115.131 60.6852 115.084 60.457 114.99C60.2287 114.896 60.0211 114.757 59.8462 114.581C59.6713 114.406 59.5324 114.197 59.4377 113.967C59.3429 113.737 59.2941 113.49 59.2941 113.24V46.8538C59.2941 46.6045 59.3429 46.3576 59.4377 46.1274C59.5324 45.8973 59.6713 45.6884 59.8462 45.5127C60.0211 45.3371 60.2287 45.1983 60.457 45.1042C60.6852 45.0102 60.9296 44.9628 61.176 44.9648H100.532C100.78 44.9632 101.025 45.0114 101.254 45.1066C101.483 45.2019 101.691 45.3422 101.866 45.5195L110.134 53.8853C110.309 54.0622 110.448 54.2726 110.542 54.5042C110.636 54.7359 110.684 54.9841 110.682 55.2346V113.24Z'
        fill='#F6F6F6'
      />
      <path
        d='M110.682 112.834C110.684 113.093 110.635 113.35 110.537 113.59C110.438 113.83 110.293 114.048 110.11 114.231C109.927 114.414 109.709 114.559 109.469 114.657C109.229 114.756 108.973 114.805 108.713 114.803H58.4769C58.2171 114.805 57.9594 114.756 57.7187 114.658C57.478 114.56 57.2591 114.415 57.0747 114.232C56.8902 114.049 56.7438 113.831 56.6438 113.591C56.5439 113.351 56.4925 113.094 56.4925 112.834V43.6439C56.4925 43.384 56.5439 43.1267 56.6438 42.8868C56.7438 42.647 56.8902 42.4292 57.0747 42.2462C57.2591 42.0632 57.478 41.9185 57.7187 41.8204C57.9594 41.7224 58.2171 41.673 58.4769 41.6751H99.9786C100.24 41.6734 100.498 41.7237 100.74 41.8229C100.981 41.9222 101.201 42.0684 101.385 42.2532L110.104 50.9723C110.289 51.1567 110.435 51.376 110.534 51.6174C110.634 51.8589 110.684 52.1176 110.682 52.3786V112.834Z'
        stroke='#757575'
        strokeLinejoin='round'
      />
      <path
        d='M96.0252 106.912C101.488 106.912 105.916 102.484 105.916 97.0213C105.916 91.5586 101.488 87.1302 96.0252 87.1302C90.5625 87.1302 86.1342 91.5586 86.1342 97.0213C86.1342 102.484 90.5625 106.912 96.0252 106.912Z'
        fill='white'
        stroke='#27AE60'
        strokeMiterlimit='10'
      />
      <path
        d='M91.0875 97.0209L95.0408 100.974L101.463 93.552'
        stroke='#27AE60'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M69.3524 65.3947H99.9786' stroke='#757575' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M72.1336 59.4572H85.2748' stroke='#757575' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M69.3524 71.3169H99.9786' stroke='#757575' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M69.3524 77.2546H99.9786' stroke='#757575' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M100.807 49.7537C100.805 49.9903 100.85 50.2249 100.939 50.4438C101.029 50.6628 101.161 50.8617 101.328 51.029C101.496 51.1963 101.695 51.3285 101.914 51.4181C102.132 51.5077 102.367 51.5527 102.604 51.5507H110.416C110.321 51.3364 110.189 51.1406 110.026 50.9725L101.385 42.2534C101.217 42.0901 101.021 41.9578 100.807 41.8628V49.7537Z'
        fill='white'
        stroke='#757575'
        strokeLinejoin='round'
      />
    </svg>
  );
}
