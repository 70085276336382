import { ESignSigner2FAInfo, FileTemplateRecipient } from '@breathelife/types';

export function getFileTemplateSignatureRoles(
  fileTemplateRecipients: FileTemplateRecipient[] = [],
  signers: ESignSigner2FAInfo[],
): { type: string; position: number }[] {
  // 1- Filter the roles of the evaluated application signers
  const signerRoles = signers.map((signer) => signer.roles).flat();

  // 2- Get the roles of the file template recipients
  const documentRecipientRoles = fileTemplateRecipients.map(({ participantRole: { type, position } }) => ({
    type,
    position,
  }));

  // 3- Match the file template recipient roles to the signer roles
  const signatureRoles = documentRecipientRoles.filter((role) =>
    signerRoles.some((signerRole) => signerRole.type === role.type && signerRole.position === role.position),
  );

  return signatureRoles;
}
