import { ReactElement, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Language, Localizable } from '@breathelife/types';
import { Input as TextInput } from '@breathelife/ui-components';
import { AddIcon, Box, Grid } from '@breathelife/mui';

import { ActionButton } from '../../../../../Components/Button/ActionButton';
import { IconButton } from '../../../../../Components/Controls';
import { Icon } from '../../../../../Components/Icons';
import Typography from '../../../../../Components/Typography';
import { DeleteItemFunction, ValueChangeFunction } from '../../types';

type Props = {
  enabledLanguages: Language[];
  additionalFeatures: Partial<Localizable>[];
  onInputValueChange: ValueChangeFunction;
  onListFieldItemDelete: DeleteItemFunction;
  disabled?: boolean;
};

export function AdditionalFeatures(props: Props): ReactElement {
  const { t } = useTranslation();
  return (
    <Grid item xs={12} container spacing={2}>
      <Grid item xs={12}>
        <Box mt={2}>
          <Typography variant='h3'>{t('admin.productManagement.labels.additionalFeatures')}</Typography>
        </Box>
      </Grid>
      {props.additionalFeatures.map((feature, index) => (
        <Fragment key={index}>
          <Grid item xs={12}>
            <Box mt={2} display='flex' alignItems='center' justifyContent='space-between'>
              <Typography variant='body1'>
                {t('admin.productManagement.labels.additionalFeature', { index: index + 1 })}
              </Typography>
              <IconButton
                disabled={props.disabled}
                data-testid={`additionalFeature-${index}-delete`}
                icon={<Icon name='delete' alt={t('cta.delete')} />}
                title={t('cta.delete')}
                onClick={() => props.onListFieldItemDelete({ path: 'additionalFeatures', index })}
              />
            </Box>
          </Grid>
          {props.enabledLanguages.map((language) => (
            <Fragment key={`productManagement-additionalFeatures-${index}-${language}`}>
              <Grid item xs={12}>
                <TextInput
                  multiline
                  disabled={props.disabled}
                  name={`productManagement-additionalFeatures-${index}-${language}`}
                  inputVariant='outlined'
                  label={t(`language.${language}`)}
                  value={feature[language] ?? ''}
                  onChange={(event) => {
                    props.onInputValueChange({
                      path: `additionalFeatures.${index}.${language}`,
                      value: event.target.value,
                    });
                  }}
                />
              </Grid>
            </Fragment>
          ))}
        </Fragment>
      ))}
      <Box mt={1} display='flex' alignItems='center' justifyContent='flex-end' ml='auto' width='100%'>
        <ActionButton
          data-testid='addProductAdditionalFeatures'
          color='primary'
          variant='contained'
          disabled={props.disabled}
          onClick={() =>
            props.onInputValueChange({
              path: 'additionalFeatures',
              value: [
                ...props.additionalFeatures,
                props.enabledLanguages.reduce((acc, language) => ({ ...acc, [language]: '' }), {}),
              ],
            })
          }
          startIcon={<AddIcon htmlColor='white' />}
        >
          {t('cta.addNew')}
        </ActionButton>
      </Box>
    </Grid>
  );
}
