import _ from 'lodash';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { LeadTab } from '../Models/Layout';
import { UrlParamKeys } from '../Navigation/Urls';

type UseNavigationOutput = {
  leadId?: number;
  leadTab?: LeadTab;
  applicationId?: string;
  drawerType?: string;
};

export function useNavigation(): UseNavigationOutput {
  const routerParams = useParams<{
    [UrlParamKeys.leadId]: string;
    [UrlParamKeys.leadTab]: string;
    [UrlParamKeys.applicationId]: string;
    [UrlParamKeys.drawerType]: string;
  }>();

  const leadIdParam: string = routerParams[UrlParamKeys.leadId] || '';
  const leadTabParam: string = routerParams[UrlParamKeys.leadTab] || '';
  const applicationIdParam: string = routerParams[UrlParamKeys.applicationId] || '';
  const drawerTypeParam: string = routerParams[UrlParamKeys.drawerType] || '';

  const leadId = useMemo(() => {
    if (_.isUndefined(leadIdParam)) return undefined;
    const parsedLeadIdParam = parseInt(leadIdParam);
    return _.isNaN(parsedLeadIdParam) ? undefined : parsedLeadIdParam;
  }, [leadIdParam]);

  const leadTab = useMemo(() => {
    if (_.isUndefined(leadTabParam)) return undefined;
    const isValidLeadTab = Object.keys(LeadTab).includes(leadTabParam);
    return isValidLeadTab ? LeadTab[leadTabParam as keyof typeof LeadTab] : undefined;
  }, [leadTabParam]);

  const applicationId = useMemo(() => {
    if (_.isUndefined(applicationIdParam)) return undefined;

    return applicationIdParam;
  }, [applicationIdParam]);

  const drawerType = useMemo(() => {
    if (_.isUndefined(drawerTypeParam)) return undefined;

    return drawerTypeParam;
  }, [drawerTypeParam]);

  return {
    leadId,
    leadTab,
    applicationId,
    drawerType,
  };
}
