import { WebAuth } from 'auth0-js';
import i18n from 'i18next';
import _ from 'lodash';
import { ReactElement, memo, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ThemeProvider } from './Styles/themed-styled-components';

import { MuiThemeProvider } from '@breathelife/mui';
import { setMonitoringUser } from '@breathelife/monitoring-frontend';
import { TypewriterTracking } from '@breathelife/frontend-tracking';
import {
  Language,
  LeadPlatformHeaderVariants,
  NavigationSidebarVariants,
  UserFields,
  LeadStatus,
  LineOfBusinessName,
  LeadPlatformTextOverrides,
  LeadStatusesKeys,
  LeadTableColumnInfo,
} from '@breathelife/types';
import { ErrorBoundary, Loader, getColorizedBaseTheme } from '@breathelife/ui-components';

import { CarrierContext } from './Context/CarrierContext';
import { FileType } from './Helpers/getFileIcon';
import { defaultSsoProfileFields } from './Helpers/user';
import { useDispatch, useSelector } from './Hooks';
import initI18n, { changeLanguage } from './Localization';
import { getCurrentLocale, getLanguageRegion } from './Localization/utils';
import { EnabledTabs } from './Models/Layout';
import { LeadStatusesColumnData } from './Models/Lead';
import { Mga } from './Models/Mga';
import { PlatformFeatures } from './Models/PlatformFeatures';
import { ErrorPage } from './Pages/Error/ErrorPage';
import { ReactQueryClientProvider } from './ReactQuery';
import { getSettingsData } from './ReduxStore/Admin/SettingsManagement/SettingsSelectors';
import { fetchTheme } from './ReduxStore/Admin/ThemeManagement/ThemeOperations';
import { Root } from './Root/Root';
import { setAuthorizationInterceptor } from './Services/ApiService';
import { initAuth0Instance } from './Services/Auth0';
import { GlobalStyle } from './Styles/GlobalStyle';
import { createMuiTheme } from './Styles/MuiTheme';
import leadPlatformTheme from './Styles/Theme';
import { LeadPlatformTheme } from './Styles/Types';
import { LeadPlatformConfig } from './types';
import { themeSlice } from './ReduxStore/Admin/ThemeManagement/ThemeSlice';
import { getThemeFromUserGroups } from './Helpers/userGroups';

export type { CountryCode, LeadPlatformConfig, LocalizedHostname } from './types';

const defaultLeadStatusLabels = {
  new: {
    label: LeadStatusesKeys.new,
  },
  invited: {
    label: LeadStatusesKeys.invited,
  },
  planFinderStarted: {
    label: LeadStatusesKeys.started,
  },
  planFinderCompleted: {
    label: LeadStatusesKeys.progressing,
  },
  engaged: {
    label: LeadStatusesKeys.finalized,
  },
  transactionFlowStarted: {
    label: LeadStatusesKeys.applicationStarted,
  },
  qualified: {
    label: LeadStatusesKeys.applicationCompleted,
  },
  submissionStarted: {
    label: LeadStatusesKeys.submissionInProgress,
  },
  submissionCompleted: {
    label: LeadStatusesKeys.applicationSubmitted,
  },
};

interface IsEmailActionEnabled {
  (leadStatus: LeadStatus): boolean;
}

type CarrierInfo = {
  companyName: string;
  id: string;
  logo?: string;
  logoCompact?: string;
  isDefaultTheme?: boolean;
  lineOfBusiness: LineOfBusinessName[];
  minorAge?: number;
  validFileUploadMimeTypes?: string[];
};

type LeadPlatformProps = {
  carrierInfo: CarrierInfo;
  enabledTabs: EnabledTabs;
  externalResources?: {
    downloadableFiles?: { title: string; subtitle?: string; source: string; type: FileType }[];
    headerMenuResources?: { title: string; link: string; icon: ReactElement<SVGElement> }[];
  };
  headerVariant?: LeadPlatformHeaderVariants;
  isEmailActionEnabled: IsEmailActionEnabled;
  leadTableColumns: LeadTableColumnInfo[];
  defaultToSsoConnection?: boolean;
  availablePlatformLanguages?: Language[];
  defaultPlatformLanguage?: Language;
  monitoringEnvironment?: string;
  navigationSidebarVariant?: NavigationSidebarVariants;
  ssoProfileFields?: UserFields;
  coverageAmountStepRounding?: number;
  leadStatuses?: Partial<LeadStatusesColumnData>;
  mgas?: Mga[];
  config: LeadPlatformConfig;
  features: PlatformFeatures;
  iconMap?: Record<string, string>;
  textOverrides?: LeadPlatformTextOverrides;
};

function LeadPlatformComponent(props: LeadPlatformProps): ReactElement | null {
  const {
    config,
    leadStatuses,
    carrierInfo,
    enabledTabs,
    externalResources,
    headerVariant,
    isEmailActionEnabled,
    leadTableColumns,
    mgas,
    features,
    monitoringEnvironment,
    navigationSidebarVariant,
    iconMap,
    availablePlatformLanguages,
    defaultPlatformLanguage,
    ssoProfileFields,
    coverageAmountStepRounding,
    textOverrides,
  } = props;

  const { auth0: auth0Config, cloudinary, countryCode, googleMapsPlaces, unsubToolUrl, localizedHostnames } = config;

  const dispatch = useDispatch();
  const location = useLocation();

  const [auth0, setAuth0] = useState<WebAuth>();
  const [languageInitialized, setLanguageInitialized] = useState<boolean>(false);
  const [fetchThemeInitialized, setFetchThemeInitialized] = useState<boolean>(false);
  const authentication = useSelector((store) => store.leadPlatform.authentication);
  const { theme } = useSelector((store) => store.leadPlatform.theme);
  const settings = useSelector(getSettingsData, _.isEqual);
  const carrierNames = settings?.carrierNames;
  const defaultLanguage = settings?.defaultLanguage;
  const enabledLanguages = settings?.enabledLanguages;
  const authenticatedUser = authentication.user;

  const isMultiBrandingEnabled = features.multiBranding.enabled;

  useEffect(() => {
    if (fetchThemeInitialized) return;
    if (isMultiBrandingEnabled && theme && authenticatedUser && authenticatedUser.groups) {
      const theme = getThemeFromUserGroups(authenticatedUser.groups);
      if (theme) {
        dispatch(themeSlice.actions.setTheme({ theme }));
        setFetchThemeInitialized(true);
        return;
      }
    }

    void dispatch(fetchTheme());
    setFetchThemeInitialized(true);
  }, [dispatch, fetchThemeInitialized, theme, isMultiBrandingEnabled, authenticatedUser]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const auth0Instance = initAuth0Instance(auth0Config, searchParams);
    setAuth0(auth0Instance);
  }, [auth0Config, location.search]);

  const mergedLanguageSettings: {
    enabledLanguages: Language[];
    default: Language;
    userPlatformLanguage: Language;
  } = useMemo(() => {
    if (enabledLanguages && defaultLanguage) {
      const currentLocale = getCurrentLocale(defaultLanguage);
      let userPlatformLanguage = authenticatedUser?.platformLanguage;

      if (authenticatedUser?.platformLanguage && enabledLanguages.includes(authenticatedUser.platformLanguage)) {
        userPlatformLanguage = authenticatedUser.platformLanguage;
      }

      if (!authenticatedUser?.platformLanguage && enabledLanguages.includes(currentLocale)) {
        userPlatformLanguage = currentLocale;
      }

      return {
        enabledLanguages: enabledLanguages,
        default: defaultLanguage,
        userPlatformLanguage: userPlatformLanguage ?? defaultLanguage,
      };
    } else {
      const availableLanguages = availablePlatformLanguages ?? [Language.en];
      const defaultLanguage = defaultPlatformLanguage ?? Language.en;
      const currentLocale = getCurrentLocale(defaultLanguage);

      let userPlatformLanguage;

      if (authenticatedUser?.platformLanguage && availableLanguages.includes(authenticatedUser.platformLanguage)) {
        userPlatformLanguage = authenticatedUser.platformLanguage;
      }

      if (!authenticatedUser?.platformLanguage && availableLanguages.includes(currentLocale)) {
        userPlatformLanguage = currentLocale;
      }

      return {
        enabledLanguages: availableLanguages,
        default: defaultLanguage,
        userPlatformLanguage: userPlatformLanguage ?? defaultLanguage,
      };
    }
  }, [availablePlatformLanguages, defaultLanguage, defaultPlatformLanguage, enabledLanguages, authenticatedUser]);

  useEffect(() => {
    if (languageInitialized) return;
    setLanguageInitialized(true);
    const isOptionalNeedsAnalysisEnabled = features.needsAnalysis.enabled && features.needsAnalysis.optional;
    void initI18n(mergedLanguageSettings.userPlatformLanguage, isOptionalNeedsAnalysisEnabled, textOverrides);
  }, [languageInitialized, mergedLanguageSettings.userPlatformLanguage, features, textOverrides]);

  useEffect(() => {
    if (!languageInitialized || !authenticatedUser) return;

    const onLanguageChanged = (): void => {
      TypewriterTracking.identify({
        userId: authenticatedUser.auth0Id,
        traits: {
          userType: authenticatedUser.roles.join(','),
          locale: getLanguageRegion(),
        },
      });
    };

    i18n.on('languageChanged', onLanguageChanged);

    void changeLanguage(mergedLanguageSettings.userPlatformLanguage);

    return () => {
      i18n.off('languageChanged', onLanguageChanged);
    };
  }, [languageInitialized, authenticatedUser, mergedLanguageSettings.userPlatformLanguage]);

  useEffect(() => {
    if (authentication.token) {
      setAuthorizationInterceptor(authentication.token);
    }
    if (authentication.user) {
      setMonitoringUser(authentication.user);
    }
  }, [dispatch, authentication, auth0]);

  const mergedThemes = useMemo<LeadPlatformTheme | undefined>(() => {
    if (!theme) return;

    const colorizedTheme = getColorizedBaseTheme(theme.colorRanges);
    return _.merge({}, colorizedTheme, leadPlatformTheme);
  }, [theme]);

  if (!theme || !mergedThemes) return <Loader color='#000000' />;

  return (
    <ReactQueryClientProvider>
      <ThemeProvider theme={mergedThemes}>
        <MuiThemeProvider theme={createMuiTheme(mergedThemes)}>
          <ErrorBoundary renderErrorComponent={() => <ErrorPage />}>
            <CarrierContext.Provider
              value={{
                carrierInfo: {
                  companyName:
                    features.loadCarrierNamesFromDb?.enabled && carrierNames
                      ? carrierNames[mergedLanguageSettings.userPlatformLanguage]
                      : carrierInfo.companyName,
                  id: carrierInfo.id,
                  logo: theme.logoImgUrl,
                  logoCompact: theme.compactLogoImgUrl,
                  isDefaultTheme: theme.isDefault,
                  // TODO HOT-4574: Remove lineOfBusiness from config
                  lineOfBusiness: carrierInfo.lineOfBusiness,
                  minorAge: carrierInfo.minorAge,
                  validFileUploadMimeTypes: carrierInfo.validFileUploadMimeTypes,
                },
                cloudinaryConfig: cloudinary,
                countryCode: countryCode,
                googleMapsPlaces,
                enabledTabs: enabledTabs,
                externalResources: externalResources,
                headerVariant: headerVariant,
                isEmailActionEnabled: isEmailActionEnabled,
                leadStatuses: leadStatuses || defaultLeadStatusLabels,
                leadTableColumns: leadTableColumns,
                unsubToolUrl: unsubToolUrl,
                responseType: auth0Config.responseType,
                ssoConnectionName: auth0Config.ssoConnectionName,
                defaultToSsoConnection: auth0Config.defaultToSsoConnection ?? false,
                mgas: mgas ?? [],
                features: features,
                languageSettings: {
                  enabledLanguages: mergedLanguageSettings.enabledLanguages,
                  default: mergedLanguageSettings.default,
                },
                navigationSidebarVariant: theme.navigationSidebarVariant ?? navigationSidebarVariant,
                iconMap: iconMap ?? {},
                ssoProfileFields: ssoProfileFields || defaultSsoProfileFields,
                availablePlatformLanguages,
                coverageAmountStepRounding,
                pdfFetchDuration: config.pdfFetchDuration,
                showBlueprintIdInEditor: features.showBlueprintIdInEditor?.enabled,
                localizedHostnames,
                monitoringEnvironment,
                git: {
                  commitSha: config.git.commitSha,
                },
              }}
            >
              {auth0 ? <Root /> : <div />}
            </CarrierContext.Provider>
          </ErrorBoundary>
        </MuiThemeProvider>
        <GlobalStyle />
      </ThemeProvider>
    </ReactQueryClientProvider>
  );
}

export const LeadPlatform = memo(LeadPlatformComponent, _.isEqual);

export * from './ReduxStore/Store';
export * from './ReduxStore/types';
export * from './Models/Layout';
export * from './Models/Lead';
export * from './Models/Mga';
export * from './Models/PlatformFeatures';
