import { ReactElement, RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Box, Divider } from '@breathelife/mui';
import { InsuredUnderwritingOutcomeReport, InsuredUnderwritingOutcomesReports } from '@breathelife/types';
import { Loader, TreeView, TreeViewItemRenderChildProps } from '@breathelife/ui-components';

import { Tabs } from '../../../../Components/Tabs/Tabs';
import {
  mapInsuredToTreeViewData,
  SelectedReportData,
  UnderwritingTreeViewDataItem,
} from '../../../../Helpers/applicationSupport/underwritingTreeviewDataMapping';
import { useFindApplicationSupportUnderwritingReportsQuery } from '../../../../ReactQuery/ApplicationSupport/UnderwritingReports/underwritingReports.queries';
import { ReportTreeViewItem } from './ReportTreeViewItem';
import {
  UnderwritingReportsArea,
  UnderwritingReportsView,
  UnderwritingResizeHandle,
  UnderwritingTreeContainer,
  UnderwritingTreeViewPanelContainer,
} from './Styles';
import { UnderWritingReportsJsonArea } from './UnderwritingReportsJsonArea';

function useResize<T extends HTMLElement = HTMLDivElement>({
  minWidth,
}: {
  minWidth: number;
}): {
  ref: RefObject<T>;
  width: number;
  isResizing: boolean;
  enableResize: () => void;
} {
  const [isResizing, setIsResizing] = useState(false);
  const [width, setWidth] = useState(minWidth);
  const ref = useRef<T>(null);

  const enableResize = useCallback(() => {
    setIsResizing(true);
  }, [setIsResizing]);

  const disableResize = useCallback(() => {
    setIsResizing(false);
  }, [setIsResizing]);

  const resize = useCallback(
    (event: MouseEvent) => {
      if (isResizing && ref.current) {
        const offsetLeft = ref.current.getBoundingClientRect().left;
        const newWidth = event.clientX - offsetLeft;
        setWidth(newWidth);
      }
    },
    [isResizing],
  );

  useEffect(() => {
    document.addEventListener('mousemove', resize);
    document.addEventListener('mouseup', disableResize);

    return () => {
      document.removeEventListener('mousemove', resize);
      document.removeEventListener('mouseup', disableResize);
    };
  }, [disableResize, resize]);

  return { ref, width, isResizing, enableResize };
}

const getNewParentPathFromPreviousTreeItemPath = (
  _: string[],
  previousItemPath: string[],
  previousItemIsOpen?: boolean,
): string[] => {
  if (previousItemIsOpen) {
    return previousItemPath;
  }

  const parentPath = [...previousItemPath];
  parentPath.length -= 1;
  return parentPath;
};

const TREE_SECTION_WIDTH = 500;

const hasReports = (data: InsuredUnderwritingOutcomesReports | null | undefined): boolean => {
  return (
    !!data?.insured.length &&
    data.insured.some((reportsForInsured: InsuredUnderwritingOutcomeReport) => reportsForInsured.reports.length)
  );
};

export function UnderwritingReportsViewContainer(): ReactElement {
  const { t } = useTranslation();
  const { applicationId = '' } = useParams<{ applicationId: string }>();
  const { width, ref, isResizing, enableResize } = useResize({ minWidth: TREE_SECTION_WIDTH });

  const [selectedInsuredTab, setSelectedInsuredTab] = useState(0);
  const [underwritingTreeViewdata, setUnderwritingTreeViewData] = useState<UnderwritingTreeViewDataItem[]>([]);
  const [selectedReport, setSelectedReport] = useState<SelectedReportData>();
  const [selectedTreeViewItem, setSelectedTreeViewItem] = useState<string>();

  const { data: underwritingData, isFetched } = useFindApplicationSupportUnderwritingReportsQuery(applicationId);

  const insuredTabs = underwritingData?.insured?.length
    ? underwritingData.insured.map((_insured: InsuredUnderwritingOutcomeReport, index: number) => ({
        label: t('applicationSupport.underwritingReports.insured', (index + 1).toString()),
      }))
    : [];

  useEffect(() => {
    if (isFetched && underwritingData?.insured[selectedInsuredTab]) {
      const insuredTreeViewData = mapInsuredToTreeViewData(underwritingData?.insured[selectedInsuredTab]);
      setUnderwritingTreeViewData(insuredTreeViewData);
    } else {
      setUnderwritingTreeViewData([]);
    }
  }, [isFetched, underwritingData, selectedInsuredTab]);

  const onSwitchTabs = (selectedInsured: number): void => {
    setSelectedInsuredTab(selectedInsured);
  };

  const onReportsClick = (report: SelectedReportData, treeViewItemId: string): void => {
    setSelectedReport(report);
    setSelectedTreeViewItem(treeViewItemId);
  };

  if (!hasReports(underwritingData)) {
    return <Box p='1.1rem'>{t('applicationSupport.underwritingReports.noReportsFound')}</Box>;
  }

  return !isFetched ? (
    <Loader />
  ) : (
    <UnderwritingReportsView>
      <Tabs
        value={selectedInsuredTab}
        onChange={(_event, selectedInsured) => onSwitchTabs(selectedInsured)}
        tabs={insuredTabs}
      />
      <Divider />
      {underwritingTreeViewdata.length > 0 && (
        <UnderwritingReportsArea>
          <UnderwritingTreeViewPanelContainer>
            <UnderwritingTreeContainer ref={ref} width={width}>
              <TreeView
                data={underwritingTreeViewdata}
                treeName='tree'
                canDropInsideOtherElement
                itemStyle={{ height: 30 }}
                isFullItemClickable
                getDataItemFromUnknownItem={(item: UnderwritingTreeViewDataItem) => ({
                  identifier: item.identifier,
                  iconName: item.iconName,
                  label: item.label,
                  iconWidth: 20,
                  metadata: item.metadata,
                })}
                getUnknownItemChildren={(item: UnderwritingTreeViewDataItem) => {
                  return item.children || [];
                }}
                getNewParentPathFromPreviousTreeItemPath={getNewParentPathFromPreviousTreeItemPath}
                renderChild={(child: TreeViewItemRenderChildProps) => {
                  const { data: reportData } = child;
                  const isTreeViewItemSelected = reportData.identifier === selectedTreeViewItem;
                  if (!reportData.hasChildren && reportData.metadata) {
                    return (
                      <ReportTreeViewItem
                        /** @TODO [HOT-3650]: Rework the treeview types to avoid recasting renderChild data */
                        reportMetadata={reportData.metadata as SelectedReportData}
                        onReportClick={onReportsClick}
                        treeViewItemId={reportData.identifier}
                        isSelected={isTreeViewItemSelected}
                      />
                    );
                  }
                  return null;
                }}
              />
            </UnderwritingTreeContainer>
          </UnderwritingTreeViewPanelContainer>
          <UnderwritingResizeHandle type='button' $isResizing={isResizing} onMouseDown={enableResize} />
          <UnderWritingReportsJsonArea report={selectedReport} />
        </UnderwritingReportsArea>
      )}
    </UnderwritingReportsView>
  );
}
