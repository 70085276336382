import { ReactElement } from 'react';

import { SvgIconProps } from '../../Components/Icons';

export function NotificationArrowDownIcon(props: SvgIconProps): ReactElement {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props.svgProps}>
      <path
        d='M13.7143 13.5C14.0286 13.5 14.2857 13.275 14.2857 13V3C14.2857 2.725 14.0286 2.5 13.7143 2.5H2.28571C1.97143 2.5 1.71429 2.725 1.71429 3V13C1.71429 13.275 1.97143 13.5 2.28571 13.5H13.7143ZM16 13C16 14.1031 14.975 15 13.7143 15H2.28571C1.025 15 0 14.1031 0 13V3C0 1.89688 1.025 1 2.28571 1H13.7143C14.975 1 16 1.89688 16 3V13ZM8 11C7.76071 11 7.53571 10.9125 7.37143 10.7594L3.65714 7.25938C3.425 7.04063 3.36429 6.72187 3.5 6.45C3.63571 6.17812 3.94643 6 4.28571 6H11.7143C12.0536 6 12.3643 6.17812 12.5 6.45C12.6357 6.72187 12.575 7.04063 12.3429 7.25938L8.62857 10.7594C8.46786 10.9125 8.23929 11 8 11Z'
        fill='#999999'
      />
    </svg>
  );
}
