import { TFunction } from 'i18next';

import { SubsectionVariant, SubsectionVariantBlueprint } from '@breathelife/types';

export function getBlueprintVariantName(variant?: SubsectionVariantBlueprint, t?: TFunction): string | undefined {
  if (!variant || !t) {
    return;
  }
  if (variant.type === SubsectionVariant.quoter && variant.simpleQuoter) {
    return t(`admin.questionnaireManagement.subsectionVariants.simpleQuoter`);
  } else {
    return t(`admin.questionnaireManagement.subsectionVariants.${variant.type}`);
  }
}
