import { QuestionnaireVersionPricingNodeIds } from '@breathelife/types';
import ApiService from './ApiService';

export async function getPricingFieldIdentifiers(
  questionnaireVersionId?: string | null,
): Promise<QuestionnaireVersionPricingNodeIds[]> {
  const response = await ApiService.getPricingFieldIdentifiers({
    questionnaireVersionId,
  });
  return response;
}

export async function patchPricingFieldIdentifiers(
  pricingFieldIdentifiersId: string,
  data: Pick<QuestionnaireVersionPricingNodeIds, 'nodeIds'>,
): Promise<QuestionnaireVersionPricingNodeIds> {
  const response = await ApiService.patchPricingFieldIdentifiers(pricingFieldIdentifiersId, data);
  return response.data;
}
