import { Box } from '@breathelife/mui';
import styled from '../../Styles/themed-styled-components';

export const PerPageContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${(props) => props.theme.moduleBreakpoints.sm}) {
    position: absolute;
    top: 4px;
    left: 0;
  }
`;
