import { ReactElement, Fragment } from 'react';

import { DetailViewType } from '../../../../../Models/Layout';
import { Lead } from '../../../../../Models/Lead';
import { PersonalInfoReadView } from './PersonalInfoReadView';
import { PersonalInfoWriteView } from './PersonalInfoWriteView';

type PersonalInfoViewProps = {
  lead?: Lead;
  onClose: () => void;
  detailViewType: DetailViewType;
};

export function PersonalInfoView(props: PersonalInfoViewProps): ReactElement {
  const { detailViewType, lead, onClose } = props;
  const displayWriteView = detailViewType === DetailViewType.create || detailViewType === DetailViewType.edit;

  return (
    <Fragment>
      {displayWriteView && (
        <PersonalInfoWriteView lead={props.lead} detailViewType={detailViewType} onClose={onClose} />
      )}
      {!displayWriteView && lead && <PersonalInfoReadView lead={lead} />}
    </Fragment>
  );
}
