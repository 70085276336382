import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Grid, IconButton, Tooltip } from '@breathelife/mui';
import { ESignSigner, ESignSignerAuthMethod, OutcomeCode } from '@breathelife/types';

import { OutcomeBadge } from '../../../../../Components/Badge/OutcomeBadge';
import Typography from '../../../../../Components/Typography';
import { SignerInfoHeader } from './SignerInfoHeader';
import { ESignSignerStatusBadge } from '../../../../../Components/Badge/ESignSignerStatusBadge';
import { ImageTypes } from '@breathelife/ui-components';
import { Icon } from '../../../../Icons';

type SignerInfoProps = {
  signer: ESignSigner;
  outcome?: OutcomeCode;
  overrideOutcome?: string;
};

export function SignerInfo(props: SignerInfoProps): ReactElement {
  const { t } = useTranslation();
  const { signer, outcome, overrideOutcome } = props;
  const { authMethod, cellphone, passcode, email, status } = signer;
  const [maskPasscode, setMaskPasscode] = useState(true);

  const tooltipTextHide = t('assistedApplication.eSignatureDetails.hide');
  const tooltipTextShow = t('assistedApplication.eSignatureDetails.show');

  const maskString = (s: string): string =>
    s
      .split('')
      .map(() => '•')
      .join('');

  return (
    <div>
      <SignerInfoHeader signer={signer} />
      <Box px={2}>
        <Box mb={2}>
          <Grid container>
            {outcome && (
              <Grid item xs={6}>
                <OutcomeBadge outcome={outcome} overrideOutcome={overrideOutcome} withIcon />
              </Grid>
            )}
            <Grid item xs={6}>
              <ESignSignerStatusBadge status={status} withIcon />
            </Grid>
          </Grid>
        </Box>
        <Box mb={2}>
          <Typography variant='body5' component='div'>
            {t('assistedApplication.eSignatureDetails.email')}
          </Typography>
          <Typography variant='body1' component='div'>
            {email}
          </Typography>
        </Box>
        {authMethod === ESignSignerAuthMethod.cellphone && (
          <Box>
            <Typography variant='body5' component='div'>
              {t('assistedApplication.eSignatureDetails.cellphone')}
            </Typography>
            <Typography variant='body1' component='div'>
              {cellphone}
            </Typography>
          </Box>
        )}
        {authMethod === ESignSignerAuthMethod.passcode && passcode && (
          <Box>
            <Typography variant='body5' component='div'>
              {t('assistedApplication.eSignatureDetails.passcode')}
            </Typography>
            <Box display='flex' alignItems='top'>
              <Typography variant='body1' component='div'>
                {maskPasscode ? maskString(passcode) : passcode}
              </Typography>
              <Tooltip title={maskPasscode ? tooltipTextShow : tooltipTextHide} placement='right'>
                <IconButton
                  onClick={() => setMaskPasscode(!maskPasscode)}
                  sx={{ marginTop: '5px', marginLeft: '10px' }}
                >
                  <Icon
                    name={maskPasscode ? 'visibility' : 'visibilityOff'}
                    variant='primary'
                    stroke='none'
                    imageType={ImageTypes.decorative}
                    width='25px'
                    height='25px'
                  />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        )}
      </Box>
    </div>
  );
}
