import { ReactElement } from 'react';

import { SvgIconProps } from '../../Components/Icons';

export function NotificationFeedbackIcon(props: SvgIconProps): ReactElement {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props.svgProps}>
      <path
        d='M13.7143 11.9356C14.0952 11.9356 14.3783 11.6524 14.3783 11.3262C14.3783 10.9785 14.0697 10.7168 13.7143 10.7168H5.26736C4.88853 10.7168 4.60335 11 4.60335 11.3262C4.60335 11.6738 4.91194 11.9356 5.26736 11.9356H13.7143ZM16.4831 3.85353L20.3352 0.115251C20.482 0.00196989 20.631 -0.042952 20.8034 0.0468918L23.9021 2.8008C24.0255 2.93556 24.049 3.09377 23.8766 3.25197L19.9521 7.05861L16.4831 3.85353ZM18.673 8.25588L14.7018 9.00978L15.204 5.04885L18.673 8.25588ZM4.07342 2.55666H12.9545L12.3054 3.81642H4.07342C3.3328 3.81642 2.65815 4.09572 2.16653 4.54494C1.67491 4.99416 1.37271 5.61134 1.37271 6.29494V15.2285C1.37271 15.9082 1.67704 16.5293 2.16653 16.9785C2.65603 17.4278 3.33067 17.707 4.07342 17.707H4.86512V17.709L4.90982 17.711C5.28651 17.7344 5.57382 18.0352 5.54616 18.3809L5.278 21.916L10.3815 19.082C10.5049 18.9688 10.6752 18.9004 10.8646 18.9004H18.8071C19.5478 18.9004 20.2224 18.6231 20.714 18.1719C21.2035 17.7227 21.5078 17.1016 21.5078 16.4219V9.85353H22.9295V18.1914C23.004 20.0723 22.1676 21.1289 18.7944 21.2578L11.9308 21.1133L4.96515 23.8223L4.9311 23.8477C4.64379 24.0742 4.21176 24.043 3.96488 23.7774C3.84358 23.6465 3.79037 23.4863 3.80101 23.3281L4.13089 18.9649H4.07555C2.95611 18.9649 1.93669 18.5449 1.19819 17.8672C0.457569 17.1934 0 16.2578 0 15.2305V6.29494C0 5.26759 0.457568 4.33205 1.19606 3.65431C1.93243 2.97658 2.95185 2.55666 4.07342 2.55666ZM16.9598 15.3164C17.3408 15.3164 17.6238 15.0332 17.6238 14.707C17.6238 14.3574 17.3152 14.0977 16.9598 14.0977H5.26736C4.88853 14.0977 4.60335 14.3809 4.60335 14.707C4.60335 15.0547 4.91194 15.3164 5.26736 15.3164H16.9598ZM10.7241 8.55666C11.103 8.55666 11.3881 8.27345 11.3881 7.94728C11.3881 7.62111 11.0795 7.33791 10.7241 7.33791H5.26736C4.88853 7.33791 4.60335 7.62111 4.60335 7.94728C4.60335 8.29494 4.91194 8.55666 5.26736 8.55666H10.7241Z'
        fill='#999999'
      />
    </svg>
  );
}
