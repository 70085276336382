import { CheckBoxGroupFieldBlueprint } from '@breathelife/types';

import { FieldDataSetterProps } from '../../../Helpers/helperTypes';
import { CheckboxGroupFieldSetters, useCheckboxGroupFieldData } from '../useCheckboxGroupFieldData';
import { useCommonFieldDataSetters } from './useCommonFieldDataSetters';

export function CheckboxGroupFieldDataSetter({
  initialData,
  dataLabelOptions,
  ...props
}: FieldDataSetterProps<CheckBoxGroupFieldBlueprint, CheckboxGroupFieldSetters>): null {
  const { data, setters, validationOptions, defaultValueValidationError } = useCheckboxGroupFieldData({
    initialData,
    dataLabelOptions,
  });

  useCommonFieldDataSetters<CheckBoxGroupFieldBlueprint, CheckboxGroupFieldSetters>({
    ...props,
    data,
    setters,
    validationOptions,
    defaultValueValidationError,
    getIsValidBlueprint: (blueprint) => !!(blueprint.validateAs && blueprint.selectOptions),
  });
  return null;
}
