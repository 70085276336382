import { FormControlLabel } from '@breathelife/mui';

import styled from '../../Styles/themed-styled-components';

export const StyledFormControlLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    font-size: ${(props) => props.theme.text.body3.size};
    font-weight: ${(props) => props.theme.text.body3.weight};
  }
`;
