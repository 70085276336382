import { ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { hash } from '@breathelife/hash';
import { getDocumentFormat, TypewriterTracking } from '@breathelife/frontend-tracking';

import { useDispatch } from '../../../../Hooks';
import { Application } from '../../../../Models/Application';
import { uploadAttachment, deleteAttachment } from '../../../../ReduxStore/Application/ApplicationOperations';
import { notificationSlice } from '../../../../ReduxStore/Notification/NotificationSlice';
import { getViewAttachmentUrl } from '../../../../Services/ApplicationsAttachmentsService';
import { FileAttachmentModal } from './FileAttachmentModal';

type FileAttachmentModalContainerProps = {
  application: Application;
  isOpen: boolean;
  closeModal: () => void;
};

export function FileAttachmentModalContainer(props: FileAttachmentModalContainerProps): ReactElement {
  const { isOpen, closeModal, application } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onFileUpload = useCallback(
    (file: File) => {
      if (!application) return;
      void dispatch(uploadAttachment(application.id, file));
    },
    [application, dispatch],
  );

  const onFileUploadError = useCallback(
    (file: File, errorMessage: string) => {
      TypewriterTracking.attachmentErrorOccurred({
        hashedId: hash(application.id),
        documentFormat: getDocumentFormat(file),
        error: errorMessage,
      });
    },
    [application.id],
  );

  const onViewAttachment = useCallback(
    async (attachmentId: string) => {
      try {
        const { redirectUrl } = await getViewAttachmentUrl(attachmentId);
        window.open(redirectUrl, '_blank')?.focus();
      } catch (err: any) {
        const entity = t('assistedApplication.attachmentUrl');
        dispatch(
          notificationSlice.actions.setError({
            message: t('notifications.failedToFetchEntity', { entity }),
            autoHideDuration: 5000,
          }),
        );
      }
    },
    [dispatch, t],
  );

  const onDeleteAttachment = useCallback(
    async (attachmentId: string) => {
      await dispatch(deleteAttachment(attachmentId));
    },
    [dispatch],
  );

  return (
    <FileAttachmentModal
      isOpen={isOpen}
      closeModal={closeModal}
      isUploadDisabled={application.submitted}
      onFileUpload={onFileUpload}
      onFileUploadError={onFileUploadError}
      onViewAttachment={onViewAttachment}
      onDeleteAttachment={onDeleteAttachment}
    />
  );
}
