import {
  isFieldPartIdentifier,
  isQuestionPartIdentifier,
  isSectionPartIdentifier,
  isSelectOptionPartIdentifier,
  isSubsectionPartIdentifier,
  PartIdentifier,
} from '@breathelife/types';

export function isPartIdentifierAChild(
  potentialChildPartIdentifier: PartIdentifier,
  potentialParentPartIdentifier: PartIdentifier,
): boolean {
  if (
    isSectionPartIdentifier(potentialParentPartIdentifier) &&
    (isSubsectionPartIdentifier(potentialChildPartIdentifier) ||
      isQuestionPartIdentifier(potentialChildPartIdentifier) ||
      isFieldPartIdentifier(potentialChildPartIdentifier) ||
      isSelectOptionPartIdentifier(potentialChildPartIdentifier))
  ) {
    return potentialParentPartIdentifier.sectionPartName === potentialChildPartIdentifier.sectionPartName;
  }
  if (
    isSubsectionPartIdentifier(potentialParentPartIdentifier) &&
    (isQuestionPartIdentifier(potentialChildPartIdentifier) ||
      isFieldPartIdentifier(potentialChildPartIdentifier) ||
      isSelectOptionPartIdentifier(potentialChildPartIdentifier))
  ) {
    return (
      potentialParentPartIdentifier.sectionPartName === potentialChildPartIdentifier.sectionPartName &&
      potentialParentPartIdentifier.subsectionPartName === potentialChildPartIdentifier.subsectionPartName
    );
  }
  if (
    isQuestionPartIdentifier(potentialParentPartIdentifier) &&
    (isFieldPartIdentifier(potentialChildPartIdentifier) || isSelectOptionPartIdentifier(potentialChildPartIdentifier))
  ) {
    return (
      potentialParentPartIdentifier.sectionPartName === potentialChildPartIdentifier.sectionPartName &&
      potentialParentPartIdentifier.subsectionPartName === potentialChildPartIdentifier.subsectionPartName &&
      potentialParentPartIdentifier.questionPartName === potentialChildPartIdentifier.questionPartName
    );
  }
  if (
    isFieldPartIdentifier(potentialParentPartIdentifier) &&
    isSelectOptionPartIdentifier(potentialChildPartIdentifier)
  ) {
    return (
      potentialParentPartIdentifier.sectionPartName === potentialChildPartIdentifier.sectionPartName &&
      potentialParentPartIdentifier.subsectionPartName === potentialChildPartIdentifier.subsectionPartName &&
      potentialParentPartIdentifier.questionPartName === potentialChildPartIdentifier.questionPartName &&
      potentialParentPartIdentifier.fieldPartName === potentialChildPartIdentifier.fieldPartName
    );
  }
  return false;
}
