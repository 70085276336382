import styled from '../../../../Styles/themed-styled-components';

import { Box, Grid } from '@breathelife/mui';
import { ActionButton } from '../../../../Components/Button/ActionButton';
import { Button } from '../../../../Components/Button/Button';

export const CtaContainer = styled(Box)`
  background-color: ${(props) => props.theme.colors.grey[20]};
`;

export const CtaButton = styled(Button)`
  padding: 6px 12px;
`;

export const StyledGrid = styled(Grid)`
  &&&& {
    .MuiTypography-root > span {
      color: ${(props) => props.theme.colors.grey[90]};
      font-size: ${(props) => props.theme.text.body5.size};
    }
    .MuiFormControlLabel-root {
      width: 100%;
    }
  }
`;

export const StyledActionButton = styled(ActionButton)`
  grid-column: 2/-1;
`;
