import { Box } from '@breathelife/mui';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { FilteredListEmpty } from '../../../../Assets/FilteredListEmpty';
import Typography from '../../../../Components/Typography';

import styled from '../../../../Styles/themed-styled-components';

import { emptyTableHeight } from '../../../../Models/Layout';

const EmptySearchResultContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: ${() => `calc(100vh - ${emptyTableHeight}px)`};
  min-height: 300px;
`;

export function EmptySearchResultView(): ReactElement {
  const { t } = useTranslation();

  return (
    <EmptySearchResultContainer>
      <Box mb={3}>
        <FilteredListEmpty role='img' />
      </Box>
      <Box mb={1.5}>
        <Typography variant='h2' grey={70}>
          {t('usersListTable.emptySearchResult.title')}
        </Typography>
      </Box>
      <Box maxWidth={300}>
        <Typography variant='body1' grey={60}>
          {t('usersListTable.emptySearchResult.body')}
        </Typography>
      </Box>
    </EmptySearchResultContainer>
  );
}
