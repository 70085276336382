import { ReactElement, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { AdminToolResourceName } from '@breathelife/types';
import { HistoryIcon } from '@breathelife/mui';

import { IconButton } from '../../Components/Controls/IconButton/IconButton';
import Urls from '../../Navigation/Urls';

type Props = {
  resourceId: string;
  resourceName: AdminToolResourceName;
};

export function LogButton({ resourceId, resourceName }: Props): ReactElement {
  const navigate = useNavigate();

  const redirectToLogsUrl = useCallback(() => {
    navigate(`${Urls.auditLogs.fullPath}?resourceName=${resourceName}&resourceId=${resourceId}`);
  }, [navigate, resourceName, resourceId]);

  return <IconButton $customSize='small' icon={<HistoryIcon />} onClick={redirectToLogsUrl} />;
}
