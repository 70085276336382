import _ from 'lodash';
import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ValidationError } from 'yup';
import styled from '../../../../Styles/themed-styled-components';

import { Box, Grid, FormControl, MenuItem, Select as MultipleSelect } from '@breathelife/mui';
import { Permission, UserCategory, UserType } from '@breathelife/types';
import { Input as TextInput, PhoneInput, StyledFormControlLabel } from '@breathelife/ui-components';

import { Select, SelectProps } from '../../../../Components/Select/Select';
import { getFieldValidationError } from '../../../../Helpers/inputValidation/form/user';
import { getOptionsFromEnum } from '../../../../Helpers/options';
import { userHasPermission } from '../../../../Helpers/user';
import { useCarrierContext, useSelector } from '../../../../Hooks';
// Not using UserRole from @breathelife/types because it is also including service role..
import { UserRole } from '../../../../Services/Auth0';
import { useAllUserGroups } from '../../../../ReactQuery/Admin/UserGroups/userGroups.queries';

export type UserFormState = {
  firstName: InputState;
  lastName: InputState;
  phoneNumber: InputState;
  userId: InputState;
  email: InputState;
  role: UserRole;
  category: UserCategory;
  type: UserType;
  picture?: string;
  isSso?: boolean;
  groupNames: string[];
};

export type InputState = {
  value: string;
  error?: ValidationError;
};

type UserFormProps = {
  userFormState: UserFormState;
  onFirstNameChange: (formData: InputState) => void;
  onLastNameChange: (formData: InputState) => void;
  onEmailChange: (formData: InputState) => void;
  onPhoneNumberChange: (formData: InputState) => void;
  onUserIdChange: (formData: InputState) => void;
  onRoleChange: (value: UserRole) => void;
  onCategoryChange: (value: UserCategory) => void;
  onTypeChange: (value: UserType) => void;
  onGroupsChange: (value: string[]) => void;
};

// styled HoC does not preserve Generic types

const StyledMultipleSelect = styled(MultipleSelect)`
  width: 100%;
`;

const StyledSelect = styled(Select)`
  width: 100%;
` as <T extends string | number = string>(props: SelectProps<T>) => ReactElement;

export function UserForm(props: UserFormProps): ReactElement | null {
  const { data: userGroupsData } = useAllUserGroups();

  const userGroupOptions = useMemo(() => {
    return userGroupsData?.map((group) => ({ label: group.name, value: group.name })) || [];
  }, [userGroupsData]);

  const { t } = useTranslation();
  const currentUserPermissions = useSelector((store) => store.leadPlatform.authentication?.user?.permissions);
  const {
    userFormState,
    onFirstNameChange,
    onLastNameChange,
    onEmailChange,
    onPhoneNumberChange,
    onUserIdChange,
    onRoleChange,
    onCategoryChange,
    onTypeChange,
    onGroupsChange,
  } = props;

  const { firstName, lastName, email, phoneNumber, userId, role, category, type, isSso, groupNames } = userFormState;

  const { ssoProfileFields } = useCarrierContext();

  let rolesOptions = getOptionsFromEnum<UserRole>(UserRole, 'user.role');
  if (!userHasPermission(currentUserPermissions, Permission.UserCreateSuperAdmin)) {
    rolesOptions = _.filter(rolesOptions, (option) => option.value !== UserRole.superAdmin);
  }
  const userCategoryOptions = getOptionsFromEnum<UserCategory>(UserCategory, 'user.category');
  const userTypeOptions = getOptionsFromEnum<UserType>(UserType, 'user.type');

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box mt={-0.5}>
            <TextInput
              fullWidth
              required
              disabled={isSso && ssoProfileFields.includes('firstName')}
              value={firstName.value}
              error={!!firstName.error}
              validationError={firstName.error}
              id='userManagement-fname'
              inputVariant='outlined'
              label={t('inputs.firstName')}
              onChange={(event) =>
                onFirstNameChange({
                  value: event.target.value,
                  error: getFieldValidationError('firstName', event.target.value),
                })
              }
              onBlur={() =>
                onFirstNameChange({
                  ...firstName,
                  error: getFieldValidationError('firstName', firstName.value),
                })
              }
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box mt={-0.5}>
            <TextInput
              fullWidth
              required
              disabled={isSso && ssoProfileFields.includes('lastName')}
              value={lastName.value}
              error={!!lastName.error}
              validationError={lastName.error}
              id='userManagement-lname'
              inputVariant='outlined'
              label={t('inputs.lastName')}
              onChange={(event) =>
                onLastNameChange({
                  value: event.target.value,
                  error: getFieldValidationError('lastName', event.target.value),
                })
              }
              onBlur={() =>
                onLastNameChange({
                  ...lastName,
                  error: getFieldValidationError('lastName', lastName.value),
                })
              }
            />
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box mt={-0.5}>
            <TextInput
              fullWidth
              required
              disabled={isSso && ssoProfileFields.includes('emailLogin')}
              value={email.value}
              error={!!email.error}
              validationError={email.error}
              id='userManagement-email'
              inputVariant='outlined'
              label={t('inputs.email')}
              onChange={(event) =>
                onEmailChange({
                  value: event.target.value.trim(),
                  error: getFieldValidationError('emailLogin', event.target.value),
                })
              }
              onBlur={() =>
                onEmailChange({
                  ...email,
                  error: getFieldValidationError('emailLogin', email.value),
                })
              }
            />
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box mt={-0.5}>
            <PhoneInput
              name='userManagement-phoneNumber'
              disabled={isSso && ssoProfileFields.includes('phoneNumber')}
              fullWidth
              inputVariant='outlined'
              label={t('inputs.phoneNumber')}
              validationError={phoneNumber.error}
              value={phoneNumber.value}
              onAnswerChange={(answer: string) =>
                onPhoneNumberChange({
                  value: answer,
                  error: getFieldValidationError('phoneNumber', answer),
                })
              }
            />
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box mt={-0.5}>
            <TextInput
              fullWidth
              disabled={isSso && ssoProfileFields.includes('customId')}
              value={userId.value}
              error={!!userId.error}
              validationError={userId.error}
              id='userManagement-customId'
              inputVariant='outlined'
              label={t('inputs.userId')}
              onChange={(event) =>
                onUserIdChange({
                  value: event.target.value,
                  error: getFieldValidationError('userId', event.target.value),
                })
              }
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box mt={-0.5}>
            <StyledSelect
              id='roles-select'
              disabled={isSso && ssoProfileFields.includes('roles')}
              label={t('inputs.role')}
              value={role}
              options={rolesOptions}
              onChange={onRoleChange}
            />
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box mt={-0.5}>
            <FormControl fullWidth variant='outlined'>
              <StyledFormControlLabel
                showError={false}
                control={
                  <StyledMultipleSelect
                    multiple
                    value={groupNames}
                    onChange={(e) => {
                      onGroupsChange(e.target.value as string[]);
                    }}
                  >
                    {userGroupOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </StyledMultipleSelect>
                }
                label={t('inputs.group')}
                labelPlacement='top'
              />
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box mt={-0.5}>
            <StyledSelect
              id='category-select'
              disabled={isSso && ssoProfileFields.includes('category')}
              label={t('inputs.category')}
              value={category}
              options={userCategoryOptions}
              onChange={onCategoryChange}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box mt={-0.5}>
            <StyledSelect
              id='type-select'
              disabled={isSso && ssoProfileFields.includes('type')}
              label={t('inputs.type')}
              value={type}
              options={userTypeOptions}
              onChange={onTypeChange}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
