import _ from 'lodash';
import { ReactElement, Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '../../../Styles/themed-styled-components';

import { Permission } from '@breathelife/types';

import { Icon } from '../../../Components/Icons';
import { MoreActionButton } from '../../../Components/MoreActionButton/MoreActionButton';
import { MenuAction } from '../../../Components/PopupMenu/PopupMenu';
import { userHasPermission } from '../../../Helpers/user';
import { useCarrierContext, useSelector } from '../../../Hooks';
import { Application } from '../../../Models/Application';
import { Lead } from '../../../Models/Lead';
import { isApplicationEligibleForCopy } from '../../../Helpers/applicationCopy/applicationCopy';

export const leadActionsPermissions = {
  leadEmail: [Permission.LeadInvitePrivate, Permission.LeadInviteEmail],
  leadAssign: [
    Permission.LeadAssignAll,
    Permission.LeadAssignGroup,
    Permission.LeadAssignMe,
    Permission.LeadUnassignAll,
    Permission.LeadUnassignMe,
  ],
  leadArchive: [Permission.LeadArchive],
  leadDelete: [Permission.LeadDelete],
  applicationCopy: [Permission.ApplicationCopyCreate],
};

type Props = {
  lead: Lead;
  onAssignClick: (lead: Lead) => void;
  onCopyClick: (application: Application) => void;
  onDeleteClick: (lead: Lead) => void;
  onSendEmailClick: (lead: Lead) => void;
  onToggleArchiveClick: (lead: Lead, userId: string) => void;
};

const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export function LeadMoreActionButton({
  lead,
  onAssignClick,
  onCopyClick,
  onDeleteClick,
  onSendEmailClick,
  onToggleArchiveClick,
}: Props): ReactElement {
  const { user } = useSelector((store) => store.leadPlatform.authentication);
  const { t } = useTranslation();
  const { isEmailActionEnabled, features } = useCarrierContext();

  const userPermissions = useSelector((store) => store.leadPlatform.authentication.user?.permissions);

  const userHasActionsPermissions = useMemo(() => {
    const allActionsPermissions = _.flow([_.values, _.flatten])(leadActionsPermissions);

    const userHasActionsPermissions = userHasPermission(allActionsPermissions, userPermissions ?? []);

    return userHasActionsPermissions;
  }, [userPermissions]);

  const application = lead.applications?.[0];

  const actions: MenuAction[] = useMemo(() => {
    const actions: MenuAction[] = [];
    if (!user) return actions;
    if (userHasPermission(user.permissions, leadActionsPermissions.leadEmail ?? [])) {
      const isSendNeedsAnalysisButtonEnabled = !!lead.assignedToId && isEmailActionEnabled(lead.status);
      actions.push({
        tag: 'MenuAction',
        onClick: () => {
          onSendEmailClick(lead);
        },
        icon: <Icon name='email' />,
        label: t('cta.sendNeedsAnalysis'),
        disabled: !isSendNeedsAnalysisButtonEnabled,
      });
    }
    if (
      features.applicationCopy?.enabled &&
      application &&
      userHasPermission(user.permissions, leadActionsPermissions.applicationCopy ?? [])
    ) {
      const applicationCopyEligibility = isApplicationEligibleForCopy(application);
      const isCopyButtonDisabledForApplication =
        !applicationCopyEligibility.eligibility ||
        (features.applicationCopy.disabledForLineOfBusiness || []).includes(application.lineOfBusiness);
      actions.push({
        tag: 'MenuAction',
        onClick: () => {
          onCopyClick(application);
        },
        icon: <Icon name='copy' />,
        label: t('cta.copyApplication'),
        disabled: isCopyButtonDisabledForApplication,
        tooltip:
          isCopyButtonDisabledForApplication && applicationCopyEligibility.reason
            ? t(`${applicationCopyEligibility.reason}`, {
                refNoIncrement: application.refNoIncrement,
                thresholdInDays: features.applicationCopy.applicationCopyThresholdInDays,
              })
            : '',
      });
    }
    if (userHasPermission(user?.permissions, leadActionsPermissions.leadAssign ?? [])) {
      actions.push({
        tag: 'MenuAction',
        onClick: () => {
          onAssignClick(lead);
        },
        icon: <Icon name='assignTo' />,
        label: t('cta.assignTo'),
      });
    }
    if (userHasPermission(user?.permissions, leadActionsPermissions.leadArchive ?? [])) {
      actions.push({
        tag: 'MenuAction',
        onClick: () => {
          onToggleArchiveClick(lead, user.auth0Id);
        },
        icon: lead.archived ? <Icon name='unarchive' /> : <Icon name='archive' />,
        label: lead.archived ? t('cta.unarchive') : t('cta.archive'),
      });
    }
    if (lead.archived && userHasPermission(user?.permissions, leadActionsPermissions.leadDelete ?? [])) {
      actions.push({
        tag: 'MenuAction',
        onClick: () => {
          onDeleteClick(lead);
        },
        icon: <Icon name='delete' />,
        label: t('cta.delete'),
      });
    }
    return actions;
  }, [
    lead,
    t,
    user,
    onSendEmailClick,
    isEmailActionEnabled,
    onToggleArchiveClick,
    onDeleteClick,
    onAssignClick,
    application,
    onCopyClick,
    features.applicationCopy,
  ]);

  return userHasActionsPermissions ? (
    <ActionContainer>
      <MoreActionButton actions={actions} />
    </ActionContainer>
  ) : (
    <Fragment />
  );
}
