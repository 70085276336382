import styled from '../../Styles/themed-styled-components';
import { Image } from '../../Components/Images/Image';

export const PageLayoutGridWrapper = styled('div')`
  display: flex;
  height: 100vh;
`;

export const Sidebar = styled('div')`
  width: 200px;
  min-width: 200px;
  padding-top: 24px;
  background-color: ${({ theme }) => theme.colors.grey[20]};
  overflow: auto;
  border-right: 1px solid ${({ theme }) => theme.colors.grey[40]};
`;

export const PageContent = styled('div')`
  display: flex;
  flex-direction: column;
  justify-items: stretch;
  align-items: stretch;
  overflow: hidden;
  height: 100vh;
  flex-grow: 1;
`;

export const PageHeader = styled('div')`
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[40]};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 25px;
  min-height: 64px;
`;

export const LogoWrapper = styled('div')`
  width: 100%;
  padding: 0 24px;
  margin-bottom: 24px;
  height: 40px;
`;

export const Logo = styled(Image)`
  width: 100%;
  height: 100%;
  & img {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const ContentWrapper = styled('div')`
  overflow: auto;
  display: flex;
  flex-grow: 1;
`;

export const BackButtonWrapper = styled('div')`
  padding: 0 66px;
`;
