import { TextareaFieldBlueprint } from '@breathelife/types';

import { FieldDataSetterProps } from '../../../Helpers/helperTypes';
import { TextareaFieldSetters, useTextareaFieldData } from '../useTextareaFieldData';
import { useCommonFieldDataSetters } from './useCommonFieldDataSetters';

export function TextareaFieldDataSetter({
  initialData,
  dataLabelOptions,
  ...props
}: FieldDataSetterProps<TextareaFieldBlueprint, TextareaFieldSetters>): null {
  const { data, setters, validationOptions, defaultValueValidationError } = useTextareaFieldData({
    initialData,
    dataLabelOptions,
  });

  useCommonFieldDataSetters<TextareaFieldBlueprint, TextareaFieldSetters>({
    ...props,
    data,
    setters,
    validationOptions,
    defaultValueValidationError,
    getIsValidBlueprint: (blueprint) => !!blueprint.validateAs,
  });
  return null;
}
