import { ReactElement } from 'react';

import { SvgIconProps } from '../../Components/Icons';

export function RightArrowIcon(props: SvgIconProps): ReactElement {
  return (
    <svg
      fill='none'
      height='16'
      stroke='#757575'
      viewBox='0 0 16 16'
      width='16'
      xmlns='http://www.w3.org/2000/svg'
      {...props.svgProps}
    >
      <g strokeLinecap='round' strokeLinejoin='round'>
        <path d='m15.5 8h-15' />
        <path d='m8.5 1 7 7-7 7' />
      </g>
    </svg>
  );
}
