import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { Questionnaire } from '@breathelife/types';
import { logger } from '@breathelife/monitoring-frontend';
import { QueryId } from '../../ReactQuery/common/common.types';

import { useDispatch } from 'react-redux';
import { notificationSlice } from '../../ReduxStore/Notification/NotificationSlice';
import { useTranslation } from 'react-i18next';
import ApiService from '../../Services/ApiService';
import { getQuestionnaireByVersionIdForProUser } from '../../Services/QuestionnaireService';

export function useGetQuestionnaires(options?: UseQueryOptions<Questionnaire[]>): UseQueryResult<Questionnaire[]> {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return useQuery<Questionnaire[]>(
    QueryId.proUserQuestionnaires,
    async () => {
      const response = await ApiService.getUserQuestionnaires();
      return response.data;
    },
    {
      ...options,
      onError(err) {
        dispatch(
          notificationSlice.actions.setError({
            message: t('notifications.failedToFetchQuestionnaires'),
          }),
        );
        logger.error(err);
        options?.onError?.(err);
      },
    },
  );
}

export function useGetQuestionnaireForQuestionnaireVersionId(
  questionnaireVersionId?: string,
  options?: UseQueryOptions<Questionnaire | null>,
): UseQueryResult<Questionnaire | null> {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return useQuery<Questionnaire | null>(
    [QueryId.proUserQuestionnaires, { questionnaireVersionId }],
    async () => {
      if (!questionnaireVersionId) {
        return null;
      }
      return getQuestionnaireByVersionIdForProUser(questionnaireVersionId);
    },
    {
      ...options,
      onError(err) {
        dispatch(
          notificationSlice.actions.setError({
            message: t('notifications.failedToFetchQuestionnairesForQuestionnaireVersionId'),
          }),
        );
        logger.error(err);
        options?.onError?.(err);
      },
    },
  );
}
