import i18next from 'i18next';
import _ from 'lodash';
import { Fragment, MouseEvent, ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, ClickAwayListener } from '@breathelife/mui';
import { AgentLicenseVerificationStatus, VersionedAnswers } from '@breathelife/types';
import { ImageTypes, MessageType } from '@breathelife/ui-components';

import { Icon } from '../../../../Components/Icons';
import Typography from '../../../../Components/Typography';
import {
  getOwnerProvincesOrStates,
  refreshLicenseValidation,
} from '../../../../Helpers/assistedApplication/licenseValidation';
import {
  useAAQuestionnaireVersionContext,
  useCarrierContext,
  useDispatch,
  useNavigation,
  useSelector,
} from '../../../../Hooks';
import { useGetAgentLicensesQuery } from '../../../../ReactQuery/AgentLicense/agentLicense.queries';
import { useGetApplicationQuery } from '../../../../ReactQuery/Application/application.queries';
import { notificationSlice } from '../../../../ReduxStore/Notification/NotificationSlice';
import { StyledPopper } from '../Styles';
import { PopperBox, StyledBadge, TextContainer } from './Styles';
import { RepeatedIndices } from '@breathelife/questionnaire-engine';

const verificationStatusMessageLookup = {
  [AgentLicenseVerificationStatus.valid]: {
    messageType: MessageType.success,
    messageKey: 'popper.licenseVerification.status.valid',
    autoHideDuration: 3000,
  },
  [AgentLicenseVerificationStatus.invalidJurisdiction]: {
    messageType: MessageType.warning,
    messageKey: 'popper.licenseVerification.status.invalidJurisdiction',
    autoHideDuration: 3000,
  },
  [AgentLicenseVerificationStatus.expired]: {
    messageType: MessageType.warning,
    messageKey: 'popper.licenseVerification.status.expired',
    autoHideDuration: 3000,
  },
};

export function LicenseVerificationPopper(): ReactElement {
  const authentication = useSelector((store) => store.leadPlatform.authentication);

  const dispatch = useDispatch();
  const { applicationId } = useNavigation();
  const { questionnaireEngine } = useAAQuestionnaireVersionContext();
  const { data: application } = useGetApplicationQuery(applicationId);
  const { data: licensesData } = useGetAgentLicensesQuery(authentication.user?.customId);
  const [prevProvincesOrStates, setPrevProvincesOrStates] = useState<string[] | null>(null);
  const isFirstRender = useRef(true);
  const { features } = useCarrierContext();

  const [licenseVerificationStatus, setLicenseVerificationStatus] = useState(AgentLicenseVerificationStatus.neutral);

  useEffect(() => {
    if (!licensesData || !application?.answers) {
      return;
    }

    const versionedAnswers = new VersionedAnswers({ v1: application.answers, v2: application.answersV2 });

    const getAnswer = (answers: VersionedAnswers, nodeId: string, repeatedIndices?: RepeatedIndices): unknown =>
      questionnaireEngine.getAnswer(answers, nodeId, repeatedIndices);

    const provincesOrStates = getOwnerProvincesOrStates(features, getAnswer, versionedAnswers);

    if (!_.isEqual(_.sortBy(prevProvincesOrStates), _.sortBy(provincesOrStates))) {
      const refreshedLicenseValidationStatus = refreshLicenseValidation(provincesOrStates, licensesData);

      setLicenseVerificationStatus(refreshedLicenseValidationStatus);
      setPrevProvincesOrStates(provincesOrStates);

      if (!isFirstRender.current && refreshedLicenseValidationStatus !== AgentLicenseVerificationStatus.neutral) {
        const notificationInfo = verificationStatusMessageLookup[refreshedLicenseValidationStatus];
        dispatch(
          notificationSlice.actions.setNotification({
            type: notificationInfo.messageType,
            message: i18next.t(notificationInfo.messageKey),
            autoHideDuration: notificationInfo.autoHideDuration,
          }),
        );
      }
    }
    if (isFirstRender) isFirstRender.current = false;
  }, [
    application?.answers,
    application?.answersV2,
    features,
    licensesData,
    prevProvincesOrStates,
    dispatch,
    questionnaireEngine,
  ]);

  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const onOpenLicenseVerificationPopper = (event: MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const isOpen = !!anchorEl;
  const popperId = isOpen ? 'licenseverification-popper' : undefined;

  const handleClickAway = (): void => {
    setAnchorEl(null);
  };

  const isLicenseVerificationStatusValid = licenseVerificationStatus === AgentLicenseVerificationStatus.valid;

  return (
    <Fragment>
      <Box mr={2}>
        <StyledBadge
          badgeContent={isLicenseVerificationStatusValid ? '✓' : '!'}
          aria-label={t(`popper.licenseVerification.status.${licenseVerificationStatus}`)}
          color={isLicenseVerificationStatusValid ? 'primary' : 'error'}
        >
          <Button
            color='secondary'
            variant='outlined'
            data-testid='license_verification_button'
            onClick={onOpenLicenseVerificationPopper}
            startIcon={<Icon name='licensesIcon' imageType={ImageTypes.decorative} />}
          >
            {t('assistedApplication.licenseVerification')}
          </Button>
        </StyledBadge>
      </Box>
      {anchorEl && (
        <StyledPopper id={popperId} isOpen={isOpen} anchorEl={anchorEl} placement='auto'>
          <ClickAwayListener onClickAway={handleClickAway}>
            <PopperBox>
              <Box ml={2} alignContent='center' data-testid='license_verification_text'>
                <TextContainer>
                  <Typography variant='body3' grey={90}>
                    {t(`popper.licenseVerification.status.${licenseVerificationStatus}`)}
                  </Typography>
                </TextContainer>
              </Box>
            </PopperBox>
          </ClickAwayListener>
        </StyledPopper>
      )}
    </Fragment>
  );
}
