import { Fragment, ReactElement, useContext } from 'react';
import { QuestionnaireEditorContainer } from './QuestionnaireEditorContainer';
import { QuestionnaireEditorHeader } from './QuestionnaireEditorHeaderContainer';
import { QuestionnaireVersionDataContext } from '../ContextProvider/QuestionnaireVersionDataContextProvider';
import LoadingView from '../../../../Components/LoadingView/LoadingView';

export function QuestionnaireEditorView(): ReactElement {
  const { isLoading } = useContext(QuestionnaireVersionDataContext);
  return isLoading ? (
    <LoadingView />
  ) : (
    <Fragment>
      <QuestionnaireEditorHeader />
      <QuestionnaireEditorContainer />
    </Fragment>
  );
}
