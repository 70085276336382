import { ReactElement } from 'react';

import { Permission } from '@breathelife/types';
import { RestrictedToPermissions } from '@breathelife/ui-components';

import { useSelector } from '../../Hooks';

type Props = {
  requiredPermissions?: Permission[];
  children: ReactElement;
};

export const RestrictedToUserPermissions = (props: Props): ReactElement => {
  const userPermissions =
    useSelector((store) => {
      return store.leadPlatform.authentication.user?.permissions;
    }) ?? [];

  return (
    <RestrictedToPermissions
      requiredPermissions={props.requiredPermissions}
      applicablePermissions={userPermissions}
      {...props}
    />
  );
};
