import { Box } from '@breathelife/mui';
import { ReactElement } from 'react';

import { ESignCeremonyStatus, ESignSigner2FAInfo } from '@breathelife/types';
import { Loader } from '@breathelife/ui-components';

import { Drawer } from '../../../../Components/Drawer/Drawer';
import { useNavigation, useSelector, useCarrierContext } from '../../../../Hooks';
import { RequiredFile } from '../../../../Hooks/Application';
import { useGetApplicationQuery } from '../../../../ReactQuery/Application/application.queries';
import { useGetESignCeremonyQuery } from '../../../../ReactQuery/ESignCeremony/eSignCeremony.queries';
import { ESignatureDetailsHeader } from './ESignatureDetailsHeader';
import { SignerInfoView } from './SignerInfo/SignerInfoView';

type ESignatureDetailsViewProps = {
  isLoading: boolean;
  isOpen: boolean;
  onClose: () => void;
  onClickSend: () => void;
  onClickCancel: () => void;
  onUpdateSigner: (signer: ESignSigner2FAInfo, idx: number) => void;
  requiredFiles: RequiredFile[];
  participants: ESignSigner2FAInfo[];
};

export function ESignatureDetailsView(props: ESignatureDetailsViewProps): ReactElement {
  const isSubmitting = useSelector((state) => state.leadPlatform.submission.isSubmittingApplication);
  const {
    isLoading: isLoadingProp,
    isOpen,
    onClose,
    onClickSend,
    onClickCancel,
    onUpdateSigner,
    requiredFiles,
    participants,
  } = props;
  const { features } = useCarrierContext();
  const ENABLE_DECOUPLE_ESIGN_FLOW = !!features.enableDecoupleESignFlow?.enabled;

  const { applicationId } = useNavigation();
  const { data: application } = useGetApplicationQuery(applicationId);
  const { data: eSignCeremony, isLoading: isESignCeremonyLoading } = useGetESignCeremonyQuery(
    applicationId,
    features.signatureType,
    {
      refetchInterval: (eSignCeremony) => {
        if (!ENABLE_DECOUPLE_ESIGN_FLOW || eSignCeremony?.status !== ESignCeremonyStatus.IN_PROGRESS) {
          return false;
        }
        return 3000;
      },
    },
  );

  const isLoading = isESignCeremonyLoading || isLoadingProp || isSubmitting;

  if (!application) {
    return <Loader />;
  }

  const isEditMode =
    eSignCeremony?.status === ESignCeremonyStatus.DRAFT ||
    (ENABLE_DECOUPLE_ESIGN_FLOW &&
      (eSignCeremony?.status === ESignCeremonyStatus.IN_PROGRESS ||
        eSignCeremony?.status === ESignCeremonyStatus.READY));

  return (
    <Drawer open={isOpen} onClose={onClose}>
      {isOpen && (
        <Box p={4}>
          <ESignatureDetailsHeader
            isLoading={isLoading}
            status={eSignCeremony?.status}
            onClose={onClose}
            onClickSend={onClickSend}
            onClickCancel={onClickCancel}
            requiredFiles={requiredFiles}
          />
          <SignerInfoView
            eSignCeremony={eSignCeremony}
            participants={participants}
            isEditMode={isEditMode}
            isLoading={isLoading}
            onUpdateSigner={onUpdateSigner}
            application={application}
            requiredFiles={requiredFiles}
          />
        </Box>
      )}
    </Drawer>
  );
}
