import { useState } from 'react';

import { AgreeFieldBlueprint, BooleanFieldValidation, FieldBlueprint, FieldTypes, Language } from '@breathelife/types';
import { AutocompleteOption } from '@breathelife/ui-components';

import {
  FieldDataFromBlueprintWithValidateAs,
  FieldDataHookReturnValueWithValidationOptions,
  FieldSettersValidateAs,
  LanguageDependentSetter,
  useCastBlueprintToFieldType,
} from '../../Helpers/helperTypes';
import { BaseFieldData, BaseFieldSetters, useBaseFieldData } from './useBaseFieldData';
import { useValidateAsData } from './useValidateAsData';

export type AgreeFieldData = FieldDataFromBlueprintWithValidateAs<
  AgreeFieldBlueprint,
  'confirmedLabel' | 'modalText' | 'modalHeader'
> &
  BaseFieldData;

export type AgreeFieldSetters = FieldSettersValidateAs<AgreeFieldData> & {
  setModalHeader: LanguageDependentSetter;
  setModalText: LanguageDependentSetter;
  setConfirmedLabel: LanguageDependentSetter;
} & BaseFieldSetters;

const defaultProperties = {
  validateAs: BooleanFieldValidation.boolean,
} as const;

export function useAgreeFieldData({
  initialData,
  dataLabelOptions,
}: {
  initialData?: Partial<FieldBlueprint>;
  dataLabelOptions: AutocompleteOption[];
}): FieldDataHookReturnValueWithValidationOptions<AgreeFieldBlueprint, AgreeFieldSetters> {
  const initialDataAsAgreeField = useCastBlueprintToFieldType<AgreeFieldBlueprint>(FieldTypes.agree, initialData);

  const [modalHeaderFr, setModalHeaderFr] = useState<AgreeFieldData['modalHeader']['fr']>(
    initialDataAsAgreeField?.modalHeader?.fr || '',
  );
  const [modalHeaderEn, setModalHeaderEn] = useState<AgreeFieldData['modalHeader']['en']>(
    initialDataAsAgreeField?.modalHeader?.en || '',
  );
  const [modalTextFr, setModalTextFr] = useState<AgreeFieldData['modalText']['fr']>(
    initialDataAsAgreeField?.modalText?.fr || '',
  );
  const [modalTextEn, setModalTextEn] = useState<AgreeFieldData['modalText']['en']>(
    initialDataAsAgreeField?.modalText?.en || '',
  );
  const [confirmedLabelFr, setConfirmedLabelFr] = useState<AgreeFieldData['confirmedLabel']['fr']>(
    initialDataAsAgreeField?.confirmedLabel?.en || '',
  );
  const [confirmedLabelEn, setConfirmedLabelEn] = useState<AgreeFieldData['confirmedLabel']['en']>(
    initialDataAsAgreeField?.confirmedLabel?.en || '',
  );

  const { data, setters } = useBaseFieldData({ initialData, dataLabelOptions });

  const {
    data: validateAsData,
    setters: validateAsSetters,
    validationOptions,
  } = useValidateAsData<AgreeFieldBlueprint>({
    fieldType: FieldTypes.agree,
    initialValidateAs: defaultProperties.validateAs,
  });

  const setModalHeader: LanguageDependentSetter = (language, value) => {
    switch (language) {
      case Language.en:
        setModalHeaderEn(value);
        break;
      case Language.fr:
        setModalHeaderFr(value);
        break;
    }
  };

  const setModalText: LanguageDependentSetter = (language, value) => {
    switch (language) {
      case Language.en:
        setModalTextEn(value);
        break;
      case Language.fr:
        setModalTextFr(value);
        break;
    }
  };

  const setConfirmedLabel: LanguageDependentSetter = (language, value) => {
    switch (language) {
      case Language.en:
        setConfirmedLabelEn(value);
        break;
      case Language.fr:
        setConfirmedLabelFr(value);
        break;
    }
  };

  return {
    data: {
      fieldType: FieldTypes.agree,
      ...data,
      modalHeader: {
        en: modalHeaderEn,
        fr: modalHeaderFr,
      },
      modalText: {
        en: modalTextEn,
        fr: modalTextFr,
      },
      confirmedLabel: {
        en: confirmedLabelEn,
        fr: confirmedLabelFr,
      },
      ...validateAsData,
    },
    setters: {
      setModalHeader,
      setModalText,
      setConfirmedLabel,
      ...setters,
      ...validateAsSetters,
    },
    validationOptions,
  };
}
