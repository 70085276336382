import { ReactElement } from 'react';

import { SvgIconProps } from '../../Components/Icons';

export function CirclefilledWarningIcon(props: SvgIconProps): ReactElement {
  return (
    <svg
      fill='#757575'
      width='16'
      height='16'
      viewBox='0 0 20 20'
      xmlns='http://www.w3.org/2000/svg'
      {...props.svgProps}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.2104 10C20.2104 15.5228 15.7333 20 10.2104 20C4.6876 20 0.210449 15.5228 0.210449 10C0.210449 4.47715 4.6876 0 10.2104 0C15.7333 0 20.2104 4.47715 20.2104 10ZM19.2104 10C19.2104 14.9706 15.181 19 10.2104 19C5.23989 19 1.21045 14.9706 1.21045 10C1.21045 5.02944 5.23989 1 10.2104 1C15.181 1 19.2104 5.02944 19.2104 10Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.2104 19C15.181 19 19.2104 14.9706 19.2104 10C19.2104 5.02944 15.181 1 10.2104 1C5.23989 1 1.21045 5.02944 1.21045 10C1.21045 14.9706 5.23989 19 10.2104 19Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.2339 6.38862C11.2339 5.89785 10.7861 5.5 10.2338 5.5C9.6815 5.5 9.23376 5.89785 9.23376 6.38862V10.6114C9.23376 11.1021 9.71137 11.5 10.2338 11.5C10.7563 11.5 11.2339 11.1021 11.2339 10.6114V6.38862ZM11.2104 13.5C11.2104 14.0523 10.7627 14.5 10.2104 14.5C9.65816 14.5 9.21045 14.0523 9.21045 13.5C9.21045 12.9477 9.65816 12.5 10.2104 12.5C10.7627 12.5 11.2104 12.9477 11.2104 13.5Z'
        fill='white'
      />
    </svg>
  );
}
