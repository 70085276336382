import { ReactElement } from 'react';

import { SvgIconProps } from '../../Components/Icons';

export function ProfileIcon(props: SvgIconProps): ReactElement {
  return (
    <svg stroke='#757575' fill='#f6f6f6' height='16' width='16' xmlns='http://www.w3.org/2000/svg' {...props.svgProps}>
      <g strokeLinecap='round' strokeLinejoin='round'>
        <path d='M2.5.5h11a1 1 0 011 1v13a1 1 0 01-1 1h-11a1 1 0 01-1-1v-13a1 1 0 011-1z' />
        <path d='M8 3.315a2.74 2.74 0 110 5.48 2.74 2.74 0 010-5.48zm4.5 9.37a5.087 5.087 0 00-9 0' />
      </g>
    </svg>
  );
}
