import _ from 'lodash';
import { Dispatch, ReactElement, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, ExpandMoreIcon, Input } from '@breathelife/mui';
import {
  ColorRanges,
  FullColorGradient,
  PartialColorGradient,
  PartialColorRangesValues,
  Theme,
} from '@breathelife/types';

import Typography from '../../../Components/Typography';
import { validateColorHex } from '../../../Helpers/inputValidation/form/theme';
import styled, { css } from '../../../Styles/themed-styled-components';

const Color = styled(Box)<{ $hexColor: string }>`
  background-color: ${(props) => props.$hexColor};
  width: 30px;
  height: 30px;
  border-radius: 50%;
`;

const ColorItem = styled(Box)<{ $highlight: boolean }>`
  padding: 4px 16px;
  border-radius: 4px;

  ${(props) =>
    props.$highlight &&
    css`
      background-color: ${props.theme.colors.grey[20]};
    `}
`;

const TextInput = styled(Input)`
  border-radius: 4px;

  &.Mui-error {
    border: 1px solid ${(props) => props.theme.colors.red[40]};
  }

  ::before,
  ::after {
    display: none;
  }
`;

const SmallColor = styled(Box)<{ $hexColor: string }>`
  background-color: ${(props) => props.$hexColor};
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-left: 8px;
`;

type Props = {
  colorRanges: ColorRanges;
  updatedThemeData: Partial<Theme>;
  setUpdatedThemeData: Dispatch<SetStateAction<Partial<Theme>>>;
};

export function ColorRangesEditView({
  colorRanges: colorRangesProp,
  updatedThemeData,
  setUpdatedThemeData,
}: Props): ReactElement {
  const [colorRanges, setColorRanges] = useState<ColorRanges>(colorRangesProp);
  const { t } = useTranslation();

  useEffect(() => {
    setColorRanges(colorRangesProp);
  }, [colorRangesProp]);

  const updateColorRanges = useCallback(
    (key: keyof ColorRanges) => {
      return (updatedColorGradient: FullColorGradient | PartialColorGradient) => {
        const newColorRanges = {
          ...colorRanges,
          [key]: updatedColorGradient,
        };
        setColorRanges(newColorRanges);
        setUpdatedThemeData({ ...updatedThemeData, colorRanges: newColorRanges });
      };
    },
    [updatedThemeData, colorRanges, setUpdatedThemeData],
  );

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1c-content' id='panel1c-header'>
        <Box display='flex' flexDirection='row' alignItems='center'>
          <Typography variant='h2' style={{ marginRight: '8px' }}>
            {t('admin.themeManagement.colors.title')}
          </Typography>
          <SmallColor $hexColor={colorRanges.primary.default} />
          <SmallColor $hexColor={colorRanges.secondary.default} />
          <SmallColor $hexColor={colorRanges.tertiary.default} />
        </Box>
      </AccordionSummary>
      <Divider />
      <AccordionDetails>
        <Box width='100%' display='flex' flexDirection='row' alignItems='center' justifyContent='space-evenly'>
          <div>
            <Box mb={2} ml={2}>
              <Typography variant='h3'>{t('admin.themeManagement.colors.primary')}</Typography>
            </Box>
            <EditableColorRange colorGradient={colorRanges.primary} onChange={updateColorRanges('primary')} />
          </div>
          <Divider style={{ width: '1px' }} orientation='vertical' />

          <div>
            <Box mb={2} ml={2}>
              <Typography variant='h3'>{t('admin.themeManagement.colors.secondary')}</Typography>
            </Box>

            <EditableColorRange colorGradient={colorRanges.secondary} onChange={updateColorRanges('secondary')} />
          </div>
          <Divider style={{ width: '1px' }} orientation='vertical' />

          <div>
            <Box mb={2} ml={2}>
              <Typography variant='h3'>{t('admin.themeManagement.colors.tertiary')}</Typography>
            </Box>

            <EditableColorRange colorGradient={colorRanges.tertiary} onChange={updateColorRanges('tertiary')} />
          </div>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

function EditableColorRange(props: {
  colorGradient: PartialColorGradient;
  onChange: (updatedColorGradient: PartialColorGradient) => void;
}): ReactElement | null {
  if (!props.colorGradient) return null;

  const reversedColorGradient = _.reverse(Object.keys(props.colorGradient));

  return (
    <div>
      {reversedColorGradient.map((key) => {
        const color = props.colorGradient?.[key as (typeof PartialColorRangesValues)[number]];
        return (
          <ColorItem display='flex' alignItems='center' key={key} $highlight={key === 'default'}>
            <Box minWidth='80px'>
              <span>{key}:</span>
            </Box>
            <Color mx={1.5} $hexColor={color} />
            <EditableColor
              color={color}
              onChange={(updatedColor) => {
                const updatedColorGradient = _.clone(props.colorGradient) as PartialColorGradient;
                updatedColorGradient[key as keyof PartialColorGradient] = updatedColor;
                props.onChange(updatedColorGradient);
              }}
            />
          </ColorItem>
        );
      })}
    </div>
  );
}

function EditableColor(props: { color: string; onChange: (color: string) => void }): ReactElement {
  const [hasError, setHasError] = useState(Boolean(validateColorHex(props.color)));

  return (
    <Box ml={0.5}>
      <TextInput
        style={{ width: '70px', height: '30px' }}
        value={props.color}
        onChange={(event) => {
          const value = event.target.value;
          setHasError(Boolean(validateColorHex(value)));
          props.onChange(value);
        }}
        error={hasError}
      />
    </Box>
  );
}
