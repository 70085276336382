import { ReactElement } from 'react';

import { SvgIconProps } from '../../Components/Icons';

export function FilesIcon(props: SvgIconProps): ReactElement {
  return (
    <svg
      fill='#f6f6f6'
      stroke='#757575'
      height='16'
      viewBox='0 0 16 16'
      width='16'
      xmlns='http://www.w3.org/2000/svg'
      {...props.svgProps}
    >
      <g strokeLinecap='round' strokeLinejoin='round'>
        <path
          d='m15.524 8.28885c.019-.09649.0164-.19598-.0076-.29134s-.0688-.18423-.1312-.26024c-.0623-.07602-.1408-.13729-.2296-.17943-.0889-.04215-.1859-.06411-.2843-.06433h-7.88529c-.15346-.00027-.30212-.05348-.4209-.15065-.11878-.09716-.20041-.23232-.2311-.38268l-.18467-.92733c-.03069-.15036-.11231-.28553-.23109-.38269s-.26745-.15037-.4209-.15065h-4.29734c-.0981.00061-.19487.02286-.283384.06518-.088516.04231-.166602.10363-.228687.1796-.062084.07597-.106637.1647-.130479.25987-.023841.09516-.026385.19442-.007448.29069l1.728668 8.66995c.03069.1504.11231.2856.2311.3827.11878.0972.26744.1504.4209.1507h10.61132c.1535-.0003.3021-.0535.4209-.1507.1188-.0971.2004-.2323.2311-.3827.2727-1.3713 1.006-5.05262 1.33-6.67595z'
          fill='#f6f6f6'
        />
        <path d='m3.03735 3.49878.01334-2.33333c0-.176815.07023-.346384.19526-.471409.12502-.125024.29459-.195262.4714-.195262h8.64135c.1768 0 .3464.070238.4714.195262.125.125025.1953.294594.1953.471409l.014 4.33333' />
        <path d='m5.04535 2.49341 5.49195.00533' />
        <path d='m8.03735 4.49878h2.50005' />
      </g>
    </svg>
  );
}
