import _ from 'lodash';
import { Box } from '@breathelife/mui';
import { SetStateAction, ReactElement, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigationType } from 'react-router-dom';
import dayjs from 'dayjs';
import { getAge } from '@breathelife/date-helpers';
import { hash } from '@breathelife/hash';
import { isRenderingQuestionnaireComplete } from '@breathelife/questionnaire-engine';
import { TypewriterTracking } from '@breathelife/frontend-tracking';
import {
  LocalizedInsuranceProduct,
  Permission,
  RenderingType,
  SignatureType,
  ESignSigner2FAInfo,
  ESignCeremonyStatus,
  ApplicationMode,
  AgeRoundingType,
  TimeMeasurementUnits,
  Language,
  ProductsWidgetFeatureType,
  VersionedAnswers,
  InstanceScope,
  QuoteInfo,
} from '@breathelife/types';
import { GoogleMapsHelmet } from '@breathelife/ui-components';

import { MemoizedAssistedApplicationHeader as AssistedApplicationHeader } from '../../Components/AssistedApplication/AssistedApplicationView/AssistedApplicationHeader';
import { MemoizedAssistedApplicationView as AssistedApplicationView } from '../../Components/AssistedApplication/AssistedApplicationView/AssistedApplicationView';
import * as ProductsOperations from '../../ReduxStore/Products/ProductsOperations';
import { RestrictedToUserPermissions } from '../Restricted/RestrictedToUserPermissions';
import { AssistedApplicationContext } from '../../Context/AssistedApplicationContext';
import { getApplicantFullName, getInsuredDateOfBirth, SectionGroupId } from '../../Helpers/questionnaireAnswers';
import {
  useCarrierContext,
  useLocale,
  useModalState,
  useSelector,
  useDispatch,
  useAAQuestionnaireVersionContext,
  useNavigation,
} from '../../Hooks';
import { Application } from '../../Models/Application';
import { useSubmitInsuranceApplication } from '../../ReactQuery/Application/application.mutations';
import { useGetApplicationQuery } from '../../ReactQuery/Application/application.queries';
import {
  useFetchQuotesMutation,
  useSaveAssistedApplicationAnswersMutation,
  SaveAssistedApplicationAnswersParams,
} from '../../ReactQuery/AssistedApplication/assistedApplication.mutations';
import {
  useSendESignCeremonyMutation,
  useUpdateESignCeremony,
} from '../../ReactQuery/ESignCeremony/eSignCeremony.mutations';
import { useGetESignCeremonyQuery } from '../../ReactQuery/ESignCeremony/eSignCeremony.queries';
import { useGetPointOfSaleDecisions } from '../../ReactQuery/PointOfSaleDecisions/pointOfSaleDecisions.queries';
import { QueryId } from '../../ReactQuery/common/common.types';
import {
  processApplicationSubmission,
  processPreviewApplicationSubmission,
} from '../../ReduxStore/Application/ApplicationOperations';
import { notificationSlice } from '../../ReduxStore/Notification/NotificationSlice';
import { SkipLink } from '../SkipLink/SkipLink';
import { DocumentsDrawer } from './Drawers/Documents/DocumentsDrawer';
import { ESignatureDetailsContainer } from './Drawers/ESignatureDetails/ESignatureDetailsContainer';
import { InfoMessageTrackESignatureModal } from './Modals/ESignature/InfoMessageTrackESignatureModal';
import { SubmitPaperAppModal } from './Modals/SubmitPaperApp/SubmitPaperAppModal';
import { useAutoSaveAnswers } from './useAutoSaveAnswers';
import { useStoredSectionState } from './useStoredSectionState';
import { logger } from '@breathelife/monitoring-frontend';
import { shouldRefreshAllAnswers } from '../../Helpers/assistedApplication/answers';
import { hasIncludes } from '../../Helpers/assistedApplication/pricing';
import { canOnlyReadApplication } from '../../Helpers/permissions';
import { userHasPermission } from '../../Helpers/user';
import { useGetLeadQuery } from '../../ReactQuery/Lead/lead.queries';
import { NodeIds } from '@breathelife/insurance-form-builder';
import { deleteAllOutcomesForParticipant } from '../../Services/ApplicationOutcomesService';
import { useFullPageLoaderContext } from '../LoadingView/FullPageLoader';
import { DrawerType } from './AssistedApplication';
import { useGetProductsEntityQuery } from '../../ReactQuery/ProductsEntity/productsEntity.queries';
import { productsSlice } from '../../ReduxStore/Products/ProductsSlice';

import { ApplicationStoredFilesContextProvider } from './Drawers/Documents/ApplicationStoredFilesContext';

type Props = {
  application: Application;
  applicationSignatureType?: SignatureType;
  closeAssistedApplication: () => void;
  isSubmitButtonDisabled: boolean;
  onNavigateToESignatureDetails: () => void;
  onOpenESignatureDetails: () => void;
  onOpenSubmissionDetailsModal: () => void;
  onOpenFileAttachmentModal: () => void;
  isESignatureDetailsOpen: boolean;
  onCloseESignatureDetails: () => void;
  isInfoMessageTrackESignatureModalOpen: boolean;
  onCloseInfoMessageTrackESignatureModal: () => void;
  setHasInitiatedSubmission: (value: SetStateAction<boolean>) => void;
  onOpenSubmitApplicationModal: () => void;
  triggerIdentityVerification: () => void;
  onOpenInstantIdReportDrawer: () => void;
  proposedInsuredIndexToDelete: number;
  onOpenDeleteProposedInsuredModal: (index: number) => void;
  onCloseDeleteProposedInsuredModal: () => void;
};

const ADD_REMOVE_INSURED_LOADER_DELAY = 1000;

export function AssistedApplicationContainer(props: Props): ReactElement | null {
  const { t, i18n } = useTranslation();
  const { carrierInfo, features, googleMapsPlaces: googleMapsApiKey } = useCarrierContext();
  const { nodeIdToAnswerPathMap, questionnaireEngine, pricingFieldIdentifiers } = useAAQuestionnaireVersionContext();
  const locale = useLocale();
  const { drawerType } = useNavigation();
  const navigationType = useNavigationType();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { closeFullPageLoader, openFullPageLoader } = useFullPageLoaderContext();

  const {
    application,
    applicationSignatureType,
    closeAssistedApplication,
    isSubmitButtonDisabled: isSubmitButtonDisabledProps,
    onOpenSubmitApplicationModal,
    onNavigateToESignatureDetails,
    onOpenESignatureDetails,
    onOpenSubmissionDetailsModal,
    onOpenFileAttachmentModal,
    isESignatureDetailsOpen,
    onCloseESignatureDetails,
    setHasInitiatedSubmission,
    isInfoMessageTrackESignatureModalOpen,
    onCloseInfoMessageTrackESignatureModal,
    triggerIdentityVerification,
    onOpenInstantIdReportDrawer,
    proposedInsuredIndexToDelete,
    onOpenDeleteProposedInsuredModal,
    onCloseDeleteProposedInsuredModal,
  } = props;

  const { isSubmittingApplication } = useSelector((state) => state.leadPlatform.submission);
  const { products, quotes, isLoadingQuotes } = useSelector((state) => state.leadPlatform.products);
  const userPermissions = useSelector((store) => store.leadPlatform.authentication.user?.permissions ?? []);
  const isIdentityVerificationCheckCompleted = useSelector(
    (store) => store.leadPlatform.identityVerification.isIdentityVerificationCheckCompleted,
  );

  const sendESignCeremonyMutation = useSendESignCeremonyMutation();
  const updateESignCeremonyStatusMutation = useUpdateESignCeremony();
  const submitInsuranceApplicationMutation = useSubmitInsuranceApplication();
  const { data: eSignCeremony, isFetching: isGetESignCeremonyFetching } = useGetESignCeremonyQuery(
    application?.id,
    applicationSignatureType,
  );
  const { isFetching: isGetApplicationFetching } = useGetApplicationQuery(application?.id);
  const { isFetching: isPointOfSalesDecisionsFetching } = useGetPointOfSaleDecisions(application?.id);

  const [isDocumentDrawerOpen, onOpenDocumentDrawerHook, onCloseDocumentDrawer] = useModalState();
  const [isSubmitPaperAppModalOpen, onOpenSubmitPaperAppModal, onCloseSubmitPaperAppModal] = useModalState();
  const [storedSectionIndices, setStoredSectionIndices] = useStoredSectionState(application?.id);

  const [displayValidationErrors, setDisplayValidationErrors] = useState(false);
  const [openESignatureDrawerRequested, setOpenESignatureDrawerRequested] = useState(false);
  const [shouldPointOfSalesTriggerLoadingStatus, setShouldPointOfSalesTriggerLoadingStatus] = useState(true);
  const [shouldRefreshAnswers, setShouldRefreshAnswers] = useState(false);
  const [nodesToRefresh, setNodesToRefresh] = useState<Record<string, unknown>[]>([]);
  const [shouldDisableFields, setShouldDisableFields] = useState(false);
  const [shouldRefreshQuotesAfterInsuredCountChanged, setShouldRefreshQuotesAfterInsuredCountChanged] =
    useState<boolean>(false);

  const { data: lead } = useGetLeadQuery(application?.leadId, {});
  const userCanOnlyReadApplication = canOnlyReadApplication(lead);

  const productWidgetType = useMemo(() => {
    const isProductWidgetEnabled = features.assistedApplication?.productsWidget?.enabled;

    const isApplicationLineOfBusinessAllowed =
      isProductWidgetEnabled &&
      features.assistedApplication?.productsWidget?.allowedLinesOfBusiness.includes(application.lineOfBusiness);

    if (!isProductWidgetEnabled || !isApplicationLineOfBusinessAllowed) return undefined;

    return features.assistedApplication?.productsWidget?.type;
  }, [features.assistedApplication?.productsWidget, application.lineOfBusiness]);

  const isProductsEntityEnabled =
    features.loadProductInformationFromDb?.enabled &&
    features.assistedApplication?.productsWidget?.enabled &&
    productWidgetType === ProductsWidgetFeatureType.default;

  const isIdentityVerificationCheckEnabled = !!features.enableInstantIdRequest?.enabled;
  const isMissingIdentityVerificationCheck =
    isIdentityVerificationCheckEnabled && !isIdentityVerificationCheckCompleted;
  const minorAge = carrierInfo.minorAge ?? 0;

  const { activeSectionIndex, previousSectionIndex, currentSectionGroupId, currentProposedInsuredIndex } =
    storedSectionIndices;

  const isESignStatusChanging =
    updateESignCeremonyStatusMutation.isLoading ||
    submitInsuranceApplicationMutation.isLoading ||
    sendESignCeremonyMutation.isLoading ||
    isGetApplicationFetching ||
    (isPointOfSalesDecisionsFetching && shouldPointOfSalesTriggerLoadingStatus) ||
    (isGetESignCeremonyFetching && eSignCeremony?.status !== ESignCeremonyStatus.IN_PROGRESS);

  const fetchQuotesMutation = useFetchQuotesMutation();

  const isJetDecisionWidgetEnabled = useMemo(
    () =>
      features.assistedApplication?.jetDecisionWidget?.enabled &&
      userHasPermission(userPermissions, Permission.JetDecisionWidgetView),
    [userPermissions, features.assistedApplication?.jetDecisionWidget?.enabled],
  );

  const saveAssistedApplicationAnswersMutation = useSaveAssistedApplicationAnswersMutation({
    onMutate: ({ answers, answersV2, isManuallySavingAnswers, updatedNodeIds }) => {
      const refreshAllAnswers = shouldRefreshAllAnswers({
        answers: new VersionedAnswers({ v1: answers, v2: answersV2 }),
        currentProposedInsuredIndex,
        questionnaireEngine,
        nodeIdsSkippingDebounce: features.assistedApplication?.nodeIdsSkippingDebounce,
        updatedNodeIds,
      });

      if (refreshAllAnswers || isManuallySavingAnswers) {
        setShouldDisableFields(true);
      }
    },
    onSuccess: async (__application, { answers, answersV2, updatedNodeIds, isClosing, nodesToRefresh }) => {
      const refreshAllAnswers = shouldRefreshAllAnswers({
        answers: new VersionedAnswers({ v1: answers, v2: answersV2 }),
        currentProposedInsuredIndex,
        questionnaireEngine,
        nodeIdsSkippingDebounce: features.assistedApplication?.nodeIdsSkippingDebounce,
        updatedNodeIds,
      });

      if (refreshAllAnswers) {
        setShouldRefreshAnswers(true);
      }

      if (!isClosing && nodesToRefresh && nodesToRefresh.length > 0) {
        setNodesToRefresh(nodesToRefresh);
      }

      if (isJetDecisionWidgetEnabled && application) {
        void queryClient.invalidateQueries([QueryId.jetDecisionOutcomes, application.id]);
      }

      void queryClient.invalidateQueries([QueryId.leads]);
      void queryClient.invalidateQueries([QueryId.usedLeadStatuses]);
    },
    onSettled(__application, __error, { isManuallySavingAnswers }) {
      if (isManuallySavingAnswers) {
        setShouldDisableFields(false);
      }
    },
  });

  const updatePricing = useCallback(
    async (
      props: SaveAssistedApplicationAnswersParams,
    ): Promise<{
      saveAnswersProps: SaveAssistedApplicationAnswersParams;
      shouldRefreshComprehensivePricing?: boolean;
      shouldRefreshTotalPremiums?: boolean;
    }> => {
      let saveAnswersProps = props;
      if (!application || !productWidgetType) return { saveAnswersProps };

      let currentAnswers = new VersionedAnswers({ v1: props.answers, v2: props.answersV2 });
      let shouldFetchQuotes = false;

      if (!saveAnswersProps.isClosing) {
        const fieldIdentifiersToWatch =
          features.pricingCalculation.enabled && pricingFieldIdentifiers
            ? pricingFieldIdentifiers.nodeIds
            : features.assistedApplication?.nodeIdsAffectingPricing;

        const nodeIdsAffectingPricingHaveChanged =
          _.isArray(fieldIdentifiersToWatch) &&
          _.intersection(fieldIdentifiersToWatch, props.updatedNodeIds).length > 0;

        if (nodeIdsAffectingPricingHaveChanged || shouldRefreshQuotesAfterInsuredCountChanged) {
          if (shouldRefreshQuotesAfterInsuredCountChanged) {
            setShouldRefreshQuotesAfterInsuredCountChanged(false);
          }
          if (productWidgetType === ProductsWidgetFeatureType.totalPremiums) {
            return { shouldRefreshTotalPremiums: true, saveAnswersProps };
          } else if (productWidgetType === ProductsWidgetFeatureType.comprehensive) {
            return { shouldRefreshComprehensivePricing: true, saveAnswersProps };
          } else {
            shouldFetchQuotes = true;
          }
        }
      } else if (saveAnswersProps.isManuallySavingAnswers) {
        if (!application || !productWidgetType) return { saveAnswersProps };
        if (productWidgetType === ProductsWidgetFeatureType.totalPremiums) {
          return { shouldRefreshTotalPremiums: true, saveAnswersProps };
        } else if (productWidgetType === ProductsWidgetFeatureType.comprehensive) {
          return { shouldRefreshComprehensivePricing: true, saveAnswersProps };
        } else {
          shouldFetchQuotes = true;
        }
      }

      if (shouldFetchQuotes) {
        const pricingIncludes = hasIncludes({
          answers: currentAnswers,
          featureConfigsAdoMinMax: features.assistedApplication?.adoMinMax,
          featureConfigsESA: features.assistedApplication?.equivalentSingleAge,
          nodeIdsAffectingPricing: features.assistedApplication?.nodeIdsAffectingPricing,
          updatedNodeIds: props.updatedNodeIds,
          questionnaireEngine,
          pricingFieldIdentifiers,
        });

        if (pricingIncludes.ado || pricingIncludes.esa) {
          shouldFetchQuotes = true;
        }

        let quotesInfo: QuoteInfo | null = null;
        if (shouldFetchQuotes) {
          quotesInfo = await fetchQuotesMutation.mutateAsync({
            appId: application.id,
            answers: currentAnswers.v1,
            includeADO: pricingIncludes.ado,
            includeESA: pricingIncludes.esa,
          });
          dispatch(productsSlice.actions.setQuotes(quotesInfo));
        }

        if (quotesInfo?.answers) {
          const nodesToRefresh: {
            nodeId: string;
            value: number;
            scope: InstanceScope;
          }[] = [];

          quotesInfo?.answers.map((item) => {
            const scope: InstanceScope = {
              [NodeIds.insuredPeople]: item.insuredIndex,
            };

            currentAnswers = questionnaireEngine.updateAnswer(
              currentAnswers,
              item.nodeId,
              item.value,
              undefined,
              scope,
            );
            nodesToRefresh.push({
              nodeId: item.nodeId,
              value: item.value,
              scope,
            });
          });

          saveAnswersProps = {
            ...props,
            answers: currentAnswers.v1,
            answersV2: currentAnswers.v2,
            nodesToRefresh,
          };
        }
      }

      return { saveAnswersProps };
    },
    [
      application.id,
      features.assistedApplication?.adoMinMax,
      features.assistedApplication?.equivalentSingleAge,
      fetchQuotesMutation,
      questionnaireEngine,
      productWidgetType,
      queryClient,
      dispatch,
    ],
  );

  const saveAnswers = useCallback(
    async (saveAnswersProps: SaveAssistedApplicationAnswersParams) => {
      const {
        saveAnswersProps: updatedSaveAnswersProps,
        shouldRefreshComprehensivePricing,
        shouldRefreshTotalPremiums,
      } = await updatePricing(saveAnswersProps);
      await saveAssistedApplicationAnswersMutation.mutateAsync(updatedSaveAnswersProps);

      const shouldRefreshProducts =
        _.isArray(features.assistedApplication?.nodeIdsAffectingProducts) &&
        _.intersection(features.assistedApplication.nodeIdsAffectingProducts, updatedSaveAnswersProps.updatedNodeIds)
          .length > 0;

      if (shouldRefreshProducts) {
        void dispatch(ProductsOperations.fetchProducts(application.id, i18n.language as Language));
      }
      if (shouldRefreshTotalPremiums) {
        void queryClient.invalidateQueries([QueryId.productsWidgetTotalPremiums, application.id]);
      }
      if (shouldRefreshComprehensivePricing) {
        void queryClient.invalidateQueries([QueryId.comprehensivePricing, application.id]);
      }
    },
    [updatePricing, saveAssistedApplicationAnswersMutation],
  );

  const isSavingAnswers = saveAssistedApplicationAnswersMutation.isLoading || fetchQuotesMutation.isLoading;

  const onAfterRefreshAnswers = (): void => {
    setShouldRefreshAnswers(false);
    setShouldDisableFields(false);
    setNodesToRefresh([]);
  };

  const onSelectProposedInsuredIndex = useCallback(
    (newProposedInsuredIndex: number): void => {
      setStoredSectionIndices((prev) => {
        return {
          ...prev,
          currentProposedInsuredIndex: newProposedInsuredIndex,
        };
      });
    },
    [setStoredSectionIndices],
  );

  const {
    answers: newVersionedAnswers,
    onAnswerChange,
    onBulkAnswerClear,
    hasUnsavedChanges,
    manuallySaveAnswers,
    removeItemFromCollection,
  } = useAutoSaveAnswers({
    application,
    questionnaireEngine,
    saveAnswers,
    isSavingAnswers,
    shouldRefreshAnswers,
    nodesToRefresh,
    onAfterRefreshAnswers,
  });

  const canFetchProductsEntity = useMemo(() => {
    return isProductsEntityEnabled && !_.isEmpty(quotes.quotePerProduct) && !_.isEmpty(products);
  }, [isProductsEntityEnabled, quotes, products]);

  const { data: productsEntity, refetch: refetchProductsEntity } = useGetProductsEntityQuery(
    newVersionedAnswers.v1,
    products as LocalizedInsuranceProduct[],
    quotes,
    application,
    {
      enabled: !!canFetchProductsEntity,
    },
  );

  useEffect(() => {
    if (isProductsEntityEnabled) {
      void refetchProductsEntity();
    }
  }, [products, quotes]);

  const areAllFieldsDisabled = useMemo(
    () =>
      application.signed ||
      application.submitted ||
      isSubmittingApplication ||
      shouldDisableFields ||
      isESignStatusChanging ||
      userCanOnlyReadApplication,
    [
      application.signed,
      application.submitted,
      isSubmittingApplication,
      shouldDisableFields,
      isESignStatusChanging,
      userCanOnlyReadApplication,
    ],
  );

  const renderingQuestionnaire = useMemo(() => {
    const start = performance.now();
    const renderingQuestionnaire = questionnaireEngine.generateRenderingQuestionnaire(newVersionedAnswers, locale, t, {
      renderingType: RenderingType.web,
      shouldValidateAllAnswers: displayValidationErrors,
      allFieldsCompleted: areAllFieldsDisabled || isESignStatusChanging,
      loadingFields: shouldDisableFields || isESignStatusChanging,
    });
    const end = performance.now();

    logger.keep({
      tag: 'TimeMeasurement',
      name: 'TimeToGenerateNextRenderingQuestionnaire',
      value: end - start,
      unit: TimeMeasurementUnits.millisecond,
    });

    return renderingQuestionnaire;
  }, [
    questionnaireEngine,
    newVersionedAnswers,
    locale,
    t,
    displayValidationErrors,
    areAllFieldsDisabled,
    isESignStatusChanging,
    shouldDisableFields,
  ]);

  const isQuestionnaireCompleted = useMemo(() => {
    return isRenderingQuestionnaireComplete(renderingQuestionnaire);
  }, [renderingQuestionnaire]);

  const applicantName = useMemo(() => {
    return getApplicantFullName(newVersionedAnswers, questionnaireEngine, application.lineOfBusiness, features);
  }, [newVersionedAnswers, questionnaireEngine, application.lineOfBusiness, features]);

  const applicantDateOfBirth = useMemo(() => {
    return getInsuredDateOfBirth(newVersionedAnswers, questionnaireEngine);
  }, [newVersionedAnswers, questionnaireEngine]);

  const isApplicantMinor = useMemo(() => {
    if (minorAge === 0) return false;
    const applicantDateOfBirthObject = dayjs(applicantDateOfBirth);
    const applicantAge = getAge(applicantDateOfBirthObject.toDate(), AgeRoundingType.lastBirthday);
    const isMinor = applicantAge < minorAge;
    return isMinor;
  }, [applicantDateOfBirth, minorAge]);

  const proposedInsuredTabs = useMemo(() => {
    const proposedInsuredCount =
      questionnaireEngine.getRepetitionCount(newVersionedAnswers, NodeIds.insuredPeople, {}) || 0;

    const tabs = [];
    for (let proposedInsuredIndex = 0; proposedInsuredIndex < proposedInsuredCount; proposedInsuredIndex++) {
      const scope = { ['insured-people']: proposedInsuredIndex };
      const surrogateId = questionnaireEngine.getAnswer(newVersionedAnswers, NodeIds.insuredSurrogateId, scope);
      const tabData: {
        name: string;
        index: number;
        surrogateId: string;
      } = { index: proposedInsuredIndex, surrogateId: surrogateId as string, name: '' };

      const firstName = questionnaireEngine.getAnswer(newVersionedAnswers, NodeIds.insuredFirstName, scope);
      const lastName = questionnaireEngine.getAnswer(newVersionedAnswers, NodeIds.insuredLastName, scope);

      if (firstName || lastName) {
        tabData.name = [firstName, lastName].filter(Boolean).join(' ');
      }

      tabs.push(tabData);
    }

    return tabs;
  }, [newVersionedAnswers, questionnaireEngine]);

  const hashedApplicationId = useMemo(() => hash(application.id), [application.id]);

  /** TODO: see DEV-7251
   * We should have a better way of keeping track of an application's signature status regardless of the type of signature used
   * hasSignature is used to know if something is signed in the context of cryptoSignature
   * in the context of eSignature, we only check if the submission was created (application.submitted)
   * */
  const hasNoSignature =
    application.mode !== ApplicationMode.paper &&
    applicationSignatureType !== SignatureType.eSignature &&
    !application.signed;
  const DECOUPLE_ESIGN_SUBMISSION_ENABLED = !!features.enableDecoupleESignFlow?.enabled;

  // Application is being edited by the user
  const isApplicationInEditMode =
    DECOUPLE_ESIGN_SUBMISSION_ENABLED && eSignCeremony?.status === ESignCeremonyStatus.DRAFT;

  // Application has been locked for review
  const isApplicationInReview =
    DECOUPLE_ESIGN_SUBMISSION_ENABLED &&
    // application has been submitted but eSignCeremony status is still in draft
    ((application.submitted && eSignCeremony?.status === ESignCeremonyStatus.DRAFT) ||
      eSignCeremony?.status === ESignCeremonyStatus.IN_PROGRESS ||
      eSignCeremony?.status === ESignCeremonyStatus.READY);

  const isSubmitButtonVisible = isApplicationInReview || !application.submitted || hasNoSignature;

  const isSubmitButtonDisabled =
    isSubmitButtonDisabledProps ||
    !isQuestionnaireCompleted ||
    hasUnsavedChanges ||
    (isMissingIdentityVerificationCheck && !isApplicantMinor) ||
    isSavingAnswers ||
    isLoadingQuotes ||
    isApplicationInEditMode ||
    userCanOnlyReadApplication;

  const DOCUMENTS_DRAWER_ENABLED = features.assistedApplication?.documentsDrawer?.enabled === true;

  const onClose = useCallback(async () => {
    const isSavingApplication = hasUnsavedChanges && !userCanOnlyReadApplication && (await manuallySaveAnswers(true));
    isSavingApplication &&
      dispatch(
        notificationSlice.actions.setSuccess({
          message: t('notifications.saveApplicationSuccess'),
          autoHideDuration: 3000,
        }),
      );
  }, [hasUnsavedChanges, userCanOnlyReadApplication, manuallySaveAnswers, dispatch, t]);

  const onAddProposedInsured = useCallback(async (): Promise<void> => {
    if (areAllFieldsDisabled) return;
    openFullPageLoader();
    hasUnsavedChanges && (await manuallySaveAnswers());
    if (!application) {
      closeFullPageLoader();
      return;
    }

    const insuredPeopleCount = questionnaireEngine.getRepetitionCount(newVersionedAnswers, NodeIds.insuredPeople, {});
    onAnswerChange([
      {
        id: NodeIds.insuredSurrogateId,
        value: '',
        effects: undefined,
        repeatedIndices: {
          [NodeIds.insuredPeople]: insuredPeopleCount || 0,
        },
        updateWithDefaultValue: undefined,
        triggerStepNavigation: true,
      },
    ]);
    onSelectProposedInsuredIndex(insuredPeopleCount || 0);
    setShouldRefreshQuotesAfterInsuredCountChanged(true);
    closeFullPageLoader();
    if (isJetDecisionWidgetEnabled && application) {
      void queryClient.invalidateQueries([QueryId.jetDecisionOutcomes, application.id]);
    }
  }, [
    newVersionedAnswers,
    questionnaireEngine,
    onAnswerChange,
    areAllFieldsDisabled,
    application,
    hasUnsavedChanges,
    manuallySaveAnswers,
    isJetDecisionWidgetEnabled,
    onSelectProposedInsuredIndex,
    queryClient,
    closeFullPageLoader,
    openFullPageLoader,
  ]);

  const onRemoveProposedInsured = useCallback(
    async (surrogateId: string): Promise<void> => {
      if (areAllFieldsDisabled) return;

      openFullPageLoader(ADD_REMOVE_INSURED_LOADER_DELAY);
      hasUnsavedChanges && (await manuallySaveAnswers());
      if (!application || surrogateId === '') {
        closeFullPageLoader();
        return;
      }
      removeItemFromCollection(surrogateId, NodeIds.insuredPeople, NodeIds.insuredSurrogateId);
      void deleteAllOutcomesForParticipant({ participantId: surrogateId, applicationId: application.id });
      onCloseDeleteProposedInsuredModal();
      onSelectProposedInsuredIndex(0);
      setShouldRefreshQuotesAfterInsuredCountChanged(true);
      closeFullPageLoader();
      if (isJetDecisionWidgetEnabled) {
        void queryClient.invalidateQueries([QueryId.jetDecisionOutcomes, application.id]);
      }
    },
    [
      queryClient,
      onSelectProposedInsuredIndex,
      onCloseDeleteProposedInsuredModal,
      isJetDecisionWidgetEnabled,
      application,
      areAllFieldsDisabled,
      manuallySaveAnswers,
      hasUnsavedChanges,
      removeItemFromCollection,
      closeFullPageLoader,
      openFullPageLoader,
    ],
  );

  const onAnswerComplete = useCallback(
    (fieldId: string) => {
      TypewriterTracking.completedField({
        fieldId,
        hashedId: hashedApplicationId,
      });
    },
    [hashedApplicationId],
  );

  const onOpenDocumentsDrawer = useCallback(() => {
    TypewriterTracking.clickedButton({
      buttonName: 'documents drawer',
      hashedId: application?.id ? hash(application.id) : null,
    });
    onOpenDocumentDrawerHook();
  }, [application, onOpenDocumentDrawerHook]);

  const processSubmission = useCallback(
    async (signers: ESignSigner2FAInfo[] = []) => {
      if (!application || !applicationSignatureType) return;
      const premiumForSelectedProduct = productsEntity?.totalPremium;
      let updatedApplication: Application | undefined;
      if (application.isPreview) {
        updatedApplication = await dispatch(
          processPreviewApplicationSubmission({ application, productQuote: premiumForSelectedProduct }),
        );
      } else if (applicationSignatureType === SignatureType.eSignature) {
        if (signers.length === 0 && application.mode === ApplicationMode.digital) {
          throw new Error('Failed to get signers for application.');
        }
        updatedApplication = await dispatch(
          processApplicationSubmission({
            application,
            productQuote: premiumForSelectedProduct,
            signatureType: applicationSignatureType,
            signers,
            // TODO [DEV-10295] https://breathelife.atlassian.net/browse/DEV-10295 Don't pass this down once we migrate AA submission to react-query
            sendESignCeremonyMutation,
          }),
        );
      } else {
        updatedApplication = await dispatch(
          processApplicationSubmission({
            application,
            productQuote: premiumForSelectedProduct,
            signatureType: applicationSignatureType,
          }),
        );
      }
      setHasInitiatedSubmission(true);
      if (updatedApplication) {
        queryClient.setQueryData([QueryId.application, application.id], updatedApplication);
        void queryClient.invalidateQueries([QueryId.application, application.id]);
        void queryClient.invalidateQueries([QueryId.pointOfSaleDecision, application.id]);
      }
    },
    [
      application,
      applicationSignatureType,
      productsEntity?.totalPremium,
      setHasInitiatedSubmission,
      queryClient,
      dispatch,
      sendESignCeremonyMutation,
    ],
  );

  const onSubmitApplication = useCallback(async () => {
    if (
      !application ||
      !applicationSignatureType ||
      (!application.isPreview && applicationSignatureType === SignatureType.eSignature)
    )
      return;
    onOpenSubmitApplicationModal();
    return processSubmission();
  }, [application, applicationSignatureType, onOpenSubmitApplicationModal, processSubmission]);

  const handleConfirmSubmitPaperAppModal = useCallback(async () => {
    onCloseSubmitPaperAppModal();
    try {
      await processSubmission();
      dispatch(
        notificationSlice.actions.setSuccess({
          message: t('modals.submitPaperApp.submitSuccessMessage'),
        }),
      );
      closeAssistedApplication();
    } catch (error: any) {
      dispatch(
        notificationSlice.actions.setError({
          message: t('modals.submitPaperApp.submitErrorMessage'),
        }),
      );
    }
  }, [onCloseSubmitPaperAppModal, processSubmission, closeAssistedApplication, dispatch, t]);

  const onLockApplication: () => void = useCallback(async () => {
    return;
  }, []);

  const onUnlockApplication: () => void = useCallback(async () => {
    updateESignCeremonyStatusMutation.mutate({
      applicationId: application.id,
      data: { status: ESignCeremonyStatus.DRAFT },
    });
  }, [application.id, updateESignCeremonyStatusMutation]);

  // This effect detects if the AA is unmounted due to the browser's back button being pressed
  useEffect(() => {
    const currentLocation = location.href;
    return () => {
      if (navigationType === 'POP' && !location.href.includes(currentLocation)) {
        void onClose();
      }
    };
  }, [navigationType, onClose]);

  useEffect(() => {
    if (isSubmitButtonDisabled || !drawerType) return;
    if (drawerType === DrawerType.signature) {
      onOpenESignatureDetails();
    }
  }, [application, drawerType, onOpenESignatureDetails, isSubmitButtonDisabled]);

  useEffect(() => {
    if (!openESignatureDrawerRequested) return;
    if (!isSubmitButtonDisabled) {
      setShouldPointOfSalesTriggerLoadingStatus(false);
      onNavigateToESignatureDetails();
    } else {
      dispatch(
        notificationSlice.actions.setError({
          message: t('notifications.unableToSendToSignature'),
        }),
      );
    }
    setOpenESignatureDrawerRequested(false);
  }, [isSubmitButtonDisabled, openESignatureDrawerRequested, onNavigateToESignatureDetails, dispatch, t]);

  const onSubmitESignature = (signers: ESignSigner2FAInfo[]): void => {
    if (DECOUPLE_ESIGN_SUBMISSION_ENABLED) {
      updateESignCeremonyStatusMutation.mutate({
        applicationId: application.id,
        data: { status: ESignCeremonyStatus.SENT, signers },
      });
    } else {
      void processSubmission(signers);
    }
  };

  async function onOpenESignatureDrawer(): Promise<void> {
    !application.submitted && (await manuallySaveAnswers());
    setOpenESignatureDrawerRequested(true);
  }

  function onSetActiveSectionIndex(newActiveSectionIndex: number): void {
    setStoredSectionIndices((prev) => {
      return {
        ...prev,
        activeSectionIndex: newActiveSectionIndex,
      };
    });
  }

  function onSwitchSectionGroupTab(sectionGroupId: SectionGroupId): void {
    if (sectionGroupId !== currentSectionGroupId) {
      setStoredSectionIndices((prev) => {
        return {
          ...prev,
          previousSectionIndex: activeSectionIndex,
          activeSectionIndex: previousSectionIndex,
        };
      });
    }
    setStoredSectionIndices((prev) => {
      return {
        ...prev,
        currentSectionGroupId: sectionGroupId,
      };
    });
  }

  // Display the esign loader when sending the eSignCeremony to sign
  const isESignatureDetailLoading = DECOUPLE_ESIGN_SUBMISSION_ENABLED
    ? updateESignCeremonyStatusMutation.isLoading
    : sendESignCeremonyMutation.isLoading;

  const skipToAssistedApplicationContentId = 'assistedApplicationContent';

  return (
    <AssistedApplicationContext.Provider
      value={{
        // Proposed insured
        currentSectionGroupId,
        onSwitchSectionGroupTab,
        proposedInsuredTabs,
        currentProposedInsuredIndex,
        onSelectProposedInsured: onSelectProposedInsuredIndex,
        onAddProposedInsured,
        onRemoveProposedInsured,
        activeSectionIndex,
        onSetActiveSectionIndex,

        // Form
        areAllFieldsDisabled,
        onAnswerChange,
        onAnswerComplete,
        onBulkAnswerClear,

        // Application
        applicationLineOfBusiness: application.lineOfBusiness,
        productWidgetType,
      }}
    >
      <ApplicationStoredFilesContextProvider
        application={application}
        answers={newVersionedAnswers.v1}
        nodeIdToAnswerPathMap={nodeIdToAnswerPathMap}
      >
        <Box display='flex' flexDirection='column' height='100vh'>
          <Box>
            <SkipLink href={`#${skipToAssistedApplicationContentId}`} title={t('skipLinks.header')} />
            {googleMapsApiKey && <GoogleMapsHelmet googleMapsApiKey={googleMapsApiKey} />}
            <AssistedApplicationHeader
              applicantName={applicantName}
              isApplicantMinor={isApplicantMinor}
              applicationSignatureType={applicationSignatureType}
              applicationMode={application.mode}
              close={() => {
                void onClose();
                closeAssistedApplication();
              }}
              isPreview={application.isPreview}
              isSubmitButtonDisabled={isSubmitButtonDisabled}
              isSubmitButtonVisible={isSubmitButtonVisible}
              onSubmitApplication={onSubmitApplication}
              onOpenESignatureDetails={onOpenESignatureDrawer}
              onOpenFileAttachmentModal={onOpenFileAttachmentModal}
              onOpenDocumentsDrawer={onOpenDocumentsDrawer}
              triggerIdentityVerification={triggerIdentityVerification}
              onOpenInstantIdReportDrawer={onOpenInstantIdReportDrawer}
              onOpenSubmitPaperAppModal={onOpenSubmitPaperAppModal}
            />
          </Box>
          <Box id={skipToAssistedApplicationContentId}>
            <AssistedApplicationView
              renderingQuestionnaire={renderingQuestionnaire}
              setDisplayValidationErrors={setDisplayValidationErrors}
              selectedProductId={application.product}
              applicationMode={application.mode}
              coverageAmount={application.coverageAmount}
              isApplicationSubmitted={application.submitted}
              isApplicationSigned={application.signed}
              isQuestionnaireCompleted={isQuestionnaireCompleted}
              onOpenSubmissionDetailsModal={onOpenSubmissionDetailsModal}
              productsEntity={productsEntity}
              proposedInsuredIndexToDelete={proposedInsuredIndexToDelete}
              onOpenDeleteProposedInsuredModal={onOpenDeleteProposedInsuredModal}
              onCloseDeleteProposedInsuredModal={onCloseDeleteProposedInsuredModal}
              onLockApplication={onLockApplication}
              onUnlockApplication={onUnlockApplication}
              onOpenESignatureDetails={onOpenESignatureDrawer}
              isESignStatusChanging={isESignStatusChanging}
              applicationSignatureType={applicationSignatureType}
              isReadOnly={userCanOnlyReadApplication}
            />
          </Box>
        </Box>

        {DOCUMENTS_DRAWER_ENABLED && (
          <RestrictedToUserPermissions requiredPermissions={[Permission.ApplicationFileRead]}>
            <DocumentsDrawer isOpen={isDocumentDrawerOpen} onClose={onCloseDocumentDrawer} application={application} />
          </RestrictedToUserPermissions>
        )}

        {applicationSignatureType === SignatureType.eSignature && (
          <Fragment>
            <ESignatureDetailsContainer
              isLoading={isESignatureDetailLoading}
              isOpen={isESignatureDetailsOpen}
              onClose={onCloseESignatureDetails}
              onSubmit={onSubmitESignature}
            />
            <InfoMessageTrackESignatureModal
              isOpen={isInfoMessageTrackESignatureModalOpen}
              onClose={onCloseInfoMessageTrackESignatureModal}
            />
          </Fragment>
        )}
        {application.mode === ApplicationMode.paper && (
          <SubmitPaperAppModal
            isOpen={isSubmitPaperAppModalOpen}
            onClose={onCloseSubmitPaperAppModal}
            onConfirm={handleConfirmSubmitPaperAppModal}
          />
        )}
      </ApplicationStoredFilesContextProvider>
    </AssistedApplicationContext.Provider>
  );
}
