import { ReactElement, Fragment } from 'react';

import { RenderingSubsection, RenderingSection } from '@breathelife/questionnaire-engine';

import { Subsection } from './Subsection';

type SectionProps = {
  section: RenderingSection;
};

export function Section(props: SectionProps): ReactElement | null {
  const { section } = props;

  if (!section.visible) return null;

  return (
    <Fragment>
      {section.subsections.map((subsection: RenderingSubsection) => (
        <Subsection key={subsection.id} subsection={subsection} />
      ))}
    </Fragment>
  );
}
