import ApiService from './ApiService';

export type DeleteApplicationOutcomesPayload = { success: boolean };

export async function deleteAllOutcomesForParticipant(data: {
  applicationId: string;
  participantId: string;
}): Promise<DeleteApplicationOutcomesPayload> {
  const response = await ApiService.deleteAllOutcomesForParticipant<DeleteApplicationOutcomesPayload>(data);
  return response.data;
}
