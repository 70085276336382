import i18next from 'i18next';

import {
  InsuranceProductCreateRequest,
  InsuranceProductPricingCreateRequest,
  InsuranceProductUpdateRequest,
} from '@breathelife/types';

import { DetailViewType } from '../../../Models/Layout';
import {
  defaultState as defaultProductState,
  productManagementSlice,
} from '../../../ReduxStore/Admin/ProductManagement/ProductManagementSlice';
import { defaultState as defaultLayoutState, layoutSlice } from '../../../ReduxStore/Layout/LayoutSlice';
import { notificationSlice } from '../../../ReduxStore/Notification/NotificationSlice';
import { Dispatch } from '../../../ReduxStore/types';
import ApiService from '../../../Services/ApiService';

export const fetchProducts =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    const entity = i18next.t('admin.productManagement.insuranceProduct.plural');

    dispatch(productManagementSlice.actions.setIsLoadingProducts({ isLoading: true }));

    try {
      const { data: products } = await ApiService.admin.fetchProducts();
      dispatch(productManagementSlice.actions.setProducts({ products }));
    } catch (error: any) {
      dispatch(
        notificationSlice.actions.setError({
          message: i18next.t('notifications.failedToFetchEntity', { entity }),
        }),
      );
    } finally {
      dispatch(
        productManagementSlice.actions.setIsLoadingProducts({ isLoading: defaultProductState.isLoadingProducts }),
      );
    }
  };

export const createProduct =
  (product: InsuranceProductCreateRequest) =>
  async (dispatch: Dispatch): Promise<void> => {
    const entity = i18next.t('admin.productManagement.insuranceProduct.singular');
    try {
      const response = await ApiService.admin.createProduct(product);
      dispatch(
        notificationSlice.actions.setSuccess({
          message: i18next.t('notifications.entityCreationSuccess', { entity }),
          autoHideDuration: 3000,
        }),
      );
      dispatch(productManagementSlice.actions.addProduct({ product: response.data }));
      dispatch(productManagementSlice.actions.setSelectedProduct({ selectedProduct: response.data }));
      dispatch(
        layoutSlice.actions.setRightPanelState({ rightPanelState: { type: DetailViewType.edit, isOpen: true } }),
      );
    } catch (error: any) {
      dispatch(
        notificationSlice.actions.setError({
          message: i18next.t('notifications.failedToCreateEntity', { entity }),
        }),
      );
    }
  };

export const selectProduct =
  (productId: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    const entity = i18next.t('admin.productManagement.insuranceProduct.plural');
    try {
      const { data } = await ApiService.admin.fetchProduct(productId);

      dispatch(productManagementSlice.actions.setSelectedProduct({ selectedProduct: data }));
      dispatch(
        layoutSlice.actions.setRightPanelState({ rightPanelState: { type: DetailViewType.edit, isOpen: true } }),
      );
    } catch (error: any) {
      dispatch(
        productManagementSlice.actions.setSelectedProduct({ selectedProduct: defaultProductState.selectedProduct }),
      );
      dispatch(
        notificationSlice.actions.setError({
          message: i18next.t('notifications.failedToFetchEntity', { entity }),
        }),
      );
    }
  };

export const updateProduct =
  (id: string, product: InsuranceProductUpdateRequest) =>
  async (dispatch: Dispatch): Promise<void> => {
    const entity = i18next.t('admin.productManagement.insuranceProduct.singular');
    try {
      const response = await ApiService.admin.updateProduct(id, product);
      dispatch(
        notificationSlice.actions.setSuccess({
          message: i18next.t('notifications.entityUpdateSuccess', { entity }),
          autoHideDuration: 3000,
        }),
      );
      dispatch(productManagementSlice.actions.updateProduct({ product: response.data }));
    } catch (error: any) {
      dispatch(
        notificationSlice.actions.setError({
          message: i18next.t('notifications.failedToUpdateEntity', { entity }),
        }),
      );
    }
  };

export const setProductPricing =
  (data: InsuranceProductPricingCreateRequest) =>
  async (dispatch: Dispatch): Promise<void> => {
    const entity = i18next.t('admin.productManagement.insuranceProductRates');

    dispatch(productManagementSlice.actions.setIsLoadingPricing({ isLoading: true }));

    try {
      await ApiService.admin.createProductPricing(data);

      // refetch the insurance product data
      const { data: updatedInsuranceProduct } = await ApiService.admin.fetchProduct(data.insuranceProductId);
      dispatch(productManagementSlice.actions.setPricingForSelectedProduct(updatedInsuranceProduct.pricing));
      dispatch(layoutSlice.actions.setModalState({ modalState: defaultLayoutState.modalState }));

      dispatch(
        notificationSlice.actions.setSuccess({
          message: i18next.t('notifications.entityCreationSuccess', { entity }),
          autoHideDuration: 3000,
        }),
      );
    } catch (error: any) {
      dispatch(
        notificationSlice.actions.setError({
          message: i18next.t('notifications.failedToCreateEntity', { entity }),
        }),
      );
    } finally {
      dispatch(productManagementSlice.actions.setIsLoadingPricing({ isLoading: false }));
    }
  };
