import { useTranslation } from 'react-i18next';
import { UseMutationOptions, UseMutationResult, useQueryClient, useMutation } from 'react-query';

import { QuestionnaireVersionPricingNodeIds } from '@breathelife/types';
import { logger } from '@breathelife/monitoring-frontend';

import { QueryId } from '../../common/common.types';
import { notificationSlice } from '../../../ReduxStore/Notification/NotificationSlice';
import { patchPricingFieldIdentifiers } from '../../../Services/PricingFieldIdentifiersService';

type PatchPricingIdentifiersParams = {
  pricingFieldIdentifiersId: string;
  questionnaireVersionId: string;
  data: Pick<QuestionnaireVersionPricingNodeIds, 'nodeIds'>;
};

export function useUpdatePricingFieldIdentifiersMutation(
  options?: UseMutationOptions<QuestionnaireVersionPricingNodeIds, unknown, PatchPricingIdentifiersParams>,
): UseMutationResult<QuestionnaireVersionPricingNodeIds, unknown, PatchPricingIdentifiersParams> {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<QuestionnaireVersionPricingNodeIds, unknown, PatchPricingIdentifiersParams>(
    ({ pricingFieldIdentifiersId, data }) => patchPricingFieldIdentifiers(pricingFieldIdentifiersId, data),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        void queryClient.setQueryData([QueryId.pricingFieldIdentifiers, variables.questionnaireVersionId], [data]);

        notificationSlice.actions.setSuccess({
          message: t('notifications.updatePricingFieldIdentifierSuccess'),
        });

        if (options?.onSuccess) {
          await options.onSuccess(data, variables, context);
        }
      },
      onError: async (err, variables, context) => {
        notificationSlice.actions.setError({
          message: t('notifications.failedToUpdatePricingFieldIdentifiers'),
        });
        logger.error(err);

        if (options?.onError) {
          await options.onError(err, variables, context);
        }
      },
    },
  );
}
