import { Box } from '@breathelife/mui';
import _ from 'lodash';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonName, TypewriterTracking } from '@breathelife/frontend-tracking';
import { Language, Permission } from '@breathelife/types';

import { SubmitButton } from '../../../../Components/Button/SubmitButton';
import { Image } from '../../../../Components/Images/Image';
import { RestrictedToUserPermissions } from '../../../../Components/Restricted/RestrictedToUserPermissions';
import Typography from '../../../../Components/Typography';
import { CloudinaryUpload } from '../../../../Components/Upload/Cloudinary/CloudinaryUpload';
import { getFormSchema } from '../../../../Helpers/inputValidation/form/advisor';
import { useCarrierContext, useDispatch, useSelector } from '../../../../Hooks';
import { ModalLayout } from '../../../../Layouts/Modal/ModalLayout';
import { getCurrentLocale } from '../../../../Localization/utils';
import {
  copyAgentLink as copyAgentLinkOperation,
  fetchAndSetPublicLink,
  updateUser,
} from '../../../../ReduxStore/Authentication/UserOperations';
import { isSsoUser } from '../../../../Services/Auth0';
import { AgencyName } from './AgencyName';
import { ContactInfo } from './ContactInfo';
import { PlatformLanguagePreference } from './PlatformLanguagePreference';
import { SharingPreferences } from './SharingPreferences';
import { ProfilePreferencesContainer, UserEditContainer } from './Styles';

export type Props = {
  isOpen: boolean;
  closeModal: () => void;
  isCompletingProfile?: boolean;
};

export function UserEditModal(props: Props): ReactElement | null {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { mgas, ssoProfileFields, languageSettings, availablePlatformLanguages } = useCarrierContext();
  const isLoading = useSelector((state) => state.leadPlatform.authentication.isLoading);
  const publicLink = useSelector((state) => state.leadPlatform.authentication.publicLink);
  const user = useSelector((store) => store.leadPlatform.authentication.user);
  const initialLanguage = user?.platformLanguage || getCurrentLocale(languageSettings.default);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [preferredName, setPreferredName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [picture, setPicture] = useState('');
  const [securityDisclosure, setSecurityDisclosure] = useState('');
  const [agencyName, setAgencyName] = useState<string>();
  const [platformLanguage, setPlatformLanguage] = useState<Language>(initialLanguage);
  const [isSso, setIsSso] = useState(false);

  const { closeModal: onClose, isOpen } = props;
  const enabledLanguages = availablePlatformLanguages || languageSettings.enabledLanguages;

  const syncFieldsWithUserData = useCallback(() => {
    setFirstName(user?.firstName ?? '');
    setLastName(user?.lastName ?? '');
    setPreferredName(user?.preferredName ?? '');
    setEmail(user?.emailLogin ?? '');
    setPhoneNumber(user?.phoneNumber ?? '');
    setPicture(user?.picture ?? '');
    if (mgas?.length > 0) {
      setAgencyName(user?.subGroupName ?? '');
    }
    setSecurityDisclosure(user?.securityDisclosure ?? '');
    if (user) {
      setIsSso(isSsoUser(user));
      setPlatformLanguage(user.platformLanguage);
    }
  }, [user, mgas.length]);

  useEffect(() => {
    syncFieldsWithUserData();
  }, [syncFieldsWithUserData]);

  useEffect(() => {
    if (
      !publicLink &&
      isOpen &&
      user &&
      user.firstName &&
      user.lastName &&
      _.includes(user.permissions, Permission.LeadInvitePublic)
    ) {
      dispatch(fetchAndSetPublicLink());
    }
  }, [publicLink, isOpen, dispatch, user]);

  const currentUserData = useMemo(
    () => ({
      firstName,
      lastName,
      preferredName,
      emailLogin: email,
      phoneNumber,
      agencyName,
      picture,
      platformLanguage,
      securityDisclosure,
    }),
    [firstName, lastName, preferredName, email, phoneNumber, agencyName, picture, platformLanguage, securityDisclosure],
  );

  const isFormValid = getFormSchema().isValidSync(currentUserData);

  const hasUserDataChanged = useMemo(() => {
    const initialUserData = {
      firstName: user?.firstName,
      lastName: user?.lastName,
      preferredName: user?.preferredName,
      emailLogin: user?.emailLogin,
      phoneNumber: user?.phoneNumber,
      picture: user?.picture,
      agencyName: user?.subGroupName || '',
      securityDisclosure: user?.securityDisclosure || '',
      platformLanguage: user?.platformLanguage || initialLanguage,
    };

    return !_.isEqual(currentUserData, initialUserData);
  }, [user, currentUserData, initialLanguage]);

  const isSaveButtonDisabled = useMemo(() => {
    return isLoading || !isFormValid || !hasUserDataChanged;
  }, [isLoading, isFormValid, hasUserDataChanged]);

  const onSaveClick = useCallback(() => {
    if (!isFormValid || !user) return;
    const data = {
      firstName,
      lastName,
      preferredName,
      emailLogin: email,
      phoneNumber,
      picture,
      platformLanguage,
      subGroupName: agencyName,
      securityDisclosure: _.trim(securityDisclosure),
    };
    TypewriterTracking.clickedButton({ buttonName: ButtonName.savedAdvisorProfile, hashedId: null });

    dispatch(updateUser(user.auth0Id, data, { isSso }));
  }, [
    isFormValid,
    user,
    firstName,
    lastName,
    preferredName,
    email,
    phoneNumber,
    picture,
    dispatch,
    isSso,
    agencyName,
    platformLanguage,
    securityDisclosure,
  ]);

  const copyAgentLink = useCallback(async () => {
    dispatch(copyAgentLinkOperation());
    TypewriterTracking.clickedButton({
      buttonName: ButtonName.userEditCopyPublicAgentLink,
      hashedId: null,
    });
  }, [dispatch]);

  return (
    <ModalLayout
      maxWidth='lg'
      confirmationRequired={props.isCompletingProfile}
      isOpen={isOpen}
      onExited={syncFieldsWithUserData}
      closeModal={onClose}
      title={t('modals.editUser.title')}
      submitButton={
        <SubmitButton
          data-testid='saveProfile'
          disabled={isSaveButtonDisabled}
          isLoading={isLoading}
          onClick={onSaveClick}
        >
          {t('cta.save')}
        </SubmitButton>
      }
    >
      <UserEditContainer>
        <Box flex={1}>
          <Typography variant='h3' grey={90}>
            {t('modals.editUser.contactInfo')}
          </Typography>
          <Box mt={2} display='flex' alignItems='center'>
            <Box mr={3} width='80px' height='80px'>
              <Image src={picture} width={80} height={80} />
            </Box>
            {(!isSso || !ssoProfileFields.includes('picture')) && (
              <CloudinaryUpload setPicture={setPicture} buttonText={t('modals.editUser.uploadProfilePicture')} />
            )}
          </Box>
          <ContactInfo
            firstName={firstName}
            lastName={lastName}
            preferredName={preferredName}
            email={email}
            phoneNumber={phoneNumber}
            onFirstNameChange={setFirstName}
            onLastNameChange={setLastName}
            onPreferredNameChange={setPreferredName}
            onEmailChange={setEmail}
            onPhoneNumberChange={setPhoneNumber}
            isSso={isSso}
          />
          {mgas?.length > 0 && (
            <Box mt={2}>
              <AgencyName
                maxLength={25}
                value={agencyName ?? ''}
                onChange={(value: any) => setAgencyName(value)}
                isSso={isSso}
              />
            </Box>
          )}
        </Box>
        <ProfilePreferencesContainer flex={1} ml={4} pl={4}>
          {enabledLanguages.length > 1 && (
            <Box mb={3}>
              <PlatformLanguagePreference
                enabledLanguages={enabledLanguages}
                selectedLanguage={platformLanguage}
                onLanguageChange={setPlatformLanguage}
              />
            </Box>
          )}
          <RestrictedToUserPermissions
            requiredPermissions={[
              Permission.LeadInvitePublic,
              Permission.LeadInvitePrivate,
              Permission.LeadInviteEmail,
            ]}
          >
            <SharingPreferences
              isCompletingProfile={!!props.isCompletingProfile}
              publicLink={publicLink}
              onCopyAgentLink={copyAgentLink}
              isSso={isSso}
              securityDisclosure={securityDisclosure}
              onSecurityDisclosureChange={setSecurityDisclosure}
            />
          </RestrictedToUserPermissions>
        </ProfilePreferencesContainer>
      </UserEditContainer>
    </ModalLayout>
  );
}
