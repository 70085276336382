import styled from '../../../../../Styles/themed-styled-components';

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: ${(props) => `1px solid ${props.theme.colors.grey[40]}`};
  border-radius: 8px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.07);
  width: 220px;
  overflow: hidden;
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  padding: 0 16px 0 20px;
  background-color: ${(props) => props.theme.colors.grey[20]};
`;

export const CardBody = styled.div`
  padding: 20px;
  max-height: 400px;
  overflow-y: auto;
`;
