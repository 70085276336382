import { ReactElement } from 'react';
import { NumericFormat, NumericFormatProps, NumberFormatValues } from 'react-number-format';

import { TextFieldProps } from '@breathelife/mui';

import { Language } from '@breathelife/types';
import { Input as TextInput } from '@breathelife/ui-components';

import { getCurrencySymbolPosition, getThousandSeparator } from '../../Localization/utils';
import styled, { css } from '../../Styles/themed-styled-components';

type OwnProps = {
  transparent?: boolean;
  locale?: Language;
  isCurrency?: boolean;
  onNumberChange?: (value: number | undefined) => void;
  [key: string]: any;
};

type Props = NumericFormatProps & TextFieldProps & OwnProps;

export const StyledNumericFormat = styled(NumericFormat).withConfig({
  shouldForwardProp: (prop: any) => !['transparent'].includes(prop),
})<OwnProps>`
  ${({ transparent }) =>
    !transparent &&
    css`
      input {
        background-color: ${(props) => props.theme.colors.grey[0]};
      }
    `}
`;

export function NumberInput(props: Props): ReactElement {
  const { locale, isCurrency, transparent, onNumberChange, required } = props;

  let prefix, suffix: undefined | string;

  if (isCurrency) {
    const position = getCurrencySymbolPosition(locale);
    prefix = position === 'before' ? '$ ' : undefined;
    suffix = position === 'after' ? ' $' : undefined;
  }

  return (
    <StyledNumericFormat
      customInput={TextInput}
      displayType='input'
      id={props.id}
      label={props.label}
      error={!!props.error}
      placeholder={props.placeholder}
      allowEmptyFormatting={false}
      allowNegative={false}
      required={required}
      thousandSeparator={props.thousandSeparator || getThousandSeparator(locale)}
      prefix={prefix}
      suffix={suffix}
      value={props.value}
      onValueChange={(value: NumberFormatValues) => {
        onNumberChange && onNumberChange(value.floatValue);
      }}
      decimalScale={props.decimalScale}
      // TextField props
      inputVariant={props.inputVariant}
      disabled={props.disabled}
      inputTooltip={props.inputTooltip}
      validationError={props.validationError}
      onBlur={props.onBlur}
      // Own props
      transparent={transparent}
    />
  );
}
