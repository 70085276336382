import { memo, ReactElement } from 'react';

import { PaymentServiceProvider } from '@breathelife/types';

import { StripeSubsectionContainer } from './ProviderSubsections';

type PaymentSubsectionRendererProps = {
  provider: PaymentServiceProvider | undefined;
};

export const PaymentSubsectionRenderer = memo(function PaymentSubsectionRendererComponent({
  provider,
}: PaymentSubsectionRendererProps): ReactElement | null {
  if (!provider) {
    return null;
  }

  const paymentProviderComponentMap: { [key in PaymentServiceProvider]: ReactElement | null } = {
    [PaymentServiceProvider.CLOVER]: null,
    [PaymentServiceProvider.STRIPE]: <StripeSubsectionContainer />,
  };

  return paymentProviderComponentMap[provider] ?? null;
});
