import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Theme } from '@breathelife/types';

export type ThemeState = {
  theme?: Theme;
  isFetching: boolean;
  isUpdatingColors: boolean;
  isUpdatingLogos: boolean;
  isUpdatingNavigationSidebarVariant: boolean;
};

type ThemePayload = { theme: Theme };
type SetLoadingPayload = boolean;

type ActionsType = {
  setTheme: (state: ThemeState, { payload }: PayloadAction<ThemePayload>) => void;
  setIsFetching: (state: ThemeState, { payload }: PayloadAction<SetLoadingPayload>) => void;
  setIsUpdatingColors: (state: ThemeState, { payload }: PayloadAction<SetLoadingPayload>) => void;
  setIsUpdatingLogos: (state: ThemeState, { payload }: PayloadAction<SetLoadingPayload>) => void;
  setIsUpdatingNavigationSidebarVariant: (state: ThemeState, { payload }: PayloadAction<SetLoadingPayload>) => void;
};

const defaultState = {
  isFetching: false,
  isUpdatingColors: false,
  isUpdatingLogos: false,
  isUpdatingNavigationSidebarVariant: false,
};

export const themeSlice = createSlice<ThemeState, ActionsType>({
  name: 'leadPlatform/theme',
  initialState: defaultState,
  reducers: {
    setTheme: (state: ThemeState, { payload }: PayloadAction<ThemePayload>) => {
      state.theme = payload.theme;
    },
    setIsFetching: (state: ThemeState, { payload }: PayloadAction<SetLoadingPayload>) => {
      state.isFetching = payload;
    },
    setIsUpdatingColors: (state: ThemeState, { payload }: PayloadAction<SetLoadingPayload>) => {
      state.isUpdatingColors = payload;
    },
    setIsUpdatingLogos: (state: ThemeState, { payload }: PayloadAction<SetLoadingPayload>) => {
      state.isUpdatingLogos = payload;
    },
    setIsUpdatingNavigationSidebarVariant: (state: ThemeState, { payload }: PayloadAction<SetLoadingPayload>) => {
      state.isUpdatingNavigationSidebarVariant = payload;
    },
  },
});
