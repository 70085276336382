import { SetStateAction, Dispatch, createContext } from 'react';

import { ESignSigner2FAInfo } from '@breathelife/types';

import { Application } from '../Models/Application';

export type ESignatureContextType = {
  application: Application | undefined;
  signers: ESignSigner2FAInfo[];
  setSigners: Dispatch<SetStateAction<ESignSigner2FAInfo[]>>;
};

export const ESignatureContext = createContext<ESignatureContextType>({
  signers: [],
  setSigners: () => {},
  application: undefined,
});
