import { Fragment, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '../../../../../Styles/themed-styled-components';

import { Box, Grid } from '@breathelife/mui';
import { TextAnswer } from '@breathelife/ui-components';

import { Badge } from '../../../../../Components/Badge/Badge';
import Typography from '../../../../../Components/Typography';
import { convertToReadableTime, getDayInText, getMethodDisplayText } from '../../../../../Helpers/communication';
import { BaseLeadCommunicationSchedule } from '../../../../../Models/Lead';

export const BestTimeToReachTextAnswerWrapper = styled(Box)`
  border-top: 1px solid ${(props) => props.theme.colors.grey[40]};
`;

type Props = {
  preferredMethods: string[];
  communicationSchedules: BaseLeadCommunicationSchedule[];
};

export function BestTimeToReach(props: Props): ReactElement {
  const { t } = useTranslation();
  return (
    <Fragment>
      <BestTimeToReachTextAnswerWrapper pt={3}>
        <TextAnswer label={t('bestTimeToReach')} value={getMethodDisplayText(props.preferredMethods)} />
      </BestTimeToReachTextAnswerWrapper>
      {props.communicationSchedules.map((schedule) => (
        <Box my={2} key={`communication-${schedule.day}`}>
          <Grid container>
            <Box minWidth={40}>
              <Typography variant='body1'>{getDayInText(schedule.day, 'short')}: </Typography>
            </Box>
            {schedule.time.map((time) => {
              const readableTime = convertToReadableTime(time, '12');
              return (
                <Box key={readableTime} mx={'2px'}>
                  <Badge label={readableTime} />
                </Box>
              );
            })}
          </Grid>
        </Box>
      ))}
    </Fragment>
  );
}
