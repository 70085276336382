import { ReactElement, Fragment } from 'react';

import { Skeleton } from '@breathelife/mui';
import { LeadStatus } from '@breathelife/types';

import { Progress } from '../../../../../../Components/Progress/Progress';
import Typography from '../../../../../../Components/Typography';
import getCurrency from '../../../../../../Helpers/currency';
import { useApplicationProgress, useCarrierContext } from '../../../../../../Hooks';
import { toCurrency } from '../../../../../../Localization/utils';
import { Application } from '../../../../../../Models/Application';
import { useFetchCarrierQuestionnaireQuery } from '../../../../../../ReactQuery/CarrierQuestionnaires/carrierQuestionnaire.queries';

type ApplicationHeaderProgressProps = {
  application: Application;
  status: LeadStatus;
};

export function ApplicationHeaderProgress({
  application,
  status,
}: ApplicationHeaderProgressProps): ReactElement | null {
  const { countryCode } = useCarrierContext();
  const { data: questionnaireData, isLoading: isLoadingQuestionnaire } = useFetchCarrierQuestionnaireQuery(
    application?.id,
    {},
  );

  const needsAnalysisProgress = useApplicationProgress({
    application,
    needsAnalysisOnly: true,
    questionnaireResponse: questionnaireData,
  });

  const isCompleted = needsAnalysisProgress === 100;
  const coverageAmount = application.recommendedCoverageAmount;
  const hasCoverageAmount = !!coverageAmount && coverageAmount > 0;

  return (
    <Fragment>
      {isLoadingQuestionnaire ? (
        <Skeleton width={120} height='2em' />
      ) : (
        <Progress applicationId={application.id} progress={needsAnalysisProgress || 0} status={status} />
      )}

      {(isCompleted || isLoadingQuestionnaire) &&
        hasCoverageAmount &&
        (isLoadingQuestionnaire ? (
          <Skeleton width={120} height='2em' />
        ) : (
          <Typography variant='body1' grey={90} component='p'>
            {toCurrency(coverageAmount || 0, getCurrency(countryCode))}
          </Typography>
        ))}
    </Fragment>
  );
}
