import { Box } from '@breathelife/mui';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { CloseButton } from '../../../../Components/Button/CloseButton';
import Typography from '../../../../Components/Typography';

type Props = {
  onClose: () => void;
};

export function DocumentsDrawerHeader(props: Props): ReactElement | null {
  const { t } = useTranslation();

  const { onClose } = props;

  return (
    <Box p={4}>
      <Box display='flex' justifyContent='space-between'>
        <Box display='flex' alignItems='center'>
          <Typography variant='h1'>{t('assistedApplication.files')}</Typography>
        </Box>

        <CloseButton onClick={onClose} />
      </Box>
    </Box>
  );
}
