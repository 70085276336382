import { ReactElement, useCallback, useState } from 'react';

import { FileTemplate, FileTemplateOrigin } from '@breathelife/types';

import { Drawer } from '../../../Components/Drawer/Drawer';
import LoadingView from '../../../Components/LoadingView/LoadingView';
import { usePatchFileTemplateMutation } from '../../../ReactQuery/Document/document.mutations';
import { useGetFileTemplateQuery } from '../../../ReactQuery/Document/document.queries';
import { UpdateFileTemplateData } from '../../../Services/FileTemplateService';
import { ArchiveDocumentDialog } from './ArchiveDocumentDialog';
import { DocumentEditor } from './Editor/DocumentEditor';

type Props = {
  fileTemplateId: string | null;
  onClose: () => void;
};

export function EditDocumentDrawer(props: Props): ReactElement | null {
  const { fileTemplateId, onClose } = props;

  // archive file template dialog
  const [fileTemplateIdToArchive, setFileTemplateIdToArchive] = useState<string>('');
  const cancelArchiveTemplate = (): void => setFileTemplateIdToArchive('');
  const onArchiveTemplate = (fileTemplateId: string): void => setFileTemplateIdToArchive(fileTemplateId);

  const fileTemplate = useGetFileTemplateQuery(fileTemplateId);

  const patchFileTemplateMutation = usePatchFileTemplateMutation({
    onSuccess: useCallback(() => {
      onClose();
    }, [onClose]),
  });

  const onEditDocument = async (data: Partial<FileTemplate>): Promise<void> => {
    if (!fileTemplateId || !data.origin || !data.docType || !data.localizedName || !data.localizedName.en) {
      return;
    }

    const patchFileTemplateData: UpdateFileTemplateData = {
      name: data.name || '',
      origin: data.origin,
      docType: data.docType,
      externalUrl: data.origin === FileTemplateOrigin.ExternalStatic ? data.externalUrl : undefined,
      identifier: data.identifier,
      isRequired: !!data.isRequired,
      variant: !data.variant || data.variant === 'none' ? null : data.variant,
      localizedName: data.localizedName,
    };

    await patchFileTemplateMutation.mutateAsync({
      fileTemplateId,
      data: patchFileTemplateData,
    });
  };

  if (fileTemplate?.isLoading) {
    return <LoadingView />;
  }

  const isOpen = Boolean(fileTemplateId);

  return (
    <Drawer open={isOpen} onClose={onClose}>
      {fileTemplate.data && (
        <DocumentEditor
          onClose={onClose}
          onArchive={onArchiveTemplate}
          isSavingTemplate={patchFileTemplateMutation.isLoading}
          onSubmit={onEditDocument}
          fileTemplate={fileTemplate.data}
          fileTemplateId={fileTemplate.data.id}
        />
      )}

      <ArchiveDocumentDialog
        isOpen={Boolean(fileTemplateIdToArchive)}
        onClose={cancelArchiveTemplate}
        fileTemplateIdToArchive={fileTemplateIdToArchive}
      />
    </Drawer>
  );
}
