import { useTranslation } from 'react-i18next';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { useDispatch } from 'react-redux';

import { InsuredUnderwritingOutcomesReports } from '@breathelife/types';

import { notificationSlice } from '../../../ReduxStore/Notification/NotificationSlice';
import { getUnderwritingReportsOutcomes } from '../../../Services/ApplicationSupportService';
import { QueryId } from '../../common/common.types';

export function useFindApplicationSupportUnderwritingReportsQuery(
  applicationId: string,
  options?: UseQueryOptions<InsuredUnderwritingOutcomesReports | null>,
): UseQueryResult<InsuredUnderwritingOutcomesReports | null> {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return useQuery<InsuredUnderwritingOutcomesReports | null>(
    [QueryId.underwritingReports, applicationId],
    async () => {
      if (!applicationId) {
        return Promise.reject(new Error(`Application ID is missing while fetching underwritingReports`));
      }
      return await getUnderwritingReportsOutcomes(applicationId);
    },
    {
      ...options,
      enabled: !!applicationId,
      onError: () => {
        dispatch(
          notificationSlice.actions.setError({
            message: t('notifications.getUnderwritingReportsFailure', { applicationId: applicationId }),
          }),
        );
      },
    },
  );
}
