import { ReactElement } from 'react';

import { SvgIconProps } from '../../Components/Icons';

export function BlockUserIcon(props: SvgIconProps): ReactElement {
  return (
    <svg
      stroke='#757575'
      fill='#f6f6f6'
      height='16'
      viewBox='0 0 16 16'
      width='16'
      xmlns='http://www.w3.org/2000/svg'
      {...props.svgProps}
    >
      <g strokeLinecap='round' strokeLinejoin='round'>
        <path d='m7.5 11.498c0 1.0609.42143 2.0783 1.17157 2.8284.75015.7502 1.76753 1.1716 2.82843 1.1716s2.0783-.4214 2.8284-1.1716c.7502-.7501 1.1716-1.7675 1.1716-2.8284s-.4214-2.07828-1.1716-2.82843c-.7501-.75014-1.7675-1.17157-2.8284-1.17157s-2.07828.42143-2.82843 1.17157c-.75014.75015-1.17157 1.76753-1.17157 2.82843z' />
        <path d='m13 9.998-3 3' />
        <path d='m10 9.998 3 3' />
        <path d='m2 2.5c0 .53043.21071 1.03914.58579 1.41421.37507.37508.88378.58579 1.41421.58579s1.03914-.21071 1.41421-.58579c.37508-.37507.58579-.88378.58579-1.41421s-.21071-1.03914-.58579-1.41421c-.37507-.375076-.88378-.58579-1.41421-.58579s-1.03914.210714-1.41421.58579c-.37508.37507-.58579.88378-.58579 1.41421z' />
      </g>
      <path d='m7.03667 7.25867c-.38396-.66886-.97805-1.19221-1.69-1.48875s-1.50189-.34967-2.24714-.15114c-.74524.19853-1.40406.63761-1.87413 1.24903-.470074.61142-.725076 1.36096-.7254 2.13219v1.5h1.5l.5 5h3l.21533-2.1553' />
      <path
        d='m7.03667 7.25867c-.38396-.66886-.97805-1.19221-1.69-1.48875s-1.50189-.34967-2.24714-.15114c-.74524.19853-1.40406.63761-1.87413 1.24903-.470074.61142-.725076 1.36096-.7254 2.13219v1.5h1.5l.5 5h3l.21533-2.1553'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
