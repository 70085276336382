import { Box, Link } from '@breathelife/mui';

import styled from '../../../../Styles/themed-styled-components';
import { Button } from '../../../../Components/Button/Button';

export const IconContainer = styled.div`
  margin-bottom: 30px;
  margin-left: -30px;
  &&&&& {
    svg {
      overflow: overlay;
    }
  }
`;
export const IdentityVerificationButton = styled(Button)`
  &&&& {
    background-color: ${(props) => props.theme.colors.blue[60]};
    min-width: 200px;
    margin: 6px 8px 6px 0;
    :hover {
      background-color: ${(props) => props.theme.colors.blue[60]} !important;
    }
  }
`;

export const PopperBox = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 16px;
  max-width: 245px;
`;

export const TextContainer = styled(Box)`
  padding-bottom: 8px;
`;

export const IdentityVerificationRetryButton = styled(Button)`
  &&& {
    margin: 6px 8px 6px 0;
    min-width: 200px;
  }
`;

export const StyledLink = styled(Link)`
  padding-top: 6px;
  &&&& {
    color: ${(props) => props.theme.colors.blue[50]};
  }
  display: flex;
  line-height: 1.3em;
  cursor: pointer;
`;
