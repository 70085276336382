import Copy from 'copy-to-clipboard';
import { Fragment, ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonName, TypewriterTracking } from '@breathelife/frontend-tracking';
import { Box, CircularProgress } from '@breathelife/mui';
import { Permission } from '@breathelife/types';

import { Alert } from '../../../Components/Alert/Alert';
import { Button } from '../../../Components/Button/Button';
import { SubmitButton } from '../../../Components/Button/SubmitButton';
import { Icon } from '../../../Components/Icons';
import { RestrictedToUserPermissions } from '../../../Components/Restricted/RestrictedToUserPermissions';
import Typography from '../../../Components/Typography';
import { useDispatch } from '../../../Hooks';
import { ModalLayout } from '../../../Layouts/Modal/ModalLayout';
import { Lead } from '../../../Models/Lead';
import {
  useGeneratePrivateLinkTokenMutation,
  useSendInvitationEmailToLeadMutation,
} from '../../../ReactQuery/Lead/lead.mutations';
import { useValidateLeadEmailQuery } from '../../../ReactQuery/Lead/lead.queries';
import { notificationSlice } from '../../../ReduxStore/Notification/NotificationSlice';
import { LeadEmail, LeadName } from './Styles';

export type Props = {
  isOpen: boolean;
  closeModal: () => void;
  lead: Lead;
};

export function SendEmailModal(props: Props): ReactElement | null {
  const { isOpen, closeModal, lead } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { data: emailValidation, isLoading: isValidatingEmail } = useValidateLeadEmailQuery(lead.id, {
    refetchOnMount: 'always',
  });

  const copyLink = useCallback(
    (link: string) => {
      Copy(`${link}&method=privateLink`);
      dispatch(
        notificationSlice.actions.setSuccess({ message: t('notifications.linkCopied'), autoHideDuration: 3000 }),
      );
    },
    [dispatch, t],
  );

  const { isLoading: isGeneratingInvitationLink, mutate: generatePrivateLinkToken } =
    useGeneratePrivateLinkTokenMutation({
      onSuccess: ({ link }) => {
        copyLink(link);
      },
    });

  const onClickCopyToClipboard = useCallback(() => {
    if (isGeneratingInvitationLink) return;

    TypewriterTracking.clickedButton({
      buttonName: ButtonName.generateLink,
      hashedId: null,
    });

    // TODO: check if token exists for app and if so, use that one
    generatePrivateLinkToken({ leadId: lead.id });
  }, [lead, isGeneratingInvitationLink, generatePrivateLinkToken]);

  const { isLoading: isSendingEmail, mutate: sendInvitationEmail } = useSendInvitationEmailToLeadMutation({
    onSuccess: closeModal,
  });

  const onSendEmail = useCallback(() => {
    sendInvitationEmail({ leadId: lead.id });
  }, [lead, sendInvitationEmail]);

  return (
    <ModalLayout
      maxWidth='sm'
      isOpen={isOpen}
      closeModal={closeModal}
      title={t('cta.sendNeedsAnalysis')}
      submitButton={
        <RestrictedToUserPermissions requiredPermissions={[Permission.LeadInviteEmail]}>
          <SubmitButton
            isLoading={isSendingEmail || isValidatingEmail}
            disabled={isSendingEmail || isValidatingEmail}
            onClick={onSendEmail}
          >
            {t('cta.send')}
          </SubmitButton>
        </RestrictedToUserPermissions>
      }
    >
      {isValidatingEmail && (
        <Box display='flex' justifyContent='center' py={10}>
          <CircularProgress size={90} />
        </Box>
      )}

      {!isValidatingEmail && (
        <Fragment>
          <RestrictedToUserPermissions requiredPermissions={[Permission.LeadInviteEmail]}>
            <Typography variant='body1' grey={70}>
              {t('modals.sendEmail.willBeSentTo')}
            </Typography>
          </RestrictedToUserPermissions>
          <Box mt={1}>
            <LeadName variant='h3' component='span' grey={90}>
              {lead.firstName} {lead.lastName}
            </LeadName>

            <LeadEmail variant='body1' grey={90}>{`<${lead.email}>`}</LeadEmail>
          </Box>
          {emailValidation?.verdict && (
            <Box mt={2}>
              <Alert severity='warning'>
                {emailValidation.verdict === 'Invalid'
                  ? t('modals.sendEmail.invalidEmail')
                  : t('modals.sendEmail.confirmSend')}
              </Alert>
            </Box>
          )}
          <RestrictedToUserPermissions requiredPermissions={[Permission.LeadInvitePrivate]}>
            <Box mt={3}>
              <Button
                data-testid='sendEmailModal-copyToClipboard'
                variant='outlined'
                startIcon={<Icon name='copy' />}
                onClick={onClickCopyToClipboard}
              >
                <Typography variant='body1'>{t('modals.sendEmail.copyLink')}</Typography>
              </Button>
            </Box>
          </RestrictedToUserPermissions>
        </Fragment>
      )}
    </ModalLayout>
  );
}
