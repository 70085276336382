import { ReactElement } from 'react';

import { SvgIconProps } from '../../Components/Icons';

export function ChevronDownIcon(props: SvgIconProps): ReactElement {
  return (
    <svg
      fill='none'
      stroke='#333'
      height='6'
      width='10'
      xmlns='http://www.w3.org/2000/svg'
      {...props.svgProps}
      className={props.className}
    >
      <path d='M1 1l4 4 4-4' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
}
