import { ReactElement, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@breathelife/mui';
import { FileTemplate, IconName, Permission, StoredFileWithExistsProperty } from '@breathelife/types';
import { ImageTypes, Loader, Icon as IconFromUIComponents } from '@breathelife/ui-components';

import { IconButton } from '../../../../../../Components/Controls';
import { Icon } from '../../../../../../Components/Icons';

import { printFileSize } from '../../../../../../Helpers/fileSize';
import { useCarrierName, useGetApplicationFile, useLocale } from '../../../../../../Hooks';
import { Application } from '../../../../../../Models/Application';
import { RestrictedToUserPermissions } from '../../../../../Restricted/RestrictedToUserPermissions';

import { useTheme } from '../../../../../../Styles/themed-styled-components';
import { BasicStoredFile } from '../BasicStoredFile';

type CompletedRequiredFileProps = {
  application: Application;
  template: FileTemplate;
  storedFile: StoredFileWithExistsProperty;
  onFileDelete: (fileId: string, isRequired: boolean) => void;
};

export function CompletedRequiredFile(props: CompletedRequiredFileProps): ReactElement {
  const { application, template, storedFile, onFileDelete: onFileDeleteProp } = props;

  const platformLanguage = useLocale();
  const { t } = useTranslation();
  //  Display file name according to application language
  //  if translation does not exit: default to english name
  const localizedFileName = template.localizedName[platformLanguage] ?? template.localizedName.en;

  const { fetchFile, fileToView } = useGetApplicationFile(
    application.id,
    storedFile.id,
    useCallback(({ signedUrl }) => {
      window.open(signedUrl, '_blank')?.focus();
    }, []),
  );

  const sizeLabel = useMemo(
    () => (storedFile.sizeBytes ? printFileSize(storedFile.sizeBytes, 1) : undefined),
    [storedFile.sizeBytes],
  );

  const onFileDelete = useCallback(async () => {
    await onFileDeleteProp(storedFile.id, true);
  }, [onFileDeleteProp, storedFile.id]);

  const isLoading = fileToView.isLoading;
  const theme = useTheme();
  const carrierName = useCarrierName();

  return (
    <BasicStoredFile
      title={localizedFileName}
      subtitle={storedFile.displayName}
      subtitle2={sizeLabel && storedFile.exists ? sizeLabel : undefined}
      warning={
        !isLoading && !storedFile.exists
          ? application.submitted
            ? t('modals.assistedApplication.fileAttachment.fileDeletedByRetentionPolicy', { carrierName })
            : t('modals.assistedApplication.fileAttachment.fileNotFoundUploadAgain', { carrierName })
          : undefined
      }
      icon={
        isLoading ? (
          <Loader size='24' />
        ) : storedFile.exists ? (
          <Icon
            name='success'
            variant='green'
            inverted
            imageType={ImageTypes.meaningful}
            ariaLabel={t('assistedApplication.success')}
          />
        ) : (
          <IconFromUIComponents name={IconName.doDisturbAlt} color={{ primary: theme.colors.red[60] }} size='24px' />
        )
      }
      rightCtas={
        <Box sx={{ display: 'flex', flexWrap: 'no-wrap', alignItems: 'center' }}>
          <Box mx={1}>
            <IconButton
              icon={
                <Icon
                  name='eye'
                  imageType={ImageTypes.meaningful}
                  ariaLabel={t('modals.assistedApplication.fileAttachment.openAttachment')}
                />
              }
              disabled={isLoading}
              onClick={fetchFile}
            />
          </Box>
          {!application.submitted && (
            <RestrictedToUserPermissions requiredPermissions={[Permission.ApplicationFileRemove]}>
              <Box>
                <IconButton
                  icon={
                    <Icon
                      name='delete'
                      imageType={ImageTypes.meaningful}
                      ariaLabel={t('modals.assistedApplication.fileAttachment.deleteAttachment')}
                    />
                  }
                  disabled={isLoading}
                  onClick={onFileDelete}
                />
              </Box>
            </RestrictedToUserPermissions>
          )}
        </Box>
      }
    />
  );
}
