import { ApplicationMode, LineOfBusinessName, Permission, Questionnaire } from '@breathelife/types';

import { Grid } from '@breathelife/mui';

import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitButton } from '../../../Components/Button/SubmitButton';
import RadioGroup, { Radio } from '../../../Components/RadioGroup/RadioGroup';
import { RestrictedToUserPermissions } from '../../../Components/Restricted/RestrictedToUserPermissions';
import Typography from '../../../Components/Typography';
import { useCarrierContext, useLocale, useSelector } from '../../../Hooks';
import { ModalLayout } from '../../../Layouts/Modal/ModalLayout';
import { useGetQuestionnaires } from '../../../ReactQuery/Questionnaire/questionnaire.queries';
import { isInternalUser } from '../../../Helpers/user';

type LaunchQuestionnaireData = {
  mode: ApplicationMode;
  lineOfBusiness: LineOfBusinessName;
  questionnaireId: string;
};

type LaunchApplicationModalProps = {
  onSubmitClick: (data: LaunchQuestionnaireData) => void;
  isOpen: boolean;
  closeModal: () => void;
  disableSubmitButton?: boolean;
  requireApplicationMode: boolean;
};

export function removeLegacyQuestionnaire(questionnaires: Questionnaire[]): Questionnaire[] {
  return questionnaires.filter(
    (q) =>
      q.linesOfBusiness.filter(
        (lob) => lob.tag === LineOfBusinessName.legacyLife || lob.tag === LineOfBusinessName.legacyAnnuity,
      ).length < 1,
  );
}

export function LaunchApplicationModal(props: LaunchApplicationModalProps): ReactElement {
  const { isOpen, closeModal, disableSubmitButton, requireApplicationMode, onSubmitClick } = props;
  const { carrierInfo, monitoringEnvironment } = useCarrierContext();
  const { t } = useTranslation();
  const locale = useLocale();
  const user = useSelector((store) => store.leadPlatform.authentication.user);
  const userIsInternalUser = useMemo(() => {
    //Check is the environnement is only for testing in Serenia Life
    const allowedEnvs = ['staging', 'sandbox', 'dev'];
    if (
      (monitoringEnvironment && !allowedEnvs.includes(monitoringEnvironment)) ||
      !user?.emailLogin ||
      carrierInfo.id !== 'faithlife'
    )
      return false;
    return isInternalUser(user.emailLogin);
  }, [user]);

  const [selectedApplicationMode, setSelectedApplicationMode] = useState<ApplicationMode>(ApplicationMode.digital);
  // TODO HOT-4739: Remove lineOfBusiness from config and internal dev users
  const configLinesOfBusiness = carrierInfo.lineOfBusiness;

  const [selectedQuestionnaireId, setSelectedQuestionnaireId] = useState<string | undefined>(undefined);
  const [selectedLineOfBusiness, setSelectedLineOfBusiness] = useState<LineOfBusinessName | undefined>(undefined);

  const { data: questionnaires = [] } = useGetQuestionnaires();

  const nonLegacyQuestionnaires = useMemo(() => {
    return removeLegacyQuestionnaire(questionnaires);
  }, [questionnaires]);

  const lineOfBusinessOptions: { label: string; value: LineOfBusinessName }[] = useMemo(() => {
    if (configLinesOfBusiness?.length) {
      return configLinesOfBusiness.map((lineOfBusiness) => ({
        label: t(`modals.createButton.lineOfBusinessChoices.${lineOfBusiness}`),
        value: lineOfBusiness,
      }));
    }
    const nonLegacyLinesOfBusiness = nonLegacyQuestionnaires.reduce(
      (acc: { label: string; value: LineOfBusinessName }[], element) => {
        element.linesOfBusiness?.forEach((lineOfBusiness) => {
          const label = lineOfBusiness.name[locale];
          const exists = !!acc.find((val) => val.label === label);
          if (label && !exists) {
            acc.push({
              label,
              value: lineOfBusiness.tag,
            });
          }
        });
        return acc;
      },
      [],
    );

    const linesOfBusiness = userIsInternalUser
      ? [
          ...nonLegacyLinesOfBusiness,
          ...[
            { label: 'Legacy Annuity', value: LineOfBusinessName.legacyAnnuity },
            { label: 'Legacy Life', value: LineOfBusinessName.legacyLife },
          ],
        ]
      : nonLegacyLinesOfBusiness;
    return linesOfBusiness;
  }, [nonLegacyQuestionnaires, locale, configLinesOfBusiness, t]);

  useEffect(() => {
    if (lineOfBusinessOptions.length > 0) {
      setSelectedLineOfBusiness(lineOfBusinessOptions[0].value);
    }
  }, [lineOfBusinessOptions]);

  const questionnairesOptions: { label: string; value: string }[] = useMemo(() => {
    if (configLinesOfBusiness?.length) {
      const questionnaire = questionnaires[0];
      return [{ label: questionnaire.name[locale] || '', value: questionnaire.id }];
    }
    if (!selectedLineOfBusiness) {
      return [];
    }
    if (userIsInternalUser && (selectedLineOfBusiness === 'legacyLife' || selectedLineOfBusiness === 'legacyAnnuity')) {
      return questionnaires.reduce((acc: { label: string; value: string }[], questionnaire) => {
        const hasSelectedLineOfBusiness = questionnaire.linesOfBusiness.find(
          (lineOfBusiness) => lineOfBusiness.tag === 'legacyLife',
        );
        const label = questionnaire.name[locale];
        if (hasSelectedLineOfBusiness && label) {
          acc.push({ label, value: questionnaire.id });
        }
        return acc;
      }, []);
    }
    return questionnaires.reduce((acc: { label: string; value: string }[], questionnaire) => {
      const hasSelectedLineOfBusiness = questionnaire.linesOfBusiness.find(
        (lineOfBusiness) => lineOfBusiness.tag === selectedLineOfBusiness,
      );
      const label = questionnaire.name[locale];
      if (hasSelectedLineOfBusiness && label) {
        acc.push({ label, value: questionnaire.id });
      }
      return acc;
    }, []);
  }, [selectedLineOfBusiness, questionnaires, locale, configLinesOfBusiness]);

  useEffect(() => {
    if (questionnairesOptions.length === 1) {
      setSelectedQuestionnaireId(questionnairesOptions[0].value);
    } else {
      setSelectedQuestionnaireId(undefined);
    }
  }, [questionnairesOptions]);

  const isValid = useMemo(() => {
    if (
      !selectedLineOfBusiness ||
      !selectedApplicationMode ||
      (!selectedQuestionnaireId && questionnaires.length !== 1)
    ) {
      return false;
    }
    return true;
  }, [questionnaires.length, selectedApplicationMode, selectedLineOfBusiness, selectedQuestionnaireId]);

  const handleSubmit = useCallback(() => {
    if (!selectedLineOfBusiness || !selectedApplicationMode || !selectedQuestionnaireId) {
      return;
    }

    onSubmitClick({
      mode: selectedApplicationMode,
      questionnaireId: selectedQuestionnaireId,
      lineOfBusiness: selectedLineOfBusiness,
    });
  }, [selectedLineOfBusiness, selectedQuestionnaireId, selectedApplicationMode, onSubmitClick]);

  return (
    <ModalLayout
      maxWidth='sm'
      isOpen={isOpen}
      closeModal={closeModal}
      title={t('modals.createButton.title')}
      submitButton={
        <RestrictedToUserPermissions requiredPermissions={[Permission.LeadCreate, Permission.ApplicationLaunchMe]}>
          <SubmitButton
            onClick={handleSubmit}
            disabled={disableSubmitButton || !isValid}
            data-testid='launchApplicationFromModal'
          >
            {t('cta.launchApplication')}
          </SubmitButton>
        </RestrictedToUserPermissions>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant='h3' grey={90}>
            {t('modals.createButton.lineOfBusiness')}
          </Typography>

          <RadioGroup
            label={t('modals.createButton.lineOfBusinessLabel')}
            name='createButton-lineOfBusiness'
            onChange={setSelectedLineOfBusiness}
            value={selectedLineOfBusiness}
          >
            <Grid container spacing={1}>
              {lineOfBusinessOptions.map((choice) => {
                return (
                  <Grid item sm={6} key={choice.value}>
                    <Radio value={choice.value} label={choice.label} />
                  </Grid>
                );
              })}
            </Grid>
          </RadioGroup>
        </Grid>

        {questionnairesOptions.length > 1 && (
          <Grid item xs={12}>
            <Typography variant='h3' grey={90}>
              {t('modals.createButton.formType')}
            </Typography>

            <RadioGroup
              label={t('modals.createButton.formTypeLabel')}
              name='createButton-form-type'
              onChange={setSelectedQuestionnaireId}
              value={selectedQuestionnaireId}
            >
              <Grid container spacing={1}>
                {questionnairesOptions.map((choice) => {
                  return (
                    <Grid item sm={6} key={choice.value}>
                      <Radio value={choice.value} label={choice.label} />
                    </Grid>
                  );
                })}
              </Grid>
            </RadioGroup>
          </Grid>
        )}

        {requireApplicationMode && (
          <Grid item xs={12}>
            <Typography variant='h3' grey={90}>
              {t('modals.createButton.applicationMode')}
            </Typography>

            <RadioGroup<ApplicationMode>
              label={t('modals.createButton.applicationModeLabel')}
              name='createButton-applicationMode'
              onChange={(value) => setSelectedApplicationMode(value)}
              value={selectedApplicationMode}
            >
              <Grid container spacing={1}>
                {[ApplicationMode.digital, ApplicationMode.paper].map((choice) => {
                  return (
                    <Grid item sm={6} key={choice}>
                      <Radio<ApplicationMode> value={choice} label={t(`applicationModes.${choice}`)} />
                    </Grid>
                  );
                })}
              </Grid>
            </RadioGroup>
          </Grid>
        )}
      </Grid>
    </ModalLayout>
  );
}
