import * as yup from 'yup';

export type RecursiveNullable<T> = T extends Record<string, any>
  ?
      | {
          [key in keyof T]: RecursiveNullable<T[key]>;
        }
      | null
      | undefined
  : T | null | undefined;

export function objectSchemaFieldsAreDefined<T extends object>(
  schema: yup.ObjectSchema<T | null | undefined, object>,
): schema is yup.ObjectSchema<T, object> {
  return schema.fields !== undefined;
}
