import { ReactElement, createContext, PropsWithChildren, useMemo } from 'react';

import { NodeDetail } from '../../../Helpers/questionnaireEditor/questionnaireNodeIds';

import { useFetchAllQuestionnaireNodeIdsQuery } from '../../../ReactQuery/Admin/Questionnaire/questionnaireNodeIds.queries';

type QuestionnaireNodeIdsContextValue = {
  nodeIdsForAllQuestionnaires: NodeDetail[];
};

const initialValue: QuestionnaireNodeIdsContextValue = {
  nodeIdsForAllQuestionnaires: [],
};

export const AllQuestionnairesNodeIdsContext = createContext<QuestionnaireNodeIdsContextValue>(initialValue);

export function AllQuestionnairesNodeIdsContextProvider({
  children,
}: PropsWithChildren<Record<string, unknown>>): ReactElement {
  const { data: nodeIds } = useFetchAllQuestionnaireNodeIdsQuery();

  const nodeIdsForAllQuestionnaires = useMemo(() => {
    return (nodeIds || []).map((nodeId) => ({
      answerNodeId: nodeId,
      collectionNodeIds: [],
    }));
  }, [nodeIds]);

  return (
    <AllQuestionnairesNodeIdsContext.Provider value={{ nodeIdsForAllQuestionnaires }}>
      {children}
    </AllQuestionnairesNodeIdsContext.Provider>
  );
}
