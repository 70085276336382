import { ReactNode, ReactElement, useMemo } from 'react';

import { Alert as MuiAlert } from '@breathelife/mui';

import { useTheme } from '../../Styles/themed-styled-components';
import { Icon } from '../../Components/Icons';
import { LeadPlatformTheme } from '../../Styles/Types';

export type Severity = 'success' | 'warning' | 'error' | 'info';

type Props = {
  onClose?: () => void;
  severity: Severity;
  children: ReactNode;
};

type StylingProps = { fill: string; stroke: string };

const AlertIcon: {
  [variant in Severity]: (props: StylingProps) => ReactElement;
} = {
  success: function (props: StylingProps) {
    return <Icon name='success' stroke={props.stroke} fill={props.fill} />;
  },
  warning: function (props: StylingProps) {
    return <Icon name='warning' stroke={props.stroke} fill={props.fill} />;
  },
  error: function (props: StylingProps) {
    return <Icon name='error' stroke={props.stroke} fill={props.fill} />;
  },
  info: function (props: StylingProps) {
    return <Icon name='info' stroke={props.stroke} fill={props.fill} />;
  },
};

function handleSeverity(severity: Severity, theme: LeadPlatformTheme): StylingProps {
  const fill = theme.colors.grey[0];
  switch (severity) {
    case 'success':
      return { stroke: theme.colors.green[60], fill };
    case 'info':
      return { stroke: theme.colors.blue[60], fill };
    case 'warning':
      return { stroke: theme.colors.yellow[60], fill };
    case 'error':
      return { stroke: theme.colors.red[60], fill };
  }
}

export function Alert(props: Props): ReactElement {
  const { onClose, severity, children } = props;
  const theme = useTheme();

  const icon = useMemo(() => {
    const Icon = AlertIcon[severity];
    const stylingProps = handleSeverity(severity, theme);

    return <Icon {...stylingProps} />;
  }, [severity, theme]);

  return (
    <MuiAlert variant='outlined' severity={severity} icon={icon} onClose={onClose}>
      {children}
    </MuiAlert>
  );
}
