import { ReactElement } from 'react';

import { EditDocumentRule } from './EditDocumentRule';
import { NewDocumentRule } from './NewDocumentRule';
import { useFindFileTemplateRulesQuery } from '../../../../../ReactQuery/Document/DocumentRule/documentRule.queries';

type Props = {
  questionnaireVersionId: string;
  fileTemplateId: string;
};

export function DocumentRulesContainer(props: Props): ReactElement {
  const { questionnaireVersionId, fileTemplateId } = props;

  const { data: fileTemplateRules } = useFindFileTemplateRulesQuery(questionnaireVersionId, fileTemplateId);

  return fileTemplateRules && fileTemplateRules.length > 0 ? (
    <EditDocumentRule fileTemplateRule={fileTemplateRules[0]} />
  ) : (
    <NewDocumentRule fileTemplateId={fileTemplateId} questionnaireVersionId={questionnaireVersionId} />
  );
}
