import i18next from 'i18next';

import { ConsiderationBlueprint, ConsiderationBlueprintRowData, PageQueryOptions } from '@breathelife/types';

import { salesDecisionRuleManagementSlice } from '../../../ReduxStore/Admin/SalesDecisionRulesManagement/SalesDecisionRulesManagementSlice';
import { notificationSlice } from '../../../ReduxStore/Notification/NotificationSlice';
import { Dispatch } from '../../../ReduxStore/types';
import ApiService from '../../../Services/ApiService';

export const fetchRules =
  (
    questionnaireVersionId: string,
    options: PageQueryOptions<ConsiderationBlueprintRowData> = {},
    isForReload: boolean = false,
  ) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(salesDecisionRuleManagementSlice.actions.setIsLoadingRules({ isLoading: true, isForReload }));

    try {
      const { data: rules } = await ApiService.admin.fetchSalesDecisionRules({ questionnaireVersionId, ...options });
      dispatch(salesDecisionRuleManagementSlice.actions.setRules({ rules: rules.data }));
      dispatch(salesDecisionRuleManagementSlice.actions.setTotal({ total: rules.total }));
    } catch (error: any) {
      dispatch(
        notificationSlice.actions.setError({
          message: i18next.t('notifications.failedToFetchEntity', {
            entity: i18next.t('admin.salesDecisionRulesManagement.salesDecisionRule.plural'),
          }),
        }),
      );
    } finally {
      dispatch(salesDecisionRuleManagementSlice.actions.setIsLoadingRules({ isLoading: false, isForReload }));
    }
  };

export const createRule =
  (questionnaireVersionId: string, blueprint: ConsiderationBlueprint, identifier: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    const entity = i18next.t('admin.salesDecisionRulesManagement.salesDecisionRule.singular');

    dispatch(salesDecisionRuleManagementSlice.actions.setIsLoadingRules({ isLoading: true, isForReload: true }));

    try {
      const { data: rule } = await ApiService.admin.createSalesDecisionRule({
        questionnaireVersionId,
        blueprint,
        identifier,
      });
      dispatch(
        notificationSlice.actions.setSuccess({
          message: i18next.t('notifications.entityCreationSuccess', { entity }),
          autoHideDuration: 3000,
        }),
      );
      dispatch(salesDecisionRuleManagementSlice.actions.addRule({ rule }));
    } catch (error: any) {
      dispatch(
        notificationSlice.actions.setError({
          message: `${i18next.t('notifications.failedToCreateEntity', { entity })}: ${error.message}`,
        }),
      );
    } finally {
      dispatch(salesDecisionRuleManagementSlice.actions.setIsLoadingRules({ isLoading: false, isForReload: true }));
    }
  };

export const updateRule =
  (id: string, blueprint: ConsiderationBlueprint, identifier: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    const entity = i18next.t('admin.salesDecisionRulesManagement.salesDecisionRule.singular');

    dispatch(salesDecisionRuleManagementSlice.actions.setIsLoadingRules({ isLoading: true, isForReload: true }));

    try {
      const { data: rule } = await ApiService.admin.updateSalesDecisionRule(id, {
        ...blueprint,
        identifier,
      });
      dispatch(
        notificationSlice.actions.setSuccess({
          message: i18next.t('notifications.entityUpdateSuccess', { entity }),
          autoHideDuration: 3000,
        }),
      );
      dispatch(salesDecisionRuleManagementSlice.actions.updateRule({ rule }));
    } catch (error: any) {
      dispatch(
        notificationSlice.actions.setError({
          message: `${i18next.t('notifications.failedToUpdateEntity', { entity })}: ${error.message}`,
        }),
      );
    } finally {
      dispatch(salesDecisionRuleManagementSlice.actions.setIsLoadingRules({ isLoading: false, isForReload: true }));
    }
  };

export const deleteRule =
  (id: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    const entity = i18next.t('admin.salesDecisionRulesManagement.salesDecisionRule.singular');

    dispatch(salesDecisionRuleManagementSlice.actions.setIsLoadingRules({ isLoading: true, isForReload: true }));

    try {
      await ApiService.admin.deleteSalesDecisionRule(id);

      dispatch(
        notificationSlice.actions.setSuccess({
          message: i18next.t('notifications.entityDeleteSuccess', { entity }),
          autoHideDuration: 3000,
        }),
      );
      dispatch(salesDecisionRuleManagementSlice.actions.removeRule({ id }));
    } catch (error: any) {
      dispatch(
        notificationSlice.actions.setError({
          message: i18next.t('notifications.failedToDeleteEntity', { entity }),
        }),
      );
    } finally {
      dispatch(salesDecisionRuleManagementSlice.actions.setIsLoadingRules({ isLoading: false, isForReload: true }));
    }
  };
