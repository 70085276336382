import { CurrencyCardFieldBlueprint } from '@breathelife/types';

import { FieldDataSetterProps } from '../../../Helpers/helperTypes';
import { CurrencyCardFieldSetters, useCurrencyCardFieldData } from '../useCurrencyCardFieldData';
import { useCommonFieldDataSetters } from './useCommonFieldDataSetters';

export function CurrencyCardFieldDataSetter({
  initialData,
  dataLabelOptions,
  ...props
}: FieldDataSetterProps<CurrencyCardFieldBlueprint, CurrencyCardFieldSetters>): null {
  const { data, setters, validationOptions, defaultValueValidationError } = useCurrencyCardFieldData({
    initialData,
    dataLabelOptions,
  });

  useCommonFieldDataSetters<CurrencyCardFieldBlueprint, CurrencyCardFieldSetters>({
    ...props,
    data,
    setters,
    validationOptions,
    defaultValueValidationError,
    getIsValidBlueprint: (blueprint) => !!blueprint.validateAs,
  });
  return null;
}
