import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { QueryId } from '../../../ReactQuery/common/common.types';
import { Permission, User, UserPage } from '@breathelife/types';

import { useDispatch, useSelector } from '../../../Hooks';

import { logger } from '@breathelife/monitoring-frontend';

import { notificationSlice } from '../../../ReduxStore/Notification/NotificationSlice';
import UsersService, { getUsers, GetUsersOptions } from '../../../Services/Users/UsersService';
import { userHasPermission } from '../../../Helpers/user';

export const minimalUserFields = ['id', 'firstName', 'lastName', 'emailLogin', 'auth0Id', 'picture'];

export const extensiveUserFields = [
  'id',
  'firstName',
  'lastName',
  'emailLogin',
  'createdAt',
  'auth0Id',
  'lastLogin',
  'roles',
  'picture',
  'groupId',
  'phoneNumber',
  'customId',
  'category',
  'type',
  'status',
  'isSso',
];

export function useUsers(getOptions?: GetUsersOptions, options?: UseQueryOptions<UserPage>): UseQueryResult<UserPage> {
  const dispatch = useDispatch();
  const userPermissions =
    useSelector((store) => {
      return store.leadPlatform.authentication.user?.permissions;
    }) ?? [];

  return useQuery<UserPage>(
    [QueryId.users, getOptions || {}],

    async () => {
      return getUsers({
        includeAssociationCounts: true,
        fields: minimalUserFields,
        ...getOptions,
      });
    },
    {
      ...options,
      enabled: userHasPermission(userPermissions, Permission.UserListAll),
      onError(err: any) {
        dispatch(
          notificationSlice.actions.setError({
            message: err.message,
          }),
        );
        logger.error(err);
        options?.onError?.(err);
      },
    },
  );
}

export function useUser(
  userId?: string,
  options?: UseQueryOptions<User | undefined>,
): UseQueryResult<User | undefined> {
  const dispatch = useDispatch();

  return useQuery<User | undefined>(
    [QueryId.users, { userId }],
    async () => {
      if (!userId) {
        return undefined;
      }
      return UsersService.getUser(userId);
    },
    {
      ...options,
      onError(err: any) {
        dispatch(
          notificationSlice.actions.setError({
            message: err.message,
          }),
        );
        logger.error(err);
        options?.onError?.(err);
      },
    },
  );
}
