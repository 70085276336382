import { Fragment, ReactElement } from 'react';

import { Grid } from '@breathelife/mui';
import { NumberComparisonConditionValue } from '@breathelife/types';

import {
  getConditionValidationError,
  ValidationValues,
} from '../../../Helpers/inputValidation/form/salesDecisionRules';
import { CollectionOperators } from '../Helpers/CollectionOperators';
import { ConditionTargetNodeAutocomplete } from '../Helpers/ConditionTargetNodeAutocomplete';
import { NumberComparisonConditionOperatorSelect } from '../Helpers/NumberComparisonConditionOperatorSelect';
import { NumberValueOrNodeIdInput } from '../Helpers/NumberValueOrNodeIdInput';
import { ConditionCriteriaProps } from '../types';
import { ValidationErrorText } from '../ValidationErrorText';

export function NumberComparisonConditionCriteria({
  condition,
  nodeIdList,
  nodeIdInCollectionMap,
  collectionContext,
  onConditionPropertiesChange,
  language,
  conditionIndex,
}: ConditionCriteriaProps<NumberComparisonConditionValue>): ReactElement {
  const validationErrors = getConditionValidationError(condition, nodeIdList, nodeIdInCollectionMap);

  const collectionNodeIds = nodeIdInCollectionMap[condition.targetNodeId] ?? [];

  return (
    <Fragment>
      <Grid item xs={12}>
        <ConditionTargetNodeAutocomplete
          value={condition.targetNodeId}
          nodeIdList={nodeIdList}
          onSelect={(targetNodeId) => {
            if (targetNodeId !== null) {
              onConditionPropertiesChange({ targetNodeId });
            }
          }}
          validationError={validationErrors?.[ValidationValues.targetNodeId]}
          selectedLanguage={language}
        />
      </Grid>
      <Grid item xs={12}>
        <NumberComparisonConditionOperatorSelect
          value={condition.operator}
          onChange={(operator) => {
            onConditionPropertiesChange({ operator });
          }}
        />
        {validationErrors?.[ValidationValues.operator] && (
          <ValidationErrorText>{validationErrors[ValidationValues.operator].message}</ValidationErrorText>
        )}
      </Grid>
      <NumberValueOrNodeIdInput
        nodeIdList={nodeIdList}
        numberValue={condition.value}
        nodeIdOfValue={condition.nodeIdOfValue}
        onChange={({ numberValue, nodeIdOfValue }) =>
          onConditionPropertiesChange({ value: numberValue, nodeIdOfValue })
        }
        language={language}
        numberValueValidationError={validationErrors && validationErrors[ValidationValues.value]}
        nodeIdOfValueValidationError={validationErrors && validationErrors[ValidationValues.nodeIdOfValue]}
      />
      <CollectionOperators
        collectionContext={collectionContext}
        collectionNodeIds={collectionNodeIds}
        collectionOperators={condition.collectionOperators}
        onConditionPropertiesChange={onConditionPropertiesChange}
        conditionIndex={conditionIndex}
      />
    </Fragment>
  );
}
