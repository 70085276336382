import { ReactElement } from 'react';

import { SvgIconProps } from '../../Components/Icons';

export function EmailIcon(props: SvgIconProps): ReactElement {
  return (
    <svg stroke='#757575' fill='#f6f6f6' height='16' width='16' xmlns='http://www.w3.org/2000/svg' {...props.svgProps}>
      <g strokeLinecap='round' strokeLinejoin='round'>
        <path d='M1.319 1.135a1 1 0 00-.386 1.36l3.372 5.5h10.893a.5.5 0 00-.273-.445L2.249 1.11a1 1 0 00-.93.025zM14.925 8.441a.495.495 0 00.273-.446H4.305l-3.372 5.51a1 1 0 001.316 1.384z' />
      </g>
    </svg>
  );
}
