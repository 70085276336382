import { ReactElement, Fragment, memo } from 'react';

import { RenderingQuestion, RenderingSubsection } from '@breathelife/questionnaire-engine';

import { FieldGroupContainer } from './FieldGroupContainer';
import { SubsectionHeader } from './SubsectionHeader';

type SubsectionViewProps = {
  subsection: RenderingSubsection;
};

export const SubsectionView = memo(InnerSubsectionView);

export function InnerSubsectionView(props: SubsectionViewProps): ReactElement | null {
  const { subsection } = props;

  if (!subsection.visible) return null;

  return (
    <Fragment>
      <SubsectionHeader title={subsection.title} text={subsection.text} />

      {subsection.questions.map((question: RenderingQuestion) => (
        <FieldGroupContainer key={question.id} question={question} />
      ))}
    </Fragment>
  );
}
