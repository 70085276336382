import _ from 'lodash';
import { JSXElementConstructor, ReactElement, SVGProps } from 'react';
import styled, { useTheme } from '../../Styles/themed-styled-components';

import { getHtmlAttributesForImageType, ImageTypes } from '@breathelife/ui-components';

import { AddAssuredIcon } from '../../Assets/Icons/AddAssuredIcon';
import { AddIcon } from '../../Assets/Icons/AddIcon';
import { ArchiveIcon } from '../../Assets/Icons/ArchiveIcon';
import { ArrowDownIcon } from '../../Assets/Icons/ArrowDownIcon';
import { AssignIcon } from '../../Assets/Icons/AssignIcon';
import { BlockUserIcon } from '../../Assets/Icons/BlockUserIcon';
import { ChevronDownIcon } from '../../Assets/Icons/ChevronDownIcon';
import { CircledCheckmarkIcon } from '../../Assets/Icons/CircledCheckmarkIcon';
import { CircledSendIcon } from '../../Assets/Icons/CircledSendIcon';
import { CircledWarningIcon } from '../../Assets/Icons/CircledWarningIcon';
import { CirclefilledWarningIcon } from '../../Assets/Icons/CirclefilledWarningIcon';
import { CloudDownloadIcon } from '../../Assets/Icons/CloudDownloadIcon';
import { CopyIcon } from '../../Assets/Icons/CopyIcon';
import { DeleteIcon } from '../../Assets/Icons/DeleteIcon';
import { DownloadIcon } from '../../Assets/Icons/DownloadIcon';
import { EmailIcon } from '../../Assets/Icons/EmailIcon';
import { EmailSubscriptionStatusIcon } from '../../Assets/Icons/EmailSubscriptionStatusIcon';
import { EmptyProductListIcon } from '../../Assets/Icons/EmptyProductListIcon';
import { ErrorIcon } from '../../Assets/Icons/ErrorIcon';
import { EyeIcon } from '../../Assets/Icons/EyeIcon';
import { FilesIcon } from '../../Assets/Icons/FilesIcon';
import { IdCheckIcon } from '../../Assets/Icons/IdCheckIcon';
import { InfoIcon } from '../../Assets/Icons/InfoIcon';
import { LeftArrowIcon } from '../../Assets/Icons/LeftArrowIcon';
import { LicensesIcon } from '../../Assets/Icons/LicensesIcon';
import { LinkIcon } from '../../Assets/Icons/LinkIcon';
import { LoginKeyIcon } from '../../Assets/Icons/LoginKeyIcon';
import { LogoBreatheLifeSmallIcon } from '../../Assets/Icons/LogoBreatheLifeSmallIcon';
import { LogoutIcon } from '../../Assets/Icons/LogoutIcon';
import { MuiVisibilityIcon } from '../../Assets/Icons/MuiVisibilityIcon';
import { MuiVisibilityOffIcon } from '../../Assets/Icons/MuiVisibilityOffIcon';
import { NotificationIcon } from '../../Assets/Icons/NotificationIcon';
import { PdfIcon } from '../../Assets/Icons/PdfIcon';
import { ProfileIcon } from '../../Assets/Icons/ProfileIcon';
import { PushLeftIcon } from '../../Assets/Icons/PushLeftIcon';
import { PushRightIcon } from '../../Assets/Icons/PushRightIcon';
import { RefreshIcon } from '../../Assets/Icons/RefreshIcon';
import { RightArrowIcon } from '../../Assets/Icons/RightArrowIcon';
import { SuccessIcon } from '../../Assets/Icons/SuccessIcon';
import { UnarchiveIcon } from '../../Assets/Icons/UnarchiveIcon';
import { UnassignedProfilePictureIcon } from '../../Assets/Icons/UnassignedProfilePictureIcon';
import { UnblockUserIcon } from '../../Assets/Icons/UnblockUserIcon';
import { UploadIcon } from '../../Assets/Icons/UploadIcon';
import { WarningIcon } from '../../Assets/Icons/WarningIcon';
import { LeadPlatformTheme, PlatformColorList } from '../../Styles/Types';
import { Variant } from './types';
import { NotificationActionIcon } from '../../Assets/Icons/NotificationActionIcon';
import { NotificationArrowUpIcon } from '../../Assets/Icons/NotificationArrowUpIcon';
import { NotificationArrowDownIcon } from '../../Assets/Icons/NotificationArrowDownIcon';
import { NotificationMarkAsUnreadEyeIcon } from '../../Assets/Icons/NotificationMarkAsUnreadEyeIcon';
import { NotificationBellIcon } from '../../Assets/Icons/NotificationBellIcon';
import { NotificationInfoIcon } from '../../Assets/Icons/NotificationInfoIcon';
import { NotificationFeedbackIcon } from '../../Assets/Icons/NotificationFeedbackIcon';
import { NotificationUnreadDotIcon } from '../../Assets/Icons/NotificationUnreadDotIcon';

export type SvgIconProps = {
  svgProps: SVGProps<SVGSVGElement>;
  title?: string;
  className?: string;
};

export const IconMap: Record<
  string,
  (props: SvgIconProps) => ReactElement<SvgIconProps, string | JSXElementConstructor<SvgIconProps>>
> = {
  visibility: MuiVisibilityIcon,
  visibilityOff: MuiVisibilityOffIcon,
  add: AddIcon,
  addAssured: AddAssuredIcon,
  allUsersProfilePicture: UnassignedProfilePictureIcon,
  archive: ArchiveIcon,
  assignTo: AssignIcon,
  // @ts-ignore TODO: FIXME: fix type
  breatheLifeLogoSmall: LogoBreatheLifeSmallIcon,
  loginKey: LoginKeyIcon,
  copy: CopyIcon,
  download: DownloadIcon,
  delete: DeleteIcon,
  email: EmailIcon,
  emailSubscriptionStatus: EmailSubscriptionStatusIcon,
  error: ErrorIcon,
  eye: EyeIcon,
  files: FilesIcon,
  filledCaret: ArrowDownIcon,
  info: InfoIcon,
  link: LinkIcon,
  logout: LogoutIcon,
  pdf: PdfIcon,
  profile: ProfileIcon,
  success: SuccessIcon,
  unarchive: UnarchiveIcon,
  unassignedProfilePicture: UnassignedProfilePictureIcon,
  upload: UploadIcon,
  warning: WarningIcon,
  blockUser: BlockUserIcon,
  unblockUser: UnblockUserIcon,
  notification: NotificationIcon,
  leftArrow: LeftArrowIcon,
  rightArrow: RightArrowIcon,
  idCheck: IdCheckIcon,
  circleFilledWarning: CirclefilledWarningIcon,
  circledCheckMark: CircledCheckmarkIcon,
  circledWarning: CircledWarningIcon,
  circleSendIcon: CircledSendIcon,
  pushLeft: PushLeftIcon,
  pushRight: PushRightIcon,
  chevronDown: ChevronDownIcon,
  refresh: RefreshIcon,
  licensesIcon: LicensesIcon,
  cloudDownload: CloudDownloadIcon,
  documentWithCheck: EmptyProductListIcon,
  notificationBellIcon: NotificationBellIcon,
  notificationInfoIcon: NotificationInfoIcon,
  notificationActionIcon: NotificationActionIcon,
  notificationArrowUpIcon: NotificationArrowUpIcon,
  notificationArrowDownIcon: NotificationArrowDownIcon,
  notificationMarkAsUnreadEyeIcon: NotificationMarkAsUnreadEyeIcon,
  notificationFeedbackIcon: NotificationFeedbackIcon,
  notificationUnreadDotIcon: NotificationUnreadDotIcon,
};

export type IconProps = {
  name: keyof typeof IconMap;
  variant?: Variant | PlatformColorList | 'primary-inverted' | 'secondary-inverted' | 'dark';
  stroke?: string;
  fill?: string;
  alt?: string;
  rotation?: number;
  width?: string;
  height?: string;
  className?: string;
  id?: string;
  title?: string;
  inverted?: boolean;
  imageType?: ImageTypes;
  ariaLabel?: string;
};

const handleVariant = (
  theme: LeadPlatformTheme,
  variant?: Variant | PlatformColorList | 'primary-inverted' | 'secondary-inverted' | 'dark',
  inverted?: boolean,
): { stroke: string; fill: string } => {
  let stroke = theme.colors.grey[inverted ? 20 : 70];
  let fill = theme.colors.grey[inverted ? 70 : 20];

  switch (variant) {
    case 'light':
      stroke = theme.colors.grey[inverted ? 0 : 50];
      fill = theme.colors.grey[inverted ? 50 : 0];
      break;
    case 'dark':
      stroke = theme.colors.grey[100];
      fill = theme.colors.grey[100];
      break;
    case 'primary':
    case 'secondary':
    case 'green':
    case 'red':
    case 'yellow':
    case 'blue':
    case 'purple':
    case 'grey':
    case 'orange':
      stroke = theme.colors[variant][inverted ? 20 : 50];
      fill = theme.colors[variant][inverted ? 50 : 20];
      break;
    case 'primary-inverted':
      stroke = theme.colors.grey[inverted ? 40 : 0];
      if (inverted) {
        fill = theme.colors.grey[0];
      } else {
        fill = theme.colors.primary[40];
      }
      break;
    case 'secondary-inverted':
      stroke = theme.colors.grey[inverted ? 40 : 0];
      if (inverted) {
        fill = theme.colors.grey[0];
      } else {
        fill = theme.colors.secondary[40];
      }
      break;
  }

  return { fill, stroke };
};

const IconContainer = styled.div<{ rotation?: number; width?: string; height?: string }>`
  transform: ${(props) => `rotateX(${props.rotation ?? 0}deg)`};
  transition: transform ${(props) => `${props.theme.animation.speed.fast} ${props.theme.animation.transition}`};
  display: inline-flex;
  svg {
    width: ${(props) => props.width};
    height: ${(props) => props.height};
  }
`;

function constructAttributesForImageType({
  imageType,
  attributes,
}: {
  imageType: ImageTypes;
  attributes: Record<string, string>;
}): Record<string, string> | undefined {
  if (imageType === ImageTypes.meaningful) {
    return {
      ['aria-label']: attributes['aria-label'],
      ['role']: attributes['role'],
    };
  }

  return undefined;
}

export function Icon(props: IconProps): ReactElement {
  const theme = useTheme() as LeadPlatformTheme;
  let { stroke, fill } = handleVariant(theme, props.variant, props.inverted);

  if (props.stroke) stroke = props.stroke;
  if (props.fill) fill = props.fill;

  const IconToRender = IconMap[props.name];

  const size = _.pickBy(
    {
      height: props.height,
      width: props.width,
    },
    _.identity,
  );

  const { imageType = ImageTypes.meaningful, ariaLabel } = props;

  const additionalAttributesByImageType = constructAttributesForImageType({
    imageType,
    attributes: {
      ['aria-label']: ariaLabel ?? '',
      ['role']: 'img',
    },
  });

  const imageWithAttributes = getHtmlAttributesForImageType({
    imageType,
    additionalAttributes: additionalAttributesByImageType,
  });

  return (
    <IconContainer rotation={props.rotation} height={props.height} width={props.width} className={props.className}>
      <IconToRender
        svgProps={{
          stroke,
          fill,
          ...size,
          id: props.id,
          ...imageWithAttributes,
        }}
        title={props.title || ''}
      />
    </IconContainer>
  );
}
