import {
  ComprehensivePricingCurrency,
  ComprehensivePricingPartyRole,
  ComprehensivePricingSupportedPaymentModes,
  ComprehensivePricingCosts,
} from '@breathelife/types';
import { getLanguageRegion } from '../../../../Localization/utils';
import { t } from 'i18next';

export function costToCostWithCurrency(
  cost: number,
  currency: ComprehensivePricingCurrency,
  removeTrailingZerosIfInteger?: boolean,
): string {
  return new Intl.NumberFormat(getLanguageRegion(), {
    currency,
    style: 'currency',
    maximumFractionDigits: 2,
    ...(removeTrailingZerosIfInteger && { trailingZeroDisplay: 'stripIfInteger' }),
  }).format(cost);
}

export function formatPartyRole(roles: ComprehensivePricingPartyRole[]): string {
  const displayNames = roles.map((role) => {
    const roleDisplayName = t(`assistedApplication.comprehensivePricingWidget.partyRoles.${role.roleCode}`);
    return roleDisplayName + (role.roleIndex ? ` ${role.roleIndex}` : '');
  });
  return `${displayNames.join(', ')}`;
}

const paymentModeToCostKeyMap: Record<ComprehensivePricingSupportedPaymentModes, keyof ComprehensivePricingCosts> = {
  [ComprehensivePricingSupportedPaymentModes.annual]: 'annualCost',
  [ComprehensivePricingSupportedPaymentModes.quarterly]: 'quarterlyCost',
  [ComprehensivePricingSupportedPaymentModes.monthly]: 'monthlyCost',
  [ComprehensivePricingSupportedPaymentModes.semiAnnual]: 'semiAnnualCost',
  [ComprehensivePricingSupportedPaymentModes.singlePremium]: 'singlePremiumCost',
};

export function paymentModeToModeToCost(
  paymentMode: ComprehensivePricingSupportedPaymentModes,
  comprehensivePricingCosts: ComprehensivePricingCosts,
  currency: ComprehensivePricingCurrency,
): number | undefined {
  return comprehensivePricingCosts[paymentModeToCostKeyMap[paymentMode]]?.[currency];
}
