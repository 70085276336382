import { ReactElement } from 'react';
import { TransferList } from '../../../Components/TransferList/TransferList';

export function PricingFieldIdentifiersManagementSelector({
  onFieldIdentifiersSelectionChange,
  availableFieldIdentifiers,
  pricingFieldIdentifiers,
}: {
  onFieldIdentifiersSelectionChange: (values: string[]) => void;
  availableFieldIdentifiers: string[];
  pricingFieldIdentifiers: string[];
}): ReactElement | null {
  return (
    <TransferList
      selected={pricingFieldIdentifiers}
      notSelected={availableFieldIdentifiers}
      onChange={onFieldIdentifiersSelectionChange}
    />
  );
}
