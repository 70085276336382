import _ from 'lodash';
import { Fragment, ReactElement, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid } from '@breathelife/mui';
import { AgeRangeConditionValue, AgeRoundingType, DateUnit } from '@breathelife/types';

import { NumberInput } from '../../../Components/NumberInput/NumberInput';
import {
  getConditionValidationError,
  ValidationValues,
} from '../../../Helpers/inputValidation/form/salesDecisionRules';
import { Select } from '../../Select/Select';
import { CollectionOperators } from '../Helpers/CollectionOperators';
import { ConditionTargetNodeAutocomplete } from '../Helpers/ConditionTargetNodeAutocomplete';
import { ConditionCriteriaProps } from '../types';
import { ValidationErrorText } from '../ValidationErrorText';

type Unit = AgeRangeConditionValue['value']['unit'];
type RoundingType = NonNullable<AgeRangeConditionValue['roundingType']>;

const DEFAULT_UNIT = DateUnit.year;
const DEFAULT_ROUNDING_TYPE = AgeRoundingType.none;
const DEFAULT_ROUNDING_TYPE_FOR_YEARS = AgeRoundingType.closestBirthday;

export function AgeRangeConditionCriteria({
  condition,
  nodeIdList,
  nodeIdInCollectionMap,
  collectionContext,
  onConditionPropertiesChange,
  language,
  conditionIndex,
}: ConditionCriteriaProps<AgeRangeConditionValue>): ReactElement {
  const { t } = useTranslation();
  const validationErrors = getConditionValidationError(condition, nodeIdList, nodeIdInCollectionMap);

  const [minAge, setMinAge] = useState<number | undefined>(condition.value.minAge);
  const [maxAge, setMaxAge] = useState<number | undefined>(condition.value.maxAge);
  const [unit, setUnit] = useState<Unit>(condition.value.unit ?? DEFAULT_UNIT);
  const [ageRoundingType, setAgeRoundingType] = useState<RoundingType>(
    condition.roundingType ??
      (condition.value.unit === DateUnit.year ? DEFAULT_ROUNDING_TYPE_FOR_YEARS : DEFAULT_ROUNDING_TYPE),
  );

  const save = useCallback(
    (
      newMinAge?: number,
      newMaxAge?: number,
      newUnit: Unit = DEFAULT_UNIT,
      newAgeRoundingType: RoundingType = DEFAULT_ROUNDING_TYPE,
    ) => {
      if (typeof newMinAge === 'undefined' || typeof newMaxAge === 'undefined') return;
      onConditionPropertiesChange({
        value: { minAge: newMinAge, maxAge: newMaxAge, unit: newUnit },
        roundingType: newAgeRoundingType,
      });
    },
    [onConditionPropertiesChange],
  );

  const onBlurNumber = useCallback(() => {
    save(minAge, maxAge, unit, ageRoundingType);
  }, [save, minAge, maxAge, unit, ageRoundingType]);

  const updateAgeRoundingType = useCallback(
    (newAgeRoundingType: RoundingType) => {
      setAgeRoundingType(newAgeRoundingType);
      save(minAge, maxAge, unit, newAgeRoundingType);
    },
    [save, minAge, maxAge, unit],
  );

  const updateUnit = useCallback(
    (newUnit: Unit) => {
      setUnit(newUnit);
      const newAgeRoundingType = newUnit === DateUnit.year ? DEFAULT_ROUNDING_TYPE_FOR_YEARS : DEFAULT_ROUNDING_TYPE;
      setAgeRoundingType(newAgeRoundingType);
      save(minAge, maxAge, newUnit, newAgeRoundingType);
    },
    [save, minAge, maxAge],
  );

  const collectionNodeIds = nodeIdInCollectionMap[condition.targetBirthdateNodeId] ?? [];

  const isValidValidationError = _.isObject(validationErrors);

  return (
    <Fragment>
      <Grid item xs={12}>
        <ConditionTargetNodeAutocomplete
          value={condition.targetBirthdateNodeId}
          nodeIdList={nodeIdList}
          onSelect={(targetNodeId) => {
            if (targetNodeId) {
              onConditionPropertiesChange({ targetBirthdateNodeId: targetNodeId });
            }
          }}
          validationError={
            isValidValidationError ? validationErrors[ValidationValues.targetBirthdateNodeId] : undefined
          }
          selectedLanguage={language}
        />
      </Grid>
      <Grid item xs={4}>
        <Select
          id='date-unit-select'
          value={unit}
          onChange={updateUnit}
          options={[
            { value: DateUnit.year, label: t('admin.conditions.labels.years') as string },
            { value: DateUnit.month, label: t('admin.conditions.labels.months') as string },
            { value: DateUnit.day, label: t('admin.conditions.labels.days') as string },
          ]}
          label={t('admin.conditions.labels.ageUnit')}
        />
      </Grid>
      <Grid item xs={4}>
        <NumberInput
          inputVariant='outlined'
          value={minAge}
          onNumberChange={setMinAge}
          label={t('admin.conditions.labels.minAge')}
          onBlur={onBlurNumber}
          error={isValidValidationError && !!validationErrors?.[ValidationValues.minAgeValue]}
        />
        {isValidValidationError && validationErrors?.[ValidationValues.minAgeValue] && (
          <ValidationErrorText>{validationErrors?.[ValidationValues.minAgeValue]?.message}</ValidationErrorText>
        )}
      </Grid>
      <Grid item xs={4}>
        <NumberInput
          inputVariant='outlined'
          value={maxAge}
          onNumberChange={setMaxAge}
          label={t('admin.conditions.labels.maxAge')}
          onBlur={onBlurNumber}
          error={isValidValidationError && !!validationErrors?.[ValidationValues.maxAgeValue]}
        />
        {isValidValidationError && validationErrors?.[ValidationValues.maxAgeValue] && (
          <ValidationErrorText>{validationErrors?.[ValidationValues.maxAgeValue]?.message}</ValidationErrorText>
        )}
      </Grid>
      {unit === DateUnit.year && (
        <Grid item xs={4}>
          <Select
            id='age-rounding-select'
            value={ageRoundingType}
            onChange={updateAgeRoundingType}
            options={[
              { value: AgeRoundingType.none, label: t('admin.conditions.labels.roundingTypeNone') as string },
              {
                value: AgeRoundingType.closestBirthday,
                label: t('admin.conditions.labels.roundingTypeClosestBirthday') as string,
              },
              {
                value: AgeRoundingType.nextBirthday,
                label: t('admin.conditions.labels.roundingTypeNextBirthday') as string,
              },
              {
                value: AgeRoundingType.lastBirthday,
                label: t('admin.conditions.labels.roundingTypeLastBirthday') as string,
              },
            ]}
            label={t('admin.conditions.labels.roundingType')}
          />
        </Grid>
      )}
      <CollectionOperators
        collectionContext={collectionContext}
        collectionNodeIds={collectionNodeIds}
        collectionOperators={condition.collectionOperators}
        onConditionPropertiesChange={onConditionPropertiesChange}
        conditionIndex={conditionIndex}
      />
    </Fragment>
  );
}
