import { ReactElement, useCallback } from 'react';

import { User } from '@breathelife/types';

import { useDispatch } from '../../../Hooks';
import {
  regularAuthenticate as reduxRegularAuthenticate,
  ssoAuthenticate as reduxSsoAuthenticate,
} from '../../../ReduxStore/Authentication/AuthenticationOperations';
import AuthCallbackView from './AuthCallbackView';

function AuthCallbackContainer(): ReactElement | null {
  const dispatch = useDispatch();

  const handleRegularCallback = useCallback(
    (token: string, auth0User: Pick<User, 'auth0Id'>, lang?: string, companyName?: string) => {
      dispatch(reduxRegularAuthenticate(token, auth0User, lang, companyName));
    },
    [dispatch],
  );

  const handleSsoCallback = useCallback(
    (token: string, auth0User: Pick<User, 'auth0Id'>, lang?: string, companyName?: string) => {
      dispatch(reduxSsoAuthenticate(token, auth0User, lang, companyName));
    },
    [dispatch],
  );

  const props = { handleRegularCallback, handleSsoCallback };

  return <AuthCallbackView {...props} />;
}

export default AuthCallbackContainer;
