import { useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';

import {
  ApplicationModesDefault,
  FieldBlueprint,
  FieldBlueprintBase,
  getFieldBlueprintBase,
  Language,
  QuestionnaireBlueprintCopyableOption,
} from '@breathelife/types';
import { AutocompleteOption } from '@breathelife/ui-components';

import { FieldDataHookReturnValue, LanguageDependentSetter } from '../../Helpers/helperTypes';

export type BaseFieldData = Omit<FieldBlueprintBase, 'layout' | 'optional'> & {
  size: NonNullable<FieldBlueprintBase['layout']>['size'];
  forceNewLine: NonNullable<FieldBlueprintBase['layout']>['forceNewLine'];
  required: FieldBlueprintBase['optional'];
};

export type BaseFieldSetters = {
  setTitle: LanguageDependentSetter;
  setText: LanguageDependentSetter;
  setPlaceholder: LanguageDependentSetter;
  setAnswerNodeId: React.Dispatch<React.SetStateAction<BaseFieldData['answerNodeId']>>;
  setDataLabel: React.Dispatch<React.SetStateAction<BaseFieldData['dataLabel']>>;
  setRenderOn: React.Dispatch<React.SetStateAction<BaseFieldData['renderOn']>>;
  setRequired: React.Dispatch<React.SetStateAction<BaseFieldData['required']>>;
  setDisabled: React.Dispatch<React.SetStateAction<BaseFieldData['disabled']>>;
  setTriggerStepNavigation: React.Dispatch<React.SetStateAction<BaseFieldData['triggerStepNavigation']>>;
  setSize: React.Dispatch<React.SetStateAction<BaseFieldData['size']>>;
  setForceNewLine: React.Dispatch<React.SetStateAction<BaseFieldData['forceNewLine']>>;
  setPlatforms: React.Dispatch<React.SetStateAction<BaseFieldData['platforms']>>;
  setCopyable: React.Dispatch<React.SetStateAction<BaseFieldData['copyable']>>;
  setApplicationModes: React.Dispatch<React.SetStateAction<BaseFieldData['applicationModes']>>;
  setIconName: React.Dispatch<React.SetStateAction<BaseFieldData['iconName']>>;
  setDisplayInCardPreview: React.Dispatch<React.SetStateAction<BaseFieldData['displayInCardPreview']>>;
};

export function useBaseFieldData({
  initialData,
}: {
  initialData?: Partial<FieldBlueprint>;
  dataLabelOptions: AutocompleteOption[];
}): FieldDataHookReturnValue<FieldBlueprintBase, BaseFieldSetters> {
  const id = useRef<string>(initialData?.id || uuid());
  const partName = useRef<string>(initialData?.partName || `custom-${uuid()}`);
  const [titleFr, setTitleFr] = useState<NonNullable<BaseFieldData['title']>['fr']>(initialData?.title?.fr || '');
  const [titleEn, setTitleEn] = useState<NonNullable<BaseFieldData['title']>['en']>(initialData?.title?.en || '');
  const [textFr, setTextFr] = useState<NonNullable<BaseFieldData['text']>['fr']>(initialData?.text?.fr || '');
  const [textEn, setTextEn] = useState<NonNullable<BaseFieldData['text']>['en']>(initialData?.text?.en || '');
  const [answerNodeId, setAnswerNodeId] = useState<BaseFieldData['answerNodeId']>(initialData?.answerNodeId || '');
  const [dataLabel, setDataLabel] = useState<BaseFieldData['dataLabel']>(initialData?.dataLabel || undefined);
  const [renderOn, setRenderOn] = useState<BaseFieldData['renderOn']>(initialData?.renderOn || []);
  const [platforms, setPlatforms] = useState<BaseFieldData['platforms']>(initialData?.platforms || []);
  const [copyable, setCopyable] = useState<BaseFieldData['copyable']>(
    initialData?.copyable || QuestionnaireBlueprintCopyableOption.none,
  );
  const [required, setRequired] = useState<BaseFieldData['required']>(initialData?.optional ? false : true);
  const [triggerStepNavigation, setTriggerStepNavigation] = useState<BaseFieldData['triggerStepNavigation']>(false);
  const [iconName, setIconName] = useState<BaseFieldData['iconName']>(initialData?.iconName || undefined);
  const [disabled, setDisabled] = useState<BaseFieldData['disabled']>(initialData?.disabled || undefined);
  const [size, setSize] = useState<BaseFieldData['size']>(initialData?.layout?.size || undefined);
  const [forceNewLine, setForceNewLine] = useState<BaseFieldData['forceNewLine']>(
    initialData?.layout?.forceNewLine || undefined,
  );
  const [displayInCardPreview, setDisplayInCardPreview] = useState<BaseFieldData['displayInCardPreview']>(
    initialData?.displayInCardPreview || false,
  );
  const [applicationModes, setApplicationModes] = useState<BaseFieldData['applicationModes']>(
    initialData?.applicationModes || ApplicationModesDefault,
  );

  const [placeholderEn, setPlaceholderEn] = useState<NonNullable<BaseFieldData['placeholder']>['en']>(
    initialData?.placeholder?.en || '',
  );
  const [placeholderFr, setPlaceholderFr] = useState<NonNullable<BaseFieldData['placeholder']>['fr']>(
    initialData?.placeholder?.fr || '',
  );

  const setTitle: LanguageDependentSetter = (language, value) => {
    switch (language) {
      case Language.en:
        setTitleEn(value);
        break;
      case Language.fr:
        setTitleFr(value);
        break;
    }
  };

  const setText: LanguageDependentSetter = (language, value) => {
    switch (language) {
      case Language.en:
        setTextEn(value);
        break;
      case Language.fr:
        setTextFr(value);
        break;
    }
  };

  const setPlaceholder: LanguageDependentSetter = (language, value) => {
    switch (language) {
      case Language.en:
        setPlaceholderEn(value);
        break;
      case Language.fr:
        setPlaceholderFr(value);
        break;
    }
  };

  return {
    data: {
      ...(initialData ? getFieldBlueprintBase(initialData as FieldBlueprint) : {}),
      text: {
        en: textEn,
        fr: textFr,
      },
      title: {
        en: titleEn,
        fr: titleFr,
      },
      answerNodeId,
      dataLabel,
      renderOn,
      platforms,
      copyable,
      optional: !required,
      triggerStepNavigation,
      iconName,
      disabled,
      layout: {
        size,
        forceNewLine,
      },
      displayInCardPreview,
      applicationModes,
      placeholder: {
        en: placeholderEn,
        fr: placeholderFr,
      },
      id: id.current.valueOf(),
      partName: partName.current.valueOf(),
    },
    setters: {
      setTitle,
      setText,
      setDataLabel,
      setRenderOn,
      setAnswerNodeId,
      setRequired,
      setDisabled,
      setTriggerStepNavigation,
      setPlaceholder,
      setSize,
      setForceNewLine,
      setCopyable,
      setPlatforms,
      setApplicationModes,
      setIconName,
      setDisplayInCardPreview,
    },
  };
}
