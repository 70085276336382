import { SVGProps, ReactElement } from 'react';

export function NaEmpty(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg fill='none' height='182' viewBox='0 0 170 182' width='170' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='m85 176.5c46.944 0 85-38.056 85-85 0-46.9442-38.056-85-85-85-46.9442 0-85 38.0558-85 85 0 46.944 38.0558 85 85 85z'
        fill='#fcfcfc'
      />
      <path
        d='m112 137.5c0-1.316-12.2643-2.5-27.4944-2.5s-27.5056 1.053-27.5056 2.5 12.2643 2.5 27.5056 2.5c15.2412 0 27.4944-1.118 27.4944-2.5z'
        fill='#eee'
      />
      <path
        d='m112 121.088c.002.252-.046.501-.14.734s-.233.445-.409.623c-.176.177-.385.318-.615.414-.23.095-.477.143-.725.141h-48.2067c-.2494.002-.4967-.046-.7276-.141-.231-.095-.441-.236-.6181-.414-.177-.177-.3175-.389-.4134-.622-.0958-.232-.1452-.482-.1452-.735v-67.1764c0-.2523.0494-.5021.1452-.735.0959-.233.2364-.4444.4134-.6221.1771-.1777.3871-.3181.6181-.4133.2309-.0952.4782-.1431.7276-.1411h39.8247c.25-.0017.499.0471.73.1435.232.0964.442.2384.619.4178l8.367 8.4653c.177.1791.318.392.413.6264s.144.4856.142.739z'
        fill='#f6f6f6'
      />
      <path
        d='m112 120.677c.002.263-.048.523-.148.765-.099.243-.246.464-.431.649s-.406.332-.649.431c-.242.1-.502.15-.764.148h-50.8349c-.2629.002-.5237-.048-.7672-.147-.2436-.1-.4651-.246-.6518-.431-.1866-.185-.3348-.406-.4359-.648-.1011-.243-.1532-.504-.1532-.767v-70.0135c0-.263.0521-.5234.1532-.7661.1011-.2428.2493-.4631.4359-.6483.1867-.1852.4082-.3316.6518-.4308.2435-.0992.5043-.1492.7672-.1471h41.9959c.264-.0017.526.0492.77.1496s.466.2485.653.4354l8.823 8.8229c.187.1866.335.4085.435.6528.101.2443.152.5061.15.7703z'
        stroke='#757575'
        strokeLinejoin='round'
      />
      <path
        d='m97.1684 114.685c5.5276 0 10.0086-4.481 10.0086-10.009 0-5.5274-4.481-10.0085-10.0086-10.0085s-10.0087 4.4811-10.0087 10.0085c0 5.528 4.4811 10.009 10.0087 10.009z'
        fill='#fff'
        stroke='#64af5d'
        strokeMiterlimit='10'
      />
      <g strokeLinejoin='round'>
        <path d='m92.1719 104.676 4.0003 4 6.4988-7.51' stroke='#64af5d' strokeLinecap='round' />
        <path d='m70.178 72.6731h30.991' stroke='#757575' strokeLinecap='round' />
        <path d='m72.9924 66.665h13.2976' stroke='#757575' strokeLinecap='round' />
        <path d='m70.178 78.666h30.991' stroke='#757575' strokeLinecap='round' />
        <path d='m70.178 84.6743h30.991' stroke='#757575' strokeLinecap='round' />
        <path
          d='m102.007 56.846c-.002.2393.043.4767.134.6983s.225.4229.394.5921c.169.1693.37.3032.592.3938.221.0906.459.1362.698.1341h7.906c-.096-.2168-.23-.4149-.395-.585l-8.744-8.8229c-.17-.1653-.368-.2991-.585-.3953z'
          fill='#fff'
          stroke='#757575'
        />
      </g>
    </svg>
  );
}
