import { ReactElement, Fragment, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, ImageTypes } from '@breathelife/ui-components';

import { SubmitButton } from '../../../../../Components/Button/SubmitButton';
import { Icon } from '../../../../../Components/Icons';
import Typography from '../../../../../Components/Typography';
import { ModalLayout } from '../../../../../Layouts/Modal/ModalLayout';

type Props = {
  filePendingDelete?: { id: string; isRequired: boolean };
  onClose: () => void;
  onConfirm: (fileId: string) => Promise<void>;
  isOpen: boolean;
};

export function ConfirmDeleteModal(props: Props): ReactElement {
  const { filePendingDelete, onClose, onConfirm, isOpen } = props;
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = useCallback(async () => {
    if (!filePendingDelete?.id) return;

    setIsLoading(true);
    await onConfirm(filePendingDelete.id);
    setIsLoading(false);

    onClose();
  }, [filePendingDelete?.id, onClose, onConfirm]);

  const onCancel = useCallback(() => {
    if (isLoading) return;
    onClose();
  }, [isLoading, onClose]);

  return (
    <ModalLayout
      maxWidth='sm'
      isOpen={isOpen}
      closeModal={onCancel}
      titleIcon={<Icon name='warning' variant='yellow' fill='none' imageType={ImageTypes.decorative} />}
      title={t('modals.confirmDeleteFile.title')}
      submitButton={
        <SubmitButton isLoading={isLoading} onClick={handleDelete}>
          {t('cta.delete')}
        </SubmitButton>
      }
    >
      <Box mt={2} mb={4}>
        {filePendingDelete?.isRequired ? (
          <Fragment>
            <Box mb={2}>
              <Typography variant='body1'>{t('modals.confirmDeleteFile.requiredFile.body1')}</Typography>
            </Box>
            <Box>
              <Typography variant='body1'>{t('modals.confirmDeleteFile.requiredFile.body2')}</Typography>
            </Box>
          </Fragment>
        ) : (
          <Typography variant='body1'>{t('modals.confirmDeleteFile.optionalFile.body')}</Typography>
        )}
      </Box>
    </ModalLayout>
  );
}
