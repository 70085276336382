import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Attachment } from '@breathelife/types';

export type ApplicationState = {
  applicationId?: string;
  isUploadingAttachment: boolean;
  attachments: Attachment[];
};

type ActionsType = {
  reset: () => void;
  setApplicationId: (state: ApplicationState, { payload }: PayloadAction<string>) => void;
  setIsUploadingAttachment: (state: ApplicationState, { payload }: PayloadAction<boolean>) => void;
  setAttachments: (state: ApplicationState, { payload }: PayloadAction<Attachment[]>) => void;
};

const defaultState: ApplicationState = {
  applicationId: undefined,
  isUploadingAttachment: false,
  attachments: [],
};

export const applicationSlice = createSlice<ApplicationState, ActionsType>({
  name: 'leadPlatform/application',
  initialState: defaultState,
  reducers: {
    reset: () => defaultState,
    setApplicationId: (state: ApplicationState, { payload }: PayloadAction<string>) => {
      state.applicationId = payload;
    },
    setIsUploadingAttachment: (state: ApplicationState, { payload }: PayloadAction<boolean>) => {
      state.isUploadingAttachment = payload;
    },
    setAttachments: (state: ApplicationState, { payload }: PayloadAction<Attachment[]>) => {
      state.attachments = payload;
    },
  },
});
