import {
  isFieldPartIdentifier,
  isQuestionPartIdentifier,
  isSectionPartIdentifier,
  isSelectOptionPartIdentifier,
  isSubsectionPartIdentifier,
  PartIdentifier,
} from '@breathelife/types';

export function arePartIdentifiersTheSame(partIdentifierA: PartIdentifier, partIdentifierB: PartIdentifier): boolean {
  if (partIdentifierA.tag !== partIdentifierB.tag) {
    return false;
  }
  if (isSectionPartIdentifier(partIdentifierA) && isSectionPartIdentifier(partIdentifierB)) {
    return partIdentifierB.sectionPartName === partIdentifierA.sectionPartName;
  }
  if (isSubsectionPartIdentifier(partIdentifierA) && isSubsectionPartIdentifier(partIdentifierB)) {
    return (
      partIdentifierB.sectionPartName === partIdentifierA.sectionPartName &&
      partIdentifierB.subsectionPartName === partIdentifierA.subsectionPartName
    );
  }
  if (isQuestionPartIdentifier(partIdentifierA) && isQuestionPartIdentifier(partIdentifierB)) {
    return (
      partIdentifierB.sectionPartName === partIdentifierA.sectionPartName &&
      partIdentifierB.subsectionPartName === partIdentifierA.subsectionPartName &&
      partIdentifierB.questionPartName === partIdentifierA.questionPartName
    );
  }
  if (isFieldPartIdentifier(partIdentifierA) && isFieldPartIdentifier(partIdentifierB)) {
    return (
      partIdentifierB.sectionPartName === partIdentifierA.sectionPartName &&
      partIdentifierB.subsectionPartName === partIdentifierA.subsectionPartName &&
      partIdentifierB.questionPartName === partIdentifierA.questionPartName &&
      partIdentifierB.fieldPartName === partIdentifierA.fieldPartName
    );
  }
  if (isSelectOptionPartIdentifier(partIdentifierA) && isSelectOptionPartIdentifier(partIdentifierB)) {
    return (
      partIdentifierB.sectionPartName === partIdentifierA.sectionPartName &&
      partIdentifierB.subsectionPartName === partIdentifierA.subsectionPartName &&
      partIdentifierB.questionPartName === partIdentifierA.questionPartName &&
      partIdentifierB.fieldPartName === partIdentifierA.fieldPartName &&
      partIdentifierB.selectOptionPartName === partIdentifierA.selectOptionPartName
    );
  }
  return false;
}
