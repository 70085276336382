import { RadioFieldBlueprint } from '@breathelife/types';

import { FieldDataSetterProps } from '../../../Helpers/helperTypes';
import { RadioFieldSetters, useRadioFieldData } from '../useRadioFieldData';
import { useCommonFieldDataSetters } from './useCommonFieldDataSetters';

export function RadioFieldDataSetter({
  initialData,
  dataLabelOptions,
  ...props
}: FieldDataSetterProps<RadioFieldBlueprint, RadioFieldSetters>): null {
  const { data, setters, validationOptions, defaultValueValidationError } = useRadioFieldData({
    initialData,
    dataLabelOptions,
  });

  useCommonFieldDataSetters<RadioFieldBlueprint, RadioFieldSetters>({
    ...props,
    data,
    setters,
    validationOptions,
    defaultValueValidationError,
    getIsValidBlueprint: (blueprint) => !!blueprint.validateAs,
  });
  return null;
}
