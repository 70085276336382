import { Box } from '@breathelife/mui';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../Styles/themed-styled-components';

import { ImageTypes } from '@breathelife/ui-components';

import { Icon } from '../../../../Components/Icons';
import { LeadPlatformTheme } from '../../../../Styles/Types';
import { StyledActionBanner, StyledBox, StyledLink } from './styles';

export enum ActionBannerVariant {
  readyForReview = 'readyForReview',
  inReview = 'inReview',
  sent = 'sent',
}

type Props = {
  variant: ActionBannerVariant;
  onEditApplication?: () => void;
  onSendToSignature?: () => void;
  onLockApplication?: () => void;
  onCancelSignature?: () => void;
};

export function ActionBanner(props: Props): ReactElement | null {
  const theme = useTheme() as LeadPlatformTheme;
  const { t } = useTranslation();
  const readyForReviewTranslations = 'assistedApplication.actionBanner.readyForReview';
  const inReviewTranslations = 'assistedApplication.actionBanner.inReview';
  const sentTranslations = 'assistedApplication.actionBanner.sent';

  const { variant, onEditApplication, onSendToSignature, onLockApplication, onCancelSignature } = props;

  switch (variant) {
    case ActionBannerVariant.inReview:
      return (
        <StyledActionBanner mt={5} variantColor='yellow'>
          <Icon name='info' imageType={ImageTypes.decorative} stroke={theme.colors.yellow[60]} />
          <StyledBox ml={1.5}>
            <Box mb={1}>{t(`${inReviewTranslations}.body`)}</Box>
            <Box display='flex'>
              <StyledLink onClick={onEditApplication}>{t(`${inReviewTranslations}.cta`)}</StyledLink>
              <StyledLink onClick={onSendToSignature}>{t('assistedApplication.sendToSignature')}</StyledLink>
            </Box>
          </StyledBox>
        </StyledActionBanner>
      );
    case ActionBannerVariant.readyForReview:
      return (
        <StyledActionBanner mt={5} variantColor='blue'>
          <Icon name='info' imageType={ImageTypes.decorative} stroke={theme.colors.blue[60]} />
          <StyledBox ml={1.5}>
            <Box mb={1}>{t(`${readyForReviewTranslations}.body`)}</Box>
            <StyledLink onClick={onLockApplication}>{t(`${readyForReviewTranslations}.cta`)}</StyledLink>
          </StyledBox>
        </StyledActionBanner>
      );

    case ActionBannerVariant.sent:
      return (
        <StyledActionBanner mt={5} variantColor='yellow'>
          <Icon name='info' imageType={ImageTypes.decorative} stroke={theme.colors.yellow[60]} />
          <StyledBox ml={1.5}>
            <Box mb={1}>{t(`${sentTranslations}.body`)}</Box>
            <StyledLink onClick={onCancelSignature}>{t(`${sentTranslations}.cta`)}</StyledLink>
          </StyledBox>
        </StyledActionBanner>
      );

    default:
      return null;
  }
}
