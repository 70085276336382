import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, ImageTypes } from '@breathelife/ui-components';

import { SubmitButton } from '../../../../Components/Button/SubmitButton';
import { Icon } from '../../../../Components/Icons';
import Typography from '../../../../Components/Typography';
import { ModalLayout } from '../../../../Layouts/Modal/ModalLayout';

type ConfirmPricingFieldIdentifiersModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirmChangesClick: () => void;
};

export function ConfirmPricingFieldIdentifiersModal(props: ConfirmPricingFieldIdentifiersModalProps): ReactElement {
  const { isOpen, onClose, onConfirmChangesClick } = props;
  const { t } = useTranslation();

  return (
    <ModalLayout
      maxWidth='sm'
      isOpen={isOpen}
      closeModal={onClose}
      titleIcon={<Icon name='warning' variant='yellow' fill='none' imageType={ImageTypes.decorative} />}
      title={t('modals.confirmPricingFieldIdentifiers.title')}
      submitButton={<SubmitButton onClick={onConfirmChangesClick}>{t('cta.save')}</SubmitButton>}
    >
      <Box mb={2}>
        <Typography variant='body1'>{t('modals.confirmPricingFieldIdentifiers.body')}</Typography>
      </Box>
    </ModalLayout>
  );
}
