import { Box } from '@breathelife/mui';
import { ReactNode, ReactElement, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from '../../../../Styles/themed-styled-components';

import { HelmetContainer } from '../../../../Components/HelmetContainer';
import Typography from '../../../../Components/Typography';
import { useGetSupportPageApplicationQuery } from '../../../../ReactQuery/ApplicationSupport/Application/application.queries';

const UnderwritingHeaderContainer = styled(Box)`
  && {
    padding: 16px;
    border-bottom: 1px solid #e6e6e6;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const HeaderTextsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

type ApplicationSupportHeaderProps = {
  applicationSupportPage: string;
  children: ReactNode;
};

export function ApplicationSupportHeader(props: ApplicationSupportHeaderProps): ReactElement {
  const { t } = useTranslation();
  const { applicationId = '' } = useParams<{ applicationId: string }>();
  const { applicationSupportPage, children } = props;
  const { data: application } = useGetSupportPageApplicationQuery(applicationId);

  // We don't want to display redundant info on the overview page
  const displayApplicationDetails = !(applicationSupportPage === 'overview');

  return (
    <Fragment>
      <HelmetContainer text={t(`applicationSupport.pages.${applicationSupportPage}`)} />
      <UnderwritingHeaderContainer m={2}>
        <HeaderTextsContainer>
          <Typography variant='h1'>{t(`applicationSupport.pages.${applicationSupportPage}`)}</Typography>
          {displayApplicationDetails && (
            <Typography variant='body5'>
              {t('applicationSupport.header.applicationId', { applicationId: applicationId })}
            </Typography>
          )}
          {displayApplicationDetails && (
            <Typography variant='body5'>
              {t('applicationSupport.header.policyNumber', { refNo: application?.refNo })}
            </Typography>
          )}
        </HeaderTextsContainer>
      </UnderwritingHeaderContainer>
      {children}
    </Fragment>
  );
}
