import { SVGProps, ReactElement } from 'react';

export function ContactedIcon(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg fill='none' height='24' width='24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <clipPath id='prefix__a'>
        <path d='M0 0h12v12H0z' transform='matrix(-1 0 0 1 18 6)' />
      </clipPath>
      <circle cx='12' cy='12' fill='#f6f6f6' r='12' />
      <g clipPath='url(#prefix__a)'>
        <path
          d='M17.56 7.074a1.051 1.051 0 00-1.5.234l-5.093 7.141-3.238-2.677a1.053 1.053 0 00-1.507.206 1.137 1.137 0 00.164 1.529l4.111 3.397a1.062 1.062 0 001.524-.192l.012-.016 5.759-8.07c.35-.495.248-1.19-.231-1.552z'
          fill='#4dd787'
        />
      </g>
    </svg>
  );
}
