import { ReactElement } from 'react';

import { SvgIconProps } from '../../Components/Icons';

export function PdfIcon(props: SvgIconProps): ReactElement {
  return (
    <svg
      fill='none'
      stroke='#f6f6f6'
      height='24'
      viewBox='0 0 25 24'
      width='25'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props.svgProps}
    >
      <clipPath id='a'>
        <path d='m.5 0h24v24h-24z' />
      </clipPath>
      <g clipPath='url(#a)'>
        <path
          d='m5 22.5c0 .2652.10536.5196.29289.7071.18754.1875.44189.2929.70711.2929h15c.2652 0 .5196-.1054.7071-.2929s.2929-.4419.2929-.7071v-16.027c-.0005-1.58432-.6303-3.10357-1.7507-4.22367-1.1205-1.1201-2.64-1.74933-4.2243-1.74933h-10.025c-.26522 0-.51957.105357-.70711.292893-.18753.187537-.29289.441887-.29289.707107v7.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='m17 .579102v3.920998c0 .26522.1054.51957.2929.70711.1875.18753.4419.29289.7071.29289h3.92'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <g fill='#eb5757'>
          <path d='m8.951 13.3219c-.105-.046-.326-.048-.326.164v3.9781c.0017.038.0125.0751.0315.108.01899.033.04562.061.07765.0816.03204.0205.06854.0331.10645.0367.0379.0035.07611-.0021.1114-.0164.42312-.185.78311-.4894 1.03591-.8759.25279-.3864.38739-.8382.38739-1.3001 0-.4618-.1346-.9136-.38739-1.3-.2528-.3865-.61279-.6909-1.03591-.876z' />
          <path d='m4.5 13.123h-.623c-.06683 0-.13093.0266-.17819.0739-.04726.0472-.07381.1113-.07381.1781v1.246c0 .0669.02655.131.07381.1782.04726.0473.11136.0738.17819.0738h.623c.23206 0 .45462-.0921.61872-.2562.16409-.1641.25628-.3867.25628-.6188 0-.232-.09219-.4546-.25628-.6187-.1641-.1641-.38666-.2563-.61872-.2563z' />
          <path d='m18.5 20v-9c0-.2652-.1054-.5196-.2929-.7071s-.4419-.2929-.7071-.2929h-16c-.26522 0-.51957.1054-.707107.2929-.187536.1875-.292893.4419-.292893.7071v9c0 .2652.105357.5196.292893.7071.187537.1875.441887.2929.707107.2929h16c.2652 0 .5196-.1054.7071-.2929s.2929-.4419.2929-.7071zm-14-3.877h-.623c-.03309 0-.06586.0065-.09644.0192-.03057.0126-.05835.0312-.08175.0546s-.04196.0512-.05463.0818c-.01266.0305-.01918.0633-.01918.0964v2.125c0 .1658-.06585.3247-.18306.4419-.11721.1173-.27618.1831-.44194.1831s-.32473-.0658-.44194-.1831c-.11721-.1172-.18306-.2761-.18306-.4419v-6c0-.1658.06585-.3247.18306-.4419.11721-.1173.27618-.1831.44194-.1831h1.5c.56359 0 1.10409.2239 1.5026.6224.39852.3985.6224.939.6224 1.5026s-.22388 1.1041-.6224 1.5026c-.39851.3985-.93901.6224-1.5026.6224zm3.5 3c-.16576 0-.32473-.0658-.44194-.1831-.11721-.1172-.18306-.2761-.18306-.4419v-6c0-.1658.06585-.3247.18306-.4419.11721-.1173.27618-.1831.44194-.1831.96141 0 1.88344.3819 2.5633 1.0617.6798.6799 1.0617 1.6019 1.0617 2.5633s-.3819 1.8834-1.0617 2.5633c-.67986.6798-1.60189 1.0617-2.5633 1.0617zm8-6h-2c-.0995 0-.1948.0395-.2652.1098-.0703.0704-.1098.1657-.1098.2652v1.123c0 .0668.0265.1309.0738.1782.0473.0472.1114.0738.1782.0738h1.123c.1658 0 .3247.0658.4419.1831.1173.1172.1831.2761.1831.4419s-.0658.3247-.1831.4419c-.1172.1173-.2761.1831-.4419.1831h-1.123c-.0668 0-.1309.0265-.1782.0738s-.0738.1114-.0738.1782v2.125c0 .1658-.0658.3247-.1831.4419-.1172.1173-.2761.1831-.4419.1831s-.3247-.0658-.4419-.1831c-.1173-.1172-.1831-.2761-.1831-.4419v-5c0-.4312.1711-.8447.4758-1.1498.3047-.305.718-.4767 1.1492-.4772h2c.1658 0 .3247.0658.4419.1831.1173.1172.1831.2761.1831.4419s-.0658.3247-.1831.4419c-.1172.1173-.2761.1831-.4419.1831z' />
        </g>
      </g>
    </svg>
  );
}
