import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, BoxProps } from '@breathelife/mui';

import Typography from '../../../../../../Components/Typography';

export function PaymentSubsectionHeader(props: BoxProps = {}): ReactElement {
  const { t } = useTranslation();

  return (
    <Box {...props}>
      <Box mb={4}>
        <Typography variant='h3'>{t('assistedApplication.paymentSubsection.title')}</Typography>
      </Box>
      <Box mb={3}>
        <Typography variant='body1'>{t('assistedApplication.paymentSubsection.paymentMethods.creditCard')}</Typography>
      </Box>
    </Box>
  );
}
