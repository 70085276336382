import { ReactElement } from 'react';

import { SvgIconProps } from '../../Components/Icons';

export function NotificationIcon(props: SvgIconProps): ReactElement {
  return (
    <svg
      stroke='#757575'
      fill='#f6f6f6'
      height='16'
      viewBox='0 0 16 16'
      width='16'
      xmlns='http://www.w3.org/2000/svg'
      {...props.svgProps}
    >
      <path
        d='m4.54266 2.84399c-.69212.38434-1.26261.95506-1.64666 1.64734'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='m3.98333 1c-.71033.20196-1.35727.5821-1.8794 1.10435s-.90213 1.16927-1.10393 1.87965'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='m13 12s-1-.8707-1-4.00004c0-2.5-1.708-4.712-4-4.712s-4 2.212-4 4.712c0 3.14604-1 4.00004-1 4.00004' />
      <g strokeLinecap='round' strokeLinejoin='round'>
        <path d='m13 12s-1-.8707-1-4.00004c0-2.5-1.708-4.712-4-4.712s-4 2.212-4 4.712c0 3.14604-1 4.00004-1 4.00004' />
        <path d='m3 12h10' />
        <path d='m9 14c0 .2652-.10536.5196-.29289.7071-.18754.1875-.44189.2929-.70711.2929s-.51957-.1054-.70711-.2929c-.18753-.1875-.29289-.4419-.29289-.7071' />
        <path d='m8 3.288v-1.288' />
        <path d='m11.4575 2.84399c.3438.19196.6597.43018.9387.708.2783.27888.5165.595.708.93934' />
        <path d='m12.0166 1c.7103.20196 1.3573.5821 1.8794 1.10435s.9021 1.16927 1.1039 1.87965' />
      </g>
    </svg>
  );
}
