import { Theme } from '@breathelife/types';
import { useTranslation } from 'react-i18next';
import { UseMutationOptions, UseMutationResult, useMutation } from 'react-query';
import { useDispatch } from '../../Hooks';
import { notificationSlice } from '../../ReduxStore/Notification/NotificationSlice';
import { updateTheme } from '../../Services/ThemeService';

type UpdateThemeParams = {
  themeId: string;
  data: Partial<Theme>;
};

export function useUpdateThemeMutation(
  options?: UseMutationOptions<Theme, unknown, UpdateThemeParams>,
): UseMutationResult<Theme, unknown, UpdateThemeParams> {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return useMutation<Theme, unknown, UpdateThemeParams>(async ({ themeId, data }) => updateTheme(themeId, data), {
    ...options,
    onSuccess: async (data, variables, context) => {
      if (options?.onSuccess) {
        await options.onSuccess(data, variables, context);
      }

      dispatch(
        notificationSlice.actions.setSuccess({
          message: t('notifications.themeUpdateSuccess'),
          autoHideDuration: 3000,
        }),
      );
    },
    onError: async (error, variables, context) => {
      dispatch(
        notificationSlice.actions.setError({
          message: t('notifications.failedToUpdateTheme'),
        }),
      );

      if (options?.onError) {
        await options.onError(error, variables, context);
      }
    },
  });
}
