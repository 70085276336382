import ApiService from './ApiService';

export type DataExtractResponse = {
  success: boolean;
};

export type DataExtractRequest = {
  type: string;
};

export async function createDataExtract(data: DataExtractRequest): Promise<DataExtractResponse> {
  const response = await ApiService.createDataExtractRequest(data);
  return response.data;
}
