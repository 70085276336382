import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '../../../../../Components/Typography';
import { ActionsList } from './ActionsList';
import { CardBody, CardContainer, CardHeader } from './Styles';

type ActionWidgetProps = {
  applicationId: string;
  refNo: string;
  applicationStatus: string | undefined;
  onOpenResetSubmissonStatusConfirmationModal: () => void;
  onDownloadSubmissionFiles: () => void;
  isDownloadingFiles: boolean;
};

export function ActionsWidget(props: ActionWidgetProps): ReactElement {
  const {
    applicationStatus,
    applicationId,
    refNo,
    onOpenResetSubmissonStatusConfirmationModal,
    onDownloadSubmissionFiles,
    isDownloadingFiles,
  } = props;
  const { t } = useTranslation();

  return (
    <CardContainer>
      <CardHeader>
        <Typography variant='h3' component='h2'>
          {t('applicationSupport.applicationOverview.actionsWidgetTitle')}
        </Typography>
      </CardHeader>

      <CardBody>
        <ActionsList
          applicationId={applicationId}
          refNo={refNo}
          applicationStatus={applicationStatus}
          onOpenResetSubmissonStatusConfirmationModal={onOpenResetSubmissonStatusConfirmationModal}
          onDownloadSubmissionFiles={onDownloadSubmissionFiles}
          isDownloadingFiles={isDownloadingFiles}
        ></ActionsList>
      </CardBody>
    </CardContainer>
  );
}
