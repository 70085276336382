import { Box } from '@breathelife/mui';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { getUserDisplayName } from '../../../../Helpers/user';
import { useSelector } from '../../../../Hooks';
import { EmptyListContainer, ListText, ListTitle, EmptyApplicationListImg } from './Styles';

export function TabArchivedEmptyListView(): ReactElement {
  const { t } = useTranslation();
  const authentication = useSelector((store) => store.leadPlatform.authentication);
  const displayName = authentication.user ? ` ${getUserDisplayName(authentication.user)}` : '';
  const listTitleText = `${t('tabs.archived.empty.hello', { name: displayName })}!`;
  return (
    <EmptyListContainer textAlign='center' pt={12} minHeight={400}>
      <Box mb={3}>
        <EmptyApplicationListImg role='img' />
      </Box>
      <Box mb={1.5}>
        <ListTitle variant='h1' grey={70}>
          {listTitleText}
        </ListTitle>
      </Box>
      <ListText variant='body1' grey={60}>
        {t('tabs.archived.empty.thereAreNoLeads')}
      </ListText>
    </EmptyListContainer>
  );
}
