import { ReactElement, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Grid } from '@breathelife/mui';
import { Language } from '@breathelife/types';

import { NumberInput } from '../../../Components/NumberInput/NumberInput';
import RadioGroup, { Radio } from '../../../Components/RadioGroup/RadioGroup';
import { NodeDetail } from '../../../Helpers/questionnaireEditor/questionnaireNodeIds';
import { ConditionTargetNodeAutocomplete } from '../Helpers/ConditionTargetNodeAutocomplete';
import { ValidationErrorText } from '../ValidationErrorText';

enum ComparisonValueType {
  number = 'number',
  nodeId = 'nodeId',
}

type Props = {
  numberValue: number | undefined;
  nodeIdOfValue: string | undefined;
  onChange: (newValues: { numberValue?: number; nodeIdOfValue?: string }) => void;
  nodeIdList: NodeDetail[];
  language: Language;
  numberValueValidationError?: yup.ValidationError;
  nodeIdOfValueValidationError?: yup.ValidationError;
};

export function NumberValueOrNodeIdInput(props: Props): ReactElement {
  const {
    language,
    nodeIdList,
    numberValue,
    nodeIdOfValue,
    onChange,
    numberValueValidationError,
    nodeIdOfValueValidationError,
  } = props;
  const { t } = useTranslation();
  const [comparisonValueType, setComparisonValueType] = useState<ComparisonValueType>(
    nodeIdOfValue ? ComparisonValueType.nodeId : ComparisonValueType.number,
  );

  return (
    <Fragment>
      <Grid item xs={12}>
        <RadioGroup
          onChange={(value: ComparisonValueType) => {
            onChange({ numberValue: undefined, nodeIdOfValue: undefined });
            setComparisonValueType(value);
          }}
          label={t('admin.conditions.labels.comparisonValueType')}
          name='condition-emptiness-radio-group'
          value={comparisonValueType}
        >
          <Grid container spacing={1}>
            <Grid item xs={3} sm={3}>
              <Radio value={ComparisonValueType.number} label={t('admin.conditions.labels.number')} />
            </Grid>
            <Grid item xs={3} sm={3}>
              <Radio value={ComparisonValueType.nodeId} label={t('admin.conditions.labels.nodeId')} />
            </Grid>
          </Grid>
        </RadioGroup>
      </Grid>
      {comparisonValueType === ComparisonValueType.nodeId && (
        <Grid item xs={12}>
          <ConditionTargetNodeAutocomplete
            value={nodeIdOfValue || ''}
            nodeIdList={nodeIdList}
            label={t('admin.conditions.labels.controlValue')}
            onSelect={(nodeIdOfValue) => {
              if (nodeIdOfValue !== null) {
                onChange({ numberValue: undefined, nodeIdOfValue });
              }
            }}
            validationError={nodeIdOfValueValidationError}
            selectedLanguage={language}
          />
        </Grid>
      )}
      {comparisonValueType === ComparisonValueType.number && (
        <Grid item xs={12}>
          <NumberInput
            inputVariant='outlined'
            value={numberValue}
            onNumberChange={(value) => onChange({ numberValue: value, nodeIdOfValue: undefined })}
            label={t('admin.conditions.labels.controlValue')}
            error={!!numberValueValidationError}
          />
          {numberValueValidationError && (
            <ValidationErrorText>{numberValueValidationError.message}</ValidationErrorText>
          )}
        </Grid>
      )}
    </Fragment>
  );
}
