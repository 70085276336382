import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { CloseIcon, IconButton, IconButtonProps } from '@breathelife/mui';

import styled from '../../Styles/themed-styled-components';

const Button = styled(IconButton)`
  && {
    border: 1px solid transparent;
    padding: 3px;

    :hover {
      background-color: ${(props) => props.theme.colors.grey[10]};
      border-color: ${(props) => props.theme.colors.grey[10]};
    }
    :active {
      background-color: ${(props) => props.theme.colors.grey[30]};
      border-color: ${(props) => props.theme.colors.grey[30]};
    }
    :focus:not(:active) {
      outline: 0;
      border-color: ${(props) => props.theme.colors.tertiary.default};
    }
  }
`;

export const Icon = styled(CloseIcon)`
  &&& {
    color: ${(props) => props.theme.colors.grey[70]};
  }
`;

export function CloseButton(props: IconButtonProps): ReactElement {
  const { t } = useTranslation();
  return (
    <Button {...props} aria-label={t('cta.close')}>
      <Icon role='img' aria-label={t('cta.close')} aria-hidden={false} />
    </Button>
  );
}
