import { Box } from '@breathelife/mui';
import styled from '../../../../Styles/themed-styled-components';

export const OverviewGridContainer = styled(Box)`
  && {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 16px 10px;
    width: 50%;
    min-width: 400px;
  }
`;

export const ActionsWidgetContainer = styled.div`
  padding: 35px;
`;

export const EmptyGridElement = styled.div``;
