import { ReactElement, Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '../../Styles/themed-styled-components';

import { ScreenName, TypewriterTracking } from '@breathelife/frontend-tracking';

import { HelmetContainer } from '../../Components/HelmetContainer';
import { useDispatch } from '../../Hooks';
import { authenticationSlice } from '../../ReduxStore/Authentication/AuthenticationSlice';
import LogoutView from './LogoutView';

const { actions: authenticationActions } = authenticationSlice;

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.grey[20]};
`;

const Content = styled.div`
  width: 100%;
  max-width: 360px;
`;

function LogoutContainer(): ReactElement {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    TypewriterTracking.viewedScreen({ screenName: ScreenName.logout, hashedId: null });
  }, []);

  useEffect(() => {
    dispatch(authenticationActions.reset());
  }, [dispatch]);

  return (
    <Fragment>
      <HelmetContainer text={t('pageTitles.logout')} />

      <Container>
        <Content>
          <LogoutView />
        </Content>
      </Container>
    </Fragment>
  );
}

export default LogoutContainer;
