import { FileTemplateRecipient } from '@breathelife/types';

import ApiService from './ApiService';

export type CreateFileTemplateRecipientData = Omit<FileTemplateRecipient, 'id' | 'createdAt' | 'participantRole'>;

export async function findFileTemplateRecipients(
  options: Parameters<typeof ApiService.findFileTemplateRecipients>[0],
): Promise<FileTemplateRecipient[]> {
  const response = await ApiService.findFileTemplateRecipients(options);
  return response.data;
}

export async function getFileTemplateRecipien(id: string): Promise<FileTemplateRecipient> {
  const response = await ApiService.getFileTemplateRecipient(id);
  return response.data;
}

export async function createFileTemplateRecipient(
  data: CreateFileTemplateRecipientData,
): Promise<FileTemplateRecipient> {
  const response = await ApiService.createFileTemplateRecipient(data);
  return response.data;
}

export async function deleteFileTemplateRecipient(id: string): Promise<FileTemplateRecipient> {
  const response = await ApiService.deleteFileTemplateRecipient(id);

  return response.data;
}

export async function updateFileTemplateRecipient(
  id: string,
  data: Partial<FileTemplateRecipient>,
): Promise<FileTemplateRecipient> {
  const response = await ApiService.updateFileTemplateRecipient(id, data);
  return response.data;
}
