import { useState } from 'react';

import {
  AddressAutocompleteFieldBlueprint,
  FieldBlueprint,
  FieldTypes,
  StringFieldValidation,
} from '@breathelife/types';
import { AutocompleteOption } from '@breathelife/ui-components';

import {
  FieldDataFromBlueprintWithValidateAs,
  FieldDataHookReturnValueWithValidationOptions,
  FieldSettersValidateAs,
  useCastBlueprintToFieldType,
} from '../../Helpers/helperTypes';
import { BaseFieldData, BaseFieldSetters, useBaseFieldData } from './useBaseFieldData';
import { useValidateAsData } from './useValidateAsData';

export type AddressAutocompleteFieldData = FieldDataFromBlueprintWithValidateAs<
  AddressAutocompleteFieldBlueprint,
  'countryCode' | 'addressAutocompleteNodeId' | 'validateAs' | 'addressAutocompleteFields'
> &
  BaseFieldData;

const defaultProperties = {
  countryCode: 'CA',
  addressAutocompleteNodeId: '',
  addressAutocompleteFields: {
    streetAddress: '',
    city: '',
    stateOrProvince: '',
    postalCodeOrZip: '',
  },
  validateAs: StringFieldValidation.string,
} as const;

export type AddressAutocompleteFieldSetters = FieldSettersValidateAs<AddressAutocompleteFieldData> & {
  setCountryCode: React.Dispatch<React.SetStateAction<AddressAutocompleteFieldData['countryCode']>>;
  setAddressAutocompleteNodeId: React.Dispatch<
    React.SetStateAction<AddressAutocompleteFieldData['addressAutocompleteNodeId']>
  >;
  setStreetAddress: React.Dispatch<
    React.SetStateAction<AddressAutocompleteFieldData['addressAutocompleteFields']['streetAddress']>
  >;
  setStateOrProvince: React.Dispatch<
    React.SetStateAction<AddressAutocompleteFieldData['addressAutocompleteFields']['stateOrProvince']>
  >;
  setPostalCodeOrZip: React.Dispatch<
    React.SetStateAction<AddressAutocompleteFieldData['addressAutocompleteFields']['postalCodeOrZip']>
  >;
  setCity: React.Dispatch<React.SetStateAction<AddressAutocompleteFieldData['addressAutocompleteFields']['city']>>;
} & BaseFieldSetters;

export function useAddressAutocompleteFieldData({
  initialData,
  dataLabelOptions,
}: {
  initialData?: Partial<FieldBlueprint>;
  dataLabelOptions: AutocompleteOption[];
}): FieldDataHookReturnValueWithValidationOptions<AddressAutocompleteFieldBlueprint, AddressAutocompleteFieldSetters> {
  const initialDataAsAutocompleteField = useCastBlueprintToFieldType<AddressAutocompleteFieldBlueprint>(
    FieldTypes.autocomplete,
    initialData,
  );

  const [addressAutocompleteNodeId, setAddressAutocompleteNodeId] = useState<
    AddressAutocompleteFieldData['addressAutocompleteNodeId']
  >(initialDataAsAutocompleteField?.addressAutocompleteNodeId || defaultProperties.addressAutocompleteNodeId);

  const [countryCode, setCountryCode] = useState<AddressAutocompleteFieldData['countryCode']>(
    initialDataAsAutocompleteField?.countryCode || defaultProperties.countryCode,
  );

  const [city, setCity] = useState<AddressAutocompleteFieldData['addressAutocompleteFields']['city']>(
    initialDataAsAutocompleteField?.addressAutocompleteFields?.city || defaultProperties.addressAutocompleteFields.city,
  );

  const [stateOrProvince, setStateOrProvince] = useState<
    AddressAutocompleteFieldData['addressAutocompleteFields']['stateOrProvince']
  >(
    initialDataAsAutocompleteField?.addressAutocompleteFields?.stateOrProvince ||
      defaultProperties.addressAutocompleteFields.stateOrProvince,
  );

  const [streetAddress, setStreetAddress] = useState<
    AddressAutocompleteFieldData['addressAutocompleteFields']['streetAddress']
  >(
    initialDataAsAutocompleteField?.addressAutocompleteFields?.streetAddress ||
      defaultProperties.addressAutocompleteFields.streetAddress,
  );

  const [postalCodeOrZip, setPostalCodeOrZip] = useState<
    AddressAutocompleteFieldData['addressAutocompleteFields']['postalCodeOrZip']
  >(
    initialDataAsAutocompleteField?.addressAutocompleteFields?.postalCodeOrZip ||
      defaultProperties.addressAutocompleteFields.postalCodeOrZip,
  );

  const { data, setters } = useBaseFieldData({ initialData, dataLabelOptions });

  const {
    data: validateAsData,
    setters: validateAsSetters,
    validationOptions,
  } = useValidateAsData<AddressAutocompleteFieldBlueprint>({
    fieldType: FieldTypes.autocomplete,
    initialValidateAs: defaultProperties.validateAs,
  });

  return {
    data: {
      fieldType: FieldTypes.autocomplete,
      ...data,
      countryCode,
      addressAutocompleteNodeId,
      addressAutocompleteFields: {
        streetAddress,
        city,
        stateOrProvince,
        postalCodeOrZip,
      },
      ...validateAsData,
    },
    setters: {
      ...setters,
      setCountryCode,
      setAddressAutocompleteNodeId,
      setCity,
      setStreetAddress,
      setPostalCodeOrZip,
      setStateOrProvince,
      ...validateAsSetters,
    },
    validationOptions,
  };
}
