import { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { PublishIcon, Tooltip } from '@breathelife/mui';

import { HeaderButton } from '../../Pages/Admin/Questionnaire/QuestionnaireEditor/Components/HeaderButton';
import { ImportCtx } from './ImportDataProvider';

export function ImportButton(): ReactElement {
  const { toggleImportModal, isLoading } = useContext(ImportCtx);
  const { t } = useTranslation();
  const tooltipTitle = t('admin.importData.tooltip');

  return (
    <HeaderButton onClick={toggleImportModal} disabled={isLoading} size='large' sx={{ padding: '22px' }}>
      <Tooltip title={tooltipTitle} disableInteractive>
        <PublishIcon />
      </Tooltip>
    </HeaderButton>
  );
}
