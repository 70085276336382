import { ReactElement } from 'react';

import { Icon } from '../Components/Icons';

export enum FileType {
  pdf = 'pdf',
}

export function getFileIcon(fileType: FileType): ReactElement<SVGElement> | undefined {
  let icon;
  if (fileType === FileType.pdf) {
    icon = <Icon name='pdf' />;
  }

  return icon;
}
