import { ReactElement } from 'react';

import { SvgIconProps } from '../../Components/Icons';

export function AssignIcon(props: SvgIconProps): ReactElement {
  return (
    <svg stroke='#757575' fill='#f6f6f6' height='16' width='16' xmlns='http://www.w3.org/2000/svg' {...props.svgProps}>
      <g strokeLinecap='round' strokeLinejoin='round'>
        <path d='M11.5 7.5a4 4 0 110 8 4 4 0 010-8z' />
        <path d='M13.283 10.337l-1.937 2.582a.5.5 0 01-.754.054l-1-1M4 .5a2 2 0 110 4 2 2 0 010-4z' />
      </g>
      <path d='M7.037 7.259A3.5 3.5 0 00.5 8.999v1.5H2l.5 5h3l.215-2.154' />
      <path
        d='M7.037 7.259A3.5 3.5 0 00.5 8.999v1.5H2l.5 5h3l.215-2.154'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
