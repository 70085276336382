import i18next from 'i18next';

export enum FileSizeUnits {
  'B' = 0,
  'KB' = 1,
  'MB' = 2,
  'GB' = 3,
  'TB' = 4,
}

export function convertFileSize(size: number, from: FileSizeUnits, to: FileSizeUnits): number {
  if (from === to) return size;

  if (from < to) {
    return size / Math.pow(1024, to - from);
  }

  return size * Math.pow(1024, from - to);
}

export function printFileSize(sizeInBytes: number, fractionDigits: number = 0): string {
  if (sizeInBytes < 0) throw new Error('File size must be larger than 0!');

  const logBase1024 = sizeInBytes <= 0 ? 0 : Math.floor(Math.log(sizeInBytes) / Math.log(1024));
  const sizeLabel = i18next.t(`dataSizeLabels.${['B', 'KB', 'MB', 'GB', 'TB'][logBase1024]}`);
  return `${(sizeInBytes / Math.pow(1024, logBase1024)).toFixed(fractionDigits)} ${sizeLabel}`;
}
