import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '../../../../../Styles/themed-styled-components';

import { SimpleCheckbox } from '@breathelife/ui-components';

import EditorTooltip from './EditorTooltip';

const Root = styled.div`
  display: flex;

  & > :last-child {
    margin-left: 8px;
  }
`;

interface Props {
  prefix: string;
  checked: boolean;
  disabled?: boolean;
  onChange: (checked: boolean) => void;
}

function NavigatesToNextStepCheckbox(props: Props): ReactElement {
  const { prefix, checked, onChange, disabled = false } = props;
  const { t } = useTranslation();

  const description = t('admin.questionnaireManagement.triggerStepNavigation.description');

  return (
    <Root>
      <SimpleCheckbox
        id={`${prefix}-triggerStepNavigation`}
        label={t('admin.questionnaireManagement.triggerStepNavigation.label')}
        checked={checked}
        disabled={disabled}
        onChange={(event: any) => {
          onChange(event?.target?.checked ?? false);
        }}
      />
      <EditorTooltip placement='top' content={description} />
    </Root>
  );
}

export default NavigatesToNextStepCheckbox;
