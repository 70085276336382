type SearchParamsToClear = readonly [string, ...string[]]; // Variatic tuple to catch empty arrays

/**
 * Clear search parameters from the target route.
 * If searchParamsToClear is undefined, all params will be cleared.
 *
 * @param {string} route - The target route
 * @param {string[] | undefined} searchParamsToClear - Unique search params to be cleared (Optional)
 */
export function clearSearchParameters(route: string, searchParamsToClear?: SearchParamsToClear): string {
  if (typeof searchParamsToClear === 'undefined') {
    return route.split('?')[0];
  }
  const endpoint = route.split('?')[0];
  const paramsString = route.split('?')[1];
  const searchParams = new URLSearchParams(paramsString);
  searchParamsToClear.forEach((param: string) => {
    searchParams.delete(param);
  });
  return endpoint + '?' + searchParams.toString();
}
