import _ from 'lodash';
import { Fragment, ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Divider, Grid } from '@breathelife/mui';
import { FileTemplateRecipient } from '@breathelife/types';

import { Button } from '../../../../../../Components/Button/Button';
import { useModalState } from '../../../../../../Hooks';
import { EditESignFieldSetting } from './EditESignFieldSettings';
import { NewESignFieldSetting } from './NewESignFieldSettings';

export function ESignFieldSettingContainer(props: { fileTemplateRecipient: FileTemplateRecipient }): ReactElement {
  const [isCreating, openCreateForm, closeCreateForm] = useModalState();
  const { t } = useTranslation();

  const sortedFieldSettings = useMemo(
    () => _.orderBy(props?.fileTemplateRecipient?.fieldSettings, ['createdAt'], ['desc']),
    [props?.fileTemplateRecipient?.fieldSettings],
  );

  return (
    <Grid container>
      <Grid item>
        <Box my={2}>
          {isCreating ? (
            <NewESignFieldSetting signerSettingId={props.fileTemplateRecipient.id} onClose={closeCreateForm} />
          ) : (
            <Box display='flex' justifyContent='flex-end' width='100%'>
              <Button color='primary' variant='outlined' onClick={openCreateForm}>
                + {t('admin.documentDetailManagement.addSignatureField')}
              </Button>
            </Box>
          )}

          <Grid item>
            {sortedFieldSettings.map((eSignFieldSetting) => {
              return (
                <Fragment key={eSignFieldSetting.id}>
                  <Box my={4}>
                    <EditESignFieldSetting
                      signerSettingId={eSignFieldSetting.signerSettingId}
                      eSignFieldSetting={eSignFieldSetting}
                    />
                  </Box>
                  <Divider />
                </Fragment>
              );
            })}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}
