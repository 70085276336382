import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { DateUnit } from '@breathelife/types';
import { Select } from '../Select/Select';

type Props = {
  value: DateUnit;
  onChange: (value: DateUnit) => void;
};

export function DateUnitSelect(props: Props): ReactElement {
  const { value, onChange } = props;

  const { t } = useTranslation();

  const options = [DateUnit.day, DateUnit.month, DateUnit.year].map((dateUnit) => {
    return {
      value: dateUnit,
      label: t(`admin.conditions.options.dateUnit.${dateUnit}`),
    };
  });

  return (
    <Select
      id='condition-options-date-unit-select'
      label={t('admin.conditions.labels.dateUnit')}
      options={options}
      value={value}
      onChange={onChange}
    />
  );
}
