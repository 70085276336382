import * as yup from 'yup';

import {
  ESignFieldSettingType,
  ESignFieldSettingPlacementType,
  ESignFieldSettingAnchorPointType,
} from '@breathelife/types';

import { getValidationError } from '../getValidationError';

type ESignFieldSettingFields =
  | 'signerSettingId'
  | 'type'
  | 'placementType'
  | 'top'
  | 'left'
  | 'height'
  | 'width'
  | 'page'
  | 'anchorText'
  | 'anchorPoint'
  | 'anchorIndex';

const getSchemaMap = (): { [field in ESignFieldSettingFields]: yup.StringSchema | yup.NumberSchema } => ({
  // common
  signerSettingId: yup.string(),
  type: yup.string().oneOf(Object.values(ESignFieldSettingType)),
  placementType: yup.string().oneOf(Object.values(ESignFieldSettingPlacementType)),
  top: yup.number().required(),
  left: yup.number().required(),
  height: yup.number().required(),
  width: yup.number().required(),

  // absolute
  page: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .when('placementType', {
      is: ESignFieldSettingPlacementType.ABSOLUTE,
      then: yup.number().required(),
    }),

  // relative
  anchorText: yup.string().when('placementType', {
    is: ESignFieldSettingPlacementType.RELATIVE,
    then: yup.string().required(),
  }),
  anchorPoint: yup.string().when('placementType', {
    is: ESignFieldSettingPlacementType.RELATIVE,
    then: yup.string().required().oneOf(Object.values(ESignFieldSettingAnchorPointType)),
  }),
  anchorIndex: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .when('placementType', {
      is: ESignFieldSettingPlacementType.RELATIVE,
      then: yup.number().required(),
    }),
});

const getFormSchema = (): yup.ObjectSchema => yup.object(getSchemaMap());

function getFieldValidationError(
  fieldName: ESignFieldSettingFields,
  value: string | number | undefined,
): yup.ValidationError | undefined {
  return getValidationError(getSchemaMap(), fieldName, value);
}

export const eSignFieldSettingValidation = {
  getFormSchema,
  getFieldValidationError,
};
