import { useEffect, Dispatch, SetStateAction } from 'react';

import { SectionGroupId } from '../../Helpers/questionnaireAnswers';
import { SessionStorageKeys } from '../../Hooks/useSessionStorage/sessionStorageKeys';
import { useSessionStorage } from '../../Hooks/useSessionStorage/useSessionStorage';

export type StoredSectionState = {
  applicationId?: string;
  activeSectionIndex: number;
  previousSectionIndex: number;
  currentProposedInsuredIndex: number;
  currentSectionGroupId: SectionGroupId;
};

type UseStoredSectionStateReturn = [StoredSectionState, Dispatch<SetStateAction<StoredSectionState>>];

export function useStoredSectionState(applicationId?: string | undefined): UseStoredSectionStateReturn {
  const initialSectionIndexState: StoredSectionState = {
    applicationId: applicationId,
    activeSectionIndex: 0,
    previousSectionIndex: 0,
    currentProposedInsuredIndex: 0,
    currentSectionGroupId: SectionGroupId.insuredPeople,
  };

  const [storedSectionIndices, setStoredSectionIndices] = useSessionStorage<StoredSectionState>(
    SessionStorageKeys.storedSectionIndicesState,
    initialSectionIndexState,
  );

  useEffect(() => {
    function updateInitialStoredState(): void {
      if (!applicationId) {
        return;
      }
      const { applicationId: storedApplicationId } = storedSectionIndices;

      const isSameApplicationStored = storedApplicationId === applicationId;

      if (isSameApplicationStored) {
        return;
      }

      setStoredSectionIndices(initialSectionIndexState);
    }

    updateInitialStoredState();
  }, [applicationId]);

  return [storedSectionIndices, setStoredSectionIndices];
}
