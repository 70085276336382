import { AxiosError } from 'axios';
import { UseMutationOptions, UseMutationResult, useQueryClient, useMutation } from 'react-query';
import { QueryId } from '../common/common.types';
import * as PaymentService from '../../Services/PaymentService';
import { PaymentTransaction } from '@breathelife/types';

export function usePatchPaymentTokenMutation(
  options?: UseMutationOptions<
    PaymentTransaction | null,
    AxiosError,
    { applicationId: string; paymentTransactionId: string; data: { paymentToken: string } }
  >,
): UseMutationResult<
  PaymentTransaction | null,
  unknown,
  { applicationId: string; paymentTransactionId: string; data: { paymentToken: string } }
> {
  const queryClient = useQueryClient();

  return useMutation<
    PaymentTransaction | null,
    AxiosError,
    { applicationId: string; paymentTransactionId: string; data: { paymentToken: string } }
  >(
    async ({ paymentTransactionId, data }: { paymentTransactionId: string; data: { paymentToken: string } }) => {
      return await PaymentService.patchPaymentTransaction(paymentTransactionId, data);
    },
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries([QueryId.fetchPaymentTransaction, variables.applicationId]);

        void options?.onSuccess?.(data, variables, context);
      },
      onError: (error, variables, context) => {
        void options?.onError?.(error, variables, context);
      },
    },
  );
}

export type UseCreatePaymentTokenMutationVariables = {
  data: { applicationId: string };
  shouldInvalidateFetchQuery?: boolean;
};

export function useCreatePaymentTokenMutation(
  options?: UseMutationOptions<PaymentTransaction | null, AxiosError, UseCreatePaymentTokenMutationVariables>,
): UseMutationResult<PaymentTransaction | null, unknown, UseCreatePaymentTokenMutationVariables> {
  const queryClient = useQueryClient();

  return useMutation<PaymentTransaction | null, AxiosError, UseCreatePaymentTokenMutationVariables>(
    async ({ data }) => {
      return await PaymentService.createPaymentTransaction(data);
    },
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        const { shouldInvalidateFetchQuery = true } = variables;

        if (shouldInvalidateFetchQuery) {
          await queryClient.invalidateQueries([QueryId.fetchPaymentTransaction, variables.data.applicationId]);
        }
        await options?.onSuccess?.(data, variables, context);
      },
      onError: async (error, variables, context) => {
        if (error instanceof Error) {
          if (options?.onError) {
            await options.onError(error, variables, context);
          }
        }
      },
    },
  );
}
