import { ReactElement, SVGProps } from 'react';

export function FilteredListEmpty(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg fill='none' height='182' viewBox='0 0 170 182' width='170' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='m85 176.5c46.944 0 85-38.056 85-85 0-46.9442-38.056-85-85-85-46.9442 0-85 38.0558-85 85 0 46.944 38.0558 85 85 85z'
        fill='#fcfcfc'
      />
      <path
        d='m88.3697 141.993c18.3683 0 33.2583-1.317 33.2583-2.942 0-1.624-14.89-2.941-33.2583-2.941-18.3682 0-33.2586 1.317-33.2586 2.941 0 1.625 14.8904 2.942 33.2586 2.942z'
        fill='#eee'
      />
      <path
        d='m68.8171 51h36.5699l14.613 15.1629v54.9381c0 .503-.193.987-.537 1.343-.343.356-.809.556-1.295.556h-49.3509c-.4807-.004-.9406-.204-1.2805-.556-.3399-.353-.5326-.829-.5366-1.328v-68.2324c.004-.4983.1967-.975.5366-1.3274.3399-.3523.7998-.5521 1.2805-.5562z'
        fill='#f6f6f6'
      />
      <g stroke='#757575' strokeLinecap='round' strokeLinejoin='round'>
        <path d='m65.8842 48.3113h39.1228l15.633 15.6975v56.8752c0 .521-.206 1.021-.574 1.39s-.866.576-1.386.576h-52.7958c-.5143-.004-1.0063-.211-1.37-.576-.3636-.365-.5698-.858-.574-1.374v-70.6387c.0042-.5159.2104-1.0094.574-1.3742.3637-.3648.8557-.5716 1.37-.5758z' />
        <path
          d='m120.64 64.0088-15.649-15.6975v13.7312c0 .5187.204 1.0164.568 1.3847.364.3682.859.5773 1.376.5816z'
          fill='#fff'
        />
        <path d='m74.4214 58.1101h21.5136' />
        <path d='m74.4214 64.9839h21.5136' />
        <path d='m74.4214 71.8574h36.1906' />
        <path d='m74.4214 78.7151h36.1906' />
        <path d='m80 85.5886h30.612' />
        <path d='m95.9351 92.4624h14.6769' />
        <path d='m94 99h17' />
        <path d='m90 106h21' />
        <path d='m81 113h30' />
      </g>
      <path
        d='m88.4667 91.9369c.0517-.7935-.5501-1.4778-1.3417-1.5295-.0307-.0019-.0613-.0038-.092-.0038h-40.158c-.7935 0-1.4375.644-1.4375 1.4375 0 .0325.0019.0632.0038.0958.6919 9.6461 7.7204 17.6521 17.1964 19.5881v18.927c0 .794.644 1.438 1.4375 1.438.3354 0 .6612-.119.92-.334l5.75-4.791c.3277-.273.5175-.677.5175-1.104v-14.136c9.4779-1.932 16.5101-9.94 17.204-19.5881z'
        fill='#b1d7ae'
      />
      <path
        d='m86.0002 92.5c.4-1.5999-2.1667-2-3.5-2h4.9998l.5.5.5 2-.5 1.75-.5 1.75-1.5 3.5-3.5 5.5-5.5 4-6 2v14l-.0001 1c-1.466 1.152-3.7346 2.948-4.921 3.927-.1645.228-.3563.425-.579.573-.446.297-.1229.005.579-.573.7221-1.002.921-2.613.921-3.427v-15c0-.8.3334-1.333.5-1.5 2.6667-1.167 8.3512-2.812 12.5001-6.5 4.5-4 5.5001-9.3333 6.0002-11.5z'
        fill='#9dcb9a'
      />
      <path
        d='m88.4667 91.9369c.0517-.7935-.5501-1.4778-1.3417-1.5295-.0307-.0019-.0613-.0038-.092-.0038h-40.158c-.7935 0-1.4375.644-1.4375 1.4375 0 .0325.0019.0632.0038.0958.6919 9.6461 7.7204 17.6521 17.1964 19.5881v18.927c0 .794.644 1.438 1.4375 1.438.3354 0 .6612-.119.92-.334l5.75-4.791c.3277-.273.5175-.677.5175-1.104v-14.136c9.4779-1.932 16.5101-9.94 17.204-19.5881z'
        stroke='#757575'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='.5'
      />
    </svg>
  );
}
