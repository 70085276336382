import { Fragment, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, BoxProps, ButtonProps } from '@breathelife/mui';
import { ImageTypes } from '@breathelife/ui-components';

import { Button } from '../../../../../../Components/Button/Button';
import { Icon } from '../../../../../../Components/Icons';
import { StyledActionGhostIconButton } from '../../../Styles';
import { CreditCardPreview } from './PaymentMethodPreviews/CreditCardPreview';

export type PaymentSubsectionActionsProps = {
  editButtonProps?: Omit<ButtonProps, 'onClick'>;
  isEditable?: boolean;
  lastFourCardDigits?: string;
  onAddClick: () => void;
  onEditClick: () => void;
} & BoxProps;

export function PaymentSubsectionActions(props: PaymentSubsectionActionsProps): ReactElement {
  const { editButtonProps = {}, isEditable = false, lastFourCardDigits = '', onAddClick, onEditClick, ...rest } = props;
  const { t } = useTranslation();

  return (
    <Box {...rest}>
      <Box alignItems='center' display='flex'>
        {lastFourCardDigits ? (
          <Fragment>
            <Box mr={5}>
              <CreditCardPreview last4={lastFourCardDigits} />
            </Box>
            {isEditable ? (
              <Button onClick={onEditClick} variant='outlined' {...editButtonProps}>
                {t('cta.edit')}
              </Button>
            ) : null}
          </Fragment>
        ) : (
          <StyledActionGhostIconButton
            onClick={onAddClick}
            removeIconBorder
            size='small'
            startIcon={<Icon name='addAssured' variant='primary' imageType={ImageTypes.decorative} />}
            typographyVariant='button'
          >
            {t('assistedApplication.paymentSubsection.addPaymentMethod.creditCard')}
          </StyledActionGhostIconButton>
        )}
      </Box>
    </Box>
  );
}
