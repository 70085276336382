import auth0 from 'auth0-js';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useCarrierContext, useDispatch, useSelector } from '../../Hooks';
import { authenticationSlice } from '../../ReduxStore/Authentication/AuthenticationSlice';
import { isSsoUser, loginAgent } from '../../Services/Auth0';
import LoginView from './LoginView';

const { actions: authenticationActions } = authenticationSlice;

function LoginContainer(): ReactElement | null {
  const location = useLocation();
  const dispatch = useDispatch();
  const [isStateReset, setIsStatesReset] = useState(false);
  const user = useSelector((store) => store.leadPlatform.authentication.user);
  const { ssoConnectionName, defaultToSsoConnection, responseType } = useCarrierContext();

  const isRequestingSsoConnection = useMemo((): boolean => {
    const urlParams = new URLSearchParams(window.location.search);
    const ssoConnectionParam = urlParams.get('sso');

    if (ssoConnectionParam && ssoConnectionParam === '1') {
      const isSso = user && isSsoUser(user);
      return isSso || ssoConnectionParam === '1';
    }

    if (ssoConnectionParam && ssoConnectionParam === '0') {
      return false;
    }

    return !!defaultToSsoConnection;
  }, [user, defaultToSsoConnection]);

  const login = useCallback(() => {
    const options: auth0.AuthorizeOptions = {
      appState: {
        targetRoute: location?.state?.targetRoute,
      },
      responseType,
    };

    if (ssoConnectionName && isRequestingSsoConnection) {
      options.connection = ssoConnectionName;
    }

    return loginAgent(options);
  }, [isRequestingSsoConnection, location, responseType, ssoConnectionName]);

  useEffect(() => {
    const resetStates = async (): Promise<void> => {
      await dispatch(authenticationActions.reset());
      setIsStatesReset(true);
    };

    void resetStates();
  }, [dispatch]);

  if (!isStateReset) return null;
  return <LoginView login={login} />;
}

export default LoginContainer;
