import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { UseMutationOptions, UseMutationResult, useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import { ApplicationSupportApplication } from '@breathelife/types';

import { notificationSlice } from '../../../ReduxStore/Notification/NotificationSlice';
import { resetSubmissionStatus } from '../../../Services/ApplicationSupportService';
import { getTranslationKeyFromErrorId } from '../../../Localization/errorIdsToTranslationMap';

export function useResetApplicationSubmissionStatusMutation(
  options?: UseMutationOptions<ApplicationSupportApplication, AxiosError, string>,
): UseMutationResult<ApplicationSupportApplication, AxiosError, string> {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return useMutation<ApplicationSupportApplication, AxiosError, string>(resetSubmissionStatus, {
    ...options,
    onSuccess: async (data, variables, context) => {
      dispatch(
        notificationSlice.actions.setSuccess({
          message: t('notifications.applicationSupport.resetStatus.success'),
        }),
      );

      if (options?.onSuccess) {
        await options.onSuccess(data, variables, context);
      }
    },
    onError: async (error, variables, context) => {
      const errorTranslationKey =
        getTranslationKeyFromErrorId(error) || 'notifications.applicationSupport.resetStatus.error.default';

      dispatch(
        notificationSlice.actions.setError({
          message: t(errorTranslationKey),
        }),
      );

      if (options?.onError) {
        await options.onError(error, variables, context);
      }
    },
  });
}
