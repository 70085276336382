import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '../../../Styles/themed-styled-components';

import { BooleanOperator } from '@breathelife/types';

import { Select } from '../../../Components/Select/Select';
import { getOptionsFromEnum, SelectOptions } from '../../../Helpers/options';

type Props = {
  canSelectNotOperator: boolean;
  value: BooleanOperator;
  onChange: (operator: BooleanOperator, path: string) => void;
  path: string;
};

const StyledSelect = styled(Select)`
  &&&& {
    width: 70px;
  }
`;

export function BooleanOperatorSelector({ value, onChange, path, canSelectNotOperator }: Props): ReactElement {
  const { t } = useTranslation();
  let options: SelectOptions<BooleanOperator>;

  if (canSelectNotOperator) {
    options = getOptionsFromEnum(BooleanOperator);
  } else {
    options = getOptionsFromEnum(BooleanOperator).filter((option) => option.value !== BooleanOperator.not);
  }

  return (
    <StyledSelect
      id={`booleanOperatorSelector-${path}`}
      value={value}
      options={options}
      onChange={(operator) => onChange(operator as BooleanOperator, path)}
      label={t('admin.conditions.labels.logicalOperator')}
    />
  );
}
