import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid } from '@breathelife/mui';
import { ConsiderationBlueprint, OutcomeCode } from '@breathelife/types';

import { Select } from '../../../../Components/Select/Select';

type Props = {
  value?: OutcomeCode;
  onChange: (data: Partial<ConsiderationBlueprint>) => void;
};

export function OutcomeSelect({ value, onChange }: Props): ReactElement {
  const { t } = useTranslation();

  const options = [OutcomeCode.blockedAtQuoter, OutcomeCode.blocked, OutcomeCode.denied, OutcomeCode.referred].map(
    (value) => ({
      value,
      label: t(`admin.salesDecisionRulesManagement.options.outcomes.${value}`),
    }),
  );

  return (
    <Grid item xs={12}>
      <Select
        id='sales-decision-rule-outcome-select'
        value={value ?? OutcomeCode.conditional}
        onChange={(outcomeCode) => {
          onChange({ outcomeCode });
        }}
        options={options}
        label={t('admin.salesDecisionRulesManagement.labels.outcome')}
      />
    </Grid>
  );
}
