import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FieldTypes } from '@breathelife/types';
import { AutocompleteOption } from '@breathelife/ui-components';

import { getValidationOptionsByFieldType } from '../../../../../../Helpers/questionnaireEditor/selectOptions';

export function useGetValidationOptions(fieldType: FieldTypes): AutocompleteOption[] {
  const { t } = useTranslation();

  const validationOptions = useMemo(() => getValidationOptionsByFieldType(t)[fieldType], [t]);

  return validationOptions;
}
