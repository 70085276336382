import { Ref, MouseEventHandler, FormEventHandler, ReactElement, forwardRef } from 'react';

import { Button, ButtonProps } from '@breathelife/mui';

import Typography from '../Typography';
import { StyledLoader } from './Styles';

export const SubmitButton = forwardRef(
  (props: { isLoading?: boolean } & Omit<ButtonProps, 'variant' | 'color'>, ref: Ref<any>): ReactElement => {
    const { children, isLoading, onClick: onClickProp, onSubmit: onSubmitProp, ...buttonProps } = props;

    const onClick: MouseEventHandler<HTMLButtonElement> = (event) => {
      if (isLoading) return;
      onClickProp?.(event);
    };

    const onSubmit: FormEventHandler<HTMLButtonElement> = (event) => {
      if (isLoading) return;
      onSubmitProp?.(event);
    };

    return (
      <Button {...buttonProps} onClick={onClick} onSubmit={onSubmit} variant='contained' color='primary' ref={ref}>
        {isLoading ? (
          <StyledLoader size='20px' color='secondary' />
        ) : (
          <Typography variant='button'>{children}</Typography>
        )}
      </Button>
    );
  },
);
