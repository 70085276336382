import {
  PropsWithChildren,
  ReactElement,
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
  useRef,
  PureComponent,
  FC,
} from 'react';
import { Backdrop, CircularProgress, Portal } from '@breathelife/mui';
import styled from '../../Styles/themed-styled-components';

const StyledBackdrop = styled(Backdrop)`
  &.MuiBackdrop-root {
    z-index: 30000;
  }
`;

const StyledLoaderWrapper = styled.div`
  background-color: white;
  border-radius: 100%;
  padding: 10px;
`;

type FullPageLoaderContextValue = {
  isOpen: boolean;
  openFullPageLoader: (delay?: number) => void;
  closeFullPageLoader: () => void;
};
const FullPageLoaderContext = createContext<FullPageLoaderContextValue>({
  isOpen: false,
  openFullPageLoader: () => {},
  closeFullPageLoader: () => {},
});

const minDelay = 2000;

export function FullPageLoaderContextProvider({ children }: PropsWithChildren<{}>): ReactElement {
  const timerRef = useRef<any>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [shouldClose, setShouldClose] = useState<boolean>(false);
  const [canClose, setCanClose] = useState<boolean>(true);

  const openFullPageLoader = useCallback((delay?: number) => {
    setIsOpen(true);
    setShouldClose(false);
    setCanClose(false);
    timerRef.current = setTimeout(() => {
      setCanClose(true);
    }, delay || minDelay);
    return () => clearTimeout(timerRef.current);
  }, []);

  const closeFullPageLoader = useCallback(() => {
    setShouldClose(true);
  }, []);

  useEffect(() => {
    if (canClose && shouldClose) {
      setIsOpen(false);
    }
  }, [canClose, shouldClose]);

  return (
    <FullPageLoaderContext.Provider
      value={{
        isOpen,
        openFullPageLoader,
        closeFullPageLoader,
      }}
    >
      {children}
      <FullPageLoader />
    </FullPageLoaderContext.Provider>
  );
}

export function useFullPageLoaderContext(): FullPageLoaderContextValue {
  return useContext(FullPageLoaderContext);
}

export function FullPageLoader(): ReactElement {
  const { isOpen } = useFullPageLoaderContext();
  return (
    <Portal>
      <StyledBackdrop open={isOpen}>
        <StyledLoaderWrapper>
          <CircularProgress />
        </StyledLoaderWrapper>
      </StyledBackdrop>
    </Portal>
  );
}

export const WrappedWithFullPageLoader = (WrappedComponent: FC) => {
  class Wrapper extends PureComponent {
    render(): ReactElement {
      return (
        <FullPageLoaderContextProvider>
          <WrappedComponent {...this.props} />
        </FullPageLoaderContextProvider>
      );
    }
  }
  return Wrapper;
};
