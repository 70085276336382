import { Fragment, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid } from '@breathelife/mui';
import { getFieldLayoutOptions, FieldSizes } from '@breathelife/types';
import { SimpleCheckbox } from '@breathelife/ui-components';

import { EditorDropdown } from '../../../Components/EditorDropdown';

type FieldLayoutInputsProps = {
  sizeValue?: number;
  handleSizeChange: (newValue?: number) => void;
  forceNewLineValue?: boolean;
  handleForceNewLineChange: (newValue?: boolean) => void;
};

export function FieldLayoutInputs({
  sizeValue,
  handleSizeChange,
  forceNewLineValue,
  handleForceNewLineChange,
}: FieldLayoutInputsProps): ReactElement {
  const { t } = useTranslation();
  return (
    <Fragment>
      <Grid item xs={6}>
        <EditorDropdown
          id='addFieldLayoutDropdown'
          label={'Field size'}
          selectedOptionValue={sizeValue?.toString() || undefined}
          onChange={(value) => {
            if (!value) {
              handleSizeChange(undefined);
              return;
            }
            handleSizeChange(parseInt(value));
          }}
          options={getFieldLayoutOptions((key: FieldSizes) => t(`admin.questionnaireManagement.fieldSizes.${key}`)).map(
            (e) => ({ ...e, value: e.value.toString() }),
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <SimpleCheckbox
          id='createField-layoutForceNewLine'
          label={'Force new line'}
          checked={!!forceNewLineValue}
          onChange={(event) => {
            handleForceNewLineChange(event.target.checked);
          }}
        />
      </Grid>
    </Fragment>
  );
}
