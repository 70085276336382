import { useContext } from 'react';

import { CarrierContext, ContextProps } from '../Context/CarrierContext';

export function useCarrierContext(): ContextProps {
  const context = useContext(CarrierContext);
  return context;
}

export function useCarrierName(): string {
  const { carrierInfo } = useCarrierContext();

  const carrierName = carrierInfo.companyName.trim().toLowerCase().replace(/\s/, '_');
  return carrierName;
}
