import { Fragment, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@breathelife/mui';

import { Loader } from '@breathelife/ui-components';

import { Alert } from '../../../../../../Components/Alert/Alert';
import { SubmitButton } from '../../../../../../Components/Button/SubmitButton';
import { ModalLayout, ModalLayoutProps } from '../../../../../../Layouts/Modal/ModalLayout';

export type CreditCardFormModalProps = Omit<ModalLayoutProps, 'ariaLabel' | 'title'> & {
  error?: string;
  isLoading?: boolean;
  isSubmitting?: boolean;
  onSubmit: () => void;
};

export function CreditCardFormModal(props: CreditCardFormModalProps): ReactElement {
  const {
    children,
    closeModal = () => {},
    error = null,
    hideCancel = false,
    isLoading = false,
    isOpen = false,
    isSubmitting = false,
    maxWidth = 'sm',
    onSubmit,
    ...rest
  } = props;
  const { t } = useTranslation();

  return (
    <ModalLayout
      closeModal={closeModal}
      hideCancel={hideCancel}
      isOpen={isOpen}
      maxWidth={maxWidth}
      submitButton={
        <SubmitButton isLoading={isSubmitting} onClick={onSubmit}>
          {t('cta.save')}
        </SubmitButton>
      }
      title={t('modals.assistedApplication.addPaymentMethod.creditCard.modalTitle')}
      {...rest}
    >
      <Box>
        {isLoading ? (
          <Loader />
        ) : (
          <Fragment>
            {error ? (
              <Box mb={3}>
                <Alert severity='error'>{error}</Alert>
              </Box>
            ) : null}
            {children}
          </Fragment>
        )}
      </Box>
      <Box mt={3}>
        <Alert severity='info'>{t('modals.assistedApplication.addPaymentMethod.creditCard.informationText')}</Alert>
      </Box>
    </ModalLayout>
  );
}
