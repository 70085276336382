import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { User, UserNotificationPreference } from '@breathelife/types';

type IsAuthenticatedPayload = {
  isAuthenticated: boolean;
};

type TokenPayload = {
  token?: string;
};

// TODO: Move user, preferences, and public link actions to separate store
type UserPayload = {
  user?: User;
};

type NotificationPreferencesPayload = {
  notificationPreferences?: UserNotificationPreference;
};

type PublicLinkPayload = {
  publicLink?: string;
};

type LoadingPayload = {
  isLoading: boolean;
};

export type AuthenticationState = IsAuthenticatedPayload &
  TokenPayload &
  UserPayload &
  PublicLinkPayload &
  LoadingPayload;

type ActionsType = {
  authenticate: (state: AuthenticationState, { payload }: PayloadAction<TokenPayload & UserPayload>) => void;
  reset: (state: AuthenticationState, { payload }: PayloadAction) => void;
  setIsLoading: (state: AuthenticationState, { payload }: PayloadAction<LoadingPayload>) => void;
  updateUser: (state: AuthenticationState, { payload }: PayloadAction<UserPayload>) => void;
  updateNotificationPreferences: (
    state: AuthenticationState,
    { payload }: PayloadAction<NotificationPreferencesPayload>,
  ) => void;
  setPublicLink: (state: AuthenticationState, { payload }: PayloadAction<PublicLinkPayload>) => void;
};

const defaultState = {
  user: undefined,
  token: undefined,
  publicLink: undefined,
  isAuthenticated: false,
  isLoading: false,
};

export const authenticationSlice = createSlice<AuthenticationState, ActionsType>({
  name: 'leadPlatform/authentication',
  initialState: defaultState,
  reducers: {
    authenticate: (
      _state: AuthenticationState,
      { payload }: PayloadAction<TokenPayload & UserPayload>,
    ): AuthenticationState => ({
      ..._state,
      ...payload,
      isAuthenticated: true,
    }),
    reset: (): AuthenticationState => defaultState,
    setIsLoading: (state: AuthenticationState, { payload }: PayloadAction<LoadingPayload>) => {
      state.isLoading = payload.isLoading;
    },
    updateUser: (state: AuthenticationState, { payload }: PayloadAction<UserPayload>) => {
      state.user = payload.user;
    },
    updateNotificationPreferences: (
      state: AuthenticationState,
      { payload }: PayloadAction<NotificationPreferencesPayload>,
    ) => {
      if (!state.user) return;
      state.user = {
        ...state.user,
        notificationPreferences: payload.notificationPreferences,
      };
    },
    setPublicLink: (state: AuthenticationState, { payload }: PayloadAction<PublicLinkPayload>) => {
      state.publicLink = payload.publicLink;
    },
  },
});
