import dayjs from 'dayjs';
import { ReactElement, useMemo } from 'react';

import { useCarrierContext } from '../../../Hooks';
import { Application } from '../../../Models/Application';
import { Lead } from '../../../Models/Lead';
import { ExportEntityButton } from './ExportEntityButton';

type Props = {
  lead?: Lead;
  application?: Application;
};

export function ExportLeadButton({ lead, application }: Props): ReactElement {
  const { carrierInfo } = useCarrierContext();

  const carrierName = carrierInfo.companyName.trim().toLowerCase().replace(/\s/, '_');
  const date = dayjs().format('YYYYMMDD_HHmmss');

  const fileName = lead ? ['lead', lead.id, carrierName, date].filter(Boolean).join('_') : '';

  const data = useMemo(() => {
    if (!lead) return undefined;
    const { firstName, lastName, email, phoneNumber, postalCode, region, communicationLanguage } = lead;

    const dataToString = JSON.stringify(
      {
        firstName,
        lastName,
        email,
        phoneNumber,
        postalCode,
        region,
        communicationLanguage,
        answers: application?.answers,
      },
      (_key, value) => (value === null ? undefined : value),
      4,
    );

    return dataToString;
  }, [lead, application?.answers]);

  return <ExportEntityButton name='Export lead' disabled={!lead} data={data} fileName={fileName} />;
}
