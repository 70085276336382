import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { QuestionnairePreviewData } from '@breathelife/types';

import { notificationSlice } from '../../../../../ReduxStore/Notification/NotificationSlice';
import ApiService from '../../../../../Services/ApiService';

export function useOpenQuestionnairePreviewLink() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return async (
    questionnairePreviewData: QuestionnairePreviewData,
    onSuccess: () => void,
    onInvalidData: (error: { type: string; message: string }) => void,
  ): Promise<void> => {
    try {
      const data = await ApiService.admin.createQuestionnairePreviewLink(questionnairePreviewData);

      if (data.tag === 'preview') {
        window.open(data.link, '_blank');
        onSuccess();
      } else {
        onInvalidData({ type: data.type, message: data.message });
      }
    } catch (e: any) {
      dispatch(
        notificationSlice.actions.setError({
          message: t('notifications.failedToCreateAPreview'),
        }),
      );
    }
  };
}
