import _ from 'lodash';

import { InsuranceProduct } from '@breathelife/types';

import { LeadPlatformStore } from '../../ReduxStore/types';

type AvailableProductsAndRiders = {
  availableParents: InsuranceProduct[];
  availableRiders: InsuranceProduct[];
};

export const getLinkableProductsForTheSelectedProduct =
  (language: string) =>
  (store: LeadPlatformStore): AvailableProductsAndRiders => {
    const availableRiders: InsuranceProduct[] = [];
    const availableParents: InsuranceProduct[] = [];

    const products = store.leadPlatform.productManagement.products;
    const selectedProduct = store.leadPlatform.productManagement.selectedProduct;

    for (const product of products) {
      if (selectedProduct && selectedProduct.id === product.id) {
        continue;
      }

      if (product.isRider) {
        availableRiders.push(product);
      } else {
        availableParents.push(product);
      }
    }

    const alphabetically = (a: InsuranceProduct, b: InsuranceProduct): number => {
      return a.name[language].localeCompare(b.name[language], language, { sensitivity: 'base' });
    };

    return {
      availableParents: availableParents.sort(alphabetically),
      availableRiders: availableRiders.sort(alphabetically),
    };
  };
