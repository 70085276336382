import { ReactElement } from 'react';

import { SvgIconProps } from '../../Components/Icons';

export function NotificationArrowUpIcon(props: SvgIconProps): ReactElement {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props.svgProps}>
      <path
        d='M13.7143 2.5C14.0286 2.5 14.2857 2.725 14.2857 3V13C14.2857 13.275 14.0286 13.5 13.7143 13.5H2.28571C1.97143 13.5 1.71429 13.275 1.71429 13V3C1.71429 2.725 1.97143 2.5 2.28571 2.5H13.7143ZM16 3C16 1.89688 14.975 1 13.7143 1H2.28571C1.025 1 0 1.89688 0 3V13C0 14.1031 1.025 15 2.28571 15H13.7143C14.975 15 16 14.1031 16 13V3ZM8 5C7.76071 5 7.53571 5.0875 7.37143 5.24063L3.65714 8.74062C3.425 8.95937 3.36429 9.27813 3.5 9.55C3.63571 9.82188 3.94643 10 4.28571 10H11.7143C12.0536 10 12.3643 9.82188 12.5 9.55C12.6357 9.27813 12.575 8.95937 12.3429 8.74062L8.62857 5.24063C8.46786 5.0875 8.23929 5 8 5Z'
        fill='#999999'
      />
    </svg>
  );
}
