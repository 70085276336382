import { Box } from '@breathelife/mui';
import { ReactNode, ReactElement } from 'react';
import styled from '../../Styles/themed-styled-components';

type ActionsContainerProps = {
  children: ReactNode;
};

const ActionsContent = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  > *:not(:first-child) {
    margin-left: 10px;
  }
`;

export function ActionsContainer(props: ActionsContainerProps): ReactElement {
  const { children } = props;
  return <ActionsContent>{children}</ActionsContent>;
}
