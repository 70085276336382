import _ from 'lodash';
import { memo, ReactElement } from 'react';

import { Box, Divider } from '@breathelife/mui';
import { RenderingSubsection } from '@breathelife/questionnaire-engine';
import { SubsectionVariant } from '@breathelife/types';

import { useCarrierContext } from '../../../../Hooks';
import { PaymentSubsectionRenderer } from './SubsectionVariants/Payment/PaymentSubsectionRenderer';
import { SubsectionView } from './SubsectionView';

interface SubsectionContainerProps {
  subsection: RenderingSubsection;
}

export const SubsectionContainer = memo(InnerSubsectionContainer);

export function InnerSubsectionContainer(props: SubsectionContainerProps): ReactElement {
  const { subsection } = props;
  const { features } = useCarrierContext();

  let content: ReactElement; //todo memo

  switch (subsection.variant) {
    case SubsectionVariant.payment:
      content = (
        <PaymentSubsectionRenderer
          provider={features?.payments?.enabled ? features?.payments?.serviceProvider : undefined}
        />
      );
      break;
    default:
      content = <SubsectionView subsection={subsection} />;
  }

  return (
    <Box>
      <Divider />
      <Box display='flex' flexDirection='column' pt={4} id={_.kebabCase(subsection.title)}>
        {content}
      </Box>
    </Box>
  );
}
