import { ReactElement } from 'react';

import { Box, BoxProps, useTheme } from '@breathelife/mui';

export function ApplicationDetailsWidgetsStickyWrapper(props: BoxProps): ReactElement {
  const theme = useTheme();

  const topPadding = props.pt || props.p;
  const topPaddingValue = topPadding ? Number(topPadding) : 0;

  const topMargin = props.mt || props.m;
  const topMarginValue = topMargin ? Number(topMargin) : 0;

  const topStickyPosition = theme.spacing(topPaddingValue + topMarginValue);

  return (
    <Box {...props}>
      <Box position='sticky' top={topStickyPosition}>
        {props.children}
      </Box>
    </Box>
  );
}
