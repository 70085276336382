import _ from 'lodash';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { CloseButton } from '../../../../Components/Button/CloseButton';
import Typography from '../../../../Components/Typography';
import { ActionButtons } from './ActionButtons';
import { Container, InlineContent } from './Styles';

export type Props = {
  closeRightPanel: () => void;
};

export function CreateLeadHeader(props: Props): ReactElement {
  const { t } = useTranslation();
  return (
    <Container>
      <InlineContent>
        <Typography variant='h1'>{t('leadDetailDrawer.newProfile')}</Typography>
        <CloseButton data-testid='leadDetailHeader-close' onClick={props.closeRightPanel} />
      </InlineContent>
      <ActionButtons disableArchive disableAssign disableEmail onSendEmailClick={_.noop} />
    </Container>
  );
}
