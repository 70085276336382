import { PhoneFieldBlueprint } from '@breathelife/types';

import { FieldDataSetterProps } from '../../../Helpers/helperTypes';
import { PhoneFieldSetters, usePhoneFieldData } from '../usePhoneFieldData';
import { useCommonFieldDataSetters } from './useCommonFieldDataSetters';

export function PhoneFieldDataSetter({
  initialData,
  dataLabelOptions,
  ...props
}: FieldDataSetterProps<PhoneFieldBlueprint, PhoneFieldSetters>): null {
  const { data, setters, validationOptions, defaultValueValidationError } = usePhoneFieldData({
    initialData,
    dataLabelOptions,
  });

  useCommonFieldDataSetters<PhoneFieldBlueprint, PhoneFieldSetters>({
    ...props,
    data,
    setters,
    validationOptions,
    defaultValueValidationError,
    getIsValidBlueprint: (blueprint) => !!blueprint.validateAs,
  });
  return null;
}
