import { Box } from '@breathelife/mui';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { FilteredListEmpty } from '../../../../Assets/FilteredListEmpty';
import Typography from '../../../../Components/Typography';
import { EmptySearchResultContainer } from './Styles';

export function EmptySearchResultView(): ReactElement {
  const { t } = useTranslation();

  return (
    <EmptySearchResultContainer>
      <Box mb={3}>
        <FilteredListEmpty role='img' />
      </Box>
      <Box mb={1.5}>
        <Typography variant='h2' grey={70}>
          {t('applicationSupport.applications.emptySearchResult.title')}
        </Typography>
      </Box>
      <Box maxWidth={300}>
        <Typography variant='body1' grey={60}>
          {t('applicationSupport.applications.emptySearchResult.body')}
        </Typography>
      </Box>
    </EmptySearchResultContainer>
  );
}
