import { Box } from '@breathelife/mui';
import { Auth0Error } from 'auth0-js';
import _ from 'lodash';
import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { logger } from '@breathelife/monitoring-frontend';
import { User } from '@breathelife/types';
import { Loader } from '@breathelife/ui-components';

import Typography from '../../../Components/Typography';
import { clearSearchParameters } from '../../../Helpers/login';
import { useCarrierContext, useDispatch, usePlatformLanguage, useSelector } from '../../../Hooks';
import Urls from '../../../Navigation/Urls';
import { setAuthorizationInterceptor } from '../../../Services/ApiService';
import { AUTH_USER_BLOCKED, Authentication, isSsoUser, parseUrlHash } from '../../../Services/Auth0';
import styled from '../../../Styles/themed-styled-components';
import { LogoFooter } from '../Styles';
import { themeSlice } from '../../../ReduxStore/Admin/ThemeManagement/ThemeSlice';
import { getThemeFromUserGroups } from '../../../Helpers/userGroups';

const AuthCallbackContainer = styled(Box)`
  height: 100vh;
  width: 100vw;
  background-color: ${(props) => props.theme.colors.grey[20]};
`;

const AuthCallbackContent = styled(Box)`
  background-color: ${(props) => props.theme.colors.grey[0]};
  border-radius: 3px;
  max-width: 380px;
  width: 100%;
`;

type Props = {
  handleSsoCallback: (token: string, user: Pick<User, 'auth0Id'>, lang?: string, companyName?: string) => void;
  handleRegularCallback: (token: string, user: Pick<User, 'auth0Id'>, lang?: string, companyName?: string) => void;
};

function AuthCallbackView(props: Props): ReactElement | null {
  const { handleRegularCallback, handleSsoCallback } = props;
  const { t } = useTranslation();
  const [error, setError] = useState<Auth0Error>();
  const [token, setToken] = useState<Authentication>();
  const errorState = useSelector((store) => store.leadPlatform.notification);
  const authentication = useSelector((store) => store.leadPlatform.authentication);
  const lang = usePlatformLanguage();
  const { carrierInfo, features } = useCarrierContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isMultiBrandingEnabled = features.multiBranding.enabled;

  useEffect(() => {
    let isCancelled = false;

    const handleCallback = async (): Promise<void> => {
      const token = await parseUrlHash();
      setToken(token);
      setAuthorizationInterceptor(token.jwtToken);
      if (isSsoUser(token.user)) {
        handleSsoCallback(token.jwtToken, token.user, lang, carrierInfo.companyName);
      } else {
        handleRegularCallback(token.jwtToken, token.user, lang, carrierInfo.companyName);
      }
    };
    handleCallback().catch((error: Auth0Error) => {
      if (!isCancelled) {
        const errorDescription = error?.errorDescription || error?.error_description || error?.description || '';
        logger.error(Error(`${error?.error}: ${errorDescription}`));
        setError(error);
      }
    });

    return () => {
      isCancelled = true;
    };
  }, [t, handleRegularCallback, handleSsoCallback, lang]);

  useEffect(() => {
    if (authentication.isAuthenticated && authentication.user && token) {
      const userGroups = authentication.user.groups;
      const targetRoute = token.targetRoute ? clearSearchParameters(token.targetRoute) : Urls.pro.fullPath;

      if (isMultiBrandingEnabled && userGroups) {
        const theme = getThemeFromUserGroups(userGroups);
        if (theme) {
          dispatch(themeSlice.actions.setTheme({ theme }));
        }
      }

      navigate(targetRoute);
    }
  }, [authentication, token, navigate, isMultiBrandingEnabled, dispatch]);

  return (
    <AuthCallbackContainer display='flex'>
      <AuthCallbackContent margin='auto' p={4}>
        <Box mb={4}>
          <Typography variant='h1'>
            {!error && t('authentication.loggingIn')}
            {error &&
              (error.error === AUTH_USER_BLOCKED
                ? t('authentication.userBlockedTitle')
                : t('authentication.generalErrorTitle'))}
          </Typography>
        </Box>

        {!error && _.isEmpty(errorState) && <Loader size='50px' />}

        {(error || !_.isEmpty(errorState)) && (
          <Box mb={2}>
            <Typography variant='body1' grey={80}>
              {error?.error === AUTH_USER_BLOCKED
                ? t('authentication.userBlockedText')
                : t('authentication.generalErrorText')}
            </Typography>
          </Box>
        )}

        <Box textAlign='center' mt={5}>
          <Typography variant='small2' grey={50}>
            {t('authentication.softwareBy')}&nbsp;
            <LogoFooter role='img' aria-label='Breathe Life' />
          </Typography>
        </Box>
      </AuthCallbackContent>
    </AuthCallbackContainer>
  );
}

export default AuthCallbackView;
