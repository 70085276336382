import { ReactElement } from 'react';

import { SvgIconProps } from '../../Components/Icons';

export function UnblockUserIcon(props: SvgIconProps): ReactElement {
  return (
    <svg
      stroke='#757575'
      fill='#f6f6f6'
      height='16'
      viewBox='0 0 16 16'
      width='16'
      xmlns='http://www.w3.org/2000/svg'
      {...props.svgProps}
    >
      <g strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.5'>
        <path d='m11.5 7.5c2.2093 0 4 1.79067 4 4 0 2.2093-1.7907 4-4 4-2.20933 0-4-1.7907-4-4 0-2.20933 1.79067-4 4-4z' />
        <path d='m13.2826 10.3367-1.9366 2.5826c-.166.2214-.48.2654-.7014.0994-.0186-.014-.0366-.0294-.0526-.046l-1.00002-1' />
        <path d='m4 .5c1.10467 0 2 .89533 2 2s-.89533 2-2 2-2-.89533-2-2 .89533-2 2-2z' />
      </g>
      <path d='m7.03667 7.25867c-.96267-1.67667-3.102-2.256-4.77867-1.29334-1.08733.624-1.757333 1.78134-1.758 3.03467v1.5h1.5l.5 5h3l.21533-2.1553' />
      <path
        d='m7.03667 7.25867c-.96267-1.67667-3.102-2.256-4.77867-1.29334-1.08733.624-1.757333 1.78134-1.758 3.03467v1.5h1.5l.5 5h3l.21533-2.1553'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
      />
    </svg>
  );
}
