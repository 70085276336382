import { CreateRequest, UpdateRequest, InsuranceFirm, InsuranceFirmWithProductCount } from '@breathelife/types';

import ApiService from './ApiService';

export async function createInsuranceFirm(data: CreateRequest<InsuranceFirm>): Promise<InsuranceFirm> {
  const response = await ApiService.admin.createFirm(data);
  return response.data;
}

export async function updateInsuranceFirm(firmId: string, data: UpdateRequest<InsuranceFirm>): Promise<InsuranceFirm> {
  const response = await ApiService.admin.updateFirm(firmId, data);
  return response.data;
}

export async function findInsuranceFirms(): Promise<InsuranceFirmWithProductCount[]> {
  const response = await ApiService.admin.fetchFirms();
  return response.data;
}

export async function getInsuranceFirm(firmId: string): Promise<InsuranceFirm> {
  const response = await ApiService.admin.fetchFirm(firmId);
  return response.data;
}
