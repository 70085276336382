import { useMemo, useState } from 'react';

import { PageQueryOptions, SortParams } from '@breathelife/types';

export const DEFAULT_PER_PAGE_OPTIONS = [
  { label: 10, value: 10 },
  { label: 15, value: 15 },
  { label: 25, value: 25 },
  { label: 50, value: 50 },
  { label: 100, value: 100 },
];

type UsePaginationHandlers<T> = {
  onPageChange: (page: number) => void;
  onLimitPerPageChange: (limit: number) => void;
  onSortingChange: (sort: SortParams<T>) => void;
};

type PaginationOptions<T> = Omit<PageQueryOptions<T>, '$page' | '$limit'> & {
  $page: number;
  $limit: number;
};

type PerPageOptions = Record<'label' | 'value', number>[];

export function usePagination<T>(
  queryOptions: PageQueryOptions<T> = {},
  total: number,
  perPageOptions: PerPageOptions = DEFAULT_PER_PAGE_OPTIONS,
): [queryOptions: PaginationOptions<T>, handlers: UsePaginationHandlers<T>] {
  const initialValue = {
    $limit: queryOptions.$limit || perPageOptions[1].value,
    $page: queryOptions.$page || 1,
    $sort: queryOptions.$sort,
  };
  const [state, setState] = useState(initialValue);

  const handlers = useMemo(
    () => ({
      onPageChange: ($page: number) => setState((prevState) => ({ ...prevState, $page })),
      onLimitPerPageChange: ($limit: number) =>
        setState((prevState) => {
          if ($limit > total && queryOptions?.$page !== 1) {
            return { ...prevState, $limit, $page: 1 };
          }

          return { ...prevState, $limit };
        }),
      onSortingChange: ($sort: SortParams<T>) => setState((prevState) => ({ ...prevState, $sort })),
    }),
    [total, queryOptions.$page],
  );

  return [state, handlers];
}
