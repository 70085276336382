import { Box } from '@breathelife/mui';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '../../../../../Styles/themed-styled-components';

import { Button } from '../../../../../Components/Button/Button';
import { CloseButton } from '../../../../../Components/Button/CloseButton';
import Typography from '../../../../../Components/Typography';

const CtaContainer = styled(Box)`
  background-color: ${(props) => props.theme.colors.grey[20]};
`;

const CtaButton = styled(Button)`
  padding: 6px 12px;
`;

type Props = {
  onClose: () => void;
  onSave: () => void;
  isFormValid: boolean;
  firmName?: string;
};

export function FirmDetailHeader({ onClose, onSave, isFormValid, firmName }: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <Box display='flex' flexDirection='column'>
      <Box display='flex' justifyContent='space-between' p={3}>
        <Typography variant='h2'>{firmName || t('admin.firmManagement.createNewFirm')}</Typography>
        <CloseButton data-testid={'createFirmHeader-close'} onClick={onClose} />
      </Box>
      <CtaContainer width='100%' display='flex' justifyContent='flex-end' alignItems='center' py={2} px={2.5} mb={2}>
        <Box component='span' mr={1}>
          <CtaButton color='primary' variant='outlined' onClick={onClose} data-testid='FirmDetailView-cancel'>
            {t('cta.cancel')}
          </CtaButton>
        </Box>
        <CtaButton
          data-testid='FirmDetailView-save'
          color='primary'
          variant='contained'
          onClick={onSave}
          disabled={!isFormValid}
        >
          {t('cta.save')}
        </CtaButton>
      </CtaContainer>
    </Box>
  );
}
