import { ReactElement } from 'react';

import { SvgIconProps } from '../../Components/Icons';

export function UploadIcon(props: SvgIconProps): ReactElement {
  return (
    <svg stroke='#757575' fill='#f6f6f6' height='16' width='16' xmlns='http://www.w3.org/2000/svg' {...props.svgProps}>
      <path d='M8 .5a7.5 7.5 0 110 15 7.5 7.5 0 010-15z' />
      <path strokeLinecap='round' strokeLinejoin='round' d='M10.5 6.5L8 4 5.5 6.5m2.5 5V4' />
    </svg>
  );
}
