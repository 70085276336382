import { Box } from '@breathelife/mui';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '../../../../Styles/themed-styled-components';

import { Attachment } from '@breathelife/types';
import { ImageTypes } from '@breathelife/ui-components';

import { IconButton } from '../../../../Components/Controls';
import { AttachmentIcon, Icon } from '../../../../Components/Icons';
import Typography from '../../../../Components/Typography';
import { formatDate, guessTimezone } from '../../../../Helpers/datetime';
import { printFileSize } from '../../../../Helpers/fileSize';

type FileAttachmentItemProps = {
  attachment: Attachment;
  onViewAttachment: (attachmentId: string) => void;
  onDeleteAttachment: (attachmentId: string) => Promise<void>;
};

const ListItem = styled(Box)`
  background-color: ${(props) => props.theme.colors.grey[0]};
  box-shadow: inset 0 -1px 0 ${(props) => props.theme.colors.grey[30]};
`;

const DisplayNameTypography = styled(Typography)`
  text-overflow: ellipsis;
`;

const SubtitleContainer = styled(Box)`
  color: ${(props) => props.theme.colors.grey[70]};
`;

export function FileAttachmentItem(props: FileAttachmentItemProps): ReactElement {
  const { attachment, onViewAttachment: onViewAttachmentProps, onDeleteAttachment: onDeleteAttachmentProps } = props;
  const { t } = useTranslation();

  const [isDeleting, setIsDeleting] = useState(false);

  const iconName = useMemo(() => {
    switch (attachment.mimeType) {
      case 'application/pdf':
        return 'pdf';

      default:
        return null;
    }
  }, [attachment.mimeType]);

  const sizeLabel = useMemo(() => printFileSize(attachment.sizeBytes, 1), [attachment.sizeBytes]);

  const dateLabel = useMemo(() => {
    const formattedDate = formatDate(attachment.createdAt, 'D/M/YYYY', guessTimezone());
    return t('modals.assistedApplication.fileAttachment.uploadedOn', { date: formattedDate });
  }, [attachment.createdAt, t]);

  const onViewAttachment = useCallback(
    () => onViewAttachmentProps(attachment.id),
    [attachment.id, onViewAttachmentProps],
  );

  const onDeleteAttachment = useCallback(async () => {
    setIsDeleting(true);
    await onDeleteAttachmentProps(attachment.id);
    setIsDeleting(false);
  }, [attachment.id, onDeleteAttachmentProps]);

  return (
    <ListItem display='flex' width='100%' px={2} py={1}>
      <Box display='flex' alignItems='center' flexGrow={1}>
        {iconName && (
          <Box mr={2}>
            <AttachmentIcon name={iconName} />
          </Box>
        )}
        <Box display='flex' flexDirection='column' flexGrow={1}>
          <Box>
            <DisplayNameTypography variant='body3'>{attachment.displayName}</DisplayNameTypography>
          </Box>
          <SubtitleContainer display='flex'>
            <Box mr={1}>
              <Typography variant='body5'>{sizeLabel}</Typography>
            </Box>
            <Box>
              <Typography variant='body5'>{dateLabel}</Typography>
            </Box>
          </SubtitleContainer>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexWrap: 'no-wrap', alignItems: 'center' }}>
        <Box mx={1}>
          <IconButton
            icon={
              <Icon
                name='eye'
                imageType={ImageTypes.meaningful}
                ariaLabel={t('modals.assistedApplication.fileAttachment.openAttachment')}
              />
            }
            disabled={isDeleting}
            onClick={onViewAttachment}
          />
        </Box>
        <Box>
          <IconButton
            icon={
              <Icon
                name='delete'
                imageType={ImageTypes.meaningful}
                ariaLabel={t('modals.assistedApplication.fileAttachment.deleteAttachment')}
              />
            }
            disabled={isDeleting}
            onClick={onDeleteAttachment}
          />
        </Box>
      </Box>
    </ListItem>
  );
}
