import { useTranslation } from 'react-i18next';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { useDispatch } from 'react-redux';

import { logger } from '@breathelife/monitoring-frontend';
import { LineOfBusiness } from '@breathelife/types';

import { QueryId } from '../../../ReactQuery/common/common.types';
import { notificationSlice } from '../../../ReduxStore/Notification/NotificationSlice';
import { fetchAllLinesOfBusiness } from '../../../Services/LinesOfBusinessService';

export function useFetchAllLinesOfBusinessQuery(
  options?: UseQueryOptions<LineOfBusiness[]>,
): UseQueryResult<LineOfBusiness[]> {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return useQuery<LineOfBusiness[]>(QueryId.allLinesOfBusiness, () => fetchAllLinesOfBusiness(), {
    ...options,
    onError(err) {
      dispatch(
        notificationSlice.actions.setError({
          message: t('notifications.failedToFetchLinesOfBusiness'),
        }),
      );
      logger.error(err);
      options?.onError?.(err);
    },
  });
}
