import { Permission } from '@breathelife/types';

import { ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { RestrictedToUserPermissions } from '../../../../Components/Restricted/RestrictedToUserPermissions';
import { ModalLayout } from '../../../../Layouts/Modal/ModalLayout';
import { SubmitButton } from '../../../../Components/Button/SubmitButton';
import Typography from '../../../../Components/Typography';

type DeleteQuestionnaireModalProps = {
  onSubmitClick: () => void;
  isOpen: boolean;
  closeModal: () => void;
};

export function DeleteQuestionnaireModal(props: DeleteQuestionnaireModalProps): ReactElement {
  const { isOpen, closeModal, onSubmitClick } = props;
  const { t } = useTranslation();

  const handleSubmit = useCallback(() => {
    onSubmitClick();
  }, [onSubmitClick]);

  return (
    <ModalLayout
      maxWidth='sm'
      isOpen={isOpen}
      closeModal={closeModal}
      title={t('admin.questionnaireManagement.deleteQuestionnaire.deleteQuestionnaire')}
      submitButton={
        <RestrictedToUserPermissions requiredPermissions={[Permission.QuestionnaireRemove]}>
          <SubmitButton onClick={handleSubmit} data-testid='deleteQuestionnaireModal'>
            {t('admin.questionnaireManagement.deleteQuestionnaire.deleteQuestionnaire')}
          </SubmitButton>
        </RestrictedToUserPermissions>
      }
    >
      <Typography variant='h3' grey={90} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {t('admin.questionnaireManagement.deleteQuestionnaire.confirmation')}
      </Typography>
    </ModalLayout>
  );
}
