import { useMutation, useQueryClient, UseMutationResult, UseMutationOptions } from 'react-query';

import { FileTemplateRule, CreateFileTemplateRuleData, PatchFileTemplateRuleData } from '@breathelife/types';

import { QueryId } from '../../../ReactQuery/common/common.types';
import {
  createFileTemplateRule,
  deleteFileTemplateRule,
  patchFileTemplateRule,
} from '../../../Services/FileTemplateRulesService';

export function useCreateFileTemplateRuleMutation(
  options?: UseMutationOptions<FileTemplateRule, unknown, CreateFileTemplateRuleData>,
): UseMutationResult<FileTemplateRule, unknown, CreateFileTemplateRuleData> {
  const queryClient = useQueryClient();
  return useMutation<FileTemplateRule, unknown, CreateFileTemplateRuleData, unknown>(createFileTemplateRule, {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([QueryId.fileTemplateRules, data.questionnaireVersionId, data.templateId]);
      if (options?.onSuccess) {
        await options.onSuccess(data, variables, context);
      }
    },
  });
}

type PatchFileTemplateRuleParameters = {
  id: string;
  data: PatchFileTemplateRuleData;
};

export function usePatchFileTemplateRuleMutation(
  options?: UseMutationOptions<FileTemplateRule, unknown, PatchFileTemplateRuleParameters>,
): UseMutationResult<FileTemplateRule, unknown, PatchFileTemplateRuleParameters> {
  const queryClient = useQueryClient();
  return useMutation<FileTemplateRule, unknown, PatchFileTemplateRuleParameters, unknown>(
    ({ data, id }) => patchFileTemplateRule(id, data),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries([QueryId.fileTemplateRules, data.questionnaireVersionId, data.templateId]);
        if (options?.onSuccess) {
          await options.onSuccess(data, variables, context);
        }
      },
    },
  );
}

export function useDeleteFileTemplateRuleMutation(
  mutationContext: {
    questionnaireVersionId?: string;
    templateId?: string;
  },
  options?: UseMutationOptions<void, unknown, string>,
): UseMutationResult<void, unknown, string> {
  const queryClient = useQueryClient();
  return useMutation<void, unknown, string>((id) => deleteFileTemplateRule(id), {
    ...options,
    onSuccess: async (data, variables, context) => {
      const queryKey: string[] = [QueryId.fileTemplateRules];

      if (mutationContext.questionnaireVersionId) {
        queryKey.push(mutationContext.questionnaireVersionId);
      }

      if (mutationContext.templateId) {
        queryKey.push(mutationContext.templateId);
      }

      await queryClient.invalidateQueries(queryKey);

      if (options?.onSuccess) {
        await options.onSuccess(data, variables, context);
      }
    },
  });
}
