import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Chip } from '@breathelife/mui';
import { BaseESignSigner } from '@breathelife/types';

import { GreyContainer } from '../../../../../Components/AssistedApplication/Styles';
import Typography from '../../../../../Components/Typography';
import styled from '../../../../../Styles/themed-styled-components';

type SignerInfoHeaderProps = {
  signer: BaseESignSigner;
};

const StyledChip = styled(Chip)`
  margin-right: 10px;
  && {
    background-color: ${(props) => props.theme.colors.blue[20]};
    color: ${(props) => props.theme.colors.blue[50]};
    border-color: ${(props) => props.theme.colors.blue[50]};
  }
`;

export function SignerInfoHeader(props: SignerInfoHeaderProps): ReactElement {
  const { signer } = props;
  const { firstName, lastName, roles } = signer;

  const { t } = useTranslation();

  return (
    <GreyContainer display='flex' py={1} px={2} mb={2}>
      <Box display='flex' alignItems='center'>
        <Typography variant='h4' component='span'>
          {firstName} {lastName} :
        </Typography>
      </Box>
      <Box ml={1} display='flex' alignItems='center'>
        {roles.map((role) => (
          <StyledChip
            key={role.type}
            label={t(`participantRole.${role.type}`)}
            size='small'
            variant='outlined'
            color='info'
          />
        ))}
      </Box>
    </GreyContainer>
  );
}
