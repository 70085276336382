import { ReactElement } from 'react';

import { CreateLeadButton } from './CreateLeadButton';
import { CreateApplicationAndLaunchButton } from './CreateApplicationAndLaunchButton';
import { CreateApplicationWithLeadAndLaunchButton } from './CreateApplicationWithLeadAndLaunchButton';

export enum CreateButtonActionType {
  createLead = 'createLead',
  createApplicationAndLaunch = 'createApplicationAndLaunch', // when the application is already created
  createApplicationWithLeadAndLaunch = 'createApplicationWithLeadAndLaunch',
}

type CreateButtonProps = {
  action: CreateButtonActionType;
  idSuffix?: string;
};

export function CreateButton(props: CreateButtonProps): ReactElement {
  const { action, idSuffix } = props;

  switch (action) {
    case CreateButtonActionType.createLead:
      return <CreateLeadButton idSuffix={idSuffix} />;
    case CreateButtonActionType.createApplicationAndLaunch:
      return <CreateApplicationAndLaunchButton />;
    case CreateButtonActionType.createApplicationWithLeadAndLaunch:
      return <CreateApplicationWithLeadAndLaunchButton />;
  }
}
