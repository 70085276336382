import { SVGProps, ReactElement } from 'react';

export function Error(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg
      fill='none'
      height='182'
      viewBox='0 0 170 182'
      width='170'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <mask id='a' fill='#fff'>
        <path
          d='m64.468 97.7676 5.5314 5.5294 5.5295-5.5275.0015-.0016c1.0216-1.0233 2.6784-1.0235 3.701-.0027 1.0232 1.0215 1.0253 2.6788.0033 3.7028l-5.533 5.532 5.5307 5.532c1.0221 1.022 1.0221 2.679 0 3.701-1.0222 1.023-2.6794 1.023-3.7016 0l-5.5314-5.532-5.5324 5.533c-1.0202 1.019-2.6781 1.024-3.7016-.002-1.019-1.02-1.0224-2.677.0009-3.7l5.5309-5.532-5.5318-5.532c-1.021-1.023-1.019-2.6805.0017-3.7011 1.0221-1.0221 2.6794-1.0206 3.7009.0007z'
          fill='#fff'
        />
      </mask>
      <path
        d='m85 176.5c46.944 0 85-38.056 85-85 0-46.9442-38.056-85-85-85-46.9442 0-85 38.0558-85 85 0 46.944 38.0558 85 85 85z'
        fill='#fcfcfc'
      />
      <path
        d='m85 176.5c46.944 0 85-38.056 85-85 0-46.9442-38.056-85-85-85-46.9442 0-85 38.0558-85 85 0 46.944 38.0558 85 85 85z'
        fill='#fcfcfc'
      />
      <path
        d='m88.3699 141.993c18.3681 0 33.2591-1.317 33.2591-2.942 0-1.624-14.891-2.941-33.2591-2.941-18.3682 0-33.2586 1.317-33.2586 2.941 0 1.625 14.8904 2.942 33.2586 2.942z'
        fill='#eee'
      />
      <path
        d='m68.8171 51h36.5699l14.613 15.1629v54.9381c0 .503-.193.987-.537 1.343-.343.356-.809.556-1.295.556h-49.3509c-.4807-.004-.9406-.204-1.2805-.556-.3399-.353-.5326-.829-.5366-1.328v-68.2324c.004-.4983.1967-.975.5366-1.3274.3399-.3523.7998-.5521 1.2805-.5562z'
        fill='#f6f6f6'
      />
      <path
        d='m65.8844 48.3113h39.1226l15.633 15.6975v56.8752c0 .521-.206 1.021-.574 1.39-.367.369-.866.576-1.386.576h-52.7956c-.5142-.004-1.0063-.211-1.3699-.576-.3637-.365-.5698-.858-.5741-1.374v-70.6387c.0043-.5159.2104-1.0094.5741-1.3742.3636-.3648.8557-.5716 1.3699-.5758z'
        stroke='#757575'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='m120.64 64.0088-15.649-15.6975v13.7312c0 .5187.204 1.0164.569 1.3847.364.3682.858.5773 1.375.5816z'
        fill='#fff'
        stroke='#757575'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='m69.7884 129.74c12.4274 0 22.5018-10.105 22.5018-22.571 0-12.4659-10.0744-22.5713-22.5018-22.5713s-22.5018 10.1054-22.5018 22.5713c0 12.466 10.0744 22.571 22.5018 22.571z'
        fill='#f6b1b1'
        stroke='#757575'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='m69.7886 124.833c9.7254 0 17.6094-7.909 17.6094-17.664 0-9.7555-7.884-17.6639-17.6094-17.6639s-17.6094 7.9084-17.6094 17.6639c0 9.755 7.884 17.664 17.6094 17.664z'
        fill='#fff'
      />
      <path
        d='m69.7886 94.4124c4.2434.0023 8.343 1.543 11.5433 4.3381 3.2003 2.7955 5.2856 6.6565 5.8717 10.8715.1192-.785.1842-1.578.1944-2.372 0-4.685-1.8553-9.1776-5.1577-12.4902s-7.7814-5.1736-12.4517-5.1736-9.1493 1.861-12.4517 5.1736-5.1577 7.8052-5.1577 12.4902c.0064.821.0714 1.641.1944 2.454.5714-4.229 2.65-8.108 5.8514-10.9189 3.2013-2.8109 7.309-4.3642 11.5636-4.3727z'
        fill='#f0f0f0'
      />
      <path
        d='m64.468 97.7676 5.5314 5.5294 5.5295-5.5275.0015-.0016c1.0216-1.0233 2.6784-1.0235 3.701-.0027 1.0232 1.0215 1.0253 2.6788.0033 3.7028l-5.533 5.532 5.5307 5.532c1.0221 1.022 1.0221 2.679 0 3.701-1.0222 1.023-2.6794 1.023-3.7016 0l-5.5314-5.532-5.5324 5.533c-1.0202 1.019-2.6781 1.024-3.7016-.002-1.019-1.02-1.0224-2.677.0009-3.7l5.5309-5.532-5.5318-5.532c-1.021-1.023-1.019-2.6805.0017-3.7011 1.0221-1.0221 2.6794-1.0206 3.7009.0007z'
        fill='#f6b1b1'
      />
      <path
        d='m69.9994 103.297-.707.707.707.706.7069-.706zm-5.5314-5.5294-.707.7072v.0001zm11.0609.0019.7069.7072.001-.0009zm.0015-.0016-.7077-.7064-.0002.0001zm3.701-.0027-.7065.7078zm.0033 3.7028.7071.707.0006-.001zm-.0023 11.064-.7072.707zm-5.5307-5.532-.7071-.707-.7071.707.707.707zm5.5307 9.233.7071.707zm-3.7016 0-.7072.707h.0001zm-14.7654-.001.7078-.706h-.0001zm3.7016.002.7065.708.0007-.001zm-3.7007-3.702.7071.707zm5.5309-5.532.7072.707.7069-.707-.707-.707zm-5.5301-9.2331.7071.7071zm-.0017 3.7011-.7077.706.0006.001zm9.234 9.233.7071-.707-.7071-.707-.7072.707zm.7069-8.112-5.5314-5.5287-1.4139 1.4146 5.5314 5.5291zm4.1156-5.5268-5.5295 5.5268 1.4139 1.415 5.5295-5.5273zm.0006-.0006-.0015.0016 1.4158 1.4126.0015-.0016zm5.1154-.0041c-1.413-1.4106-3.703-1.4107-5.1152.004l1.4155 1.4129c.6309-.632 1.6545-.6326 2.2867-.0014zm.0045 5.1165c1.4124-1.415 1.4094-3.7051-.0045-5.1165l-1.413 1.4155c.6325.6314.6337 1.655.0021 2.288zm-.0028 9.651-5.5308-5.532-1.4143 1.414 5.5307 5.532zm-.0001 5.115c1.4127-1.412 1.4125-3.703 0-5.115l-1.4143 1.414c.6317.632.6317 1.656.0001 2.287zm-5.1158 0c1.4127 1.413 3.7031 1.413 5.1158 0l-1.4142-1.414c-.6317.632-1.6557.632-2.2874 0zm-14.766-.001c1.4155 1.418 3.7066 1.41 5.1158.003l-1.413-1.415c-.6312.63-1.656.631-2.2873-.001zm.0014-5.114c-1.4147 1.415-1.4091 3.704-.0014 5.114l1.4154-1.413c-.6302-.631-.6315-1.655.0003-2.287zm5.5309-5.532-5.5309 5.532 1.4143 1.414 5.531-5.532zm-5.53-9.2332c-1.4106 1.4106-1.4136 3.7002-.0023 5.1142l1.4155-1.413c-.6308-.632-.6297-1.6563.001-2.287zm5.115.0006c-1.4114-1.4111-3.7018-1.4138-5.115-.0006l1.4142 1.4142c.631-.6309 1.6551-.6307 2.2868.0008zm9.2337 10.6466 5.5331-5.532-1.4141-1.415-5.5331 5.533zm-5.1165 3.702 5.5314 5.531 1.4143-1.414-5.5314-5.532zm-4.118 5.532 5.5323-5.532-1.4143-1.415-5.5323 5.533zm-5.1159-14.766 5.5318 5.532 1.4142-1.414-5.5317-5.533z'
        fill='#757575'
        mask='url(#a)'
      />
      <g stroke='#757575' strokeLinecap='round' strokeLinejoin='round'>
        <path d='m69.7886 124.833c9.7254 0 17.6094-7.909 17.6094-17.664 0-9.7555-7.884-17.6639-17.6094-17.6639s-17.6094 7.9084-17.6094 17.6639c0 9.755 7.884 17.664 17.6094 17.664z' />
        <path d='m74.4214 58.1101h21.5136' />
        <path d='m74.4214 64.9839h21.5136' />
        <path d='m74.4214 71.8574h36.1906' />
        <path d='m74.4214 78.7151h36.1906' />
        <path d='m90.0708 85.5886h20.5412' />
        <path d='m95.9351 92.4624h14.6769' />
        <path d='m98.8672 99.3198h11.7448' />
        <path d='m99.8555 106.194h10.7565' />
        <path d='m98.8672 113.051h11.7448' />
      </g>
    </svg>
  );
}
