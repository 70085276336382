import { IconButton } from '@breathelife/mui';

import styled from '../../../Styles/themed-styled-components';
import Typography from '../../../Components/Typography';
import { CloseButton } from '../../../Components/Button/CloseButton';

export const HeaderContent = styled.div`
  display: flex;
  align-items: top;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 2px solid ${(props) => props.theme.colors.grey[40]};
`;

export const HelpButton = styled(IconButton)`
  && {
    margin-top: 10px;
  }
`;

export const Title = styled(Typography)`
  && {
    margin-bottom: 5px;
  }
`;

export const DrawerCloseButton = styled(CloseButton)`
  && {
    display: flex;
    align-items: flex-start;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FeedbackIcon = styled(IconButton)`
  && {
    margin-right: 15px;
  }
`;

export const RightSideContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
`;

export const PopoverContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  max-width: 250px;
`;

export const HelpPopoverContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  max-width: 550px;
`;

export const HelpPopoverHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  svg {
    width: 15px;
  }
`;

export const PopoverCloseButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
  svg {
    width: 15px;
  }
`;

export const LoadMoreContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px;

  p {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
  }
`;
