import { ReactElement } from 'react';

import { FieldGenerator, RenderContext } from '@breathelife/field-generator';
import { RenderingQuestion, RenderingSubsection } from '@breathelife/questionnaire-engine';
import { Language } from '@breathelife/types';

import Typography from '../../../../../../../Components/Typography';
import getCurrency from '../../../../../../../Helpers/currency';
import { useCarrierContext } from '../../../../../../../Hooks';
import { getLanguage } from '../../../../../../../Localization/utils';
import { SubsectionContainer } from './Styles';

type SubsectionProps = {
  subsection: RenderingSubsection;
};

// NLG has a confirmation only "step" that does not have any fields,
// While we use the engine to manage that "step", there are no fields associated
// so we should not display such steps in the pro platform
function isTextOnlyConfirmationStep(subsection: RenderingSubsection): boolean {
  const subSectionHasSingleQuestion = subsection.questions.length === 1;
  const singleQuestionHasNoFields = subSectionHasSingleQuestion && subsection.questions[0].fields.length === 0;
  const subSectionHasNoQuestion = subsection.questions.length === 0;
  return singleQuestionHasNoFields || subSectionHasNoQuestion;
}

export function Subsection(props: SubsectionProps): ReactElement | null {
  const { subsection } = props;
  const { countryCode } = useCarrierContext();

  if (!subsection.completed || !subsection.visible || isTextOnlyConfirmationStep(subsection)) return null;

  return (
    <SubsectionContainer mt={3} pb={3}>
      {subsection.title && <Typography variant='h3'>{subsection.title}</Typography>}
      {subsection.questions.map((question: RenderingQuestion) => (
        <FieldGenerator
          key={question.id}
          context={RenderContext.proSummary}
          question={question}
          locale={getLanguage() as Language}
          currency={getCurrency(countryCode)}
        />
      ))}
    </SubsectionContainer>
  );
}
