import { Permission, User } from '@breathelife/types';
import { ReactElement, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { AssistedApplication } from '../Components/AssistedApplication/AssistedApplication';
import { DebugToolbarContainer } from '../Components/DebugToolbar/DebugToolbarContainer';

import { Notification } from '../Components/Notification/Notification';
import { userHasPermission } from '../Helpers/user';
import { useCarrierContext, useSelector } from '../Hooks';
import { useAdminTabs } from '../Hooks/useAdminTabs';
import { LeadsPageLayout } from '../Layouts/LeadsPageLayout/LeadsPageLayout';
import { PageLayout } from '../Layouts/PageLayout/PageLayout';
import { LeadTab } from '../Models/Layout';
import Urls, { generateLeadsListUrl } from '../Navigation/Urls';
import { ApplicationOverviewContainer } from '../Pages/Admin/ApplicationSupport/ApplicationOverview/ApplicationOverviewContainer';
import { ApplicationsTablePage } from '../Pages/Admin/ApplicationSupport/ApplicationsViewContainer';
import { AssociatedFilesPage } from '../Pages/Admin/ApplicationSupport/AssociatedFiles/AssociatedFilesPage';
import { ESignatureViewContainer } from '../Pages/Admin/ApplicationSupport/ESignature/ESignatureViewContainer';
import { UnderwritingReportsViewContainer } from '../Pages/Admin/ApplicationSupport/UnderwritingReports/UnderwritingReportsViewContainer';
import { DocumentManagementViewContainer } from '../Pages/Admin/Document/DocumentManagementViewContainer';
import { FirmManagementViewContainer } from '../Pages/Admin/Firm/FirmManagementViewContainer';
import { AuditLogsViewContainer } from '../Pages/Admin/Logs/AuditLogsViewContainer';
import { ProductManagementViewContainer } from '../Pages/Admin/Product/ProductManagementViewContainer';
import { QuestionnaireVersionManagementViewContainer } from '../Pages/Admin/Questionnaire/QuestionnaireVersionManagementViewContainer';
import { QuestionnaireVersionsContextProvider } from '../Pages/Admin/Questionnaire/ContextProvider/QuestionnaireVersionsContextProvider';
import { QuestionnaireBuilderView } from '../Pages/Admin/QuestionnaireBuilder/QuestionnaireBuilderView';
import { SalesDecisionRulesManagementViewContainer } from '../Pages/Admin/SalesDecisionRules/SalesDecisionRulesManagementViewContainer';
import { SettingsViewContainer } from '../Pages/Admin/Settings/SettingsViewContainer';
import { ThemeManagementViewContainer } from '../Pages/Admin/Theme/ThemeManagementViewContainer';
import { ThirdPartyIntegrationsView } from '../Pages/Admin/ThirdPartyIntegrations/ThirdPartyIntegrationsView';
import { UserManagementViewContainer } from '../Pages/Admin/UserManagement/UserManagementViewContainer';
import { LeadsListView } from '../Pages/Home/LeadsListView/LeadsListView';
import { LeadsPageDataContextProvider } from '../Pages/Leads/LeadsPageDataContextProvider';
import AuthCallbackPage from '../Pages/Login/AuthCallback/AuthCallbackContainer';
import LoginPage from '../Pages/Login/LoginContainer';
import LogoutPage from '../Pages/Login/LogoutContainer';
import { PrivateRootApplication } from '../Root/PrivateRootApplication';
import RequiredAuth from '../Root/RequiredAuth';
import { QuestionnaireManagementViewContainer } from '../Pages/Admin/Questionnaire/QuestionnaireManagementViewContainer';
import { ApplicationSupportPage } from '../Pages/Admin/ApplicationSupport/ApplicationSupportPage';
import { PricingFieldIdentifiersManagementViewContainer } from '../Pages/Admin/PricingFieldIdentifiers/PricingFieldIdentifiersManagementViewContainer';
import { AdHocSignatureRequestsView } from '../Pages/Admin/AdHocSignatureRequests/AdHocSignatureRequestsView';
import { AdHocSignatureRequestView } from '../Pages/Admin/AdHocSignatureRequests/AdHocSignatureRequestView';

export function Root(): ReactElement {
  const location = useLocation();
  const { features } = useCarrierContext();
  const { t } = useTranslation();
  const user = useSelector((store) => store.leadPlatform.authentication.user);

  const useApplicationTabs = features.hiddenLeads?.enabled;
  const leadTabs = [
    {
      to: generateLeadsListUrl(LeadTab.active),
      label: useApplicationTabs ? t('applicationTable.active') : t('leadsListTable.active'),
      ['data-testid']: 'leads-list-tab-active',
    },
    {
      to: generateLeadsListUrl(LeadTab.archived),
      label: useApplicationTabs ? t('applicationTable.archived') : t('leadsListTable.archived'),
      ['data-testid']: 'leads-list-tab-archive',
    },
  ];
  const adminTabs = useAdminTabs();

  return (
    <Fragment>
      <Notification />
      {/* Since react-router v6, nested route works with Outlets (https://reactrouter.com/en/main/components/outlet#outlet). Each parent route contains render an "<Outlet />" component which is responsible to render the child route.  */}
      <Routes>
        {/* Leads routes */}
        <Route
          path={Urls.leads.path}
          element={
            <RequiredAuth>
              <PrivateRootApplication />
            </RequiredAuth>
          }
        >
          <Route index element={<Navigate to={{ pathname: leadTabs[0].to, search: location.search }} replace />} />
          <Route
            path={Urls.leadsList.path}
            element={
              <RequiredAuth>
                <LeadsPageLayout pageTitle={t('pageTitles.home')} tabs={leadTabs}>
                  {features.questionnaireDebugTools.enabled && <DebugToolbarContainer />}
                  <LeadsPageDataContextProvider />
                </LeadsPageLayout>
              </RequiredAuth>
            }
          >
            <Route
              index
              element={
                <RequiredAuth>
                  <LeadsListView />
                </RequiredAuth>
              }
            />
            <Route
              path={Urls.leadDetail.path}
              element={
                <RequiredAuth>
                  <LeadsListView />
                </RequiredAuth>
              }
            ></Route>
          </Route>
        </Route>
        <Route
          path={Urls.leads.path}
          element={
            <RequiredAuth>
              <PrivateRootApplication displayContentFullScreen />
            </RequiredAuth>
          }
        >
          <Route
            path={Urls.application.path}
            element={
              <RequiredAuth>
                <AssistedApplication />
              </RequiredAuth>
            }
          >
            <Route path={Urls.applicationDrawer.path} />
          </Route>
        </Route>

        {/* Admin routes */}
        <Route
          element={
            <RequiredAuth>
              <PrivateRootApplication displayContentFullScreen />
            </RequiredAuth>
          }
        >
          <Route
            element={
              <RequiredAuth permissions={Permission.AdminToolsRead}>
                <PageLayout tabs={adminTabs} />
              </RequiredAuth>
            }
          >
            <Route
              index
              path={Urls.admin.path}
              element={<Navigate to={{ pathname: getRootRedirect(user), search: location.search }} replace />}
            />
            <Route
              path={Urls.userManagement.path}
              element={
                <RequiredAuth>
                  <UserManagementViewContainer />
                </RequiredAuth>
              }
            />
            <Route
              path={Urls.productManagement.path}
              element={
                <RequiredAuth>
                  <ProductManagementViewContainer />
                </RequiredAuth>
              }
            />
            <Route
              path={Urls.themeManagement.path}
              element={
                <RequiredAuth>
                  <ThemeManagementViewContainer />
                </RequiredAuth>
              }
            />
            <Route
              path={Urls.settingsManagement.path}
              element={
                <RequiredAuth>
                  <SettingsViewContainer />
                </RequiredAuth>
              }
            />
            <Route
              path={Urls.auditLogs.path}
              element={
                <RequiredAuth>
                  <AuditLogsViewContainer />
                </RequiredAuth>
              }
            />
            <Route
              path={Urls.firmManagement.path}
              element={
                <RequiredAuth>
                  <FirmManagementViewContainer />
                </RequiredAuth>
              }
            />
            <Route
              path={Urls.documentsManagement.path}
              element={
                <RequiredAuth>
                  <DocumentManagementViewContainer />
                </RequiredAuth>
              }
            />
            <Route
              path={Urls.questionnaireBuilder.path}
              element={
                <RequiredAuth>
                  <QuestionnaireBuilderView />
                </RequiredAuth>
              }
            />
            <Route
              path={Urls.thirdPartyIntegrations.path}
              element={
                <RequiredAuth>
                  <ThirdPartyIntegrationsView />
                </RequiredAuth>
              }
            />
            <Route
              path={Urls.salesDecisionRulesManagement.path}
              element={
                <RequiredAuth>
                  <QuestionnaireVersionsContextProvider>
                    <SalesDecisionRulesManagementViewContainer />
                  </QuestionnaireVersionsContextProvider>
                </RequiredAuth>
              }
            />
            <Route
              path={Urls.questionnaireManagement.path}
              element={
                <RequiredAuth>
                  <QuestionnaireVersionsContextProvider>
                    <QuestionnaireManagementViewContainer />
                  </QuestionnaireVersionsContextProvider>
                </RequiredAuth>
              }
            >
              <Route
                path={Urls.questionnaire.path}
                element={
                  <RequiredAuth>
                    <QuestionnaireVersionsContextProvider>
                      <QuestionnaireManagementViewContainer />
                    </QuestionnaireVersionsContextProvider>
                  </RequiredAuth>
                }
              />
            </Route>
            <Route
              path={Urls.questionnaireVersion.path}
              element={
                <RequiredAuth>
                  <QuestionnaireVersionManagementViewContainer />
                </RequiredAuth>
              }
            />
            {/* This path has to live within the admin route because it is available inside the admin layout.
            Once an application is selected, we stay under the admin route, but we get a new layout from the "applications/:applicationsId" route */}
            <Route
              path={Urls.applicationSupportApplications.path}
              element={
                <RequiredAuth>
                  <ApplicationsTablePage />
                </RequiredAuth>
              }
            />
            <Route
              path={Urls.pricingFieldIdentifiersManagement.path}
              element={
                <RequiredAuth>
                  <QuestionnaireVersionsContextProvider>
                    <PricingFieldIdentifiersManagementViewContainer />
                  </QuestionnaireVersionsContextProvider>
                </RequiredAuth>
              }
            />
            <Route
              path={Urls.adHocSignatureRequest.path}
              element={
                <RequiredAuth>
                  <AdHocSignatureRequestView />
                </RequiredAuth>
              }
            />
            <Route
              path={Urls.adHocSignatureRequests.path}
              element={
                <RequiredAuth>
                  <AdHocSignatureRequestsView />
                </RequiredAuth>
              }
            />
          </Route>
        </Route>

        <Route
          element={
            <RequiredAuth>
              <PrivateRootApplication displayContentFullScreen />
            </RequiredAuth>
          }
        >
          {/* The nested routes when an application is selected from the application support ApplicationsTablePage. The layout is different. */}
          <Route
            path={Urls.applicationSupportApplication.path}
            element={
              <RequiredAuth>
                <ApplicationSupportPage />
              </RequiredAuth>
            }
          >
            <Route
              path={Urls.applicationSupportOverview.path}
              element={
                <RequiredAuth>
                  <ApplicationOverviewContainer />
                </RequiredAuth>
              }
            />
            <Route
              path={Urls.applicationSupportUnderwritingReports.path}
              element={
                <RequiredAuth>
                  <UnderwritingReportsViewContainer />
                </RequiredAuth>
              }
            />
            <Route
              path={Urls.applicationSupportAssociatedFiles.path}
              element={
                <RequiredAuth>
                  <AssociatedFilesPage />
                </RequiredAuth>
              }
            />
            <Route
              path={Urls.applicationSupportESignature.path}
              element={
                <RequiredAuth>
                  <ESignatureViewContainer />
                </RequiredAuth>
              }
            />
          </Route>
        </Route>
        <Route path={Urls.login.path} element={<LoginPage />} />
        <Route path={Urls.logout.path} element={<LogoutPage />} />
        <Route path={Urls.authCallback.path} element={<AuthCallbackPage />} />
        <Route
          path='*'
          element={<Navigate to={{ pathname: Urls.leads.fullPath, search: location.search }} replace />}
        />
      </Routes>
    </Fragment>
  );
}

// TODO HOT-2105 This is temporary until we have a landing page in the admin that does not requires the same level of permission as showing the Admin button
export function getRootRedirect(user: User | undefined): string {
  if (user?.permissions?.length) {
    if (userHasPermission(user.permissions, Permission.QuestionnaireUpdate)) {
      return Urls.questionnaireManagement.fullPath;
    }
    if (userHasPermission(user.permissions, Permission.ApplicationSupportApplicationsRead)) {
      return Urls.applicationSupportApplications.fullPath;
    }
  }
  return Urls.leads.fullPath;
}
