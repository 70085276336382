import { Box } from '@breathelife/mui';
import { ReactElement, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { FieldId, TypewriterTracking } from '@breathelife/frontend-tracking';
import { Input as TextInput, PhoneInput } from '@breathelife/ui-components';

import { getFieldValidationError } from '../../../../Helpers/inputValidation/form/advisor';
import { useCarrierContext } from '../../../../Hooks';

type Props = {
  firstName: string;
  lastName: string;
  preferredName: string;
  email: string;
  phoneNumber: string;
  onFirstNameChange: (value: string) => void;
  onLastNameChange: (value: string) => void;
  onPreferredNameChange: (value: string) => void;
  onEmailChange: (value: string) => void;
  onPhoneNumberChange: (value: string) => void;
  isSso?: boolean;
};

export function ContactInfo(props: Props): ReactElement {
  const { t } = useTranslation();
  const { ssoProfileFields } = useCarrierContext();
  const firstNameError = getFieldValidationError('firstName', props.firstName);
  const lastNameError = getFieldValidationError('lastName', props.lastName);
  const preferredNameError = getFieldValidationError('preferredName', props.preferredName);
  const emailError = getFieldValidationError('emailLogin', props.email);
  const phoneNumberError = getFieldValidationError('phoneNumber', props.phoneNumber);

  const onBlur = (fieldId: string): void => {
    TypewriterTracking.completedField({
      fieldId,
      hashedId: null,
    });
  };

  const onFirstNameChange = (value: string): void => {
    props.onFirstNameChange(value);
  };

  const onLastNameChange = (value: string): void => {
    props.onLastNameChange(value);
  };

  const onPreferredNameChange = (value: string): void => {
    props.onPreferredNameChange(value);
  };

  const onEmailChange = (value: string): void => {
    props.onEmailChange(value);
  };

  const onPhoneNumberChange = (value: string): void => {
    props.onPhoneNumberChange(value);
  };

  return (
    <Fragment>
      <Box mt={2} mb={2}>
        <TextInput
          fullWidth
          required
          id='userEditModal-firstName'
          inputVariant='outlined'
          label={t('inputs.firstName')}
          error={!!firstNameError}
          validationError={firstNameError}
          value={props.firstName}
          disabled={props.isSso && ssoProfileFields.includes('firstName')}
          onChange={(event) => onFirstNameChange(event.target.value)}
          onBlur={() => onBlur(FieldId.userProfileFirstName)}
        />
      </Box>
      <Box mb={2}>
        <TextInput
          fullWidth
          id='userEditModal-preferredName'
          inputVariant='outlined'
          label={t('inputs.preferredName')}
          error={!!preferredNameError}
          validationError={preferredNameError}
          value={props.preferredName}
          disabled={props.isSso && ssoProfileFields.includes('preferredName')}
          onChange={(event) => onPreferredNameChange(event.target.value)}
          onBlur={() => onBlur(FieldId.userProfilePreferredName)}
        />
      </Box>
      <Box mb={2}>
        <TextInput
          fullWidth
          required
          id='userEditModal-lastName'
          inputVariant='outlined'
          label={t('inputs.lastName')}
          error={!!lastNameError}
          validationError={lastNameError}
          value={props.lastName}
          disabled={props.isSso && ssoProfileFields.includes('lastName')}
          onChange={(event) => onLastNameChange(event.target.value)}
          onBlur={() => onBlur(FieldId.userProfileLastName)}
        />
      </Box>
      <Box mb={2}>
        <TextInput
          fullWidth
          required
          id='userEditModal-email'
          inputVariant='outlined'
          label={t('inputs.email')}
          error={!!emailError}
          validationError={emailError}
          value={props.email}
          disabled={props.isSso && ssoProfileFields.includes('emailLogin')}
          onChange={(event) => onEmailChange(event.target.value)}
          onBlur={() => onBlur(FieldId.userProfileEmail)}
        />
      </Box>
      <Box>
        <PhoneInput
          name='userEditModal-phoneNumber'
          fullWidth
          required
          label={t('inputs.phoneNumber')}
          inputVariant='outlined'
          validationError={phoneNumberError}
          value={props.phoneNumber}
          disabled={props.isSso && ssoProfileFields.includes('phoneNumber')}
          onAnswerChange={(answer: string) => onPhoneNumberChange(answer)}
          onBlur={(e) => {
            onPhoneNumberChange(e.target.value as string);
            return onBlur(FieldId.userProfilePhoneNumber);
          }}
        />
      </Box>
    </Fragment>
  );
}
