import { Box } from '@breathelife/mui';
import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CloseButton } from '../../../../Components/Button/CloseButton';
import Typography from '../../../../Components/Typography';
import { DetailViewType } from '../../../../Models/Layout';
import { ArchiveModal } from './Components/ArchiveModal';
import { CtaButton, CtaContainer } from './Styles';

type Props = {
  onClose: () => void;
  onCreateClick: () => void;
  onSaveClick: () => void;
  onArchiveClick: () => void;
  isFormValid: boolean;
  viewType: DetailViewType;
  productName?: string;
  isProductArchived: boolean;
  isExistingProduct: boolean;
};

export function ProductDetailHeader(props: Props): ReactElement {
  const { t } = useTranslation();

  const [isArchiveModalOpen, setArchiveModalIsOpen] = useState(false);

  return (
    <Box display='flex' flexDirection='column'>
      <Box display='flex' justifyContent='space-between' p={3}>
        <Typography variant='h2'>{props.productName || t('admin.productManagement.createNewProduct')}</Typography>
        <CloseButton data-testid={'createProductHeader-close'} onClick={props.onClose} />
      </Box>

      <CtaContainer
        width='100%'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        py={2}
        px={2.5}
        mb={2}
      >
        <div>
          {props.isExistingProduct && (
            <CtaButton
              color='primary'
              variant='outlined'
              onClick={() => setArchiveModalIsOpen(true)}
              data-testid={props.isProductArchived ? 'ProductDetailView-unarchive' : 'ProductDetailView-archive'}
            >
              {props.isProductArchived
                ? t('admin.productManagement.archiving.unarchive')
                : t('admin.productManagement.archiving.archive')}
            </CtaButton>
          )}
        </div>
        <div>
          <Box component='span' mr={1}>
            <CtaButton
              color='primary'
              variant='outlined'
              onClick={props.onClose}
              data-testid='ProductDetailView-cancel'
            >
              {t('cta.cancel')}
            </CtaButton>
          </Box>
          <CtaButton
            data-testid='ProductDetailView-save'
            color='primary'
            variant='contained'
            onClick={props.viewType === DetailViewType.create ? props.onCreateClick : props.onSaveClick}
            disabled={!props.isFormValid || props.isProductArchived}
          >
            {t('cta.save')}
          </CtaButton>
        </div>
      </CtaContainer>
      <ArchiveModal
        isOpen={isArchiveModalOpen}
        onCloseModal={() => setArchiveModalIsOpen(false)}
        onSubmit={props.onArchiveClick}
        isProductArchived={props.isProductArchived}
      />
    </Box>
  );
}
