import { Fragment, ReactElement, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { EditIcon, Grid, IconButton } from '@breathelife/mui';

import {
  Language,
  Localizable,
  QuestionBlueprint,
  QuestionPartIdentifier,
  QuestionnaireBlueprintCopyableOption,
} from '@breathelife/types';
import { AutocompleteOption, NumberInput, SimpleCheckbox, StyledFormControlLabel } from '@breathelife/ui-components';

import { MonospaceTypography } from '../../../../../Components/Typography';
import { QuestionnaireNodeIds } from '../../../../../Helpers/questionnaireEditor/questionnaireNodeIds';
import { ValidationOptionsByFieldType } from '../../../../../Helpers/questionnaireEditor/selectOptions';
import { useCarrierContext } from '../../../../../Hooks/useCarrierContext';
import { useUpdateQuestionnaireElementBlueprint } from '../../../../../ReactQuery/Admin/Questionnaire/questionnaireVersion.mutations';
import { QuestionnaireVersionDataContext } from '../../ContextProvider/QuestionnaireVersionDataContextProvider';
import { AdvancedBlueprintOptions } from '../Components/AdvancedBlueprintOptions';
import { useAdvancedBlueprintOptions } from '../Hooks/useAdvancedBlueprintOptions';
import { useHighlightedTextInBlueprintElement } from '../Hooks/useHighlightedTextInBlueprintElement';
import { HighlightedContent } from '../TreeView/HighlightedContent';
import { ConditionsEditor } from './ConditionsEditor';
import { NodeIdSelector } from '../../../../../Components/NodeIds/NodeIdSelector';
import { Button } from '../../../../../Components/Button/Button';
import { TextInput } from '../../../../../Pages/Admin/Questionnaire/QuestionnaireEditor/Components/TextInput';
import { SubmitButton } from '../../../../../Components/Button/SubmitButton';
import { ModalLayout } from '../../../../../Layouts/Modal/ModalLayout';
import { failure, Result, success } from '@breathelife/result';

type Props = {
  blueprint: QuestionBlueprint;
  partIdentifier: QuestionPartIdentifier;
  sectionGroupCollectionNodeId: string | undefined;
  selectedLanguage: Language;
  parentHidden: boolean;
  dataLabelOptions: AutocompleteOption[];
  validationOptionsByFieldType: ValidationOptionsByFieldType;
  questionnaireNodeIds: QuestionnaireNodeIds;
  isEditingEnabled: boolean;
  disableCopyable: boolean;
};

function asValidRepeatableProperties(object: unknown): Result<unknown, QuestionBlueprint['repeatable']> {
  const localizableSchema = yup.object({ en: yup.string().optional(), fr: yup.string().optional() });
  const repeatablePropertiesSchema = yup.object({
    repeatableAnswerNodeId: yup.string().required(),
    addButtonText: localizableSchema,
    removeButtonText: localizableSchema,
    minRepeatable: yup.number().moreThan(0),
    maxRepeatable: yup.number().moreThan(0),
  });

  try {
    repeatablePropertiesSchema.validateSync(object);
    return success(object as QuestionBlueprint['repeatable']);
  } catch (e) {
    return failure(e);
  }
}

export function QuestionBlueprintEditor(props: Props): ReactElement {
  const {
    partIdentifier,
    blueprint,
    selectedLanguage,
    parentHidden,
    dataLabelOptions,
    questionnaireNodeIds,
    isEditingEnabled,
    sectionGroupCollectionNodeId,
    disableCopyable,
  } = props;

  const { t } = useTranslation();

  const { showBlueprintIdInEditor, languageSettings } = useCarrierContext();
  const enabledLanguages = languageSettings.enabledLanguages;

  const [referenceLabel, setReferenceLabel] = useState<string>('');
  const [title, setTitle] = useState<Partial<Localizable>>({});
  const [text, setText] = useState<Partial<Localizable>>({});
  const [displayAsCard, setDisplayAsCard] = useState(false);

  const [collectionNodeId, setCollectionNodeId] = useState<string>('');

  const [addButtonText, setAddButtonText] = useState<Partial<Localizable>>({});
  const [removeButtonText, setRemoveButtonText] = useState<Partial<Localizable>>({});

  const [minRepeatable, setMinRepeatable] = useState<number>(1);
  const [maxRepeatable, setMaxRepeatable] = useState<number>(3);

  const [isRepeatableModalOpen, setIsRepeatableModalOpen] = useState<boolean>(false);

  const collectionNodeIdDetails = sectionGroupCollectionNodeId
    ? [
        ...(questionnaireNodeIds?.notInQuestionnaire.withRepeatableAncestor[sectionGroupCollectionNodeId] ?? []),
        ...(questionnaireNodeIds?.inQuestionnaire.withRepeatableAncestor[sectionGroupCollectionNodeId] ?? []),
      ]
    : [
        ...(questionnaireNodeIds?.notInQuestionnaire.collection ?? []),
        ...(questionnaireNodeIds?.inQuestionnaire.collection ?? []),
      ];

  const collectionContext = useMemo(() => {
    const collectionNodeIds: string[] = [];
    if (sectionGroupCollectionNodeId) {
      collectionNodeIds.push(sectionGroupCollectionNodeId);
    }
    if (blueprint.repeatable?.repeatableAnswerNodeId) {
      collectionNodeIds.push(blueprint.repeatable?.repeatableAnswerNodeId);
    }

    return collectionNodeIds;
  }, [blueprint.repeatable, sectionGroupCollectionNodeId]);

  const { questionnaireVersionId } = useContext(QuestionnaireVersionDataContext);

  const blueprintUpdate = useUpdateQuestionnaireElementBlueprint(questionnaireVersionId);

  const {
    actions: { setPlatforms, setRenderOn, setDataLabel, setCopyable },
    selectors: { platforms, renderOn, dataLabel, copyable },
  } = useAdvancedBlueprintOptions<QuestionPartIdentifier>({
    partIdentifier,
    blueprintUpdate,
  });

  const [isInitialized, setIsInitialized] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isInitialized) return;

    const referenceLabel = blueprint.referenceLabel;
    if (referenceLabel) {
      setReferenceLabel(referenceLabel);
    }

    const initialTitle = blueprint.title;
    if (initialTitle) {
      setTitle(initialTitle);
    }

    const initialText = blueprint.text;
    if (initialText) {
      setText(initialText);
    }

    const initialDisplayAsCard = blueprint.displayAsCard;
    if (initialDisplayAsCard) {
      setDisplayAsCard(initialDisplayAsCard);
    }

    const initialDataLabel = blueprint.dataLabel;
    if (initialDataLabel) {
      setDataLabel(initialDataLabel, false);
    } else if (dataLabelOptions?.length) {
      setDataLabel(dataLabelOptions[0].value, false);
    }

    setPlatforms(blueprint.platforms ?? [], false);
    setRenderOn(blueprint.renderOn ?? [], false);
    setCopyable(blueprint.copyable ?? QuestionnaireBlueprintCopyableOption.none, false);

    setCollectionNodeId(blueprint.repeatable?.repeatableAnswerNodeId || '');
    setAddButtonText(blueprint.repeatable?.addButtonText || {});
    setRemoveButtonText(blueprint.repeatable?.removeButtonText || {});
    setMaxRepeatable(blueprint.repeatable?.maxRepeatable || 0);
    setMinRepeatable(blueprint.repeatable?.minRepeatable || 0);

    setIsInitialized(true);
  }, [
    blueprint.title,
    blueprint.text,
    blueprint.displayAsCard,
    blueprint.dataLabel,
    blueprint.platforms,
    blueprint.renderOn,
    blueprint.copyable,
    blueprint.referenceLabel,
    dataLabelOptions,
    isInitialized,
    blueprint.repeatable?.repeatableAnswerNodeId,
    blueprint.repeatable?.maxRepeatable,
    blueprint.repeatable?.minRepeatable,
    blueprint.repeatable?.removeButtonText,
    blueprint.repeatable?.addButtonText,
    setPlatforms,
    setRenderOn,
    setDataLabel,
    setCopyable,
    setCollectionNodeId,
    setAddButtonText,
    setRemoveButtonText,
    setMaxRepeatable,
    setMinRepeatable,
  ]);

  const disabled = parentHidden || !!blueprint.hidden || !isEditingEnabled;

  const onTitleChange = useCallback(
    (value: string) => {
      setTitle((previousValue) => ({ ...previousValue, [selectedLanguage]: value }));
    },
    [selectedLanguage],
  );

  const titleValue = useMemo(() => title[selectedLanguage] ?? '', [title, selectedLanguage]);

  const onReferenceLabelBlur = useCallback(() => {
    void blueprintUpdate({
      partIdentifier,
      update: {
        property: 'referenceLabel',
        value: referenceLabel.trim(),
        blueprintId: blueprint.id,
        nodeId: blueprint.repeatable?.repeatableAnswerNodeId,
      },
    });
  }, [blueprintUpdate, partIdentifier, referenceLabel, blueprint.id, blueprint.repeatable?.repeatableAnswerNodeId]);

  const onTitleBlur = useCallback(() => {
    void blueprintUpdate({
      partIdentifier,
      update: { property: 'title', value: title },
    });
  }, [blueprintUpdate, partIdentifier, title]);

  const onTextChange = useCallback(
    (value: string) => {
      setText((previousValue) => ({ ...previousValue, [selectedLanguage]: value }));
    },
    [selectedLanguage],
  );

  const textValue = useMemo(() => text[selectedLanguage] ?? '', [text, selectedLanguage]);

  const highlightedTexts = useHighlightedTextInBlueprintElement({ partIdentifier, blueprint });

  const onTextBlur = useCallback(() => {
    void blueprintUpdate({
      partIdentifier,
      update: { property: 'text', value: text },
    });
  }, [blueprintUpdate, partIdentifier, text]);

  const areRepeatablePropertiesValid = useMemo(() => {
    const update = {
      repeatableAnswerNodeId: collectionNodeId,
      addButtonText: addButtonText,
      removeButtonText: removeButtonText,
      minRepeatable,
      maxRepeatable,
    };
    return asValidRepeatableProperties(update);
  }, [collectionNodeId, addButtonText, removeButtonText, minRepeatable, maxRepeatable]);

  const onSaveRepeatableProperties = useCallback(() => {
    const update = {
      repeatableAnswerNodeId: collectionNodeId,
      addButtonText: addButtonText,
      removeButtonText: removeButtonText,
      minRepeatable,
      maxRepeatable,
    };

    if (asValidRepeatableProperties(update).success) {
      void blueprintUpdate({
        partIdentifier,
        update: { property: 'repeatable', value: update },
      });
      setIsRepeatableModalOpen(false);
    }
  }, [
    blueprintUpdate,
    partIdentifier,
    addButtonText,
    collectionNodeId,
    removeButtonText,
    minRepeatable,
    maxRepeatable,
  ]);

  const onRemoveRepeatableProperties = useCallback(() => {
    setCollectionNodeId('');
    setAddButtonText({});
    setRemoveButtonText({});
    setMaxRepeatable(0);
    setMinRepeatable(0);
    void blueprintUpdate({
      partIdentifier,
      update: { property: 'repeatable', value: undefined },
    });
  }, [blueprintUpdate, partIdentifier]);

  return (
    /* @ts-ignore  ref is missing in Material UI Box typing definitions https://github.com/mui-org/material-ui/issues/17010 */
    <Grid container ref={containerRef} spacing={4}>
      <Grid container item spacing={2}>
        {showBlueprintIdInEditor && (
          <Grid item xs={12}>
            <TextInput label={'Id'} value={blueprint?.id} disabled highlighted={!!highlightedTexts?.blueprintId} />
          </Grid>
        )}
        <Grid item xs={12}>
          <TextInput
            label={t('admin.questionnaireManagement.input.referenceLabel')}
            value={referenceLabel}
            disabled={disabled}
            onChange={(event) => {
              setReferenceLabel(event.target.value);
            }}
            onBlur={onReferenceLabelBlur}
            highlighted={!!highlightedTexts?.referenceLabel}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            highlighted={!!highlightedTexts.title}
            label={t('admin.questionnaireManagement.input.questionTitle')}
            value={titleValue}
            disabled={disabled}
            onChange={(event) => onTitleChange(event.target.value)}
            onBlur={onTitleBlur}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            label={t('admin.questionnaireManagement.input.questionText')}
            highlighted={!!highlightedTexts.text}
            multiline={true}
            value={textValue}
            disabled={disabled}
            onChange={(event) => onTextChange(event.target.value)}
            onBlur={onTextBlur}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <SimpleCheckbox
          id={`displayAsCard-${blueprint.partName}`}
          label={t('admin.questionnaireManagement.input.displayAsCard')}
          checked={displayAsCard}
          disabled={disabled}
          onChange={(event) => {
            setDisplayAsCard(event.target.checked);
            void blueprintUpdate({
              partIdentifier,
              update: { property: 'displayAsCard', value: event.target.checked },
            });
          }}
        />
      </Grid>

      <Grid item xs={collectionNodeId ? 8 : 10}>
        {collectionNodeId ? (
          <StyledFormControlLabel
            label={t('admin.questionnaireManagement.input.repeatableQuestionNodeId')}
            showError={false}
            labelPlacement='top'
            control={
              <MonospaceTypography variant='body1'>
                {highlightedTexts.repeatableAnswerNodeId ? (
                  <HighlightedContent textFragments={highlightedTexts.repeatableAnswerNodeId} />
                ) : (
                  collectionNodeId
                )}
              </MonospaceTypography>
            }
          />
        ) : (
          <div>Not Repeatable</div>
        )}
      </Grid>
      <Grid item xs={2}>
        {!disabled && (
          <IconButton onClick={() => setIsRepeatableModalOpen(true)} size='large'>
            <EditIcon />
          </IconButton>
        )}
      </Grid>

      {collectionNodeId && !disabled && (
        <Grid item xs={2}>
          <Button onClick={onRemoveRepeatableProperties}>{t('admin.questionnaireManagement.remove')}</Button>
        </Grid>
      )}

      <ModalLayout
        maxWidth='md'
        isOpen={isRepeatableModalOpen}
        closeModal={() => setIsRepeatableModalOpen(false)}
        title={t('admin.questionnaireManagement.input.updateQuestion.title')}
        submitButton={
          <SubmitButton disabled={areRepeatablePropertiesValid.success === false} onClick={onSaveRepeatableProperties}>
            {t('cta.save')}
          </SubmitButton>
        }
      >
        <Grid item container spacing={2}>
          <Grid item xs={12} key='createQuestion-collectionNodeId'>
            <NodeIdSelector
              required
              label={t('admin.questionnaireManagement.input.repeatableQuestionNodeId')}
              nodeIds={collectionNodeIdDetails}
              readOnly={false}
              selectedNodeId={collectionNodeId}
              onChange={(nodeIdUpdate) => {
                if (nodeIdUpdate !== null) {
                  setCollectionNodeId(nodeIdUpdate?.value);
                }
              }}
              selectedLanguage={selectedLanguage}
            />
          </Grid>
          <Grid item xs={6} key='createQuestion-minRepeatable'>
            <NumberInput
              label={`${t('admin.questionnaireManagement.input.minRepeatable')} *`}
              value={minRepeatable}
              inputVariant='outlined'
              name='createQuestion-minRepeatable'
              onAnswerChange={(newValue) => {
                const newNumberValue = parseInt(newValue);
                setMinRepeatable(newNumberValue);
              }}
            />
          </Grid>
          <Grid item xs={6} key='createQuestion-maxRepeatable'>
            <NumberInput
              label={`${t('admin.questionnaireManagement.input.maxRepeatable')} *`}
              value={maxRepeatable}
              inputVariant='outlined'
              name='createQuestion-maxRepeatable'
              onAnswerChange={(newValue) => {
                const newNumberValue = parseInt(newValue);
                setMaxRepeatable(newNumberValue);
              }}
            />
          </Grid>
          {enabledLanguages.map((language) => (
            <Fragment key={language}>
              <Grid item xs={6} key={`createQuestion-addButtonText-${language}`}>
                <TextInput
                  label={t('admin.questionnaireManagement.input.addButtonTextAndLanguage', {
                    language: t(`language.${language}`),
                  })}
                  multiline={true}
                  value={addButtonText[language] ?? ''}
                  onChange={(event) => {
                    const value = event.target.value;
                    setAddButtonText((previousValue) => ({ ...previousValue, [language]: value }));
                  }}
                />
              </Grid>
              <Grid item xs={6} key={`createQuestion-removeButtonText-${language}`}>
                <TextInput
                  label={t('admin.questionnaireManagement.input.removeButtonTextAndLanguage', {
                    language: t(`language.${language}`),
                  })}
                  multiline={true}
                  value={removeButtonText[language] ?? ''}
                  onChange={(event) => {
                    const value = event.target.value;
                    setRemoveButtonText((previousValue) => ({ ...previousValue, [language]: value }));
                  }}
                />
              </Grid>
            </Fragment>
          ))}
        </Grid>
      </ModalLayout>

      <Grid item xs={12}>
        <ConditionsEditor
          condition={blueprint.visible}
          collectionContext={collectionContext}
          questionnaireNodeIds={questionnaireNodeIds}
          isReadOnly={parentHidden || !!blueprint.hidden || disabled}
          label={t('admin.questionnaireManagement.rules.visibility.conditions')}
          editHeadingText={t('admin.questionnaireManagement.rules.visibility.edit')}
          saveCondition={(condition) => {
            void blueprintUpdate({
              partIdentifier,
              update: { property: 'visible', value: condition },
            });
          }}
          selectedLanguage={selectedLanguage}
        />
      </Grid>

      <Grid item xs={12}>
        <AdvancedBlueprintOptions
          tag='question'
          platform={{ selected: platforms, onChange: setPlatforms }}
          renderOn={{ selected: renderOn, onChange: setRenderOn }}
          dataLabel={{
            selected: dataLabel,
            options: dataLabelOptions,
            onChange: (value) => {
              if (value !== null) {
                setDataLabel(value);
              }
            },
          }}
          copyable={{
            selected: copyable,
            disabled: disableCopyable,
            onChange: (value) => {
              if (value !== null) {
                setCopyable(value as QuestionnaireBlueprintCopyableOption);
              }
            },
          }}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
}
