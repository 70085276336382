import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient, UseMutationResult, UseMutationOptions } from 'react-query';

import { Answers } from '@breathelife/types';

import { useDispatch } from '../../Hooks';
import { Application } from '../../Models/Application';
import { ImportableLead, Lead } from '../../Models/Lead';
import { QueryId } from '../../ReactQuery/common/common.types';
import { notificationSlice } from '../../ReduxStore/Notification/NotificationSlice';
import {
  createLeadWithAnswers,
  createOrUpdateApplication,
  updateApplication,
  UpdateApplicationResponse,
} from '../../Services/DebugToolbarService';

type UpdateApplicationAnswersUntilParams = {
  applicationId: string;
  subsectionId: string;
};

export function useUpdateApplicationAnswersUntilMutation(
  options?: UseMutationOptions<UpdateApplicationResponse, unknown, UpdateApplicationAnswersUntilParams>,
): UseMutationResult<UpdateApplicationResponse, unknown, UpdateApplicationAnswersUntilParams> {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation<UpdateApplicationResponse, unknown, UpdateApplicationAnswersUntilParams>(
    ({ applicationId, subsectionId }) => updateApplication(applicationId, { subsectionId }),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries([QueryId.application, data.application.id]);
        await queryClient.invalidateQueries([QueryId.lead, data.application.leadId]);

        dispatch(
          notificationSlice.actions.setSuccess({
            message: `Application filled up to '${variables.subsectionId}' step.`,
          }),
        );

        if (options?.onSuccess) {
          await options.onSuccess(data, variables, context);
        }
      },
      onError: async (error, variables, context) => {
        dispatch(
          notificationSlice.actions.setError({
            message: t('notifications.debugToolbar.failedAutoFillApplicationAnswers', {
              subsectionId: variables.subsectionId,
            }),
          }),
        );

        if (options?.onError) {
          await options.onError(error, variables, context);
        }
      },
    },
  );
}

type CreateOrUpdateApplicationParams = {
  leadId: number;
  applicationId: string | undefined;
  answers: Answers;
};

export function useCreateOrUpdateApplicationMutation(
  options?: UseMutationOptions<Application, unknown, CreateOrUpdateApplicationParams>,
): UseMutationResult<Application, unknown, CreateOrUpdateApplicationParams> {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation<Application, unknown, CreateOrUpdateApplicationParams>(
    ({ leadId, applicationId, answers }) =>
      createOrUpdateApplication(leadId, applicationId, {
        answers,
      }),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries([QueryId.application, data.id]);
        await queryClient.invalidateQueries([QueryId.lead, variables.leadId]);

        dispatch(
          notificationSlice.actions.setSuccess({
            message: 'Answers import successful',
          }),
        );

        if (options?.onSuccess) {
          await options.onSuccess(data, variables, context);
        }
      },
      onError: async (error, variables, context) => {
        dispatch(
          notificationSlice.actions.setError({
            message: 'Error while importing answers',
          }),
        );

        if (options?.onError) {
          await options.onError(error, variables, context);
        }
      },
    },
  );
}

export function useCreateLeadMutation(
  options?: UseMutationOptions<Lead, unknown, ImportableLead>,
): UseMutationResult<Lead, unknown, ImportableLead> {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation<Lead, unknown, ImportableLead>(createLeadWithAnswers, {
    ...options,
    onSuccess: async (data, variables, context) => {
      void queryClient.invalidateQueries([QueryId.leads]);
      void queryClient.invalidateQueries([QueryId.usedLeadStatuses]);

      dispatch(
        notificationSlice.actions.setSuccess({
          message: 'Lead import successful',
        }),
      );

      if (options?.onSuccess) {
        await options.onSuccess(data, variables, context);
      }
    },
    onError: async (error, variables, context) => {
      dispatch(
        notificationSlice.actions.setError({
          message: 'Error while importing lead',
        }),
      );

      if (options?.onError) {
        await options.onError(error, variables, context);
      }
    },
  });
}
