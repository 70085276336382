import ReactHtmlParser from 'html-react-parser';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Collapse, IconButton, Tooltip } from '@breathelife/mui';
import { Notification, NotificationStatus } from '@breathelife/types';
import { ImageTypes, Loader } from '@breathelife/ui-components';

import { Icon } from '../../../../Components/Icons';
import Typography from '../../../../Components/Typography';
import { useUpdateNotificationMutation } from '../../../../ReactQuery/NotificationCentre/notifications.mutations';
import { getNotificationData } from '../../../../Helpers/notifications';
import { formatDate, guessTimezone } from '../../../../Helpers/datetime';
import {
  ActionsContainer,
  ActionsContent,
  CollapseContentContainer,
  CollapseTextContent,
  DotIcon,
  ExpandButton,
  EyeIcon,
  InformationContainer,
  InformationContent,
  MainTextContent,
  NotificationItemContainer,
  StyledLink,
  TextContainer,
  TextContent,
} from './Styles';

export function NotificationItem(props: {
  notification: Notification;
  updateNotificationList: (notification: Notification) => void;
}): ReactElement {
  const { updateNotificationList } = props;

  const { t } = useTranslation();

  const [isTextExpanded, setIsTextExpanded] = useState<boolean>(false);
  const [isRead, setIsRead] = useState<boolean>();
  const [notification, setNotification] = useState<Notification>();

  useEffect(() => {
    if (props.notification) {
      setNotification(props.notification);
    }
  }, [props.notification]);

  useEffect(() => {
    if (!notification) return;
    if (notification.status === NotificationStatus.Read) {
      setIsRead(true);
    } else {
      setIsRead(false);
    }
  }, [notification]);

  const updateNotificationMutation = useUpdateNotificationMutation({
    onSuccess: (output: Notification) => {
      setNotification(output);
      setIsRead(output.status === NotificationStatus.Read);
      updateNotificationList(output);
    },
  });

  const updateNotification = useCallback(
    (data: Partial<Notification>) => {
      if (!notification) return;
      updateNotificationMutation.mutate({
        id: notification.id,
        data,
      });
    },
    [notification, updateNotificationMutation],
  );

  const markAsRead = useCallback(() => {
    if (!notification) return;
    if (notification.status === NotificationStatus.Read) return;
    updateNotification({ status: NotificationStatus.Read });
  }, [updateNotification, notification]);

  const markAsUnread = useCallback(() => {
    if (!notification) return;
    if (notification.status === NotificationStatus.Unread) return;
    updateNotification({ status: NotificationStatus.Unread });
  }, [updateNotification, notification]);

  if (!notification) return <Loader />;

  const { icon, callToActionUrl, metadata } = getNotificationData(notification);
  if (metadata?.roles) {
    metadata.roles = metadata?.roles.map((role: string) => t(`participantRole.${role}`)).join(', ');
  }

  const isHiddenContentEmpty = t(`notificationCentre.event.${notification.event}.hiddenContent`) === '';
  const iconWidthHeight = '16px';

  return (
    <NotificationItemContainer>
      <StyledLink rel='noopener noreferrer' href={callToActionUrl} onClick={markAsRead}>
        <InformationContainer>
          <InformationContent>
            {!isRead && (
              <DotIcon
                name='notificationUnreadDotIcon'
                stroke='none'
                imageType={ImageTypes.decorative}
                width={iconWidthHeight}
                height={iconWidthHeight}
              />
            )}

            <Icon name={icon} stroke='none' imageType={ImageTypes.decorative} />
          </InformationContent>
        </InformationContainer>
        <TextContainer>
          <TextContent>
            <div>
              <Typography variant='body1'>{t(`notificationCentre.event.${notification.event}.title`)} </Typography>
              <Typography variant='body3'>
                - <i>{formatDate(notification.createdAt, 'YYYY/MM/DD HH:mm A', guessTimezone())}</i>
              </Typography>
            </div>
            <CollapseContentContainer>
              <CollapseTextContent>
                <Collapse collapsedSize='60px' in={isTextExpanded} timeout='auto' sx={{ paddingBottom: '15px' }}>
                  <Typography variant='body3' grey={80}>
                    <MainTextContent>
                      {ReactHtmlParser(t(`notificationCentre.event.${notification.event}.mainContent`, metadata))}
                    </MainTextContent>
                    {ReactHtmlParser(t(`notificationCentre.event.${notification.event}.hiddenContent`, metadata))}
                  </Typography>
                </Collapse>
              </CollapseTextContent>
              <ExpandButton
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setIsTextExpanded(!isTextExpanded);
                }}
                disabled={isHiddenContentEmpty}
              >
                {isTextExpanded ? (
                  <Icon
                    name='notificationArrowUpIcon'
                    variant='grey'
                    stroke='none'
                    imageType={ImageTypes.decorative}
                    width={!isHiddenContentEmpty ? iconWidthHeight : '0'}
                    height={iconWidthHeight}
                  />
                ) : (
                  <Icon
                    name='notificationArrowDownIcon'
                    variant='grey'
                    stroke='none'
                    imageType={ImageTypes.decorative}
                    width={!isHiddenContentEmpty ? iconWidthHeight : '0'}
                    height={iconWidthHeight}
                  />
                )}
              </ExpandButton>
            </CollapseContentContainer>
          </TextContent>
        </TextContainer>
        <ActionsContainer>
          <ActionsContent>
            {isRead && (
              <Tooltip title={t(`notificationCentre.markAsUnread`)}>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    markAsUnread();
                  }}
                >
                  <EyeIcon
                    name='notificationMarkAsUnreadEyeIcon'
                    stroke='none'
                    imageType={ImageTypes.decorative}
                    width={iconWidthHeight}
                    height={iconWidthHeight}
                  />
                </IconButton>
              </Tooltip>
            )}
          </ActionsContent>
        </ActionsContainer>
      </StyledLink>
    </NotificationItemContainer>
  );
}
