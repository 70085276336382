import { Fragment, ReactElement } from 'react';

import { Box, BoxProps, FormControl, IconButton, InputAdornment } from '@breathelife/mui';

import { copyValueToClipboard } from '../../Helpers/clipboard';
import { Icon } from '../Icons';
import Typography from '../Typography';
import { StyledButton, StyledInput, StyledLabel } from './Styles';

type ComponentOptions = {
  buttonPosition?: 'inside' | 'outside';
  buttonLabel?: string;
};

type ContainerWithCopyButtonProps = {
  inputLabel: string;
  inputValue: string;
  onClickCallback: (value: string) => void;
  options?: ComponentOptions;
} & BoxProps;

export function ContainerWithCopyButton(props: ContainerWithCopyButtonProps): ReactElement {
  const defaultOptions: ComponentOptions = {
    buttonPosition: 'outside',
  };
  const { inputLabel, inputValue, options, onClickCallback, ...boxProps } = props;
  const { buttonPosition, buttonLabel } = Object.assign({}, defaultOptions, options);
  const handleClick = (): void => {
    copyValueToClipboard(inputValue, () => onClickCallback(inputLabel));
  };

  return (
    <Box display='flex' justifyContent='space-between' alignItems='flex-end' {...boxProps}>
      {buttonPosition === 'inside' && (
        <FormControl fullWidth>
          <StyledLabel color='primary'>
            <Typography variant='body5'>{inputLabel}</Typography>
          </StyledLabel>
          <StyledInput
            type='text'
            value={inputValue}
            disabled
            endAdornment={
              <InputAdornment position='end'>
                <IconButton aria-label={buttonLabel} onClick={handleClick} size='small'>
                  <Icon name={'copy'}></Icon>
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      )}

      {buttonPosition === 'outside' && (
        <Fragment>
          <Box flexGrow='1'>
            <FormControl fullWidth>
              <StyledLabel color='primary'>
                <Typography variant='body5'>{inputLabel}</Typography>
              </StyledLabel>
              <StyledInput type='text' value={inputValue} disabled />
            </FormControl>
          </Box>

          <Box flexGrow='0' ml='0.4rem'>
            <StyledButton
              aria-label={buttonLabel}
              color='primary'
              variant='outlined'
              onClick={handleClick}
              data-testid={'copy-to-clipboard-button'}
            >
              <Icon name={'copy'}></Icon> {buttonLabel}
            </StyledButton>
          </Box>
        </Fragment>
      )}
    </Box>
  );
}
