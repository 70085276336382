import { useTranslation } from 'react-i18next';
import { UseMutationOptions, UseMutationResult, useQueryClient, useMutation } from 'react-query';

import { CreateQuestionnaireData, Questionnaire } from '@breathelife/types';
import { logger } from '@breathelife/monitoring-frontend';

import { QueryId } from '../../common/common.types';
import { notificationSlice } from '../../../ReduxStore/Notification/NotificationSlice';
import { createQuestionnaire } from '../../../Services/QuestionnaireService';

import { AxiosError } from 'axios';
import { useDispatch } from 'react-redux';
import ApiService from '../../../Services/ApiService';
import i18next from 'i18next';

export function useCreateQuestionnaireMutation(
  options?: UseMutationOptions<Questionnaire, unknown, CreateQuestionnaireData>,
): UseMutationResult<Questionnaire, unknown, CreateQuestionnaireData> {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<Questionnaire, unknown, CreateQuestionnaireData, unknown>(createQuestionnaire, {
    ...options,
    onSuccess: async (data, variables, context) => {
      void queryClient.setQueryData([QueryId.questionnaire], data);

      if (options?.onSuccess) {
        await options.onSuccess(data, variables, context);
      }
    },
    onError: async (err, variables, context) => {
      notificationSlice.actions.setError({
        message: t('notifications.failedToCreateNewQuestionnaire'),
      });
      logger.error(err);

      if (options?.onError) {
        await options.onError(err, variables, context);
      }
    },
  });
}

type UseDeleteQuestionnaireMutation = (
  options?: UseMutationOptions<null, AxiosError<any>, string>,
) => UseMutationResult<null, AxiosError<any>, string>;

export const useDeleteQuestionnaireMutation: UseDeleteQuestionnaireMutation = (options) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: async (questionnaireId: string): Promise<null> => {
      await ApiService.admin.deleteQuestionnaire(questionnaireId);
      return null;
    },

    onSuccess: async (data, variables, context) => {
      const message = i18next.t('admin.questionnaireManagement.deleteQuestionnaire.success');
      dispatch(notificationSlice.actions.setSuccess({ message }));

      await queryClient.invalidateQueries(QueryId.allQuestionnaires);
      if (options?.onSuccess) {
        await options.onSuccess(data, variables, context);
      }
    },

    onError: async (err, variables, context) => {
      const message = i18next.t('admin.questionnaireManagement.deleteQuestionnaire.error');
      dispatch(notificationSlice.actions.setError({ message }));
      if (options?.onError) {
        await options.onError(err, variables, context);
      }
    },
  });
};
