import { ConditionBlueprintType, CriterionMetadata } from '@breathelife/types';

export type CriteriaMetadata = { [key in ConditionBlueprintType]: CriterionMetadata };

export const criteriaMetadata: CriteriaMetadata = {
  [ConditionBlueprintType.ageRange]: {
    isDateCriteria: true,
  },
  [ConditionBlueprintType.bmiRange]: {
    isNumberCriteria: true,
    isSelectOptionCriteria: true,
  },
  [ConditionBlueprintType.characterCountInBetween]: {
    isTextCriteria: true,
  },
  [ConditionBlueprintType.countEqual]: {},
  [ConditionBlueprintType.dateComparison]: {
    isDateCriteria: true,
  },
  [ConditionBlueprintType.emptiness]: {},
  [ConditionBlueprintType.engineCondition]: {},
  [ConditionBlueprintType.equality]: {},
  [ConditionBlueprintType.futureDate]: {
    isDateCriteria: true,
  },
  [ConditionBlueprintType.instancesCount]: {},
  [ConditionBlueprintType.jointProductAgeRange]: {},
  [ConditionBlueprintType.lastIncidentDate]: {
    isDateCriteria: true,
  },
  [ConditionBlueprintType.matches]: {
    isSelectOptionCriteria: true,
  },
  [ConditionBlueprintType.matchesRegex]: {
    isTextCriteria: true,
  },
  [ConditionBlueprintType.mathOperator]: {
    isNumberCriteria: true,
    isSelectOptionCriteria: true,
  },
  [ConditionBlueprintType.numberComparison]: {
    isNumberCriteria: true,
    isSelectOptionCriteria: true,
  },
  [ConditionBlueprintType.percentOf]: {
    isNumberCriteria: true,
  },
  [ConditionBlueprintType.reflexive]: {
    isReflexiveCriteria: true,
  },
};
