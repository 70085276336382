import { FieldBlueprint, DateFieldBlueprint, FieldTypes, DateFieldValidation } from '@breathelife/types';
import { AutocompleteOption } from '@breathelife/ui-components';

import { FieldDataHookReturnValueWithValidationOptions, FieldSettersValidateAs } from '../../Helpers/helperTypes';
import { BaseFieldSetters, useBaseFieldData } from './useBaseFieldData';
import { useValidateAsData } from './useValidateAsData';

export type DateFieldSetters = FieldSettersValidateAs<DateFieldBlueprint> & BaseFieldSetters;

export const useDateFieldData = ({
  initialData,
  dataLabelOptions,
}: {
  initialData?: Partial<FieldBlueprint>;
  dataLabelOptions: AutocompleteOption[];
}): FieldDataHookReturnValueWithValidationOptions<DateFieldBlueprint, DateFieldSetters> => {
  const fieldType = FieldTypes.date;
  const {
    data: validateAsData,
    setters: validateAsSetters,
    validationOptions,
  } = useValidateAsData<DateFieldBlueprint>({
    fieldType,
    initialValidateAs: DateFieldValidation.date,
  });
  const { data, setters } = useBaseFieldData({ initialData, dataLabelOptions });
  return {
    data: { fieldType: fieldType, ...data, ...validateAsData },
    setters: { ...setters, ...validateAsSetters },
    validationOptions,
  };
};
