import { ReactElement, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '../../../../../Styles/themed-styled-components';

import { Divider, Grid, GridProps } from '@breathelife/mui';
import {
  isFieldBlueprint,
  isFieldPartIdentifier,
  isQuestionBlueprint,
  isQuestionPartIdentifier,
  isSectionBlueprint,
  isSectionPartIdentifier,
  isSubsectionBlueprint,
  isSubsectionPartIdentifier,
  Language,
  QuestionnaireBlueprintCopyableOption,
} from '@breathelife/types';
import { AutocompleteOption, Box } from '@breathelife/ui-components';

import {
  getFieldTypeOptions,
  getValidationOptionsByFieldType,
} from '../../../../../Helpers/questionnaireEditor/selectOptions';
import { SummaryText } from '../../../../../Pages/Admin/Questionnaire/QuestionnaireEditor/Components/SummaryText';
import { SetSelectedPartIdentifier } from '../../../../../Pages/Admin/Questionnaire/QuestionnaireEditor/types';
import { QuestionnaireEditorContext } from '../../ContextProvider/QuestionnaireEditorContextProvider';
import { QuestionnaireVersionDataContext } from '../../ContextProvider/QuestionnaireVersionDataContextProvider';
import { findElementBlueprintInSiblingsFromPartName } from '../Helpers/findBlueprintFromPartIdentifier';
import { FieldBlueprintEditor } from './FieldBlueprintEditor';
import { QuestionBlueprintEditor } from './QuestionBlueprintEditor';
import { SectionBlueprintEditor } from './SectionBlueprintEditor';
import { SubsectionBlueprintEditor } from './SubsectionBlueprintEditor';

const CONTENT_MAX_WIDTH = '472px';
const MISSING_INFORMATION_EDITOR_VALUES = { editor: null, editTitle: '' };

const DetailedEditorContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const EditorScrollContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-grow: 1;
  overflow: auto;
`;

const EditorMaxWidthContainer = styled.div`
  width: 100%;
  max-width: ${CONTENT_MAX_WIDTH};
  padding: 0 16px;
`;

const EditorPaddingContainer = styled.div`
  padding: 32px 0;
`;

const DetailedEditorHeaderContainer = styled(Grid)<GridProps>`
  background-color: ${(props) => props.theme.colors.grey[0]};
`;

type Props = {
  dataLabelOptions: AutocompleteOption[];
  setSelectedPartIdentifier: SetSelectedPartIdentifier;
  selectedLanguage: Language;
  isEditingEnabled: boolean;
};

function DetailedEditorComponent(props: Props): ReactElement | null {
  const { t } = useTranslation();

  const { questionnaireVersionData } = useContext(QuestionnaireVersionDataContext);
  const { blueprint: questionnaireBlueprint, questionnaireNodeIds } = questionnaireVersionData || {};
  const { selectedPartIdentifier } = useContext(QuestionnaireEditorContext);

  const sectionBlueprint =
    questionnaireBlueprint?.sectionBlueprints.find(
      (blueprint) => blueprint.partName === selectedPartIdentifier?.sectionPartName,
    ) ?? questionnaireBlueprint?.sectionBlueprints[0];

  const sectionGroupBlueprint = sectionBlueprint
    ? questionnaireBlueprint?.sectionGroupBlueprints?.[sectionBlueprint?.sectionGroupKey]
    : undefined;

  const fieldTypeOptions = useMemo(() => getFieldTypeOptions(t), [t]);
  const validationOptionsByFieldType = useMemo(() => getValidationOptionsByFieldType(t), [t]);

  const { setSelectedPartIdentifier, selectedLanguage, isEditingEnabled, dataLabelOptions } = props;

  const { editor, editTitle } = (() => {
    if (
      !questionnaireNodeIds ||
      !selectedPartIdentifier ||
      !sectionBlueprint ||
      !isSectionBlueprint(sectionBlueprint)
    ) {
      return MISSING_INFORMATION_EDITOR_VALUES;
    }

    const sectionIsCopyable =
      !!sectionBlueprint.copyable && sectionBlueprint.copyable !== QuestionnaireBlueprintCopyableOption.none;

    const isSectionHidden = !!sectionBlueprint.hidden;
    if (isSectionPartIdentifier(selectedPartIdentifier)) {
      return {
        editor: (
          <SectionBlueprintEditor
            key={selectedPartIdentifier.sectionPartName}
            dataLabelOptions={dataLabelOptions}
            isEditingEnabled={isEditingEnabled}
            partIdentifier={selectedPartIdentifier}
            sectionBlueprint={sectionBlueprint}
            sectionGroupCollectionNodeId={sectionGroupBlueprint?.repeatable?.repeatableAnswerNodeId}
            selectedLanguage={selectedLanguage}
            questionnaireNodeIds={questionnaireNodeIds}
          />
        ),
        editTitle: t('admin.questionnaireManagement.editSection'),
      };
    }

    const subsectionBlueprint = findElementBlueprintInSiblingsFromPartName(
      sectionBlueprint.subsections,
      selectedPartIdentifier.subsectionPartName,
    );

    if (!subsectionBlueprint || !isSubsectionBlueprint(subsectionBlueprint)) {
      return MISSING_INFORMATION_EDITOR_VALUES;
    }

    const subsectionIsCopyable =
      !!subsectionBlueprint.copyable && subsectionBlueprint.copyable !== QuestionnaireBlueprintCopyableOption.none;

    if (isSubsectionPartIdentifier(selectedPartIdentifier)) {
      return {
        editor: (
          <SubsectionBlueprintEditor
            key={selectedPartIdentifier.subsectionPartName}
            partIdentifier={selectedPartIdentifier}
            sectionGroupCollectionNodeId={sectionGroupBlueprint?.repeatable?.repeatableAnswerNodeId}
            setSelectedPartIdentifier={setSelectedPartIdentifier}
            blueprint={subsectionBlueprint}
            questionnaireNodeIds={questionnaireNodeIds}
            dataLabelOptions={dataLabelOptions}
            selectedLanguage={selectedLanguage}
            parentHidden={isSectionHidden}
            isEditingEnabled={isEditingEnabled}
            disableCopyable={sectionIsCopyable}
          />
        ),
        editTitle: t('admin.questionnaireManagement.editSubsection'),
      };
    }

    const isSubsectionHidden = !!subsectionBlueprint.hidden;

    const questionBlueprint = findElementBlueprintInSiblingsFromPartName(
      subsectionBlueprint.questions,
      selectedPartIdentifier.questionPartName,
    );

    if (!questionBlueprint || !isQuestionBlueprint(questionBlueprint)) {
      return MISSING_INFORMATION_EDITOR_VALUES;
    }

    const questionIsCopyable =
      !!questionBlueprint.copyable && questionBlueprint.copyable !== QuestionnaireBlueprintCopyableOption.none;

    if (isQuestionPartIdentifier(selectedPartIdentifier)) {
      return {
        editor: (
          <QuestionBlueprintEditor
            key={selectedPartIdentifier.questionPartName}
            partIdentifier={selectedPartIdentifier}
            blueprint={questionBlueprint}
            sectionGroupCollectionNodeId={sectionGroupBlueprint?.repeatable?.repeatableAnswerNodeId}
            selectedLanguage={selectedLanguage}
            parentHidden={isSectionHidden || isSubsectionHidden}
            questionnaireNodeIds={questionnaireNodeIds}
            isEditingEnabled={isEditingEnabled}
            validationOptionsByFieldType={validationOptionsByFieldType}
            dataLabelOptions={dataLabelOptions}
            disableCopyable={sectionIsCopyable || subsectionIsCopyable}
          />
        ),
        editTitle: t('admin.questionnaireManagement.editQuestion'),
      };
    }

    const isQuestionHidden = !!questionBlueprint.hidden;

    const fieldBlueprint = findElementBlueprintInSiblingsFromPartName(
      questionBlueprint.fields,
      selectedPartIdentifier.fieldPartName,
    );

    if (!fieldBlueprint || !isFieldBlueprint(fieldBlueprint)) {
      return MISSING_INFORMATION_EDITOR_VALUES;
    }

    if (isFieldPartIdentifier(selectedPartIdentifier)) {
      return {
        editor: (
          <FieldBlueprintEditor
            key={fieldBlueprint.partName}
            parentHidden={isSectionHidden || isSubsectionHidden || isQuestionHidden}
            blueprint={fieldBlueprint}
            partIdentifier={selectedPartIdentifier}
            sectionGroupCollectionNodeId={sectionGroupBlueprint?.repeatable?.repeatableAnswerNodeId}
            questionCollectionNodeId={questionBlueprint.repeatable?.repeatableAnswerNodeId}
            selectedLanguage={selectedLanguage}
            questionnaireNodeIds={questionnaireNodeIds}
            validationOptionsByFieldType={validationOptionsByFieldType}
            fieldTypeOptions={fieldTypeOptions}
            dataLabelOptions={dataLabelOptions}
            displayAsCard={questionBlueprint.displayAsCard}
            isEditingEnabled={isEditingEnabled}
            disableCopyable={sectionIsCopyable || subsectionIsCopyable || questionIsCopyable}
            questionBlueprint={questionBlueprint}
          />
        ),
        editTitle: t('admin.questionnaireManagement.editField'),
      };
    }
    return MISSING_INFORMATION_EDITOR_VALUES;
  })();

  if (editor === null) {
    return null;
  }

  return (
    <DetailedEditorContainer>
      <DetailedEditorHeader editTitle={editTitle} />
      <Divider />
      <EditorScrollContainer>
        <EditorMaxWidthContainer>
          <EditorPaddingContainer>{editor}</EditorPaddingContainer>
        </EditorMaxWidthContainer>
      </EditorScrollContainer>
    </DetailedEditorContainer>
  );
}

function DetailedEditorHeader(props: { editTitle: string }): ReactElement {
  const { editTitle } = props;

  return (
    <DetailedEditorHeaderContainer container alignItems='center' justifyContent='space-between'>
      <Grid item xs={8}>
        <Box pt={2} pb={2} pl={2} pr={3}>
          <SummaryText text={editTitle} />
        </Box>
      </Grid>
      <Grid item container xs={4} justifyContent='flex-end'>
        <Box pr={1}></Box>
      </Grid>
    </DetailedEditorHeaderContainer>
  );
}

export const DetailedEditor = DetailedEditorComponent;
