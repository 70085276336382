import i18next from 'i18next';

import {
  IconName,
  InsuredUnderwritingOutcomeReport,
  OutcomeCode,
  ProviderUnderwritingRequests,
  UnderwritingDataProvider,
  UnderwritingDataReportCategory,
  UnderwritingReport,
} from '@breathelife/types';

export type SelectedReportData = UnderwritingReport & {
  provider: UnderwritingDataProvider;
  category: UnderwritingDataReportCategory;
  outcome: OutcomeCode;
};

export type UnderwritingTreeViewDataItem = {
  label: string;
  identifier: string;
  iconName?: IconName;
  children?: UnderwritingTreeViewDataItem[];
  metadata?: SelectedReportData;
};

const mapRequestsToTreeData = (
  provider: UnderwritingDataProvider,
  reports: UnderwritingReport[],
  category: UnderwritingDataReportCategory,
  outcome: OutcomeCode | null,
): UnderwritingTreeViewDataItem[] => {
  return reports.map((report, index) => {
    return {
      label: `${provider} ${i18next.t('applicationSupport.underwritingReports.report')} ${index + 1}`,
      identifier: `${provider}${i18next.t('applicationSupport.underwritingReports.report')}${index + 1}`,
      metadata: {
        request: report.request === '' ? i18next.t('applicationSupport.underwritingReports.protected') : report.request,
        response:
          report.response === '' ? i18next.t('applicationSupport.underwritingReports.protected') : report.response,
        provider,
        category,
        createdAt: report.createdAt,
        updatedAt: report.updatedAt,
        outcome: index === 0 ? outcome || OutcomeCode.unknown : OutcomeCode.unknown,
      },
    };
  });
};

const mapUnderwritingRequestsToTreeData = (
  providers: ProviderUnderwritingRequests[],
  category: UnderwritingDataReportCategory,
): UnderwritingTreeViewDataItem[] => {
  const reportsTreeViewData = providers.map((provider) => {
    return {
      label: provider.provider,
      identifier: provider.provider,
      iconName: IconName.briefcase,
      children: mapRequestsToTreeData(provider.provider, provider.reports, category, provider.outcome),
    };
  });
  return reportsTreeViewData;
};

export const mapInsuredToTreeViewData = (insured: InsuredUnderwritingOutcomeReport): UnderwritingTreeViewDataItem[] => {
  const insuredTreeViewData = insured.reports?.map((report) => {
    return {
      label: report.type,
      iconName: IconName.list,
      identifier: report.type,
      children: mapUnderwritingRequestsToTreeData(report.underwriting, report.type),
    };
  });
  return insuredTreeViewData;
};
