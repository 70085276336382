import dayjs from 'dayjs';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, ComputerIcon, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@breathelife/mui';

import { Checkbox } from '../../../../../Components/Checkbox/Checkbox';
import Typography from '../../../../../Components/Typography';
import { useCarrierContext } from '../../../../../Hooks';
import { Lead, LeadsHistory } from '../../../../../Models/Lead';
import { ContentHeading } from '../Styles';

type HistoryViewProps = {
  lead?: Lead;
};

type LeadEvent = { typeLabel: string; label: string; internal?: boolean };

const DATE_FORMAT = 'MMM. D, YYYY hh:mma';

function getUserIdentifier(history: LeadsHistory): string {
  if (history.user?.firstName && history.user?.lastName) {
    return `${history.user.firstName} ${history.user.lastName}`;
  } else if (history.user?.emailLogin) {
    return history.user.emailLogin;
  } else if (history.userId) {
    return history.userId;
  } else {
    return '-';
  }
}

export function HistoryView({ lead }: HistoryViewProps): ReactElement {
  const { t } = useTranslation();
  const { leadStatuses } = useCarrierContext();
  const [showInternalStatuses, setShowInternalStatuses] = useState(false);

  const statusMapping: Record<string, LeadEvent> = useMemo(() => {
    return {
      'statusUpdated/Unready': {
        typeLabel: t('leadDetailDrawer.history.statusTypes.statusUpdate'),
        label: t('leadDetailDrawer.history.statuses.statusUpdated/Unready'),
      },
      'statusUpdated/New': {
        typeLabel: t('leadDetailDrawer.history.statusTypes.statusUpdate'),
        label: t(
          leadStatuses.new?.label
            ? `leadStatuses.${leadStatuses.new.label}`
            : 'leadDetailDrawer.history.statuses.statusUpdated/New',
        ),
        internal: !leadStatuses.new?.label, // If there's no custom label, it means it was a transition status triggered by the system, so the agent doesn't have to know about it
      },
      'statusUpdated/Invited': {
        typeLabel: t('leadDetailDrawer.history.statusTypes.statusUpdate'),
        label: t(
          leadStatuses.invited?.label
            ? `leadStatuses.${leadStatuses.invited.label}`
            : 'leadDetailDrawer.history.statuses.statusUpdated/Invited',
        ),
        internal: !leadStatuses.invited?.label, // If there's no custom label, it means it was a transition status triggered by the system, so the agent doesn't have to know about it
      },
      'statusUpdated/PlanFinderStarted': {
        typeLabel: t('leadDetailDrawer.history.statusTypes.statusUpdate'),
        label: t(
          leadStatuses.planFinderStarted?.label
            ? `leadStatuses.${leadStatuses.planFinderStarted.label}`
            : 'leadDetailDrawer.history.statuses.statusUpdated/PlanFinderStarted',
        ),
        internal: !leadStatuses.planFinderStarted?.label, // If there's no custom label, it means it was a transition status triggered by the system, so the agent doesn't have to know about it
      },
      'statusUpdated/PlanFinderCompleted': {
        typeLabel: t('leadDetailDrawer.history.statusTypes.statusUpdate'),
        label: t(
          leadStatuses.planFinderCompleted?.label
            ? `leadStatuses.${leadStatuses.planFinderCompleted.label}`
            : 'leadDetailDrawer.history.statuses.statusUpdated/PlanFinderCompleted',
        ),
        internal: !leadStatuses.planFinderCompleted?.label, // If there's no custom label, it means it was a transition status triggered by the system, so the agent doesn't have to know about it
      },
      'statusUpdated/Engaged': {
        typeLabel: t('leadDetailDrawer.history.statusTypes.statusUpdate'),
        label: t(
          leadStatuses.engaged?.label
            ? `leadStatuses.${leadStatuses.engaged.label}`
            : 'leadDetailDrawer.history.statuses.statusUpdated/Engaged',
        ),
        internal: !leadStatuses.engaged?.label, // If there's no custom label, it means it was a transition status triggered by the system, so the agent doesn't have to know about it
      },
      'statusUpdated/TransactionFlowStarted': {
        typeLabel: t('leadDetailDrawer.history.statusTypes.statusUpdate'),
        label: t(
          leadStatuses.transactionFlowStarted?.label
            ? `leadStatuses.${leadStatuses.transactionFlowStarted.label}`
            : 'leadDetailDrawer.history.statuses.statusUpdated/TransactionFlowStarted',
        ),
        internal: !leadStatuses.transactionFlowStarted?.label, // If there's no custom label, it means it was a transition status triggered by the system, so the agent doesn't have to know about it
      },
      'statusUpdated/TransactionFlowCompleted': {
        typeLabel: t('leadDetailDrawer.history.statusTypes.statusUpdate'),
        label: t(
          leadStatuses.transactionFlowCompleted?.label
            ? `leadStatuses.${leadStatuses.transactionFlowCompleted.label}`
            : 'leadDetailDrawer.history.statuses.statusUpdated/TransactionFlowCompleted',
        ),
        internal: !leadStatuses.transactionFlowCompleted?.label, // If there's no custom label, it means it was a transition status triggered by the system, so the agent doesn't have to know about it
      },
      'statusUpdated/Qualified': {
        typeLabel: t('leadDetailDrawer.history.statusTypes.statusUpdate'),
        label: t(
          leadStatuses.qualified?.label
            ? `leadStatuses.${leadStatuses.qualified.label}`
            : 'leadDetailDrawer.history.statuses.statusUpdated/Qualified',
        ),
        internal: !leadStatuses.qualified?.label, // If there's no custom label, it means it was a transition status triggered by the system, so the agent doesn't have to know about it
      },
      archived: {
        typeLabel: t('leadDetailDrawer.history.statusTypes.archive'),
        label: t('leadDetailDrawer.history.statuses.archived'),
      },
      unarchived: {
        typeLabel: t('leadDetailDrawer.history.statusTypes.archive'),
        label: t('leadDetailDrawer.history.statuses.unarchived'),
      },
      deleted: {
        typeLabel: t('leadDetailDrawer.history.statusTypes.archive'),
        label: t('leadDetailDrawer.history.statuses.deleted'),
      },
      emailSent: {
        typeLabel: t('leadDetailDrawer.history.statusTypes.communication'),
        label: t('leadDetailDrawer.history.statuses.emailSent'),
      },
      'assist/ApplicationAccessed': {
        typeLabel: t('leadDetailDrawer.history.statusTypes.assisted'),
        label: t('leadDetailDrawer.history.statuses.assist/ApplicationAccessed'),
      },
      assigned: {
        typeLabel: t('leadDetailDrawer.history.statusTypes.dispatch'),
        label: t('leadDetailDrawer.history.statuses.assigned'),
      },
      unassign: {
        typeLabel: t('leadDetailDrawer.history.statusTypes.dispatch'),
        label: t('leadDetailDrawer.history.statuses.unassigned'),
      },
    };
  }, [leadStatuses, t]);

  const isInternalEvent = useCallback((event?: LeadEvent) => {
    return !event || event.internal;
  }, []);

  const leadHistory = useMemo(() => {
    let history = lead?.['leads-histories'] || [];

    if (!showInternalStatuses) {
      history = history.filter((history) => {
        const event = statusMapping[history.eventName];
        return !isInternalEvent(event);
      });
    }

    return history;
  }, [lead, statusMapping, showInternalStatuses, isInternalEvent]);

  return (
    <Box px={2.5} whiteSpace='nowrap'>
      <ContentHeading py={2} px={2.5} display='flex' justifyContent='flex-end'>
        <Checkbox
          checked={showInternalStatuses}
          onChange={() => setShowInternalStatuses(!showInternalStatuses)}
          label={t('leadDetailDrawer.history.showSystemStatus')}
        />
      </ContentHeading>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {showInternalStatuses && <TableCell />}
              <TableCell>{t('leadDetailDrawer.history.header.date')}</TableCell>
              <TableCell>{t('leadDetailDrawer.history.header.user')}</TableCell>
              <TableCell>{t('leadDetailDrawer.history.header.event')}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {leadHistory.map((history) => {
              const event = statusMapping[history.eventName];
              const userIdentifier = getUserIdentifier(history);

              return (
                <TableRow key={history.id}>
                  {showInternalStatuses && (
                    <TableCell>
                      {isInternalEvent(event) && (
                        <Typography variant='body3' grey={50}>
                          <ComputerIcon />
                        </Typography>
                      )}
                    </TableCell>
                  )}
                  <TableCell>{dayjs(history.createdAt).format(DATE_FORMAT)}</TableCell>
                  <TableCell>{userIdentifier}</TableCell>
                  <TableCell>{event ? `${event.typeLabel}: ${event.label}` : history.eventName}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
