import { Dispatch, Fragment, ReactElement, SetStateAction, useCallback, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, HelpIcon, Popover, Tooltip } from '@breathelife/mui';
import { NotificationStatus, Notification, ConsumerFeedbackProvider } from '@breathelife/types';
import { ImageTypes, Loader } from '@breathelife/ui-components';

import { Icon } from '../../../Components/Icons';
import { Drawer } from '../../../Components/Drawer/Drawer';
import { CloseButton } from '../../../Components/Button/CloseButton';
import { useTheme } from '../../../Styles/themed-styled-components';
import { StyledBadge } from '../../../Components/Badge/Badge';
import { LeadPlatformTheme } from '../../../Styles/Types';
import { useSelector, useCarrierContext, useLocale } from '../../../Hooks';
import { getUserDisplayName } from '../../../Helpers/user';
import { NotificationList } from './NotificationList/NotificationList';
import { SubmitButton } from '../../../Components/Button/SubmitButton';
import { useUpdateAllNotificationsMutation } from '../../../ReactQuery/NotificationCentre/notifications.mutations';
import { useFetchAllNotificationsQuery } from '../../../ReactQuery/NotificationCentre/notifications.queries';
import { useIdleTimer } from '../../../Helpers/idleTimer';
import { NotificationCentreHelp } from './NotificationCentreHelp/NotificationCentreHelp';
import {
  Actions,
  DrawerCloseButton,
  FeedbackIcon,
  HeaderContent,
  HelpButton,
  HelpPopoverContent,
  HelpPopoverHeader,
  PopoverCloseButtonContainer,
  PopoverContent,
  RightSideContent,
  Title,
  LoadMoreContainer,
} from './Styles';

export type Props = {
  isOpen: boolean;
  onClose: () => void;
  unreadNotificationsCount: number | undefined;
  setUnreadNotificationsCount: Dispatch<SetStateAction<number | undefined>>;
  maxDays: number;
};

export function NotificationCentreDrawer(props: Props): ReactElement {
  const { isOpen, onClose, unreadNotificationsCount, setUnreadNotificationsCount, maxDays } = props;
  const theme = useTheme() as LeadPlatformTheme;
  const { t } = useTranslation();
  const { features } = useCarrierContext();
  const locale = useLocale();

  const user = useSelector((store) => store.leadPlatform.authentication.user);

  const [isMarkAllAsReadModalOpen, setIsMarkAllAsReadModalOpen] = useState<boolean>(false);
  const [isHelpModalOpen, setHelpModalOpen] = useState<boolean>(false);
  const [helpPopoverAnchorEl, setHelpPopoverAnchorEl] = useState(null);
  const [markAllAsReadPopoverAnchorEl, setMarkAllAsReadPopoverAnchorEl] = useState(null);

  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [cursorDate, setCursorDate] = useState<Date>();
  const [isUserIdle, setIsUserIdle] = useState<boolean>(false);

  // We stop the automatic refetch for the notifications when the user is idle
  useIdleTimer({
    onIdle: () => setIsUserIdle(true),
    onActive: () => setIsUserIdle(false),
    timeout: 180000,
  });

  const {
    data: fetchedNotifications,
    refetch: refetchNotifications,
    isFetching: isFetchingNotifications,
  } = useFetchAllNotificationsQuery(cursorDate, {
    refetchInterval: () => {
      if (isOpen || isUserIdle) {
        return false;
      }
      return 120000;
    },
  });

  const { mutate: mutateNotifications, isLoading: isLoadingNotifications } = useUpdateAllNotificationsMutation({
    onSuccess: () => {
      const updatedNotifications = notifications.map((notification) => {
        return { ...notification, status: NotificationStatus.Read };
      });
      setNotifications(updatedNotifications);
    },
  });

  const consumerFeedbackSurveyLink = useMemo(() => {
    return features.consumerFeedback.enabled &&
      features.consumerFeedback.provider === ConsumerFeedbackProvider.DELIGHTED &&
      !!features.consumerFeedback.delighted?.notificationCentre
      ? features.consumerFeedback.delighted?.notificationCentre.link
      : '';
  }, [features.consumerFeedback]);

  useEffect(() => {
    if (fetchedNotifications && fetchedNotifications.length > 0) {
      const currentNotifications =
        notifications.length > 0 ? [...notifications, ...fetchedNotifications] : fetchedNotifications;
      setNotifications(currentNotifications);

      const oldestNotification = currentNotifications.reduce((oldest, current) => {
        return new Date(current.createdAt) < new Date(oldest.createdAt) ? current : oldest;
      });
      setCursorDate(oldestNotification.createdAt);
    }
  }, [fetchedNotifications]);

  useEffect(() => {
    const unreadNotifications = notifications.filter((item) => {
      return item.status === NotificationStatus.Unread;
    });
    setUnreadNotificationsCount(unreadNotifications.length);
  }, [notifications, setUnreadNotificationsCount]);

  const unreadNotificationsLabel = useMemo(() => {
    if (unreadNotificationsCount === 0) {
      return t('notificationCentre.unreadNotificationsBadge.zero');
    }
    if (unreadNotificationsCount === 1) {
      return t('notificationCentre.unreadNotificationsBadge.singular');
    }
    return t('notificationCentre.unreadNotificationsBadge.plurial', { value: unreadNotificationsCount });
  }, [t, unreadNotificationsCount]);

  const handleMarkAllAsReadPopoverClick = useCallback((e: any) => {
    setMarkAllAsReadPopoverAnchorEl(e.currentTarget);
    setIsMarkAllAsReadModalOpen(true);
  }, []);

  const handleMarkAllAsReadPopoverClose = useCallback(() => {
    setMarkAllAsReadPopoverAnchorEl(null);
    setIsMarkAllAsReadModalOpen(false);
  }, []);

  const handleHelpPopoverClick = useCallback((e: any) => {
    setHelpPopoverAnchorEl(e.currentTarget);
    setHelpModalOpen(true);
  }, []);

  const handleHelpPopoverClose = useCallback(() => {
    setHelpPopoverAnchorEl(null);
    setHelpModalOpen(false);
  }, []);

  const markAllNotificationsAsRead = useCallback(() => {
    if (!user) return;
    mutateNotifications({ data: { status: NotificationStatus.Read } });
    handleMarkAllAsReadPopoverClose();
  }, [mutateNotifications, user, handleMarkAllAsReadPopoverClose]);

  const updateNotificationList = useCallback(
    (notification: Notification) => {
      if (!notifications) return;
      const newList = notifications.map((oldNotification) =>
        oldNotification.id === notification.id ? notification : oldNotification,
      );
      setNotifications(newList);
    },
    [notifications],
  );

  return (
    <Fragment>
      <Drawer open={isOpen} onClose={onClose}>
        <HeaderContent>
          <div>
            <Title variant='h1'>{t('notificationCentre.title')}</Title>
            {user && (
              <Title variant='body3' component='p' grey={60}>
                {t('notificationCentre.welcomeMessage', { name: getUserDisplayName(user) })}
              </Title>
            )}
            <StyledBadge
              backgroundColor={theme.colors.blue[20]}
              textColor={theme.colors.blue[50]}
              label={unreadNotificationsLabel}
            />
            <Tooltip title={t(`notificationCentre.helpMessage`)} arrow>
              <HelpButton onClick={handleHelpPopoverClick}>
                <HelpIcon />
              </HelpButton>
            </Tooltip>
            <Popover
              anchorEl={helpPopoverAnchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={isHelpModalOpen}
              onClose={handleHelpPopoverClose}
            >
              <HelpPopoverContent>
                <HelpPopoverHeader>
                  <Title variant='h3'>{t('notificationCentre.helpMessage')}</Title>
                  <CloseButton onClick={handleHelpPopoverClose} />
                </HelpPopoverHeader>
                <NotificationCentreHelp />
              </HelpPopoverContent>
            </Popover>
          </div>
          <RightSideContent>
            <DrawerCloseButton onClick={onClose} />
            <Actions>
              {consumerFeedbackSurveyLink && (
                <FeedbackIcon
                  onClick={() =>
                    window.open(consumerFeedbackSurveyLink + '?locale=' + locale, '_blank', 'noopener noreferrer')
                  }
                >
                  <Icon
                    name='notificationFeedbackIcon'
                    variant='grey'
                    stroke='none'
                    imageType={ImageTypes.decorative}
                    width='24px'
                    height='24px'
                  />
                </FeedbackIcon>
              )}
              <Button color='primary' variant='outlined' onClick={handleMarkAllAsReadPopoverClick}>
                {t('notificationCentre.markAllAsRead')}
              </Button>
              <Popover
                anchorEl={markAllAsReadPopoverAnchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={isMarkAllAsReadModalOpen}
                onClose={handleMarkAllAsReadPopoverClose}
              >
                <PopoverContent>
                  <PopoverCloseButtonContainer>
                    <CloseButton onClick={handleMarkAllAsReadPopoverClose} />
                  </PopoverCloseButtonContainer>
                  <p>{t('notificationCentre.markAllAsReadPopover.content')}</p>
                  {user ? (
                    <SubmitButton
                      onClick={markAllNotificationsAsRead}
                      disabled={isLoadingNotifications || unreadNotificationsCount === 0}
                    >
                      {t('notificationCentre.markAllAsReadPopover.confirmButton')}
                    </SubmitButton>
                  ) : (
                    <Loader />
                  )}
                </PopoverContent>
              </Popover>
            </Actions>
          </RightSideContent>
        </HeaderContent>

        {!isFetchingNotifications ? (
          <NotificationList notifications={notifications} updateNotificationList={updateNotificationList} />
        ) : (
          <Loader />
        )}
        <LoadMoreContainer>
          {fetchedNotifications && fetchedNotifications.length > 0 ? (
            <Button onClick={() => refetchNotifications()}>{t('notificationCentre.loadMoreNotifications')}</Button>
          ) : (
            <p>{t('notificationCentre.allLatestNotificationsLoaded', { maxDays })}</p>
          )}
        </LoadMoreContainer>
      </Drawer>
    </Fragment>
  );
}
