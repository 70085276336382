import { Fragment, ReactElement, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { AddIcon, EditIcon, Grid, IconButton, RemoveIcon } from '@breathelife/mui';
import {
  BlueprintConditionsValueWithMessage,
  BlueprintConditionValue,
  BooleanOperator,
  Language,
} from '@breathelife/types';
import { Box, Input as TextInput, ReadonlyFormControlLabel } from '@breathelife/ui-components';

import { ActionButton } from '../../../../../Components/Button/ActionButton';
import { SubmitButton } from '../../../../../Components/Button/SubmitButton';
import {
  ConditionsEditor as ConditionsEditorView,
  defaultSingleConditionBlueprintValue,
} from '../../../../../Components/Conditions';
import { ConditionView } from '../../../../../Components/Conditions/ConditionView';
import {
  addConditionToBlueprint,
  addNestedConditionToBlueprint,
  removeConditionFromBlueprint,
  updateConditionBlueprint,
  updateConditionBooleanOperator,
} from '../../../../../Helpers/conditions/blueprintHelpers';
import { validateBlueprint } from '../../../../../Helpers/inputValidation/form/salesDecisionRules';
import {
  NodeIdInCollections,
  QuestionnaireNodeIds,
} from '../../../../../Helpers/questionnaireEditor/questionnaireNodeIds';
import { useCarrierContext } from '../../../../../Hooks';
import { ModalLayout } from '../../../../../Layouts/Modal/ModalLayout';
import { useTheme } from '../../../../../Styles/themed-styled-components';
import { useQuestionnaireVersionDetailWithNodeIdInfo } from '../../ContextProvider/QuestionnaireVersionDataContextProvider';
import { SummaryText } from '../Components/SummaryText';

type Props = {
  selectedLanguage: Language;
  questionnaireNodeIds: QuestionnaireNodeIds;
  collectionContext: string[];
  validationConditions?: BlueprintConditionsValueWithMessage[];
  isReadOnly: boolean;
  save: (validationConditions: BlueprintConditionsValueWithMessage[] | undefined) => void;
};

export function ValidationConditionsEditor(props: Props): ReactElement | null {
  const { questionnaireNodeIds, collectionContext, isReadOnly, validationConditions, selectedLanguage, save } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = useCallback(() => setIsModalOpen(false), [setIsModalOpen]);

  return (
    <Box pt={2} pr={1}>
      {isModalOpen && (
        <ValidationConditionsEditorModal
          existingValidationConditions={validationConditions}
          questionnaireNodeIds={questionnaireNodeIds}
          collectionContext={collectionContext}
          closeModal={closeModal}
          selectedLanguage={selectedLanguage}
          save={save}
        />
      )}
      <ValidationConditionsSummaryWithEditButton
        validationConditions={validationConditions}
        setIsModalOpen={setIsModalOpen}
        isReadOnly={isReadOnly}
        selectedLanguage={selectedLanguage}
      />
    </Box>
  );
}

function ValidationConditionsSummaryWithEditButton(props: {
  validationConditions?: BlueprintConditionsValueWithMessage[];
  setIsModalOpen: (isOpen: boolean) => void;
  isReadOnly: boolean;
  selectedLanguage: Language;
}): ReactElement {
  const { validationConditions, isReadOnly, setIsModalOpen, selectedLanguage } = props;

  return (
    <Grid container alignItems='flex-start' justifyContent='space-between'>
      <ValidationConditionsSummary validationConditions={validationConditions} selectedLanguage={selectedLanguage} />
      <Grid item>
        {!isReadOnly && (
          <IconButton onClick={() => setIsModalOpen(true)} size='large'>
            <EditIcon />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
}

function ValidationConditionsSummary(props: {
  validationConditions?: BlueprintConditionsValueWithMessage[];
  selectedLanguage: Language;
}): ReactElement {
  const { validationConditions, selectedLanguage } = props;
  const { t } = useTranslation();

  const conditionSummaryControl = useMemo(
    () =>
      validationConditions ? (
        <Fragment>
          {validationConditions.map((validationConditions) => (
            <Box key={validationConditions.id} pt={1}>
              <ConditionView condition={validationConditions.conditions} />

              <Box pr={1}>
                <SummaryText
                  text={t('admin.questionnaireManagement.rules.validation.messagePrompt', {
                    message: validationConditions.message[selectedLanguage],
                  })}
                />
              </Box>
            </Box>
          ))}
        </Fragment>
      ) : (
        <Box pr={1}>
          <SummaryText fallbackText={t('admin.questionnaireManagement.input.noneSet')} />
        </Box>
      ),
    [validationConditions, selectedLanguage, t],
  );

  return (
    <Grid item xs={10}>
      <ReadonlyFormControlLabel
        label={t('admin.questionnaireManagement.rules.validation.conditions')}
        showError={false}
        labelPlacement='top'
        control={conditionSummaryControl}
      />
    </Grid>
  );
}

function ValidationConditionsEditorModal(props: {
  existingValidationConditions?: BlueprintConditionsValueWithMessage[];
  save: (validationConditions: BlueprintConditionsValueWithMessage[] | undefined) => void;
  closeModal: () => void;
  questionnaireNodeIds: QuestionnaireNodeIds;
  collectionContext: string[];
  selectedLanguage: Language;
}): ReactElement | null {
  const { save, closeModal, existingValidationConditions, questionnaireNodeIds, collectionContext, selectedLanguage } =
    props;

  const { languageSettings } = useCarrierContext();
  const enabledLanguages = languageSettings.enabledLanguages;
  const { nodeIdInCollectionMap } = useQuestionnaireVersionDetailWithNodeIdInfo() || {};

  const [validationConditions, setValidationConditions] = useState<BlueprintConditionsValueWithMessage[]>(
    setIdIfMissing(existingValidationConditions) ?? [],
  );

  const { t } = useTranslation();
  const isFormValid = useMemo(() => {
    if (!nodeIdInCollectionMap) {
      return false;
    }
    return validationConditions.every((validationCondition) => {
      const messageExistsForAllLanguages = enabledLanguages.every((language) => validationCondition.message[language]);
      return (
        messageExistsForAllLanguages &&
        validateBlueprint(validationCondition.conditions, questionnaireNodeIds, nodeIdInCollectionMap)
      );
    });
  }, [validationConditions, questionnaireNodeIds, enabledLanguages, nodeIdInCollectionMap]);

  if (!nodeIdInCollectionMap) {
    return null;
  }
  return (
    <ModalLayout
      maxWidth='lg'
      isOpen={true}
      closeModal={closeModal}
      title={t('admin.questionnaireManagement.rules.validation.edit')}
      submitButton={
        <SubmitButton
          disabled={!isFormValid}
          onClick={() => {
            if (validationConditions?.length) {
              save(validationConditions);
            } else {
              // Clear entirely rather than save an empty array.
              save(undefined);
            }

            closeModal();
          }}
          data-testid='questionnaire-editor-save-conditions'
        >
          {t('cta.save')}
        </SubmitButton>
      }
    >
      {validationConditions.map((validationCondition, index) => (
        <ConditionAndMessageEditor
          validationCondition={validationCondition}
          questionnaireNodeIds={questionnaireNodeIds}
          nodeIdInCollectionMap={nodeIdInCollectionMap}
          collectionContext={collectionContext}
          onRemove={() =>
            setValidationConditions((previousConditions) => {
              const updatedConditions = [...previousConditions];
              updatedConditions.splice(index, 1);
              return updatedConditions;
            })
          }
          onChange={(updated: BlueprintConditionsValueWithMessage) => {
            setValidationConditions((previousConditions) => {
              const updatedConditions = [...previousConditions];
              updatedConditions[index] = updated;
              return updatedConditions;
            });
          }}
          selectedLanguage={selectedLanguage}
          key={validationCondition.id}
          conditionIndex={index.toString()}
        />
      ))}
      <Box pt={2}>
        <ActionButton
          onClick={() => {
            setValidationConditions((previousConditions) => {
              const defaultValidationCondition: BlueprintConditionsValueWithMessage = {
                id: uuid(),
                conditions: {
                  booleanOperator: BooleanOperator.and,
                  conditions: [defaultSingleConditionBlueprintValue()],
                },
                message: { en: '', fr: '' },
              };

              const updatedConditions = [...previousConditions];
              updatedConditions.push(defaultValidationCondition);

              return updatedConditions;
            });
          }}
          data-testid='addValidationConditionButton'
          color='primary'
          variant='outlined'
          startIcon={<AddIcon htmlColor='white' />}
        >
          {t('admin.questionnaireManagement.rules.validation.addCondition')}
        </ActionButton>
      </Box>
    </ModalLayout>
  );
}

function ConditionAndMessageEditor(props: {
  validationCondition: BlueprintConditionsValueWithMessage;
  collectionContext: string[];
  questionnaireNodeIds: QuestionnaireNodeIds;
  nodeIdInCollectionMap: NodeIdInCollections;
  onRemove: () => void;
  onChange: (validationCondition: BlueprintConditionsValueWithMessage) => void;
  selectedLanguage: Language;
  conditionIndex: string;
}): ReactElement {
  const {
    validationCondition,
    questionnaireNodeIds,
    nodeIdInCollectionMap,
    collectionContext,
    onRemove,
    onChange,
    selectedLanguage,
    conditionIndex,
  } = props;
  const { conditions } = validationCondition;
  const { t } = useTranslation();
  const theme = useTheme();
  const { languageSettings } = useCarrierContext();
  const enabledLanguages = useMemo(() => languageSettings.enabledLanguages, [languageSettings.enabledLanguages]);

  const messageLabels = useMemo(
    () =>
      enabledLanguages.reduce(
        (labels, language) => {
          labels[language] = t('admin.questionnaireManagement.input.validationMessageAndLanguage', {
            language: t(`language.${language}`),
          });
          return labels;
        },
        {} as Record<Language, string>,
      ),
    [enabledLanguages, t],
  );

  const onConditionChange = useCallback(
    (data: BlueprintConditionValue, path: string) => {
      const updatedCondition = updateConditionBlueprint(validationCondition.conditions, path, data);

      if (updatedCondition) {
        onChange({
          ...validationCondition,
          conditions: updatedCondition,
        });
      }
    },
    [validationCondition, onChange],
  );

  const onBooleanOperatorChange = useCallback(
    (booleanOperator: BooleanOperator, path: string) => {
      const updatedCondition = updateConditionBooleanOperator(validationCondition.conditions, path, booleanOperator);
      if (updatedCondition) {
        onChange({
          ...validationCondition,
          conditions: updatedCondition,
        });
      }
    },
    [validationCondition, onChange],
  );

  const onAddCondition = useCallback(
    (path: string) => {
      const updatedCondition = addConditionToBlueprint(validationCondition.conditions, path);
      if (updatedCondition) {
        onChange({
          ...validationCondition,
          conditions: updatedCondition,
        });
      }
    },
    [validationCondition, onChange],
  );

  const onAddNestedCondition = useCallback(
    (path: string) => {
      const updatedCondition = addNestedConditionToBlueprint(validationCondition.conditions, path);
      if (updatedCondition) {
        onChange({
          ...validationCondition,
          conditions: updatedCondition,
        });
      }
    },
    [validationCondition, onChange],
  );

  const onRemoveCondition = useCallback(
    (path: string) => {
      const updatedCondition = removeConditionFromBlueprint(validationCondition.conditions, path);
      if (updatedCondition) {
        onChange({
          ...validationCondition,
          conditions: updatedCondition,
        });
      }
    },
    [validationCondition, onChange],
  );

  return (
    <Box pt={3} pb={2} borderBottom={`1px solid ${theme.colors.grey[40]}`}>
      <ConditionsEditorView
        questionnaireNodeIds={questionnaireNodeIds}
        collectionContext={collectionContext}
        condition={conditions}
        onConditionChange={onConditionChange}
        onBooleanOperatorChange={onBooleanOperatorChange}
        onAddCondition={onAddCondition}
        onAddNestedCondition={onAddNestedCondition}
        onRemoveCondition={onRemoveCondition}
        language={selectedLanguage}
        nodeIdInCollectionMap={nodeIdInCollectionMap}
        conditionIndex={conditionIndex}
      />
      <Box pt={2} pb={2}>
        <Grid container spacing={1}>
          {enabledLanguages.map((language) => (
            <Grid item xs={6} key={`validation-message-${language}`}>
              <TextInput
                inputVariant='outlined'
                label={`${messageLabels[language]} *`}
                value={validationCondition.message[language] ?? ''}
                onChange={(event) => {
                  const value = event.target.value;

                  const updatedValidationCondition: BlueprintConditionsValueWithMessage = {
                    ...validationCondition,
                    message: {
                      ...validationCondition.message,
                      [language]: value,
                    },
                  };
                  onChange(updatedValidationCondition);
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      <ActionButton
        onClick={onRemove}
        data-testid='removeDefaultConditionButton'
        color='secondary'
        variant='outlined'
        startIcon={<RemoveIcon />}
      >
        {t('admin.questionnaireManagement.rules.removeCondition')}
      </ActionButton>
    </Box>
  );
}

function setIdIfMissing(
  validationConditions?: BlueprintConditionsValueWithMessage[],
): BlueprintConditionsValueWithMessage[] | undefined {
  if (!validationConditions) return undefined;

  return validationConditions.map((condition) => {
    if (!condition.id) {
      return { ...condition, id: uuid() };
    }
    return condition;
  });
}
