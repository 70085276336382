import { ReactElement, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Divider } from '@breathelife/mui';
import { Box } from '@breathelife/ui-components';

import { HelmetContainer } from '../../../Components/HelmetContainer';
import Typography from '../../../Components/Typography';
import { ThirdPartyIntegrationsBoard } from './ThirdPartyIntegrationsBoard';
import { ThirdPartyIntegrationsContextProvider } from './ThirdPartyIntegrationsContextProvider';

export function ThirdPartyIntegrationsView(): ReactElement {
  const { t } = useTranslation();

  const pageTitle = t('pageTitles.thirdPartyIntegrations');

  return (
    <Fragment>
      <HelmetContainer text={pageTitle} />

      <Box flex flexGrow={1}>
        <Box m={2} display='flex' justifyContent={'space-between'}>
          <div>
            <Typography variant='h1'>{pageTitle}</Typography>
            <Typography variant='body1'>Work in progress..</Typography>
          </div>
        </Box>

        <Divider />

        <Box m={2} flex>
          <ThirdPartyIntegrationsContextProvider>
            <ThirdPartyIntegrationsBoard />
          </ThirdPartyIntegrationsContextProvider>
        </Box>
      </Box>
    </Fragment>
  );
}
