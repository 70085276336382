import { Application, ApplicationMode, ApplicationType, LineOfBusinessName, Permission } from '@breathelife/types';
import { Fragment, ReactElement, useCallback, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCarrierContext, useNavigation, useSelector } from '../../Hooks';
import { useLaunchNewAssistedApplicationMutation } from '../../ReactQuery/AssistedApplication/assistedApplication.mutations';
import { ActionButton } from '../Button/ActionButton';
import { useTranslation } from 'react-i18next';
import { generateApplicationUrl } from '../../Navigation/Urls';
import { LeadsPageDataContext } from '../../Pages/Leads/LeadsPageDataContextProvider';
import { useGetQuestionnaires } from '../../ReactQuery/Questionnaire/questionnaire.queries';
import { LaunchApplicationModal } from '../../Pages/Home/Modals/LaunchApplicationModal';
import { WrappedWithFullPageLoader, useFullPageLoaderContext } from '../LoadingView/FullPageLoader';
import { userHasPermission } from '../../Helpers/user';

export function CreateApplicationAndLaunchButtonChild(): ReactElement {
  const { leadTab, leadId } = useNavigation();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { isLoadingApplication } = useContext(LeadsPageDataContext);

  const { openFullPageLoader, closeFullPageLoader } = useFullPageLoaderContext();

  const { carrierInfo, features } = useCarrierContext();

  const { data: questionnaires = [], isLoading: getQuestionnairesIsLoading } = useGetQuestionnaires();
  const [isSelectQuestionnaireModalOpen, setIsSelectQuestionnaireModalOpen] = useState<boolean>(false);

  // TODO HOT-4574: Remove lineOfBusiness from config
  const hasMultipleLineOfBusiness = carrierInfo.lineOfBusiness.length > 1;

  const user = useSelector((store) => store.leadPlatform.authentication.user);

  const isPaperAppModeEnabled =
    features.questionnaireScreen?.enabled && userHasPermission(user?.permissions, [Permission.PaperApplicationCreate]);

  const launchAssistedApplication = useCallback(
    (application: Application) => {
      if (!application.leadId) return;
      navigate(generateApplicationUrl(application.id, application.leadId, leadTab));
    },
    [leadTab, navigate],
  );
  const launchNewAssistedApplicationMutation = useLaunchNewAssistedApplicationMutation({
    onSuccess: (data) => {
      launchAssistedApplication(data);
    },
    onError: () => {
      closeFullPageLoader();
    },
  });

  const launchNewApplication = useCallback(
    (questionnaireId: string, lineOfBusiness: LineOfBusinessName, mode: ApplicationMode = ApplicationMode.digital) => {
      if (leadId) {
        openFullPageLoader();
        launchNewAssistedApplicationMutation.mutate(
          {
            leadId,
            type: ApplicationType.transactionFlow,
            mode,
            lineOfBusiness,
            questionnaireId,
          },
          {
            onError: () => {
              closeFullPageLoader();
            },
          },
        );
      }
    },
    [leadId, launchNewAssistedApplicationMutation, openFullPageLoader, closeFullPageLoader],
  );

  const handleClick = useCallback(() => {
    if (!questionnaires.length) {
      return;
    }

    if (hasMultipleLineOfBusiness) {
      setIsSelectQuestionnaireModalOpen(true);
      return;
    }

    if (questionnaires.length === 1 && questionnaires[0].linesOfBusiness?.length === 1) {
      launchNewApplication(questionnaires[0].id, questionnaires[0].linesOfBusiness[0].tag);
      return;
    }

    setIsSelectQuestionnaireModalOpen(true);
  }, [questionnaires, launchNewApplication, hasMultipleLineOfBusiness]);

  const handleSubmit = useCallback(
    (data: { lineOfBusinessTag: LineOfBusinessName; questionnaireId: string; applicationMode?: ApplicationMode }) => {
      launchNewApplication(data.questionnaireId, data.lineOfBusinessTag, data.applicationMode);
    },
    [launchNewApplication],
  );

  return (
    <Fragment>
      {!getQuestionnairesIsLoading && questionnaires.length > 0 && (
        <ActionButton
          color='primary'
          variant='contained'
          onClick={handleClick}
          disabled={launchNewAssistedApplicationMutation.isLoading || isLoadingApplication}
          data-testid='create-application-and-launch-btn'
        >
          {t('cta.launchApplication')}
        </ActionButton>
      )}
      <LaunchApplicationModal
        isOpen={isSelectQuestionnaireModalOpen}
        closeModal={() => setIsSelectQuestionnaireModalOpen(false)}
        onSubmitClick={(data) => {
          handleSubmit({
            lineOfBusinessTag: data.lineOfBusiness,
            questionnaireId: data.questionnaireId,
            applicationMode: data.mode,
          });
        }}
        requireApplicationMode={!!isPaperAppModeEnabled}
        disableSubmitButton={launchNewAssistedApplicationMutation.isLoading}
      />
    </Fragment>
  );
}

export const CreateApplicationAndLaunchButton = WrappedWithFullPageLoader(CreateApplicationAndLaunchButtonChild);
