import { ReactElement } from 'react';

import { SvgIconProps } from '../../Components/Icons';

export function RefreshIcon(props: SvgIconProps): ReactElement {
  return (
    <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props.svgProps}>
      <g clipPath='url(#clip0_3974_2499)'>
        <path
          d='M15.0906 12.2731C15.2777 11.9911 15.2013 11.6106 14.9185 11.4232C14.6365 11.236 14.2558 11.313 14.0686 11.5953C13.5618 12.3591 12.8991 12.9806 12.0986 13.4428C9.27363 15.0738 5.64855 14.1023 4.01764 11.2775C2.38673 8.45266 3.35796 4.82752 6.18293 3.19653C8.64434 1.77543 11.4556 1.91365 13.2835 3.94859L12.5476 4.30592C12.3553 4.44859 11.9191 4.54333 11.8986 4.88131C11.8912 5.00578 11.9215 5.12383 11.9795 5.22427C12.079 5.39664 12.2606 5.51738 12.474 5.53031L14.752 5.66691L14.7583 5.66731C14.7784 5.66846 14.7978 5.66596 14.8176 5.66525C14.836 5.66453 14.8545 5.66578 14.873 5.66341C14.912 5.65848 14.9499 5.64977 14.9864 5.63776C14.9934 5.63554 15 5.63153 15.0069 5.629C15.0372 5.61776 15.0667 5.60516 15.0947 5.58943C15.0968 5.5882 15.0993 5.58758 15.1014 5.58635C15.1114 5.58057 15.1194 5.57271 15.129 5.56656C15.1503 5.55265 15.1711 5.5386 15.1907 5.52228C15.2038 5.51105 15.2154 5.4989 15.2274 5.48672C15.2425 5.47153 15.2575 5.45618 15.2709 5.43952C15.2821 5.42558 15.2919 5.41102 15.3019 5.39637C15.3135 5.37911 15.3245 5.36165 15.3345 5.34316C15.3429 5.32734 15.35 5.31114 15.3571 5.2947C15.365 5.27594 15.3723 5.25698 15.3785 5.23722C15.3842 5.21898 15.3886 5.20042 15.3926 5.18174C15.3948 5.17093 15.3991 5.16078 15.4008 5.14969L15.765 2.81174C15.8173 2.47742 15.5884 2.16352 15.2534 2.11149C14.9188 2.05928 14.8553 2.44859 14.5532 2.623L14.0686 3.19653C11.8576 0.772792 8.52501 0.428149 5.5697 2.1344C2.15905 4.10354 0.986365 8.48004 2.95551 11.8907C4.92465 15.3014 9.30116 16.474 12.7118 14.5049C13.6777 13.9472 14.478 13.1965 15.0906 12.2731Z'
          fill='#757575'
        />
      </g>
      <defs>
        <clipPath id='clip0_3974_2499'>
          <rect width='16' height='16' fill='white' transform='translate(16.9282) rotate(90)' />
        </clipPath>
      </defs>
    </svg>
  );
}
