import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, RepeatIcon, Tooltip } from '@breathelife/mui';
import { Language, SectionGroupBlueprint } from '@breathelife/types';

import { MoreActionButton } from '../../../../../Components/MoreActionButton/MoreActionButton';
import { MenuElement } from '../../../../../Components/PopupMenu/PopupMenu';
import Typography from '../../../../../Components/Typography';
import styled, { useTheme } from '../../../../../Styles/themed-styled-components';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 16px;
`;

export function SectionGroupHeader({
  sectionGroupBlueprint,
  selectedLanguage,
  menuActions,
}: {
  sectionGroupBlueprint?: SectionGroupBlueprint;
  selectedLanguage: Language;
  repeatableIcon?: ReactElement;
  menuActions: MenuElement[];
}): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();

  const title = sectionGroupBlueprint?.title?.[selectedLanguage]?.toUpperCase();
  const titleColor = theme.colors.grey[60];

  const isRepeatIconVisible = Number(sectionGroupBlueprint?.repeatable?.maxRepeatable) > 0;
  const repeatIconCount = sectionGroupBlueprint?.repeatable?.maxRepeatable;
  const repeatIconTooltipText = t('admin.questionnaireManagement.input.numberOfInsured');

  return (
    <Container>
      <Box display={'flex'}>
        <Typography variant='h2' color={titleColor}>
          {title}
        </Typography>

        {isRepeatIconVisible && (
          <Tooltip title={repeatIconTooltipText} aria-label='Repeatable field group' placement='top' disableInteractive>
            <Box display='flex' ml={1}>
              <RepeatIcon fontSize='small' />
              {repeatIconCount}
            </Box>
          </Tooltip>
        )}
      </Box>

      <div>
        <MoreActionButton actions={menuActions} />
      </div>
    </Container>
  );
}
