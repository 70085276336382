import { CSSProperties, ReactElement } from 'react';

import styled from '../../Styles/themed-styled-components';

import { Box, Tag } from '@breathelife/ui-components';

export type BadgeConfig = BadgeUI & { label: string };

export type BadgeUI = {
  icon?: ReactElement;
  styles: Pick<CSSProperties, 'backgroundColor' | 'color'>;
};

export const Badge = styled(Tag)`
  font-size: ${(props) => props.theme.text.small2.size};
  font-weight: ${(props) => props.theme.text.small2.weight};
  color: ${(props) => (props.textColor ? props.textColor : props.theme.colors.grey[60])};
`;

export const StyledBadge = styled(Badge)`
  text-transform: uppercase;
  border-color: ${(props) => props.theme.colors.grey[0]};
`;

export const StyledBadgeContainer = styled(Box)`
  max-width: fit-content;
`;

export const StyledBadgeIconContainer = styled(Box)`
  align-items: center;
  display: flex;
  margin-right: 5px;
`;
