import { ReactElement, createContext, PropsWithChildren, useContext } from 'react';

import {
  PartIdentifierTag,
  QuestionnaireVersionPricingNodeIds,
  SectionBlueprint,
  SectionGroupKey,
} from '@breathelife/types';

import { useFetchQuestionnaireVersionQuery } from '../../../../ReactQuery/Admin/Questionnaire/questionnaireVersion.queries';
import { QuestionnaireVersionDetailWithNodeIdInfo } from '../../../../Services/QuestionnaireVersionService';
import { QuestionnaireEditorContext } from './QuestionnaireEditorContextProvider';
import { useFetchPricingFieldIdentifiersQuery } from '../../../../ReactQuery/Admin/PricingFieldIdentifiers/pricingFieldIdentifiers.queries';
import { CarrierContext } from '../../../../Context/CarrierContext';

type QuestionnaireVersionDataContextValue = {
  questionnaireVersionId?: string;
  questionnaireVersionData?: QuestionnaireVersionDetailWithNodeIdInfo;
  pricingFieldIdentifiers?: QuestionnaireVersionPricingNodeIds;
  isLoading: boolean;
};

export const QuestionnaireVersionDataContext = createContext<QuestionnaireVersionDataContextValue>({
  isLoading: false,
});

export function useSectionBlueprintsInSectionGroup(sectionGroupKey: SectionGroupKey): SectionBlueprint[] {
  const { questionnaireVersionData } = useContext(QuestionnaireVersionDataContext);
  return (
    questionnaireVersionData?.blueprint.sectionBlueprints?.filter(
      (sectionBlueprint) => sectionBlueprint.sectionGroupKey === sectionGroupKey,
    ) || []
  );
}

export function useContractSectionBlueprints(): SectionBlueprint[] {
  return useSectionBlueprintsInSectionGroup('contract');
}

export function useInsuredPeopleSectionBlueprints(): SectionBlueprint[] {
  return useSectionBlueprintsInSectionGroup('insuredPeople');
}

export function useQuestionnaireVersionDetailWithNodeIdInfo(): QuestionnaireVersionDetailWithNodeIdInfo | undefined {
  const { questionnaireVersionData } = useContext(QuestionnaireVersionDataContext);
  return questionnaireVersionData;
}

export function QuestionnaireVersionDataContextProvider({
  questionnaireVersionId,
  children,
}: PropsWithChildren<{ questionnaireVersionId: string }>): ReactElement {
  const questionnaireEditorContextData = useContext(QuestionnaireEditorContext);
  const { features } = useContext(CarrierContext);

  const { data: questionnaireVersionData, isLoading: isQuestionnaireVersionLoading } =
    useFetchQuestionnaireVersionQuery(questionnaireVersionId, {
      onSuccess: ({ blueprint }) => {
        if (!questionnaireEditorContextData.selectedPartIdentifier) {
          questionnaireEditorContextData?.onSelectedPartIdentifierChange({
            tag: PartIdentifierTag.section,
            sectionGroupPartName: 'insuredPeople',
            sectionPartName: blueprint.sectionBlueprints[0]?.partName ?? '',
          });
        }
      },
    });

  const { data, isLoading: isPricingFieldIdentifiersLoading } = useFetchPricingFieldIdentifiersQuery(
    questionnaireVersionId,
    { enabled: features.pricingCalculation.enabled },
  );

  let isLoading = isQuestionnaireVersionLoading;

  if (features.pricingCalculation.enabled) {
    isLoading = isLoading && isPricingFieldIdentifiersLoading;
  }

  return (
    <QuestionnaireVersionDataContext.Provider
      value={{
        isLoading,
        questionnaireVersionId,
        questionnaireVersionData,
        pricingFieldIdentifiers: data && data[0],
      }}
    >
      {children}
    </QuestionnaireVersionDataContext.Provider>
  );
}
