import { ReactElement } from 'react';

import { SvgIconProps } from '../../Components/Icons';

export function UnarchiveIcon(props: SvgIconProps): ReactElement {
  return (
    <svg
      stroke='rgb(117, 117, 117)'
      fill='#f6f6f6'
      height='16'
      width='16'
      xmlns='http://www.w3.org/2000/svg'
      {...props.svgProps}
    >
      <g strokeLinecap='round' strokeLinejoin='round'>
        <path fill='none' d='M10.5 4v1m-9-1v6c0 .81.69 1.5 1.5 1.5h1.5' />
        <path d='M10.767 1.5H1.233a.734.734 0 00-.733.733V3.5h11V2.233a.734.734 0 00-.733-.733zM7.467 5.9a.734.734 0 01-.734.734H5.267a.734.734 0 010-1.467h1.466c.405 0 .734.329.734.733zM11 7a4 4 0 110 8 4 4 0 010-8z' />
      </g>
      <path d='M12.5 9.5l-3 3z' />
      <path d='M12.5 9.5l-3 3' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M9.5 9.5l3 3z' />
      <path d='M9.5 9.5l3 3' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
}
