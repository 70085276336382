import _ from 'lodash';
import { ReactElement, useCallback, useState } from 'react';

import { UserNotificationOptions } from '@breathelife/types';

import { useDispatch, useSelector, useCarrierContext } from '../../../../Hooks';
import { updateUserNotificationPreferences } from '../../../../ReduxStore/Authentication/UserOperations';
import { NotificationPreferencesModal } from './NotificationPreferencesModal';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
};

export function NotificationPreferencesModalContainer(props: Props): ReactElement | null {
  const { isOpen, closeModal } = props;
  const dispatch = useDispatch();
  const { features } = useCarrierContext();

  if (!features.userNotificationEmail.enabled) return null;

  const carrierPreferences = features.userNotificationEmail.emailTypes;
  const user = useSelector((store) => store.leadPlatform.authentication.user);
  const userNotificationPreferences = user?.notificationPreferences;

  const selectedPreferencesWithDefault: UserNotificationOptions = Object.values(carrierPreferences).reduce(
    (acc, key) => {
      acc[key] = userNotificationPreferences ? userNotificationPreferences[key] : false;
      return acc;
    },
    {},
  );

  const [selectedPreferences, setSelectedPreferences] =
    useState<UserNotificationOptions>(selectedPreferencesWithDefault);

  const isLoading = useSelector((state) => state.leadPlatform.authentication.isLoading);

  const onSaveClick = useCallback(async () => {
    if (!user) return;
    const preferencesWithBoolValues = _.mapValues(selectedPreferences, (preference) => !!preference);
    await dispatch(updateUserNotificationPreferences(user.auth0Id, preferencesWithBoolValues));
    closeModal();
  }, [dispatch, user, selectedPreferences, closeModal]);

  return (
    <NotificationPreferencesModal
      isOpen={isOpen}
      closeModal={() => {
        closeModal();
        setSelectedPreferences(selectedPreferencesWithDefault);
      }}
      isLoading={isLoading}
      preferences={selectedPreferences}
      onSaveClick={onSaveClick}
      onChange={(notification: string) => {
        setSelectedPreferences((oldPreferences) => ({
          ...oldPreferences,
          [notification]: !selectedPreferences[notification],
        }));
      }}
    />
  );
}
