import { Validations } from '@breathelife/questionnaire-engine';
import { FieldBlueprint, CheckboxFieldBlueprint, FieldTypes, BooleanFieldValidation } from '@breathelife/types';
import { AutocompleteOption } from '@breathelife/ui-components';

import {
  FieldSettersValidateAs,
  FieldSettersDefaultValue,
  FieldDataHookReturnValueWithValidationOptions,
} from '../../Helpers/helperTypes';
import { BaseFieldSetters, useBaseFieldData } from './useBaseFieldData';
import { useDefaultValue } from './useDefaultValue';
import { useValidateAsData } from './useValidateAsData';

export type CheckboxFieldSetters = FieldSettersValidateAs<CheckboxFieldBlueprint> &
  FieldSettersDefaultValue<CheckboxFieldBlueprint> &
  BaseFieldSetters;

export const useCheckboxFieldData = ({
  initialData,
  dataLabelOptions,
}: {
  initialData?: Partial<FieldBlueprint>;
  dataLabelOptions: AutocompleteOption[];
}): FieldDataHookReturnValueWithValidationOptions<CheckboxFieldBlueprint, CheckboxFieldSetters> => {
  const fieldType = FieldTypes.checkbox;
  const { data, setters } = useBaseFieldData({ initialData, dataLabelOptions });
  const {
    data: validateAsData,
    setters: validateAsSetters,
    validationOptions,
  } = useValidateAsData<CheckboxFieldBlueprint>({
    fieldType,
    initialValidateAs: BooleanFieldValidation.boolean,
  });
  const {
    data: defaultValueData,
    setters: defaultValueSetters,
    validationError,
  } = useDefaultValue<CheckboxFieldBlueprint>({
    validateAs: validateAsData.validateAs as unknown as Validations,
  });

  return {
    data: { fieldType, ...data, ...validateAsData, ...defaultValueData },
    setters: { ...setters, ...validateAsSetters, ...defaultValueSetters },
    validationOptions,
    defaultValueValidationError: validationError,
  };
};
