import { TFunction } from 'i18next';
import _ from 'lodash';

import { UserCategory, UserImportData, UserRole, UserType } from '@breathelife/types';

export const headerStructure = [
  'firstName',
  'lastName',
  'email',
  'agentId',
  'phoneNumber',
  'role',
  'type',
  'category',
  'groupId',
  'subGroupName',
];
export const MAX_NUMBER_OF_USERS = 100;
export const WHITELISTED_FILE_EXTENSIONS: string[] = ['csv'];
export const NUMBER_OF_COLUMNS = headerStructure.length;

export function validateHeader(headerRow: string[]): boolean {
  return headerRow.every(
    (columnName: string, index: number) =>
      !!headerStructure[index] && headerStructure[index].toLocaleLowerCase() === columnName.toLocaleLowerCase(),
  );
}

export function validateExtension(extension: string | undefined): boolean {
  return !!extension && WHITELISTED_FILE_EXTENSIONS.includes(extension.replace(/\./, '').toLocaleLowerCase());
}

export function validateUserImportData(importData: string[][], t: TFunction): void {
  const [header, ...data] = importData;

  if (!header || !data.length) {
    throw new Error(t('modals.importUsers.invalidFileLength'));
  }

  const hasValidHeader = validateHeader(header);

  if (!hasValidHeader) {
    throw new Error(t('modals.importUsers.invalidFileHeader'));
  }

  if (data.length > MAX_NUMBER_OF_USERS) {
    throw new Error(t('modals.importUsers.maxUsersExceeded', { maxNumberOfUsers: MAX_NUMBER_OF_USERS }));
  }

  data.forEach((row) => {
    if (row.length !== NUMBER_OF_COLUMNS) {
      throw new Error(
        t('modals.importUsers.invalidNumberOfColumn', {
          nbOfColumnRequired: NUMBER_OF_COLUMNS,
          nbOfColumnSpecified: row.length,
          data: row.length > 0 ? row.join(',') : '',
        }),
      );
    }
  });
}

function removeEmptyKeysFromUserImportData(userImportData: UserImportData): UserImportData {
  return {
    ..._.omitBy(userImportData, _.isEmpty),
    // make sure emailLogin is not filtered out if it was empty.
    emailLogin: userImportData.emailLogin,
  };
}

export function buildUserImportData(importData: string[][]): UserImportData[] {
  const [, ...data] = importData;
  const userImportData = data.map((row: string[]) => {
    const [firstName, lastName, emailLogin, customId, phoneNumber, role, type, category, groupId, subgroupName] = row;
    const user = {
      firstName,
      lastName,
      emailLogin,
      customId,
      phoneNumber,
      roles: role ? ([role] as UserRole[]) : [],
      type: type as UserType,
      category: category as UserCategory,
      groupId,
      subgroupName,
    };
    return removeEmptyKeysFromUserImportData(user);
  });

  return userImportData;
}
