import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { FieldValidation } from '@breathelife/types';
import { AutocompleteOption } from '@breathelife/ui-components';

import { EditorDropdown } from '../../../Components/EditorDropdown';

type ValidateAsInputProps = {
  value: FieldValidation;
  handleChange: (newValue: FieldValidation) => void;
  validationOptions: AutocompleteOption[];
};

export function ValidateAsInput({ value, handleChange, validationOptions }: ValidateAsInputProps): ReactElement {
  const { t } = useTranslation();
  return (
    <EditorDropdown
      id='addFieldValidationTypeDropdown'
      label={t('admin.questionnaireManagement.input.validationType')}
      selectedOptionValue={value}
      onChange={(value) => {
        if (value !== null) {
          handleChange(value as FieldValidation);
        }
      }}
      options={validationOptions}
    />
  );
}
