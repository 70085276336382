import { ReactElement } from 'react';

import { SvgIconProps } from '../../Components/Icons';

export function NotificationUnreadDotIcon(props: SvgIconProps): ReactElement {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props.svgProps}>
      <path
        d='M13 8C13 10.7613 10.7616 12.9998 8.00033 13C5.24013 12.9965 3.00343 10.7597 3 7.99945C3.0003 5.23828 5.23876 3 8 3C10.7614 3 13 5.23858 13 8Z'
        fill='#2F80ED'
        stroke='#ACCCF8'
        strokeWidth='2'
      />
    </svg>
  );
}
