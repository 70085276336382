import { useTranslation } from 'react-i18next';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { useDispatch } from 'react-redux';

import { logger } from '@breathelife/monitoring-frontend';
import { Questionnaire } from '@breathelife/types';

import { QueryId } from '../../../ReactQuery/common/common.types';
import { notificationSlice } from '../../../ReduxStore/Notification/NotificationSlice';
import { fetchAllQuestionnaires } from '../../../Services/QuestionnaireService';

export function useFetchAllQuestionnairesQuery(
  options?: UseQueryOptions<Questionnaire[]>,
): UseQueryResult<Questionnaire[]> {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return useQuery<Questionnaire[]>(QueryId.allQuestionnaires, () => fetchAllQuestionnaires(), {
    ...options,
    onError(err) {
      dispatch(
        notificationSlice.actions.setError({
          message: t('notifications.failedToFetchQuestionnaires'),
        }),
      );
      logger.error(err);
      options?.onError?.(err);
    },
  });
}
