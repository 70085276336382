import { AddressAutocompleteFieldBlueprint } from '@breathelife/types';

import { FieldDataSetterProps } from '../../../Helpers/helperTypes';
import { AddressAutocompleteFieldSetters, useAddressAutocompleteFieldData } from '../useAddressAutocompleteFieldData';
import { useCommonFieldDataSetters } from './useCommonFieldDataSetters';

export function AddressAutocompleteFieldDataSetter({
  initialData,
  dataLabelOptions,
  ...props
}: FieldDataSetterProps<AddressAutocompleteFieldBlueprint, AddressAutocompleteFieldSetters>): null {
  const { data, setters, validationOptions, defaultValueValidationError } = useAddressAutocompleteFieldData({
    initialData,
    dataLabelOptions,
  });

  useCommonFieldDataSetters<AddressAutocompleteFieldBlueprint, AddressAutocompleteFieldSetters>({
    ...props,
    data,
    setters,
    validationOptions,
    defaultValueValidationError,
    getIsValidBlueprint: (blueprint) =>
      !!(
        blueprint.validateAs &&
        blueprint.addressAutocompleteFields.city &&
        blueprint.addressAutocompleteFields.postalCodeOrZip &&
        blueprint.addressAutocompleteFields.stateOrProvince &&
        blueprint.countryCode
      ),
  });
  return null;
}
