// Selects a shade based on the default in the given palette and the provided adjustment
// Adjustment value must be divisible by ten
// Positive adjustment values darken, negative adjustment values lighten
export function getSupportingShade(colorPalette: Record<string, string>, adjustment: number): string | undefined {
  const defaultShade = Object.entries(colorPalette).find((color) => color[1] === colorPalette.default);
  if (Math.abs(adjustment) % 10 !== 0 || !defaultShade) return undefined;
  const shadeLevel = parseInt(defaultShade[0]) + adjustment;
  if (shadeLevel > 90) return colorPalette[90];
  if (shadeLevel < 10) return colorPalette[10];
  return colorPalette[shadeLevel];
}
