import { Box } from '@breathelife/mui';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { DetailViewType } from '../../../../Models/Layout';
import { ContentHeading, CtaButton } from './Styles';

type UserDetailHeadingProps = {
  isFormValid: boolean;
  viewType: DetailViewType;
  onClose: () => void;
  onCreateClick: () => void;
  onSaveClick: () => void;
};

export function UserDetailContentHeader(props: UserDetailHeadingProps): ReactElement {
  const { t } = useTranslation();

  return (
    <ContentHeading width='100%' display='flex' justifyContent='flex-end' alignItems='center' py={2} px={2.5} mb={2}>
      <Box component='span' mr={1}>
        <CtaButton color='primary' variant='outlined' onClick={props.onClose}>
          {t('cta.cancel')}
        </CtaButton>
      </Box>

      <CtaButton
        data-testid='UserDetailView-save'
        color='primary'
        variant='contained'
        disabled={!props.isFormValid}
        onClick={props.viewType === DetailViewType.create ? props.onCreateClick : props.onSaveClick}
      >
        {t('cta.save')}
      </CtaButton>
    </ContentHeading>
  );
}
