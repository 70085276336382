import { ReactElement } from 'react';

import { RenderingSection, RenderingSectionGroup } from '@breathelife/questionnaire-engine';

import { Section } from './Section';
import { SectionGroupContainer } from './Styles';

type SectionGroupProps = {
  sectionGroup: RenderingSectionGroup;
};

export function SectionGroup(props: SectionGroupProps): ReactElement | null {
  const { sectionGroup } = props;
  return (
    <SectionGroupContainer mt={3} pb={3}>
      {sectionGroup.sections.map((section: RenderingSection) => (
        <Section key={section.id} section={section} />
      ))}
    </SectionGroupContainer>
  );
}
