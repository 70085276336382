import { ReactElement } from 'react';

import { Divider, Link, Menu, MenuProps, ListItemIcon, Tooltip } from '@breathelife/mui';

import Typography from '../../Components/Typography';
import { MenuItem } from './Styles';

export type MenuAction = {
  tag: 'MenuAction';
  onClick: () => void;
  icon: ReactElement;
  label: string;
  disabled?: boolean;
  linkProperties?: Record<string, any>;
  ['data-testid']?: string;
  tooltip?: string;
};

export type MenuDivider = {
  tag: 'MenuDivider';
};

export type MenuElement = MenuAction | MenuDivider;

type Props = MenuProps & { items: MenuElement[] };

export function PopupMenu(props: Props): ReactElement {
  const { items, ...restProps } = props;

  return (
    <Menu {...restProps}>
      {items.map((item: MenuElement, index: number) => {
        switch (item.tag) {
          case 'MenuDivider':
            return <Divider key={`MenuDivider-${index}`} />;
          case 'MenuAction':
            const component = item.linkProperties ? { component: Link } : {};
            const menuItem = (
              <MenuItem
                key={`MenuItem-${index}`}
                onClick={item.onClick}
                disabled={item.disabled}
                data-testid={item['data-testid']}
                {...component}
                {...item.linkProperties}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <Typography variant='body3' grey={90}>
                  {item.label}
                </Typography>
              </MenuItem>
            );

            if (item.tooltip)
              return (
                <Tooltip title={item.tooltip} key={`MenuItemTooltip-${index}`}>
                  <div>{menuItem}</div>
                </Tooltip>
              );
            return menuItem;
        }
      })}
    </Menu>
  );
}
