import { Dispatch, SetStateAction } from 'react';

import {
  isRenderingRepeatedSectionGroup,
  RenderingQuestionnaire,
  RenderingSection,
  RenderingSectionGroup,
} from '@breathelife/questionnaire-engine';
import { SubsectionVariant } from '@breathelife/types';

import { SectionGroupId } from '../../Helpers/questionnaireAnswers';

export enum Direction {
  next = 'next',
  previous = 'previous',
}

export type SectionGroupIndices = {
  id: string;
  indices: number[];
};

export type SectionGroupSelectionProps = {
  sectionGroup: RenderingSectionGroup;
  visitedSectionIndicesByGroup?: SectionGroupIndices[];
};

export type SectionNavigationData = {
  index: number;
  groupId: SectionGroupId;
  proposedInsuredIndex?: number;
  section?: RenderingSection;
};

export type NavigationRenderingSection = RenderingSection & { visited: boolean };

export type VisitedSectionProps = {
  activeSectionIndex: number;
  sectionIndicesByGroupId: string;
  setVisitedSectionsIndicesByGroup: Dispatch<SetStateAction<SectionGroupIndices[] | undefined>>;
  visitedSectionIndicesByGroup?: SectionGroupIndices[];
};

function containsSubsectionVariant(section: RenderingSection, variant: SubsectionVariant): boolean {
  return section.subsections.map((subsection) => subsection.variant).includes(variant);
}

// Retrieves the section group from the rendering questionnaire based on the section group id and proposed insured id
export function findSectionGroup(
  renderingQuestionnaire: RenderingQuestionnaire,
  currentSectionGroupId: SectionGroupId,
  currentSelectedProposedInsuredIndex?: number,
): RenderingSectionGroup | undefined {
  return renderingQuestionnaire.find((renderingSectionGroup) => {
    if (
      currentSectionGroupId === SectionGroupId.insuredPeople &&
      isRenderingRepeatedSectionGroup(renderingSectionGroup) &&
      renderingSectionGroup.metadata.parentId === SectionGroupId.insuredPeople
    ) {
      return renderingSectionGroup.metadata.repetitionIndex === currentSelectedProposedInsuredIndex;
    }

    return renderingSectionGroup.id === currentSectionGroupId;
  });
}

// Generates id from section group id and proposed insured surrogateId that is used for storing and retrieving visited section indices
export function getSectionGroupWithIndicesId(sectionGroupId: SectionGroupId, surrogateId: string): string {
  return sectionGroupId === SectionGroupId.insuredPeople && !!surrogateId
    ? sectionGroupId + '-' + surrogateId
    : sectionGroupId;
}

// Retrieves sections for a particular section group and computes visited and completed field values
export function getSectionsForSectionGroup(
  sectionGroupSections: RenderingSection[],
  visitedSectionIndices: number[],
  payments?: { isPaymentsEnabled: boolean; isPaymentReadyToSubmit?: boolean },
): NavigationRenderingSection[] {
  const { isPaymentsEnabled = false, isPaymentReadyToSubmit = false } = payments ?? {};

  return sectionGroupSections
    .filter((section) => section.visible)
    .map((section, sectionIndex) => {
      let completed = section.completed;

      if (isPaymentsEnabled && containsSubsectionVariant(section, SubsectionVariant.payment)) {
        completed = completed && isPaymentReadyToSubmit;
      }

      return {
        ...section,
        visited: visitedSectionIndices.includes(sectionIndex),
        completed,
      };
    });
}

// Checks to see if current section index has been added to the visitedSectionIndicesByGroup collection and adds it if not
export function updateVisitedSectionIndices(visitedSectionProps: VisitedSectionProps): void {
  const {
    activeSectionIndex,
    sectionIndicesByGroupId,
    setVisitedSectionsIndicesByGroup,
    visitedSectionIndicesByGroup,
  } = visitedSectionProps;

  // First index to be added
  if (!visitedSectionIndicesByGroup) {
    return setVisitedSectionsIndicesByGroup([{ id: sectionIndicesByGroupId, indices: [activeSectionIndex] }]);
  }

  const sectionGroup = visitedSectionIndicesByGroup.find((sectionGroup) => sectionGroup.id === sectionIndicesByGroupId);

  // First index in section group to be added
  if (!sectionGroup) {
    setVisitedSectionsIndicesByGroup((prevState) => [
      ...(prevState || []),
      { id: sectionIndicesByGroupId, indices: [activeSectionIndex] },
    ]);

    // New index to add in section group
  } else if (!sectionGroup.indices.includes(activeSectionIndex)) {
    sectionGroup.indices.push(activeSectionIndex);
    setVisitedSectionsIndicesByGroup((prevState) => {
      return prevState?.map((group) => (group.id === sectionIndicesByGroupId ? sectionGroup : group));
    });
  }
}
