import { Fragment, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Divider, Skeleton, Chip } from '@breathelife/mui';
import { Theme } from '@breathelife/types';

import { ExportButton } from '../../../Components/ExportData/ExportButton';
import { ExportDataProvider } from '../../../Components/ExportData/ExportDataProvider';
import { ImportButton } from '../../../Components/ExportData/ImportButton';
import { ImportDataProvider } from '../../../Components/ExportData/ImportDataProvider';
import { ImportModal } from '../../../Components/ExportData/ImportModal';
import { HelmetContainer } from '../../../Components/HelmetContainer';
import Typography from '../../../Components/Typography';
import { useCarrierContext, useDispatch, useSelector } from '../../../Hooks';
import { Select } from '../../../Components/Select/Select';
import { useFetchAllThemesQuery } from '../../../ReactQuery/Themes/themes.queries';
import { useUpdateThemeMutation } from '../../../ReactQuery/Themes/themes.mutations';
import { ActionButton } from '../../../Components/Button/ActionButton';
import { ColorRangesEditView } from './ColorRangesEditView';
import { LogoUploadView } from './LogoUploadView';
import { SidebarVariantEditView } from './SidebarVariantEditView';
import { getFieldValidationError } from '../../../Helpers/inputValidation/form/theme';
import { themeSlice } from '../../../ReduxStore/Admin/ThemeManagement/ThemeSlice';

export function ThemeManagementViewContainer(): ReactElement {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { features } = useCarrierContext();
  const { theme } = useSelector((store) => store.leadPlatform.theme);

  const isMultiBrandingEnabled = features.multiBranding.enabled;
  const {
    data: themes,
    isFetching: isFetchingThemes,
    refetch: refetchThemes,
  } = useFetchAllThemesQuery({ enabled: !!isMultiBrandingEnabled });

  const [selectedTheme, setSelectedTheme] = useState<Theme | undefined>();
  const [hasValidationError, setHasValidationError] = useState<boolean>(false);
  const [updatedThemeData, setUpdatedThemeData] = useState<Partial<Theme>>({});

  const updateThemeMutation = useUpdateThemeMutation({
    onSuccess(data) {
      if (isMultiBrandingEnabled) void refetchThemes();
      if (data.isDefault) dispatch(themeSlice.actions.setTheme({ theme: data }));
    },
  });

  useEffect(() => {
    if (isMultiBrandingEnabled && themes) {
      if (!selectedTheme) {
        const newTheme = themes.find((theme) => {
          return theme.isDefault;
        });
        setSelectedTheme(newTheme);
      } else {
        const newTheme = themes.find((theme) => {
          return theme.id === selectedTheme.id;
        });
        setSelectedTheme(newTheme);
      }
    } else if (theme) {
      setSelectedTheme(theme);
    }
  }, [themes, selectedTheme, theme, isMultiBrandingEnabled]);

  useEffect(() => {
    if (updatedThemeData) {
      const hasError = Object.keys(updatedThemeData).filter((key) => {
        return getFieldValidationError(key, updatedThemeData[key]);
      });

      if (hasError.length) {
        setHasValidationError(true);
      } else {
        setHasValidationError(false);
      }
    }
  }, [updatedThemeData]);

  const onSelectTheme = useCallback(
    (themeId: string) => {
      const newSelectedTheme = themes?.find((theme) => {
        return theme.id === themeId;
      });
      setSelectedTheme(newSelectedTheme);
    },
    [setSelectedTheme, themes],
  );

  const onUpdateTheme = useCallback(
    async (selectedTheme: Theme) => {
      updateThemeMutation.mutate({ themeId: selectedTheme.id, data: updatedThemeData });
    },
    [updateThemeMutation, updatedThemeData],
  );

  const themeOptions = useMemo(() => {
    return (
      themes?.map((theme) => {
        return {
          value: theme.id,
          label: theme.userGroup ? theme.userGroup[0].name : '',
        };
      }) || []
    );
  }, [themes]);

  return (
    <Fragment>
      <HelmetContainer text={t('pageTitles.themeManagement')} />
      <Box display='flex' flexDirection='column' flexGrow={1}>
        <Box m={2} display='flex' justifyContent='space-between'>
          <Box display='flex' flexDirection='column'>
            <div>
              <Typography variant='h1'>{t('admin.themeManagement.title')}</Typography>
              <Typography variant='body1'>{t('admin.themeManagement.subtitle')}</Typography>
            </div>
            {isMultiBrandingEnabled && (
              <Box display='flex' alignItems='center' mt={2}>
                <Select
                  isSearchFilter
                  id='questionnaire-select'
                  label={t('admin.themeManagement.themeProfile')}
                  value={selectedTheme?.id || ''}
                  options={themeOptions}
                  onChange={onSelectTheme}
                />

                {selectedTheme?.isDefault && (
                  <Box ml={2}>
                    <Chip label={t('admin.themeManagement.themeDefault')} color='secondary' />
                  </Box>
                )}
                <Divider />
              </Box>
            )}
          </Box>
          {selectedTheme && (
            <Box display='flex' alignItems='end'>
              <Box display='flex' mr={2}>
                <Box mr={1}>
                  <ExportDataProvider theme themeId={selectedTheme.id}>
                    <ExportButton />
                  </ExportDataProvider>
                </Box>
                <ImportDataProvider theme themeId={selectedTheme.id}>
                  <ImportButton />
                  <ImportModal
                    title={
                      selectedTheme?.userGroup
                        ? t('admin.importData.userGroupTheme', { userGroup: selectedTheme?.userGroup[0].name })
                        : t('admin.importData.theme')
                    }
                  />
                </ImportDataProvider>
              </Box>
              <ActionButton
                data-testid='theme-save'
                color='primary'
                variant='contained'
                onClick={() => onUpdateTheme(selectedTheme)}
                disabled={!!hasValidationError || updateThemeMutation.isLoading}
              >
                {t('cta.save')}
              </ActionButton>
            </Box>
          )}
        </Box>
        <Divider />
        <Box m={2}>
          {(isFetchingThemes || !selectedTheme) && <Skeleton variant='rectangular' height={50} />}
          {!isFetchingThemes && selectedTheme && (
            <Fragment>
              <ColorRangesEditView
                colorRanges={selectedTheme.colorRanges}
                updatedThemeData={updatedThemeData}
                setUpdatedThemeData={setUpdatedThemeData}
              />
              <LogoUploadView
                logoImgUrl={selectedTheme.logoImgUrl}
                compactLogoImgUrl={selectedTheme.compactLogoImgUrl}
                updatedThemeData={updatedThemeData}
                setUpdatedThemeData={setUpdatedThemeData}
              />
              <SidebarVariantEditView
                navigationSidebarVariant={selectedTheme.navigationSidebarVariant}
                updatedThemeData={updatedThemeData}
                setUpdatedThemeData={setUpdatedThemeData}
              />
            </Fragment>
          )}
        </Box>
      </Box>
    </Fragment>
  );
}
