import { useState } from 'react';
import { ValidationError } from 'yup';

import { Validations } from '@breathelife/questionnaire-engine';
import { FieldBlueprint, FieldTypes, NumberFieldBlueprint, NumberFieldValidation } from '@breathelife/types';
import { AutocompleteOption } from '@breathelife/ui-components';

import {
  FieldDataHookReturnValueWithValidationOptions,
  FieldSettersValidateAs,
  useCastBlueprintToFieldType,
} from '../../Helpers/helperTypes';
import { BaseFieldSetters, useBaseFieldData } from './useBaseFieldData';
import { useDefaultValue } from './useDefaultValue';
import { useValidateAsData } from './useValidateAsData';

export type NumberFieldData = Pick<NumberFieldBlueprint, 'numericalDataType' | 'validateAs' | 'defaultValue'>;

export type NumberFieldSetters = FieldSettersValidateAs<NumberFieldData> & {
  setNumericalDataType: React.Dispatch<React.SetStateAction<NumberFieldData['numericalDataType']>>;
  setDefaultValue: React.Dispatch<React.SetStateAction<NumberFieldData['defaultValue']>>;
} & BaseFieldSetters;

const defaultProperties = {
  validateAs: NumberFieldValidation.decimal,
} as const;

export function useNumberFieldData({
  initialData,
  dataLabelOptions,
}: {
  dataLabelOptions: AutocompleteOption[];
  initialData?: Partial<FieldBlueprint>;
}): FieldDataHookReturnValueWithValidationOptions<NumberFieldBlueprint, NumberFieldSetters> & {
  defaultValueValidationError?: ValidationError;
} {
  const initialDataAsNumberField = useCastBlueprintToFieldType<NumberFieldBlueprint>(FieldTypes.number, initialData);

  const [numericalDataType, setNumericalDataType] = useState<NumberFieldData['numericalDataType']>(
    initialDataAsNumberField?.numericalDataType,
  );

  const { data, setters } = useBaseFieldData({ initialData, dataLabelOptions });

  const {
    data: validateAsData,
    setters: validateAsSetters,
    validationOptions,
  } = useValidateAsData<NumberFieldBlueprint>({
    fieldType: FieldTypes.number,
    initialValidateAs: defaultProperties.validateAs,
  });

  const {
    data: defaultValueData,
    setters: defaultValueSetters,
    validationError: defaultValueValidationError,
  } = useDefaultValue<NumberFieldBlueprint>({
    validateAs: validateAsData.validateAs as unknown as Validations, // TODO create mapper here...
  });

  return {
    data: {
      ...data,
      fieldType: FieldTypes.number,
      numericalDataType,
      ...validateAsData,
      ...defaultValueData,
    },
    setters: {
      ...setters,
      setNumericalDataType,
      ...validateAsSetters,
      ...defaultValueSetters,
    },
    validationOptions,
    defaultValueValidationError,
  };
}
