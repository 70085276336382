import { useTranslation } from 'react-i18next';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { useDispatch } from 'react-redux';

import { logger } from '@breathelife/monitoring-frontend';
import { ApiESignAdHocBaseRequest, ApiESignAdHocFullRequest } from '@breathelife/types';

import { QueryId } from '../../../ReactQuery/common/common.types';
import { notificationSlice } from '../../../ReduxStore/Notification/NotificationSlice';
import {
  findAllAddHocSignatureRequests,
  getAdHocSignatureRequest,
} from '../../../Services/AdHocSignatureRequestsService';

export function useFetchAllAdHocSignatureRequestsQuery(
  options?: UseQueryOptions<ApiESignAdHocBaseRequest[]>,
): UseQueryResult<ApiESignAdHocBaseRequest[]> {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return useQuery<ApiESignAdHocBaseRequest[]>(
    QueryId.allAdHocSignatureRequests,
    () => findAllAddHocSignatureRequests(),
    {
      ...options,
      onError(err) {
        dispatch(
          notificationSlice.actions.setError({
            message: t('notifications.failedToFetchAllAdHocSignatureRequests'),
          }),
        );
        logger.error(err);
        options?.onError?.(err);
      },
    },
  );
}

export function useGetAdHocSignatureRequestQuery(
  adHocSignatureRequestId: string,
  options?: UseQueryOptions<ApiESignAdHocFullRequest | undefined>,
): UseQueryResult<ApiESignAdHocFullRequest | undefined> {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return useQuery<ApiESignAdHocFullRequest | undefined>(
    [QueryId.adHocSignatureRequest, adHocSignatureRequestId],
    () => getAdHocSignatureRequest(adHocSignatureRequestId),
    {
      ...options,
      onError(err) {
        dispatch(
          notificationSlice.actions.setError({
            message: t('notifications.failedToFetchAllAdHocSignatureRequest'),
          }),
        );
        logger.error(err);
        options?.onError?.(err);
      },
    },
  );
}
