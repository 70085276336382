import { ReactElement, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid } from '@breathelife/mui';
import { Language, Localizable } from '@breathelife/types';

import { useCarrierContext } from '../../../../../../../Hooks';
import { TextInput } from '../../../Components/TextInput';

type TextPropertyInAllLanguagesInputsProps = {
  value?: Partial<Localizable>;
  handleChange: (language: Language, value: string) => void;
  helperTooltipTextKey?: string;
  fieldName: string;
  labelKey: string;
};

function TextPropertyInAllLanguagesInputs({
  value,
  handleChange,
  helperTooltipTextKey,
  fieldName,
  labelKey,
}: TextPropertyInAllLanguagesInputsProps): ReactElement {
  const { languageSettings } = useCarrierContext();
  const enabledLanguages = languageSettings.enabledLanguages;
  const { t } = useTranslation();
  return (
    <Fragment>
      {enabledLanguages.map((language) => (
        <Grid item xs={6} key={`createField-${fieldName}-${language}`}>
          <TextInput
            label={t(labelKey, { language: t(`language.${language}`) })}
            value={value?.[language] ?? ''}
            onChange={(event) => {
              const value = event.target.value;
              handleChange(language, value);
            }}
            helperTooltipText={helperTooltipTextKey ? t(helperTooltipTextKey) : undefined}
          />
        </Grid>
      ))}
    </Fragment>
  );
}

export const TitleInAllLanguagesInputs = (
  props: Pick<TextPropertyInAllLanguagesInputsProps, 'value' | 'handleChange' | 'helperTooltipTextKey'>,
): ReactElement =>
  TextPropertyInAllLanguagesInputs({
    ...props,
    fieldName: 'title',
    labelKey: 'admin.questionnaireManagement.input.titleAndLanguage',
  });

export const TextInAllLanguagesInputs = (
  props: Pick<TextPropertyInAllLanguagesInputsProps, 'value' | 'handleChange' | 'helperTooltipTextKey'>,
): ReactElement =>
  TextPropertyInAllLanguagesInputs({
    ...props,
    fieldName: 'text',
    labelKey: 'admin.questionnaireManagement.input.textAndLanguage',
  });

export const PlaceholderInAllLanguagesInputs = (
  props: Pick<TextPropertyInAllLanguagesInputsProps, 'value' | 'handleChange' | 'helperTooltipTextKey'>,
): ReactElement =>
  TextPropertyInAllLanguagesInputs({
    ...props,
    fieldName: 'placeholder',
    labelKey: 'admin.questionnaireManagement.input.placeholderAndLanguage',
  });

export const ButtonTextInAllLanguagesInputs = (
  props: Pick<TextPropertyInAllLanguagesInputsProps, 'value' | 'handleChange' | 'helperTooltipTextKey'>,
): ReactElement =>
  TextPropertyInAllLanguagesInputs({
    ...props,
    fieldName: 'buttonText',
    labelKey: 'admin.questionnaireManagement.input.buttonTextAndLanguage',
  });
