import i18next from 'i18next';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { ApplicationSupportESignatureCeremony } from '@breathelife/types';

import { useDispatch } from '../../../Hooks';
import { notificationSlice } from '../../../ReduxStore/Notification/NotificationSlice';
import { getApplicationSupportESignatureCeremonies } from '../../../Services/ApplicationSupportService';
import { QueryId } from '../../common/common.types';

export function useGetApplicationSupportESignatureCeremoniesQuery(
  applicationId: string,
  options?: UseQueryOptions<ApplicationSupportESignatureCeremony[], Error>,
): UseQueryResult<ApplicationSupportESignatureCeremony[], Error> {
  const dispatch = useDispatch();

  return useQuery<ApplicationSupportESignatureCeremony[], Error>(
    [QueryId.applicationSupportESignCeremonies, applicationId],
    async () => await getApplicationSupportESignatureCeremonies(applicationId),
    {
      ...options,
      onError: (error) => {
        options?.onError?.(error);
        dispatch(
          notificationSlice.actions.setError({
            message: i18next.t('notifications.failedToFetchESignatureCeremonies'),
          }),
        );
      },
    },
  );
}
