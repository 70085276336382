import { IconButton } from '@breathelife/mui';

import styled from '../../../../../Styles/themed-styled-components';

export const HeaderButton = styled(IconButton)`
  &&& {
    background-color: ${(props) => props.theme.colors.grey[20]};

    &:hover {
      background-color: ${(props) => props.theme.colors.grey[40]};
    }
  }
`;
