import i18next from 'i18next';
import * as yup from 'yup';

import { isFileNameValid } from '../../../Helpers/files/validateFileName';

// Source https://regex101.com/r/wUJRj6/15
export const nameRegex =
  /^((([a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u024F]([\.]?))+?)(([\s'-][a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u024F]([\.]?))+)*?){0,}$/;

export const getFirstNameSchema = (): yup.StringSchema =>
  yup
    .string()
    .trim()
    .matches(nameRegex, { message: i18next.t('validation.error.fname') });

export const getLastNameSchema = (): yup.StringSchema =>
  yup
    .string()
    .trim()
    .matches(nameRegex, { message: i18next.t('validation.error.lname') });

export const getPreferredNameSchema = (): yup.StringSchema =>
  yup
    .string()
    .trim()
    .matches(nameRegex, { message: i18next.t('validation.error.pname') });

export const getDefaultNameSchema = (): yup.StringSchema =>
  yup
    .string()
    .test('fileNameCheck', i18next.t('validation.error.fileName'), (value) => isFileNameValid(value))
    .trim()
    .typeError(i18next.t('validation.error.fileName'));
