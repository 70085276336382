import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertTitle, Box } from '@breathelife/mui';
import { Conditions, ExpandableFileTemplateRule, PatchFileTemplateRuleData } from '@breathelife/types';

import { Alert } from '../../../../../Components/Alert/Alert';
import { SubmitButton } from '../../../../../Components/Button/SubmitButton';
import {
  useDeleteFileTemplateRuleMutation,
  usePatchFileTemplateRuleMutation,
} from '../../../../../ReactQuery/Document/DocumentRule/documentRule.mutations';
import { notificationSlice } from '../../../../../ReduxStore/Notification/NotificationSlice';

type EditDocumentRuleProps = {
  fileTemplateRule: ExpandableFileTemplateRule;
};

export function EditDocumentRule(props: EditDocumentRuleProps): ReactElement {
  const initialConditionState = JSON.stringify(props.fileTemplateRule.condition);
  const [conditionState, setConditionsState] = useState(initialConditionState);
  const [validationState, setValidationState] = useState<null | string>(null);
  const { t } = useTranslation();

  const patchFileTemplateRuleMutation = usePatchFileTemplateRuleMutation({
    onError: () => {
      notificationSlice.actions.setError({
        message: t('notifications.failedToUpdateEntity', {
          entity: t('admin.documentDetailManagement.documentRule.singular'),
        }),
      });
    },
  });

  const deleteFileTemplateRuleMutation = useDeleteFileTemplateRuleMutation(
    {
      questionnaireVersionId: props.fileTemplateRule.questionnaireVersionId,
      templateId: props.fileTemplateRule.templateId,
    },
    {
      onError: () => {
        notificationSlice.actions.setError({
          message: t('notifications.failedToUpdateEntity', {
            entity: t('admin.documentDetailManagement.documentRule.singular'),
          }),
        });
      },
    },
  );

  const editDocumentRule = async (): Promise<void> => {
    if (conditionState.trim().length < 1) {
      return await deleteFileTemplateRuleMutation.mutateAsync(props.fileTemplateRule.id);
    }

    // TODO: DEV-10157 - construct JSON using GUI
    let condition;
    try {
      condition = JSON.parse(conditionState.trim()) as Conditions;
      setValidationState(null);
    } catch (err: any) {
      setValidationState('Invalid condition');
      return;
    }

    const data: PatchFileTemplateRuleData = {
      ...props.fileTemplateRule,
      condition,
    };
    await patchFileTemplateRuleMutation.mutateAsync({ id: props.fileTemplateRule.id, data });
  };

  useEffect(() => {
    setConditionsState(initialConditionState);
  }, [initialConditionState]);

  return (
    <Box p={2}>
      <Box>
        {validationState && (
          <Alert severity={'error'} onClose={() => setValidationState(null)}>
            <AlertTitle>{t('alerts.errorDetails')}</AlertTitle>
            {validationState}
          </Alert>
        )}
      </Box>

      <Box>
        <textarea
          disabled={patchFileTemplateRuleMutation.isLoading || deleteFileTemplateRuleMutation.isLoading}
          rows={10}
          onChange={(event) => setConditionsState(event.target.value)}
          cols={75}
          value={conditionState}
        />
      </Box>
      <Box display='flex' justifyContent='flex-end'>
        <SubmitButton
          data-testid='edit-document-rule-button'
          isLoading={patchFileTemplateRuleMutation.isLoading || deleteFileTemplateRuleMutation.isLoading}
          onClick={editDocumentRule}
        >
          {t('cta.save')}
        </SubmitButton>
      </Box>
    </Box>
  );
}
