import _ from 'lodash';

import { logger } from '@breathelife/monitoring-frontend';
import { TypewriterTracking } from '@breathelife/frontend-tracking';
import { User } from '@breathelife/types';

import { acronymize } from '../../Helpers/acronymize';
import { getLanguageRegion } from '../../Localization/utils';
import { notificationSlice } from '../../ReduxStore/Notification/NotificationSlice';
import { Dispatch, LeadPlatformStore } from '../../ReduxStore/types';
import { isSsoUser, logoutAgent } from '../../Services/Auth0';
import { syncUser } from '../../Services/SyncService';
import { fetchUserPermissions } from '../../Services/Users/PermissionsService';
import { authenticationSlice } from './AuthenticationSlice';

const { actions: authenticationActions } = authenticationSlice;

async function trackUserAuthentication(user: User, companyName?: string): Promise<void> {
  const locale = getLanguageRegion();

  if (!companyName) {
    return;
  }

  const groupId = `${_.snakeCase(companyName)}_user_group`;
  const mgaId = user.groupId ?? (user.groupName && acronymize(user.groupName));

  try {
    await new Promise<void>((resolve) => {
      TypewriterTracking.identify(
        {
          userId: user.auth0Id,
          traits: {
            userType: user.roles.join(','),
            locale,
          },
        },
        resolve,
      );
    });

    await new Promise<void>((resolve) => {
      TypewriterTracking.group(groupId, undefined, resolve);
    });

    await new Promise<void>((resolve) => {
      TypewriterTracking.loggedIn({ organizationId: mgaId }, undefined, resolve);
    });
  } catch (error: any) {
    logger.error(new Error(`Authentication tracking error: ${error.message}`));
  }
}

export const authenticate = (token: string, user: User, companyName?: string) => async (dispatch: Dispatch) => {
  try {
    const permissions = await fetchUserPermissions();
    const userWithPermissions: User = { ...user, permissions };

    dispatch(authenticationActions.authenticate({ token, user: userWithPermissions }));

    await trackUserAuthentication(user, companyName);
  } catch (e: any) {
    dispatch(notificationSlice.actions.setError({ message: e.message }));
  }
};

export const logout = () => async (dispatch: Dispatch, store: () => LeadPlatformStore) => {
  try {
    const user = store().leadPlatform.authentication.user;
    const isSso = user ? isSsoUser(user) : false;
    TypewriterTracking.loggedOut({});
    logoutAgent(isSso);
  } catch (e: any) {
    dispatch(notificationSlice.actions.setError({ message: e.message }));
  }

  return;
};

export const regularAuthenticate =
  (token: string, auth0User: Pick<User, 'auth0Id'>, lang?: string, companyName?: string) =>
  async (dispatch: Dispatch) => {
    try {
      const user = await syncUser(auth0User.auth0Id, lang);
      await authenticate(token, user, companyName)(dispatch);
    } catch (e: any) {
      dispatch(notificationSlice.actions.setError({ message: e.message }));
    }
  };

export const ssoAuthenticate =
  (token: string, auth0User: Pick<User, 'auth0Id'>, lang?: string, companyName?: string) =>
  async (dispatch: Dispatch) => {
    try {
      const user = await syncUser(auth0User.auth0Id, lang);
      await authenticate(token, user, companyName)(dispatch);
    } catch (err: any) {
      dispatch(notificationSlice.actions.setError({ message: err.message }));
      return;
    }
  };
