import { useTranslation } from 'react-i18next';
import { UseMutationOptions, UseMutationResult, useMutation } from 'react-query';

import { Notification } from '@breathelife/types';

import { useDispatch } from '../../Hooks';
import { notificationSlice } from '../../ReduxStore/Notification/NotificationSlice';
import { updateAllNotifications, updateNotification } from '../../Services/NotificationCentreService';

type UpdateNotificationParams = { id: string; data: Partial<Notification> };
type UpdateNotificationMutation = UseMutationResult<Notification, unknown, UpdateNotificationParams>;

export function useUpdateNotificationMutation(
  options?: UseMutationOptions<Notification, unknown, UpdateNotificationParams>,
): UpdateNotificationMutation {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return useMutation<Notification, unknown, UpdateNotificationParams>(({ id, data }) => updateNotification(id, data), {
    ...options,
    onError: async () => {
      dispatch(
        notificationSlice.actions.setError({
          message: t('notificationCentre.errors.updateNotificationError'),
        }),
      );
    },
  });
}

type UpdateAllNotificationsParams = { data: Partial<Notification> };
type UpdateAllNotificationsMutation = UseMutationResult<Notification[], unknown, UpdateAllNotificationsParams>;

export function useUpdateAllNotificationsMutation(
  options?: UseMutationOptions<Notification[], unknown, UpdateAllNotificationsParams>,
): UpdateAllNotificationsMutation {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return useMutation<Notification[], unknown, UpdateAllNotificationsParams>(
    ({ data }) => updateAllNotifications(data),
    {
      ...options,
      onError: async () => {
        dispatch(
          notificationSlice.actions.setError({
            message: t('notificationCentre.errors.updateNotificationsError'),
          }),
        );
      },
    },
  );
}
