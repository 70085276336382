import dayjs from 'dayjs';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '../../../../../Components/Typography';
import { setMonthShorthandToEnglishFormat } from '../../../../../Helpers/datetime';
import { Lead } from '../../../../../Models/Lead';
import { DateTextContainer } from '../Styles';

setMonthShorthandToEnglishFormat();

export type Props = {
  lead?: Lead;
};

const UPDATE_THRESHOLD_SECONDS = 1;
const DATE_FORMAT = 'MMM D, YYYY';

export const DateText: FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const [hasUpdates, setHasUpdates] = useState<boolean>(false);
  const [date, setDate] = useState<string>('');
  const isNewLead = !props.lead?.createdAt && !props.lead?.updatedAt;

  useEffect(() => {
    const leadCreatedAt = props.lead?.createdAt || new Date();
    const leadUpdatedAt = props.lead?.updatedAt || new Date();

    const formattedDate = dayjs(leadUpdatedAt).format(DATE_FORMAT);
    setDate(formattedDate);

    // createdAt and updatedAt usually have a few ms of differences. If they have less than 1 second of difference, we can
    // safely assume that there was no update
    const timeDifference = dayjs(leadUpdatedAt).diff(leadCreatedAt, 'second');
    setHasUpdates(timeDifference > UPDATE_THRESHOLD_SECONDS);
  }, [props.lead]);

  return (
    <DateTextContainer>
      <Typography variant='body3' grey={70}>
        {!isNewLead && `${hasUpdates ? t('lastUpdateOn') : t('createdOn')} ${date}`}
      </Typography>
    </DateTextContainer>
  );
};
