import { ReactElement } from 'react';

import { FieldBlueprint, FieldTypes } from '@breathelife/types';

import { FieldDataSetterProps, FieldSetters } from '../../../Helpers/helperTypes';
import { AddressAutocompleteFieldDataSetter } from './AddressAutocompleteDataSetter.tsx';
import { AgreeFieldDataSetter } from './AgreeFieldDataSetter';
import { ButtonFieldDataSetter } from './ButtonFieldDataSetter';
import { CheckboxFieldDataSetter } from './CheckboxFieldDataSetter';
import { CheckboxGroupFieldDataSetter } from './CheckboxGroupFieldDataSetter';
import { CurrencyCardFieldDataSetter } from './CurrencyCardFieldDataSetter';
import { DateFieldDataSetter } from './DateFieldDataSetter';
import { DropdownFieldDataSetter } from './DropdownFieldDataSetter';
import { InformationFieldDataSetter } from './InformationFieldDataSetter';
import { InputFieldDataSetter } from './InputFieldDataSetter';
import { MoneyFieldDataSetter } from './MoneyFieldDataSetter';
import { NumberFieldDataSetter } from './NumberFieldDataSetter';
import { PhoneFieldDataSetter } from './PhoneFieldDataSetter';
import { RadioFieldDataSetter } from './RadioFieldDataSetter';
import { SignatureFieldDataSetter } from './SignatureFieldDataSetter';
import { TextareaFieldDataSetter } from './TextareaFieldDataSetter';

type Props = FieldDataSetterProps<FieldBlueprint, FieldSetters> & {
  fieldType: FieldTypes;
};

export function FieldDataSetter({ fieldType, ...props }: Props): ReactElement | null {
  const getComponent = (): ((props: Omit<Props, 'fieldType'>) => ReactElement | null) | null => {
    switch (fieldType) {
      case FieldTypes.autocomplete:
        return AddressAutocompleteFieldDataSetter;
      case FieldTypes.agree:
        return AgreeFieldDataSetter;
      case FieldTypes.button:
        return ButtonFieldDataSetter;
      case FieldTypes.checkbox:
        return CheckboxFieldDataSetter;
      case FieldTypes.checkboxGroup:
        return CheckboxGroupFieldDataSetter;
      case FieldTypes.currencyCard:
        return CurrencyCardFieldDataSetter;
      case FieldTypes.date:
        return DateFieldDataSetter;
      case FieldTypes.dropdown:
        return DropdownFieldDataSetter;
      case FieldTypes.information:
        return InformationFieldDataSetter;
      case FieldTypes.input:
        return InputFieldDataSetter;
      case FieldTypes.money:
        return MoneyFieldDataSetter;
      case FieldTypes.number:
        return NumberFieldDataSetter;
      case FieldTypes.phone:
        return PhoneFieldDataSetter;
      case FieldTypes.radio:
        return RadioFieldDataSetter;
      case FieldTypes.signature:
        return SignatureFieldDataSetter;
      case FieldTypes.textarea:
        return TextareaFieldDataSetter;
      case FieldTypes.yearMonth:
        return null;
    }
  };

  const Component = getComponent();
  if (!Component) return null;

  return <Component {...props} />;
}
