import { LeadAccessTokenData } from '@breathelife/types';

import ApiService from './ApiService';

export async function generatePrivateLinkToken(leadId: number): Promise<LeadAccessTokenData> {
  const response = await ApiService.generatePrivateLinkToken(leadId);
  return response.data;
}

export async function getPublicAccessLink(): Promise<LeadAccessTokenData> {
  const response = await ApiService.getPublicAccessLink();
  return response.data || [];
}

export async function getCoBrowsingAccessLinkToken(appId: string): Promise<string> {
  const response = await ApiService.getCoBrowsingAccessLink(appId);
  return response.data.link;
}
