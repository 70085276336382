import { Box } from '@breathelife/mui';
import { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Permission } from '@breathelife/types';

import { SubmitButton } from '../../../Components/Button/SubmitButton';
import { RestrictedToUserPermissions } from '../../../Components/Restricted/RestrictedToUserPermissions';
import Typography from '../../../Components/Typography';
import { CarrierContext } from '../../../Context/CarrierContext';
import { ModalLayout } from '../../../Layouts/Modal/ModalLayout';
import { Lead } from '../../../Models/Lead';
import { useNavigation } from '../../../Hooks';
import { generateLeadsListUrl } from '../../../Navigation/Urls';
import { useDeleteLeadMutation } from '../../../ReactQuery/Lead/lead.mutations';
import { useNavigate } from 'react-router-dom';

export type Props = {
  isOpen: boolean;
  closeModal: () => void;
  lead: Lead;
};

export function DeleteLeadModal(props: Props): ReactElement {
  const { t } = useTranslation();
  const { features } = useContext(CarrierContext);
  const { isOpen, closeModal, lead } = props;
  const { leadTab } = useNavigation();
  const navigate = useNavigate();

  const deleteLeadMutation = useDeleteLeadMutation({
    onSuccess: () => {
      navigate(generateLeadsListUrl(leadTab));
      closeModal();
    },
  });

  function deleteLead(): void {
    deleteLeadMutation.mutate(lead.id);
  }

  return (
    <ModalLayout
      maxWidth='sm'
      isOpen={isOpen}
      closeModal={closeModal}
      title={t(
        features.hiddenLeads?.enabled
          ? 'modals.deleteLead.deleteApplicationTitle'
          : 'modals.deleteLead.deleteLeadTitle',
      )}
      submitButton={
        <RestrictedToUserPermissions requiredPermissions={[Permission.LeadDelete]}>
          <SubmitButton
            isLoading={deleteLeadMutation.isLoading}
            disabled={deleteLeadMutation.isLoading}
            onClick={deleteLead}
            data-testid='lead-delete-confirm'
          >
            {t('cta.delete')}
          </SubmitButton>
        </RestrictedToUserPermissions>
      }
    >
      <Box mb={5}>
        <Typography variant='body1' grey={90}>
          {t(
            features.hiddenLeads?.enabled
              ? 'modals.deleteLead.deleteApplicationConfirmation'
              : 'modals.deleteLead.deleteLeadConfirmation',
          )}{' '}
          {t('modals.deleteLead.irreversible')}
        </Typography>
      </Box>
    </ModalLayout>
  );
}
