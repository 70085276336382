import { ReactElement } from 'react';
import styled, { useTheme } from '../../Styles/themed-styled-components';

import { LeadPlatformTheme, PlatformColorList } from '../../Styles/Types';

type DotProps = {
  color?: PlatformColorList;
};

const ColoredDot = styled.span<{ color?: string; border?: string }>`
  background-color: ${(props) => props.color};
  display: inline-block;
  vertical-align: middle;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid ${(props) => props.border};
`;

export function Dot(props: DotProps): ReactElement {
  const theme = useTheme() as LeadPlatformTheme;
  const defaultColor = 'grey';
  const color = props.color || defaultColor;
  return <ColoredDot color={theme.colors[color][50]} border={theme.colors[color][30]} />;
}
