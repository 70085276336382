import { ReactElement, Fragment } from 'react';
import styled from '../../../../../Styles/themed-styled-components';

const HighlightedText = styled.span`
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors.yellow[30]};
  font: inherit;
`;

export function HighlightedContent({
  textFragments,
}: {
  textFragments: { text: string; highlight: boolean }[];
}): ReactElement {
  return (
    <Fragment>
      {textFragments.map((fragment, i) => {
        if (fragment.highlight) {
          return <HighlightedText key={`${fragment.text}-${fragment.highlight}-${i}`}>{fragment.text}</HighlightedText>;
        }
        return fragment.text;
      })}
    </Fragment>
  );
}
