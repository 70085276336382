import _ from 'lodash';

import { BaseLead, Lead } from '../Models/Lead';
import { LeadNote } from '../Models/LeadsNote';
import ApiService from './ApiService';
import { convertObjectToQueryString, ConvertObjectToQueryStringOptions, CustomFieldMap } from './helpers/query';

export type FetchLeadsQueryParams = {
  status?: string;
  selectedUserId: string;
  signatureStatus?: string;
  archived?: boolean;
  $search?: string;
  $limit?: string;
};

export type FetchLeadsSortingParams = {
  field: keyof Lead | 'applicationMode' | 'lineOfBusiness';
  direction: 'asc' | 'desc';
};

export type FetchLeadsOptions = {
  limit?: number;
  skip?: number;
  queryParams?: Omit<FetchLeadsQueryParams, 'selectedUserId'> & { assignedToId?: string | null };
  sort?: FetchLeadsSortingParams;
  fields?: string[];
};

type LeadsGetResponse = {
  total: number;
  limit: number;
  skip: number;
  data: BaseLead[];
};

export type LeadsMappedGetResponse = {
  total: number;
  limit: number;
  skip: number;
  data: Lead[];
};

export type ValidateLeadEmailResponse = {
  isValid: boolean;
  verdict?: string;
};

export type SendInvitationEmailToLeadResponse = {
  success: true;
};

// the ordering of fields matter
const customFieldMapping: CustomFieldMap = {
  fullName: ['firstName', 'lastName'],
};

export async function getLead(leadId: number): Promise<Lead> {
  const response = await ApiService.getLead<Lead>(leadId);
  return mapBaseLeadToLead(response.data);
}

export async function createLead(data: Partial<BaseLead>): Promise<Lead> {
  const response = await ApiService.createLead<BaseLead>(data);
  return mapBaseLeadToLead(response.data);
}

export async function deleteLead(leadId: number): Promise<Lead> {
  const response = await ApiService.deleteLead<Lead>(leadId);
  return mapBaseLeadToLead(response.data);
}

export async function updateLead(data: Partial<BaseLead>): Promise<Lead> {
  const { id, ...leadData } = data;
  const response = await ApiService.updateLead<Lead>(id!, leadData);
  return mapBaseLeadToLead(response.data);
}

export async function archiveLead(leadId: number): Promise<Lead> {
  const response = await ApiService.archiveLead<Lead>(leadId);
  return mapBaseLeadToLead(response.data);
}

export async function unarchiveLead(leadId: number): Promise<Lead> {
  const response = await ApiService.unarchiveLead<Lead>(leadId);
  return mapBaseLeadToLead(response.data);
}

export async function fetchLeads(options: FetchLeadsOptions): Promise<LeadsMappedGetResponse> {
  const queryParams: ConvertObjectToQueryStringOptions = options?.queryParams ? { ...options.queryParams } : {};

  queryParams['$limit'] = options.limit?.toString() ?? '10';

  if (!_.isUndefined(options.skip)) {
    queryParams['$skip'] = options.skip;
  }

  if (options.sort) {
    queryParams['$sort'] = options.sort;
  }

  const queryString = convertObjectToQueryString({ ...queryParams, fields: options.fields }, customFieldMapping);

  const response = await ApiService.fetchLeads<LeadsGetResponse>(queryString);
  response.data.data = response.data.data.map(mapBaseLeadToLead);
  return response.data as LeadsMappedGetResponse;
}

export async function validateLeadEmail(leadId: number): Promise<ValidateLeadEmailResponse> {
  const response = await ApiService.validateLeadEmail(leadId);
  return response.data;
}

export async function sendInvitationEmailToLead(leadId: number): Promise<SendInvitationEmailToLeadResponse> {
  const response = await ApiService.sendInvitationEmailToLead<SendInvitationEmailToLeadResponse>(leadId);
  return response.data;
}

function mapBaseLeadToLead(lead: BaseLead): Lead {
  const { email } = lead;
  return {
    ...lead,
    // TODO Update the mandatory email field, it might not be set for all carriers
    email: email as string, // this should always be set
  };
}

export async function updateLeadNote(leadId: number, note: string): Promise<LeadNote> {
  const response = await ApiService.updateLeadNote<LeadNote>(leadId, note);
  return response.data;
}

export type FindUsedLeadStatusesResponse = string[];

export async function findUsedLeadStatuses(
  archived: boolean,
  statusType: 'signatureStatus' | 'status',
): Promise<FindUsedLeadStatusesResponse> {
  const response = await ApiService.findUsedLeadStatuses(archived, statusType);
  return response.data;
}
