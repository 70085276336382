import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import _ from 'lodash';
import { ReactElement, Fragment, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import styled from '../../../../Styles/themed-styled-components';

import { QuestionnaireVersionRowData, SortDirection } from '@breathelife/types';
import { Box } from '@breathelife/ui-components';

import { Pagination } from '../../../../Components/Pagination/Pagination';
import { Table } from '../../../../Components/Table/Table';
import Typography from '../../../../Components/Typography';
import { emptyTableHeight } from '../../../../Models/Layout';
import {
  QuestionnaireVersionsContext,
  PER_PAGE_OPTIONS,
} from '../ContextProvider/QuestionnaireVersionsContextProvider';
import { DownloadApplicationSchemaButton } from './DownloadApplicationSchemaButton';
import { useNavigate } from 'react-router-dom';
import { generateQuestionnaireVersionUrl } from '../../../../Navigation/Urls';
import { DownloadQuestionnaireSchemaButton } from './DownloadQuestionnaireSchemaButton';
import { useCarrierContext } from '../../../../Hooks';

dayjs.extend(LocalizedFormat);

const EmptyQuestionnaireContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: calc(100vh - ${emptyTableHeight}px);
  min-height: 300px;
`;

const TextOverFlowCell = styled.div`
  max-width: 300px;
  overflow-wrap: break-word;
`;

type QuestionnaireVersionsTableColumn = {
  id?: string;
  Header: string;
  accessor: keyof QuestionnaireVersionRowData;
  disableSortBy?: boolean;
  hidden?: boolean;
  Cell?: (props: CellProps<QuestionnaireVersionRowData>) => ReactElement;
};

export function QuestionnaireVersionsTable(): ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { onPageChange, onLimitPerPageChange, onSortingChange, questionnaireVersions, total, currentPage, perPage } =
    useContext(QuestionnaireVersionsContext);

  const { features } = useCarrierContext();

  const tableColumns = useMemo<QuestionnaireVersionsTableColumn[]>(
    () => [
      {
        Header: t('admin.questionnaireManagement.table.version'),
        accessor: 'version',
        disableSortBy: true,
      },
      {
        Header: t('admin.questionnaireManagement.table.description'),
        accessor: 'description',
        Cell: function ({ cell: { value } }: { cell: { value: string } }): ReactElement {
          return <TextOverFlowCell>{value}</TextOverFlowCell>;
        },
      },
      {
        Header: t('admin.questionnaireManagement.table.createdAt'),
        accessor: 'createdAt',
        Cell: function ({ cell: { value } }: { cell: { value: Date } }): ReactElement {
          return <Fragment>{dayjs(value).format('D MMMM YYYY HH:MM A')}</Fragment>;
        },
      },
      {
        Header: t('admin.questionnaireManagement.table.updatedAt'),
        accessor: 'updatedAt',
        Cell: function ({ cell: { value } }: { cell: { value: Date } }): ReactElement {
          return <Fragment>{dayjs(value).format('D MMMM YYYY HH:MM A')}</Fragment>;
        },
      },
      {
        Header: t('admin.questionnaireManagement.table.status'),
        accessor: 'isDraft',
        Cell: function ({ cell: { value } }: { cell: { value: boolean } }): ReactElement {
          return (
            <Fragment>
              {value
                ? t('admin.questionnaireManagement.table.draft')
                : t('admin.questionnaireManagement.table.published')}
            </Fragment>
          );
        },
      },
      {
        Header: t('admin.questionnaireManagement.table.applicationsCount'),
        accessor: 'applicationsCount',
        disableSortBy: true,
      },
      {
        Header: t('admin.questionnaireManagement.table.applicationSchema'),
        accessor: 'id',
        disableSortBy: true,
        Cell: function ({ cell: { row } }: { cell: { row: { original: QuestionnaireVersionRowData } } }): ReactElement {
          return <DownloadApplicationSchemaButton id={row.original.id} version={row.original.version} />;
        },
      },
      ...(features.questionnaireSchemaExport.enabled
        ? [
            {
              id: 'questionnaire-schema',
              Header: t('admin.questionnaireManagement.table.questionnaireSchema'),
              accessor: 'version' as keyof QuestionnaireVersionRowData,
              disableSortBy: true,
              Cell: function ({
                cell: { row },
              }: {
                cell: { row: { original: QuestionnaireVersionRowData } };
              }): ReactElement {
                return <DownloadQuestionnaireSchemaButton id={row.original.id} version={row.original.version} />;
              },
            },
          ]
        : []),
    ],
    [t, features],
  );

  const onSelectQuestionnaireVersion = useCallback(
    (questionnaireVersionId: string) => {
      navigate(generateQuestionnaireVersionUrl(questionnaireVersionId));
    },
    [navigate],
  );

  const handleSortingChange = useCallback(
    (columnId?: keyof QuestionnaireVersionRowData, sortDirection?: SortDirection) => {
      if (!columnId || !sortDirection) return;

      onSortingChange({ field: columnId, direction: sortDirection });
    },
    [onSortingChange],
  );

  const data = useMemo<QuestionnaireVersionRowData[] | undefined>(() => {
    if (_.isEmpty(questionnaireVersions)) return;

    return questionnaireVersions.map(
      ({
        id,
        questionnaireId,
        description,
        majorVersion,
        minorVersion,
        createdAt,
        updatedAt,
        isDraft,
        applicationsCount,
      }) => {
        return {
          id,
          questionnaireId,
          description,
          createdAt,
          updatedAt,
          isDraft,
          applicationsCount,
          version: `${majorVersion}.${minorVersion}`,
        };
      },
    );
  }, [questionnaireVersions]);

  return (
    <Fragment>
      {!data ? (
        <EmptyQuestionnaireContainer>
          <Box mb={1.5}>
            <Typography variant='h2' grey={70}>
              {t('admin.questionnaireManagement.table.noQuestionnairesFoundTitle')}
            </Typography>
          </Box>
          <Box maxWidth='300px'>
            <Typography variant='body1' grey={60}>
              {t('admin.questionnaireManagement.table.noQuestionnairesFoundDetails')}
            </Typography>
          </Box>
        </EmptyQuestionnaireContainer>
      ) : (
        <Fragment>
          <Table<QuestionnaireVersionRowData>
            onRowClick={onSelectQuestionnaireVersion}
            columns={tableColumns}
            data={data}
            onOrderChange={handleSortingChange}
          />
          <Box pl={2.5} pr={2.5}>
            <Pagination
              total={total}
              page={currentPage}
              perPage={perPage}
              perPageOptions={PER_PAGE_OPTIONS}
              onPageChange={onPageChange}
              onPerPageChange={onLimitPerPageChange}
            />
          </Box>
        </Fragment>
      )}
    </Fragment>
  );
}
