import { SyntheticEvent, ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Language } from '@breathelife/types';

import { Tabs, TabType } from '../../../../../Components/Tabs/Tabs';

type Props = {
  enabledLanguages: Language[];
  selectedLanguage: Language;
  onLanguageChange: (language: Language) => void;
};

export function LanguageToggle(props: Props): ReactElement | null {
  const { enabledLanguages, selectedLanguage, onLanguageChange } = props;
  const { t } = useTranslation();

  const onChange = useCallback(
    (_event: SyntheticEvent, index: number) => {
      const languagePressed = enabledLanguages[index];
      onLanguageChange(languagePressed);
    },
    [enabledLanguages, onLanguageChange],
  );

  if (enabledLanguages.length < 2) {
    return null;
  }

  const selectedLanguageTab = enabledLanguages.indexOf(selectedLanguage);
  const languageTabs: TabType[] = enabledLanguages.map((language) => ({
    label: t(`language.${language}`),
  }));
  return <Tabs tabs={languageTabs} value={selectedLanguageTab} onChange={onChange} />;
}
