import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Card } from '@breathelife/mui';
import { Loader } from '@breathelife/ui-components';

import { Image } from '../../Components/Images/Image';
import Typography from '../../Components/Typography';
import { useCarrierContext, useDispatch, useSelector } from '../../Hooks';
import { fetchTheme } from '../../ReduxStore/Admin/ThemeManagement/ThemeOperations';
import styled, { useTheme } from '../../Styles/themed-styled-components';
import { LeadPlatformTheme } from '../../Styles/Types';
import { LogoFooter } from './Styles';

type Variants = 'primary' | 'secondary' | 'light';

const Content = styled(Card)`
  width: 100%;
  text-align: center;
`;

const handleHeaderVariant = (theme: LeadPlatformTheme, variant?: Variants): string => {
  let backgroundColor = theme.colors.primary.default;

  switch (variant) {
    case 'light':
      backgroundColor = theme.colors.grey[10];
      break;
    case 'primary':
    case 'secondary':
      backgroundColor = theme.colors[variant].default;
      break;
  }

  return backgroundColor;
};
const Header = styled(Box)<{ color: string }>`
  background-color: ${(props) => props.color};
`;

function LogoutView(): ReactElement {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme() as LeadPlatformTheme;
  const { carrierInfo, headerVariant } = useCarrierContext();
  const [hasInitializedFetchTheme, setHasInitializedFetchTheme] = useState(false);
  const reduxTheme = useSelector((state) => state.leadPlatform.theme);

  const bgColor = handleHeaderVariant(theme, headerVariant);

  useEffect(() => {
    if (hasInitializedFetchTheme) return;

    void dispatch(fetchTheme());
    setHasInitializedFetchTheme(true);
  }, [dispatch, hasInitializedFetchTheme]);

  if (!reduxTheme) return <Loader color='#000000' />;

  return (
    <Content>
      <Header p={4} color={bgColor}>
        <Image src={carrierInfo.logo} radius={0} height={50} alt={carrierInfo.companyName} isProfile={false} />
      </Header>

      <Box px={4} pt={7} height='160px'>
        <Typography variant='body1' component='h1' grey={90}>
          {t('logout.text')}
        </Typography>
      </Box>

      <Box px={4} py={2}>
        <Typography variant='small2' grey={50}>
          {t('authentication.softwareBy')}&nbsp;
          <LogoFooter role='img' aria-label='Breathe Life' />
        </Typography>
      </Box>
    </Content>
  );
}

export default LogoutView;
