import { ReactElement, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Box } from '@breathelife/ui-components';

import { HelmetContainer } from '../../../Components/HelmetContainer';
import { QuestionnaireEditorContextProvider } from './ContextProvider/QuestionnaireEditorContextProvider';
import { QuestionnaireVersionDataContextProvider } from './ContextProvider/QuestionnaireVersionDataContextProvider';
import { QuestionnaireEditorView } from './QuestionnaireEditor/QuestionnaireEditorView';

export function QuestionnaireVersionManagementViewContainer(): ReactElement {
  const { t } = useTranslation();

  const { questionnaireVersionId = '' } = useParams<{ questionnaireVersionId: string }>();

  return (
    <Fragment>
      <HelmetContainer text={t('pageTitles.questionnaireManagement')} />
      <Box display='flex' flexGrow={1} flexDirection={'column'}>
        <QuestionnaireEditorContextProvider>
          <QuestionnaireVersionDataContextProvider questionnaireVersionId={questionnaireVersionId}>
            <QuestionnaireEditorView />
          </QuestionnaireVersionDataContextProvider>
        </QuestionnaireEditorContextProvider>
      </Box>
    </Fragment>
  );
}
