import { ReactElement, useMemo } from 'react';
import styled from '../../Styles/themed-styled-components';
import * as yup from 'yup';

import { Language } from '@breathelife/types';
import { AutoComplete, ReadonlyFormControlLabel } from '@breathelife/ui-components';

import { MonospaceTypography } from '../../Components/Typography';
import { NodeDetail } from '../../Helpers/questionnaireEditor/questionnaireNodeIds';
import { TextFragmentWithHighlightInfo } from '../../Helpers/questionnaireEditor/questionnaireSearch';
import { HighlightedContent } from '../../Pages/Admin/Questionnaire/QuestionnaireEditor/TreeView/HighlightedContent';

const StyledLabel = styled(ReadonlyFormControlLabel)`
  && {
    width: 100%;
  }
`;

type Props = {
  label: string;
  highlightedSelectedNodeId?: TextFragmentWithHighlightInfo[];
  readOnly: boolean;
  required?: boolean;
  selectedNodeId: string;
  onChange: (value: { label: string; value: string } | null) => void;
  nodeIds: NodeDetail[];
  onClear?: () => void;
  selectedLanguage: Language;
  disabled?: boolean;
  validationError?: yup.ValidationError;
};

export function NodeIdSelector(props: Props): ReactElement {
  const {
    label,
    readOnly,
    nodeIds,
    selectedNodeId,
    onChange,
    onClear,
    selectedLanguage,
    required,
    disabled,
    validationError,
    highlightedSelectedNodeId,
  } = props;

  const options = useMemo(() => {
    return nodeIds.map((nodeDetail) => ({
      value: nodeDetail.answerNodeId,
      label: nodeDetail.answerNodeId,
      groupName: nodeDetail.groupName ? nodeDetail.groupName[selectedLanguage] : undefined,
    }));
  }, [nodeIds, selectedLanguage]);

  if (readOnly) {
    const nodeIdControl = (
      <MonospaceTypography variant='body1'>
        {highlightedSelectedNodeId ? <HighlightedContent textFragments={highlightedSelectedNodeId} /> : selectedNodeId}
      </MonospaceTypography>
    );
    return <StyledLabel label={label} showError={false} labelPlacement='top' control={nodeIdControl} />;
  }

  return (
    <AutoComplete
      id='nodeIdsDropdown'
      options={options}
      option={{ label: selectedNodeId, value: selectedNodeId, groupName: '' }}
      onChange={onChange}
      label={label}
      disabled={disabled}
      disableClearable={!onClear}
      onClear={onClear}
      required={required}
      groupBy={(option) => option.groupName ?? ''}
      validationError={validationError}
    />
  );
}
