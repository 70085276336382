import { SelectOptionFieldBlueprint } from '@breathelife/types';

import { FieldDataSetterProps } from '../../../Helpers/helperTypes';
import { DropdownFieldSetters, useDropdownFieldData } from '../useDropdownFieldData';
import { useCommonFieldDataSetters } from './useCommonFieldDataSetters';

export function DropdownFieldDataSetter({
  initialData,
  dataLabelOptions,
  ...props
}: FieldDataSetterProps<SelectOptionFieldBlueprint, DropdownFieldSetters>): null {
  const { data, setters, validationOptions, defaultValueValidationError } = useDropdownFieldData({
    initialData,
    dataLabelOptions,
  });

  useCommonFieldDataSetters<SelectOptionFieldBlueprint, DropdownFieldSetters>({
    ...props,
    data,
    setters,
    validationOptions,
    defaultValueValidationError,
    getIsValidBlueprint: (blueprint) => !!blueprint.validateAs,
  });
  return null;
}
