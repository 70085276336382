import { ChangeEvent, ReactElement, useCallback, useLayoutEffect, useRef, useState } from 'react';

import { TextFieldProps } from '@breathelife/mui';
import { Input, ValidationProps } from '@breathelife/ui-components';

import { formatPostalCode, MAX_POSTAL_CODE_LENGTH } from '../../Helpers/inputValidation/formatters/postalCode';

const MAX_INPUT_LENGTH = MAX_POSTAL_CODE_LENGTH + 1;

type PostalCodeInputMuiProps = Omit<TextFieldProps, 'onChange'> &
  ValidationProps & {
    onChange: (value: string) => void;
  };

export function PostalCodeInputMui(props: PostalCodeInputMuiProps): ReactElement {
  const { onChange } = props;
  const ref = useRef<HTMLInputElement>();
  const [selection, setSelection] = useState<number>();

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!onChange) return;

      const formattedValue = formatPostalCode(event?.target?.value?.toUpperCase().trim());
      const selectionOffset = formattedValue.length > event.target.value.length ? 1 : 0;

      setSelection((event.target.selectionStart ?? 0) + selectionOffset);
      onChange(formattedValue);
    },
    [onChange],
  );

  useLayoutEffect(() => {
    if (selection && ref.current) {
      ref.current.selectionStart = selection;
      ref.current.selectionEnd = selection;
    }
  }, [selection]);

  return (
    <Input
      id={props.id}
      error={!!props.validationError}
      inputProps={{ maxLength: MAX_INPUT_LENGTH }}
      inputRef={ref}
      inputVariant='outlined'
      label={props.label}
      onChange={handleChange}
      placeholder='A1A 1A1'
      required={props.required}
      validationError={props.validationError}
      value={props.value as string}
    />
  );
}
