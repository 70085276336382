import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid } from '@breathelife/mui';
import { Localizable } from '@breathelife/types';

import { Checkbox, CheckboxGroup } from '../../../Components/Checkbox/Checkbox';
import { getLanguage } from '../../../Localization/utils';

export function CriteriaValuesCheckboxGroup(props: {
  options: { optionName: string; optionLabel: Partial<Localizable> }[];
  values: Array<string>;
  onChange: (values: Array<string>) => void;
}): ReactElement {
  const { options, values, onChange } = props;

  const { t } = useTranslation();
  const language = getLanguage();

  return (
    <CheckboxGroup label={t('admin.conditions.labels.selectedValues.pluralish')}>
      <Grid container spacing={1}>
        {options?.length ? (
          options.map((option) => {
            const { optionName, optionLabel } = option;
            const isOptionSelected = values.includes(optionName);
            return (
              <Grid item key={optionName}>
                <Checkbox
                  color='primary'
                  label={optionLabel[language]}
                  checked={values.includes(optionName)}
                  onChange={() => {
                    onChange(
                      isOptionSelected ? values.filter((value) => value !== optionName) : [...values, optionName],
                    );
                  }}
                />
              </Grid>
            );
          })
        ) : (
          <Grid item>{t('admin.conditions.labels.selectedValues.noOptions')}</Grid>
        )}
      </Grid>
    </CheckboxGroup>
  );
}
