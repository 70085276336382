import { SetStateAction, Dispatch, useContext, useState } from 'react';

import { CopyDeckUnhandledRow, isSectionPartIdentifier, PartIdentifier } from '@breathelife/types';

import { useImportCopyDeckMutation } from '../../../../../ReactQuery/Admin/ImportCopyDeck/importCopyDeck.mutations';
import { QuestionnaireVersionDataContext } from '../../ContextProvider/QuestionnaireVersionDataContextProvider';

type UseCopyDeckImportReturn = {
  handleFileUpload: (file: File[]) => void;
  isCopyDeckImportModalOpen: boolean;
  setIsCopyDeckImportModalOpen: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
  isSuccess: boolean;
  unhandledRows?: CopyDeckUnhandledRow[];
};
export function useCopyDeckImport({
  parentPartIdentifier,
}: {
  parentPartIdentifier: PartIdentifier;
}): UseCopyDeckImportReturn {
  const { questionnaireVersionId } = useContext(QuestionnaireVersionDataContext);
  const [isCopyDeckImportModalOpen, setIsCopyDeckImportModalOpen] = useState<boolean>(false);
  const importCopyDeckMutation = useImportCopyDeckMutation();

  const handleImportedData =
    () =>
    (event: ProgressEvent<FileReader>): void => {
      if (!isSectionPartIdentifier(parentPartIdentifier)) {
        return;
      }

      if (typeof event?.target?.result !== 'string' || !questionnaireVersionId) {
        return;
      }

      importCopyDeckMutation.mutate({ parentPartIdentifier, questionnaireVersionId, copyDeckCSV: event.target.result });
    };

  function handleFileUpload(file: File[]): void {
    const fileData = file[0];
    const reader = new FileReader();
    reader.onload = handleImportedData();
    reader.readAsText(fileData);
  }

  return {
    handleFileUpload,
    isCopyDeckImportModalOpen,
    setIsCopyDeckImportModalOpen,
    isLoading: importCopyDeckMutation.isLoading,
    isSuccess: importCopyDeckMutation.isSuccess,
    unhandledRows: importCopyDeckMutation.data?.unhandledRows,
  };
}
