import _ from 'lodash';

import { NodeIds } from '@breathelife/insurance-form-builder';
import { AnswerPath, SerializedNodeIdToAnswerPathMap } from '@breathelife/questionnaire-engine';
import {
  FieldBlueprint,
  isAddressAutocompleteFieldBlueprint,
  isQuoterSubsectionBlueprint,
  isSelectOptionFieldBlueprint,
  Localizable,
  QuestionnaireBlueprint,
  FieldTypes,
  SelectOptionFieldBlueprint,
  SubsectionBlueprint,
} from '@breathelife/types';

export type NodeDetail = {
  answerNodeId: string;
  collectionNodeIds: string[];
  fieldType?: FieldTypes;
  groupName?: Partial<Localizable>;
};

export type NodeIdInCollections = Record<string, string[]>;

const NOT_IN_QUESTIONNAIRE_GROUP_NAME = { en: 'Not in Questionnaire', fr: 'Pas dans le questionnaire' };
const UNTITLED_SUBSECTION_GROUP_NAME = { en: 'Untitled subsection', fr: 'Sous-section sans titre' };
const UNTITLED_SECTION_GROUP_GROUP_NAME = { en: 'Untitled section group', fr: 'Groupe de sections sans titre' };

// For Beneva short term solution to multi and joint products (DEV-11756), we want to associate
// product selection node ids found in the product look up table answer path to fall under insured people
// See: productLookupTable in bliss/backend/carriers/beneva/src/nodeIds/nodeIdToAnswerPathMap.ts
const PRODUCT_SELECTION_NODE_IDS = [
  'main-product',
  'main-product-id',
  'main-product-surrogate-id',
  'main-product-coverage-type',
  'main-product-capital',
  'main-product-product-type',
  'main-product-product-category',
  'main-product-term-duration',
  'main-product-premium-payable-type',
  'main-product-face-amount-adjustment',
  'maximizer-beginning-of-duration',
  'maximizer-end-of-duration',
  'maximizer-minimum-face-amount',
  'secondary-product',
  'secondary-product-id',
  'secondary-product-surrogate-id',
  'secondary-product-exists',
  'secondary-product-coverage-type',
  'secondary-product-capital',
  'secondary-product-product-type',
  'secondary-product-product-category',
  'secondary-product-term-duration',
  'secondary-product-premium-payable-type',
  'investment-information',
  'investment-account-type',
  'investment-managed-account',
  'investment-interest-account',
  'investment-indexed-account',
  'investment-other-account',
  'investment-percentage',
  'waiver-of-premium-term-duration-universal-life',
  'waiver-of-premium-type-universal-life',
  'insured-billing-premium-universal-life',
];

export type QuestionnaireNodeIds = {
  inQuestionnaire: NodeIdDetailsByRepeatability;
  notInQuestionnaire: NodeIdDetailsByRepeatability;
  selectOptionsByNodeId: Record<string, NodeIdSelectOption[]>;
};

type NodeIdSelectOption = { optionName: string; optionLabel: Partial<Localizable> };

export type NodeIdDetailsByRepeatability = {
  allLeafs: NodeDetail[]; // All non-collection node ids
  noRepeatability: NodeDetail[]; // Node ids with no repeatable ancestor.
  withRepeatableAncestor: Record<string, NodeDetail[]>;
  collection: NodeDetail[]; // Node ids associated with isCollection: true answer paths.
};

export function generateQuestionnaireNodeIdsFromBlueprint(
  blueprint: QuestionnaireBlueprint,
  nodeIdToAnswerPath: SerializedNodeIdToAnswerPathMap,
): QuestionnaireNodeIds {
  const { inQuestionnaire, nodeIdsInQuestionnaire, selectOptionsByNodeId } = getQuestionnaireNodeIdDetails(blueprint);
  const notInQuestionnaire = getNotInQuestionnaireNodeIdDetails(nodeIdToAnswerPath, nodeIdsInQuestionnaire);

  return { inQuestionnaire, notInQuestionnaire, selectOptionsByNodeId };
}

export function generateNodeIdInCollectionMap(questionnaireNodeIds: QuestionnaireNodeIds): NodeIdInCollections {
  const nodeIds = [
    ...questionnaireNodeIds.inQuestionnaire.allLeafs,
    ...questionnaireNodeIds.notInQuestionnaire.allLeafs,
  ];

  return nodeIds.reduce<Record<string, string[]>>((map, node) => {
    if (node.collectionNodeIds.length) {
      map[node.answerNodeId] = node.collectionNodeIds;
    }
    return map;
  }, {});
}

function getQuestionnaireNodeIdDetails(blueprint: QuestionnaireBlueprint): {
  inQuestionnaire: NodeIdDetailsByRepeatability;
  nodeIdsInQuestionnaire: Set<string>;
  selectOptionsByNodeId: Record<string, NodeIdSelectOption[]>;
} {
  const inQuestionnaire: NodeIdDetailsByRepeatability = {
    allLeafs: [],
    noRepeatability: [],
    withRepeatableAncestor: {},
    collection: [],
  };

  const selectOptionsByNodeId: Record<string, NodeIdSelectOption[]> = {};
  const knownSelectOptionsByNodeId: Record<string, Set<string>> = {};

  const nodeIdsInQuestionnaire = new Set<string>();

  // Loop through the questionnaire sectionBlueprints, hunting for nodeIds.
  blueprint.sectionBlueprints.forEach((section) => {
    if (section.hidden) return;

    const sectionGroup = blueprint.sectionGroupBlueprints[section.sectionGroupKey];
    if (!sectionGroup) return;

    const repeatableSectionGroupAnswerNodeId = sectionGroup.repeatable?.repeatableAnswerNodeId;
    const sectionGroupCollectionNodeIds = repeatableSectionGroupAnswerNodeId
      ? [repeatableSectionGroupAnswerNodeId]
      : [];

    if (repeatableSectionGroupAnswerNodeId && !nodeIdsInQuestionnaire.has(repeatableSectionGroupAnswerNodeId)) {
      const repeatableSectionGroupDetail: NodeDetail = {
        answerNodeId: repeatableSectionGroupAnswerNodeId,
        groupName: sectionGroup.title ?? sectionGroup.text ?? UNTITLED_SECTION_GROUP_GROUP_NAME,
        collectionNodeIds: [],
      };
      inQuestionnaire.collection.push(repeatableSectionGroupDetail);

      nodeIdsInQuestionnaire.add(repeatableSectionGroupAnswerNodeId);
    }

    section.subsections.forEach((subsection) => {
      if (subsection.hidden) return;

      // Track nodeIds found in each subsection so we don't repeat them in the final list.
      const knownSubsectionNodeIds = new Set<string>();

      if (isQuoterSubsectionBlueprint(subsection)) {
        const productNodeId = subsection.variant?.productNodeIds?.product;
        const coverageAmountNodeId = subsection.variant?.productNodeIds?.coverageAmount;

        if (productNodeId && !knownSubsectionNodeIds.has(productNodeId)) {
          const productNodeDetail: NodeDetail = {
            answerNodeId: productNodeId,
            fieldType: FieldTypes.money,
            groupName: subsection.title ?? UNTITLED_SUBSECTION_GROUP_NAME,
            collectionNodeIds: [...sectionGroupCollectionNodeIds],
          };

          if (repeatableSectionGroupAnswerNodeId) {
            if (!inQuestionnaire.withRepeatableAncestor[repeatableSectionGroupAnswerNodeId]) {
              inQuestionnaire.withRepeatableAncestor[repeatableSectionGroupAnswerNodeId] = [];
            }

            inQuestionnaire.withRepeatableAncestor[repeatableSectionGroupAnswerNodeId].push(productNodeDetail);
          } else {
            inQuestionnaire.noRepeatability.push(productNodeDetail);
          }

          inQuestionnaire.allLeafs.push(productNodeDetail);
          nodeIdsInQuestionnaire.add(productNodeDetail.answerNodeId);
          knownSubsectionNodeIds.add(productNodeDetail.answerNodeId);
        }

        if (coverageAmountNodeId && !knownSubsectionNodeIds.has(coverageAmountNodeId)) {
          const coverageAmountNodeDetail: NodeDetail = {
            answerNodeId: coverageAmountNodeId,
            fieldType: FieldTypes.money,
            groupName: subsection.title ?? UNTITLED_SUBSECTION_GROUP_NAME,
            collectionNodeIds: [...sectionGroupCollectionNodeIds],
          };

          if (repeatableSectionGroupAnswerNodeId) {
            if (!inQuestionnaire.withRepeatableAncestor[repeatableSectionGroupAnswerNodeId]) {
              inQuestionnaire.withRepeatableAncestor[repeatableSectionGroupAnswerNodeId] = [];
            }

            inQuestionnaire.withRepeatableAncestor[repeatableSectionGroupAnswerNodeId].push(coverageAmountNodeDetail);
          } else {
            inQuestionnaire.noRepeatability.push(coverageAmountNodeDetail);
          }

          inQuestionnaire.allLeafs.push(coverageAmountNodeDetail);
          nodeIdsInQuestionnaire.add(coverageAmountNodeDetail.answerNodeId);
          knownSubsectionNodeIds.add(coverageAmountNodeDetail.answerNodeId);
        }

        if (
          subsection.variant?.simpleQuoter?.useNeedsAnalysisNodeId &&
          !knownSubsectionNodeIds.has(subsection.variant.simpleQuoter.useNeedsAnalysisNodeId)
        ) {
          const useNeedsAnalysisNodeDetail: NodeDetail = {
            answerNodeId: subsection.variant.simpleQuoter.useNeedsAnalysisNodeId,
            fieldType: FieldTypes.checkbox,
            groupName: subsection.title ?? UNTITLED_SUBSECTION_GROUP_NAME,
            collectionNodeIds: [...sectionGroupCollectionNodeIds],
          };

          if (!knownSubsectionNodeIds.has(useNeedsAnalysisNodeDetail.answerNodeId)) {
            if (repeatableSectionGroupAnswerNodeId) {
              if (!inQuestionnaire.withRepeatableAncestor[repeatableSectionGroupAnswerNodeId]) {
                inQuestionnaire.withRepeatableAncestor[repeatableSectionGroupAnswerNodeId] = [];
              }

              inQuestionnaire.withRepeatableAncestor[repeatableSectionGroupAnswerNodeId].push(
                useNeedsAnalysisNodeDetail,
              );
            } else {
              inQuestionnaire.noRepeatability.push(useNeedsAnalysisNodeDetail);
            }

            inQuestionnaire.allLeafs.push(useNeedsAnalysisNodeDetail);
            nodeIdsInQuestionnaire.add(useNeedsAnalysisNodeDetail.answerNodeId);
            knownSubsectionNodeIds.add(useNeedsAnalysisNodeDetail.answerNodeId);
          }
        }
      }

      subsection.questions.forEach((question) => {
        if (question.hidden) return;

        const repeatableAnswerNodeId =
          question.repeatable?.repeatableAnswerNodeId ?? repeatableSectionGroupAnswerNodeId;

        if (
          question.repeatable?.repeatableAnswerNodeId &&
          !knownSubsectionNodeIds.has(question.repeatable?.repeatableAnswerNodeId)
        ) {
          // Found a collection nodeId.
          const repeatableQuestionDetail: NodeDetail = {
            answerNodeId: question.repeatable?.repeatableAnswerNodeId,
            groupName: subsection.title ?? UNTITLED_SUBSECTION_GROUP_NAME,
            collectionNodeIds: [...sectionGroupCollectionNodeIds],
          };
          inQuestionnaire.collection.push(repeatableQuestionDetail);

          nodeIdsInQuestionnaire.add(question.repeatable?.repeatableAnswerNodeId);
          knownSubsectionNodeIds.add(question.repeatable?.repeatableAnswerNodeId);
        }

        const questionCollectionNodeIds = question.repeatable?.repeatableAnswerNodeId
          ? [...sectionGroupCollectionNodeIds, question.repeatable.repeatableAnswerNodeId]
          : [...sectionGroupCollectionNodeIds];

        question.fields.forEach((field) => {
          const { hidden, answerNodeId, fieldType } = field;

          if (isSelectOptionFieldBlueprint(field)) {
            const selectOptions = getSelectOptionsForField(field);

            if (selectOptions) {
              if (!selectOptionsByNodeId[answerNodeId]) {
                selectOptionsByNodeId[answerNodeId] = [];
                knownSelectOptionsByNodeId[answerNodeId] = new Set();
              }

              // Add select options to `selectOptionsByNodeId`, avoiding already-added selections
              selectOptions.forEach((selectOption) => {
                const knownSelectOptions = knownSelectOptionsByNodeId[answerNodeId];

                const knownOption = knownSelectOptions.has(selectOption.optionName);
                if (!knownOption) {
                  knownSelectOptions.add(selectOption.optionName);
                  selectOptionsByNodeId[answerNodeId].push(selectOption);
                }
              });
            }
          }

          if (hidden || knownSubsectionNodeIds.has(answerNodeId)) return;

          const fieldNodeDetail: NodeDetail = {
            answerNodeId,
            fieldType,
            groupName: subsection.title ?? UNTITLED_SUBSECTION_GROUP_NAME,
            collectionNodeIds: [...questionCollectionNodeIds],
          };

          // Address autocomplete nodeIds should be considered 'part' of the questionnaire.
          const addressAutocompleteNodeDetail = getAddressAutocompleteNodeDetail(field, subsection, [
            ...questionCollectionNodeIds,
          ]);

          if (repeatableAnswerNodeId) {
            if (!inQuestionnaire.withRepeatableAncestor[repeatableAnswerNodeId]) {
              inQuestionnaire.withRepeatableAncestor[repeatableAnswerNodeId] = [];
            }

            inQuestionnaire.withRepeatableAncestor[repeatableAnswerNodeId].push(fieldNodeDetail);

            if (addressAutocompleteNodeDetail) {
              inQuestionnaire.withRepeatableAncestor[repeatableAnswerNodeId].push(addressAutocompleteNodeDetail);
            }
          } else {
            inQuestionnaire.noRepeatability.push(fieldNodeDetail);

            if (addressAutocompleteNodeDetail) {
              inQuestionnaire.noRepeatability.push(addressAutocompleteNodeDetail);
            }
          }

          inQuestionnaire.allLeafs.push(fieldNodeDetail);
          if (addressAutocompleteNodeDetail) {
            inQuestionnaire.allLeafs.push(addressAutocompleteNodeDetail);

            nodeIdsInQuestionnaire.add(addressAutocompleteNodeDetail.answerNodeId);
            knownSubsectionNodeIds.add(addressAutocompleteNodeDetail.answerNodeId);
          }

          nodeIdsInQuestionnaire.add(answerNodeId);
          knownSubsectionNodeIds.add(answerNodeId);
        });
      });
    });
  });

  return { inQuestionnaire, nodeIdsInQuestionnaire, selectOptionsByNodeId };
}

export function getNotInQuestionnaireNodeIdDetails(
  nodeIdToAnswerPath: SerializedNodeIdToAnswerPathMap,
  nodeIdsInQuestionnaire: Set<string>,
): NodeIdDetailsByRepeatability {
  const notInQuestionnaire: NodeIdDetailsByRepeatability = {
    allLeafs: [],
    noRepeatability: [],
    withRepeatableAncestor: {},
    collection: [],
  };

  const notInQuestionnaireNodeIdToAnswerPath = nodeIdToAnswerPath.filter(
    ([nodeId]) => !nodeIdsInQuestionnaire.has(nodeId),
  );

  if (!notInQuestionnaireNodeIdToAnswerPath.length) {
    // All nodeIds are used in the questionnaire.
    return notInQuestionnaire;
  }

  // Get collection node id details.
  const collectionNodeIdAnswerPaths = getCollectionNodeIdAnswerPaths(nodeIdToAnswerPath);
  const collectionsInQuestionnaire = collectionNodeIdAnswerPaths.filter(([nodeId]) =>
    nodeIdsInQuestionnaire.has(nodeId),
  );

  const collectionsNotInQuestionnaire = collectionNodeIdAnswerPaths.filter(
    ([nodeId]) => !nodeIdsInQuestionnaire.has(nodeId),
  );

  notInQuestionnaire.collection = collectionsNotInQuestionnaire.map(([nodeId]) => ({
    answerNodeId: nodeId,
    groupName: NOT_IN_QUESTIONNAIRE_GROUP_NAME,
    collectionNodeIds: [],
  }));

  // Get node id details from non-repeatable contexts.
  const noRepeatabilityNodeIdDetails = buildNonRepeatableNodeIds(notInQuestionnaireNodeIdToAnswerPath);
  notInQuestionnaire.noRepeatability = noRepeatabilityNodeIdDetails.map((answerNodeId) => ({
    answerNodeId,
    groupName: NOT_IN_QUESTIONNAIRE_GROUP_NAME,
    collectionNodeIds: [],
  }));

  // Get node id details from repeatable contexts.
  const nodeIdDetailsByAncestorRepeatableNodeId = buildNodeIdDetailsByAncestorRepeatableNodeId(
    // Include all collections in the questionnaire since they may include fields not in the questionnaire.
    notInQuestionnaireNodeIdToAnswerPath.concat(collectionsInQuestionnaire),
    NOT_IN_QUESTIONNAIRE_GROUP_NAME,
  );

  // Get node id details of custom node ids explicitly mapped to a section group.
  const nodeIdDetailsOfCustomNodeIdsToSectionGroup = buildNodeIdDetailsOfCustomNodeIdsToSectionGroup(
    // Include all collections in the questionnaire since they may include fields not in the questionnaire.
    notInQuestionnaireNodeIdToAnswerPath.concat(collectionsInQuestionnaire),
    NOT_IN_QUESTIONNAIRE_GROUP_NAME,
    {
      [NodeIds.insuredPeople]: PRODUCT_SELECTION_NODE_IDS,
    },
  );

  notInQuestionnaire.withRepeatableAncestor = _.mergeWith(
    {},
    nodeIdDetailsByAncestorRepeatableNodeId.byAncestorNodeId,
    nodeIdDetailsOfCustomNodeIdsToSectionGroup.byAncestorNodeId,
    mergeWithCustomizer,
  );

  // Get node id details for all fields.
  notInQuestionnaire.allLeafs = notInQuestionnaire.noRepeatability.concat(
    nodeIdDetailsByAncestorRepeatableNodeId.allWithAncestors,
  );

  return notInQuestionnaire;
}

function mergeWithCustomizer(objValue: unknown, srcValue: unknown): NodeDetail[] | undefined {
  if (_.isArray(objValue)) {
    return objValue.concat(srcValue);
  }
  return;
}

function getCollectionNodeIdAnswerPaths(nodeIdToAnswerPath: SerializedNodeIdToAnswerPathMap): [string, AnswerPath][] {
  return nodeIdToAnswerPath.filter(([, answerPath]) => answerPath.isCollection);
}

function buildNonRepeatableNodeIds(nodeIdToAnswerPath: SerializedNodeIdToAnswerPathMap): string[] {
  return nodeIdToAnswerPath
    .filter(([, answerPath]) => !answerPath.isCollection && AnswerPath.getAncestorCollectionCount(answerPath) === 0)
    .map(([nodeId]) => nodeId);
}

function buildNodeIdDetailsByAncestorRepeatableNodeId(
  nodeIdToAnswerPath: SerializedNodeIdToAnswerPathMap,
  groupName: Partial<Localizable>,
): { byAncestorNodeId: Record<string, NodeDetail[]>; allWithAncestors: NodeDetail[] } {
  const fieldNodeIdsByAncestorRepeatableNodeId: Record<string, NodeDetail[]> = {};
  const allWithAncestors: NodeDetail[] = [];

  nodeIdToAnswerPath.forEach(([nodeId, answerPath]) => {
    const ancestorCollectionAnswerPath = AnswerPath.getAncestorCollectionAnswerPath(answerPath);
    const ancestorNodeId = ancestorCollectionAnswerPath?.nodeId;

    // The current node id iteration has a valid collection ancestor
    if (ancestorNodeId && ancestorCollectionAnswerPath) {
      addToNodeDetailsArrays({
        ancestorNodeId,
        nodeDetail: createNodeDetails({ nodeId, answerPath, groupName }),
        fieldNodeIdsByAncestorRepeatableNodeId,
        allWithAncestors,
      });
    }
  });

  return { byAncestorNodeId: fieldNodeIdsByAncestorRepeatableNodeId, allWithAncestors };
}

function buildNodeIdDetailsOfCustomNodeIdsToSectionGroup(
  nodeIdToAnswerPath: SerializedNodeIdToAnswerPathMap,
  groupName: Partial<Localizable>,
  nodeIdToSectionMapping: Record<string, string[]>,
): { byAncestorNodeId: Record<string, NodeDetail[]>; allWithAncestors: NodeDetail[] } {
  const fieldNodeIdsByAncestorRepeatableNodeId: Record<string, NodeDetail[]> = {};
  const allWithAncestors: NodeDetail[] = [];

  Object.entries(nodeIdToSectionMapping).forEach(([sectionGroupNodeId, nodeIdsToMap]) => {
    nodeIdToAnswerPath.forEach(([nodeId, answerPath]) => {
      const nodeIdRequiresExplicitMappingToSectionGroup = nodeIdsToMap.includes(nodeId);

      if (nodeIdRequiresExplicitMappingToSectionGroup) {
        addToNodeDetailsArrays({
          // Explicitly associate node id detail with insured people section group
          ancestorNodeId: sectionGroupNodeId,
          nodeDetail: createNodeDetails({ nodeId, answerPath, groupName }),
          fieldNodeIdsByAncestorRepeatableNodeId,
          allWithAncestors,
        });
      }
    });
  });

  return { byAncestorNodeId: fieldNodeIdsByAncestorRepeatableNodeId, allWithAncestors };
}

function createNodeDetails(data: {
  nodeId: string;
  answerPath: AnswerPath;
  groupName: Partial<Localizable>;
}): NodeDetail {
  return {
    answerNodeId: data.nodeId,
    collectionNodeIds: getAllAncestorCollectionNodeIds(data.answerPath),
    groupName: data.groupName,
  };
}

export function addToNodeDetailsArrays({
  ancestorNodeId,
  nodeDetail,
  fieldNodeIdsByAncestorRepeatableNodeId,
  allWithAncestors,
}: {
  ancestorNodeId: string;
  nodeDetail: NodeDetail;
  fieldNodeIdsByAncestorRepeatableNodeId: Record<string, NodeDetail[]>;
  allWithAncestors: NodeDetail[];
}): void {
  if (!fieldNodeIdsByAncestorRepeatableNodeId[ancestorNodeId]) {
    fieldNodeIdsByAncestorRepeatableNodeId[ancestorNodeId] = [];
  }

  fieldNodeIdsByAncestorRepeatableNodeId[ancestorNodeId].push(nodeDetail);
  allWithAncestors.push(nodeDetail);
}

function getAllAncestorCollectionNodeIds(answerPath: AnswerPath): string[] {
  const collectionNodeIds: string[] = [];

  let parent = answerPath.parent;
  while (parent) {
    if (parent.isCollection && parent.nodeId) {
      collectionNodeIds.unshift(parent.nodeId);
    }

    parent = parent.parent;
  }

  return collectionNodeIds;
}

function getSelectOptionsForField(fieldBlueprint: SelectOptionFieldBlueprint): NodeIdSelectOption[] {
  return fieldBlueprint.selectOptions.map((selectOptionBlueprint) => {
    return {
      optionName: selectOptionBlueprint.partName,
      optionLabel: _.merge({ en: '', fr: '' }, selectOptionBlueprint.text),
    };
  });
}

function getAddressAutocompleteNodeDetail(
  field: FieldBlueprint,
  subsection: SubsectionBlueprint,
  collectionNodeIds: string[],
): NodeDetail | undefined {
  const addressAutocompleteNodeId =
    isAddressAutocompleteFieldBlueprint(field) && field.addressAutocompleteNodeId
      ? field.addressAutocompleteNodeId
      : undefined;
  if (addressAutocompleteNodeId) {
    const nodeDetail: NodeDetail = {
      answerNodeId: addressAutocompleteNodeId,
      fieldType: field.fieldType,
      groupName: subsection.title ?? UNTITLED_SUBSECTION_GROUP_NAME,
      collectionNodeIds,
    };

    return nodeDetail;
  }

  return undefined;
}
