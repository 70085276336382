import { ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs, TabType } from '../../../Components/Tabs/Tabs';
import { SectionGroupId } from '../../../Helpers/questionnaireAnswers';
import { useAssistedApplicationContext } from '../../../Hooks/useAssistedApplicationContext';
import { HeaderTabContainer } from './Styles';

export function HeaderTabBar(): ReactElement {
  const { currentSectionGroupId, onSwitchSectionGroupTab } = useAssistedApplicationContext();
  const { t } = useTranslation();

  // TODO: Fetch the section groups information from the questionnaire https://zinnia.atlassian.net/browse/HOT-4668
  const tabs: TabType[] = [
    { label: t('assistedApplication.multiInsuredTabs.insuredPersons') },
    { label: t('assistedApplication.multiInsuredTabs.policyContract') },
  ];

  const onSwitchTabs = useCallback(
    (newValue: number) => {
      const sectionGroupId = newValue === 1 ? SectionGroupId.contract : SectionGroupId.insuredPeople;
      onSwitchSectionGroupTab(sectionGroupId);
    },
    [onSwitchSectionGroupTab],
  );

  return (
    <HeaderTabContainer>
      <Tabs
        value={currentSectionGroupId === SectionGroupId.contract ? 1 : 0}
        onChange={(_event, newValue) => onSwitchTabs(newValue)}
        tabs={tabs}
      />
    </HeaderTabContainer>
  );
}
