import _ from 'lodash';
import { ChangeEvent, ReactElement, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FormControl, InputAdornment, InputLabel, OutlinedInput } from '@breathelife/mui';

import { Search } from '../../Assets/Search';

export type SearchTextFieldProps = {
  onSearchChange: (searchString: string) => void;
  fullWidth?: boolean;
  defaultValue?: string;
  label?: string;
};

export function SearchTextField(props: SearchTextFieldProps): ReactElement | null {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState<string>(props.defaultValue || '');

  const debouncedSearch = useRef(
    _.debounce((search: string) => {
      props.onSearchChange(search);
    }, 400),
  );

  const onChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const searchString = event.target.value;
    setInputValue(searchString);
    debouncedSearch.current(searchString);
  };

  const label = props.label || t('leadsListTable.search');

  return (
    <FormControl fullWidth={props.fullWidth} size={'small'}>
      <InputLabel htmlFor='outlined-adornment-search'>{label}</InputLabel>
      <OutlinedInput
        id='outlined-adornment-search'
        type='search'
        value={inputValue}
        onChange={onChange}
        endAdornment={
          <InputAdornment position='start'>
            <Search role='presentation' />
          </InputAdornment>
        }
        label={label}
      />
    </FormControl>
  );
}
