import { Fragment, ReactElement, useCallback, useMemo } from 'react';

import { Box } from '@breathelife/ui-components';

import { HelmetContainer } from '../../../Components/HelmetContainer';

import { useTranslation } from 'react-i18next';
import { ApiESignAdHocBaseRequest } from '@breathelife/types';
import { CellProps } from 'react-table';
import dayjs from 'dayjs';
import { Table } from '../../../Components/Table/Table';
import styled from '../../../Styles/themed-styled-components';
import { useFetchAllAdHocSignatureRequestsQuery } from '../../../ReactQuery/Admin/AdHocSignatureRequests/adHocSignatureRequests.queries';
import LoadingView from '../../../Components/LoadingView/LoadingView';
import { generateAdHocSignatureRequestUrl } from '../../../Navigation/Urls';
import { useNavigate } from 'react-router-dom';

const TextOverFlowCell = styled.div`
  max-width: 300px;
  overflow-wrap: break-word;
`;

type AdhocSignatureRequestsTableColum = {
  id?: string;
  Header: string;
  accessor: keyof ApiESignAdHocBaseRequest;
  disableSortBy?: boolean;
  hidden?: boolean;
  Cell?: (props: CellProps<ApiESignAdHocBaseRequest>) => ReactElement;
};

export function AdHocSignatureRequestsView(): ReactElement {
  const { t } = useTranslation();
  const { data: adHocSignatureRequests, isLoading } = useFetchAllAdHocSignatureRequestsQuery();
  const navigate = useNavigate();

  const onSelectAdHocSignatureRequest = useCallback(
    (questionnaireVersionId: string) => {
      navigate(generateAdHocSignatureRequestUrl(questionnaireVersionId));
    },
    [navigate],
  );

  const tableColumns = useMemo<AdhocSignatureRequestsTableColum[]>(
    () => [
      {
        Header: t('admin.adHocSignatureRequestsManagement.table.context'),
        accessor: 'context',
        disableSortBy: true,
      },
      {
        Header: t('admin.adHocSignatureRequestsManagement.table.applicationId'),
        accessor: 'applicationId',
        Cell: function ({ cell: { value } }: { cell: { value: string } }): ReactElement {
          return <TextOverFlowCell>{value}</TextOverFlowCell>;
        },
      },
      {
        Header: t('admin.adHocSignatureRequestsManagement.table.refNo'),
        accessor: 'refNo',
        Cell: function ({ cell: { value } }: { cell: { value: string } }): ReactElement {
          return <TextOverFlowCell>{value}</TextOverFlowCell>;
        },
      },
      {
        Header: t('admin.adHocSignatureRequestsManagement.table.createdAt'),
        accessor: 'createdAt',
        Cell: function ({ cell: { value } }: { cell: { value: Date } }): ReactElement {
          return <Fragment>{dayjs(value).format('D MMMM YYYY HH:MM A')}</Fragment>;
        },
      },
      {
        Header: t('admin.adHocSignatureRequestsManagement.table.status'),
        accessor: 'status',
        Cell: function ({ cell: { value } }: { cell: { value: string } }): ReactElement {
          return <Fragment>{t(`admin.adHocSignatureRequestsManagement.requestStatuses.${value}`)}</Fragment>; // TODO color by status
        },
      },
    ],
    [t],
  );

  if (isLoading) {
    return <LoadingView />;
  }

  return (
    <Fragment>
      <HelmetContainer text={t('pageTitles.adHocSignatureRequests')} />
      <Box m={2} display='flex' flexDirection='column' flexGrow={1}>
        <Box display='flex' justifyContent='space-between'>
          <Box display='flex' ml={1}></Box>
        </Box>

        <Box mt={2}>
          <Table<ApiESignAdHocBaseRequest>
            onRowClick={onSelectAdHocSignatureRequest}
            columns={tableColumns}
            data={adHocSignatureRequests || []}
            onOrderChange={() => {}}
          />
        </Box>
      </Box>
    </Fragment>
  );
}
