import { Box } from '@breathelife/mui';
import _ from 'lodash';
import { Fragment, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, RouteProps, useLocation } from 'react-router-dom';

import { Permission } from '@breathelife/types';
import { Loader } from '@breathelife/ui-components';

import Typography from '../Components/Typography';
import { userHasPermission } from '../Helpers/user';
import { useDispatch, useSelector } from '../Hooks';
import Urls from '../Navigation/Urls';
import { getUser } from '../ReduxStore/Authentication/UserOperations';
import { isTokenExpired } from '../Services/Auth0';
import { RequiredAuthErrorContainer } from './Styles';

function RequiredAuth(
  props: Pick<RouteProps, 'children'> & { permissions?: Permission | Permission[] },
): ReactElement | null {
  const authentication = useSelector((store) => store.leadPlatform.authentication);
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const [fetchingNewUserFormat, setFetchingNewUserFormat] = useState(false);

  const userPermissions = authentication.user?.permissions;
  const isAuthorized = authentication.isAuthenticated && authentication.token && !isTokenExpired(authentication.token);
  const isOldUserFormat = isAuthorized && !authentication.user?.auth0Id;
  const isLoading = useSelector((store) => store.leadPlatform.authentication.isLoading);

  useEffect(() => {
    // If ever `authentication.user.id` is invalid, it will cause an infinite loop, spamming our API
    // since `getUser` will fail and modifies the authentication.isLoading which causes a re-render.
    // We need to protect ourself against that.
    if (isOldUserFormat && authentication.user && !isLoading && !fetchingNewUserFormat) {
      setFetchingNewUserFormat(true);
      dispatch(getUser(authentication.user.id));
    }
  }, [dispatch, isOldUserFormat, authentication.user, isLoading, fetchingNewUserFormat]);

  if (!isAuthorized) {
    const { pathname, search, hash } = location;
    const pathQueryHash = `${pathname}${search}${hash}`;

    return (
      <Navigate
        to={{
          pathname: Urls.login.fullPath,
          search, // Keep any incoming query strings
        }}
        state={{ targetRoute: pathQueryHash }}
        replace
      />
    );
  }

  // The user is currently logged in and has the old
  // user type in their store. We should fetch the
  // user data in the new format and save it in the store.
  if (isOldUserFormat && authentication.user) {
    if (isLoading) {
      return (
        <Fragment>
          <Box height='100vh' display='flex' justifyContent='center' alignItems='center'>
            <Loader />
          </Box>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <Box height='100vh' display='flex'>
          <RequiredAuthErrorContainer margin='auto' p={4}>
            <Typography component='p' variant='body1'>
              {t('authentication.generalErrorText')}
            </Typography>
          </RequiredAuthErrorContainer>
        </Box>
      </Fragment>
    );
  }

  if (props.permissions && userPermissions) {
    const allowedPermissions = !_.isArray(props.permissions) ? [props.permissions] : props.permissions;
    const hasPermissions = userHasPermission(allowedPermissions, userPermissions);
    if (!hasPermissions) {
      return <Navigate to={{ pathname: Urls.pro.fullPath }} replace />;
    }
  }

  return <Fragment>{props.children}</Fragment>;
}

export default RequiredAuth;
