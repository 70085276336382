import { Box } from '@breathelife/mui';
import styled from '../../../../../../../Styles/themed-styled-components';

export const SubsectionContainer = styled(Box)`
  border-bottom: 1px solid ${(props) => props.theme.colors.grey[40]};

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }

  &:first-child {
    margin-top: 0;
  }
`;

export const SectionGroupContainer = styled(Box)`
  &:last-child {
    padding-bottom: 0;
  }

  &:first-child {
    margin-top: 0;
  }
`;
