import { ESignCeremonyStatus, ESignSignerStatus } from '@breathelife/types';

const translation: {
  ceremonyStatus: { [key in ESignCeremonyStatus & 'unknown']: string };
  signerStatus: { [key in ESignSignerStatus & 'notSigned' & 'unknown']: string };
} = {
  ceremonyStatus: {
    [ESignCeremonyStatus.DRAFT]: 'Brouillon',
    [ESignCeremonyStatus.IN_PROGRESS]: 'Préparation des documents',
    [ESignCeremonyStatus.READY]: 'Traitement des documents',
    [ESignCeremonyStatus.SENT]: 'En attente de signature',
    [ESignCeremonyStatus.COMPLETED]: 'Signé',
    [ESignCeremonyStatus.EMAIL_BOUNCED]: 'Courriel non transmis',
    [ESignCeremonyStatus.DECLINED]: 'Signature refusée',
    [ESignCeremonyStatus.OPT_OUT]: 'Le signataire s’est retiré de la signature',
    [ESignCeremonyStatus.CANCELLED]: 'Demande de signature annulée',
    [ESignCeremonyStatus.EXPIRED]: 'Demande de signature expirée',
    [ESignCeremonyStatus.SIGNER_LOCKED_OUT]: 'Signataire bloqué hors de la signature',
    [ESignCeremonyStatus.SEND_PACKAGE_ERROR]: "Erreur lors de l'envoi de la demande",
    unknown: 'Inconnu',
  },
  signerStatus: {
    [ESignSignerStatus.EMAIL_BOUNCED]: 'Courriel retourné',
    [ESignSignerStatus.EXPIRED]: 'Expiré',
    [ESignSignerStatus.INTERNAL_SIGNING_EMAIL_SENT]: 'Courriel envoyé',
    [ESignSignerStatus.SIGNED]: 'Signé',
    [ESignSignerStatus.SIGNER_LOCKED_OUT]: 'Bloqué',
    notSigned: "N'a pas signé",
    unknown: 'Inconnu',
  },
};
export default translation;
