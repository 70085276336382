import i18next from 'i18next';

import { MessageType } from '@breathelife/ui-components';

import {
  getTranslationKeyFromErrorId,
  getTranslationKeysFromErrorIds,
} from '../../Localization/errorIdsToTranslationMap';
import { notificationSlice } from '../../ReduxStore/Notification/NotificationSlice';
import { Dispatch } from '../../ReduxStore/types';
import * as IdentityVerificationService from '../../Services/IdentityVerificationService';
import { identityVerificationSlice } from './IdentityVerificationSlice';

const buildValidationErrorsMessage = (error: unknown): string => {
  const translationKeysFromErrorIds = getTranslationKeysFromErrorIds(error);
  if (!translationKeysFromErrorIds) return i18next.t('notifications.identityVerificationToast.unexpectedError');

  const errorTranslations = translationKeysFromErrorIds.map(
    (translationKey) => `<li>${i18next.t(translationKey)}</li>`,
  );
  const validationErrorMessageMarkup = `<ul style="padding: 0">${errorTranslations.join('')}</ul>`;

  return validationErrorMessageMarkup;
};

export const sendIdentityVerificationRequest =
  (assistedApplicationId: string | undefined) =>
  async (dispatch: Dispatch): Promise<void | undefined> => {
    try {
      const submissionResponse =
        await IdentityVerificationService.sendIdentityVerificationRequest(assistedApplicationId);
      const { identityVerificationStatus, riskIndicators } = submissionResponse.data;

      if (identityVerificationStatus === 'success') {
        dispatch(
          notificationSlice.actions.setNotification({
            type: MessageType.success,
            title: i18next.t('notifications.identityVerificationToast.success.title'),
            message: i18next.t('notifications.identityVerificationToast.success.description'),
            autoHideDuration: 5000,
          }),
        );
      } else if (identityVerificationStatus === 'minor') {
        dispatch(
          notificationSlice.actions.setNotification({
            type: MessageType.warning,
            title: i18next.t('notifications.identityVerificationToast.info.minor.title'),
            message: i18next.t('notifications.identityVerificationToast.info.minor.description'),
            autoHideDuration: 5000,
          }),
        );
      } else {
        dispatch(
          notificationSlice.actions.setNotification({
            type: MessageType.warning,
            title: i18next.t('notifications.identityVerificationToast.warning.title'),
            message: i18next.t('notifications.identityVerificationToast.warning.description'),
            autoHideDuration: 5000,
          }),
        );
      }
      dispatch(identityVerificationSlice.actions.setIdentityVerificationStatus(identityVerificationStatus));
      dispatch(identityVerificationSlice.actions.setIsLoading(false));
      dispatch(identityVerificationSlice.actions.setRiskIndicatorReport(riskIndicators));
      dispatch(identityVerificationSlice.actions.setIsIdentityVerificationCheckCompleted(true));
    } catch (error: any) {
      const status = error?.response?.status;
      if (status === 444) {
        dispatch(
          notificationSlice.actions.setNotification({
            type: MessageType.error,
            message: i18next.t('notifications.identityVerificationToast.failedToCheckConsent'),
            autoHideDuration: 5000,
          }),
        );
        dispatch(identityVerificationSlice.actions.reset());
      } else if (status === 400) {
        const validationErrorMessage = buildValidationErrorsMessage(error);
        dispatch(
          notificationSlice.actions.setNotification({
            type: MessageType.error,
            title: i18next.t('notifications.identityVerificationToast.validationErrors.title'),
            message: validationErrorMessage,
            autoHideDuration: 5000,
          }),
        );
        dispatch(identityVerificationSlice.actions.reset());
      } else if (status === 408) {
        dispatch(identityVerificationSlice.actions.setIdentityVerificationStatus('retry'));
        dispatch(
          notificationSlice.actions.setNotification({
            type: MessageType.error,
            title: i18next.t('notifications.identityVerificationToast.serverErrors.title'),
            message: i18next.t(
              getTranslationKeyFromErrorId(error) || 'notifications.identityVerificationToast.unexpectedError',
            ),
            autoHideDuration: 5000,
          }),
        );
      } else {
        dispatch(identityVerificationSlice.actions.setIdentityVerificationStatus('retry'));
        dispatch(
          notificationSlice.actions.setNotification({
            type: MessageType.error,
            title: i18next.t('notifications.identityVerificationToast.error.title'),
            message: i18next.t('notifications.identityVerificationToast.error.description'),
            autoHideDuration: 5000,
          }),
        );
      }
    }
    dispatch(identityVerificationSlice.actions.setIsIdentityVerificationCheckCompleted(true));
    dispatch(identityVerificationSlice.actions.setIsLoading(false));
  };
