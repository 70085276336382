import { Context, ReactElement, PropsWithChildren, useCallback, useMemo, useState } from 'react';

import { checkForSameParentInReorder } from '@breathelife/insurance-form-builder';
import {
  FieldBlueprintReorder,
  isFieldPartIdentifier,
  isQuestionPartIdentifier,
  isSectionPartIdentifier,
  isSubsectionPartIdentifier,
  PartIdentifier,
  QuestionBlueprintReorder,
  SubsectionBlueprintReorder,
} from '@breathelife/types';
import { TreeViewRepositionOptions } from '@breathelife/ui-components';

import { makePartIdentifierFromTreePath } from '../Helpers/makePartIdentifierFromTreePath';
import { SelectedPartIdentifier } from '../types';

export type QuestionnaireTreeViewDragContextValue = {
  isUserDragging: boolean;
  isCurrentDragValid?: boolean;
  handleDragStart: (draggedItemPath: string[]) => void;
  handleDragEnd: () => void;
  handleDragUpdate: (
    sourceIdentifier: string[],
    isValid: boolean,
    options?: TreeViewRepositionOptions | undefined,
  ) => void;
  currentDraggedItemPartIdentifier?: PartIdentifier | null;
};

export function QuestionnaireTreeViewDragContextProvider({
  children,
  context,
}: PropsWithChildren<{ context: Context<QuestionnaireTreeViewDragContextValue> }>): ReactElement {
  const [isUserDragging, setIsUserDragging] = useState<boolean>(false);
  const [isTreeDragInvalid, setIsTreeDragInvalid] = useState<boolean>(false);
  const [currentRepositionOptions, setCurrentRepositionOptions] = useState<TreeViewRepositionOptions | undefined>();
  const [currentDraggedItemPartIdentifier, setCurrentDraggedItemPartIdentifier] = useState<
    SelectedPartIdentifier | undefined
  >();

  const isCurrentDragValid = useMemo(() => {
    if (isTreeDragInvalid) {
      return false;
    }
    if (!currentDraggedItemPartIdentifier) {
      return true;
    }

    if (isSectionPartIdentifier(currentDraggedItemPartIdentifier)) {
      return true;
    }

    if (!currentRepositionOptions?.newParentPath) {
      return true;
    }

    const newParentPartIdentifier = makePartIdentifierFromTreePath(currentRepositionOptions.newParentPath);
    const newSiblingPartIdentifier = currentRepositionOptions.newSiblingPath
      ? makePartIdentifierFromTreePath(currentRepositionOptions.newSiblingPath)
      : undefined;

    let reorder;

    if (isSubsectionPartIdentifier(currentDraggedItemPartIdentifier)) {
      reorder = {
        partIdentifier: currentDraggedItemPartIdentifier,
        reorder: {
          parentIdentifier: newParentPartIdentifier,
          newPreviousSiblingIdentifier: newSiblingPartIdentifier,
        },
      } as SubsectionBlueprintReorder;
    }

    if (isQuestionPartIdentifier(currentDraggedItemPartIdentifier)) {
      reorder = {
        partIdentifier: currentDraggedItemPartIdentifier,
        reorder: {
          parentIdentifier: newParentPartIdentifier,
          newPreviousSiblingIdentifier: newSiblingPartIdentifier,
        },
      } as QuestionBlueprintReorder;
    }

    if (isFieldPartIdentifier(currentDraggedItemPartIdentifier)) {
      reorder = {
        partIdentifier: currentDraggedItemPartIdentifier,
        reorder: {
          parentIdentifier: newParentPartIdentifier,
          newPreviousSiblingIdentifier: newSiblingPartIdentifier,
        },
      } as FieldBlueprintReorder;
    }

    if (!reorder) {
      return true;
    }

    try {
      checkForSameParentInReorder(reorder);
      return true;
    } catch {
      return false;
    }
  }, [currentDraggedItemPartIdentifier, currentRepositionOptions, isTreeDragInvalid]);

  const handleDragUpdate = useCallback(
    (sourceIdentifier: string[], isValid: boolean, options?: TreeViewRepositionOptions | undefined) => {
      if (!isValid) {
        setIsTreeDragInvalid(true);
      } else {
        setIsTreeDragInvalid(false);
      }
      setCurrentRepositionOptions(options);
      if (!sourceIdentifier) {
        setCurrentDraggedItemPartIdentifier(undefined);
      } else {
        const draggedItemPartIdentifier = makePartIdentifierFromTreePath(sourceIdentifier);
        setCurrentDraggedItemPartIdentifier(draggedItemPartIdentifier);
      }
    },
    [setCurrentDraggedItemPartIdentifier, setCurrentRepositionOptions, setIsTreeDragInvalid],
  );

  const handleDragStart = useCallback(
    (draggedItemPath: string[]) => {
      setIsUserDragging(true);
      if (!draggedItemPath) {
        setCurrentDraggedItemPartIdentifier(undefined);
      } else {
        const draggedItemPartIdentifier = makePartIdentifierFromTreePath(draggedItemPath);
        setCurrentDraggedItemPartIdentifier(draggedItemPartIdentifier);
      }
    },
    [setCurrentDraggedItemPartIdentifier, setIsUserDragging],
  );

  const handleDragEnd = useCallback(() => {
    setIsUserDragging(false);
    setIsTreeDragInvalid(false);
    setCurrentRepositionOptions(undefined);
    setCurrentDraggedItemPartIdentifier(undefined);
  }, [setCurrentDraggedItemPartIdentifier, setIsUserDragging]);

  return (
    <context.Provider
      value={{
        isUserDragging,
        isCurrentDragValid,
        handleDragStart,
        handleDragEnd,
        handleDragUpdate,
        currentDraggedItemPartIdentifier,
      }}
    >
      {children}
    </context.Provider>
  );
}
