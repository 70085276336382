import { Box } from '@breathelife/mui';
import styled from '../../../../Styles/themed-styled-components';

export const StyledLink = styled.a`
  color: ${(props) => props.theme.colors.blue[50]};
  text-decoration: underline;
  cursor: pointer;
  margin-right: 24px;
`;

export const StyledActionBanner = styled(Box)<{ variantColor: string }>`
  padding: 12px;
  background-color: ${({ variantColor, ...props }) => props.theme.colors[variantColor][20]};
  border: ${({ variantColor, ...props }) => `1px solid ${props.theme.colors[variantColor][30]}`};
  display: flex;
  border-radius: 3px;
  box-shadow:
    0px 0px 1px rgba(117, 117, 117, 0.31),
    0px 1px 1px rgba(117, 117, 117, 0.25);
`;

export const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
`;
