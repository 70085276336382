import { ReactElement } from 'react';

import { SvgIconProps } from '../../Components/Icons';

export function InfoIcon(props: SvgIconProps): ReactElement {
  return (
    <svg
      viewBox='0 0 24 24'
      stroke='#757575'
      fill='#f6f6f6'
      height='24'
      width='24'
      xmlns='http://www.w3.org/2000/svg'
      {...props.svgProps}
    >
      <path
        d='M.75 12a11.25 11.25 0 1022.5 0 11.25 11.25 0 00-22.5 0z'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
      />
      <path d='M14.25 16.5h-.75A1.5 1.5 0 0112 15v-3.75a.75.75 0 00-.75-.75h-.75' />
      <path
        d='M14.25 16.5h-.75A1.5 1.5 0 0112 15v-3.75a.75.75 0 00-.75-.75h-.75m1.125-3.75a.375.375 0 100 .75.375.375 0 000-.75z'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
      />
    </svg>
  );
}
