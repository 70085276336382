import _ from 'lodash';
import { ReactElement, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { Permission } from '@breathelife/types';

import { userHasPermission } from '../../Helpers/user';
import { useSelector } from '../../Hooks';
import { StyledTab, StyledTabs } from './Styles';

export type Tabs = {
  ['data-testid']: string;
  label: string;
  to: string;
  permissions?: Permission[];
  showWhenNotAllowed?: boolean;
};

type PageTabsProps = {
  tabs: Tabs[];
  defaultTab?: string;
};

export function PageTabs(props: PageTabsProps): ReactElement {
  const location = useLocation();
  const navigate = useNavigate();
  const userPermissions =
    useSelector((store) => {
      return store.leadPlatform.authentication.user?.permissions;
    }) ?? [];

  const { tabs, defaultTab } = props;

  const onSelectedTabChange = useCallback(
    // disable linting error on unused _event
    // eslint-disable-next-line @typescript-eslint/naming-convention
    (_event: any, value: string) => {
      navigate(value);
    },
    [navigate],
  );

  const currentTabFromTabs = tabs.find((tab) => location.pathname.includes(tab.to));
  // If current location is not part of any tab, we fallback on default tab if it is set, otherwise we use the first tab
  const currentTab = currentTabFromTabs?.to ?? defaultTab ?? tabs[0].to;

  return (
    <StyledTabs
      value={currentTab}
      onChange={onSelectedTabChange}
      variant='scrollable'
      indicatorColor='primary'
      textColor='primary'
      role='heading'
      aria-level={1}
    >
      {tabs.map((tab) => {
        const allowed = _.isUndefined(tab.permissions) || userHasPermission(userPermissions, tab.permissions);

        if (!allowed && !tab.showWhenNotAllowed) return null;

        return (
          <StyledTab
            key={tab.label}
            label={tab.label}
            value={tab.to}
            disabled={!allowed}
            data-testid={tab['data-testid']}
          />
        );
      })}
    </StyledTabs>
  );
}
