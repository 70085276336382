import { ChangeEvent, ReactElement, useCallback, useMemo } from 'react';

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
} from '@breathelife/mui';
import { StyledFormControlLabel } from '@breathelife/ui-components';

import styled from '../../Styles/themed-styled-components';

export type Options<T extends string | number> = {
  value: T;
  label: string | number | ReactElement;
};

const StyledFormControl = styled(FormControl)`
  width: 200px;
`;

export type SelectProps<T extends string | number = string> = {
  classes?: { [key: string]: unknown };
  id: string;
  label?: string;
  labelId?: string;
  required?: boolean;
  value: T;
  onChange: (value: T, event: ChangeEvent<{ value: unknown }>) => void;
  options: Options<T>[];
  className?: string;
  ['data-testid']?: string;
  disabled?: boolean;
  isSearchFilter?: boolean; // This prop is to be used only with selects (dropdowns) that filter search results in a table for accessibility.
};

export function Select<T extends string | number>(props: SelectProps<T>): ReactElement {
  const { onChange: onChangeProp } = props;

  const onChange = useCallback(
    (value: T, event: ChangeEvent<{ value: unknown }>) => {
      onChangeProp(value, event);
    },
    [onChangeProp],
  );

  const labelId = useMemo(() => props.labelId || `${props.id}-label`, []);

  const menuOptions = props.options.map((option) => (
    <MenuItem key={option.value} value={option.value} data-testid={`${props['data-testid']}_${option.value}`}>
      {option.label}
    </MenuItem>
  ));

  const selectProps: MuiSelectProps & {
    ['data-testid']?: string;
  } = {
    id: props.id,
    labelId,
    value: props.value,
    onChange: (e) => onChange(e.target.value as any, e as ChangeEvent<{ value: unknown }>),
    MenuProps: {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
    },
    ['data-testid']: props['data-testid'],
    disabled: props.disabled,
  };

  const labelText = props.label ? `${props.label}${props.required ? ' *' : ''}` : null;

  // This specific component is only used for selects (dropdowns) that filter search results in a table for accessibility.
  // It changes the styling to display the label as part of the input's border, instead of on top of the input.
  if (props.isSearchFilter) {
    if (labelText) {
      selectProps.label = labelText;
    }

    return (
      <StyledFormControl className={props.className} size='small'>
        <InputLabel id={labelId}>{labelText}</InputLabel>
        <MuiSelect {...selectProps}>{menuOptions}</MuiSelect>
      </StyledFormControl>
    );
  }

  return (
    <FormControl fullWidth className={props.className}>
      <StyledFormControlLabel
        showError={false}
        control={<MuiSelect {...selectProps}>{menuOptions}</MuiSelect>}
        label={labelText}
        labelPlacement='top'
      />
    </FormControl>
  );
}
