import { ReactElement } from 'react';

import { SvgIconProps } from '../../Components/Icons';

export function LicensesIcon(props: SvgIconProps): ReactElement {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      stroke='#757575'
      fill='#f6f6f6'
      xmlns='http://www.w3.org/2000/svg'
      role='img'
      aria-label='licenses'
      {...props.svgProps}
    >
      <g strokeLinecap='round' strokeLinejoin='round'>
        <path d='M6.5 3.5H1.5C1.23478 3.5 0.98043 3.60536 0.792893 3.79289C0.605357 3.98043 0.5 4.23478 0.5 4.5V13.5C0.5 13.7652 0.605357 14.0196 0.792893 14.2071C0.98043 14.3946 1.23478 14.5 1.5 14.5H14.5C14.7652 14.5 15.0196 14.3946 15.2071 14.2071C15.3946 14.0196 15.5 13.7652 15.5 13.5V4.5C15.5 4.23478 15.3946 3.98043 15.2071 3.79289C15.0196 3.60536 14.7652 3.5 14.5 3.5H9.5' />
        <path d='M9.5 3C9.5 2.60218 9.34196 2.22064 9.06066 1.93934C8.77936 1.65804 8.39782 1.5 8 1.5C7.60218 1.5 7.22064 1.65804 6.93934 1.93934C6.65804 2.22064 6.5 2.60218 6.5 3V5C6.5 5.13261 6.55268 5.25979 6.64645 5.35355C6.74022 5.44732 6.86739 5.5 7 5.5H9C9.13261 5.5 9.25978 5.44732 9.35355 5.35355C9.44732 5.25979 9.5 5.13261 9.5 5V3Z' />
        <path d='M3.75 9.25C3.75 9.47981 3.79527 9.70738 3.88321 9.9197C3.97116 10.132 4.10006 10.3249 4.26256 10.4874C4.42507 10.6499 4.61798 10.7788 4.8303 10.8668C5.04262 10.9547 5.27019 11 5.5 11C5.72981 11 5.95738 10.9547 6.1697 10.8668C6.38202 10.7788 6.57493 10.6499 6.73744 10.4874C6.89994 10.3249 7.02884 10.132 7.11679 9.9197C7.20474 9.70738 7.25 9.47981 7.25 9.25C7.25 9.02019 7.20474 8.79262 7.11679 8.5803C7.02884 8.36798 6.89994 8.17507 6.73744 8.01256C6.57493 7.85006 6.38202 7.72116 6.1697 7.63321C5.95738 7.54526 5.72981 7.5 5.5 7.5C5.27019 7.5 5.04262 7.54526 4.8303 7.63321C4.61798 7.72116 4.42507 7.85006 4.26256 8.01256C4.10006 8.17507 3.97116 8.36798 3.88321 8.5803C3.79527 8.79262 3.75 9.02019 3.75 9.25V9.25Z' />
        <path d='M2.5 14.5C2.5 13.7044 2.81607 12.9413 3.37868 12.3787C3.94129 11.8161 4.70435 11.5 5.5 11.5C6.29565 11.5 7.05871 11.8161 7.62132 12.3787C8.18393 12.9413 8.5 13.7044 8.5 14.5H2.5Z' />
        <path d='M9.5 8.5H12.5' />
        <path d='M9.5 10.5H13.5' />
      </g>
    </svg>
  );
}
