import { FieldBlueprint, CurrencyCardFieldBlueprint, FieldTypes, NumberFieldValidation } from '@breathelife/types';
import { AutocompleteOption } from '@breathelife/ui-components';

import { FieldDataHookReturnValueWithValidationOptions, FieldSettersValidateAs } from '../../Helpers/helperTypes';
import { BaseFieldSetters, useBaseFieldData } from './useBaseFieldData';
import { useValidateAsData } from './useValidateAsData';

export type CurrencyCardFieldSetters = FieldSettersValidateAs<CurrencyCardFieldBlueprint> & BaseFieldSetters;

export const useCurrencyCardFieldData = ({
  initialData,
  dataLabelOptions,
}: {
  initialData?: Partial<FieldBlueprint>;
  dataLabelOptions: AutocompleteOption[];
}): FieldDataHookReturnValueWithValidationOptions<CurrencyCardFieldBlueprint, CurrencyCardFieldSetters> => {
  const fieldType = FieldTypes.currencyCard;
  const {
    data: validateAsData,
    setters: validateAsSetters,
    validationOptions,
  } = useValidateAsData<CurrencyCardFieldBlueprint>({
    fieldType,
    initialValidateAs: NumberFieldValidation.integer,
  });
  const { data, setters } = useBaseFieldData({ initialData, dataLabelOptions });
  return {
    data: { fieldType: fieldType, ...data, ...validateAsData },
    setters: { ...setters, ...validateAsSetters },
    validationOptions,
  };
};
