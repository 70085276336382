import { CreateUserData, User } from '@breathelife/types';
import { useTranslation } from 'react-i18next';
import { UseMutationOptions, UseMutationResult, useQueryClient, useMutation } from 'react-query';
import { notificationSlice } from '../../../ReduxStore/Notification/NotificationSlice';
import { QueryId } from '../../common/common.types';
import UsersService from '../../../Services/Users/UsersService';

import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { DetailViewType } from '../../../Models/Layout';
import { layoutSlice, defaultState } from '../../../ReduxStore/Layout/LayoutSlice';
import { useUsersContext } from '../../../Pages/Admin/UserManagement/UsersContextProvider';

const layoutActions = layoutSlice.actions;
const { actions: notificationActions } = notificationSlice;

export function useCreateUserMutation(
  options?: UseMutationOptions<User, unknown, CreateUserData>,
): UseMutationResult<User, unknown, CreateUserData> {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { setSelectedUser } = useUsersContext();
  const dispatch = useDispatch();
  return useMutation<User, unknown, CreateUserData, unknown>(
    async (data) => {
      const user = await UsersService.createUser(data);
      return user;
    },
    {
      ...options,
      onSuccess: async (user, variables, context) => {
        void queryClient.invalidateQueries([QueryId.users]);

        setSelectedUser(user.auth0Id);
        dispatch(layoutActions.setRightPanelState({ rightPanelState: { type: DetailViewType.edit, isOpen: true } }));
        dispatch(
          notificationSlice.actions.setSuccess({
            message: t('notifications.userSuccessfullyCreated', {
              user: user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.emailLogin,
            }),
            autoHideDuration: 3000,
          }),
        );
        if (options?.onSuccess) {
          await options.onSuccess(user, variables, context);
        }
      },
      onError: async (err: any, variables, context) => {
        notificationSlice.actions.setError({
          message: t('notifications.failedToCreateNewQuestionnaire'),
        });
        switch (err?.response?.status) {
          case 400:
            dispatch(notificationSlice.actions.setError({ message: t('notifications.badRequestGeneral') }));
            break;

          case 403:
            dispatch(notificationSlice.actions.setError({ message: t('notifications.forbiddenGeneral') }));
            break;

          case 409:
            dispatch(notificationSlice.actions.setError({ message: t('notifications.createUserConflict') }));
            break;

          default:
            dispatch(notificationSlice.actions.setError({ message: err.message }));
            break;
        }

        if (options?.onError) {
          await options.onError(err, variables, context);
        }
      },
    },
  );
}

export function useUpdateUserMutation(
  userId: string | undefined,
  isSso?: boolean,
  options?: UseMutationOptions<User | undefined, unknown, Partial<CreateUserData>>,
): UseMutationResult<User | undefined, unknown, Partial<CreateUserData>> {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  return useMutation<User | undefined, unknown, Partial<CreateUserData>, unknown>(
    async (data) => {
      if (!userId) {
        return;
      }
      const filteredData = isSso ? _.omit(data, ['email', 'firstName', 'lastName']) : data;
      return UsersService.updateUserInformation(userId, filteredData);
    },
    {
      ...options,
      onSuccess: async (user, variables, context) => {
        void queryClient.invalidateQueries([QueryId.users]);

        dispatch(
          notificationSlice.actions.setSuccess({
            message: t('notifications.updateUser'),
            autoHideDuration: 3000,
          }),
        );
        if (options?.onSuccess) {
          await options.onSuccess(user, variables, context);
        }
      },
      onError: async (err: any, variables, context) => {
        notificationSlice.actions.setError({
          message: t('notifications.failedToCreateNewQuestionnaire'),
        });
        switch (err?.response?.status) {
          case 400:
            dispatch(notificationSlice.actions.setError({ message: t('notifications.badRequestGeneral') }));
            break;

          case 403:
            dispatch(notificationSlice.actions.setError({ message: t('notifications.forbiddenGeneral') }));
            break;

          default:
            dispatch(notificationSlice.actions.setError({ message: t('notifications.failedToUpdateUser') }));
            break;
        }

        if (options?.onError) {
          await options.onError(err, variables, context);
        }
      },
    },
  );
}

export function useSendInvitationEmailMutation(
  user: User,
  options?: UseMutationOptions<void, unknown, void>,
): UseMutationResult<void, unknown, void> {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return useMutation<void, unknown, void, unknown>(
    async () => {
      await UsersService.sendAuth0InvitationEmail(user.auth0Id);
    },
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        dispatch(
          notificationSlice.actions.setSuccess({
            title: t('notifications.userInvitationEmailSuccessfullySent.title'),
            message: t('notifications.userInvitationEmailSuccessfullySent.message', {
              name: user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.emailLogin,
            }),
            autoHideDuration: 3000,
          }),
        );
        dispatch(layoutActions.setModalState(defaultState));
        if (options?.onSuccess) {
          await options.onSuccess(data, variables, context);
        }
      },
      onError: async (err: any, variables, context) => {
        dispatch(notificationActions.setError({ message: err.message }));

        if (options?.onError) {
          await options.onError(err, variables, context);
        }
      },
    },
  );
}

export function useDeleteUserMutation(
  user: User,
  options?: UseMutationOptions<void, unknown, void>,
): UseMutationResult<void, unknown, void> {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<void, unknown, void, unknown>(
    async () => {
      await UsersService.deleteUser(user.auth0Id);
    },
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        void queryClient.invalidateQueries([QueryId.users]);
        dispatch(
          notificationSlice.actions.setSuccess({
            message: t('notifications.userSuccessfullyDeleted'),
            autoHideDuration: 3000,
          }),
        );
        dispatch(layoutActions.setModalState(defaultState));
        if (options?.onSuccess) {
          await options.onSuccess(data, variables, context);
        }
      },
      onError: async (err: any, variables, context) => {
        dispatch(notificationActions.setError({ message: t('notifications.failedToDeleteUser') }));
        if (options?.onError) {
          await options.onError(err, variables, context);
        }
      },
    },
  );
}
