import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { InsuranceProduct, InsuranceProductPricing } from '@breathelife/types';

export type ProductManagementState = {
  products: InsuranceProduct[];
  selectedProduct?: InsuranceProduct;
  selectedProductPricing: InsuranceProductPricing[] | undefined;
  isLoadingProducts: boolean;
  isLoadingPricing: boolean;
};

type ProductPayload = { product: InsuranceProduct };
type SelectedProductPayload = { selectedProduct?: InsuranceProduct };
type ProductsPayload = { products: InsuranceProduct[] };
type LoadingPayload = { isLoading: boolean };

type ActionsType = {
  setProducts: (state: ProductManagementState, { payload }: PayloadAction<ProductsPayload>) => void;
  setPricingForSelectedProduct: (
    state: ProductManagementState,
    { payload }: PayloadAction<InsuranceProductPricing[] | undefined>,
  ) => void;
  addProduct: (state: ProductManagementState, { payload }: PayloadAction<ProductPayload>) => void;
  updateProduct: (state: ProductManagementState, { payload }: PayloadAction<ProductPayload>) => void;
  setIsLoadingProducts: (state: ProductManagementState, { payload }: PayloadAction<LoadingPayload>) => void;
  setSelectedProduct: (state: ProductManagementState, { payload }: PayloadAction<SelectedProductPayload>) => void;
  setIsLoadingPricing: (state: ProductManagementState, { payload }: PayloadAction<LoadingPayload>) => void;
};

export const defaultState: ProductManagementState = {
  isLoadingProducts: false,
  selectedProduct: undefined,
  selectedProductPricing: undefined,
  products: [],
  isLoadingPricing: false,
};

export const productManagementSlice = createSlice<ProductManagementState, ActionsType>({
  name: 'leadPlatform/productManagement',
  initialState: defaultState,
  reducers: {
    setProducts: (state: ProductManagementState, { payload }: PayloadAction<ProductsPayload>) => {
      state.products = payload.products;
    },
    addProduct: (state: ProductManagementState, { payload }: PayloadAction<ProductPayload>) => {
      state.products = [payload.product].concat(state.products);
    },
    updateProduct: (state: ProductManagementState, { payload }: PayloadAction<ProductPayload>) => {
      state.products = state.products.map((product) =>
        product.id === payload.product.id ? { ...product, ...payload.product } : product,
      );
      if (state.selectedProduct && state.selectedProduct.id === payload.product.id) {
        state.selectedProduct = { ...state.selectedProduct, ...payload.product };
      }
    },
    setSelectedProduct: (state: ProductManagementState, { payload }: PayloadAction<SelectedProductPayload>) => {
      state.selectedProduct = payload.selectedProduct;
      state.selectedProductPricing = payload.selectedProduct?.pricing;
    },
    setPricingForSelectedProduct: (
      state: ProductManagementState,
      { payload }: PayloadAction<InsuranceProductPricing[] | undefined>,
    ) => {
      state.selectedProductPricing = payload;
    },
    setIsLoadingProducts: (state: ProductManagementState, { payload }: PayloadAction<LoadingPayload>) => {
      state.isLoadingProducts = payload.isLoading;
    },
    setIsLoadingPricing: (state: ProductManagementState, { payload }: PayloadAction<LoadingPayload>) => {
      state.isLoadingPricing = payload.isLoading;
    },
  },
});
