import { ReactElement, Fragment, useMemo } from 'react';

import { BlueprintSingleConditionValue, ConditionBlueprintType, Language } from '@breathelife/types';

import { buildNodeIdList } from '../../Helpers/conditions/blueprintHelpers';
import { ConditionPropertiesUpdateCallback } from './types';
import { criteriaMetadata } from '../../Helpers/questionnaireEditor/criteriaMetadata';
import { NodeIdInCollections, QuestionnaireNodeIds } from '../../Helpers/questionnaireEditor/questionnaireNodeIds';

import { AgeRangeConditionCriteria } from './Criteria/AgeRangeConditionCriteria';
import { BmiRangeConditionCriteria } from './Criteria/BmiRangeConditionCriteria';
import { CharacterRangeConditionCriteria } from './Criteria/CharacterRangeConditionCriteria';
import { CountConditionCriteria } from './Criteria/CountConditionCriteria';
import { DateComparisonConditionCriteria } from './Criteria/DateComparisonConditionCriteria';
import { DateConditionCriteria } from './Criteria/DateConditionCriteria';
import { EmptinessConditionCriteria } from './Criteria/EmptinessConditionCriteria';
import { EqualityConditionCriteria } from './Criteria/EqualityConditionCriteria';
import { MatchesConditionCriteria } from './Criteria/MatchesConditionCriteria';
import { MatchesRegexConditionCriteria } from './Criteria/MatchesRegexConditionCriteria';
import { MathOperatorConditionCriteria } from './Criteria/MathOperatorConditionCriteria';
import { NumberComparisonConditionCriteria } from './Criteria/NumberComparisonConditionCriteria';
import { PercentOfConditionCriteria } from './Criteria/PercentOfConditionCriteria';
import { ReflexiveConditionCriteria } from './Criteria/ReflexiveConditionCriteria';

type Props = {
  condition: BlueprintSingleConditionValue;
  questionnaireNodeIds: QuestionnaireNodeIds;
  onConditionPropertiesChange: ConditionPropertiesUpdateCallback<BlueprintSingleConditionValue>;
  language: Language;
  collectionContext: string[];
  nodeIdInCollectionMap: NodeIdInCollections;
  conditionIndex: string;
};

export function SingleConditionCriteria(props: Props): ReactElement {
  const { condition, questionnaireNodeIds, collectionContext, ...restProps } = props;
  const selectOptionsByNodeId = questionnaireNodeIds.selectOptionsByNodeId;

  const nodeIdList = useMemo(() => {
    return buildNodeIdList(condition.type, questionnaireNodeIds, criteriaMetadata);
  }, [questionnaireNodeIds, condition.type]);

  switch (condition.type) {
    case ConditionBlueprintType.ageRange:
      return (
        <AgeRangeConditionCriteria
          condition={condition}
          nodeIdList={nodeIdList}
          selectOptionsByNodeId={selectOptionsByNodeId}
          collectionContext={collectionContext}
          {...restProps}
        />
      );
    case ConditionBlueprintType.bmiRange:
      return (
        <BmiRangeConditionCriteria
          condition={condition}
          nodeIdList={nodeIdList}
          selectOptionsByNodeId={selectOptionsByNodeId}
          collectionContext={collectionContext}
          {...restProps}
        />
      );
    case ConditionBlueprintType.characterCountInBetween:
      return (
        <CharacterRangeConditionCriteria
          condition={condition}
          nodeIdList={nodeIdList}
          selectOptionsByNodeId={selectOptionsByNodeId}
          collectionContext={collectionContext}
          {...restProps}
        />
      );
    case ConditionBlueprintType.countEqual:
      return (
        <CountConditionCriteria
          condition={condition}
          nodeIdList={nodeIdList}
          selectOptionsByNodeId={selectOptionsByNodeId}
          collectionContext={collectionContext}
          {...restProps}
        />
      );
    case ConditionBlueprintType.dateComparison:
      return (
        <DateComparisonConditionCriteria
          condition={condition}
          nodeIdList={nodeIdList}
          selectOptionsByNodeId={selectOptionsByNodeId}
          collectionContext={collectionContext}
          {...restProps}
        />
      );
    case ConditionBlueprintType.emptiness:
      return (
        <EmptinessConditionCriteria
          condition={condition}
          nodeIdList={nodeIdList}
          selectOptionsByNodeId={selectOptionsByNodeId}
          collectionContext={collectionContext}
          {...restProps}
        />
      );
    case ConditionBlueprintType.equality:
      return (
        <EqualityConditionCriteria
          condition={condition}
          nodeIdList={nodeIdList}
          selectOptionsByNodeId={selectOptionsByNodeId}
          collectionContext={collectionContext}
          {...restProps}
        />
      );
    case ConditionBlueprintType.futureDate:
    case ConditionBlueprintType.lastIncidentDate:
      return (
        <DateConditionCriteria
          condition={condition}
          nodeIdList={nodeIdList}
          selectOptionsByNodeId={selectOptionsByNodeId}
          collectionContext={collectionContext}
          {...restProps}
        />
      );
    case ConditionBlueprintType.matches:
      return (
        <MatchesConditionCriteria
          condition={condition}
          nodeIdList={nodeIdList}
          selectOptionsByNodeId={selectOptionsByNodeId}
          collectionContext={collectionContext}
          {...restProps}
        />
      );
    case ConditionBlueprintType.matchesRegex:
      return (
        <MatchesRegexConditionCriteria
          condition={condition}
          nodeIdList={nodeIdList}
          selectOptionsByNodeId={selectOptionsByNodeId}
          collectionContext={collectionContext}
          {...restProps}
        />
      );
    case ConditionBlueprintType.mathOperator:
      return (
        <MathOperatorConditionCriteria
          condition={condition}
          nodeIdList={nodeIdList}
          selectOptionsByNodeId={selectOptionsByNodeId}
          collectionContext={collectionContext}
          {...restProps}
        />
      );
    case ConditionBlueprintType.numberComparison:
      return (
        <NumberComparisonConditionCriteria
          condition={condition}
          nodeIdList={nodeIdList}
          selectOptionsByNodeId={selectOptionsByNodeId}
          collectionContext={collectionContext}
          {...restProps}
        />
      );
    case ConditionBlueprintType.percentOf:
      return (
        <PercentOfConditionCriteria
          condition={condition}
          nodeIdList={nodeIdList}
          selectOptionsByNodeId={selectOptionsByNodeId}
          collectionContext={collectionContext}
          {...restProps}
        />
      );
    case ConditionBlueprintType.reflexive:
      return (
        <ReflexiveConditionCriteria
          condition={condition}
          nodeIdList={nodeIdList}
          selectOptionsByNodeId={selectOptionsByNodeId}
          collectionContext={collectionContext}
          {...restProps}
        />
      );
  }
  return <Fragment />;
}
