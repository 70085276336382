import { ButtonFieldBlueprint } from '@breathelife/types';

import { FieldDataSetterProps } from '../../../Helpers/helperTypes';
import { ButtonFieldSetters, useButtonFieldData } from '../useButtonFieldData';
import { useCommonFieldDataSetters } from './useCommonFieldDataSetters';

export function ButtonFieldDataSetter({
  initialData,
  dataLabelOptions,
  ...props
}: FieldDataSetterProps<ButtonFieldBlueprint, ButtonFieldSetters>): null {
  const { data, setters, validationOptions, defaultValueValidationError } = useButtonFieldData({
    initialData,
    dataLabelOptions,
  });

  useCommonFieldDataSetters<ButtonFieldBlueprint, ButtonFieldSetters>({
    ...props,
    data,
    setters,
    validationOptions,
    defaultValueValidationError,
    getIsValidBlueprint: (blueprint) => !!(blueprint.validateAs && blueprint.buttonText),
  });
  return null;
}
