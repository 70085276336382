import { useEffect, useState } from 'react';

import { Language } from '@breathelife/types';

import { useCarrierContext, useSelector } from '../Hooks';
import { getCurrentLocale } from '../Localization/utils';

export function useLocale(): Language {
  const { languageSettings } = useCarrierContext();
  const { user } = useSelector((store) => store.leadPlatform.authentication);
  const [locale, setLocale] = useState<Language>(getCurrentLocale(languageSettings.default));

  useEffect(() => {
    if (!user || user.platformLanguage === locale) {
      return;
    }

    setLocale(user.platformLanguage);
  }, [user, locale]);

  return locale;
}
