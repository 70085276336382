import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(updateLocale);

export function guessTimezone(): string {
  return dayjs.tz.guess();
}

export function formatDate(date: dayjs.ConfigType, format: string, timezone?: string): string {
  return dayjs(date).tz(timezone).format(format);
}

export function setMonthShorthandToEnglishFormat(): void {
  dayjs.updateLocale('en', {
    monthsShort: 'Jan._Feb._Mar._Apr._May._Jun._Jul._Aug._Sep._Oct._Nov._Dec.'.split('_'),
  });
}
