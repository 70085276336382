import { ReactElement, useCallback } from 'react';

import { InsuranceFirm, UpdateRequest } from '@breathelife/types';

import { useUpdateFirmMutation } from '../../../../ReactQuery/Admin/Firm/firm.mutations';
import { useGetFirmQuery } from '../../../../ReactQuery/Admin/Firm/firm.queries';
import { FirmEditorDrawer } from './FirmEditorDrawer';
import { FirmFormFields } from './types';

type Props = {
  selectedFirmId: string;
  onCloseFirmDrawer: () => void;
};

export function EditFirmDrawerContainer(props: Props): ReactElement | null {
  const { selectedFirmId, onCloseFirmDrawer } = props;

  const isDrawerOpen = Boolean(selectedFirmId);

  const { data: selectedFirm, isLoading: isLoadingFirm } = useGetFirmQuery(selectedFirmId);

  const updateFirm = useUpdateFirmMutation({
    onSuccess: onCloseFirmDrawer,
  });

  const onEdit = useCallback(
    async (formField: FirmFormFields) => {
      await updateFirm.mutateAsync({ id: selectedFirmId, data: formField as UpdateRequest<InsuranceFirm> });
    },
    [selectedFirmId, updateFirm],
  );

  if (isLoadingFirm) return null;

  return (
    <FirmEditorDrawer isOpen={isDrawerOpen} onSave={onEdit} onClose={onCloseFirmDrawer} insuranceFirm={selectedFirm} />
  );
}
