import { useTranslation } from 'react-i18next';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { AgentLicenseProperties } from '@breathelife/types';

import { useCarrierContext, useDispatch } from '../../Hooks';
import { QueryId } from '../../ReactQuery/common/common.types';
import { notificationSlice } from '../../ReduxStore/Notification/NotificationSlice';
import { getAgentLicenses } from '../../Services/AgentLicensesService';

export function useGetAgentLicensesQuery(
  customId?: string,
  options?: UseQueryOptions<AgentLicenseProperties[], Error>,
): UseQueryResult<AgentLicenseProperties[], Error> {
  const { features } = useCarrierContext();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return useQuery<AgentLicenseProperties[], Error>(
    [QueryId.agentLicenses, customId],
    async () => {
      if (!customId) {
        throw new Error(`User doesn't have a customId.`);
      }

      return await getAgentLicenses(customId);
    },
    {
      ...options,
      enabled: !!features.agentLicenseValidation?.enabled && !!customId,
      staleTime: 1000 * 60 * 60, // 1 hour
      onError: (error) => {
        options?.onError?.(error);
        dispatch(notificationSlice.actions.setError({ message: t('notifications.failedToGetAgentLicenses') }));
      },
    },
  );
}
