import { Box } from '@breathelife/mui';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { ApplicationSupportApplication } from '@breathelife/types';

import { SubmitButton } from '../../../../../Components/Button/SubmitButton';
import Typography from '../../../../../Components/Typography';
import { ModalLayout } from '../../../../../Layouts/Modal/ModalLayout';

type Props = {
  isResettingApplicationStatus: boolean;
  application: ApplicationSupportApplication;
  isOpen: boolean;
  closeModal: () => void;
  onResetApplicationStatus: (applicationId: string) => void;
};

export function ConfirmResetApplicationStatusModal(props: Props): ReactElement {
  const { t } = useTranslation();
  return (
    <ModalLayout
      maxWidth='sm'
      title={t('modals.confirmResetApplicationStatus.title')}
      isOpen={props.isOpen}
      closeModal={() => props.closeModal()}
      submitButton={
        <SubmitButton
          isLoading={props.isResettingApplicationStatus}
          disabled={props.isResettingApplicationStatus}
          onClick={() => props.onResetApplicationStatus(props.application?.id)}
        >
          {t('modals.confirmResetApplicationStatus.resetButton')}
        </SubmitButton>
      }
    >
      <Box mb={3}>
        <Typography variant='body1'>{t('modals.confirmResetApplicationStatus.body1')}</Typography>
      </Box>
      <Box>
        <Typography variant='body1'>
          {t('modals.confirmResetApplicationStatus.body2', {
            refNo: props.application?.refNo,
            applicationId: props.application?.id,
          })}
        </Typography>
      </Box>
    </ModalLayout>
  );
}
