import { SettingsData } from '@breathelife/types';

import { LeadPlatformStore } from '../../../ReduxStore/types';

export const publicLinkSettingsEnabled = (state: LeadPlatformStore): boolean => {
  return Boolean(state.leadPlatform.settings.settings?.data?.publicLink?.enabled);
};

export const getSettingsData = (store: LeadPlatformStore): SettingsData | undefined => {
  return store.leadPlatform.settings.settings?.data;
};
