import PapaParse from 'papaparse';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UsersImportResponse } from '@breathelife/types';

import {
  buildUserImportData,
  validateExtension,
  validateUserImportData,
  WHITELISTED_FILE_EXTENSIONS,
} from '../../../../Helpers/importUsers';
import { ImportUsersView } from '../../../../Pages/Admin/UserManagement/ImportUsersModal/ImportUsersView';
import { bulkCreateUsers } from '../../../../Services/Users/UsersService';

export function ImportUsersContainer(): ReactElement {
  const { t } = useTranslation();
  const [isImporting, setIsImporting] = useState(false);
  const [importError, setImportError] = useState('');
  const [importedData, setImportedData] = useState<string[][]>([]);
  const [importResult, setImportResult] = useState<UsersImportResponse>();

  const handleFileUpload = useCallback(
    (file: File) => {
      const filenameSplit = file.name.split('.');
      const extension = filenameSplit.pop();

      setImportError('');
      setImportResult(undefined);
      setIsImporting(true);
      const isValidExtension = validateExtension(extension);
      if (!isValidExtension) {
        setImportError(
          t('modals.importUsers.invalidFileFormat', {
            extensions: WHITELISTED_FILE_EXTENSIONS.map((extension) => extension.replace(/\./g, '')).join(', '),
          }),
        );
        setIsImporting(false);
        return;
      }

      PapaParse.parse(file, {
        skipEmptyLines: true,
        header: false,
        complete: (result) => {
          if (result.errors.length > 0) {
            setImportError(t('modals.importUsers.fileParsingError'));
            setIsImporting(false);
          } else {
            setImportedData(result.data as string[][]);
          }
        },
        error: () => {
          setImportError(t('modals.importUsers.fileParsingError'));
          setIsImporting(false);
        },
      });
    },
    [t],
  );

  useEffect(() => {
    if (importedData.length === 0) return;
    async function importUsers(): Promise<void> {
      try {
        validateUserImportData(importedData, t);
        const users = buildUserImportData(importedData);
        const result = await bulkCreateUsers(users);
        setImportResult(result);
      } catch (error: any) {
        setImportError(
          // FIXME: message coming from the backend aren't localized. If we want to display those error to admin users
          // in the future, we would need to localize those error.
          error?.response?.data.message || error.message || t('modals.importUsers.generalError'),
        );
      } finally {
        setIsImporting(false);
      }
    }

    importUsers();
  }, [importedData, t]);

  return (
    <ImportUsersView
      handleFileUpload={handleFileUpload}
      isImporting={isImporting}
      error={importError}
      importResult={importResult}
    />
  );
}
