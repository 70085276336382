import { ReactElement, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { Grid } from '@breathelife/mui';
import {
  Localizable,
  PlatformType,
  QuestionBlueprint,
  QuestionnaireBlueprintRenderOn,
  QuestionnaireBlueprintCopyableOption,
} from '@breathelife/types';
import { AutocompleteOption, Input as TextInput, NumberInput, SimpleCheckbox } from '@breathelife/ui-components';

import { SubmitButton } from '../../../../../Components/Button/SubmitButton';
import { NodeIdSelector } from '../../../../../Components/NodeIds/NodeIdSelector';
import { dataLabelSelectionToDataLabel } from '../../../../../Helpers/questionnaireEditor/selectOptions';
import { useCarrierContext } from '../../../../../Hooks';
import { ModalLayout } from '../../../../../Layouts/Modal/ModalLayout';
import { QuestionnaireEditorContext } from '../../ContextProvider/QuestionnaireEditorContextProvider';
import { QuestionnaireVersionDataContext } from '../../ContextProvider/QuestionnaireVersionDataContextProvider';
import { AdvancedBlueprintOptions } from '../Components/AdvancedBlueprintOptions';

export function CreateQuestionModal(props: {
  onCloseModal: () => void;
  onCreate: (blueprint: QuestionBlueprint) => void;
  dataLabelOptions: AutocompleteOption[];
  sectionGroupCollectionNodeId: string | undefined;
}): ReactElement | null {
  const { dataLabelOptions, onCreate, onCloseModal, sectionGroupCollectionNodeId } = props;

  const { questionnaireVersionData } = useContext(QuestionnaireVersionDataContext);
  const questionnaireNodeIds = questionnaireVersionData?.questionnaireNodeIds;

  const { selectedLanguage } = useContext(QuestionnaireEditorContext);

  const { languageSettings } = useCarrierContext();

  const { t } = useTranslation();
  const enabledLanguages = languageSettings.enabledLanguages;

  const [title, setTitle] = useState<Partial<Localizable>>({});
  const [text, setText] = useState<Partial<Localizable>>({});
  const [displayAsCard, setDisplayAsCard] = useState(false);
  const [dataLabel, setDataLabel] = useState<string>(dataLabelOptions?.length ? dataLabelOptions[0].value : '');
  const [renderOn, setRenderOn] = useState<QuestionnaireBlueprintRenderOn[]>([]);
  const [platforms, setPlatforms] = useState<PlatformType[]>([]);
  const [copyable, setCopyable] = useState<QuestionnaireBlueprintCopyableOption>(
    QuestionnaireBlueprintCopyableOption.none,
  );
  const [collectionNodeId, setCollectionNodeId] = useState<string>('');

  const [addButtonText, setAddButtonText] = useState<Partial<Localizable>>({});
  const [removeButtonText, setRemoveButtonText] = useState<Partial<Localizable>>({});

  const [minRepeatable, setMinRepeatable] = useState<number>(1);
  const [maxRepeatable, setMaxRepeatable] = useState<number>(3);

  const isFormValid = useMemo(() => {
    if (!collectionNodeId) {
      // No required fields for non-repeatable field groups.
      return true;
    }

    return (
      minRepeatable >= 0 &&
      minRepeatable < maxRepeatable &&
      enabledLanguages.every((language) => addButtonText[language] && removeButtonText[language])
    );
  }, [collectionNodeId, minRepeatable, maxRepeatable, addButtonText, removeButtonText, enabledLanguages]);

  const collectionNodeIdDetails = sectionGroupCollectionNodeId
    ? [
        ...(questionnaireNodeIds?.notInQuestionnaire.withRepeatableAncestor[sectionGroupCollectionNodeId] ?? []),
        ...(questionnaireNodeIds?.inQuestionnaire.withRepeatableAncestor[sectionGroupCollectionNodeId] ?? []),
      ]
    : [
        ...(questionnaireNodeIds?.notInQuestionnaire.collection ?? []),
        ...(questionnaireNodeIds?.inQuestionnaire.collection ?? []),
      ];

  if (!selectedLanguage) {
    return null;
  }
  return (
    <ModalLayout
      maxWidth='md'
      isOpen={true}
      closeModal={onCloseModal}
      title={t('admin.questionnaireManagement.input.createQuestion.title')}
      submitButton={
        <SubmitButton
          disabled={!isFormValid}
          onClick={() => {
            const blueprintDataLabel = dataLabelSelectionToDataLabel(dataLabel);

            const questionBlueprint: QuestionBlueprint = {
              id: uuid(),
              partName: `custom-${uuid()}`,
              text,
              title,
              dataLabel: blueprintDataLabel,
              isCustom: true,
              fields: [],
              displayAsCard,
              copyable,
            };

            if (renderOn.length) questionBlueprint.renderOn = renderOn;
            if (platforms.length) questionBlueprint.platforms = platforms;

            if (collectionNodeId) {
              questionBlueprint.repeatable = {
                repeatableAnswerNodeId: collectionNodeId,
                addButtonText,
                removeButtonText,
                minRepeatable,
                maxRepeatable,
              };
            }

            onCreate(questionBlueprint);
            onCloseModal();
          }}
          data-testid='questionnaire-editor-field-create'
        >
          {t('cta.save')}
        </SubmitButton>
      }
    >
      <Grid container spacing={2} alignItems='center'>
        {enabledLanguages.map((language) => (
          <Grid item xs={6} key={`createQuestion-title-${language}`}>
            <TextInput
              inputVariant='outlined'
              label={t('admin.questionnaireManagement.input.titleAndLanguage', { language: t(`language.${language}`) })}
              value={title[language] ?? ''}
              onChange={(event) => {
                const value = event.target.value;
                setTitle((previousValue) => ({ ...previousValue, [language]: value }));
              }}
            />
          </Grid>
        ))}
        {enabledLanguages.map((language) => (
          <Grid item xs={12} key={`createQuestion-text-${language}`}>
            <TextInput
              inputVariant='outlined'
              label={t('admin.questionnaireManagement.input.textAndLanguage', { language: t(`language.${language}`) })}
              multiline={true}
              value={text[language] ?? ''}
              onChange={(event) => {
                const value = event.target.value;
                setText((previousValue) => ({ ...previousValue, [language]: value }));
              }}
            />
          </Grid>
        ))}
        <Grid item xs={12} key='createField-displayAsCard'>
          <SimpleCheckbox
            id='createField-displayAsCard'
            label={t('admin.questionnaireManagement.input.displayAsCard')}
            checked={displayAsCard}
            onChange={(event) => {
              setDisplayAsCard(event.target.checked);
            }}
          />
        </Grid>
        <Grid item xs={6} key='createQuestion-collectionNodeId'>
          <NodeIdSelector
            label={t('admin.questionnaireManagement.input.repeatableQuestionNodeId')}
            nodeIds={collectionNodeIdDetails}
            readOnly={false}
            selectedNodeId={collectionNodeId}
            onChange={(nodeIdUpdate) => {
              if (nodeIdUpdate !== null) {
                setCollectionNodeId(nodeIdUpdate?.value);
              }
            }}
            onClear={() => setCollectionNodeId('')}
            selectedLanguage={selectedLanguage}
          />
        </Grid>
        {collectionNodeId && (
          <Grid item xs={6} key='createQuestion-minRepeatable'>
            <NumberInput
              label={`${t('admin.questionnaireManagement.input.minRepeatable')} *`}
              value={minRepeatable}
              inputVariant='outlined'
              name='createQuestion-minRepeatable'
              onAnswerChange={(newValue) => {
                const newNumberValue = parseInt(newValue);
                setMinRepeatable(newNumberValue);
              }}
            />
          </Grid>
        )}
        {collectionNodeId && (
          <Grid item xs={6} key='createQuestion-maxRepeatable'>
            <NumberInput
              label={`${t('admin.questionnaireManagement.input.maxRepeatable')} *`}
              value={maxRepeatable}
              inputVariant='outlined'
              name='createQuestion-maxRepeatable'
              onAnswerChange={(newValue) => {
                const newNumberValue = parseInt(newValue);
                setMaxRepeatable(newNumberValue);
              }}
            />
          </Grid>
        )}
        {collectionNodeId &&
          enabledLanguages.map((language) => (
            <Grid item xs={6} key={`createQuestion-addButtonText-${language}`}>
              <TextInput
                inputVariant='outlined'
                label={t('admin.questionnaireManagement.input.addButtonTextAndLanguage', {
                  language: t(`language.${language}`),
                })}
                multiline={true}
                value={addButtonText[language] ?? ''}
                required
                onChange={(event) => {
                  const value = event.target.value;
                  setAddButtonText((previousValue) => ({ ...previousValue, [language]: value }));
                }}
              />
            </Grid>
          ))}
        {collectionNodeId &&
          enabledLanguages.map((language) => (
            <Grid item xs={6} key={`createQuestion-removeButtonText-${language}`}>
              <TextInput
                inputVariant='outlined'
                label={t('admin.questionnaireManagement.input.removeButtonTextAndLanguage', {
                  language: t(`language.${language}`),
                })}
                multiline={true}
                value={removeButtonText[language] ?? ''}
                required
                onChange={(event) => {
                  const value = event.target.value;
                  setRemoveButtonText((previousValue) => ({ ...previousValue, [language]: value }));
                }}
              />
            </Grid>
          ))}
        <Grid item xs={12}>
          <AdvancedBlueprintOptions
            tag='question'
            platform={{ selected: platforms, onChange: setPlatforms }}
            renderOn={{ selected: renderOn, onChange: setRenderOn }}
            dataLabel={{
              selected: dataLabel,
              options: dataLabelOptions,
              onChange: (value) => {
                if (value !== null) {
                  setDataLabel(value);
                }
              },
            }}
            copyable={{
              selected: copyable,
              onChange: (value) => {
                if (value !== null) {
                  setCopyable(value as QuestionnaireBlueprintCopyableOption);
                }
              },
            }}
            disabled={false}
          />
        </Grid>
      </Grid>
    </ModalLayout>
  );
}
