import { ReactElement, Fragment, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid } from '@breathelife/mui';
import { MatchesConditionPropertyQuantifier, MatchesConditionValue } from '@breathelife/types';

import {
  getConditionValidationError,
  ValidationValues,
} from '../../../Helpers/inputValidation/form/salesDecisionRules';
import { getOptionsFromEnum } from '../../../Helpers/options';
import { CollectionOperators } from '../Helpers/CollectionOperators';
import { ConditionTargetNodeAutocomplete } from '../Helpers/ConditionTargetNodeAutocomplete';
import { CriteriaValuesCheckboxGroup } from '../Helpers/CriteriaValuesCheckboxGroup';
import { QuantifierRadioGroup } from '../Helpers/QuantifierRadioGroup';
import { ValidationErrorText } from '../ValidationErrorText';
import { ConditionCriteriaProps } from '../types';

function MatchesQuantifierRadioGroup(props: {
  value: MatchesConditionPropertyQuantifier;
  onChange: (value: MatchesConditionPropertyQuantifier) => void;
}): ReactElement {
  const { value, onChange } = props;

  const { t } = useTranslation();

  const options = getOptionsFromEnum<MatchesConditionPropertyQuantifier>(
    MatchesConditionPropertyQuantifier,
    'admin.conditions.options.matches',
  );

  return (
    <QuantifierRadioGroup
      label={t('admin.conditions.labels.matches')}
      name='condition-matches-radio-group'
      options={options}
      value={value}
      onChange={onChange}
    />
  );
}

export function MatchesConditionCriteria({
  condition,
  nodeIdList,
  collectionContext,
  nodeIdInCollectionMap,
  selectOptionsByNodeId,
  onConditionPropertiesChange,
  language,
  conditionIndex,
}: ConditionCriteriaProps<MatchesConditionValue>): ReactElement {
  const { targetNodeId, quantifier, value } = condition;
  const validationErrors = getConditionValidationError(condition, nodeIdList, nodeIdInCollectionMap);

  const [nodeOptions, setNodeOptions] = useState(selectOptionsByNodeId[targetNodeId] ?? []);

  const onTargetNodeIdChange = useCallback(
    (targetNodeId: string) => {
      setNodeOptions(selectOptionsByNodeId[targetNodeId] ?? []);
      onConditionPropertiesChange({ targetNodeId });
    },
    [onConditionPropertiesChange, selectOptionsByNodeId],
  );

  const collectionNodeIds = nodeIdInCollectionMap[condition.targetNodeId] ?? [];

  return (
    <Fragment>
      <Grid item xs={12}>
        <ConditionTargetNodeAutocomplete
          value={targetNodeId}
          nodeIdList={nodeIdList}
          onSelect={(value) => {
            if (value !== null) {
              onTargetNodeIdChange(value);
            }
          }}
          validationError={validationErrors?.[ValidationValues.targetNodeId]}
          selectedLanguage={language}
        />
      </Grid>
      <Grid item xs={12}>
        <MatchesQuantifierRadioGroup
          value={quantifier}
          onChange={(value) => {
            onConditionPropertiesChange({ quantifier: value });
          }}
        />
        {validationErrors?.[ValidationValues.quantifier] && (
          <ValidationErrorText>{validationErrors[ValidationValues.quantifier].message}</ValidationErrorText>
        )}
      </Grid>
      <Grid item xs={12}>
        <CriteriaValuesCheckboxGroup
          options={nodeOptions}
          values={value as string[]}
          onChange={(values) => {
            onConditionPropertiesChange({ value: values });
          }}
        />
        {validationErrors?.[ValidationValues.value] && (
          <ValidationErrorText>{validationErrors[ValidationValues.value].message}</ValidationErrorText>
        )}
      </Grid>
      <CollectionOperators
        collectionContext={collectionContext}
        collectionNodeIds={collectionNodeIds}
        collectionOperators={condition.collectionOperators}
        onConditionPropertiesChange={onConditionPropertiesChange}
        conditionIndex={conditionIndex}
      />
    </Fragment>
  );
}
