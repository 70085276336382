import { Box } from '@breathelife/mui';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UserStatus } from '@breathelife/types';

import { Select, SelectProps } from '../../../../Components/Select/Select';
import { SearchTextField } from '../../../../Components/TextInputs';
import { getOptionsFromEnum } from '../../../../Helpers/options';
import styled from '../../../../Styles/themed-styled-components';

const StyledSelect = styled(Select)`
  && {
    margin-left: 20px;
  }
` as <T extends string | number = string>(props: SelectProps<T>) => ReactElement;

export type Filters = {
  status: string;
};

type UsersTableHeaderProps = {
  onSearchChange: (search: string) => void;
  onFiltersChanged: (filters: Filters) => void;
};

export function UsersTableHeader(props: UsersTableHeaderProps): ReactElement {
  const { onFiltersChanged, onSearchChange } = props;
  const { t } = useTranslation();
  const [filtersValue, setFiltersValue] = useState<Filters>({ status: '' });

  const onStatusFilterChange = useCallback(
    (value: string) => {
      const newFiltersValue = { ...filtersValue, status: value };
      setFiltersValue(newFiltersValue);
      onFiltersChanged(newFiltersValue);
    },
    [filtersValue, onFiltersChanged],
  );

  const options = useMemo(() => {
    const emptyOption = [{ value: '', label: t('usersListTable.statusFilter') }];
    return emptyOption.concat(getOptionsFromEnum(UserStatus, 'user.status'));
  }, [t]);

  return (
    <Box display='flex'>
      <SearchTextField onSearchChange={onSearchChange} />
      <StyledSelect
        isSearchFilter
        id='status-select'
        label={t('leadsListTable.applicationStatus')}
        value={filtersValue.status}
        options={options}
        onChange={onStatusFilterChange}
      />
    </Box>
  );
}
