import { Box } from '@breathelife/mui';
import { ReactElement, Fragment } from 'react';

import { OutcomeCode, SignatureType } from '@breathelife/types';
import { Loader } from '@breathelife/ui-components';

import { SubmissionErrorView } from '././SubmissionErrorView';
import { SubmissionView } from './SubmissionView';

type SubmitApplicationViewProps = {
  signatureType: SignatureType;
  confirmationNumber?: string;
  cryptoSignature?: string;
  isLoading: boolean;
  isSubmissionFailure: boolean;
  isSubmissionSuccess: boolean;
  outcome?: OutcomeCode;
};

export function SubmitApplicationView(props: SubmitApplicationViewProps): ReactElement {
  const {
    signatureType,
    confirmationNumber,
    cryptoSignature,
    isLoading,
    isSubmissionFailure,
    isSubmissionSuccess,
    outcome,
  } = props;

  if (isLoading) {
    return (
      <Box p={4}>
        <Loader size='88px' />
      </Box>
    );
  }

  return (
    <Fragment>
      {isSubmissionSuccess && (
        <SubmissionView
          signatureType={signatureType}
          cryptoSignature={cryptoSignature}
          confirmationNumber={confirmationNumber}
          outcome={outcome}
        />
      )}
      {isSubmissionFailure && <SubmissionErrorView />}
    </Fragment>
  );
}
