import { Box } from '@breathelife/mui';
import ReactHtmlParser from 'html-react-parser';
import { memo, ReactElement } from 'react';

import { RenderingSection, RenderingSubsection } from '@breathelife/questionnaire-engine';

import Typography from '../../../../Components/Typography';
import { SubsectionContainer } from './SubsectionContainer';

type SectionViewProps = {
  section: RenderingSection;
};

export function InnerSectionView(props: SectionViewProps): ReactElement {
  const { section } = props;

  return (
    <Box display='flex' flexDirection='column' mt={5}>
      {(section.title || section.text) && (
        <Box mb={4}>
          {section.title && <Typography variant='h1'>{ReactHtmlParser(section.title)}</Typography>}
          {section.text && <Typography variant='h2'>{ReactHtmlParser(section.text)}</Typography>}
        </Box>
      )}

      <Box display='flex' flexDirection='column'>
        {section.subsections.map(
          (subsection: RenderingSubsection) =>
            subsection.visible && <SubsectionContainer key={subsection.id} subsection={subsection} />,
        )}
      </Box>
    </Box>
  );
}

export const SectionView = memo(InnerSectionView);
