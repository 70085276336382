import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Select, SelectProps } from '../Select/Select';

type CanadianProvinceSelectProps = Omit<SelectProps, 'options'>;

export const CA_PROVINCES = ['AB', 'BC', 'MB', 'NB', 'NL', 'NT', 'NS', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT'];

export function CanadianProvinceSelect(props: CanadianProvinceSelectProps): ReactElement {
  const { t } = useTranslation();

  const options = CA_PROVINCES.map((province) => ({
    value: province,
    label: t(`canadianProvinces.${province}`),
  }));

  return <Select {...props} options={options} />;
}
