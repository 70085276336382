import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from '../../../../Styles/themed-styled-components';

import { IconName, OutcomeCode } from '@breathelife/types';
import { Icon, Text } from '@breathelife/ui-components';
import { SelectedReportData } from '../../../../Helpers/applicationSupport/underwritingTreeviewDataMapping';

type ReportTreeViewItemProps = {
  reportMetadata: SelectedReportData;
  treeViewItemId: string;
  isSelected: boolean;
  onReportClick: (report: SelectedReportData, treeViewItemId: string) => void;
};

const greySelectedItemBackground = css`
  background-color: #cccccc;
`;

const ReportTreeViewItemContainer = styled.div<{ isSelected?: boolean }>`
  display: flex;
  padding-left: 1px;
  align-items: center;
  min-width: 0;
  align-self: stretch;
  flex-grow: 1;
  cursor: pointer;
  ${({ isSelected }) => {
    return isSelected ? greySelectedItemBackground : '';
  }}
`;

export function ReportTreeViewItem(props: ReportTreeViewItemProps): ReactElement {
  const { reportMetadata, treeViewItemId, isSelected, onReportClick } = props;
  const { t } = useTranslation();
  const { response, updatedAt, createdAt, outcome } = reportMetadata;

  const isSuccessfulResponse = response !== undefined && outcome !== OutcomeCode.unknown;

  const iconName = isSuccessfulResponse ? IconName.bulletCheckmark : IconName.bulletEx;

  return (
    <ReportTreeViewItemContainer
      isSelected={isSelected}
      onClick={() => {
        onReportClick(reportMetadata, treeViewItemId);
      }}
    >
      <Icon name={iconName} size={'20'} />
      <Text noSpacing>{`${t('applicationSupport.underwritingReports.report')} #${updatedAt || createdAt}`}</Text>
    </ReportTreeViewItemContainer>
  );
}
