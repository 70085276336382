import axios from 'axios';

import config from '@breathelife/config/frontend';
import {
  addErrorRedirectInterceptor,
  addJwtIntoHeaderInterceptor,
  addProductIntoHeaderInterceptor,
  addTraceIntoHeaderInterceptor,
  Gateway,
} from '@breathelife/gateway';
import { monitoringInterceptor, getTraceId, ProductName } from '@breathelife/monitoring-frontend';

import Urls from '../Navigation/Urls';

const ApiService = axios.create({
  timeout: 30000,
});

const errorRedirects = { 401: Urls.login.fullPath };

ApiService.interceptors.request.use(addProductIntoHeaderInterceptor(ProductName.LeadPlatform));
ApiService.interceptors.request.use(addTraceIntoHeaderInterceptor(getTraceId()));
ApiService.interceptors.response.use((response) => response, addErrorRedirectInterceptor(errorRedirects));
ApiService.interceptors.response.use((response) => response, monitoringInterceptor);

// Add header authorization token
let authorizationInterceptorIndex: number;
export function setAuthorizationInterceptor(token?: string): void {
  if (typeof authorizationInterceptorIndex !== 'undefined') {
    ApiService.interceptors.request.eject(authorizationInterceptorIndex);
  }

  authorizationInterceptorIndex = ApiService.interceptors.request.use(addJwtIntoHeaderInterceptor(token));
}

const releaseVersion = config.get<string>('git.commitSha');

export default new Gateway(releaseVersion, ApiService);
