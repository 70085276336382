import { ReactElement } from 'react';

import { SvgIconProps } from '../../Components/Icons';

export function CloudDownloadIcon(props: SvgIconProps): ReactElement {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      stroke='#757575'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...props.svgProps}
    >
      <path d='M5.00003 10.1208H3.43472C2.61745 10.0064 1.86947 9.59934 1.32972 8.97511C0.789974 8.35089 0.49516 7.55196 0.500049 6.72675C0.490545 6.28011 0.571356 5.83614 0.737633 5.42149C0.90391 5.00684 1.15223 4.63005 1.46769 4.31371C1.78315 3.99738 2.15926 3.74802 2.57345 3.5806C2.98764 3.41318 3.43138 3.33115 3.87805 3.33942C4.23069 2.53759 4.80714 1.85449 5.53826 1.37204C6.26938 0.889596 7.12414 0.628276 8.00005 0.619418C9.18565 0.641741 10.3178 1.11695 11.1641 1.94753C12.0104 2.77812 12.5068 3.90111 12.5514 5.08608C12.9149 5.01477 13.2899 5.02603 13.6485 5.11904C14.0071 5.21205 14.3402 5.38443 14.6233 5.62346C14.9063 5.86249 15.1321 6.16205 15.2838 6.50002C15.4356 6.83798 15.5095 7.20573 15.5 7.57609C15.5 10.1614 12.8914 10.1208 12.8914 10.1208H11' />
      <path d='M8 9V15' />
      <path d='M10.5 12.5L8 15L5.5 12.5' />
    </svg>
  );
}
