import { useTranslation } from 'react-i18next';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { useDispatch } from 'react-redux';

import { logger } from '@breathelife/monitoring-frontend';
import { QuestionnaireVersionPricingNodeIds } from '@breathelife/types';

import { QueryId } from '../../../ReactQuery/common/common.types';
import { notificationSlice } from '../../../ReduxStore/Notification/NotificationSlice';
import { getPricingFieldIdentifiers } from '../../../Services/PricingFieldIdentifiersService';

export function useFetchPricingFieldIdentifiersQuery(
  questionnaireVersionId?: string | null,
  options?: UseQueryOptions<QuestionnaireVersionPricingNodeIds[]>,
): UseQueryResult<QuestionnaireVersionPricingNodeIds[]> {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return useQuery<QuestionnaireVersionPricingNodeIds[]>(
    [QueryId.pricingFieldIdentifiers, questionnaireVersionId],
    () => getPricingFieldIdentifiers(questionnaireVersionId),
    {
      ...options,
      onError(err) {
        dispatch(
          notificationSlice.actions.setError({
            message: t('notifications.failedToFetchPricingFieldIdentifiers'),
          }),
        );
        logger.error(err);
        options?.onError?.(err);
      },
    },
  );
}
