import { ReactElement, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid } from '@breathelife/mui';
import { Language } from '@breathelife/types';
import { Input as TextInput, PhoneInput } from '@breathelife/ui-components';

import { getFieldValidationError } from '../../../../../Helpers/inputValidation/form/firm';
import { FirmFormFields, ValueChangeFunction } from '../types';

type Props = {
  insuranceFirmFormState: FirmFormFields;
  enabledLanguages: Language[];
  onInputValueChange: ValueChangeFunction;
};

export function FirmFields(props: Props): ReactElement {
  const { t } = useTranslation();
  const [validationErrors, setValidationErrors] = useState<Partial<Record<keyof FirmFormFields, any>>>({});

  const {
    name,
    address,
    primaryPhoneNumber,
    tollFreePhoneNumber,
    faxNumber,
    email,
    websiteUrl,
    insurerAmfNumber,
    firmAmfNumber,
    privacyPolicyUrl,
    insurerRegistrationUrl,
    firmRegistrationUrl,
  } = props.insuranceFirmFormState;

  return (
    <Fragment>
      {props.enabledLanguages.map((language) => (
        <Grid item xs={6} key={`firmManagement-name-${language}`}>
          <TextInput
            name={`firmManagement-name-${language}`}
            inputVariant='outlined'
            label={t('admin.firmManagement.labels.nameAndLanguage', { language: t(`language.${language}`) })}
            value={name?.[language] ?? ''}
            error={Boolean(validationErrors?.name?.[language])}
            validationError={validationErrors?.name?.[language]}
            required
            onChange={(event) => {
              props.onInputValueChange({
                path: `name.${language}`,
                value: event.target.value,
              });
              setValidationErrors({
                ...validationErrors,
                name: { ...validationErrors.name, [language]: getFieldValidationError('name', event.target.value) },
              });
            }}
          />
        </Grid>
      ))}
      {props.enabledLanguages.map((language) => (
        <Grid item xs={12} key={`firmManagement-address-${language}`}>
          <TextInput
            name={`firmManagement-address-${language}`}
            inputVariant='outlined'
            label={t('admin.firmManagement.labels.addressAndLanguage', { language: t(`language.${language}`) })}
            value={address?.[language] ?? ''}
            error={Boolean(validationErrors?.address?.[language])}
            validationError={validationErrors?.address?.[language]}
            onChange={(event) => {
              props.onInputValueChange({
                path: `address.${language}`,
                value: event.target.value,
              });
              setValidationErrors({
                ...validationErrors,
                address: {
                  ...validationErrors.address,
                  [language]: getFieldValidationError('address', event.target.value),
                },
              });
            }}
          />
        </Grid>
      ))}
      <Grid item xs={6}>
        <PhoneInput
          name='firmManagement-primaryPhoneNumber'
          inputVariant='outlined'
          label={t('admin.firmManagement.labels.primaryPhoneNumber')}
          validationError={validationErrors.primaryPhoneNumber}
          value={primaryPhoneNumber ?? ''}
          error={Boolean(validationErrors.primaryPhoneNumber)}
          onAnswerChange={(value: string) => {
            props.onInputValueChange({
              path: 'primaryPhoneNumber',
              value: value,
            });
            setValidationErrors({
              ...validationErrors,
              primaryPhoneNumber: getFieldValidationError('primaryPhoneNumber', value),
            });
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <PhoneInput
          name='firmManagement-tollFreePhoneNumber'
          inputVariant='outlined'
          label={t('admin.firmManagement.labels.tollFreePhoneNumber')}
          validationError={validationErrors.tollFreePhoneNumber}
          value={tollFreePhoneNumber}
          error={Boolean(validationErrors.tollFreePhoneNumber)}
          onAnswerChange={(value: string) => {
            props.onInputValueChange({
              path: 'tollFreePhoneNumber',
              value: value,
            });
            setValidationErrors({
              ...validationErrors,
              tollFreePhoneNumber: getFieldValidationError('tollFreePhoneNumber', value),
            });
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <PhoneInput
          name='firmManagement-faxNumber'
          inputVariant='outlined'
          label={t('admin.firmManagement.labels.faxNumber')}
          validationError={validationErrors.faxNumber}
          value={faxNumber ?? ''}
          error={Boolean(validationErrors.faxNumber)}
          onAnswerChange={(value: string) => {
            props.onInputValueChange({
              path: 'faxNumber',
              value: value,
            });
            setValidationErrors({
              ...validationErrors,
              faxNumber: getFieldValidationError('faxNumber', value),
            });
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          name='firmManagement-email'
          inputVariant='outlined'
          label={t('admin.firmManagement.labels.email')}
          validationError={validationErrors.email}
          value={email ?? ''}
          error={Boolean(validationErrors.email)}
          onChange={(event) => {
            props.onInputValueChange({
              path: 'email',
              value: event.target.value,
            });
            setValidationErrors({
              ...validationErrors,
              email: getFieldValidationError('email', event.target.value),
            });
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          name='firmManagement-websiteUrl'
          inputVariant='outlined'
          label={t('admin.firmManagement.labels.websiteUrl')}
          validationError={validationErrors.websiteUrl}
          value={websiteUrl ?? ''}
          error={Boolean(validationErrors.websiteUrl)}
          onChange={(event) => {
            props.onInputValueChange({
              path: 'websiteUrl',
              value: event.target.value,
            });
            setValidationErrors({
              ...validationErrors,
              websiteUrl: getFieldValidationError('websiteUrl', event.target.value),
            });
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          name='firmManagement-firmAmfNumber'
          inputVariant='outlined'
          label={t('admin.firmManagement.labels.firmAmfNumber')}
          validationError={validationErrors.firmAmfNumber}
          value={firmAmfNumber ?? ''}
          error={Boolean(validationErrors.firmAmfNumber)}
          onChange={(event) => {
            props.onInputValueChange({
              path: 'firmAmfNumber',
              value: event.target.value,
            });
            setValidationErrors({
              ...validationErrors,
              firmAmfNumber: getFieldValidationError('firmAmfNumber', event.target.value),
            });
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          name='firmManagement-insurerAmfNumber'
          inputVariant='outlined'
          label={t('admin.firmManagement.labels.insurerAmfNumber')}
          validationError={validationErrors.insurerAmfNumber}
          value={insurerAmfNumber ?? ''}
          error={Boolean(validationErrors.insurerAmfNumber)}
          onChange={(event) => {
            props.onInputValueChange({
              path: 'insurerAmfNumber',
              value: event.target.value,
            });
            setValidationErrors({
              ...validationErrors,
              insurerAmfNumber: getFieldValidationError('insurerAmfNumber', event.target.value),
            });
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          name='firmManagement-privacyPolicyUrl'
          inputVariant='outlined'
          label={t('admin.firmManagement.labels.privacyPolicyUrl')}
          validationError={validationErrors.privacyPolicyUrl}
          value={privacyPolicyUrl ?? ''}
          error={Boolean(validationErrors.privacyPolicyUrl)}
          onChange={(event) => {
            props.onInputValueChange({
              path: 'privacyPolicyUrl',
              value: event.target.value,
            });
            setValidationErrors({
              ...validationErrors,
              privacyPolicyUrl: getFieldValidationError('privacyPolicyUrl', event.target.value),
            });
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          name='firmManagement-firmRegistrationUrl'
          inputVariant='outlined'
          label={t('admin.firmManagement.labels.firmRegistrationUrl')}
          validationError={validationErrors.firmRegistrationUrl}
          value={firmRegistrationUrl ?? ''}
          error={Boolean(validationErrors.firmRegistrationUrl)}
          onChange={(event) => {
            props.onInputValueChange({
              path: 'firmRegistrationUrl',
              value: event.target.value,
            });
            setValidationErrors({
              ...validationErrors,
              firmRegistrationUrl: getFieldValidationError('firmRegistrationUrl', event.target.value),
            });
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          name='firmManagement-insurerRegistrationUrl'
          inputVariant='outlined'
          label={t('admin.firmManagement.labels.insurerRegistrationUrl')}
          validationError={validationErrors.insurerRegistrationUrl}
          value={insurerRegistrationUrl ?? ''}
          error={Boolean(validationErrors.insurerRegistrationUrl)}
          onChange={(event) => {
            props.onInputValueChange({
              path: 'insurerRegistrationUrl',
              value: event.target.value,
            });
            setValidationErrors({
              ...validationErrors,
              insurerRegistrationUrl: getFieldValidationError('insurerRegistrationUrl', event.target.value),
            });
          }}
        />
      </Grid>
    </Fragment>
  );
}
