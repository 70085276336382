import { Fragment, ReactElement, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid } from '@breathelife/mui';
import { PercentOfComparisonConditionValue } from '@breathelife/types';

import { NumberInput } from '../../../Components/NumberInput/NumberInput';
import {
  getConditionValidationError,
  ValidationValues,
} from '../../../Helpers/inputValidation/form/salesDecisionRules';
import { CollectionOperators } from '../Helpers/CollectionOperators';
import { ConditionTargetNodeAutocomplete } from '../Helpers/ConditionTargetNodeAutocomplete';
import { NumberComparisonConditionOperatorSelect } from '../Helpers/NumberComparisonConditionOperatorSelect';
import { StringValueOrNodeIdInput } from '../Helpers/StringValueOrNodeIdInput';
import { ConditionCriteriaProps } from '../types';
import { ValidationErrorText } from '../ValidationErrorText';

export function PercentOfConditionCriteria({
  condition,
  nodeIdList,
  nodeIdInCollectionMap,
  collectionContext,
  onConditionPropertiesChange,
  language,
  conditionIndex,
  selectOptionsByNodeId,
}: ConditionCriteriaProps<PercentOfComparisonConditionValue>): ReactElement {
  const { t } = useTranslation();
  const validationErrors = getConditionValidationError(condition, nodeIdList, nodeIdInCollectionMap);

  const [percent, setPercent] = useState<number | undefined>(condition.percent);

  const onPercentBlur = useCallback(() => {
    onConditionPropertiesChange({ percent });
  }, [onConditionPropertiesChange, percent]);

  const collectionNodeIds = nodeIdInCollectionMap[condition.targetNodeId] ?? [];

  return (
    <Fragment>
      <Grid item xs={12}>
        <ConditionTargetNodeAutocomplete
          value={condition.targetNodeId}
          nodeIdList={nodeIdList}
          onSelect={(targetNodeId) => {
            if (targetNodeId !== null) {
              onConditionPropertiesChange({ targetNodeId });
            }
          }}
          validationError={validationErrors?.[ValidationValues.targetNodeId]}
          selectedLanguage={language}
        />
      </Grid>
      <Grid item xs={12}>
        <NumberInput
          inputVariant='outlined'
          value={percent}
          onNumberChange={setPercent}
          onBlur={onPercentBlur}
          label={t('admin.conditions.labels.percent')}
          error={Boolean(validationErrors?.[ValidationValues.percent])}
        />
        {validationErrors?.[ValidationValues.percent]?.message && (
          <ValidationErrorText>{validationErrors[ValidationValues.percent].message}</ValidationErrorText>
        )}
      </Grid>
      <Grid item xs={12}>
        <NumberComparisonConditionOperatorSelect
          value={condition.operator}
          onChange={(operator) => {
            onConditionPropertiesChange({ operator });
          }}
        />
        {validationErrors?.[ValidationValues.operator]?.message && (
          <ValidationErrorText>{validationErrors[ValidationValues.operator].message}</ValidationErrorText>
        )}
      </Grid>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <StringValueOrNodeIdInput
            value={condition.value}
            nodeIdList={nodeIdList}
            nodeIdOfValue={condition.nodeIdOfValue}
            condition={condition}
            onChange={(values: any) => {
              onConditionPropertiesChange(values);
            }}
            language={language}
            validationErrors={validationErrors}
            selectOptionsByNodeId={selectOptionsByNodeId}
          />
          {validationErrors?.[ValidationValues.value] && (
            <ValidationErrorText>{validationErrors[ValidationValues.value].message}</ValidationErrorText>
          )}
        </Grid>
      </Grid>
      <CollectionOperators
        collectionContext={collectionContext}
        collectionNodeIds={collectionNodeIds}
        collectionOperators={condition.collectionOperators}
        onConditionPropertiesChange={onConditionPropertiesChange}
        conditionIndex={conditionIndex}
      />
    </Fragment>
  );
}
