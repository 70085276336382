import { AgentLicenseProperties } from '@breathelife/types';

import ApiService from './ApiService';

export async function createAgentLicenses(customId: string): Promise<AgentLicenseProperties[]> {
  const { data: agentLicenseProperties } = await ApiService.createAgentLicenses({ agentId: customId });

  return agentLicenseProperties;
}

export async function getAgentLicenses(customId: string): Promise<AgentLicenseProperties[]> {
  const { data: agentLicenseProperties } = await ApiService.getAgentLicenses(customId);

  return agentLicenseProperties;
}
