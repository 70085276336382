import { ReactElement, useContext, useEffect, useState } from 'react';

import { Box } from '@breathelife/mui';

import LoadingView from '../../../Components/LoadingView/LoadingView';
import { QuestionnaireVersionDataContextProvider } from '../Questionnaire/ContextProvider/QuestionnaireVersionDataContextProvider';
import { QuestionnaireVersionsContext } from '../Questionnaire/ContextProvider/QuestionnaireVersionsContextProvider';
import { PricingFieldIdentifiersManagementView } from './PricingFieldIdentifiersManagementView';
import { useUpdatePricingFieldIdentifiersMutation } from '../../../ReactQuery/Admin/PricingFieldIdentifiers/pricingFieldIdentifiers.mutations';

export function PricingFieldIdentifiersManagementViewContainer(): ReactElement | null {
  const { questionnaireVersions, questionnaireVersionsIsFetching } = useContext(QuestionnaireVersionsContext);
  const [questionnaireVersionId, setQuestionnaireVersionId] = useState('');

  const updatePricingFieldIdentifiersMutation = useUpdatePricingFieldIdentifiersMutation();

  const savePricingFieldIdentifiers = (pricingFieldIdentifiersId: string, fieldIdentifiers: string[]): void => {
    updatePricingFieldIdentifiersMutation.mutate({
      pricingFieldIdentifiersId,
      questionnaireVersionId,
      data: { nodeIds: fieldIdentifiers },
    });
  };

  useEffect(() => {
    if (!questionnaireVersions.length) return;

    const latestQuestionnaireVersion = questionnaireVersions[0];
    setQuestionnaireVersionId(latestQuestionnaireVersion.id);
  }, [questionnaireVersions]);

  if (questionnaireVersionsIsFetching && !questionnaireVersionId) {
    return (
      <Box m={2} width='100%'>
        <LoadingView />
      </Box>
    );
  }

  return (
    <QuestionnaireVersionDataContextProvider questionnaireVersionId={questionnaireVersionId}>
      <PricingFieldIdentifiersManagementView
        onQuestionnaireVersionFilterChanged={setQuestionnaireVersionId}
        onSavingPricingFieldIdentifiers={savePricingFieldIdentifiers}
      />
    </QuestionnaireVersionDataContextProvider>
  );
}
