import { Box } from '@breathelife/mui';
import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ESignCeremonyStatus } from '@breathelife/types';

import { Alert } from '../../../../Components/Alert/Alert';
import { Button } from '../../../../Components/Button/Button';
import { CloseButton } from '../../../../Components/Button/CloseButton';
import { SubmitButton } from '../../../../Components/Button/SubmitButton';
import Typography from '../../../../Components/Typography';
import { RequiredFile, useCarrierContext } from '../../../../Hooks';
import { StyledESignatureStatus } from './StyledESignatureStatus';
import { StyledInfoBox } from './styles';

type ESignatureDetailsHeaderProps = {
  isLoading: boolean;
  status?: ESignCeremonyStatus;
  onClickSend: () => void;
  onClickCancel: () => void;
  onClose: () => void;
  requiredFiles: RequiredFile[];
};

export function ESignatureDetailsHeader(props: ESignatureDetailsHeaderProps): ReactElement {
  const { t } = useTranslation();
  const { features } = useCarrierContext();
  const { isLoading, status, onClose, onClickSend, onClickCancel, requiredFiles } = props;

  // Info alert only visible when all parties signed (signature completed)
  const [isAlertOpen, setIsAlertOpen] = useState(status === ESignCeremonyStatus.COMPLETED);

  const onCloseAlert = (): void => {
    setIsAlertOpen(false);
  };

  const ENABLE_DECOUPLE_ESIGN_FLOW = !!features.enableDecoupleESignFlow?.enabled;

  const isSubmitBtnVisible = ENABLE_DECOUPLE_ESIGN_FLOW
    ? status === ESignCeremonyStatus.IN_PROGRESS || status === ESignCeremonyStatus.READY
    : status === ESignCeremonyStatus.DRAFT;

  const isESignCeremonyInProgress = ENABLE_DECOUPLE_ESIGN_FLOW && status === ESignCeremonyStatus.IN_PROGRESS;

  const areAllRequiredFilesUploaded = requiredFiles
    .filter((requiredFile) => requiredFile.template.isRequired)
    .every((requiredFile) => requiredFile.storedFile);

  // Cancel button is visible in all other cases, except when the signature is completed
  const isCancelBtnVisible = !(isSubmitBtnVisible || status === ESignCeremonyStatus.COMPLETED);

  const statusText = status ? t(`assistedApplication.eSignatureDetails.status.${status}`) : '';

  return (
    <div>
      <Box display='flex' justifyContent='space-between'>
        <Box display='flex' alignItems='center'>
          <Typography variant='h1'>{t('assistedApplication.eSignatureDetails.header')}</Typography>
          {status && statusText.length > 0 && (
            <Box ml={1.25}>
              <StyledESignatureStatus $status={status}>{statusText}</StyledESignatureStatus>
            </Box>
          )}
        </Box>

        <CloseButton onClick={onClose} />
      </Box>

      <Box my={2}>
        {isAlertOpen && (
          <Alert severity='info' onClose={onCloseAlert}>
            {t('cta.signaturesComplete')}
          </Alert>
        )}
        {isESignCeremonyInProgress && (
          <StyledInfoBox mt={3} mb={4}>
            {t('assistedApplication.eSignatureDetails.pdfGenerationInfo')}
          </StyledInfoBox>
        )}

        <Box display='flex' justifyContent='flex-end' ml={1}>
          {isSubmitBtnVisible && (
            <SubmitButton
              isLoading={isLoading}
              disabled={isESignCeremonyInProgress || !areAllRequiredFilesUploaded}
              onClick={onClickSend}
            >
              {t('cta.send')}
            </SubmitButton>
          )}
          {isCancelBtnVisible && (
            <Button color='secondary' variant='outlined' isLoading={isLoading} onClick={onClickCancel}>
              {t('cta.cancelESignatureProcess')}
            </Button>
          )}
        </Box>
      </Box>
    </div>
  );
}
