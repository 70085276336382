import { ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid } from '@breathelife/mui';
import { AddressAutocompleteFieldBlueprint, FieldTypes, IsoCountryCode, QuestionBlueprint } from '@breathelife/types';
import { AutocompleteOption, SimpleCheckbox } from '@breathelife/ui-components';

import { NodeIdSelector } from '../../../../../Components/NodeIds/NodeIdSelector';
import { validate } from '../../../../../Helpers/inputValidation/form/addressAutocompleteFields';
import { NodeDetail } from '../../../../../Helpers/questionnaireEditor/questionnaireNodeIds';
import { getCountryCodeOptions } from '../../../../../Helpers/questionnaireEditor/selectOptions';
import { QuestionnaireEditorContext } from '../../ContextProvider/QuestionnaireEditorContextProvider';
import { EditorDropdown } from '../Components/EditorDropdown';

type Props = {
  blueprint: AddressAutocompleteFieldBlueprint;
  nodeIds: NodeDetail[];
  questionBlueprint: QuestionBlueprint;
  disabled: boolean;
  setters: {
    setAddressAutocompleteNodeId: (value: string | undefined) => void;
    setCity: (value: string) => void;
    setPostalCodeOrZip: (value: string) => void;
    setCountryCode: (value: IsoCountryCode) => void;
    setStateOrProvince: (value: string) => void;
    setStreetAddress: (value: string | undefined) => void;
  };
  onIsValidChange?: (isValid: boolean) => void;
};

export function AddressAutocompleteFieldEditor({
  blueprint,
  questionBlueprint,
  disabled,
  nodeIds,
  setters,
  onIsValidChange,
}: Props): ReactElement | null {
  const { t } = useTranslation();
  const { selectedLanguage } = useContext(QuestionnaireEditorContext);
  const [countryCode, setCountryCode] = useState<IsoCountryCode>('CA');

  const options: AutocompleteOption[] = useMemo(
    () =>
      questionBlueprint.fields
        .filter((field) => field.fieldType !== FieldTypes.autocomplete)
        .map((field) => ({
          value: field.partName,
          label: field.title?.[selectedLanguage] ?? field.text?.[selectedLanguage] ?? field.answerNodeId,
        })),
    [questionBlueprint.fields, selectedLanguage],
  );

  // addressAutocomplete search inputs are also often the 'address street/number' input.
  const [addressAutocompleteIsAddressStreet, setAddressAutocompleteIsAddressStreet] = useState<boolean>(true);
  const [addressAutocompleteAutocompleteNodeId, setAddressAutocompleteAutocompleteNodeId] = useState<string>('');

  const [addressAutocompleteStreetAddress, setAddressAutocompleteStreetAddress] = useState<string>('');
  const [addressAutocompleteCity, setAddressAutocompleteCity] = useState<string>('');
  const [addressAutocompleteStateOrProvince, setAddressAutocompleteStateOrProvince] = useState<string>('');
  const [addressAutocompletePostalCodeOrZip, setAddressAutocompletePostalCodeOrZip] = useState<string>('');

  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (isInitialized) return;

    const initialCountryCode = blueprint.countryCode;
    if (initialCountryCode) {
      setCountryCode(initialCountryCode);
    }

    setAddressAutocompleteAutocompleteNodeId(blueprint.addressAutocompleteNodeId ?? '');

    if (blueprint.addressAutocompleteFields?.streetAddress === blueprint.partName) {
      setAddressAutocompleteIsAddressStreet(true);
    } else if (blueprint.addressAutocompleteFields?.streetAddress) {
      setAddressAutocompleteStreetAddress(blueprint.addressAutocompleteFields.streetAddress);
    }

    if (blueprint.addressAutocompleteFields?.city) {
      setAddressAutocompleteCity(blueprint.addressAutocompleteFields.city);
    }

    if (blueprint.addressAutocompleteFields?.stateOrProvince) {
      setAddressAutocompleteStateOrProvince(blueprint.addressAutocompleteFields.stateOrProvince);
    }

    if (blueprint.addressAutocompleteFields?.postalCodeOrZip) {
      setAddressAutocompletePostalCodeOrZip(blueprint.addressAutocompleteFields.postalCodeOrZip);
    }

    setIsInitialized(true);
  }, [isInitialized, blueprint]);

  const countryCodeOptions = useMemo(() => getCountryCodeOptions(t), [t]);

  const validationErrors = useMemo(() => {
    if (!isInitialized) return {};

    return validate(t, {
      streetAddress: addressAutocompleteStreetAddress,
      city: addressAutocompleteCity,
      stateOrProvince: addressAutocompleteStateOrProvince,
      postalCodeOrZip: addressAutocompletePostalCodeOrZip,
      autocompleteIsAddressStreet: addressAutocompleteIsAddressStreet,
      autocompleteNodeId: addressAutocompleteAutocompleteNodeId,
    });
  }, [
    t,
    addressAutocompleteStreetAddress,
    addressAutocompleteCity,
    addressAutocompleteStateOrProvince,
    addressAutocompletePostalCodeOrZip,
    addressAutocompleteIsAddressStreet,
    addressAutocompleteAutocompleteNodeId,
    isInitialized,
  ]);

  useEffect(() => {
    if (onIsValidChange) {
      onIsValidChange(Object.values(validationErrors).every((v) => v === undefined));
    }
  }, [validationErrors, onIsValidChange]);

  if (!isInitialized) return null;

  return (
    <Grid container spacing={2} alignItems='stretch'>
      <Grid item xs={12}>
        <EditorDropdown
          label={t('admin.questionnaireManagement.input.autocompleteCountryCode')}
          selectedOptionValue={countryCode}
          onChange={(value) => {
            if (!value) return;

            const countryCodeValue = value as IsoCountryCode;
            setCountryCode(countryCodeValue);

            setters.setCountryCode(countryCodeValue);
          }}
          disabled={disabled}
          options={countryCodeOptions}
        />
      </Grid>
      <Grid item xs={12}>
        <SimpleCheckbox
          id={`autocompleteIsAddressStreet-${blueprint.partName}`}
          label={t('admin.questionnaireManagement.input.autocompleteFieldIsTheStreetAddressField')}
          checked={addressAutocompleteIsAddressStreet}
          disabled={disabled}
          onChange={(event) => {
            const checked = event.target.checked;
            setAddressAutocompleteIsAddressStreet(checked);

            if (checked) {
              setters.setStreetAddress(blueprint.partName);
            } else {
              setAddressAutocompleteAutocompleteNodeId('');
              setters.setStreetAddress(undefined);
              setters.setAddressAutocompleteNodeId(undefined);
            }
          }}
        />
      </Grid>
      {addressAutocompleteIsAddressStreet && (
        <Grid item xs={12}>
          <NodeIdSelector
            label={t('admin.questionnaireManagement.input.autocompleteNodeId')}
            nodeIds={nodeIds}
            readOnly={disabled}
            required
            selectedNodeId={addressAutocompleteAutocompleteNodeId}
            onChange={(option) => {
              if (!option?.value) return;

              setAddressAutocompleteAutocompleteNodeId(option?.value || '');
              setters.setAddressAutocompleteNodeId(option?.value);
            }}
            selectedLanguage={selectedLanguage}
            validationError={validationErrors.streetAddressOrAutocompleteNodeId}
          />
        </Grid>
      )}
      {!addressAutocompleteIsAddressStreet && (
        <Grid item xs={12}>
          <EditorDropdown
            label={t('admin.questionnaireManagement.input.autocompleteStreetAddressField')}
            selectedOptionValue={addressAutocompleteStreetAddress}
            disabled={disabled}
            disableClearable={false}
            required
            onChange={(value) => {
              setAddressAutocompleteStreetAddress(value ?? '');
              setters.setStreetAddress(value ?? '');
            }}
            validationError={validationErrors.streetAddressOrAutocompleteNodeId}
            options={options}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <EditorDropdown
          label={t('admin.questionnaireManagement.input.autocompleteCityField')}
          selectedOptionValue={addressAutocompleteCity}
          disabled={disabled}
          disableClearable={false}
          required
          onChange={(value) => {
            setAddressAutocompleteCity(value ?? '');
            setters.setCity(value ?? '');
          }}
          validationError={validationErrors.city}
          options={options}
        />
      </Grid>
      <Grid item xs={12}>
        <EditorDropdown
          label={t('admin.questionnaireManagement.input.autocompleteStateOrProvinceField')}
          selectedOptionValue={addressAutocompleteStateOrProvince}
          disabled={disabled}
          disableClearable={false}
          required
          onChange={(value) => {
            setAddressAutocompleteStateOrProvince(value ?? '');
            setters.setStateOrProvince(value ?? '');
          }}
          validationError={validationErrors.stateOrProvince}
          options={options}
        />
      </Grid>
      <Grid item xs={12}>
        <EditorDropdown
          label={t('admin.questionnaireManagement.input.autocompletePostalCodeOrZipField')}
          selectedOptionValue={addressAutocompletePostalCodeOrZip}
          disabled={disabled}
          disableClearable={false}
          required
          onChange={(value) => {
            setAddressAutocompletePostalCodeOrZip(value ?? '');
            setters.setPostalCodeOrZip(value ?? '');
          }}
          validationError={validationErrors.postalCodeOrZip}
          options={options}
        />
      </Grid>
    </Grid>
  );
}
