import { SignatureFieldBlueprint } from '@breathelife/types';

import { FieldDataSetterProps } from '../../../Helpers/helperTypes';
import { SignatureFieldSetters, useSignatureFieldData } from '../useSignatureFieldData';
import { useCommonFieldDataSetters } from './useCommonFieldDataSetters';

export function SignatureFieldDataSetter({
  initialData,
  dataLabelOptions,
  ...props
}: FieldDataSetterProps<SignatureFieldBlueprint, SignatureFieldSetters>): null {
  const { data, setters, validationOptions, defaultValueValidationError } = useSignatureFieldData({
    initialData,
    dataLabelOptions,
  });

  useCommonFieldDataSetters<SignatureFieldBlueprint, SignatureFieldSetters>({
    ...props,
    data,
    setters,
    validationOptions,
    defaultValueValidationError,
    getIsValidBlueprint: (blueprint) => !!(blueprint.validateAs && blueprint.participantRole),
  });
  return null;
}
