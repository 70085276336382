import styled from '../../../../../../Styles/themed-styled-components';

import { NaEmpty } from '../../../../../../Assets/NaEmpty';
import { NaPending } from '../../../../../../Assets/NaPending';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 55px;
`;

export const Text = styled.p`
  color: ${(props) => props.theme.colors.grey[60]};
`;

export const TopText = styled(Text)`
  font-size: 16px;
  margin-bottom: 0;
`;

export const BottomText = styled(Text)`
  font-size: 16px;
`;

export const NaPendingImage = styled(NaPending)`
  height: 170px;
  width: 170px;
  margin: 30px;
`;

export const NaEmptyImage = styled(NaEmpty)`
  height: 170px;
  width: 170px;
  margin: 30px;
`;
