import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type AssistedApplicationState = {
  disableTrackESignInfoModal: boolean;
  isDocumentSignInfoBoxOpen: boolean;
};

type ActionsType = {
  setDisableTrackESignInfoModal: (state: AssistedApplicationState, { payload }: PayloadAction<boolean>) => void;
  setIsDocumentSignInfoBoxOpen: (state: AssistedApplicationState, { payload }: PayloadAction<boolean>) => void;
};

const defaultState: AssistedApplicationState = {
  disableTrackESignInfoModal: false,
  isDocumentSignInfoBoxOpen: true,
};

export const assistedApplicationSlice = createSlice<AssistedApplicationState, ActionsType>({
  name: 'leadPlatform/assisted-application',
  initialState: defaultState,
  reducers: {
    setDisableTrackESignInfoModal: (state: AssistedApplicationState, { payload }: PayloadAction<boolean>) => {
      state.disableTrackESignInfoModal = payload;
    },
    setIsDocumentSignInfoBoxOpen: (state: AssistedApplicationState, { payload }: PayloadAction<boolean>) => {
      state.isDocumentSignInfoBoxOpen = payload;
    },
  },
});
