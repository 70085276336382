import { ReactElement } from 'react';

import { SvgIconProps } from '../../Components/Icons';

export function NotificationMarkAsUnreadEyeIcon(props: SvgIconProps): ReactElement {
  return (
    <svg width='16' height='13' viewBox='0 0 16 13' fill='none' xmlns='http://www.w3.org/2000/svg' {...props.svgProps}>
      <path d='M0.970018 0.127518C0.710018 -0.0774815 0.332518 -0.0299815 0.127518 0.230018C-0.0774815 0.490018 -0.0299815 0.867519 0.230018 1.07252L15.03 12.6725C15.29 12.8775 15.6675 12.83 15.8725 12.57C16.0775 12.31 16.03 11.9325 15.77 11.7275L13.14 9.66752C14.13 8.65252 14.8 7.51502 15.1375 6.70752C15.22 6.51002 15.22 6.29002 15.1375 6.09252C14.765 5.20002 13.9825 3.90002 12.8125 2.81502C11.6375 1.72002 10.02 0.800018 8.00002 0.800018C6.29502 0.800018 4.87502 1.45752 3.76752 2.32002L0.970018 0.127518ZM4.74502 3.08502C5.65002 2.44252 6.73752 2.00002 8.00002 2.00002C9.63002 2.00002 10.97 2.74002 11.9975 3.69252C12.96 4.58752 13.625 5.65002 13.965 6.40002C13.65 7.10002 13.05 8.07002 12.1925 8.92252L10.8475 7.86752C11.075 7.42752 11.2025 6.93002 11.2025 6.40002C11.2025 4.63252 9.77002 3.20002 8.00252 3.20002C7.19752 3.20002 6.46002 3.49752 5.89752 3.98752L4.74502 3.08502ZM9.87252 7.10502L7.83502 5.50752C7.94002 5.29502 8.00002 5.05252 8.00002 4.80002C8.00002 4.66252 7.98252 4.52752 7.95002 4.40002C7.96752 4.40002 7.98252 4.40002 8.00002 4.40002C9.10502 4.40002 10 5.29502 10 6.40002C10 6.64752 9.95502 6.88502 9.87252 7.10502ZM11.155 11.1875L10.1075 10.3625C9.47002 10.635 8.76752 10.8 8.00002 10.8C6.37002 10.8 5.03002 10.06 4.00252 9.10752C3.04002 8.21252 2.37502 7.15002 2.03502 6.40002C2.24252 5.94002 2.57252 5.36252 3.02002 4.78002L2.07752 4.03752C1.50752 4.78002 1.10002 5.52002 0.862518 6.09252C0.780018 6.29002 0.780018 6.51002 0.862518 6.70752C1.23502 7.60002 2.01752 8.90002 3.18752 9.98502C4.36252 11.08 5.98002 12 8.00002 12C9.19502 12 10.2475 11.6775 11.155 11.1875ZM8.95502 9.45502L7.55002 8.35002C6.96252 8.21502 6.47252 7.82002 6.20752 7.29252L4.80502 6.18752C4.80002 6.25752 4.79752 6.32752 4.79752 6.40002C4.79752 8.16752 6.23002 9.60002 7.99752 9.60002C8.33002 9.60002 8.65002 9.55002 8.95252 9.45502H8.95502Z' />
    </svg>
  );
}
