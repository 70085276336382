import { QuestionnaireBlueprintCopyableOption } from '@breathelife/types';

import { Application } from '../Models/Application';
import ApiService from './ApiService';

export type CreateApplicationPayload = Pick<Application, 'leadId' | 'type'>;

export type UpdateApplicationPayload = Partial<Pick<Application, 'isDeclarationSent'>>;

export async function updateApplication(applicationId: string, data: UpdateApplicationPayload): Promise<Application> {
  const response = await ApiService.proUpdateApplication<Application>(applicationId, data);
  return response.data;
}

export async function getApplication(applicationId: string): Promise<Application> {
  const response = await ApiService.proFetchApplication<Application>(applicationId);
  return response.data;
}

export async function copyApplication(data: {
  applicationId: string;
  copyableOptions: QuestionnaireBlueprintCopyableOption[];
}): Promise<Application> {
  const response = await ApiService.proCopyApplication<Application>(data);
  return response.data;
}
