import { Box } from '@breathelife/mui';
import _ from 'lodash';
import { ReactElement, Fragment, useCallback, useEffect, useState } from 'react';
import styled, { css } from '../../../../Styles/themed-styled-components';

import { User } from '@breathelife/types';

import { SubmitButton } from '../../../../Components/Button/SubmitButton';
import { SearchTextField } from '../../../../Components/TextInputs';
import { ModalLayout } from '../../../../Layouts/Modal/ModalLayout';
import { UserListView } from '../../../../Pages/Home/LeadsListView/UserListView';
import { GetUsersOptions } from '../../../../Services/Users/UsersService';

export enum DefaultLeadsListFilterIds {
  All = 'ALL',
  Unassigned = 'UNASSIGNED',
}

export interface UserListModalItem {
  title: string;
  subtitle: string;
  icon: ReactElement | null;
  id: string;
  firstName?: string;
  lastName?: string;
  email?: string;
}

export type UserListModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  onModalSubmit: (selectedUserId: string) => void;
  setFetchUsersOptions: (fetchOptions: GetUsersOptions) => void;
  users: User[];
  isLoading: boolean;
  isAssigningLeads?: boolean;
  title: string;
  submitLabel: string;
  staticItems: UserListModalItem[];
  isSearchEnabled: boolean;
  defaultSelectedUserId: string;
};

const modalLayoutBodyStyling = css`
  padding: 0;
`;

const modalLayoutFooterStyling = css`
  border-top: none;
`;

const StyledSearchContainer = styled(Box)`
  border-bottom: 1px solid ${(props) => props.theme.colors.grey[40]};
`;

export function UserListModal(props: UserListModalProps): ReactElement | null {
  const [searchString, setSearchString] = useState('');
  const [selectedUserId, setSelectedUserId] = useState<string>(DefaultLeadsListFilterIds.All);
  const {
    isAssigningLeads,
    staticItems,
    setFetchUsersOptions,
    users,
    title,
    isLoading,
    submitLabel,
    isSearchEnabled,
    onModalSubmit,
    defaultSelectedUserId,
  } = props;

  const onSearchChange = useCallback(
    _.debounce((search: string) => {
      setSearchString(search);
    }, 400),
    [],
  );

  useEffect(() => {
    if (!defaultSelectedUserId) return;
    setSelectedUserId(defaultSelectedUserId);
  }, [defaultSelectedUserId, setSelectedUserId]);

  useEffect(() => {
    if (!isSearchEnabled) return;
    setFetchUsersOptions({
      limit: 25,
      query: 'usersCanAssignTo',
      fields: ['firstName', 'lastName', 'emailLogin', 'auth0Id', 'picture'],
      search: searchString,
    });
  }, [setFetchUsersOptions, searchString, isSearchEnabled]);

  const onSubmit = useCallback(() => {
    onModalSubmit(selectedUserId);
  }, [onModalSubmit, selectedUserId]);

  return (
    <ModalLayout
      bodyStyling={modalLayoutBodyStyling}
      footerStyling={modalLayoutFooterStyling}
      isOpen={props.isOpen}
      closeModal={props.closeModal}
      title={title}
      submitButton={
        <SubmitButton
          isLoading={isLoading || isAssigningLeads}
          disabled={isLoading || isAssigningLeads}
          onClick={onSubmit}
        >
          {submitLabel}
        </SubmitButton>
      }
    >
      <Fragment>
        {isSearchEnabled && (
          <StyledSearchContainer px={2} pb={2} mt={2}>
            <SearchTextField onSearchChange={onSearchChange} fullWidth />
          </StyledSearchContainer>
        )}
        <UserListView
          users={users}
          selectedUserId={selectedUserId}
          onUserSelection={setSelectedUserId}
          isLoading={isLoading}
          staticItems={staticItems}
        />
      </Fragment>
    </ModalLayout>
  );
}
