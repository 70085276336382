import { Box } from '@breathelife/mui';
import { ReactElement } from 'react';

import { User } from '@breathelife/types';

import { Drawer } from '../../../../Components/Drawer/Drawer';
import { DetailViewType } from '../../../../Models/Layout';
import { UserCategory, UserRole, UserType } from '../../../../Services/Auth0';
import { UserDetailContentHeader } from './UserDetailContentHeader';
import { UserDetailHeader } from './UserDetailHeader';
import { InputState, UserForm, UserFormState } from './UserForm';

type Props = {
  isOpen: boolean;
  onCreateUser: () => void;
  onUpdateUser: () => void;
  onClose: () => void;
  onOpenResendInvitationModal: (user: User) => void;
  viewType: DetailViewType;
  selectedUser?: User;
  isFormValid: boolean;
  userFormState: UserFormState;
  onProfilePictureChange: (url: string) => void;
  onFirstNameChange: (formData: InputState) => void;
  onLastNameChange: (formData: InputState) => void;
  onEmailChange: (formData: InputState) => void;
  onPhoneNumberChange: (formData: InputState) => void;
  onUserIdChange: (formData: InputState) => void;
  onGroupsChange: (value: string[]) => void;
  onRoleChange: (value: UserRole) => void;
  onCategoryChange: (value: UserCategory) => void;
  onTypeChange: (value: UserType) => void;
};

export function UserDetailView(props: Props): ReactElement | null {
  const {
    userFormState,
    viewType,
    onClose,
    onUpdateUser,
    onCreateUser,
    isFormValid,
    selectedUser,
    onProfilePictureChange,
    onFirstNameChange,
    onLastNameChange,
    onEmailChange,
    onPhoneNumberChange,
    onUserIdChange,
    onGroupsChange,
    onRoleChange,
    onCategoryChange,
    onTypeChange,
  } = props;

  return (
    <Drawer open={props.isOpen} onClose={onClose}>
      <section>
        <UserDetailHeader
          isLoading={false}
          onClose={onClose}
          onOpenResendInvitationModal={props.onOpenResendInvitationModal}
          toggleBlockUser={() => {}}
          // TODO: pass only required props
          user={selectedUser}
          profilePicture={userFormState.picture}
          onProfilePictureChange={onProfilePictureChange}
          email={userFormState.email.value}
          firstName={userFormState.firstName.value}
          lastName={userFormState.lastName.value}
          isSso={userFormState.isSso}
        />
      </section>
      <UserDetailContentHeader
        isFormValid={!!isFormValid}
        viewType={viewType}
        onClose={onClose}
        onCreateClick={onCreateUser}
        onSaveClick={onUpdateUser}
      />
      <Box px={3}>
        <UserForm
          userFormState={userFormState}
          onFirstNameChange={onFirstNameChange}
          onLastNameChange={onLastNameChange}
          onEmailChange={onEmailChange}
          onPhoneNumberChange={onPhoneNumberChange}
          onGroupsChange={onGroupsChange}
          onUserIdChange={onUserIdChange}
          onRoleChange={onRoleChange}
          onCategoryChange={onCategoryChange}
          onTypeChange={onTypeChange}
        />
      </Box>
    </Drawer>
  );
}
