import { Box } from '@breathelife/mui';
import { ReactElement, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '../../../../../../Styles/themed-styled-components';

import { Permission } from '@breathelife/types';

import { Button } from '../../../../../../Components/Button/Button';
import { RestrictedToUserPermissions } from '../../../../../../Components/Restricted/RestrictedToUserPermissions';
import { useCarrierContext } from '../../../../../../Hooks';
import { Lead } from '../../../../../../Models/Lead';
import { Container, TopText, BottomText, NaEmptyImage } from './Styles';

type Props = {
  lead?: Lead;
  onSendToNeedsAnalysisClick: () => void;
};

const StyledButton = styled(Button)`
  margin-top: 35px;
`;

export function NotSent(props: Props): ReactElement {
  const { t } = useTranslation();
  const { isEmailActionEnabled } = useCarrierContext();
  const { lead } = props;
  const isSendNeedsAnalysisButtonEnabled = !!lead?.assignedToId && isEmailActionEnabled(lead.status);
  return (
    <Container>
      <Box mt={3}>
        <NaEmptyImage role='img' />
        <TopText>{t('tabs.needsAnalysis.notSent.notSentYet')}</TopText>
      </Box>

      <RestrictedToUserPermissions requiredPermissions={[Permission.LeadInvitePrivate, Permission.LeadInviteEmail]}>
        <Fragment>
          <BottomText>{t('tabs.needsAnalysis.notSent.wantToSendItNow')}</BottomText>
          <StyledButton
            color='primary'
            variant='contained'
            onClick={props.onSendToNeedsAnalysisClick}
            data-testid={'send-needs-analysis-button'}
            disabled={!isSendNeedsAnalysisButtonEnabled}
          >
            {t('cta.sendNeedsAnalysis')}
          </StyledButton>
        </Fragment>
      </RestrictedToUserPermissions>
    </Container>
  );
}
