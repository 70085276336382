import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SalesDecisionRule } from '@breathelife/types';

export type SalesDecisionRuleManagementState = {
  salesDecisionRules: SalesDecisionRule[];
  total: number;
  isLoadingRules: boolean;
  isReloadingRules: boolean;
};

type SalesDecisionRulePayload = { rule: SalesDecisionRule };
type SalesDecisionTotalPayload = { total: number };
type SalesDecisionRulesPayload = { rules: SalesDecisionRule[] };
type LoadingPayload = { isLoading: boolean; isForReload?: boolean };
type RemoveSalesDecisionRulesPayload = { id: string };

type ActionsType = {
  setRules: (state: SalesDecisionRuleManagementState, action: PayloadAction<SalesDecisionRulesPayload>) => void;
  setTotal: (state: SalesDecisionRuleManagementState, action: PayloadAction<SalesDecisionTotalPayload>) => void;
  addRule: (state: SalesDecisionRuleManagementState, action: PayloadAction<SalesDecisionRulePayload>) => void;
  updateRule: (state: SalesDecisionRuleManagementState, action: PayloadAction<SalesDecisionRulePayload>) => void;
  removeRule: (state: SalesDecisionRuleManagementState, action: PayloadAction<RemoveSalesDecisionRulesPayload>) => void;
  setIsLoadingRules: (state: SalesDecisionRuleManagementState, action: PayloadAction<LoadingPayload>) => void;
};

const defaultState: SalesDecisionRuleManagementState = {
  salesDecisionRules: [],
  total: 0,
  isLoadingRules: false,
  isReloadingRules: false,
};

export const salesDecisionRuleManagementSlice = createSlice<SalesDecisionRuleManagementState, ActionsType>({
  name: 'leadPlatform/salesDecisionRulesManagement',
  initialState: defaultState,
  reducers: {
    setRules: (state, { payload }) => {
      state.salesDecisionRules = payload.rules;
    },
    setTotal: (state, { payload }) => {
      state.total = payload.total;
    },
    addRule: (state, { payload }) => {
      state.salesDecisionRules = [payload.rule].concat(state.salesDecisionRules);
      state.total = state.total + 1;
    },
    updateRule: (state, { payload }) => {
      state.salesDecisionRules = state.salesDecisionRules.map((rule) => {
        if (rule.id !== payload.rule.id) return rule;
        return payload.rule;
      });
    },
    removeRule: (state, { payload }) => {
      state.salesDecisionRules = state.salesDecisionRules.filter((rule) => rule.id !== payload.id);
      state.total = state.total - 1;
    },
    setIsLoadingRules: (state, { payload }) => {
      if (payload.isForReload) {
        // Reloading does not need a blank state for the whole page.
        state.isReloadingRules = payload.isLoading;
      } else {
        state.isLoadingRules = payload.isLoading;
      }
    },
  },
});
