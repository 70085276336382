import { SVGProps, ReactElement } from 'react';

export function Search(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg
      fill='#fff'
      height='16'
      stroke='#757575'
      viewBox='0 0 16 16'
      width='16'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <clipPath id='a'>
        <path d='m0 0h16v16h-16z' />
      </clipPath>
      <g clipPath='url(#a)' strokeLinecap='round' strokeLinejoin='round'>
        <path d='m.980412 8.90441c.626768 1.47479 1.813718 2.64019 3.299728 3.23979 1.48602.5997 3.14938.5844 4.62415-.0424 1.47481-.6267 2.64021-1.8137 3.23981-3.29969.5996-1.48601.5844-3.14937-.0424-4.62415-.6267-1.47478-1.8137-2.64017-3.2997-3.239806-1.48602-.599636-3.14937-.584394-4.62415.042372-1.47478.626764-2.64018 1.813714-3.239811 3.299734-.599635 1.48601-.584393 3.14937.042373 4.62415z' />
        <path d='m10.8125 10.8125 4.686 4.6867' />
      </g>
    </svg>
  );
}
