import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { QueryId } from '../../../ReactQuery/common/common.types';
import { UserGroup } from '@breathelife/types';
import ApiService from '../../../Services/ApiService';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { notificationSlice } from '../../../ReduxStore/Notification/NotificationSlice';

export function useAllUserGroups(options?: UseQueryOptions<UserGroup[]>): UseQueryResult<UserGroup[]> {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return useQuery<UserGroup[]>(
    QueryId.userGroups,
    async () => {
      const response = await ApiService.admin.findAllUserGroups();
      return response.data;
    },
    {
      ...options,
      onError(err) {
        dispatch(
          notificationSlice.actions.setError({
            message: t('notifications.failedToFetchUserGroups'),
          }),
        );
        options?.onError?.(err);
      },
    },
  );
}

export function useAllUserGroupsForUser(
  userId?: string,
  options?: UseQueryOptions<UserGroup[]>,
): UseQueryResult<UserGroup[]> {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return useQuery<UserGroup[]>(
    [QueryId.userGroups, { userId }],
    async () => {
      if (!userId) {
        return [];
      }
      const response = await ApiService.admin.findUserGroupsForUser(userId);
      return response.data;
    },
    {
      ...options,
      onError(err) {
        dispatch(
          notificationSlice.actions.setError({
            message: t('notifications.failedToFetchUserGroups'),
          }),
        );
        options?.onError?.(err);
      },
    },
  );
}
