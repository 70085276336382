import _ from 'lodash';
import { ReactElement } from 'react';

import { Skeleton, Box } from '@breathelife/mui';
import { ResponsiveProps, withResponsive } from '@breathelife/ui-components';

import { tableHeight } from '../../Models/Layout';
import { LoadingTablePlaceholder, LoadingViewContainer } from './Styles';

const ROW_HEIGHT = 56;
const BOTTOM_MARGIN_IN_PX = 8;
const DEFAULT_AMOUNT_OF_ROWS = 5;

function LoadingRectangle(i: number): ReactElement {
  return (
    <Box mb={1} key={i}>
      <Skeleton variant='rectangular' height={ROW_HEIGHT} />
    </Box>
  );
}

function calculateTotalAmountOfRows(propsHeight: number | undefined): number {
  if (propsHeight && propsHeight > tableHeight) {
    return _.floor((propsHeight - tableHeight) / (ROW_HEIGHT + BOTTOM_MARGIN_IN_PX));
  }

  return DEFAULT_AMOUNT_OF_ROWS;
}

function LoadingView(props: ResponsiveProps): ReactElement {
  const totalAmountOfRows = calculateTotalAmountOfRows(props.height);

  return (
    <LoadingViewContainer px={2.5} py={2.5}>
      <LoadingTablePlaceholder p={2} borderRadius={5}>
        {_.times(totalAmountOfRows, LoadingRectangle)}
      </LoadingTablePlaceholder>
      <Box pt={1} display='flex' justifyContent='center'>
        <Box pr={1}>
          <Skeleton variant='circular' height={25} width={25} />
        </Box>
        <Box pr={1}>
          <Skeleton variant='circular' height={25} width={25} />
        </Box>
        <Skeleton variant='circular' height={25} width={25} />
      </Box>
    </LoadingViewContainer>
  );
}

export default withResponsive(LoadingView);
