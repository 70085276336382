import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../Styles/themed-styled-components';

import { LeadPlatformTheme } from '../../Styles/Types';
import { StyledBadge } from './Badge';
import { CarrierSubmissionStatus } from '@breathelife/types';

type GenericSuccessStatus = CarrierSubmissionStatus;

export type Props = {
  status: GenericSuccessStatus;
};

export function GenericSuccessStatusBadge(props: Props): ReactElement | null {
  const theme = useTheme() as LeadPlatformTheme;
  const { t } = useTranslation();
  const { status } = props;

  const StatusTextColors: {
    [status in GenericSuccessStatus]: { textColor: string; backgroundColor: string };
  } = {
    success: { textColor: theme.colors.green[60], backgroundColor: theme.colors.green[20] },
    manuallySent: { textColor: theme.colors.green[60], backgroundColor: theme.colors.green[20] },
    error: { textColor: theme.colors.red[60], backgroundColor: theme.colors.red[20] },
  };

  const statusLabel = t(`applicationSupport.badge.genericSuccessStatus.${status}`);
  const { backgroundColor, textColor } = StatusTextColors[status];

  return <StyledBadge backgroundColor={backgroundColor} textColor={textColor} label={statusLabel} />;
}
