import { useTranslation } from 'react-i18next';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { useDispatch } from 'react-redux';

import { ApplicationSupportAssociatedFile, PageQueryOptions, Paginated } from '@breathelife/types';

import { notificationSlice } from '../../../ReduxStore/Notification/NotificationSlice';
import { getApplicationSupportAssociatedFiles } from '../../../Services/ApplicationSupportService';
import { QueryId } from '../../common/common.types';

export function useFindAssociatedFilesQuery(
  applicationId: string,
  pageQueryOptions?: PageQueryOptions<ApplicationSupportAssociatedFile>,
  options?: UseQueryOptions<Paginated<ApplicationSupportAssociatedFile>>,
): UseQueryResult<Paginated<ApplicationSupportAssociatedFile>> {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return useQuery<Paginated<ApplicationSupportAssociatedFile>>(
    [QueryId.applicationSupportAssociatedFiles, applicationId],
    async () => {
      return await getApplicationSupportAssociatedFiles(applicationId, pageQueryOptions);
    },
    {
      ...options,
      enabled: !!applicationId,
      onError: () => {
        dispatch(
          notificationSlice.actions.setError({
            message: t('notifications.failedToFetchAssociatedFiles'),
          }),
        );
      },
    },
  );
}
