import { Box } from '@breathelife/mui';
import { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import LoadingView from '../../../../Components/LoadingView/LoadingView';
import { useFindAssociatedFilesQuery } from '../../../../ReactQuery/ApplicationSupport/AssociatedFiles/associatedFiles.queries';
import { AssociatedFilesTable } from './AssociatedFilesTable';
import { ApplicationSupportAssociatedFile } from '@breathelife/types';
import { useTranslation } from 'react-i18next';

export function hasAssociatedFiles(
  files: ApplicationSupportAssociatedFile[] | undefined,
): files is ApplicationSupportAssociatedFile[] {
  return typeof files !== 'undefined' && Array.isArray(files) && !!files.length;
}

export function AssociatedFilesPage(): ReactElement | null {
  const { t } = useTranslation();

  const { applicationId = '' } = useParams<{ applicationId: string }>();
  const data = useFindAssociatedFilesQuery(applicationId);
  const { isFetched, data: filesResponse } = data;

  const [associatedFiles, setAssociatedFiles] = useState<ApplicationSupportAssociatedFile[]>([]);

  useEffect(() => {
    const files = filesResponse?.data;
    if (isFetched && hasAssociatedFiles(files)) {
      setAssociatedFiles(files);
    }
  }, [isFetched, filesResponse]);

  if (!isFetched) {
    return <LoadingView />;
  }

  if (!filesResponse) {
    return null;
  }

  if (!associatedFiles.length) {
    return <Box p='1.1rem'>{t('applicationSupport.associatedFiles.noFilesFound')}</Box>;
  }

  return (
    <Box mx={2} mt={1.5}>
      <AssociatedFilesTable associatedFiles={associatedFiles} />
    </Box>
  );
}
