import { ReactElement, useContext, useMemo } from 'react';

import { PartIdentifierTag, QuestionnaireBlueprint } from '@breathelife/types';
import {
  AutocompleteOption,
  FormattedTreeViewItemData,
  TreeViewItemRenderChildProps,
} from '@breathelife/ui-components';

import { arePartIdentifiersTheSame } from '../Helpers/arePartIdentifiersTheSame';
import { findElementBlueprintFromPartIdentifier } from '../Helpers/findBlueprintFromPartIdentifier';
import {
  getElementTypeFromTreePathLength,
  makePartIdentifierFromTreePath,
} from '../Helpers/makePartIdentifierFromTreePath';
import { useQuestionnaireElementActions } from '../Hooks/useQuestionnaireElementActions';
import { QuestionnaireTreeViewDragContext } from './QuestionnaireTreeViewDragContext';
import { QuestionnaireTreeViewItem, SearchInfo } from './QuestionnaireTreeViewItem';

const CHILD_TYPES_MAP = {
  [PartIdentifierTag.section]: PartIdentifierTag.subsection,
  [PartIdentifierTag.subsection]: PartIdentifierTag.question,
  [PartIdentifierTag.question]: PartIdentifierTag.field,
};

type QuestionnaireTreeViewItemWrapperProps = {
  questionnaireBlueprint: QuestionnaireBlueprint;
  sectionGroupCollectionNodeId: string | undefined;
  data: FormattedTreeViewItemData;
  canPaste: boolean;
  selected: boolean;
  copyItem: (path: string[]) => void;
  onClick: (data: FormattedTreeViewItemData) => void;
  dataLabelOptions: AutocompleteOption[];
  isEditDisabled: boolean;
  dragHandleProps?: TreeViewItemRenderChildProps['dragHandleProps'];
};

export type QuestionnaireTreeViewItemMetadata = { searchInfo: SearchInfo };

export function QuestionnaireTreeViewItemWrapper({
  questionnaireBlueprint,
  data,
  canPaste,
  selected,
  copyItem,
  onClick,
  dataLabelOptions,
  isEditDisabled,
  dragHandleProps,
  sectionGroupCollectionNodeId,
}: QuestionnaireTreeViewItemWrapperProps): ReactElement | null {
  const elementType = getElementTypeFromTreePathLength(data.path.length);
  const partIdentifier = makePartIdentifierFromTreePath(data.path);

  const blueprintAndHiddenStatus = partIdentifier
    ? findElementBlueprintFromPartIdentifier(questionnaireBlueprint, partIdentifier)
    : undefined;

  const blueprint = blueprintAndHiddenStatus?.blueprint;
  const areParentsHidden = blueprintAndHiddenStatus?.areParentsHidden;

  const { currentDraggedItemPartIdentifier, isUserDragging } = useContext(QuestionnaireTreeViewDragContext);

  const { removeItem, pasteItem, addItem, toggleItemHiddenState } = useQuestionnaireElementActions({
    isQuestionnaireEditingEnabled: !isEditDisabled,
    areParentsHidden,
  });

  const isCurrentlyDragged = useMemo(
    () =>
      !!(
        isUserDragging &&
        partIdentifier &&
        currentDraggedItemPartIdentifier &&
        arePartIdentifiersTheSame(currentDraggedItemPartIdentifier, partIdentifier)
      ),
    [isUserDragging, partIdentifier, currentDraggedItemPartIdentifier],
  );

  if (!partIdentifier) {
    return null;
  }

  const { searchInfo } = (data.metadata as QuestionnaireTreeViewItemMetadata) || {};

  const childType = Object.keys(CHILD_TYPES_MAP).includes(elementType)
    ? CHILD_TYPES_MAP[elementType as keyof typeof CHILD_TYPES_MAP]
    : undefined;

  return (
    <QuestionnaireTreeViewItem
      partIdentifier={partIdentifier}
      dragHandleProps={dragHandleProps}
      blueprint={blueprint}
      sectionGroupCollectionNodeId={sectionGroupCollectionNodeId}
      type={elementType}
      childType={childType}
      isEditDisabled={isEditDisabled}
      areParentsHidden={areParentsHidden}
      onCopy={() => {
        copyItem(data.path);
      }}
      canPaste={!!canPaste}
      onPaste={() => {
        if (!partIdentifier) {
          return;
        }
        pasteItem(partIdentifier);
      }}
      onRemove={partIdentifier ? () => removeItem(partIdentifier) : undefined}
      label={data.label}
      selected={selected}
      onClick={() => onClick(data)}
      dataLabelOptions={dataLabelOptions}
      onAddChild={
        elementType !== PartIdentifierTag.field
          ? (blueprint) => {
              addItem(blueprint, partIdentifier || undefined);
            }
          : undefined
      }
      onHide={() => toggleItemHiddenState(partIdentifier, !blueprint?.hidden)}
      isCurrentlyDragged={isCurrentlyDragged}
      searchInfo={searchInfo}
    />
  );
}
