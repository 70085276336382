import { ReactElement } from 'react';

import { SvgIconProps } from '../../Components/Icons/Icon';

export function UnassignedProfilePictureIcon(props: SvgIconProps): ReactElement {
  return (
    <svg
      stroke='#bcbcbc'
      fill='#fff'
      height='30'
      viewBox='0 0 30 30'
      width='30'
      xmlns='http://www.w3.org/2000/svg'
      {...props.svgProps}
    >
      <g>
        <circle cx='15' cy='15' r='14.5' />
        <g strokeLinecap='round' strokeLinejoin='round'>
          <path d='m11.25 18.7504 7.5004-7.5004' />
          <path d='m18.7504 18.7504-7.5004-7.5004' />
        </g>
      </g>
    </svg>
  );
}
