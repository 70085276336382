import { ReactElement, useEffect, useRef, useState } from 'react';

import { Tooltip } from '@breathelife/mui';

import styled from '../../Styles/themed-styled-components';

const WIDTH_THRESHOLD = 300;

const StyledEmail = styled.div`
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: ${WIDTH_THRESHOLD}px;
`;

type EmailProps = {
  email: string;
};

export function Email(props: EmailProps): ReactElement | null {
  const emailContainerRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const element = emailContainerRef.current;
    if (element) {
      setWidth(element.clientWidth);
    }
  }, [props.email]);

  const isTooltipEnabled = width >= WIDTH_THRESHOLD;

  return (
    <Tooltip
      disableFocusListener={!isTooltipEnabled}
      disableHoverListener={!isTooltipEnabled}
      disableTouchListener={!isTooltipEnabled}
      title={props.email || ''}
      disableInteractive
    >
      <StyledEmail ref={emailContainerRef}>{props.email}</StyledEmail>
    </Tooltip>
  );
}
