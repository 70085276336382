import { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { GetAppIcon, Tooltip } from '@breathelife/mui';

import { HeaderButton } from '../../Pages/Admin/Questionnaire/QuestionnaireEditor/Components/HeaderButton';
import { ExportCtx } from './ExportDataProvider';

export function ExportButton(): ReactElement {
  const { isLoading, onExportButtonClick } = useContext(ExportCtx);
  const { t } = useTranslation();
  const tooltipTitle = t('admin.exportData.tooltip');

  return (
    <HeaderButton onClick={onExportButtonClick} disabled={isLoading} sx={{ padding: '22px' }}>
      <Tooltip title={tooltipTitle} disableInteractive>
        <GetAppIcon />
      </Tooltip>
    </HeaderButton>
  );
}
