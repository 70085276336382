import { ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { GetAppIcon } from '@breathelife/mui';

import { Button } from '../../../../Components/Button/Button';
import { useDispatch } from '../../../../Hooks';
import { notificationSlice } from '../../../../ReduxStore/Notification/NotificationSlice';
import ApiService from '../../../../Services/ApiService';

type Props = {
  id: string;
  version: string;
};

export function DownloadApplicationSchemaButton({ id, version }: Props): ReactElement {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleApplicationSchemaDownload = useCallback(async () => {
    try {
      const { data } = await ApiService.admin.fetchApplicationSchemaForQuestionnaire(id);

      const dataToString = JSON.stringify(data, null, 4);
      const dataBlob = new Blob([dataToString], { type: 'application/json' });
      const dataBlobURL =
        window.URL && window.URL.createObjectURL
          ? window.URL.createObjectURL(dataBlob)
          : window.webkitURL.createObjectURL(dataBlob);
      const tempLink = document.createElement('a');
      tempLink.style.display = 'none';
      tempLink.href = dataBlobURL;
      tempLink.setAttribute('download', `BL_application_schema_questionnaire_version_${version.replace(/\./g, '_')}`);
      document.body.appendChild(tempLink);
      tempLink.click();
      tempLink.parentNode && tempLink.parentNode.removeChild(tempLink);
    } catch (error: any) {
      dispatch(
        notificationSlice.actions.setError({
          message: t('notifications.failedToFetchEntity', {
            entity: t('admin.questionnaireManagement.table.applicationSchema'),
          }),
        }),
      );
    }
  }, [id, version, dispatch, t]);

  return (
    <Button
      onClick={(event) => {
        event.stopPropagation();
        void handleApplicationSchemaDownload();
      }}
    >
      <GetAppIcon />
    </Button>
  );
}
