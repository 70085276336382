import _ from 'lodash';
import { useState, useEffect } from 'react';
import { ValidationError } from 'yup';

import { usePrevious } from '@breathelife/react-hooks';
import { FieldBlueprint } from '@breathelife/types';
import { AutocompleteOption } from '@breathelife/ui-components';

import { FieldSetters, FieldDataSetterProps } from '../../../Helpers/helperTypes';

type Props<TBlueprint extends FieldBlueprint, TSetters extends FieldSetters> = Omit<
  FieldDataSetterProps<TBlueprint, TSetters>,
  'initialData' | 'dataLabelOptions'
> & {
  data: TBlueprint;
  setters: TSetters;
  validationOptions: AutocompleteOption[];
  defaultValueValidationError: ValidationError | undefined;
  getIsValidBlueprint: (blueprint: TBlueprint) => boolean;
};

export function useCommonFieldDataSetters<TBlueprint extends FieldBlueprint, TSetters extends FieldSetters>({
  data,
  setters,
  setData,
  setDefaultValueValidationError,
  setIsValidBlueprint,
  setSetters,
  setValidationOptions,
  validationOptions,
  getIsValidBlueprint,
  defaultValueValidationError,
}: Props<TBlueprint, TSetters>): void {
  const [isInitial, setIsInitial] = useState<boolean>(true);
  const prevData = usePrevious(data);

  useEffect(() => {
    let isValidBlueprint = false;
    if (isInitial || !_.isEqual(data, prevData)) {
      setData(data);
      isValidBlueprint = getIsValidBlueprint(data);
      setDefaultValueValidationError(defaultValueValidationError);
      setIsValidBlueprint(
        !!(!defaultValueValidationError && data.answerNodeId && data.id && data.fieldType && isValidBlueprint),
      );
    }
    if (isInitial) {
      setIsInitial(false);
    }
  }, [
    data,
    setData,
    prevData,
    isInitial,
    setIsValidBlueprint,
    getIsValidBlueprint,
    defaultValueValidationError,
    setDefaultValueValidationError,
  ]);

  useEffect(() => {
    if (isInitial) {
      setSetters(setters);
      setValidationOptions(validationOptions);
      setIsInitial(false);
    }
  }, [setters, setIsInitial, setSetters, isInitial, setValidationOptions, validationOptions]);
}
