import { ReactElement, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '../../../../Styles/themed-styled-components';

import { SignatureType } from '@breathelife/types';

import { GreyContainer } from '../../../../Components/AssistedApplication/Styles';
import Typography from '../../../../Components/Typography';

type SubmissionDetailsProps = {
  signatureType: SignatureType;
  confirmationNumber?: string;
  cryptoSignature?: string;
};

const StyledCryptoSignature = styled(Typography)`
  word-break: break-all;
`;

export function SubmissionDetails(props: SubmissionDetailsProps): ReactElement | null {
  const { signatureType, confirmationNumber, cryptoSignature } = props;
  const { t } = useTranslation();

  if (!confirmationNumber && !cryptoSignature) return null;
  return (
    <Fragment>
      {!!confirmationNumber && (
        <GreyContainer px={2} py={1} mb={4}>
          <Typography variant='body2' grey={70} component='div'>
            {t('modals.assistedApplication.submissionDetails.confirmationNumber')}
          </Typography>
          <Typography variant='body3' grey={70} component='div'>
            {confirmationNumber}
          </Typography>
        </GreyContainer>
      )}
      {signatureType === SignatureType.cryptoSignature && !!cryptoSignature && (
        <GreyContainer p={2} mb={4}>
          <Typography variant='body2' grey={70} component='div'>
            {t('modals.assistedApplication.submissionDetails.digitalSignatureKey')}
          </Typography>
          <StyledCryptoSignature variant='body3' grey={70} component='div'>
            {cryptoSignature}
          </StyledCryptoSignature>
        </GreyContainer>
      )}
    </Fragment>
  );
}
