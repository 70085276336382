import auth0, { Auth0DecodedHash, Auth0UserProfile, AuthOptions, WebAuth } from 'auth0-js';
import dayjs from 'dayjs';
import jwtDecode from 'jwt-decode';

import { logger } from '@breathelife/monitoring-frontend';
import { User } from '@breathelife/types';

import Urls from '../../Navigation/Urls';

export const ROLES_TOKEN_ATTRIBUTE = 'https://leads.getbreathe.life/roles';
export const USER_METADATA_ATTRIBUTE = 'https://leads.getbreathe.life/user_metadata';
export const APP_METADATA_ATTRIBUTE = 'https://leads.getbreathe.life/app_metadata';
// https://auth0.com/docs/libraries/common-auth0-library-authentication-errors
export const AUTH_USER_BLOCKED = 'unauthorized';

export type Jwt = Auth0UserProfile & {
  exp: number;
  [ROLES_TOKEN_ATTRIBUTE]: UserRole[];
  [APP_METADATA_ATTRIBUTE]: AppMetadata;
};

export type Authentication = {
  jwtToken: string;
  accessToken: string;
  user: Pick<User, 'auth0Id'>;
  targetRoute?: string;
};

export enum UserType {
  captive = 'captive', // works for one insurance company
  nonCaptive = 'non-captive', // works for an agency
}

export enum UserCategory {
  registered = 'registered', // registered to a specific country state ( can only sell in this state )
  nonRegistered = 'non-registered', // can sell in any states
}

type AppMetadata = {
  type?: UserType;
  category?: UserCategory;
  group?: {
    id?: string;
    name?: string;
    email?: string;
  };
  subgroup?: {
    name: string;
  };
};

export type UserMetaData = {
  picture?: string;
  phoneNumber?: string;
  customId?: string;
  securityDisclosure?: string;
};

export enum UserRole {
  superAdmin = 'superAdmin', // Breathe Life internal user
  supportAdmin = 'supportAdmin', // Breathe Life support mostly like the "Support Manager" but with additional PII/PHI data access
  supportManager = 'supportManager', // Breathe Life support users that can reset and otherwise modify an application status
  supportAnalyst = 'supportAnalyst', // Breathe Life support users that can only observe
  admin = 'admin', // Carrier admin user
  manager = 'manager', // Managing General Agent which manage a subset of advisors
  groupManager = 'groupManager', // admin-like role but linked to a specific group. Related to the co-branding feature.
  user = 'user', // Regular advisor / agent role
  viewer = 'viewer', // Read only role
}

let auth0Instance: WebAuth;
let clientID: string;
let federated: boolean;
const initErrorMessage = 'Auth0 service is not initialized.';

/* navigator.languages are the user defined languages in the browser
   navigator.language is the OS defined language used as a fallback */
function getBrowserLocale(): string {
  const browserLocales = navigator.languages === undefined ? [navigator.language] : navigator.languages;
  if (!browserLocales) {
    return 'en';
  }
  return browserLocales[0].trim().split(/-|_/)[0]; // Take the language ordered at the top
}

export const initAuth0Instance = (
  options: AuthOptions & { ssoFederatedLogout?: boolean },
  searchParams: URLSearchParams,
): WebAuth => {
  if (auth0Instance) {
    return auth0Instance;
  }

  const { ssoFederatedLogout, ...authOptions } = options;

  clientID = authOptions.clientID;
  federated = ssoFederatedLogout || false;

  const queryStringLocale = searchParams.get('lang');
  const clientLanguage = queryStringLocale ?? getBrowserLocale();

  auth0Instance = new auth0.WebAuth({
    ...authOptions,
    redirectUri: `${window.location.origin}${Urls.authCallback.fullPath}?lang=${clientLanguage}`,
  });

  return auth0Instance;
};

export const loginAgent = (options?: auth0.AuthorizeOptions, enableAuthImprovementFlow?: boolean): void => {
  if (!auth0Instance) throw new Error(initErrorMessage);

  let responseType = options?.responseType;
  if (enableAuthImprovementFlow) responseType = 'token id_token';

  auth0Instance.authorize({ ...options, state: 'state', responseType });
};

export const logoutAgent = (isSso?: boolean): void => {
  if (!auth0Instance) throw new Error(initErrorMessage);
  // TODO replace with Urls.logout and Urls.login once every carrier auth0 tenant has the new /pro url allowed

  const url = `${window.location.origin}${isSso ? Urls.logout.fullPath : Urls.login.fullPath}`;
  auth0Instance.logout({ clientID, returnTo: url, federated });
};

export function refreshToken(): Promise<Authentication> {
  if (!auth0Instance) throw new Error(initErrorMessage);

  return new Promise<Authentication>((resolve, reject) => {
    auth0Instance.checkSession({}, (error, authResult) => {
      if (error) return reject(error);
      resolve(parseAuth0Response(authResult as Auth0DecodedHash));
    });
  });
}

export function parseUrlHash(): Promise<Authentication> {
  if (!auth0Instance) throw new Error(initErrorMessage);

  return new Promise<Authentication>((resolve, reject) => {
    auth0Instance.parseHash((error, authResult) => {
      if (error) return reject(error);
      resolve(parseAuth0Response(authResult as Auth0DecodedHash));
    });
  });
}

const isAuthResultValid = (authResult: { [key: string]: any } | null): boolean =>
  authResult && authResult.accessToken && authResult.idToken;

function parseAuth0Response(authResult: { [key: string]: any }): Authentication {
  if (!isAuthResultValid(authResult)) throw new Error('Invalid authentication.');

  const user = getUserFromToken(authResult.idToken);

  return {
    jwtToken: authResult.idToken,
    accessToken: authResult.accessToken,
    user,
    targetRoute: authResult.appState?.targetRoute,
  };
}

export function getUserFromToken(token: string): Pick<User, 'auth0Id'> {
  const decodedToken = decodeToken(token);
  return {
    auth0Id: decodedToken.sub,
  };
}

export function isJwtValid(jwt: any): Jwt {
  if (!jwt) {
    const message = 'jwt is missing';
    logger.warn(message);
    throw new Error(message);
  }

  const errorMessages = [];
  if (!jwt.name) errorMessages.push('jwt.name is missing');
  if (!jwt.email) errorMessages.push('jwt.email is missing');
  if (!jwt.exp) errorMessages.push('jwt.exp is missing');

  if (errorMessages.length > 0) {
    let message = errorMessages.join(',');
    message = message.concat(`. Jwt Value: ${JSON.stringify(jwt)}`);
    logger.warn(message);
    throw new Error(message);
  }

  return jwt as Jwt;
}

function decodeToken(token: string): Jwt {
  const decodedToken = jwtDecode<Jwt>(token);
  return isJwtValid(decodedToken);
}

export function isTokenExpired(token: string): boolean {
  try {
    const decodedToken = decodeToken(token);
    const now = dayjs();
    const expiry = dayjs(decodedToken.exp * 1000);
    return now.isAfter(expiry);
  } catch (error: any) {
    return true;
  }
}

// TODO: use identities information to get the provider / connection type instead of relying on the ID structure
// TODO: we still need to support id, since the old user format has id in it.
// New user format would contain auth0Id and we should use that one by default
export function isSsoUser(user: { auth0Id: string; id?: string }): boolean {
  const auth0Id = user.auth0Id ?? user.id ?? '';

  return !auth0Id.startsWith('auth0');
}
