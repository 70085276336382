import { useTranslation } from 'react-i18next';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { useDispatch } from 'react-redux';

import { TypewriterTracking } from '@breathelife/frontend-tracking';
import {
  ApplicationSupportApplication,
  ApplicationFilters,
  ApplicationSupportApplicationOverview,
  PageQueryOptions,
  ApplicationSearchMode,
} from '@breathelife/types';

import { notificationSlice } from '../../../ReduxStore/Notification/NotificationSlice';
import {
  GetApplicationsResponse,
  getApplicationSupportApplications,
  getApplicationSupportPageApplication,
} from '../../../Services/ApplicationSupportService';
import { QueryId } from '../../common/common.types';

export function useFindApplicationSupportApplicationsQuery(
  applicationsOptions: PageQueryOptions<ApplicationSupportApplication, ApplicationFilters, ApplicationSearchMode>,
  options?: UseQueryOptions<GetApplicationsResponse>,
): UseQueryResult<GetApplicationsResponse> {
  const dispatch = useDispatch();
  return useQuery<GetApplicationsResponse>(
    [QueryId.applicationSupportApplications, applicationsOptions],
    async () => {
      if (!applicationsOptions) return Promise.reject(new Error('Application options are missing.'));
      return await getApplicationSupportApplications(applicationsOptions);
    },
    {
      ...options,
      enabled: !!applicationsOptions,
      onSuccess: (data) => {
        options?.onSuccess?.(data);

        if (applicationsOptions?.$search) {
          TypewriterTracking.searchedForAnApplication({ hasSearchResults: !!data.total });
        }
      },
      onError: (error) => {
        options?.onError?.(error);

        if (error instanceof Error) {
          dispatch(notificationSlice.actions.setError({ message: error.message }));
        }
      },
    },
  );
}

export function useGetSupportPageApplicationQuery(
  applicationId: string,
  options?: UseQueryOptions<ApplicationSupportApplicationOverview | null>,
): UseQueryResult<ApplicationSupportApplicationOverview | null> {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return useQuery<ApplicationSupportApplicationOverview | null>(
    [QueryId.applicationSupportApplications, applicationId],
    async () => {
      return await getApplicationSupportPageApplication(applicationId);
    },
    {
      ...options,
      enabled: true,
      onError: () => {
        dispatch(
          notificationSlice.actions.setError({
            message: t('notifications.getSupportApplicationFailure', { applicationId: applicationId }),
          }),
        );
      },
    },
  );
}
