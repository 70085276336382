import { LeadPlatformStore } from '../../../ReduxStore/types';
import { ProductManagementState } from './ProductManagementSlice';

export function getProducts(state: LeadPlatformStore): ProductManagementState['products'] {
  const { productManagement } = state.leadPlatform;
  return productManagement.products;
}

export function getSelectedProduct(state: LeadPlatformStore): ProductManagementState['selectedProduct'] {
  const { productManagement } = state.leadPlatform;
  return productManagement.selectedProduct;
}

export function getSelectedProductPricing(state: LeadPlatformStore): ProductManagementState['selectedProductPricing'] {
  const { productManagement } = state.leadPlatform;
  return productManagement.selectedProductPricing;
}

export function getIsLoadingProducts(state: LeadPlatformStore): ProductManagementState['isLoadingProducts'] {
  const { productManagement } = state.leadPlatform;
  return productManagement.isLoadingProducts;
}

export function getIsLoadingPricing(state: LeadPlatformStore): ProductManagementState['isLoadingPricing'] {
  const { productManagement } = state.leadPlatform;
  return productManagement.isLoadingPricing;
}
