import { useState } from 'react';

import {
  FieldBlueprint,
  FieldTypes,
  InformationFieldBlueprint,
  InformationFieldBlueprintVariant,
  StringFieldValidation,
} from '@breathelife/types';
import { AutocompleteOption } from '@breathelife/ui-components';

import {
  FieldDataFromBlueprintWithValidateAs,
  FieldDataHookReturnValueWithValidationOptions,
  FieldSettersValidateAs,
  useCastBlueprintToFieldType,
} from '../../Helpers/helperTypes';
import { BaseFieldData, BaseFieldSetters, useBaseFieldData } from './useBaseFieldData';
import { useValidateAsData } from './useValidateAsData';

export type InformationFieldData = FieldDataFromBlueprintWithValidateAs<InformationFieldBlueprint, 'variant'> &
  BaseFieldData;

export type InformationFieldSetters = FieldSettersValidateAs<InformationFieldData> & {
  setVariant: React.Dispatch<React.SetStateAction<InformationFieldData['variant']>>;
} & BaseFieldSetters;

export function useInformationFieldData({
  initialData,
  dataLabelOptions,
}: {
  initialData?: Partial<FieldBlueprint>;
  dataLabelOptions: AutocompleteOption[];
}): FieldDataHookReturnValueWithValidationOptions<InformationFieldBlueprint, InformationFieldSetters> {
  const initialDataAsInformationField = useCastBlueprintToFieldType<InformationFieldBlueprint>(
    FieldTypes.information,
    initialData,
  );

  const { data, setters } = useBaseFieldData({ initialData, dataLabelOptions });

  const [variant, setVariant] = useState<InformationFieldData['variant']>(
    initialDataAsInformationField?.variant || InformationFieldBlueprintVariant.paragraph,
  );

  const {
    data: validateAsData,
    setters: validateAsSetters,
    validationOptions,
  } = useValidateAsData<InformationFieldBlueprint>({
    fieldType: FieldTypes.information,
    initialValidateAs: StringFieldValidation.string,
  });

  return {
    data: {
      fieldType: FieldTypes.information,
      ...data,
      variant,
      ...validateAsData,
    },
    setters: {
      ...setters,
      setVariant,
      ...validateAsSetters,
    },
    validationOptions,
  };
}
