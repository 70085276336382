import { ReactNode, ReactElement, createContext, useContext } from 'react';

import { ImportQueryParams } from '@breathelife/types';

import { useModalState, useSelector } from '../../Hooks';
import { QuestionnaireVersionDataContext } from '../../Pages/Admin/Questionnaire/ContextProvider/QuestionnaireVersionDataContextProvider';
import { useImportDataMutation } from '../../ReactQuery/Admin/ImportData/importData.mutations';
import { SettingsState } from '../../ReduxStore/Admin/SettingsManagement/SettingsSlice';

type Props = Omit<ImportQueryParams, 'fileName'> & {
  children: ReactNode;
};

interface ImportContext {
  isImportModalOpen: boolean;
  isLoading: boolean;
  toggleImportModal: () => void;
  handleFileUpload: (file: File[]) => void;
}

export const ImportCtx = createContext({} as ImportContext);

export function ImportDataProvider(props: Props): ReactElement {
  const { questionnaireVersionId } = useContext(QuestionnaireVersionDataContext);

  const { settings } = useSelector<SettingsState>((store) => store.leadPlatform.settings);

  const [isImportModalOpen, onOpenImportModal, onCloseImportModal] = useModalState();

  const importDataMutation = useImportDataMutation({
    onSuccess: onCloseImportModal,
  });

  const toggleImportModal = (): void => {
    isImportModalOpen ? onCloseImportModal() : onOpenImportModal();
  };

  const handleImportedData =
    (fileName: string) =>
    (event: ProgressEvent<FileReader>): void => {
      if (typeof event?.target?.result !== 'string') {
        return;
      }

      const query: ImportQueryParams = {
        blueprint: props.blueprint,
        salesDecisionRules: props.salesDecisionRules,
        insuranceProductsAndFirms: props.insuranceProductsAndFirms,
        theme: props.theme,
        settings: props.settings,
        questionnaireVersionId,
        themeId: props.themeId,
        settingsId: settings?.id,
        fileName,
      };

      const data = JSON.parse(event.target.result);

      importDataMutation.mutate({ query, importData: data });
    };

  const handleFileUpload = (file: File[]): void => {
    const fileData = file[0];
    const reader = new FileReader();
    reader.onload = handleImportedData(fileData.name);
    reader.readAsText(fileData);
  };

  return (
    <ImportCtx.Provider
      value={{
        isLoading: importDataMutation.isLoading,
        isImportModalOpen,
        toggleImportModal,
        handleFileUpload,
      }}
    >
      {props.children}
    </ImportCtx.Provider>
  );
}
