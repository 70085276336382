import { Popper } from '@breathelife/ui-components';

import styled from '../../../Styles/themed-styled-components';

export const StyledPopper = styled(Popper)`
  background-color: ${(props) => props.theme.colors.grey[0]};
  box-shadow: 0px 1px 4px rgb(0 0 0 / 25%);
  border-radius: 8px;
  margin-top: 15px;
  z-index: 3000;
`;
