import { ReactElement, Fragment } from 'react';

import {
  isQuestionBlueprint,
  PartIdentifier,
  PartIdentifierTag,
  QuestionnaireElementBlueprint,
  SectionGroupKey,
} from '@breathelife/types';
import { AutocompleteOption } from '@breathelife/ui-components';

import { useElementRepeatableNodeIdInParentsAndSelf } from '../Hooks/useElementRepeatableNodeIdParents';
import { CreateQuestionModal } from './CreateQuestionModal';
import { CreateSectionModal } from './CreateSectionModal';
import { CreateSubsectionModal } from './CreateSubsectionModal';
import { FieldCreator } from './FieldCreators/FieldCreator';

type CreateQuestionnaireElementProps = {
  type: PartIdentifierTag;
  onCreate: (blueprint: QuestionnaireElementBlueprint, parentPartIdentifier?: PartIdentifierTag) => void;
  onClose: () => void;
  dataLabelOptions: AutocompleteOption[];
  sectionGroupKey?: SectionGroupKey;
  parentBlueprint?: QuestionnaireElementBlueprint;
  parentPartIdentifier: PartIdentifier;
  parentHidden?: boolean;
};

export function CreateQuestionnaireElement({
  type,
  onCreate,
  onClose,
  dataLabelOptions,
  parentBlueprint,
  parentPartIdentifier,
  sectionGroupKey,
}: CreateQuestionnaireElementProps): ReactElement {
  const repeatableNodeIdsInNewElementParents = useElementRepeatableNodeIdInParentsAndSelf(
    parentPartIdentifier,
    'closestFirst',
  );

  const closestRepeatableNodeIdsInNewElementParents = repeatableNodeIdsInNewElementParents[0];

  return (
    <Fragment>
      {type === PartIdentifierTag.section && sectionGroupKey && (
        <CreateSectionModal
          sectionGroupKey={sectionGroupKey}
          onCloseModal={onClose}
          onCreate={onCreate}
          dataLabelOptions={dataLabelOptions}
        />
      )}
      {type === PartIdentifierTag.subsection && (
        <CreateSubsectionModal onCloseModal={onClose} onCreate={onCreate} dataLabelOptions={dataLabelOptions} />
      )}

      {type === PartIdentifierTag.question && (
        <CreateQuestionModal
          onCloseModal={onClose}
          onCreate={onCreate}
          dataLabelOptions={dataLabelOptions}
          sectionGroupCollectionNodeId={
            closestRepeatableNodeIdsInNewElementParents?.partIdentifierTag === PartIdentifierTag.sectionGroup
              ? closestRepeatableNodeIdsInNewElementParents.repeatableNodeId
              : undefined
          }
        />
      )}
      {type === PartIdentifierTag.field && parentBlueprint && isQuestionBlueprint(parentBlueprint) && (
        <FieldCreator
          onCloseModal={onClose}
          onSubmit={onCreate}
          dataLabelOptions={dataLabelOptions}
          repeatableNodeId={closestRepeatableNodeIdsInNewElementParents?.repeatableNodeId}
          displayAsCard={parentBlueprint.displayAsCard}
          questionBlueprint={parentBlueprint}
        />
      )}
    </Fragment>
  );
}
