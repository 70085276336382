import { useContext } from 'react';

import {
  AssistedApplicationQuestionnaireVersionValue,
  AssistedApplicationQuestionnaireVersionContext,
} from '../Context/AssistedApplicationQuestionnaireVersionContextProvider';

export function useAAQuestionnaireVersionContext(): AssistedApplicationQuestionnaireVersionValue {
  const context = useContext(AssistedApplicationQuestionnaireVersionContext);

  if (typeof context === 'undefined') {
    throw new Error(
      'useAAQuestionnaireVersionContext must be used within an AssistedApplicationQuestionnaireVersionContextProvider',
    );
  }

  return context;
}
