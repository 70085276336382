import { ReactElement } from 'react';

import { SvgIconProps } from '../../Components/Icons';

export function IdCheckIcon(props: SvgIconProps): ReactElement {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props.svgProps}>
      <path
        d='M0.5 11.5C0.500507 10.7561 0.685362 10.0238 1.03804 9.36882C1.39071 8.71379 1.90022 8.15637 2.52099 7.74639C3.14177 7.33642 3.85447 7.08667 4.59536 7.01947C5.33626 6.95228 6.08226 7.06973 6.76667 7.36134'
        stroke='#757575'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.25 3.25C2.25 3.97935 2.53973 4.67882 3.05546 5.19454C3.57118 5.71027 4.27065 6 5 6C5.72935 6 6.42882 5.71027 6.94454 5.19454C7.46027 4.67882 7.75 3.97935 7.75 3.25C7.75 2.52065 7.46027 1.82118 6.94454 1.30546C6.42882 0.789731 5.72935 0.5 5 0.5C4.27065 0.5 3.57118 0.789731 3.05546 1.30546C2.53973 1.82118 2.25 2.52065 2.25 3.25V3.25Z'
        fill='#F6F6F6'
        stroke='#757575'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.5 11.5C7.5 12.5609 7.92143 13.5783 8.67157 14.3284C9.42172 15.0786 10.4391 15.5 11.5 15.5C12.5609 15.5 13.5783 15.0786 14.3284 14.3284C15.0786 13.5783 15.5 12.5609 15.5 11.5C15.5 10.4391 15.0786 9.42172 14.3284 8.67157C13.5783 7.92143 12.5609 7.5 11.5 7.5C10.4391 7.5 9.42172 7.92143 8.67157 8.67157C7.92143 9.42172 7.5 10.4391 7.5 11.5Z'
        fill='#F6F6F6'
        stroke='#757575'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.2827 10.3367L11.346 12.9193C11.3029 12.9766 11.2481 13.024 11.1851 13.0582C11.1222 13.0925 11.0526 13.1128 10.9812 13.1179C10.9097 13.1229 10.838 13.1126 10.7708 13.0875C10.7037 13.0625 10.6427 13.0233 10.592 12.9727L9.59201 11.9727'
        stroke='#757575'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
