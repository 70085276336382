import { Box } from '@breathelife/mui';
import styled from '../../../../Styles/themed-styled-components';

import { EmptyApplicationList } from '../../../../Assets/EmptyApplicationList';
import { FilteredListEmpty } from '../../../../Assets/FilteredListEmpty';
import Typography from '../../../../Components/Typography';
import { emptyTableHeight } from '../../../../Models/Layout';

export const EmptyListContainer = styled(Box)`
  background-color: ${(props) => props.theme.colors.grey[30]};
  height: ${() => `calc(100vh - ${emptyTableHeight}px)`};
`;

export const ListTitle = styled(Typography)`
  && {
    line-height: 1.2em;
  }
`;

export const ListText = styled(Typography)`
  && {
    line-height: 1.2em;
  }
`;

export const EmptyApplicationListImg = styled(EmptyApplicationList)`
  display: inline-block;
`;

export const FilteredListEmptyImg = styled(FilteredListEmpty)`
  display: inline-block;
`;
