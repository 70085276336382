import { FieldTypes, YesNoValue } from '@breathelife/types';

import { NodeDetail, QuestionnaireNodeIds } from '../../../Helpers/questionnaireEditor/questionnaireNodeIds';

const numericFieldTypes = new Set([FieldTypes.money, FieldTypes.number]);
const textFieldTypes = new Set([FieldTypes.input, FieldTypes.textarea]);
const dateFieldTypes = new Set([FieldTypes.date]);
const booleanFieldTypes = new Set([FieldTypes.checkbox, FieldTypes.agree]);
const fieldTypesWithOptions = new Set([FieldTypes.checkboxGroup, FieldTypes.dropdown, FieldTypes.radio]);

function nodeHasOptions(nodeDetail: NodeDetail): boolean {
  return !!nodeDetail.fieldType && fieldTypesWithOptions.has(nodeDetail.fieldType);
}

export function nodeWithNumberValues(nodeDetail: NodeDetail): boolean {
  return !!nodeDetail.fieldType && numericFieldTypes.has(nodeDetail.fieldType);
}

export function nodeWithTextValues(nodeDetail: NodeDetail): boolean {
  return !!nodeDetail.fieldType && textFieldTypes.has(nodeDetail.fieldType);
}

export function nodeWithDateValues(nodeDetail: NodeDetail): boolean {
  return !!nodeDetail.fieldType && dateFieldTypes.has(nodeDetail.fieldType);
}

export function nodeWithBooleanValues(nodeDetail: NodeDetail): boolean {
  return !!nodeDetail.fieldType && booleanFieldTypes.has(nodeDetail.fieldType);
}

export function nodeWithOptions(
  nodeDetail: NodeDetail,
  selectOptionsByNodeId: QuestionnaireNodeIds['selectOptionsByNodeId'],
): boolean {
  return nodeHasOptions(nodeDetail) && !!selectOptionsByNodeId[nodeDetail.answerNodeId];
}

export function nodeWithYesNoOptions(
  nodeDetail: NodeDetail,
  selectOptionsByNodeId: QuestionnaireNodeIds['selectOptionsByNodeId'],
): boolean {
  const nodeHasReflexiveOptions =
    selectOptionsByNodeId[nodeDetail.answerNodeId]?.filter(
      (option) => option.optionName === YesNoValue.yes || option.optionName === YesNoValue.no,
    ).length === 2;

  return nodeHasOptions(nodeDetail) && nodeHasReflexiveOptions;
}
