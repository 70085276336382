import { ReactElement } from 'react';

import { SvgIconProps } from '../../Components/Icons';

export function NotificationActionIcon(props: SvgIconProps): ReactElement {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props.svgProps}>
      <path
        d='M11.983 -7.11135e-06C8.77657 0.0524852 5.71963 1.36471 3.47297 3.65299C2.34885 4.77217 1.46192 6.10651 0.865189 7.57624C0.268462 9.04598 -0.0258068 10.6209 -2.85204e-05 12.207C-0.00187299 13.7568 0.302135 15.2917 0.894574 16.7238C1.48701 18.1559 2.35623 19.457 3.45242 20.5525C4.54861 21.648 5.85022 22.5165 7.28266 23.1081C8.71509 23.6997 10.2502 24.0028 11.8 24H12.014C15.2213 23.967 18.2846 22.663 20.5317 20.3741C22.7788 18.0852 24.0261 14.9984 24 11.791C24.0037 10.2224 23.6937 8.66892 23.0882 7.22188C22.4828 5.77485 21.5941 4.46348 20.4745 3.3649C19.3548 2.26632 18.0268 1.4027 16.5686 0.824835C15.1103 0.246971 13.5512 -0.0334736 11.983 -7.11135e-06ZM10.5 16.542C10.4928 16.3459 10.5247 16.1504 10.594 15.9668C10.6632 15.7832 10.7684 15.6153 10.9033 15.4728C11.0382 15.3304 11.2002 15.2162 11.3797 15.1371C11.5593 15.058 11.7528 15.0155 11.949 15.012H11.976C12.3706 15.0128 12.7496 15.1662 13.0335 15.4403C13.3174 15.7143 13.4842 16.0877 13.499 16.482C13.5063 16.6781 13.4745 16.8737 13.4053 17.0573C13.336 17.241 13.2309 17.409 13.096 17.5515C12.961 17.6939 12.799 17.8081 12.6194 17.8871C12.4398 17.9662 12.2462 18.0087 12.05 18.012H12.023C11.6285 18.0107 11.2498 17.8571 10.966 17.5831C10.6822 17.3092 10.5152 16.9362 10.5 16.542ZM11 12.5V6.49999C11 6.23478 11.1053 5.98042 11.2929 5.79289C11.4804 5.60535 11.7348 5.49999 12 5.49999C12.2652 5.49999 12.5195 5.60535 12.7071 5.79289C12.8946 5.98042 13 6.23478 13 6.49999V12.5C13 12.7652 12.8946 13.0196 12.7071 13.2071C12.5195 13.3946 12.2652 13.5 12 13.5C11.7348 13.5 11.4804 13.3946 11.2929 13.2071C11.1053 13.0196 11 12.7652 11 12.5Z'
        fill='#D9A810'
      />

      <defs>
        <clipPath id='clip0_25_1794'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
