import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../Styles/themed-styled-components';

import { ESignCeremonyStatus } from '@breathelife/types';

import { LeadPlatformTheme } from '../../Styles/Types';
import { StyledBadge } from './Badge';

export type Props = {
  status: ESignCeremonyStatus;
};

type ESignCeremonyStatusTextColors = {
  [status in ESignCeremonyStatus]: { textColor: string; backgroundColor: string };
} & {
  unknown: { textColor: string; backgroundColor: string };
};

export function ESignCeremonyStatusBadge(props: Props): ReactElement | null {
  const theme = useTheme() as LeadPlatformTheme;
  const { t } = useTranslation();

  const eSignCeremonyStatusTextColors: ESignCeremonyStatusTextColors = {
    COMPLETED: { textColor: theme.colors.green[50], backgroundColor: theme.colors.green[20] },

    DRAFT: { textColor: theme.colors.grey[50], backgroundColor: theme.colors.grey[20] },
    READY: { textColor: theme.colors.orange[50], backgroundColor: theme.colors.orange[20] },

    IN_PROGRESS: { textColor: theme.colors.orange[50], backgroundColor: theme.colors.orange[20] },
    SENT: { textColor: theme.colors.orange[50], backgroundColor: theme.colors.orange[20] },
    CANCELLED: { textColor: theme.colors.red[50], backgroundColor: theme.colors.red[20] },
    EMAIL_BOUNCED: { textColor: theme.colors.red[50], backgroundColor: theme.colors.red[20] },
    OPT_OUT: { textColor: theme.colors.red[50], backgroundColor: theme.colors.red[20] },
    DECLINED: { textColor: theme.colors.red[50], backgroundColor: theme.colors.red[20] },
    SIGNER_LOCKED_OUT: { textColor: theme.colors.red[50], backgroundColor: theme.colors.red[20] },
    SEND_PACKAGE_ERROR: { textColor: theme.colors.red[50], backgroundColor: theme.colors.red[20] },

    EXPIRED: { textColor: theme.colors.purple[50], backgroundColor: theme.colors.purple[20] },

    unknown: { textColor: theme.colors.grey[50], backgroundColor: theme.colors.grey[20] },
  };

  let status: ESignCeremonyStatus | 'unknown' = props.status;

  if (!Object.values(ESignCeremonyStatus).includes(props.status)) {
    status = 'unknown';
  }

  const statusLabel = t(`eSignature.ceremonyStatus.${status}`);
  const statusColors = eSignCeremonyStatusTextColors[status];

  return (
    <StyledBadge
      backgroundColor={statusColors.backgroundColor}
      textColor={statusColors.textColor}
      label={statusLabel}
    />
  );
}
