import { Box } from '@breathelife/mui';
import { Fragment, ReactElement, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { HelmetContainer } from '../../../Components/HelmetContainer';
import { useDispatch, useSelector } from '../../../Hooks';
import { DetailViewType } from '../../../Models/Layout';
import * as ProductManagementOperations from '../../../ReduxStore/Admin/ProductManagement/ProductManagementOperations';
import {
  getIsLoadingProducts,
  getProducts,
} from '../../../ReduxStore/Admin/ProductManagement/ProductManagementSelectors';
import { layoutSlice } from '../../../ReduxStore/Layout/LayoutSlice';
import { ProductManagementView } from './ProductManagementView';

export function ProductManagementViewContainer(): ReactElement {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const products = useSelector(getProducts);
  const isLoadingProducts = useSelector(getIsLoadingProducts);

  useEffect(() => {
    void dispatch(ProductManagementOperations.fetchProducts());
  }, [dispatch]);

  const onOpenCreateProduct = useCallback(() => {
    void dispatch(
      layoutSlice.actions.setRightPanelState({
        rightPanelState: { isOpen: true, type: DetailViewType.create },
      }),
    );
  }, [dispatch]);

  const onTableRowClick = useCallback(
    (productId?: string) => {
      if (!productId) return;
      void dispatch(ProductManagementOperations.selectProduct(productId));
    },
    [dispatch],
  );

  return (
    <Fragment>
      <HelmetContainer text={t('pageTitles.productManagement')} />
      <Box display='flex' flexDirection={'column'} flexGrow={1}>
        <ProductManagementView
          products={products}
          isFetching={isLoadingProducts}
          onOpenCreateProduct={onOpenCreateProduct}
          onTableRowClick={onTableRowClick}
        />
      </Box>
    </Fragment>
  );
}
