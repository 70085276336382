import _ from 'lodash';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Pagination as MuiPagination, Box } from '@breathelife/mui';

import styled from '../../Styles/themed-styled-components';
import { Select, SelectProps, Options } from '../Select/Select';
import Typography from '../Typography';
import { PAGINATION_HEIGHT } from '../../Models/Layout';
import { PerPageContainer } from './Styles';

export const TablePagination = styled(MuiPagination)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${PAGINATION_HEIGHT};
`;

export const StyledSelect = styled(Select)`
  &&& {
    width: fit-content;
  }
` as <T extends string | number = string>(props: SelectProps<T>) => ReactElement;

type Props = {
  total: number;
  perPage: number;
  page: number;
  perPageOptions: Options<number>[];
  onPageChange: (page: number) => void;
  onPerPageChange: (perPage: number) => void;
};

export function Pagination(props: Props): ReactElement {
  const { t } = useTranslation();
  const paginationPerPageLabelId = 'pagination-perPage-label';
  return (
    <Box position='relative'>
      <TablePagination
        count={_.ceil(props.total / props.perPage)}
        page={props.page}
        //eslint-disable-next-line @typescript-eslint/naming-convention
        onChange={(_, page) => props.onPageChange(page)}
      />

      <PerPageContainer>
        <Typography variant='body3' grey={70}>
          {props.total} {t(`pagination.results.${props.total === 1 ? 'singular' : 'plural'}`)} | &nbsp;
        </Typography>
        <Typography variant='body3' grey={70} id={paginationPerPageLabelId}>
          {t('pagination.perPage')}
        </Typography>
        <Box ml={1}>
          <StyledSelect
            isSearchFilter
            id='tables_perPage'
            labelId={paginationPerPageLabelId}
            onChange={props.onPerPageChange}
            value={props.perPage}
            options={props.perPageOptions}
          />
        </Box>
      </PerPageContainer>
    </Box>
  );
}
