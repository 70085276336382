import { useCallback, useReducer } from 'react';

type Sort<TSortByColumnName> = { columnName: TSortByColumnName; direction: 'desc' | 'asc' };

export type TableOptionsState<TFilters, TSortByColumnName> = {
  sort?: Sort<TSortByColumnName>;
  filters: TFilters;
  page: number;
  perPage: number;
};

type TableOptionsAction<TFilters, TSortByColumnName> =
  | { type: 'setFilters'; payload?: Partial<TFilters> }
  | { type: 'setSorting'; payload: Sort<TSortByColumnName> }
  | { type: 'setPage'; payload: number }
  | { type: 'setPerPage'; payload: number };

export type TableOptionsOutput<TFilters, TSortByColumnName> = {
  tableOptions: TableOptionsState<TFilters, TSortByColumnName>;
  setFilters: (filters?: Partial<TFilters>) => void;
  setSorting: (columnName: TSortByColumnName, direction: 'desc' | 'asc') => void;
  setPage: (page: number) => void;
  setPerPage: (perPage: number) => void;
};

function useTableOptionsReducer<TFilters, TSortByColumnName>(
  state: TableOptionsState<TFilters, TSortByColumnName>,
  action: TableOptionsAction<TFilters, TSortByColumnName>,
): TableOptionsState<TFilters, TSortByColumnName> {
  switch (action.type) {
    case 'setFilters':
      const filters = { ...state.filters, ...action.payload };
      return { ...state, filters };
    case 'setSorting':
      return { ...state, sort: action.payload };
    case 'setPage':
      return { ...state, page: action.payload };
    case 'setPerPage':
      return { ...state, perPage: action.payload };
    default:
      return state;
  }
}

export function useTableOptions<TFilters, TSortByColumnName>(
  initialState: TableOptionsState<TFilters, TSortByColumnName>,
): TableOptionsOutput<TFilters, TSortByColumnName> {
  const [tableOptions, dispatch] = useReducer<
    (
      state: TableOptionsState<TFilters, TSortByColumnName>,
      action: TableOptionsAction<TFilters, TSortByColumnName>,
    ) => TableOptionsState<TFilters, TSortByColumnName>
  >(useTableOptionsReducer, initialState);

  const setFilters = useCallback(
    (filters?: Partial<TFilters>) => {
      dispatch({ type: 'setFilters', payload: filters });
    },
    [dispatch],
  );

  const setSorting = useCallback(
    (columnName: TSortByColumnName, direction: 'desc' | 'asc') => {
      dispatch({ type: 'setSorting', payload: { columnName, direction } });
    },
    [dispatch],
  );

  const setPage = useCallback(
    (page: number) => {
      dispatch({ type: 'setPage', payload: page });
    },
    [dispatch],
  );

  const setPerPage = useCallback(
    (perPage: number) => {
      dispatch({ type: 'setPerPage', payload: perPage });
    },
    [dispatch],
  );

  return {
    tableOptions,
    setFilters,
    setSorting,
    setPage,
    setPerPage,
  };
}
