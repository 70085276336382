import { Attachment } from '@breathelife/types';

import ApiService from './ApiService';

type CreateAttachmentData = {
  applicationId: string;
  file: File;
};

export async function createAttachment(data: CreateAttachmentData): Promise<Attachment> {
  const response = await ApiService.createApplicationAttachment(data);
  return response.data;
}

export async function fetchAttachments(applicationId: string): Promise<Attachment[]> {
  const response = await ApiService.fetchAttachmentsForApplication(applicationId);
  return response.data;
}

export async function getViewAttachmentUrl(attachmentId: string): Promise<{ redirectUrl: string }> {
  const response = await ApiService.getApplicationAttachmentRedirectUrl(attachmentId, false);
  return response.data;
}

export async function deleteAttachment(attachmentId: string): Promise<Attachment> {
  const response = await ApiService.deleteApplicationAttachment(attachmentId);
  return response.data;
}
