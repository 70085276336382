import { Grid } from '@breathelife/mui';

import styled from '../../../Styles/themed-styled-components';

export const StyledGrid = styled(Grid)`
  &&& {
    .MuiFormControlLabel-root {
      background-color: ${(props) => props.theme.colors.grey[20]};
      border-radius: 4px;
      margin: unset;
      padding-right: 8px;
      padding-top: 4px;
      padding-bottom: 4px;
      width: 100%;
    }
  }
`;
