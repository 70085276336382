import { ReactElement, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '../../../../../Styles/themed-styled-components';

import { QuestionnaireBlueprintRenderOn, PlatformType, InsuranceModule } from '@breathelife/types';

import EditorTooltip from '../Components/EditorTooltip';

const PLATFORM_COUNT: number = Object.keys(PlatformType).length;
const RENDER_ON_COUNT: number = Object.keys(QuestionnaireBlueprintRenderOn).length;

const List = styled.ul`
  list-style: none;
  padding: 0 16px;
`;

const SubList = styled(List)`
  padding: 8px 0 16px 0;
  display: flex;
`;

const Chip = styled.span`
  display: flex;
  align-items: center;
  border-radius: 24px;
  padding: 3px 12px;
  background: ${(props) => props.theme.colors.grey[60]};
  width: fit-content;
  margin-right: 8px;
`;

export function BlueprintContexts(props: {
  platforms?: PlatformType[];
  renderOn?: QuestionnaireBlueprintRenderOn[];
  insuranceModules?: InsuranceModule[];
}): ReactElement | null {
  const { platforms, renderOn, insuranceModules } = props;

  const shouldRenderPlatforms = Boolean(platforms?.length && hasValueButNotAllValues(platforms, PLATFORM_COUNT));
  const shouldRenderRenderOn = Boolean(renderOn?.length && hasValueButNotAllValues(renderOn, RENDER_ON_COUNT));
  const shouldRenderInsuranceModules = Boolean(insuranceModules?.includes(InsuranceModule.needsAnalysis));

  if (!shouldRenderPlatforms && !shouldRenderRenderOn && !shouldRenderInsuranceModules) {
    return null;
  }

  return (
    <EditorTooltip
      placement='bottom-start'
      content={
        <List>
          {shouldRenderPlatforms && <PlatformTypeList platforms={platforms} />}
          {shouldRenderRenderOn && <RenderOnList renderOn={renderOn} />}
          {shouldRenderInsuranceModules && <InsuranceModuleList />}
        </List>
      }
    />
  );
}

function PlatformTypeList({ platforms = [] }: { platforms?: PlatformType[] }): ReactElement {
  const { t } = useTranslation();

  return (
    <Fragment>
      <li>{t('admin.questionnaireManagement.platforms.title')}</li>
      <SubList>
        {platforms.map((platformType) => (
          <li key={platformType}>
            <Chip>{t(`admin.questionnaireManagement.platforms.${platformType}`)}</Chip>
          </li>
        ))}
      </SubList>
    </Fragment>
  );
}

function RenderOnList({ renderOn = [] }: { renderOn?: QuestionnaireBlueprintRenderOn[] }): ReactElement | null {
  const { t } = useTranslation();

  return (
    <Fragment>
      <li>{t('admin.questionnaireManagement.rendering.title')}</li>
      <SubList>
        {renderOn.map((renderOnType) => (
          <li key={renderOnType}>
            <Chip>{t(`admin.questionnaireManagement.rendering.${renderOnType}`)}</Chip>
          </li>
        ))}
      </SubList>
    </Fragment>
  );
}

function InsuranceModuleList(): ReactElement {
  const { t } = useTranslation();

  return (
    <Fragment>
      <li>{t('admin.questionnaireManagement.module.title')}</li>
      <SubList>
        <li>
          <Chip>{t('admin.questionnaireManagement.module.needsAnalysis')}</Chip>
        </li>
      </SubList>
    </Fragment>
  );
}

function hasValueButNotAllValues<T>(values: T[] | undefined, allPossibleValuesCount: number): boolean {
  return !!values && values.length !== allPossibleValuesCount;
}
