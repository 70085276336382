import { ReactElement, Fragment, useCallback, useState } from 'react';

import { useModalState } from '../../../Hooks';
import { useFindFirmsQuery } from '../../../ReactQuery/Admin/Firm/firm.queries';
import { EditFirmDrawerContainer } from './FirmDetail/EditFirmDrawerContainer';
import { NewFirmDrawerContainer } from './FirmDetail/NewFirmDrawerContainer';
import { FirmManagementView } from './FirmManagementView';
import { Box } from '@breathelife/ui-components';

export function FirmManagementViewContainer(): ReactElement {
  const [selectedFirmId, setSelectedFirm] = useState<string | null>(null);
  const [isCreateFirmDrawerOpen, onOpenCreateFirmDrawer, onCloseCreateFirmDrawer] = useModalState();

  const onOpenFirmDrawer = useCallback(
    (firmId?: string) => {
      if (firmId) {
        setSelectedFirm(firmId);
      }
    },
    [setSelectedFirm],
  );
  const onCloseFirmDrawer = useCallback(() => setSelectedFirm(null), []);

  const { isLoading: isLoadingFirms, data: firms } = useFindFirmsQuery();

  return (
    <Fragment>
      <Box flex flexGrow={1}>
        {firms && (
          <FirmManagementView
            firms={firms}
            isFetching={isLoadingFirms}
            onTableRowClick={onOpenFirmDrawer}
            onOpenCreateFirm={onOpenCreateFirmDrawer}
          />
        )}

        {selectedFirmId && (
          <EditFirmDrawerContainer selectedFirmId={selectedFirmId} onCloseFirmDrawer={onCloseFirmDrawer} />
        )}

        <NewFirmDrawerContainer isOpen={isCreateFirmDrawerOpen} onClose={onCloseCreateFirmDrawer} />
      </Box>
    </Fragment>
  );
}
