import { ReactElement } from 'react';

import { Grid } from '@breathelife/mui';
import { Language } from '@breathelife/types';

import { FirmFormFields, ValueChangeFunction } from '../types';
import { FirmFields } from './FirmFields';
import { FirmLogo } from './FirmLogo';

type Props = {
  onFirmFieldValueChange: ValueChangeFunction;
  enabledLanguages: Language[];
  insuranceFirmFormState: FirmFormFields;
};

export function FirmForm({ insuranceFirmFormState, onFirmFieldValueChange, enabledLanguages }: Props): ReactElement {
  return (
    <Grid container spacing={2}>
      <FirmFields
        insuranceFirmFormState={insuranceFirmFormState}
        onInputValueChange={onFirmFieldValueChange}
        enabledLanguages={enabledLanguages}
      />
      {enabledLanguages.map((language) => (
        <FirmLogo
          key={`productManagement-logoUrl-${language}`}
          onInputValueChange={onFirmFieldValueChange}
          language={language}
          logoUrl={insuranceFirmFormState?.logoUrl?.[language]}
        />
      ))}
    </Grid>
  );
}
