import { useTranslation } from 'react-i18next';
import { IconProps } from '../../../Icons';

export enum IdentityVerificationStatus {
  success = 'success',
  riskDetected = 'riskDetected',
  retry = 'retry',
  minor = 'minor',
  blocked = 'blocked',
}

export default function ThemePropsMapping(): Record<string, IconProps | null> {
  const { t } = useTranslation();

  return {
    [IdentityVerificationStatus.success]: {
      name: 'circledCheckMark',
      variant: 'green',
      ariaLabel: t('notifications.identityVerificationToast.success.title'),
    },
    [IdentityVerificationStatus.riskDetected]: {
      name: 'circleFilledWarning',
      fill: '#D9A810',
      variant: 'orange',
      ariaLabel: t('notifications.identityVerificationToast.warning.title'),
    },
    [IdentityVerificationStatus.retry]: {
      name: 'circleFilledWarning',
      fill: '#FF0000',
      variant: 'red',
      ariaLabel: t('notifications.identityVerificationToast.error.title'),
    },
    [IdentityVerificationStatus.minor]: {
      name: 'circledCheckMark',
      variant: 'green',
      ariaLabel: t('notifications.identityVerificationToast.info.minor.title'),
    },
  };
}
