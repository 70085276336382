import { QuestionnairePreview } from '@breathelife/types';

export const parsePreview = (data: unknown): QuestionnairePreview | null => {
  if (typeof data !== 'string') {
    return null;
  }

  switch (data) {
    case QuestionnairePreview.consumerFlow:
    case QuestionnairePreview.assistedApplication:
    case QuestionnairePreview.pdf:
      return data;
    default:
      return null;
  }
};
