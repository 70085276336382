import { ReactElement, Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FileTemplateOrigin } from '@breathelife/types';
import { AddIcon, Box } from '@breathelife/mui';

import { ActionButton } from '../../../Components/Button/ActionButton';
import { HelmetContainer } from '../../../Components/HelmetContainer';
import { Select } from '../../../Components/Select/Select';
import { useModalState } from '../../../Hooks';
import { DocumentsTable } from './DocumentsTable';
import { NewDocumentDrawer } from './NewDocumentDrawer';
import { QuestionnaireVersionsContextProvider } from '../Questionnaire/ContextProvider/QuestionnaireVersionsContextProvider';

type StringInputChangePayload = {
  value: string;
  path: string;
};

type OriginInputChangePayload = {
  value: FileTemplateOrigin;
  path: string;
};

export type ValueChangeFunctionPayload = StringInputChangePayload | OriginInputChangePayload;

enum TemplateStatus {
  ACTIVE = 'active',
  ARCHIVED = 'archived',
}

export function DocumentManagementViewContainer(): ReactElement {
  const { t } = useTranslation();

  const [isNewDocumentDrawerOpen, openNewDocumentTemplateDialog, closeNewDocumentTemplateDialog] = useModalState();
  const [templateStatusFilter, setTemplateStatusFilter] = useState(TemplateStatus.ACTIVE);

  const selectOptions: { label: string; value: TemplateStatus }[] = useMemo(
    () => [
      { label: t('admin.documentManagement.archiveStatusSelect.active'), value: TemplateStatus.ACTIVE },
      { label: t('admin.documentManagement.archiveStatusSelect.archived'), value: TemplateStatus.ARCHIVED },
    ],
    [t],
  );

  return (
    <Fragment>
      <HelmetContainer text={t('pageTitles.documents')} />
      <Box flex={1} flexGrow={1}>
        <QuestionnaireVersionsContextProvider>
          <NewDocumentDrawer isOpen={isNewDocumentDrawerOpen} onClose={closeNewDocumentTemplateDialog} />

          <Box display='flex' justifyContent='space-between' alignItems='center' m={2}>
            <Box>
              <Select
                isSearchFilter
                id='display-template-status'
                label={t('admin.documentManagement.documentStatus')}
                options={selectOptions}
                value={templateStatusFilter}
                onChange={setTemplateStatusFilter}
              />
            </Box>

            <ActionButton
              data-testid='addNewDocumentButton'
              color='primary'
              variant='contained'
              onClick={openNewDocumentTemplateDialog}
              startIcon={<AddIcon htmlColor='white' />}
            >
              {t('cta.addNew')}
            </ActionButton>
          </Box>

          <Box m={2}>
            <DocumentsTable displayArchived={templateStatusFilter === TemplateStatus.ARCHIVED} />
          </Box>
        </QuestionnaireVersionsContextProvider>
      </Box>
    </Fragment>
  );
}
