import { ReactElement } from 'react';
import { Tooltip } from '@breathelife/mui';

import { IconMap } from '../../../../../Components/Icons';
import { StyledListItem, StyledListItemButton, StyledListItemButtonIcon } from './ActionsWidgetStyles';

type ActionItemProps = {
  label: string;
  iconName: keyof typeof IconMap;
  action: () => void;
  isVisible: boolean;
  disabled: boolean;
  tooltip: string;
};

export function ActionItem(props: ActionItemProps): ReactElement | null {
  if (!props.isVisible) {
    return null;
  }

  return (
    <Tooltip title={props.tooltip}>
      <StyledListItem>
        <StyledListItemButton onClick={props.action} disabled={props.disabled}>
          <StyledListItemButtonIcon name={props.iconName} /> {props.label}
        </StyledListItemButton>
      </StyledListItem>
    </Tooltip>
  );
}
