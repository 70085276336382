import { AgreeFieldBlueprint } from '@breathelife/types';

import { FieldDataSetterProps } from '../../../Helpers/helperTypes';
import { AgreeFieldSetters, useAgreeFieldData } from '../useAgreeFieldData';
import { useCommonFieldDataSetters } from './useCommonFieldDataSetters';

export function AgreeFieldDataSetter({
  initialData,
  dataLabelOptions,
  ...props
}: FieldDataSetterProps<AgreeFieldBlueprint, AgreeFieldSetters>): null {
  const { data, setters, validationOptions, defaultValueValidationError } = useAgreeFieldData({
    initialData,
    dataLabelOptions,
  });

  useCommonFieldDataSetters<AgreeFieldBlueprint, AgreeFieldSetters>({
    ...props,
    data,
    setters,
    validationOptions,
    defaultValueValidationError,
    getIsValidBlueprint: (blueprint) =>
      !!(blueprint.validateAs && blueprint.confirmedLabel && blueprint.modalHeader && blueprint.modalText),
  });
  return null;
}
