import i18next from 'i18next';
import _ from 'lodash';

import { themeSlice } from '../../../ReduxStore/Admin/ThemeManagement/ThemeSlice';
import { notificationSlice } from '../../../ReduxStore/Notification/NotificationSlice';
import { Dispatch } from '../../../ReduxStore/types';
import ApiService from '../../../Services/ApiService';

export const fetchTheme =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(themeSlice.actions.setIsFetching(true));

    let themeResponse;
    try {
      themeResponse = await ApiService.fetchDefaultTheme();
      dispatch(themeSlice.actions.setTheme({ theme: themeResponse.data }));
    } catch (error: any) {
      dispatch(
        notificationSlice.actions.setError({
          message: i18next.t('notifications.failedToLoadTheme'),
        }),
      );
    } finally {
      dispatch(themeSlice.actions.setIsFetching(false));
    }
  };
