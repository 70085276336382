import { FileTemplate, StoredFileDocType, FileTemplateOrigin, DocumentVariant } from '@breathelife/types';

import ApiService from './ApiService';

export type CreateFileTemplateData = {
  name: string;
  localizedName: { en: string; fr: string };
  docType: StoredFileDocType;
  origin: FileTemplateOrigin;
  identifier?: string | null;
  externalUrl?: string | null;
  isRequired: boolean;
  file?: File;
  variant?: DocumentVariant | null;
};

export type UpdateFileTemplateData = {
  name: string;
  localizedName: { en: string; fr: string };
  docType: StoredFileDocType;
  origin: FileTemplateOrigin;
  identifier?: string | null;
  externalUrl?: string | null;
  isRequired: boolean;
  file?: File;
  variant: DocumentVariant | null;
};

export async function findFileTemplates(
  options?: Parameters<typeof ApiService.findFileTemplates>[0],
): Promise<FileTemplate[]> {
  const response = await ApiService.findFileTemplates(options);
  return response.data;
}

export async function getFileTemplate(fileTemplateId: string): Promise<FileTemplate> {
  const response = await ApiService.getFileTemplate(fileTemplateId);
  return response.data;
}

export async function createFileTemplate(data: CreateFileTemplateData): Promise<FileTemplate> {
  const response = await ApiService.createFileTemplate(data);
  return response.data;
}

export async function patchFileTemplate(fileTemplateId: string, data: Partial<FileTemplate>): Promise<FileTemplate> {
  const response = await ApiService.patchFileTemplate(fileTemplateId, data);
  return response.data;
}
