import _ from 'lodash';
import { ReactElement, Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import styled from '../../../Styles/themed-styled-components';

import { AdminToolResourceName, InsuranceProduct, LineOfBusinessName, ProductType } from '@breathelife/types';
import { AddIcon, Box } from '@breathelife/mui';

import { ActionButton } from '../../../Components/Button/ActionButton';
import { LogButton } from '../../../Components/Button/LogButton';
import { ExportButton } from '../../../Components/ExportData/ExportButton';
import { ExportDataProvider } from '../../../Components/ExportData/ExportDataProvider';
import { ImportButton } from '../../../Components/ExportData/ImportButton';
import { ImportDataProvider } from '../../../Components/ExportData/ImportDataProvider';
import { ImportModal } from '../../../Components/ExportData/ImportModal';
import LoadingView from '../../../Components/LoadingView/LoadingView';
import { Table } from '../../../Components/Table/Table';
import Typography from '../../../Components/Typography';
import { useCarrierContext, useLocale } from '../../../Hooks';
import { emptyTableHeight } from '../../../Models/Layout';
import { ProductDetailContainer } from './ProductDetail/ProductDetailContainer';

const EmptyProductsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: ${() => `calc(100vh - ${emptyTableHeight}px)`};
  min-height: 300px;
`;

const ActionButtonContainer = styled.div`
  display: grid;
  grid-gap: 8px;
  margin-bottom: 12px;
  grid-auto-flow: column;
  justify-content: end;
`;

type Props = {
  products: InsuranceProduct[];
  isFetching: boolean;
  onOpenCreateProduct: () => void;
  onTableRowClick: (id?: string) => void;
};

type InsuranceProductTableData = Pick<
  InsuranceProduct,
  'id' | 'type' | 'lineOfBusiness' | 'minimumAge' | 'maximumAge' | 'archived' | 'productDescriptor'
> & {
  ['name.en']?: string;
  ['name.fr']?: string;
};

export function ProductManagementView(props: Props): ReactElement {
  const { t } = useTranslation();
  const { features } = useCarrierContext();
  const currentLanguage = useLocale();

  const columns = useMemo<
    {
      id?: string;
      Header: string;
      accessor: keyof InsuranceProductTableData;
      Cell?: (props: CellProps<InsuranceProductTableData>) => ReactElement;
    }[]
  >(
    () => [
      {
        Header: t('admin.productManagement.labels.name'),
        accessor: `name.${currentLanguage}`,
      },
      {
        Header: t('admin.productManagement.labels.productDescriptor'),
        accessor: 'productDescriptor',
      },
      {
        Header: t('admin.productManagement.labels.type'),
        accessor: 'type',
        Cell: function ({ cell: { value } }: { cell: { value: ProductType } }): ReactElement {
          return <Fragment>{_.startCase(value)}</Fragment>;
        },
      },
      {
        Header: t('admin.productManagement.labels.lineOfBusiness'),
        accessor: 'lineOfBusiness',
        Cell: function ({ cell: { value } }: { cell: { value: LineOfBusinessName } }): ReactElement {
          return <Fragment>{_.startCase(value)}</Fragment>;
        },
      },
      {
        Header: t('admin.productManagement.labels.minimumAge'),
        accessor: 'minimumAge',
      },
      {
        Header: t('admin.productManagement.labels.maximumAge'),
        accessor: 'maximumAge',
      },
      {
        Header: t('admin.productManagement.archiving.archived'),
        accessor: 'archived',
        Cell: function ({ cell: { value } }: { cell: { value: boolean } }): ReactElement {
          return (
            <Fragment>
              {value ? t('admin.productManagement.archiving.yes') : t('admin.productManagement.archiving.no')}
            </Fragment>
          );
        },
        sortType: (a: any, b: any, id: string) => {
          if (a.original[id] > b.original[id]) return -1;
          if (b.original[id] > a.original[id]) return 1;
          return 0;
        },
      },
      {
        id: 'logs',
        Header: t('viewLogs'),
        accessor: 'id',
        Cell: function ({ cell: { value: id } }: { cell: { value: string } }): ReactElement {
          return <LogButton resourceId={id} resourceName={AdminToolResourceName.product} />;
        },
      },
    ],

    [currentLanguage, t],
  );

  const isProductListEmpty = _.isEmpty(props.products);

  return (
    <Fragment>
      {props.isFetching ? (
        <LoadingView />
      ) : (
        <Box m={2} display='flex' flexDirection={'column'}>
          <ActionButtonContainer>
            <ExportDataProvider insuranceProductsAndFirms>
              <ExportButton />
            </ExportDataProvider>
            <ImportDataProvider insuranceProductsAndFirms>
              <ImportButton />
              <ImportModal title={t('admin.importData.insuranceProductsAndFirms')} />
            </ImportDataProvider>
            <ActionButton
              data-testid='addNewProductButton'
              color='primary'
              variant='contained'
              onClick={props.onOpenCreateProduct}
              startIcon={<AddIcon htmlColor='white' />}
            >
              {t('cta.addNew')}
            </ActionButton>
          </ActionButtonContainer>
          {isProductListEmpty ? (
            <EmptyProductsContainer>
              <Box mb={1.5}>
                <Typography variant='h2' grey={70}>
                  {t('admin.productManagement.noProductsFoundTitle')}
                </Typography>
              </Box>
              <Box maxWidth={300}>
                <Typography variant='body1' grey={60}>
                  {t('admin.productManagement.noProductsFoundText')}
                </Typography>
              </Box>
            </EmptyProductsContainer>
          ) : (
            <Table<InsuranceProductTableData>
              columns={columns}
              data={props.products}
              onRowClick={(productId) => props.onTableRowClick(productId)}
              hiddenColumns={features.auditLogs?.enabled ? [] : ['logs']}
            />
          )}
        </Box>
      )}

      <ProductDetailContainer />
    </Fragment>
  );
}
