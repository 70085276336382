import { ReactElement, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { HelmetContainer } from '../../../Components/HelmetContainer';

import { UserManagementView } from './UserManagementView';
import { UsersContextProvider } from './UsersContextProvider';

export function UserManagementViewContainer(): ReactElement {
  const { t } = useTranslation();

  return (
    <Fragment>
      <HelmetContainer text={t('pageTitles.userManagement')} />
      <UsersContextProvider>
        <UserManagementView />
      </UsersContextProvider>
    </Fragment>
  );
}
