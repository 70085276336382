import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { PaymentTransaction } from '@breathelife/types';

import { QueryId } from '../../ReactQuery/common/common.types';

import * as PaymentService from '../../Services/PaymentService';

export function useFetchPaymentTransaction(
  applicationId: string,
  fetchOption: { withCustomer?: boolean; withToken?: boolean; withCardDetails?: boolean },
  options: UseQueryOptions<PaymentTransaction | null> = {},
): UseQueryResult<PaymentTransaction | null> {
  const { enabled = true } = options;

  return useQuery<PaymentTransaction | null>(
    [QueryId.fetchPaymentTransaction, applicationId],
    async () => {
      return await PaymentService.fetchPaymentTransaction(applicationId, fetchOption);
    },
    {
      enabled: !!applicationId && enabled,
      ...options,
    },
  );
}
