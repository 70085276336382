import { Box } from '@breathelife/mui';
import _ from 'lodash';
import { ReactElement, useState } from 'react';
import styled from '../../../../../Styles/themed-styled-components';

import { TabProps } from '../../../../../Models/Layout';
import { Lead } from '../../../../../Models/Lead';
import { ApplicationDetail } from './Components/ApplicationDetail';
import { ApplicationList } from './Components/ApplicationList';
import { NotSent } from './Components/NotSent';

type NeedsAnalysisViewProps = {
  lead?: Lead;
  onSendEmailClick: (lead: Lead) => void;
  tabProps: TabProps;
};

const ApplicationListContainer = styled.div<{ isActive?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateX(${(props) => (props.isActive ? 0 : '-100%')});
  transition: transform 0.35s ${(props) => props.theme.animation.transition};
`;

const ApplicationDetailContainer = styled.div<{ isActive?: boolean }>`
  transform: translateX(${(props) => (props.isActive ? 0 : '100%')});
  transition: transform 0.35s ${(props) => props.theme.animation.transition};
`;

export function NeedsAnalysisView({ lead, onSendEmailClick, tabProps }: NeedsAnalysisViewProps): ReactElement {
  const [activeApplicationIndex, setActiveApplicationIndex] = useState<number>();
  const leadStatus = lead?.status || 'new';
  const isLeadStatusNew = leadStatus === 'new';

  const toggleDetailView = (activeApplicationIndex: number): void => {
    setActiveApplicationIndex(activeApplicationIndex);
  };

  const onCloseDetail = (): void => {
    setActiveApplicationIndex(undefined);
  };

  if (isLeadStatusNew) {
    return <NotSent lead={lead} onSendToNeedsAnalysisClick={() => onSendEmailClick(lead as Lead)} />;
  }

  const applications = lead?.applications ?? [];

  return (
    <Box position='relative'>
      <ApplicationListContainer isActive={_.isUndefined(activeApplicationIndex)}>
        <ApplicationList
          applications={applications ? applications : []}
          leadStatus={leadStatus}
          toggleDetailView={toggleDetailView}
        />
      </ApplicationListContainer>

      <ApplicationDetailContainer isActive={!_.isUndefined(activeApplicationIndex)}>
        {typeof activeApplicationIndex !== 'undefined' && (
          <ApplicationDetail
            customView={tabProps.component}
            application={applications[activeApplicationIndex]}
            leadStatus={leadStatus}
            onClose={onCloseDetail}
          />
        )}
      </ApplicationDetailContainer>
    </Box>
  );
}
