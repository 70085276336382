import styled from '../../../../Styles/themed-styled-components';

import Typography from '../../../../Components/Typography';

export const Container = styled.div`
  padding: 15px 24px;
  min-height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey[40]};
`;

export const InlineContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

export const FullName = styled(Typography)`
  && {
    margin-bottom: 14px;
  }
`;
