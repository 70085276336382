import { CountryCode } from '../types';

const currencyMap: { [country in CountryCode]: string } = {
  US: 'USD',
  CA: 'CAD',
};

export default function getCurrency(countryCode: CountryCode): string {
  return currencyMap[countryCode];
}
