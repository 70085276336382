import { ReactChild, ReactElement } from 'react';

import { Tooltip } from '@breathelife/mui';

import { Icon } from '../../../../../Components/Icons/Icon';
import styled from '../../../../../Styles/themed-styled-components';

type Placement =
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top';

const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

interface Props {
  placement: Placement;
  content: string | ReactChild;
}

function EditorTooltip(props: Props): ReactElement {
  return (
    <Tooltip placement='top' arrow title={props.content} disableInteractive>
      <IconContainer>
        <Icon name='info' width='16px' height='16px' />
      </IconContainer>
    </Tooltip>
  );
}

export default EditorTooltip;
