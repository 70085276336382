import _ from 'lodash';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { Answers, Application, LocalizedInsuranceProduct, ProductsEntity, QuoteInfo } from '@breathelife/types';

import { QueryId } from '../../ReactQuery/common/common.types';
import { getProductsEntity } from '../../Services/ProductsService';

export function useGetProductsEntityQuery(
  answers: Answers,
  products: LocalizedInsuranceProduct[],
  quotes: QuoteInfo,
  application: Application,
  options: UseQueryOptions<ProductsEntity<string>> = {},
): UseQueryResult<ProductsEntity<string>> {
  return useQuery<ProductsEntity<string>>(
    [QueryId.productsEntity],
    async () => {
      return await getProductsEntity(answers, products, quotes, application);
    },
    {
      ...options,
    },
  );
}
