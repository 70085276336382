import { ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Grid } from '@breathelife/mui';
import { ESignCeremonyStatus } from '@breathelife/types';

import { ESignCeremonyStatusBadge } from '../../../../../Components/Badge/ESignCeremonyStatusBadge';
import { DescriptionListItem } from '../../../../../Components/DescriptionListItem';
import Typography from '../../../../../Components/Typography';
import { useDispatch } from '../../../../../Hooks';
import { notificationSlice } from '../../../../../ReduxStore/Notification/NotificationSlice';

type SummaryProps = {
  provider: string;
  providerPackageId?: string;
  status: ESignCeremonyStatus;
};

export function Summary({ provider, providerPackageId, status }: SummaryProps): ReactElement {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onProviderPackageIdCopy = useCallback(
    (data: string) => {
      dispatch(
        notificationSlice.actions.setSuccess({
          autoHideDuration: 3000,
          message: t('notifications.dataCopied', { data }),
        }),
      );
    },
    [dispatch, t],
  );

  return (
    <Box mb={4}>
      <Typography variant='h2'>{t('applicationSupport.eSignature.summary')}</Typography>
      <Box mt={1}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
            <DescriptionListItem label={t('applicationSupport.eSignature.provider')} value={provider} />
          </Grid>
          <Grid item>
            <DescriptionListItem
              label={t('applicationSupport.eSignature.signatureCeremonyStatus')}
              value={<ESignCeremonyStatusBadge status={status} />}
            />
          </Grid>
          {providerPackageId ? (
            <Grid item xs={12}>
              <DescriptionListItem
                copyButtonAriaLabel={t('applicationSupport.eSignature.package.copy', { provider })}
                copyEnabled
                label={t('applicationSupport.eSignature.package.id', { provider })}
                onCopy={onProviderPackageIdCopy}
                value={providerPackageId}
              />
            </Grid>
          ) : null}
        </Grid>
      </Box>
    </Box>
  );
}
