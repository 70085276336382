import { ReactElement, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ValidationError } from 'yup';

import { CoverageRangeCreateRequest } from '@breathelife/types';
import { AddIcon, Box, Grid } from '@breathelife/mui';

import { ActionButton } from '../../../../../Components/Button/ActionButton';
import { IconButton } from '../../../../../Components/Controls';
import { Icon } from '../../../../../Components/Icons';
import { NumberInput } from '../../../../../Components/NumberInput/NumberInput';
import Typography from '../../../../../Components/Typography';
import { getCoverageAmounRangesFieldsError } from '../../../../../Helpers/inputValidation/form/coverageAmountRanges';
import { DeleteItemFunction, ValueChangeFunction } from '../../types';

type Props = {
  coverageAmountRanges: CoverageRangeCreateRequest[];
  onInputValueChange: ValueChangeFunction;
  onListFieldItemDelete: DeleteItemFunction;
  disabled?: boolean;
};

export function CoverageAmountRanges(props: Props): ReactElement {
  const { t } = useTranslation();
  const [validationErrors, setValidationErrors] = useState<Record<string, ValidationError | undefined>>({});

  return (
    <Grid item xs={12} container spacing={2}>
      <Grid item xs={12}>
        <Box mt={2}>
          <Typography variant='h3'>{t('admin.productManagement.labels.coverageAmountRanges')}</Typography>
        </Box>
      </Grid>
      {props.coverageAmountRanges.map((coverageAmountRange, index) => (
        <Fragment key={index}>
          <Grid item xs={12}>
            <Box mt={2} display='flex' alignItems='center' justifyContent='space-between'>
              <Typography variant='body1'>
                {t('admin.productManagement.labels.coverageAmountRange', { index: index + 1 })}
              </Typography>
              <IconButton
                data-testid='coverageAmountRange-delete'
                icon={<Icon name='delete' alt={t('cta.delete')} />}
                title={t('cta.delete')}
                disabled={props.disabled}
                onClick={() => props.onListFieldItemDelete({ path: 'coverageAmountRanges', index })}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <NumberInput
              id='coverageAmountRanges-minimumAge'
              inputVariant='outlined'
              label={t('admin.productManagement.labels.minimumAge')}
              disabled={props.disabled}
              onNumberChange={(value) => {
                props.onInputValueChange({
                  path: `coverageAmountRanges.${index}.minimumAge`,
                  value,
                });
                setValidationErrors({
                  ...validationErrors,
                  [`${index}.minimumAge`]: getCoverageAmounRangesFieldsError('minimumAge', value),
                });
              }}
              value={coverageAmountRange.minimumAge ?? ''}
              error={Boolean(validationErrors?.[`${index}.minimumAge`])}
              validationError={validationErrors?.[`${index}.minimumAge`]}
            />
          </Grid>
          <Grid item xs={6}>
            <NumberInput
              id='coverageAmountRanges-maximumAge'
              inputVariant='outlined'
              disabled={props.disabled}
              label={t('admin.productManagement.labels.maximumAge')}
              onNumberChange={(value) => {
                props.onInputValueChange({
                  path: `coverageAmountRanges.${index}.maximumAge`,
                  value,
                });
                setValidationErrors({
                  ...validationErrors,
                  [`${index}.maximumAge`]: getCoverageAmounRangesFieldsError('maximumAge', value),
                });
              }}
              value={coverageAmountRange.maximumAge ?? ''}
              error={Boolean(validationErrors?.[`${index}.maximumAge`])}
              validationError={validationErrors?.[`${index}.maximumAge`]}
            />
          </Grid>
          <Grid item xs={6}>
            <NumberInput
              id='coverageAmountRanges-minimumCoverageAmount'
              inputVariant='outlined'
              label={t('admin.productManagement.labels.minimumCoverageAmount')}
              isCurrency
              disabled={props.disabled}
              onNumberChange={(value) => {
                props.onInputValueChange({
                  path: `coverageAmountRanges.${index}.minimumCoverageAmount`,
                  value,
                });
                setValidationErrors({
                  ...validationErrors,
                  [`${index}.minimumCoverageAmount`]: getCoverageAmounRangesFieldsError('minimumCoverageAmount', value),
                });
              }}
              value={coverageAmountRange.minimumCoverageAmount ?? ''}
              error={Boolean(validationErrors?.[`${index}.minimumCoverageAmount`])}
              validationError={validationErrors?.[`${index}.minimumCoverageAmount`]}
            />
          </Grid>
          <Grid item xs={6}>
            <NumberInput
              id='coverageAmountRanges-maximumCoverageAmount'
              inputVariant='outlined'
              label={t('admin.productManagement.labels.maximumCoverageAmount')}
              isCurrency
              disabled={props.disabled}
              onNumberChange={(value) => {
                props.onInputValueChange({
                  path: `coverageAmountRanges.${index}.maximumCoverageAmount`,
                  value,
                });
                setValidationErrors({
                  ...validationErrors,
                  [`${index}.maximumCoverageAmount`]: getCoverageAmounRangesFieldsError('maximumCoverageAmount', value),
                });
              }}
              value={coverageAmountRange.maximumCoverageAmount ?? ''}
              error={Boolean(validationErrors?.[`${index}.maximumCoverageAmount`])}
              validationError={validationErrors?.[`${index}.maximumCoverageAmount`]}
            />
          </Grid>
          <Grid item xs={6}>
            <NumberInput
              id='coverageAmountRanges-minimumSalary'
              inputVariant='outlined'
              disabled={props.disabled}
              label={t('admin.productManagement.labels.minimumSalary')}
              isCurrency
              onNumberChange={(value) => {
                props.onInputValueChange({
                  path: `coverageAmountRanges.${index}.minimumSalary`,
                  value,
                });
                setValidationErrors({
                  ...validationErrors,
                  [`${index}.minimumSalary`]: getCoverageAmounRangesFieldsError('minimumSalary', value),
                });
              }}
              value={coverageAmountRange.minimumSalary ?? ''}
              error={Boolean(validationErrors?.[`${index}.minimumSalary`])}
              validationError={validationErrors?.[`${index}.minimumSalary`]}
            />
          </Grid>
        </Fragment>
      ))}
      <Box mt={1} display='flex' alignItems='center' justifyContent='flex-end' ml='auto' width='100%'>
        <ActionButton
          data-testid='addNewCoverageAmountButton'
          color='primary'
          variant='contained'
          disabled={props.disabled}
          onClick={() =>
            props.onInputValueChange({
              path: 'coverageAmountRanges',
              value: [...props.coverageAmountRanges, {}],
            })
          }
          startIcon={<AddIcon htmlColor='white' />}
        >
          {t('cta.addNew')}
        </ActionButton>
      </Box>
    </Grid>
  );
}
