import { TFunction } from 'i18next';

import {
  FieldBlueprint,
  FieldPartIdentifier,
  isFieldBlueprint,
  isQuestionBlueprint,
  isSectionBlueprint,
  isSelectOptionFieldBlueprint,
  isSubsectionBlueprint,
  Language,
  PartIdentifier,
  PartIdentifierTag,
  QuestionBlueprint,
  QuestionnaireBlueprint,
  QuestionnaireElementBlueprint,
  QuestionPartIdentifier,
  SectionBlueprint,
  SectionPartIdentifier,
  SelectOptionFieldBlueprint,
  SelectOptionPartIdentifier,
  SubsectionBlueprint,
  SubsectionPartIdentifier,
} from '@breathelife/types';

import { arePartIdentifiersTheSame } from '../../Pages/Admin/Questionnaire/QuestionnaireEditor/Helpers/arePartIdentifiersTheSame';
import { getBlueprintVariantName } from '../../Pages/Admin/Questionnaire/QuestionnaireEditor/Helpers/getBlueprintvariantName';

export type TextFragmentWithHighlightInfo = { text: string; highlight: boolean };

export type HighlightedTextFragmentsByPropertyName = Partial<
  Record<SearchablePropertyNames, TextFragmentWithHighlightInfo[]>
>;

export enum SearchablePropertyNames {
  text = 'text',
  title = 'title',
  variant = 'variant',
  repeatableAnswerNodeId = 'repeatableAnswerNodeId',
  answerNodeId = 'answerNodeId',
  referenceLabel = 'referenceLabel',
  blueprintId = 'blueprintId',
}
export type QuestionnaireSearchResult = {
  identifier: PartIdentifier;
  propertiesContainingSearchTerm: SearchablePropertyNames[];
};

export function findMatchingSearchTermInstanceInSearchResults(
  partIdentifier: PartIdentifier,
  searchResults: QuestionnaireSearchResult[],
): QuestionnaireSearchResult | undefined {
  let searchResult: QuestionnaireSearchResult | undefined;
  searchResults.some((result) => {
    if (arePartIdentifiersTheSame(result.identifier, partIdentifier)) {
      searchResult = result;
      return true;
    }
    return false;
  });

  return searchResult;
}

export function getStartIndicesOfTermInString(string?: string, searchTerm?: string): number[] {
  if (!string || !searchTerm) {
    return [];
  }
  const indices: number[] = [];
  const recursiveFindIndex = (stringPart: string, currentIndex: number): void => {
    const index = stringPart.indexOf(searchTerm);
    if (index === -1) {
      return;
    }
    indices.push(index + currentIndex);
    const newCurrentIndex = index + searchTerm.length;
    recursiveFindIndex(stringPart.slice(newCurrentIndex), currentIndex + newCurrentIndex);
  };
  recursiveFindIndex(string, 0);
  return indices;
}

export function makeTextHighlightFragmentsFromIndices(
  text: string,
  searchTermCharacterLength: number,
  startIndices: number[],
): TextFragmentWithHighlightInfo[] {
  if (!searchTermCharacterLength) {
    return [{ text, highlight: false }];
  }

  const fragments: TextFragmentWithHighlightInfo[] = [];
  let highlight = false;

  const recursiveGetNextFragment = (currentIndex: number): void => {
    if (!highlight) {
      const nextStartHighlightIndex = startIndices.shift();
      const fragmentText = text.slice(currentIndex, nextStartHighlightIndex);
      if (fragmentText.length > 0) {
        fragments.push({ text: fragmentText, highlight });
      }
      highlight = true;
      if (nextStartHighlightIndex !== undefined) {
        recursiveGetNextFragment(nextStartHighlightIndex);
      }
    } else {
      const fragmentText = text.slice(currentIndex, currentIndex + searchTermCharacterLength);
      fragments.push({ text: fragmentText, highlight });
      highlight = false;
      recursiveGetNextFragment(currentIndex + searchTermCharacterLength);
    }
  };
  recursiveGetNextFragment(0);
  return fragments;
}

class NodeVisitor {
  partIdentifier: PartIdentifier;
  elementBlueprint: QuestionnaireElementBlueprint;
  action: (partIdentifier: PartIdentifier, elementBlueprint: QuestionnaireElementBlueprint) => void;
  constructor(
    partIdentifier: PartIdentifier,
    elementBlueprint: QuestionnaireElementBlueprint,
    action: (partIdentifier: PartIdentifier, elementBlueprint: QuestionnaireElementBlueprint) => void,
  ) {
    this.partIdentifier = partIdentifier;
    this.elementBlueprint = elementBlueprint;
    this.action = action;
    this.performActionOnSelfAndChildren();
  }

  private performActionOnSelfAndChildren(): void {
    this.action(this.partIdentifier, this.elementBlueprint);
    if (isSectionBlueprint(this.elementBlueprint)) {
      this.performActionOnSection(this.elementBlueprint);
    }
    if (isSubsectionBlueprint(this.elementBlueprint)) {
      this.performActionOnSubsection(this.elementBlueprint);
    }
    if (isQuestionBlueprint(this.elementBlueprint)) {
      this.performActionOnQuestion(this.elementBlueprint);
    }
    if (isFieldBlueprint(this.elementBlueprint) && isSelectOptionFieldBlueprint(this.elementBlueprint)) {
      this.performActionOnField(this.elementBlueprint);
    }
  }

  private performActionOnSection(blueprint: SectionBlueprint): void {
    blueprint.subsections.forEach((childElementBlueprint) => {
      const childPartIdentifier: SubsectionPartIdentifier = {
        ...(this.partIdentifier as SectionPartIdentifier),
        subsectionPartName: childElementBlueprint.partName,
        tag: PartIdentifierTag.subsection,
      };
      new NodeVisitor(childPartIdentifier, childElementBlueprint, this.action);
    });
  }

  private performActionOnSubsection(blueprint: SubsectionBlueprint): void {
    blueprint.questions.forEach((childElementBlueprint) => {
      const childPartIdentifier: QuestionPartIdentifier = {
        ...(this.partIdentifier as SubsectionPartIdentifier),
        questionPartName: childElementBlueprint.partName,
        tag: PartIdentifierTag.question,
      };
      new NodeVisitor(childPartIdentifier, childElementBlueprint, this.action);
    });
  }

  private performActionOnQuestion(blueprint: QuestionBlueprint): void {
    blueprint.fields.forEach((childElementBlueprint) => {
      const childPartIdentifier: FieldPartIdentifier = {
        ...(this.partIdentifier as QuestionPartIdentifier),
        fieldPartName: childElementBlueprint.partName,
        tag: PartIdentifierTag.field,
      };
      new NodeVisitor(childPartIdentifier, childElementBlueprint, this.action);
    });
  }

  private performActionOnField(blueprint: SelectOptionFieldBlueprint): void {
    blueprint.selectOptions.forEach((childElementBlueprint) => {
      const childPartIdentifier: SelectOptionPartIdentifier = {
        ...(this.partIdentifier as FieldPartIdentifier),
        selectOptionPartName: childElementBlueprint.partName,
        tag: PartIdentifierTag.selectOption,
      };
      new NodeVisitor(childPartIdentifier, childElementBlueprint as FieldBlueprint, this.action);
    });
  }
}

class QuestionnaireSearcher {
  blueprint: QuestionnaireBlueprint;
  searchTerm: string;
  searchResults: QuestionnaireSearchResult[];
  language: Language;
  t?: TFunction;

  constructor(blueprint: QuestionnaireBlueprint, searchTerm: string, language: Language, t?: TFunction) {
    this.blueprint = blueprint;
    this.searchTerm = searchTerm.toLowerCase();
    this.searchResults = [];
    this.language = language;
    this.t = t;
  }

  search(): void {
    const searchAction = (partIdentifier: PartIdentifier, elementBlueprint: QuestionnaireElementBlueprint): void => {
      const blueprintPropertiesContainingSearchTerm: SearchablePropertyNames[] = [];
      const textIndices = getStartIndicesOfTermInString(
        elementBlueprint.text?.[this.language]?.toLowerCase(),
        this.searchTerm,
      );
      if (textIndices.length > 0) {
        blueprintPropertiesContainingSearchTerm.push(SearchablePropertyNames.text);
      }
      const titleIndices = getStartIndicesOfTermInString(
        elementBlueprint.title?.[this.language]?.toLowerCase(),
        this.searchTerm,
      );
      if (titleIndices.length > 0) {
        blueprintPropertiesContainingSearchTerm.push(SearchablePropertyNames.title);
      }

      const labelIndices = getStartIndicesOfTermInString(
        elementBlueprint.referenceLabel?.toLowerCase(),
        this.searchTerm,
      );
      if (labelIndices.length > 0) {
        blueprintPropertiesContainingSearchTerm.push(SearchablePropertyNames.referenceLabel);
      }

      const blueprintIdIndices = getStartIndicesOfTermInString(elementBlueprint.id?.toLowerCase(), this.searchTerm);
      if (blueprintIdIndices.length > 0) {
        blueprintPropertiesContainingSearchTerm.push(SearchablePropertyNames.blueprintId);
      }

      if (isSubsectionBlueprint(elementBlueprint) && this.t) {
        const variantName = getBlueprintVariantName(elementBlueprint.variant, this.t);
        const variantIndices = getStartIndicesOfTermInString((variantName || '').toLowerCase(), this.searchTerm);
        if (variantIndices.length > 0) {
          blueprintPropertiesContainingSearchTerm.push(SearchablePropertyNames.variant);
        }
      }

      if (isQuestionBlueprint(elementBlueprint)) {
        const repeatableAnswerNodeIdIndices = getStartIndicesOfTermInString(
          elementBlueprint.repeatable?.repeatableAnswerNodeId.toLowerCase(),
          this.searchTerm,
        );
        if (repeatableAnswerNodeIdIndices.length > 0) {
          blueprintPropertiesContainingSearchTerm.push(SearchablePropertyNames.repeatableAnswerNodeId);
        }
      }

      if (isFieldBlueprint(elementBlueprint)) {
        const answerNodeIdIndices = getStartIndicesOfTermInString(
          elementBlueprint.answerNodeId.toLowerCase(),
          this.searchTerm,
        );
        if (answerNodeIdIndices.length > 0) {
          blueprintPropertiesContainingSearchTerm.push(SearchablePropertyNames.answerNodeId);
        }
      }

      if (blueprintPropertiesContainingSearchTerm.length > 0) {
        this.searchResults.push({
          propertiesContainingSearchTerm: blueprintPropertiesContainingSearchTerm,
          identifier: partIdentifier,
        });
      }
    };

    this.blueprint.sectionBlueprints.forEach((childElementBlueprint) => {
      const childPartIdentifier: SectionPartIdentifier = {
        sectionGroupPartName: childElementBlueprint.sectionGroupKey,
        sectionPartName: childElementBlueprint.partName,
        tag: PartIdentifierTag.section,
      };
      new NodeVisitor(childPartIdentifier, childElementBlueprint, searchAction);
    });
  }
}

export function searchQuestionnaire(
  questionnaire: QuestionnaireBlueprint,
  searchTerm: string,
  language: Language,
  t?: TFunction,
): QuestionnaireSearchResult[] {
  const searcher = new QuestionnaireSearcher(questionnaire, searchTerm, language, t);
  searcher.search();
  return searcher.searchResults;
}
