import { Button } from '@breathelife/mui';

import styled from '../../../../../../Styles/themed-styled-components';
import { UploadButton } from '../../../../../../Components/Button/UploadButton';

export const LinkUploadButton = styled(UploadButton)`
  &&& {
    border: none;
    background: none;
    text-decoration: none;
    padding: 0;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const LinkButton = styled(Button)`
  &&& {
    border: none;
    background: none;
    text-decoration: none;
    padding: 0;

    &:hover {
      text-decoration: underline;
    }
  }
`;
