import { FormLabel, OutlinedInput } from '@breathelife/mui';

import styled from '../../Styles/themed-styled-components';
import { Button } from '../Button/Button';

export const StyledInput = styled(OutlinedInput)`
  &.MuiInputBase-root {
    font-size: ${(props) => props.theme.text.body5.size};
  }
  &.Mui-disabled {
    color: ${(props) => `${props.theme.colors.grey[80]}`};
  }
`;

export const StyledLabel = styled(FormLabel)`
  position: relative;
  &.MuiFormLabel-root {
    color: ${(props) => `${props.theme.colors.grey[80]}`};
  }
`;

export const StyledButton = styled(Button)`
  white-space: nowrap;
  min-width: 24px;
`;
