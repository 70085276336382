// eslint-disable-next-line no-restricted-imports
import * as styledComponents from 'styled-components';
// eslint-disable-next-line no-restricted-imports
import type { ThemedStyledComponentsModule, FlattenSimpleInterpolation } from 'styled-components';
import { LeadPlatformTheme } from './Types';

const {
  default: styled,
  css,
  keyframes,
  ThemeProvider,
  useTheme,
  createGlobalStyle,
  ThemeContext,
} = styledComponents as any as ThemedStyledComponentsModule<LeadPlatformTheme>;

export { css, keyframes, ThemeProvider, useTheme, createGlobalStyle, ThemeContext };
export type { FlattenSimpleInterpolation };
export default styled;
