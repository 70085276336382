import { Badge, Box } from '@breathelife/mui';

import styled from '../../../../Styles/themed-styled-components';

export const PopperBox = styled(Box)`
  display: flex;
  flex-direction: row;
  padding: 16px;
  max-width: 337px;
`;

export const StyledBadge = styled(Badge)`
  .MuiBadge-colorPrimary  {
    background-color: green;
  }

  .MuiBadge-colorError {
    background-color: #d9a810;
  }
`;

export const TextContainer = styled(Box)`
  padding-bottom: 8px;
`;
