import {
  Paginated,
  SortParams,
  ApplicationSupportApplication,
  ApplicationFilters,
  ApplicationSupportApplicationOverview,
  PageQueryOptions,
  InsuredUnderwritingOutcomesReports,
  ApplicationSupportESignatureCeremony,
  ApplicationSupportAssociatedFile,
} from '@breathelife/types';

import ApiService from './ApiService';

export type GetApplicationsResponse = Paginated<ApplicationSupportApplication>;

export type ApplicationSupportSortParams = SortParams<ApplicationSupportApplication>;
export type AssociatedFilesSortParams = SortParams<ApplicationSupportAssociatedFile>;

export async function getApplicationSupportApplications(
  options: PageQueryOptions<ApplicationSupportApplication, ApplicationFilters>,
): Promise<Paginated<ApplicationSupportApplication>> {
  const response = await ApiService.getApplicationSupportApplications(options);
  return response.data;
}

export async function getUnderwritingReportsOutcomes(
  applicationId: string,
): Promise<InsuredUnderwritingOutcomesReports | null> {
  const response = await ApiService.getUnderwritingReportsOutcomes(applicationId);
  return response.data;
}

export async function getApplicationSupportPageApplication(
  applicationId: string,
): Promise<ApplicationSupportApplicationOverview | null> {
  const response = await ApiService.getApplicationSupportPageApplication(applicationId);
  return response.data;
}

export async function resetSubmissionStatus(applicationId: string): Promise<ApplicationSupportApplication> {
  const response = await ApiService.resetApplicationSupportApplicationSubmissionStatus({ applicationId });
  return response.data;
}

export async function getApplicationSupportApplicationSubmittedFiles(applicationId: string): Promise<Buffer> {
  return await ApiService.getApplicationSupportApplicationSubmittedFiles(applicationId);
}

export async function getApplicationSupportESignatureCeremonies(
  applicationId: string,
): Promise<ApplicationSupportESignatureCeremony[]> {
  const { data } = await ApiService.getApplicationSupportESignatureCeremonies(applicationId);

  return data;
}

export async function getApplicationSupportAssociatedFiles(
  applicationId: string,
  pageQueryOptions?: PageQueryOptions<ApplicationSupportAssociatedFile>,
): Promise<Paginated<ApplicationSupportAssociatedFile>> {
  const { data } = await ApiService.getApplicationSupportAssociatedFiles(applicationId, pageQueryOptions);

  return data;
}
