import { useTranslation } from 'react-i18next';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { useDispatch } from 'react-redux';

import { logger } from '@breathelife/monitoring-frontend';
import {
  QuestionnaireVersionInfo,
  QuestionnaireVersionInfoPage,
  PageQueryOptions,
  QuestionnaireVersionRowData,
} from '@breathelife/types';

import { QueryId } from '../../../ReactQuery/common/common.types';
import { notificationSlice } from '../../../ReduxStore/Notification/NotificationSlice';

import {
  fetchAllQuestionnaireVersions,
  fetchQuestionnaireVersionAndNodeIdData,
  fetchQuestionnaireVersions,
  QuestionnaireVersionDetailWithNodeIdInfo,
} from '../../../Services/QuestionnaireVersionService';

export function useFetchQuestionnaireVersionsQuery(
  fetchOptions: PageQueryOptions<QuestionnaireVersionRowData>,
  questionnaireId?: string,
  options?: UseQueryOptions<QuestionnaireVersionInfoPage | undefined>,
): UseQueryResult<QuestionnaireVersionInfoPage | undefined> {
  const queryId = [QueryId.questionnaireVersions, { fetchOptions, questionnaireId }];
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return useQuery<QuestionnaireVersionInfoPage | undefined>(
    queryId,
    () => {
      if (!questionnaireId) {
        return;
      }
      return fetchQuestionnaireVersions(questionnaireId, fetchOptions);
    },
    {
      ...options,
      onError: (err) => {
        dispatch(
          notificationSlice.actions.setError({
            message: t('notifications.failedToFetchEntity', {
              entity: t('admin.questionnaireManagement.questionnaireListData'),
            }),
          }),
        );
        logger.error(err);
        options?.onError?.(err);
      },
    },
  );
}

export function useFetchAllQuestionnaireVersionsQuery(
  questionnaireId: string,
  options?: UseQueryOptions<QuestionnaireVersionInfo[]>,
): UseQueryResult<QuestionnaireVersionInfo[]> {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return useQuery<QuestionnaireVersionInfo[]>(
    QueryId.allQuestionnaireVersions,
    () => fetchAllQuestionnaireVersions(questionnaireId),
    {
      ...options,
      onError(err) {
        dispatch(
          notificationSlice.actions.setError({
            message: t('notifications.failedToFetchEntity', {
              entity: t('admin.questionnaireManagement.questionnaireListData'),
            }),
          }),
        );
        logger.error(err);
        options?.onError?.(err);
      },
    },
  );
}

export function useFetchQuestionnaireVersionQuery(
  questionnaireVersionId: string,
  options?: UseQueryOptions<QuestionnaireVersionDetailWithNodeIdInfo>,
): UseQueryResult<QuestionnaireVersionDetailWithNodeIdInfo> {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return useQuery<QuestionnaireVersionDetailWithNodeIdInfo>(
    [QueryId.questionnaireVersion, questionnaireVersionId],
    async () => await fetchQuestionnaireVersionAndNodeIdData(questionnaireVersionId),
    {
      ...options,
      enabled: Boolean(questionnaireVersionId),
      onError: (err) => {
        dispatch(
          notificationSlice.actions.setError({
            message: t('notifications.failedToFetchEntity', {
              entity: t('admin.questionnaireManagement.questionnaireEditorData'),
            }),
          }),
        );
        logger.error(err);
        options?.onError?.(err);
      },
    },
  );
}
