import { ReactElement, ReactNode, Ref } from 'react';
import { Button as MuiButton, ButtonProps, CircularProgressProps } from '@breathelife/mui';

import Typography from '../Typography';
import { StyledLoader } from './Styles';

export function Button(
  props: ButtonProps & {
    children?: ReactNode;
    isLoading?: boolean;
    loaderProps?: CircularProgressProps;
    ref?: Ref<HTMLButtonElement>;
  },
): ReactElement {
  const { children, ref, disabled, isLoading, loaderProps, ...restProps } = props;
  return (
    <MuiButton {...restProps} ref={ref} disabled={disabled ?? isLoading}>
      {isLoading && <StyledLoader size='20px' {...loaderProps} />}
      {!isLoading && <Typography variant='button'>{children}</Typography>}
    </MuiButton>
  );
}
