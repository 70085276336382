import Copy from 'copy-to-clipboard';
import i18next from 'i18next';
import _ from 'lodash';
import queryString from 'query-string';

import { TypewriterTracking } from '@breathelife/frontend-tracking';
import { CreateUserData, UserNotificationOptions } from '@breathelife/types';

import { defaultState as defaultLayoutState, layoutSlice } from '../../ReduxStore/Layout/LayoutSlice';
import { notificationSlice } from '../../ReduxStore/Notification/NotificationSlice';
import { Dispatch, LeadPlatformStore } from '../../ReduxStore/types';
import { getPublicAccessLink } from '../../Services/AccessTokenService';
import UserService from '../../Services/Users/UsersService';
import { authenticationSlice } from './AuthenticationSlice';

const { actions: authenticationActions } = authenticationSlice;
const { actions: notificationActions } = notificationSlice;

export const fetchAndSetPublicLink = () => async (dispatch: Dispatch) => {
  const { link } = await getPublicAccessLink();
  dispatch(authenticationActions.setPublicLink({ publicLink: link }));
  return link;
};

export const copyAgentLink = () => async (dispatch: Dispatch, store: () => LeadPlatformStore) => {
  try {
    const user = store().leadPlatform.authentication.user;
    if (!user?.firstName || !user?.lastName) {
      dispatch(
        notificationSlice.actions.setError({
          message: 'In order to be able to copy your public link, make sure to complete your profile.',
        }),
      );
      return;
    }

    const publicLink = store().leadPlatform.authentication.publicLink;
    if (!publicLink) {
      const link = await fetchAndSetPublicLink()(dispatch);
      Copy(link);
    } else {
      const { url: parsedPublicLinkUrl, query: parsedQuery } = queryString.parseUrl(publicLink);
      const publicLinkUrl = queryString.stringifyUrl({
        url: parsedPublicLinkUrl,
        query: parsedQuery,
      });
      Copy(publicLinkUrl);
    }
    dispatch(
      notificationSlice.actions.setSuccess({
        message: i18next.t('notifications.linkCopied'),
        autoHideDuration: 3000,
      }),
    );
  } catch (err: any) {
    dispatch(
      notificationSlice.actions.setError({
        message: i18next.t('notifications.failedToCopyAgentLink'),
        autoHideDuration: 3000,
      }),
    );
  }
};

export const updateUser =
  (id: string, data: Partial<CreateUserData>, options: { isSso?: boolean } = {}) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(authenticationActions.setIsLoading({ isLoading: true }));
    try {
      const filteredData = options.isSso ? _.omit(data, ['emailLogin', 'firstName', 'lastName', 'roles']) : data;
      const user = await UserService.updateUserInformation(id, filteredData);
      dispatch(authenticationActions.updateUser({ user }));

      TypewriterTracking.completedProfile({ hasChanged: true });
      dispatch(
        notificationSlice.actions.setSuccess({
          message: i18next.t('notifications.profileUpdated'),
          autoHideDuration: 3000,
        }),
      );
      dispatch(layoutSlice.actions.setModalState({ modalState: defaultLayoutState.modalState }));
    } catch (error: any) {
      dispatch(notificationActions.setError({ message: error.message }));
    } finally {
      dispatch(authenticationActions.setIsLoading({ isLoading: false }));
    }
  };

export const getUser = (auth0Id: string) => async (dispatch: Dispatch) => {
  dispatch(authenticationActions.setIsLoading({ isLoading: true }));
  try {
    const user = await UserService.getUser(auth0Id);
    dispatch(authenticationActions.updateUser({ user }));
  } catch (error: any) {
    dispatch(notificationActions.setError({ message: error.message }));
  } finally {
    dispatch(authenticationActions.setIsLoading({ isLoading: false }));
  }
};

export const updateUserNotificationPreferences =
  (userId: string, data: UserNotificationOptions) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(authenticationActions.setIsLoading({ isLoading: true }));

    try {
      const notificationPreferences = await UserService.updateUserNotificationPreferences(userId, data);
      dispatch(authenticationActions.updateNotificationPreferences({ notificationPreferences }));
      dispatch(
        notificationSlice.actions.setSuccess({
          message: i18next.t('notifications.updatedUserNotificationPreferences'),
          autoHideDuration: 3000,
        }),
      );
    } catch (error: any) {
      dispatch(
        notificationActions.setError({ message: i18next.t('notifications.failedToUpdateNotificationPreferences') }),
      );
    } finally {
      dispatch(authenticationActions.setIsLoading({ isLoading: false }));
    }
  };
