import { InformationFieldBlueprint } from '@breathelife/types';

import { FieldDataSetterProps } from '../../../Helpers/helperTypes';
import { InformationFieldSetters, useInformationFieldData } from '../useInformationFieldData';
import { useCommonFieldDataSetters } from './useCommonFieldDataSetters';

export function InformationFieldDataSetter({
  initialData,
  dataLabelOptions,
  ...props
}: FieldDataSetterProps<InformationFieldBlueprint, InformationFieldSetters>): null {
  const { data, setters, validationOptions, defaultValueValidationError } = useInformationFieldData({
    initialData,
    dataLabelOptions,
  });

  useCommonFieldDataSetters<InformationFieldBlueprint, InformationFieldSetters>({
    ...props,
    data,
    setters,
    validationOptions,
    defaultValueValidationError,
    getIsValidBlueprint: (blueprint) => !!(blueprint.validateAs && blueprint.variant),
  });
  return null;
}
