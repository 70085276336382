import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AuditLog } from '@breathelife/types';

export type AuditLogsState = {
  logs: AuditLog[];
  total: number;
  isLoading: boolean;
};

type AuditLogsPayload = { logs: AuditLog[]; total: number };
type SetIsLoadingPayload = { isLoading: boolean };

type ActionsType = {
  setLogs: (state: AuditLogsState, { payload }: PayloadAction<AuditLogsPayload>) => void;
  setIsLoading: (state: AuditLogsState, { payload }: PayloadAction<SetIsLoadingPayload>) => void;
};

const defaultState: AuditLogsState = {
  logs: [],
  total: 0,
  isLoading: false,
};

export const auditLogsSlice = createSlice<AuditLogsState, ActionsType>({
  name: 'leadPlatform/auditLogs',
  initialState: defaultState,
  reducers: {
    setLogs: (state: AuditLogsState, { payload }: PayloadAction<AuditLogsPayload>) => {
      state.logs = payload.logs;
      state.total = payload.total;
    },
    setIsLoading: (state: AuditLogsState, { payload }: PayloadAction<SetIsLoadingPayload>) => {
      state.isLoading = payload.isLoading;
    },
  },
});
