import { Validations } from '@breathelife/questionnaire-engine';
import { FieldBlueprint, TextareaFieldBlueprint, FieldTypes, StringFieldValidation } from '@breathelife/types';
import { AutocompleteOption } from '@breathelife/ui-components';

import {
  FieldSettersValidateAs,
  FieldSettersDefaultValue,
  FieldDataHookReturnValueWithValidationOptions,
} from '../../Helpers/helperTypes';
import { BaseFieldSetters, useBaseFieldData } from './useBaseFieldData';
import { useDefaultValue } from './useDefaultValue';
import { useValidateAsData } from './useValidateAsData';

export type TextareaFieldSetters = FieldSettersValidateAs<TextareaFieldBlueprint> &
  FieldSettersDefaultValue<TextareaFieldBlueprint> &
  BaseFieldSetters;

export const useTextareaFieldData = ({
  initialData,
  dataLabelOptions,
}: {
  initialData?: Partial<FieldBlueprint>;
  dataLabelOptions: AutocompleteOption[];
}): FieldDataHookReturnValueWithValidationOptions<TextareaFieldBlueprint, TextareaFieldSetters> => {
  const fieldType = FieldTypes.textarea;
  const { data, setters } = useBaseFieldData({ initialData, dataLabelOptions });

  const {
    data: validateAsData,
    setters: validateAsSetters,
    validationOptions,
  } = useValidateAsData<TextareaFieldBlueprint>({
    fieldType,
    initialValidateAs: StringFieldValidation.string,
  });
  const {
    data: defaultValueData,
    setters: defaultValueSetters,
    validationError,
  } = useDefaultValue<TextareaFieldBlueprint>({
    validateAs: validateAsData.validateAs as unknown as Validations,
  });

  return {
    data: { fieldType, ...data, ...validateAsData, ...defaultValueData },
    setters: { ...setters, ...validateAsSetters, ...defaultValueSetters },
    validationOptions,
    defaultValueValidationError: validationError,
  };
};
