import { MenuItem as MenuItemMui } from '@breathelife/mui';

import styled from '../../Styles/themed-styled-components';

export const MenuItem = styled(MenuItemMui)`
  &:not(:disabled) {
    &:hover,
    &:active,
    &:focus {
      text-decoration: none;

      svg {
        stroke: ${(props) => props.theme.colors.primary.default};
        fill: ${(props) => props.theme.colors.primary[20]};
      }
    }
  }
`;
