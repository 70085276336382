import { ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Permission } from '@breathelife/types';

import { ActionsContainer } from '../../../../Components/ActionsContainer/ActionsContainer';
import { IconButton } from '../../../../Components/Controls';
import { Icon } from '../../../../Components/Icons';
import { RestrictedToUserPermissions } from '../../../../Components/Restricted/RestrictedToUserPermissions';
import { useCarrierContext } from '../../../../Hooks';
import { Application } from '../../../../Models/Application';
import { Lead } from '../../../../Models/Lead';
import { isApplicationEligibleForCopy } from '../../../../Helpers/applicationCopy/applicationCopy';

type Props = {
  disableArchive?: boolean;
  disableAssign?: boolean;
  disableEmail?: boolean;
  lead?: Lead;
  isLoading?: boolean;
  onSendEmailClick: (lead: Lead) => void;
  onToggleArchiveClick?: (lead: Lead) => void;
  onCopyClick?: (application: Application) => void;
  onDeleteClick?: (lead: Lead) => void;
  onDownloadFilesClick?: (lead: Lead) => void;
};

export function ActionButtons(props: Props): ReactElement {
  const { t } = useTranslation();
  const { externalResources, features } = useCarrierContext();
  const {
    lead,
    onToggleArchiveClick: onToggleArchiveClickProp,
    onSendEmailClick: onSendEmailClickProp,
    onCopyClick: onCopyClickProp,
    onDeleteClick: onDeleteClickProp,
    onDownloadFilesClick: onDownloadClickProp,
  } = props;

  const application = lead?.applications[0];

  const onToggleArchived = useCallback(() => {
    if (!lead || !onToggleArchiveClickProp) return;
    onToggleArchiveClickProp(lead);
  }, [lead, onToggleArchiveClickProp]);

  const onSendEmailClick = useCallback(() => {
    if (!lead) return;

    onSendEmailClickProp?.(lead);
  }, [lead, onSendEmailClickProp]);

  const onCopyClick = useCallback(() => {
    if (!application) return;
    onCopyClickProp?.(application);
  }, [application, onCopyClickProp]);

  const onDeleteClick = useCallback(() => {
    if (!lead) return;
    onDeleteClickProp?.(lead);
  }, [lead, onDeleteClickProp]);

  const onDownloadFilesClick = useCallback(() => {
    if (!lead) return;
    onDownloadClickProp?.(lead);
  }, [lead, onDownloadClickProp]);

  let applicationCopyButton;
  if (application && features.applicationCopy?.enabled) {
    const applicationCopyEligibility = isApplicationEligibleForCopy(application);
    const isCopyButtonDisabledForApplication =
      !applicationCopyEligibility.eligibility ||
      (features.applicationCopy.disabledForLineOfBusiness || []).includes(application.lineOfBusiness);

    applicationCopyButton = (
      <RestrictedToUserPermissions requiredPermissions={[Permission.ApplicationCopyCreate]}>
        <IconButton
          data-testid='actionButton-copy-application'
          icon={<Icon name='copy' alt={t('cta.copyApplication')} />}
          title={
            isCopyButtonDisabledForApplication && applicationCopyEligibility.reason
              ? t(`${applicationCopyEligibility.reason}`, {
                  refNoIncrement: application.refNoIncrement,
                  thresholdInDays: features.applicationCopy.applicationCopyThresholdInDays,
                })
              : t('cta.copyApplication')
          }
          showSkeleton={props.isLoading}
          onClick={onCopyClick}
          disabled={isCopyButtonDisabledForApplication}
          aria-label={t('cta.copyApplication')}
        />
      </RestrictedToUserPermissions>
    );
  }

  return (
    <ActionsContainer>
      {applicationCopyButton}
      {props.lead?.archived && (
        <RestrictedToUserPermissions requiredPermissions={[Permission.LeadDelete]}>
          <IconButton
            data-testid='actionButton-delete'
            icon={<Icon name='delete' alt={t('cta.delete')} />}
            title={t('cta.delete')}
            showSkeleton={props.isLoading}
            onClick={onDeleteClick}
            aria-label={t('cta.delete')}
          />
        </RestrictedToUserPermissions>
      )}
      {externalResources?.downloadableFiles && (
        <IconButton
          data-testid='actionButton-download'
          icon={<Icon name='files' alt={t('cta.downloadFile.plural')} />}
          title={t('cta.downloadFile.plural')}
          showSkeleton={props.isLoading}
          onClick={onDownloadFilesClick}
          aria-label={t('cta.downloadFile.plural')}
        />
      )}
      <RestrictedToUserPermissions requiredPermissions={[Permission.LeadArchive]}>
        <IconButton
          data-testid={props.lead?.archived ? 'actionButton-unarchive' : 'actionButton-archive'}
          icon={
            props.lead?.archived ? (
              <Icon name='unarchive' alt={t('cta.unarchive')} />
            ) : (
              <Icon name='archive' alt={t('cta.archive')} />
            )
          }
          title={
            props.lead?.archived
              ? t(features.hiddenLeads?.enabled ? 'cta.unarchiveApplication' : 'cta.unarchiveLead')
              : t(features.hiddenLeads?.enabled ? 'cta.archiveApplication' : 'cta.archiveLead')
          }
          onClick={onToggleArchived}
          disabled={!!props.disableArchive}
          showSkeleton={props.isLoading}
          aria-label={
            props.lead?.archived
              ? t(features.hiddenLeads?.enabled ? 'cta.unarchiveApplication' : 'cta.unarchiveLead')
              : t(features.hiddenLeads?.enabled ? 'cta.archiveApplication' : 'cta.archiveLead')
          }
        />
      </RestrictedToUserPermissions>
      <RestrictedToUserPermissions requiredPermissions={[Permission.LeadInvitePrivate, Permission.LeadInviteEmail]}>
        <IconButton
          data-testid='actionButton-sendNeedsAnalysis'
          icon={<Icon name='email' alt={t('cta.sendNeedsAnalysis')} />}
          title={t('cta.sendNeedsAnalysis')}
          onClick={onSendEmailClick}
          disabled={!!props.disableEmail}
          showSkeleton={props.isLoading}
          aria-label={t('cta.sendNeedsAnalysis')}
        />
      </RestrictedToUserPermissions>
    </ActionsContainer>
  );
}
