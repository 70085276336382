import { Fragment, MouseEvent, ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, ClickAwayListener } from '@breathelife/mui';
import { ImageTypes } from '@breathelife/ui-components';

import { StyledLoader } from '../../../../Components/Button/Styles';
import { Icon, IconProps } from '../../../../Components/Icons';
import Typography from '../../../../Components/Typography';
import { useCarrierContext, useSelector } from '../../../../Hooks';
import { StyledPopper } from '../Styles';
import {
  IconContainer,
  IdentityVerificationButton,
  IdentityVerificationRetryButton,
  PopperBox,
  StyledLink,
  TextContainer,
} from './Styles';
import ThemePropsMapping from './ThemePropsMapping';

type PopperContainerProps = {
  triggerIdentityVerification: () => void;
  onOpenInstantIdReportDrawer: () => void;
  isApplicantMinor?: boolean;
};

export function IdentityVerificationPopper(props: PopperContainerProps): ReactElement | null {
  const { identityVerificationStatus, isLoading } = useSelector((store) => store.leadPlatform.identityVerification);
  const { features } = useCarrierContext();
  const { triggerIdentityVerification, onOpenInstantIdReportDrawer, isApplicantMinor = false } = props;
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const onOpenIdentityVerificationPopper = (event: MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const isOpen = !!anchorEl;
  const popperId = isOpen ? 'identityverification-popper' : undefined;

  const handleClickAway = (): void => {
    setAnchorEl(null);
  };

  const themePropsMapping = ThemePropsMapping();

  const icon = useMemo(() => {
    const commonProps: Pick<IconProps, 'imageType' | 'inverted' | 'stroke'> = {
      imageType: ImageTypes.meaningful,
      inverted: true,
      stroke: 'none',
    };
    const themeProps = themePropsMapping[identityVerificationStatus] ?? null;
    return themeProps ? (
      <IconContainer>
        <Icon {...commonProps} {...themeProps} />
      </IconContainer>
    ) : null;
  }, [identityVerificationStatus, themePropsMapping]);

  if (!features.enableInstantIdRequest?.enabled) {
    return null;
  }

  return (
    <Fragment>
      <Box mr={2}>
        <Button
          color='secondary'
          variant='outlined'
          onClick={onOpenIdentityVerificationPopper}
          startIcon={<Icon name='idCheck' imageType={ImageTypes.decorative} />}
        >
          {t('assistedApplication.identityVerification')}
        </Button>
      </Box>
      <Box>{icon}</Box>

      {anchorEl && (
        <StyledPopper id={popperId} isOpen={isOpen} anchorEl={anchorEl} placement='auto'>
          <ClickAwayListener onClickAway={handleClickAway}>
            <PopperBox>
              <TextContainer>
                {(isApplicantMinor || identityVerificationStatus === 'minor') && (
                  <Typography variant='body3' grey={90}>
                    {t('notifications.identityVerificationToast.info.minor.description')}
                  </Typography>
                )}
                {!isApplicantMinor && (
                  <Box>
                    {identityVerificationStatus === '' && (
                      <Typography variant='body3' grey={90}>
                        {t('popper.identityVerification.responses.identityVerificationCheck.body')}
                      </Typography>
                    )}
                    {identityVerificationStatus === 'success' && (
                      <Typography variant='body3' grey={90}>
                        {t('popper.identityVerification.responses.identityVerificationSuccess.body')}
                      </Typography>
                    )}
                    {identityVerificationStatus === 'retry' && (
                      <Typography variant='body3' grey={90}>
                        {t('popper.identityVerification.responses.identityVerificationTimeoutRetry.body')}
                      </Typography>
                    )}
                    {identityVerificationStatus === 'riskDetected' && (
                      <Box>
                        <Box>
                          <Typography variant='body3' grey={90}>
                            {t('popper.identityVerification.responses.identityVerificationFailedRetry.body')}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant='body3' grey={90}>
                            <StyledLink onClick={onOpenInstantIdReportDrawer}>
                              {t('popper.identityVerification.responses.identityVerificationFailedRetry.reportLink')}
                            </StyledLink>
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </Box>
                )}
              </TextContainer>

              {!isApplicantMinor && (
                <Box>
                  {identityVerificationStatus === '' && (
                    <IdentityVerificationButton
                      color='primary'
                      variant='contained'
                      onClick={triggerIdentityVerification}
                      disabled={isLoading}
                    >
                      {isLoading && <StyledLoader size='20px' color='inherit' />}
                      {!isLoading &&
                        t('popper.identityVerification.responses.identityVerificationCheck.checkWithInstantIdButton')}
                    </IdentityVerificationButton>
                  )}
                  {identityVerificationStatus === 'retry' && (
                    <IdentityVerificationRetryButton
                      color='primary'
                      variant='outlined'
                      onClick={triggerIdentityVerification}
                      disabled={isLoading}
                    >
                      {t(
                        'popper.identityVerification.responses.identityVerificationTimeoutRetry.checkWithInstantIdButton',
                      )}
                    </IdentityVerificationRetryButton>
                  )}
                  {identityVerificationStatus === 'riskDetected' && (
                    <IdentityVerificationRetryButton
                      color='primary'
                      variant='outlined'
                      onClick={triggerIdentityVerification}
                      disabled={isLoading}
                    >
                      {t(
                        'popper.identityVerification.responses.identityVerificationFailedRetry.checkWithInstantIdButton',
                      )}
                    </IdentityVerificationRetryButton>
                  )}
                </Box>
              )}
            </PopperBox>
          </ClickAwayListener>
        </StyledPopper>
      )}
    </Fragment>
  );
}
