import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Helmet } from '@breathelife/ui-components';
import { useGetCloudinaryLinkAttributes } from '@breathelife/react-hooks';

import { useCarrierContext } from '../Hooks';

type HelmetContainerProps = {
  text?: string;
};

export function HelmetContainer(props: HelmetContainerProps): ReactElement {
  const { carrierInfo, cloudinaryConfig } = useCarrierContext();
  const { t } = useTranslation();

  const linkAttributes = useGetCloudinaryLinkAttributes([
    { cloudinaryConfig, compactLogoUrl: carrierInfo.logoCompact },
  ]);

  return (
    <Helmet
      linkAttributes={linkAttributes}
      titleAttributes={{ title: `${t('pageTitles.prefix')} - ${carrierInfo.companyName}`, text: props.text }}
    />
  );
}
