import { ReactElement } from 'react';

import { SvgIconProps } from '../../Components/Icons';

export function PushLeftIcon(props: SvgIconProps): ReactElement {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props.svgProps}>
      <path d='M4.75 8.00781H15' stroke='#476CC6' strokeWidth='1.25' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M7.875 11.1374L4.75 8.00816L7.875 4.87891'
        stroke='#476CC6'
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M1 1V15' stroke='#476CC6' strokeWidth='1.25' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
}
