import { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Box, Grid } from '@breathelife/mui';
import { ApplicationSupportESignatureCeremony } from '@breathelife/types';

import { useGetApplicationSupportESignatureCeremoniesQuery } from '../../../../ReactQuery/ApplicationSupport/ESignature/eSignature.queries';
import { ActiveParticipants } from './ActiveParticipants/ActiveParticipants';
import { CeremonyFiles } from './CeremonyFiles/CeremonyFiles';
import { CeremonyList } from './CeremonyList/CeremonyList';
import { Summary } from './Summary/Summary';

export function ESignatureViewContainer(): ReactElement | null {
  const { applicationId = '' } = useParams<{ applicationId: string }>();
  const { data: ceremonyData } = useGetApplicationSupportESignatureCeremoniesQuery(applicationId);
  const [selectedCeremony, setSelectedCeremony] = useState<ApplicationSupportESignatureCeremony>();

  useEffect(() => {
    if (ceremonyData && ceremonyData.length && !selectedCeremony) {
      setSelectedCeremony(ceremonyData[0]);
    }
  }, [ceremonyData, selectedCeremony]);

  if (!ceremonyData) {
    return null;
  }

  const loadCeremonyData = (ceremonyId: string): void => {
    setSelectedCeremony(ceremonyData.find((ceremony) => ceremony.id === ceremonyId));
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <Box p={2} style={{ position: 'fixed' }}>
          <CeremonyList ceremonies={ceremonyData} loadCeremonyData={loadCeremonyData} />
        </Box>
      </Grid>
      {selectedCeremony && (
        <Grid item xs={9}>
          <Box p={2}>
            <Summary
              provider={selectedCeremony.provider}
              providerPackageId={selectedCeremony?.providerPackage?.id}
              status={selectedCeremony.status}
            />
            <CeremonyFiles files={selectedCeremony.files} />
            <ActiveParticipants participants={selectedCeremony.participants} />
          </Box>
        </Grid>
      )}
    </Grid>
  );
}
