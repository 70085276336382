import { Ref, ReactElement, Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '../../../Styles/themed-styled-components';

import { ButtonName, TypewriterTracking } from '@breathelife/frontend-tracking';

import { Icon } from '../../../Components/Icons';
import { useCarrierContext, useDispatch } from '../../../Hooks';
import { notificationSlice } from '../../../ReduxStore/Notification/NotificationSlice';
import { UploadButton } from './Styles';
import { useExternalScriptUrl, ExternalScriptDomElementPosition } from '@breathelife/react-hooks';

type Cloudinary = {
  createUploadWidget: (options: Options, callback: ResultCallback) => Widget;
};

// Result events https://cloudinary.com/documentation/upload_widget#api_events
enum ResultEvents {
  success = 'success',
}

type CloudinaryUploadProps = {
  buttonText: string;
  minWidth?: number;
  minHeight?: number;
  uploadPreset?: string;
  setPicture: (pictureUrl: string) => void;
  uploadButtonRef?: Ref<HTMLButtonElement>;
  hideUploadButton?: boolean;
  clientAllowedFormats?: string[];
};

export function CloudinaryUpload(props: CloudinaryUploadProps): ReactElement | null {
  const { t } = useTranslation();
  const { cloudinaryConfig } = useCarrierContext();
  const theme = useContext(ThemeContext);

  const { lib: cloudinary } = useExternalScriptUrl<Cloudinary>({
    id: 'cloudinary',
    domElementPosition: ExternalScriptDomElementPosition.body,
    attributes: {
      src: 'https://widget.cloudinary.com/v2.0/global/all.js',
      async: false,
    },
    setLibOnLoad: true,
    onLoadLibName: 'cloudinary',
  });

  const dispatch = useDispatch();
  const [widget, setWidget] = useState<Widget>();
  const { setPicture, minWidth, minHeight, uploadPreset, uploadButtonRef, hideUploadButton, clientAllowedFormats } =
    props;

  useEffect(() => {
    if (!cloudinaryConfig || !cloudinary) return;

    const cloudinaryWidget = cloudinary.createUploadWidget(
      {
        styles: {
          palette: {
            windowBorder: theme.colors.primary.default,
            tabIcon: theme.colors.primary.default,
            menuIcons: theme.colors.grey[60],
            textDark: theme.colors.grey[90],
            link: theme.colors.primary.default,
            action: theme.colors.primary.default,
            sourceBg: theme.colors.grey[20],
          },
        },
        cloudName: cloudinaryConfig?.cloudName,
        uploadPreset: uploadPreset ?? cloudinaryConfig?.uploadPreset,
        folder: cloudinaryConfig?.folder,
        multiple: false,
        sources: ['local'],
        singleUploadAutoClose: false,
        theme: 'minimal',
        cropping: false,
        croppingAspectRatio: 1,
        croppingShowDimensions: true,
        croppingValidateDimensions: true,
        croppingCoordinatesMode: 'custom',
        maxImageFileSize: 5000000, // 5 MB
        clientAllowedFormats: clientAllowedFormats ?? ['jpg, jpeg', 'png', 'svg'],
        minImageWidth: minWidth ?? 200,
        minImageHeight: minHeight ?? 200,
      },
      (error: CloudinaryError, result: CloudinaryResult) => {
        if (error) {
          dispatch(notificationSlice.actions.setError({ message: error.statusText }));
          return;
        }
        if (result.event !== ResultEvents.success) return;
        setPicture(result.info.secure_url);
        dispatch(
          notificationSlice.actions.setSuccess({
            message: t('notifications.imageUploadSuccess'),
            autoHideDuration: 3000,
          }),
        );
      },
    );
    setWidget(cloudinaryWidget);
  }, [
    cloudinaryConfig,
    dispatch,
    cloudinary,
    setPicture,
    minWidth,
    minHeight,
    uploadPreset,
    t,
    theme,
    clientAllowedFormats,
  ]);

  const openWidget = useCallback(() => {
    widget?.open();
    TypewriterTracking.clickedButton({ buttonName: ButtonName.userProfileUploadPicture, hashedId: null });
  }, [widget]);

  if (!cloudinaryConfig) return null;

  return (
    <Fragment>
      <UploadButton
        ref={uploadButtonRef}
        data-testid='cloudinary-uploadPicture'
        color='primary'
        startIcon={<Icon name='upload' variant='primary' />}
        onClick={openWidget}
        hideUploadButton={!!hideUploadButton}
      >
        {props.buttonText}
      </UploadButton>
    </Fragment>
  );
}
