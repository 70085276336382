import { Box } from '@breathelife/mui';
import { ReactElement, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonName, TypewriterTracking } from '@breathelife/frontend-tracking';

import { Button } from '../../../Components/Button/Button';
import { GhostButton } from '../../../Components/Button/GhostButton';
import { Icon } from '../../../Components/Icons';
import Typography from '../../../Components/Typography';
import { useDispatch, useSelector } from '../../../Hooks';
import { ModalLayout } from '../../../Layouts/Modal/ModalLayout';
import {
  copyAgentLink as copyAgentLinkOperation,
  fetchAndSetPublicLink,
} from '../../../ReduxStore/Authentication/UserOperations';

export type Props = {
  isOpen: boolean;
  closeModal: () => void;
  onOpenProfilePage: () => void;
};

export function SharePublicLinkModal(props: Props): ReactElement {
  const { t } = useTranslation();
  const publicLink = useSelector((store) => store.leadPlatform.authentication.publicLink);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.isOpen) {
      dispatch(fetchAndSetPublicLink());
    }
  }, [dispatch, props.isOpen]);

  const copyAgentLink = useCallback(async () => {
    TypewriterTracking.clickedButton({
      buttonName: ButtonName.sharePublicLinkCopyToClipBoard,
      hashedId: null,
    });
    dispatch(copyAgentLinkOperation());
  }, [dispatch]);

  return (
    <ModalLayout
      maxWidth='sm'
      isOpen={props.isOpen}
      closeModal={props.closeModal}
      title={t('modals.sharePublicLink.title')}
      secondaryAction={
        <GhostButton onClick={() => window.open(`${publicLink}`)}>{t('modals.editUser.openInNewTab')}</GhostButton>
      }
    >
      <Box mb={1}>
        <Typography variant='body1' grey={70}>
          {t('modals.sharePublicLink.copyLinkLabel')}
        </Typography>
      </Box>
      <Box mb={3}>
        <Button
          startIcon={<Icon name='copy' variant='primary-inverted' />}
          variant='contained'
          color='primary'
          onClick={copyAgentLink}
          data-testid='copyPublicLink'
          data-publiclink={`${publicLink}`}
        >
          {t('modals.sharePublicLink.copyPublicLink')}
        </Button>
      </Box>
    </ModalLayout>
  );
}
