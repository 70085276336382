import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '../../../Styles/themed-styled-components';

import { Divider } from '@breathelife/mui';
import { Box } from '@breathelife/ui-components';

import { HelmetContainer } from '../../../Components/HelmetContainer';
import Typography from '../../../Components/Typography';
import { QuestionnaireBuilderPdfView } from './QuestionnaireBuilderPdfView';
import { QuestionnaireBuilderProvider } from './QuestionnaireBuilderProvider';

const QuestionnaireBuilderViewWrapper = styled.div`
  flex-grow: 1;
  min-height: 0;
`;

export function QuestionnaireBuilderView(): ReactElement {
  const { t } = useTranslation();

  const pageTitle = t('pageTitles.questionnaireBuilder');

  return (
    <Box height='100%'>
      <HelmetContainer text={pageTitle} />
      <Box height='100%' display='flex' flexDirection='column'>
        <QuestionnaireBuilderProvider>
          <Box height='100%' display='flex' flexDirection='column'>
            <Box m={2} display='flex' justifyContent='space-between'>
              <div>
                <Typography variant='h1'>{t('pageTitles.questionnaireBuilder')}</Typography>
              </div>
            </Box>

            <Divider />

            <QuestionnaireBuilderViewWrapper>
              <QuestionnaireBuilderPdfView />
            </QuestionnaireBuilderViewWrapper>
          </Box>
        </QuestionnaireBuilderProvider>
      </Box>
    </Box>
  );
}
