import { Tab, Tabs } from '@breathelife/mui';

import styled from '../../Styles/themed-styled-components';

export const StyledTab = styled(Tab)`
  min-width: 80px !important;
`;

export const StyledTabs = styled(Tabs)`
  flex-grow: 1;
`;
