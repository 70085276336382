import { useMutation, useQueryClient, UseMutationResult, UseMutationOptions } from 'react-query';

import { FileTemplateRecipient } from '@breathelife/types';

import { QueryId } from '../../ReactQuery/common/common.types';
import {
  CreateFileTemplateRecipientData,
  createFileTemplateRecipient,
  updateFileTemplateRecipient,
  deleteFileTemplateRecipient,
} from '../../Services/FileTemplateRecipientService';

export function useCreateFileTemplateRecipientMutation(
  options?: UseMutationOptions<FileTemplateRecipient, unknown, CreateFileTemplateRecipientData>,
): UseMutationResult<FileTemplateRecipient, unknown, CreateFileTemplateRecipientData> {
  const queryClient = useQueryClient();
  return useMutation<FileTemplateRecipient, unknown, CreateFileTemplateRecipientData, unknown>(
    createFileTemplateRecipient,
    {
      ...options,
      // Update cache
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries(QueryId.fileTemplateRecipients);

        if (options?.onSuccess) {
          await options.onSuccess(data, variables, context);
        }
      },
    },
  );
}

type UpdateFileTemplateRecipientParameters = {
  id: string;
  data: Partial<FileTemplateRecipient>;
};

export function useUpdateFileTemplateRecipientMutation(
  options?: UseMutationOptions<FileTemplateRecipient, unknown, UpdateFileTemplateRecipientParameters>,
): UseMutationResult<FileTemplateRecipient, unknown, UpdateFileTemplateRecipientParameters> {
  const queryClient = useQueryClient();
  return useMutation<FileTemplateRecipient, unknown, UpdateFileTemplateRecipientParameters, unknown>(
    ({ id, data }) => updateFileTemplateRecipient(id, data),
    {
      ...options,
      // Update cache
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries(QueryId.fileTemplateRecipients);

        if (options?.onSuccess) {
          await options.onSuccess(data, variables, context);
        }
      },
    },
  );
}

export function useDeleteFileTemplateRecipientMutation(
  options?: UseMutationOptions<FileTemplateRecipient, unknown, string>,
): UseMutationResult<FileTemplateRecipient, unknown, string> {
  const queryClient = useQueryClient();
  return useMutation<FileTemplateRecipient, unknown, string, unknown>(deleteFileTemplateRecipient, {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries(QueryId.fileTemplateRecipients);
      if (options?.onSuccess) {
        await options.onSuccess(data, variables, context);
      }
    },
  });
}
